export const tableHead = {
    fontWeight: "bold",
    color: 'gray',
    fontSize: "15px",
    whiteSpace: 'nowrap'
}
export const linkCol = {
    textDecoration: 'none',
    // fontWeight: "bold",
    cursor: "pointer",
    color: "#00BFFF",
    
}
export const linkHover = {
    fontSize: "16px",
    "&:hover": {
        color: "#0000ff"
    },
    textTransform:"none",
    whiteSpace: 'nowrap',
    textDecoration: 'none',
    cursor: "pointer",
    color: "#00BFFF",
    display:"inline"
}

export const statusColor:any ={
    approved: "#d7f5fa",
    pending: "#fffde3",
    rejected: "#f7e9e9",
    expired: "#e6e5e3",
    invitesSent: "#f3defa",
    cancelled: "#f0f0f0",
    dealStarted:"#e6ffee",
    revoked:"#CFB0B0",
    false:"#fffde3",
    true:"#d7f5fa",
    admin: "#f7e9e9",
    contact:"#f0f0f0"
}

export const statusColorText:any ={
    approved: "#02879e",
    pending: "#ffcc00",
    rejected: "#f24458",
    expired: "black",
    invitesSent: "#501e61",
    cancelled: "#696969",
    dealStarted:"#29a636",
    revoked:"#690000",
    false:"#ffcc00",
    true:"#02879e",
    admin: "#f24458",
    contact:"#696969"
}

export const transactionStatus:any = {
    received: "#e6ffee",
    pending: "#f7e9e9",
    "received partially": "#fffde3"
} 

export const transactionStatusText:any = {
    received: "#29a636",
    pending: "#f24458",
    "received partially": "#ffcc00"
}