import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import PageNameComp from '../../PageNameComp'
import TabMenu from '../../Layout/TabMenu'
import { Add } from '@mui/icons-material'
import InvitationForm from './InvitationForm'
import TableHeadRow from '../../Layout/TableHeadRow'
import { getComparator, sortedRowInformation } from '../../utils/SortingHandler'
import StatusCell from '../../Layout/StatusCell'
import { linkCol, linkHover, statusColor, statusColorText } from '../../tableStyle'
import { convertDate, firstCapital } from '../Approvals/util'
import axios from 'axios'
import ErrorComponent from '../../Layout/ErrorComponent'
import { useFormik } from 'formik'
import * as yup from 'yup';
import { ToastContainer, toast } from "react-toastify";
import InvitationsCardComponent from './InvitationsCardComponent'
import ReplayIcon from '@mui/icons-material/Replay';
import IconButton from '@mui/material/IconButton';
import ConfirmationDialog from '../../deals/ConfirmationDialog';
import ActivitiesHeader from '../../utils/ActivitiesHeader'

const Invitations = (props: any) => {
    const { screenType, user } = props;
    const [userType, setUserType] = useState("vendor");
    const [showDialog, setShowDialog] = useState(false);
    const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>('asc');
    const [valueToOrderBy, setValueToOrderBy] = useState('default_price');
    const [error, setError] = useState(false);
    const initialData = `<p>Hello</p><p></p><p>We would like to invite you to our application, please navigate to the link provided below to register your profile.</p><p>Thanks and Regards.</p>`;
    const [mailBody, setMailBody] = useState(initialData);
    const [rowData, setRowData] = useState<any>();
    const [empty, setEmpty] = useState(false);
    const [loader, setLoader] = useState(true);
    const [saveLoader, setSaveLoader] = useState(false);
    const [onChange, setOnchange] = useState(0);
    const [phone, setPhone] = useState<string>('');
    const [phoneValidation, setPhoneValidation] = useState({ error: false, message: "" })
    const [showResendDialog, setShowResendDialog] = useState(false);
    const [resendRow, setResendRow] = useState(null);
    const [isWebsiteValid, setIsWebsiteValid] = useState(false);
    const [websiteError, setWebsiteError] = useState<string | null>(null);

    const handleOpenResendDialog = (row: any) => {
        setResendRow(row);
        setShowResendDialog(true);
    };

    const handleCloseResendDialog = () => {
        setShowResendDialog(false);
    };

    const handlePhoneChange = (value: any) => {
        formik.values.phone = value.replace(/[^0-9]/g, "");
        setPhone(value);
        if (phoneValidation.error) {
            checkPhoneValidation();
        }
    };

    const checkPhoneValidation = () => {
        if (phone !== "") {
            if (phone === "" || phone === "+" || phone === String("+1")) {
                setPhoneValidation({ error: true, message: "Please enter your phone number" })
                return false;
            }
            else if (phone.replace(/[^0-9]/g, "").length < 11 || phone.replace(/[^0-9]/g, "").length > 12) {
                setPhoneValidation({ error: true, message: "Please enter a valid phone number" })
                return false;
            }
            else {
                setPhoneValidation({ error: false, message: "" })
                return true;
            }
        }
        else {
            setPhoneValidation({ error: true, message: "Phone No. is required" })
            return false;
        }
    }

    const successToast = () => toast.success("Mail sent successfully", { toastId: 'Mail-Sent' });
    const successInvitationToast = () => toast.success("Invitation sent successfully", { toastId: 'Invitation-Resend' });
    const failureToast = () => toast.error("There was some error while sending the invitation, please try again.", { toastId: 'Error-Occured' });
    const alreadyExistToast = () => toast.error("Can't Invite as the user already exists.", { toastId: 'Error-409-Occured' });
    const createSortHandler = (property: any) => (event: any) => {
        let isAscending;
        setValueToOrderBy(property);
        isAscending = orderDirection === 'asc';
        setOrderDirection(isAscending ? 'desc' : 'asc');
        sortedRowInformation(rowData, getComparator(orderDirection, property));
    };
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const handleChangePage = (event: any, newPage: any) => {
        setPage(newPage);
    }; const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    useEffect(() => {
        getInvitationData(userType)
    }, [onChange])
    useEffect(() => {
        if (screenType === "connector") {
            handleTabSwitch(1);
        }
        else if (screenType === "vendor") {
            handleTabSwitch(0)
        }
    }, [screenType])
    const getInvitationData = (userRole: any) => {
        axios.get(process.env.REACT_APP_BASE_URL + "invitation/type/" + userRole,
            {
                headers: {
                    "Authorization": "Bearer " + user.accessToken,
                }
            })
            .catch(error => {
                setLoader(false);
                setError(true)
            })
            .then((response: any) => {
                if (response.data.length === 0) {
                    setEmpty(true);
                }
                else {
                    setEmpty(false);
                    setRowData(response.data.reverse());
                    setLoader(false);
                }
            })
    }
    const resendInvitation = (id: any) => {
        axios.put(process.env.REACT_APP_BASE_URL + "invitation/resend", null, {
            headers: {
                "Authorization": "Bearer " + user.accessToken,
            }, params: {
                id: id,
            }
        })
            .catch(error => {
                failureToast();
            })
            .then((response: any) => {
                successInvitationToast();
            })
    }
    const handleTabSwitch = (itemNo: any) => {
        setEmpty(false);
        setLoader(true);
        if (itemNo === 1) {
            setUserType("connector");
            getInvitationData("connector");
        }
        else if (itemNo === 0) {
            setUserType("vendor");
            getInvitationData("vendor");
        }
    }
    const onClickAdd = () => {
        setPhone('');
        setShowDialog(true);
    }
    const handleClose = () => {
        setPhoneValidation({ error: false, message: "" })
        if (!saveLoader) {
            setShowDialog(false);
            setSaveLoader(false);
            setMailBody(initialData);
            formik.resetForm();
        }
    }

    const submitHandler = (values: any) => {
        if (!saveLoader) {
            setSaveLoader(true);
            var requestBody: any = {
                email: values.email,
                firstName: values.firstName,
                lastName: values.lastName,
                phoneNumber: values.phone,
                body: mailBody,
                type: userType
            };
            // TODO If changed then change the SignUp.tsx and always add https:// in payload
            if (userType === "vendor") {
                requestBody.name = values.organizationName;
                requestBody.website = "https://" + values.website;
            } else {
                requestBody.name = values.firstName + " " + values.lastName;
            }

            axios.post(process.env.REACT_APP_BASE_URL + "invitation/register", requestBody,
                {
                    headers: {
                        "Authorization": "Bearer " + user.accessToken,
                    }
                })
                .catch((error) => {
                    setShowDialog(false);
                    setSaveLoader(false);
                    if (error.response && error.response.status === 409) {
                        alreadyExistToast();
                    }
                })
                .then((response: any) => {
                    setShowDialog(false);
                    setSaveLoader(false);
                    setOnchange(prev => prev + 1);
                    if (response.status === 201) {
                        successToast();
                    }
                    else {
                        failureToast();
                    }
                    handleClose();
                })
        }
    }
    const vendorValidationSchema = yup.object({
        organizationName: yup
            .string()
            .min(4, "First name should contain 4 characters at least")
            .matches(/^[A-Za-z0-9 -]*$/, 'Please enter a valid name')
            .required("Organization name is required"),
        website: yup
            .string()
            .required("Organization website is required"),
        firstName: yup
            .string()
            .min(4, "First name should contain 4 characters at least")
            .matches(/^[A-Za-z-]*$/, 'Please enter a valid first name')
            .required('First name is required'),
        lastName: yup
            .string()
            .min(4, "First name should contain 4 characters at least")
            .matches(/^[A-Za-z-]*$/, 'Please enter a valid last name')
            .required('Last name is required'),
        email: yup
            .string()
            .matches(/^[\w\.+-]+@[\w\.-]+\.\w+$/, 'Please enter a valid email')
            .email("Enter a valid mail address")
            .required("Email id is required"),
    });

    const connectorValidationSchema = yup.object({
        firstName: yup
            .string()
            .min(4, "First name should contain 4 characters at least")
            .matches(/^[A-Za-z-]*$/, 'Please enter a valid first name')
            .required('First name is required'),
        lastName: yup
            .string()
            .min(4, "Last name should contain 4 characters at least")
            .matches(/^[A-Za-z-]*$/, 'Please enter a valid last name')
            .required('Last name is required'),
        email: yup
            .string()
            .matches(/^[\w\.+-]+@[\w\.-]+\.\w+$/, 'Please enter a valid email')
            .email("Enter a valid mail address")
            .required("Email id is required"),

    });

    const formik = useFormik({
        initialValues:
            userType === "vendor" ? {
                organizationName: '',
                website: '',
                firstName: '',
                lastName: '',
                phone: '',
                email: '',

            } : {
                firstName: '',
                lastName: '',
                phone: '',
                email: '',
            },
        validationSchema: userType === "vendor" ? vendorValidationSchema : connectorValidationSchema,
        onSubmit: (values) => {
            const res = checkPhoneValidation();
            const isOrgNameValid = values.organizationName?.trim() !== ""
            if (res) {
                if ((userType === "vendor" && isOrgNameValid && isWebsiteValid) || userType === "connector") {
                    submitHandler(values);
                }
            }
        }
    });

    useEffect(() => {
        if (showDialog === false) {
            formik.resetForm();
        }
    }, [showDialog])

    return (
        <>
            {!error ?
                <>
                    <InvitationForm showDialog={showDialog} handleClose={handleClose} mailBody={mailBody} setMailBody={setMailBody} saveLoader={saveLoader} formik={formik} userType={userType}
                        phone={phone}
                        setPhone={setPhone}
                        phoneValidation={phoneValidation}
                        setPhoneValidation={setPhoneValidation}
                        checkPhoneValidation={checkPhoneValidation}
                        handlePhoneChange={handlePhoneChange}
                        isWebsiteValid={isWebsiteValid}
                        setIsWebsiteValid={setIsWebsiteValid}
                        setWebsiteError={setWebsiteError}
                        websiteError={websiteError}
                    />
                    <Grid container>
                        <ActivitiesHeader
                            currentText="Find all invitations here"
                            heading="Invitations"
                        />
                        <Grid item xs={0} sm={0} md={6} lg={6} xl={6} sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block", xl: "block" } }}>
                            <TabMenu tabMenu={handleTabSwitch} item={["Vendor", "Connector"]} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ textAlign: "right" }}>
                            <Button variant="contained" onClick={onClickAdd} sx={{ marginTop: 1, marginRight: 3 }}><Add />Invite</Button>
                        </Grid>
                        {!empty ?
                            <>
                                {loader ?
                                    <>
                                        <Grid container sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 5 }}>
                                            <Grid item xs={12} sx={{ textAlign: "center" }}>
                                                <img src="assets/loader/Spinner-px.gif" height={"140vh"} />
                                                <Typography sx={{ paddingTop: 2 }}>
                                                    Please wait while we fetch all {firstCapital(userType)} invitations
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </>
                                    :
                                    <>
                                        <Grid item xs={12}>
                                            {rowData ?
                                                <>
                                                    <TableContainer
                                                        component={Paper}
                                                        sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block", xl: "block" } }}
                                                    >
                                                        <Table aria-label="simple table" size="small">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableHeadRow
                                                                        createSortHandler={createSortHandler}
                                                                        valueToOrderBy={valueToOrderBy}
                                                                        arr={[
                                                                            {
                                                                                name: 'NAME',
                                                                                alignment: 'left',
                                                                                valueToOrderBy: 'name',
                                                                                orderDirection: orderDirection,
                                                                            },
                                                                            {
                                                                                name: 'EMAIL',
                                                                                alignment: 'left',
                                                                                valueToOrderBy: 'email',
                                                                                orderDirection: orderDirection,
                                                                            },
                                                                            {
                                                                                name: 'SENT ON',
                                                                                alignment: 'left',
                                                                                valueToOrderBy: 'createdDatetime',
                                                                                orderDirection: orderDirection,
                                                                            },
                                                                            {
                                                                                name: 'STATUS',
                                                                                alignment: 'center',
                                                                                valueToOrderBy: false
                                                                            },
                                                                            {
                                                                                name: 'RESEND',
                                                                                alignment: 'center',
                                                                                valueToOrderBy: false,
                                                                            },
                                                                        ]}
                                                                    />
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {sortedRowInformation(rowData.slice(
                                                                    page * rowsPerPage,
                                                                    page * rowsPerPage + rowsPerPage
                                                                ), getComparator(orderDirection, valueToOrderBy)).map(
                                                                    (row: any, index: any) => {
                                                                        return (
                                                                            <TableRow
                                                                                key={row.id}
                                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                            >
                                                                                <TableCell align="left" sx={{ height: 70 }}>
                                                                                    <Typography>
                                                                                        {firstCapital(row.name)}
                                                                                    </Typography>
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <Typography
                                                                                        component="a"
                                                                                        href={`mailto:${row.email}`}
                                                                                        sx={{ textDecoration: 'none', color: '#00BFFF', '&:hover': { color: '#0000ff' } }}
                                                                                    >
                                                                                        {row.email}
                                                                                    </Typography>
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <Typography>
                                                                                        {convertDate(row.createdDatetime)}
                                                                                    </Typography>
                                                                                </TableCell>
                                                                                {/* <TableCell align="center"> */}
                                                                                <StatusCell
                                                                                    textClr={statusColorText[row.verified]}
                                                                                    bgClr={statusColor[row.verified]}
                                                                                    text={row.verified ? 'Verified' : 'Pending'}
                                                                                />
                                                                                {/* </TableCell> */}
                                                                                <TableCell align="center">
                                                                                    <IconButton
                                                                                        color="primary"
                                                                                        onClick={() => handleOpenResendDialog(row.id)}
                                                                                    >
                                                                                        <ReplayIcon />
                                                                                    </IconButton>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        );
                                                                    }
                                                                )}
                                                            </TableBody>
                                                        </Table>
                                                        <ConfirmationDialog
                                                            showConfirmationDialog={showResendDialog}
                                                            handleConfirmationDialogClose={handleCloseResendDialog}
                                                            confirmationRow={resendRow}
                                                            customContent='Do you want to re-send the mail to the user ?'
                                                            customConfirmText='Send'
                                                            customCancelText='Cancel'
                                                            handleInvitationSent={resendInvitation}
                                                        />
                                                    </TableContainer>
                                                    {(rowData) && (
                                                        <>
                                                            <Grid item xs={12} sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none", xl: "none" } }}>
                                                                <InvitationsCardComponent rowData={rowData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)} resendInvitation={resendInvitation} />
                                                            </Grid>
                                                            <TablePagination
                                                                rowsPerPageOptions={[5, 10, 15, 20]}
                                                                sx={{
                                                                    '.MuiTablePagination-toolbar': {
                                                                        alignItems: 'baseline',
                                                                    },
                                                                    marginBottom: 8,
                                                                    marginTop: 1
                                                                }}
                                                                component="div"
                                                                count={rowData.length}
                                                                rowsPerPage={rowsPerPage}
                                                                page={page}
                                                                onPageChange={handleChangePage}
                                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                            />
                                                        </>
                                                    )}
                                                </>
                                                : <>
                                                </>}
                                        </Grid>
                                    </>}
                            </>
                            :
                            <>
                                <Grid container sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 5 }}>
                                    <Grid item xs={12} sx={{ textAlign: "center", marginY: 5 }}>
                                        <img src="assets/loader/not_found.gif" height={"140vh"} />
                                        <Typography sx={{ fontWeight: 'bold' }}>
                                            No {firstCapital(userType)} invitations found
                                        </Typography>
                                        <Typography>
                                            Seems like you haven't invited any {firstCapital(userType)}s to ConnectorNet.<br />
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </>}
                    </Grid>
                </>
                :
                <Grid item xs={12} sx={{ marginTop: "10%", textAlign: "center" }}>
                    <ErrorComponent />
                </Grid>}
        </>
    )
}

export default Invitations