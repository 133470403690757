import { Dialog, Grid, Typography } from '@mui/material'
import React from 'react'

const FormLoader = (props:any) => {
    const {type} = props;
  return (
    <Dialog open={true} sx={{width:"100%", justifyContent:"center", height:"100%", backgroundColor:"transparent"}}>
        <Grid container>
        <Grid item xs={12} sx={{textAlign:"center", marginTop:5, padding:10, backgroundColor:"transparent"}}>
            <img src="assets/loader/Spinner-px.gif" height={"140vh"}/>
            <Typography sx={{paddingTop:2}}>Please wait while we fetch required data</Typography>
            </Grid>
        </Grid>
    </Dialog>
  )
}

export default FormLoader