import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Card,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    Grid,
    Tooltip,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DealTextFields from './DealTextFields';
import Connector from './RegistrationFormScreens/Connector';
import Product from './RegistrationFormScreens/Product';
import Customer from './RegistrationFormScreens/Customer';
import axios from 'axios';
import { useFormik } from 'formik';
import * as yup from 'yup';

const VendorDealForm = (props: any) => {

    const { showDialog, registrationData, handleClose, formData, setFormData, user } = props;
    const style = { padding: 1, marginBottom: 1, boxShadow: 0, paddingX: 3, paddingTop: 2 };
    const [selectedOption, setSelectedOption] = useState(null);
    const [saveLoader, setSaveLoader] = useState(false);
    const[amountValidation, setAmountValidation] = useState({error: false, message:""})
    const infoCompponent = () => {
        return (
            <>
                <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                    Please follow the following steps to get started with the
                    vendor application process:
                </Typography>
                <br />
                <Typography sx={{ fontSize: 14, color: '', textShadow: 3 }}>
                    1. Check the name of the deal.
                    <br />
                    2.Enter the amount to associate with the deal.
                    <br />
                    3.Select a tag, to associate with the deal.
                    <br />
                    4.Review and ensure the Connector, Product and Customer
                    details are as intended.
                    <br />
                    5.Click on save to start the new deal.
                </Typography>
            </>
        );
    };
    const asterisk = () => {
        return(<><Typography sx={{color:"#00BFFF", display:"inline", fontSize:"20px", fontWeight:"bold"}}>*</Typography></>)
    }
    const tags = [
        { id: 0, name: 'devices' },
        { id: 1, name: 'telehealth' },
        { id: 2, name: 'medicines' },
        { id: 3, name: 'radiology' },
        { id: 4, name: 'physician' },
        { id: 5, name: 'cardiology' }
    ];
    const handleTag = (event: any, newVal: any) => {
        setFormData({ ...formData, type: newVal.name })
        
    }
    const getOptions = (option: any) => {
        if (option) {
            return option.name;
        }
        return selectedOption || '';
    };
    const validationSchema = yup.object({
        dealName: yup
            .string()
            .min(4,"Name should contain 4 characters at least")
            .matches(/^[A-Za-z0-9 -]*$/, 'Please enter valid name, can contain "-" only')
            .required('Name is required'),
        description: yup
            .string()
            .min(10, "Description must be 10 characters long")
            .max(200, "Description shouldn't exceed 200 characters")
      });

      const formik = useFormik({
        initialValues: {
            dealName: '',
            description:''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            
            handleSave(values)
        }
      });
      useEffect(() => {
        if(registrationData){
            
            formik.setValues({...formik.values, dealName: formData.name})
        }
    }, [formData])
    const inputHandler = (e: any) => {
        setFormData({ ...formData, [e.target.id]: e.target.value })
    }
    const amountHandler = (e: any) => {
        setFormData({ ...formData, amount: Number(e.target.value) })
        if(Number(e.target.value) <1){
            setAmountValidation({...amountValidation, error: true, message: "The deal amount must be greater than 0"})
        }
        else if(Number(e.target.value) > 10000000){
            setAmountValidation({...amountValidation, error: true, message: "The deal amount cannot be greater than 10 Millions"})
        }
        else{
            setAmountValidation({...amountValidation, error: false, message: ""})
        }
    }
    useEffect(() => {
        setFormData({...formData, name: formik.values.dealName, description: formik.values.description})
    },[formik.values.dealName, formik.values.description])

    const handleSave = (values:any) => {
        if(amountValidation.error || formData.amount === 0){
            setAmountValidation({...amountValidation, error: true, message: "A valid amount is required"})
        }
        else{
            if(!saveLoader){
                setSaveLoader(true)
                axios.post(process.env.REACT_APP_BASE_URL + 'deals/register', formData,
                {
                  headers: {
                      "Authorization": "Bearer "+user.accessToken,
                  }
                })
                .catch((error: any) => {
                    alert("There was some error in registering the deal, please try again")
                    setSaveLoader(false)
                })
                .then((response: any) => {
                    handleClose(response.data);
                    setSaveLoader(false)
                });
            }
        }
    };

    return (
        <form onSubmit={formik.handleSubmit}>
        <Dialog
            open={showDialog}
            fullWidth
            maxWidth="lg"
            sx={{
                '.MuiDialog-paper': {
                    position: 'absolute',
                    width: '90%',
                    height: '700px',
                    minWidth: '90%',
                },
            }}
        >
            <Alert
                icon={false}
                severity="info"
                sx={{
                    justifyContent: 'center',
                }}
            >
                <AlertTitle>
                    <Typography
                        variant="h4"
                        sx={{ marginTop: 1, fontWeight: 'bold' }}
                    >
                        New Deal
                        <Tooltip
                            title={infoCompponent()}
                            arrow
                            placement="right"
                        >
                            <Button>
                                <InfoOutlinedIcon />
                            </Button>
                        </Tooltip>
                    </Typography>
                </AlertTitle>
            </Alert>
            <DialogContent>
                {registrationData?
                <>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography
                                        sx={{fontWeight: 'bold',fontSize: '20px',}}
                                    >
                                        Start a new deal as
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Card sx={{ padding: 1, boxShadow: 0,backgroundColor: '#F0F0F0',}}>
                                        <Typography variant="subtitle2">
                                            {registrationData.vendorId.name}
                                        </Typography>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ marginY: 2 }}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Typography sx={{fontWeight: 'bold',fontSize: '20px',}}>
                                        Connector
                                    </Typography>
                                    <Card sx={{ padding: 1, boxShadow: 0,backgroundColor: '#F0F0F0',}}>
                                        <Typography variant="subtitle2">
                                            {registrationData.connectorId.name}
                                        </Typography>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Box sx={style}>
                                        <Connector currentSelection={registrationData.connectorId} cardConnector={true} />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ marginY: 2 }}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Typography sx={{fontWeight: 'bold',fontSize: '20px',}}>
                                        Product
                                    </Typography>
                                    <Card sx={{ padding: 1, boxShadow: 0,backgroundColor: '#F0F0F0',}}>
                                        <Typography variant="subtitle2">
                                            {registrationData.productId.name}
                                        </Typography>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Box sx={style}>
                                        <Product currentSelection={registrationData.productId} cardProduct={true}/>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ marginY: 2 }}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Typography sx={{fontWeight: 'bold',fontSize: '20px',}}>
                                        Customer
                                    </Typography>
                                    <Card sx={{ padding: 1, boxShadow: 0,backgroundColor: '#F0F0F0',}}>
                                        <Typography variant="subtitle2">
                                            {registrationData.customerId.name}
                                        </Typography>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Box sx={style}>
                                        <Customer currentSelection={registrationData.customerId} cardCustomer={true}/>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ marginY: 2 }}>
                            <Divider />
                        </Grid>
                        <DealTextFields 
                            tags={tags} 
                            handleTag={handleTag} 
                            getOptions={getOptions} 
                            formData={formData} 
                            inputHandler={inputHandler} 
                            amountHandler={amountHandler} 
                            formik={formik} 
                            amountValidation={amountValidation} 
                            asterisk={asterisk}
                        />
                    </Grid>
                </>:<></>}
            </DialogContent>
            <DialogActions sx={{ paddingRight: 5, paddingY: 2 }}>
                <Button
                    onClick={handleClose}
                    variant="contained"
                    color="error"
                    sx={{ width: 110, height:50, padding: 1 }}
                    disabled={saveLoader}
                >
                    cancel
                </Button>
                <Button type="submit" onClick={(e) => formik.handleSubmit()} sx={{ width: 110, height:50, padding: 1 }} variant="contained">
                    {saveLoader?<><CircularProgress sx={{color:"white"}}/></>:<>Save</>}
                </Button>
            </DialogActions>
        </Dialog>
        </form>
    );
};

export default VendorDealForm;
