import { Divider, Grid, Typography } from '@mui/material';
import React from 'react'
import { convertNumber } from '../../Admin/Approvals/util';

const Customer = (props:any) => {
  const {currentSelection, cardCustomer} = props;
  const style = {padding:1, marginBottom:1 ,boxShadow:0, backgroundColor:"#F0F0F0", minHeight: 195};
  const createLink = (link:any) => {
    const mail = "mailto:"+link+"?body=New mail to customer";
    return(mail)
  }
  return (
    <>
        <Grid container spacing={2}>
          <Grid container sx={style}>
          <Grid item xs={12} mb={1}>
          <Typography sx={{fontWeight:"bold", paddingLeft:1, fontSize:"20px"}}>Customer Details</Typography>
          </Grid>
          <Grid container sx={{paddingX: 1}}>
            {cardCustomer?<>
              {currentSelection && 
              <>
                <Grid item xs={12} mb={1}>
                  <Typography sx={{fontWeight:"bold"}}>{currentSelection.name}</Typography>
                </Grid>
                <Grid item xs={12} mb={1}>
                <Typography>{currentSelection.firstName} {currentSelection.lastName}</Typography>
                </Grid>
                <Grid item xs={12} mb={1}>
                {currentSelection.email?
                      <Typography sx={{fontWeight:"light"}}><a href={createLink(currentSelection.email)}>{currentSelection.email}</a></Typography>
                    :
                      <Typography sx={{fontWeight:"light"}} >No customer email available</Typography>
                    }
                </Grid>
                <Grid item xs={12} mb={1}>
                {currentSelection.phoneNumber1? 
                      <Typography sx={{fontWeight:"light"}}>{convertNumber(currentSelection.phoneNumber1)}</Typography>
                    :
                      <Typography sx={{fontWeight:"light"}} >No customer contact number available</Typography>
                    }
                </Grid>
                <Grid item xs={12} mb={1}>
                {currentSelection.phoneNumber2? 
                      <Typography sx={{fontWeight:"light"}}>{convertNumber(currentSelection.phoneNumber2)}</Typography>
                    :
                      <Typography sx={{fontWeight:"light"}} >No alternate contact number available</Typography>
                    }
                </Grid>
                <Grid item xs={12}>
                {currentSelection.description? 
                      <Typography sx={{fontWeight:"light", minHeight:40, maxHeight:40}}>{currentSelection.description}</Typography>
                    :
                      <Typography sx={{fontWeight:"light"}} >No description available</Typography>
                    }
                </Grid>
              </>}
            </>
            :
            <>
              <Grid item xs={12} mb={1} height={130}>
                <Typography>Please select a customer</Typography>
              </Grid>
            </>}
          {/* <Grid xs={12} sx={{ marginTop: 2 }}>
            <Divider />
          </Grid> */}
          </Grid>
          </Grid>
        </Grid>
    </>
  )
}

export default Customer