import { IconButton, Toolbar, Tooltip, Typography } from "@mui/material";
import { alpha } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from "react";

export default function EnhancedTableToolbar(props: any) {
    let { numSelected,deletingCols,selectedUpdate,deletableUpdate } = props;
    const [visible,setVisible] = useState(true);
    const handleColsDelete = () => {
        deletingCols();
    }
    const handleCloseToolbar = () => {
        setVisible(false);
        selectedUpdate([]);
        deletableUpdate();
    }
    return (
        <>
        {visible ? <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: theme =>
                        alpha(
                            theme.palette.primary.main,
                            theme.palette.action.activatedOpacity
                        ),
                }),
            }}
        >
            {numSelected > 0 ? (
                    <Tooltip title="Unselect">
                        <IconButton onClick={handleCloseToolbar}>
                            <CloseIcon color="inherit"/>
                        </IconButton>
                    </Tooltip>
            ) : (
                <></>
            )}
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="primary"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} item selected
                </Typography>
            ) : (
                <></>
            )}
            {numSelected > 0 ? (
                    <Tooltip title="Delete">
                        <IconButton onClick={handleColsDelete}>
                            <DeleteIcon color="error"/>
                        </IconButton>
                    </Tooltip>
            ) : (
                <></>
            )}
        </Toolbar> : <></>}
        </>
    );
}