import * as React from 'react';
import {
    CreateButton,
    useGetIdentity,
} from 'react-admin';
import { matchPath, useLocation } from 'react-router';
import { DealListContent } from './DealListContent';
import { DealCreate } from './DealCreate';
import { Grid } from '@mui/material';
import SearchTextField from '../Layout/SearchTextField';
import { useEffect, useState } from 'react';

export const DealList = (props: any) => {
    const { dealData, empty, onRedirect, showProfile, user, setDealData } = props;
    const [dealProps, setDealProps] = useState(dealData);
    const [dealSearch, setDealSearch] = useState("");
    const location = useLocation();
    const matchCreate = matchPath('/admin/deals/create', location.pathname);
    useEffect(() => {
        setDealProps(dealData)
    }, [dealData])
    useEffect(() => {
        var tempArr = [...dealData];
        if (dealSearch.trim().length > 0) {
            tempArr = tempArr.filter(row => {
                return row.name
                    .toLowerCase()
                    .includes(dealSearch.trim().toLowerCase());
            });
        }
        setDealData(tempArr);
        setDealProps(tempArr);
    }, [dealSearch])
    return (
        <>
            <Grid container sx={{marginY:2}}>
                <Grid item xs={6} sx={{textAlign:"left"}}>
                    {!empty && <SearchTextField val={dealSearch} funcHandle={setDealSearch} type={"Deal"} />}
                </Grid>
                <Grid item xs={6} sx={{textAlign:"right", paddingTop:1}}>
                    {user && <>
                        {user!.role === "vendor" && <CreateButton
                        variant="contained"
                        label="New Deal"
                        sx={{ marginLeft: 2 }}
                    />}
                </>}
                </Grid>
            </Grid>
            <DealListContent user={user} dealProps={dealProps} empty={empty} onRedirect={onRedirect} showProfile={showProfile}/>
            <DealCreate open={!!matchCreate} user={user}/>
        </>
    );
};
