import React,{ useEffect, useState } from 'react'
import './styles.css';
import { Button, Typography } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Navbar from './Navbar';


const HomePage = () => {
  return (
    <>
        <div id="layoutDefault">
            <div id="layoutDefault_content">
                <main>
                    <Navbar/>
                    <header className="page-header-ui page-header-ui-dark bg-gradient-primary-to-secondary">
                        <div className="page-header-ui-content pt-lg-10 pt-sm-2">
                            <div className="container px-5">
                                <div className="row gx-5 align-items-center">
                                    <div className=" col-sm-12 col-md-12 col-xl-6 col-lg-6 d-none d-sm-block d-md-block d-lg-block d-xl-block px-sm-5 px-md-10 px-lg-10" data-aos="fade-up" data-aos-delay="100"><img className="img-fluid" src="assets/charts/ConnectorNet-Diagram-Light.png" /></div>
                                    <div className="col-lg-6" data-aos="fade-up">
                                        <h1 className="page-header-ui-title pt-sm-5">The Power of Connection</h1>
                                        <p className="page-header-ui-text mb-5">Connectornet is an invite-only network designed to facilitate meaningful connections between trusted vendor-partners and top-level healthcare executives, with the aim of fostering mutually advantageous business partnerships.</p>
                                            {/* <Button variant="contained" sx={{width:"160px",height:"45px", backgroundColor:"#00ba94"}} onClick={() => loginWithRedirect()}>Connect today</Button> */}
                                            <a className="btn-home-link fw-500 text-decoration-none" target="_blank" href="https://share.hsforms.com/1GqoF_88bSlaVpba1yDYDqgekab8"><Button variant="contained" sx={{width:"160px",height:"45px", backgroundColor:"#00ba94", marginLeft: "10px"}} >Connect Today</Button></a>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="svg-border-rounded text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor"><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path></svg>
                        </div>
                    </header>
                    <section className="bg-white py-10">
                        <div className="container px-5">
                            <div className="row gx-5 text-center">
                                <div className="col-lg-4 mb-5 mb-lg-0">
                                    <div className="icon-stack icon-stack-xl text-white mb-4"><img className="img-fluid" src="assets/icons/Partner_Icon.png" /></div>
                                    <h3>Partners</h3>
                                    <p className="mb-0">Our partners are a group of trusted vendors who consistently deliver superior products and services. They are selected based on their experience, expertise, and commitment to quality.</p>
                                </div>
                                <div className="col-lg-4 mb-5 mb-lg-0">
                                    <div className="icon-stack icon-stack-xl text-white mb-4"><img className="img-fluid" src="assets/icons/Connector_Icon.png" /></div>
                                    <h3>Connectors</h3>
                                    <p className="mb-0">Our connectors understand the power of building strong business networks, and the potential for meaningful introductions to create mutual value, both for themselves and for others.</p>
                                </div>
                                <div className="col-lg-4">
                                    <div className="icon-stack icon-stack-xl text-white mb-4"><img className="img-fluid" src="assets/icons/Client_Icon.png" /></div>
                                    <h3>Clients</h3>
                                    <p className="mb-0">Our clients are industry-leading healthcare providers who require reliable vendors and cutting-edge technology solutions to meet their business needs and drive innovation. </p>
                                </div>
                            </div>
                        </div>
                        <div className="svg-border-rounded text-light">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor"><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path></svg>
                        </div>
                    </section>
                    <section className="bg-light py-10">
                        <div className="container px-8">
                            <div className="row gx-8 align-items-center justify-content-center">
                                <div className='col-md-12 col-lg-12 text-center mb-lg-4'>
                                    <Typography sx={{fontWeight:"bold", fontSize:34, color:"#5998D2", fontStyle:"italic"}}>Connect People. Catalyze Partnerships. Generate Value.</Typography>
                                </div>
                                <div className="col-md-12 col-lg-12 order-lg-0" data-aos="fade-right">
                                    <div className="content-skewed content-skewed-right text-center"><img className="img-fluid " src="assets/images/handshake.png" alt="..." /></div>
                                </div>
                            </div>
                        </div>
                        <div className="svg-border-rounded text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor"><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path></svg>
                        </div>
                    </section>
                    <section className="pb-10 bg-white">
                        <div className="container px-8">
                            <div className="row gx-8 align-items-center justify-content-center">
                                <div className="col-lg-10 order-0 order-lg-1 mb-5 mb-lg-0 text-align-center" data-aos="fade-left">
                                    <div className="my-5 text-center">
                                        <Typography sx={{fontWeight:"bold", fontSize:28, color:"#5998D2",fontStyle:"italic"}}>The Net Advantage</Typography>
                                    </div>
                                    <div className="my-3 text-center">
                                        <Typography sx={{fontStyle:"italic"}}>
                                        With ConnectorNet, meaningful connections drive mutually beneficial partnerships and help achieve business goals for both connectors and partners.
                                        </Typography>
                                    </div>
                                    <div className="row gx-5">
                                        <div className="col-md-6 mb-4">
                                            <Typography sx={{fontWeight:"bold", fontSize:28, color:"#5998D2",fontStyle:"italic"}}>For Connectors :</Typography>
                                            <ul>
                                                <li>
                                                    Leverage existing contacts to create new business opportunities
                                                </li>
                                                <li>
                                                    Increased earning potential through commission-based deals
                                                </li>
                                                <li>
                                                    Access to a select group of trusted vendor-partners
                                                </li>
                                                <li>
                                                    Streamlined deal tracking and contact management through the ConnectorNet portal
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-md-6 mb-4">
                                            <Typography sx={{fontWeight:"bold", fontSize:28, color:"#5998D2",fontStyle:"italic"}}>For Partners :</Typography>
                                            <ul>
                                                <li>
                                                    Exposure to potential clients seeking the right products and services to meet their business needs
                                                </li>
                                                <li>
                                                    Access to a select group of trusted connectors with established networks in the healthcare industry
                                                </li>
                                                <li>
                                                    Streamlined deal tracking and management through the ConnectorNet portal
                                                </li>
                                                <li>
                                                    Increased efficiency in the vendor-client connection process
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                  
                    <section className="bg-light">

                        <div className="svg-border-rounded text-dark">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor"><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path></svg>
                        </div>
                    </section>
                    <section className="bg-dark py-10">
                        <div className="container px-5">
                            
                            <div className="row gx-5 justify-content-center text-center">
                                <div className="col-lg-8">
                                    <h2 className="text-white">Save time with ConnectorNet</h2>
                                    <p className="lead text-white-50 mb-5">Robust and flexible platform to manage contacts and relationships and track registrations and payouts all in one place!</p>
                                    <a className="btn-home-link fw-500 text-decoration-none" target="_blank" href="https://share.hsforms.com/1GqoF_88bSlaVpba1yDYDqgekab8"><button className="btn btn-teal fw-500">Join ConnectorNet</button></a>
                                </div>
                            </div>
                        </div>
                    </section>
                    <hr className="m-0" />
                </main>
            </div>
        </div>
    </>
  )
}

export default HomePage