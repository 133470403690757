import Navbar from "./Navbar"

const PartnerPage = () => {
    return (<div id="layoutDefault">
        <div id="layoutDefault_content">
            <main>
                <Navbar />
                <header className="page-header-ui page-header-ui-dark bg-gradient-primary-to-secondary">
                        <div className="page-header-ui-content pt-10">
                            <div className="container px-5 text-center">
                                <div className="row gx-5 justify-content-center">
                                    <div className="col-lg-8">
                                        <h1 className="page-header-ui-title mb-3">About our company</h1>
                                        <p className="page-header-ui-text">We recognize the importance of strong relationships in the healthcare industry, and are dedicated to helping our partners uncover new opportunities through the power of connection. By partnering with ConnectorNet, you'll have the support and resources you need to succeed in this ever-evolving industry.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="svg-border-rounded text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor"><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path></svg>
                        </div>
                    </header>
                    <section className="bg-white py-10">
                        <div className="container px-5">
                            <div className="row gx-5 justify-content-center">
                                <div className="col-lg-10">
                                    <h4 className="mb-4 d-flex align-items-center">
                                        <div className="icon-stack bg-primary text-white me-2"><i data-feather="arrow-right"></i></div>
                                        Who are ConnectorNet's partners? 
                                    </h4>
                                    <p className="lead">Our partners are a group of carefully selected, trusted vendors who consistently deliver superior products and services to the healthcare industry. They are the best in the business, and selected based on their experience, expertise, and commitment to quality.</p>
                                    
                                    <hr className="my-5" />
                                    <h4 className="mb-4 d-flex align-items-center">
                                        <div className="icon-stack bg-primary text-white me-2"><i data-feather="arrow-right"></i></div>
                                        What benefits do I get as a partner of ConnectorNet?
                                    </h4>
                                    <p className="lead">As a partner, you'll have access to respected industry connectors who have established relationships with potential clients. With their help, you can seek meaningful introductions to potential clients and increase your chances of closing lucrative business deals.</p>
                                    
                                    <hr className="my-5" />
                                    <h4 className="mb-4 d-flex align-items-center">
                                        <div className="icon-stack bg-primary text-white me-2"><i data-feather="arrow-right"></i></div>
                                        How does ConnectorNet choose its partners?
                                    </h4>
                                    <p className="lead">We carefully evaluate potential partners based on their experience, expertise, and commitment to quality. Our goal is to bring together the best vendors in the healthcare industry to provide clients with superior products and services.</p>

                                    <hr className="my-5" />
                                    <h4 className="mb-4 d-flex align-items-center">
                                        <div className="icon-stack bg-primary text-white me-2"><i data-feather="arrow-right"></i></div>
                                        Can I become a ConnectorNet partner?
                                    </h4>
                                    <p className="lead">Yes, we are always looking for new partners who share our commitment to quality and excellence in the healthcare industry. If you are interested in becoming a partner, please apply, and submit relevant supporting materials.</p>
                                    
                                    <hr className="my-5" />
                                    <h4 className="mb-4 d-flex align-items-center">
                                        <div className="icon-stack bg-primary text-white me-2"><i data-feather="arrow-right"></i></div>
                                        How does it work?

                                    </h4>
                                    <p className="lead">
                                        <ol>
                                            <li>Complete your Connectornet partner profile  </li>
                                            <li>Submit business information for partner approval process </li>
                                            <li>Complete legal agreements</li>
                                            <li>Begin onboarding process</li>
                                            <li>Enjoy the benefits of ConnectorNet!   </li>
                                        </ol>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
            </main>
        </div>
    </div>)
}
export default PartnerPage