import {
    Alert,
    AlertTitle,
    Autocomplete,
    Button,
    Card,
    CardMedia,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MuiPhoneNumber from 'material-ui-phone-number';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';

const ContactForm = (props: any) => {
    const {
        user,
        showDialog,
        onClose,
        onChange,
        handlePhoneChange,
        addContactLoader,
        userInfo,
        formik,
        phoneValidate,
        validatePhone,
        editForm,
        rowState,
        setRowState,
    } = props;
    const [companyList, setCompanyList] = useState<any>();
    useEffect(() =>{
        if(user){
            axios.get(process.env.REACT_APP_BASE_URL + "company",
            {
            headers: {
                "Authorization": "Bearer "+user.accessToken,
            }
            })
                .catch((error) => {
                    
                })
                .then((response: any) => {
                    
                    setCompanyList(response.data);
                })
            }
    },[user])
    const getOptionsCompanies = (option: any) => {
        if (option) {
            if(option.name)
                return (option.name);
            return '';
        }
        return '';
    };
    // const getCompanyValue = (id:any) => {
    //     let ind = -1
    //     if (companyList) {
    //         for (let i = 0; i < companyList.length; i++) {
    //             ind = companyList.findIndex((ele: any) => ele.id == id)
    //         }
    //         if(ind!=-1) return companyList[ind].name
    //     }
    //     return '';
    // }
    const handleCompanyChange = (event: any, newVal: any) => {
        
        if(newVal)
            setRowState({ ...rowState, companyId: {id : newVal.id} });
    };

    return (
        <form>
            <Dialog
                open={showDialog}
                onClose={onClose}
                fullWidth
                maxWidth="sm"
                sx={{
                    '.MuiDialog-paper': {
                        position: 'absolute',
                        width: '85%',
                        height: '600px',
                        minWidth: '85%',
                    },
                }}
            >
                <DialogTitle
                    sx={{
                        textAlign: 'center',
                        fontWeight: 'bold',
                        backgroundColor: '#F0F7FF',
                        fontSize: '26px',
                    }}
                >
                    {' '}
                    {editForm ? 'Edit Contact' : 'Add Contact'}
                    <IconButton onClick={onClose} sx={{ float: 'right' }}>
                        <CloseIcon color="error" />
                    </IconButton>
                </DialogTitle>

                {userInfo ? (
                    <>
                        <DialogContent>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} mt={1}>
                                    <CardMedia
                                        component="img"
                                        image={'assets/icons/userIcon.png'}
                                        alt="Connected People"
                                        sx={{
                                            height: '10rem',
                                            objectFit: 'contain',
                                            textAlign: 'center',
                                            margin: 'auto',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Typography
                                        sx={{
                                            fontWeight: 'bold',
                                            fontSize: '20px',
                                        }}
                                    >
                                        First Name{' '}
                                        <span style={{ color: '#00BFFF' }}>
                                            *
                                        </span>
                                    </Typography>
                                    <TextField
                                        variant="outlined"
                                        sx={{ width: '100%' }}
                                        type="text"
                                        placeholder="First Name"
                                        id="firstName"
                                        name="firstName"
                                        value={formik.values.firstName}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={
                                            formik.touched.firstName &&
                                            Boolean(formik.errors.firstName)
                                        }
                                        helperText={
                                            formik.touched.firstName &&
                                            formik.errors.firstName
                                        }
                                    />
                                    <Typography
                                        sx={{
                                            fontWeight: 'bold',
                                            fontSize: '20px',
                                        }}
                                    >
                                        Last Name{' '}
                                        <span style={{ color: '#00BFFF' }}>
                                            *
                                        </span>
                                    </Typography>
                                    <TextField
                                        variant="outlined"
                                        sx={{ width: '100%' }}
                                        type="text"
                                        placeholder="Last Name"
                                        name="lastName"
                                        id="lastName"
                                        value={formik.values.lastName}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={
                                            formik.touched.lastName &&
                                            Boolean(formik.errors.lastName)
                                        }
                                        helperText={
                                            formik.touched.lastName &&
                                            formik.errors.lastName
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Typography
                                        sx={{
                                            fontWeight: 'bold',
                                            fontSize: '20px',
                                        }}
                                    >
                                        Email{' '}
                                        <span style={{ color: '#00BFFF' }}>
                                            *
                                        </span>
                                    </Typography>

                                    {editForm ? (
                                        <Card
                                            sx={{
                                                padding: 1,
                                                boxShadow: 0,
                                                backgroundColor: '#F0F0F0',
                                            }}
                                        >
                                            <Typography variant="subtitle2">
                                                {formik.values.email}
                                            </Typography>
                                        </Card>
                                    ) : (
                                        <TextField
                                            variant="outlined"
                                            sx={{ width: '100%' }}
                                            type="text"
                                            placeholder="Email"
                                            id="email"
                                            name="email"
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={
                                                formik.touched.email &&
                                                Boolean(formik.errors.email)
                                            }
                                            helperText={
                                                formik.touched.email &&
                                                formik.errors.email
                                            }
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Typography
                                        sx={{
                                            fontWeight: 'bold',
                                            fontSize: '20px',
                                        }}
                                    >
                                        Phone{' '}
                                        <span style={{ color: '#00BFFF' }}>
                                            *
                                        </span>
                                    </Typography>

                                    <MuiPhoneNumber
                                        defaultCountry={'us'}
                                        onChange={handlePhoneChange}
                                        error={phoneValidate.phoneError}
                                        helperText={phoneValidate.phoneText}
                                        variant="outlined"
                                        value={
                                            editForm
                                                ? rowState.phoneNumber1
                                                : ''
                                        }
                                        fullWidth
                                        placeholder="Phone Number"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Typography
                                        sx={{
                                            fontWeight: 'bold',
                                            fontSize: '20px',
                                        }}
                                    >
                                        Designation
                                    </Typography>

                                    <Autocomplete
                                        id="designation"
                                        options={[
                                            'CEO',
                                            'CTO',
                                            'CMO',
                                            'COO',
                                            'CFO',
                                            'CIO',
                                            'Employee',
                                        ]}
                                        value={
                                            rowState.designation
                                                ? rowState.designation
                                                : null
                                        }
                                        onChange={(event, newValue: any) => {
                                            
                                            setRowState({
                                                ...rowState,
                                                designation: newValue,
                                            });
                                        }}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                type="text"
                                                placeholder="Designation"
                                                fullWidth
                                                variant="standard"
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Typography
                                        sx={{
                                            fontWeight: 'bold',
                                            fontSize: '20px',
                                        }}
                                    >
                                        Organization{' '}
                                        <span style={{ color: '#00BFFF' }}>
                                            *
                                        </span>
                                    </Typography>
                                    <Autocomplete
                                        id="companyId"
                                        // options={(companyList && companyList.length > 0) ? companyList : []}
                                        options = {[]}
                                        getOptionLabel={getOptionsCompanies}
                                        disabled
                                        value={rowState.companyId ? rowState.companyId.name: ''}
                                        // onChange={handleCompanyChange}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                type="text"
                                                placeholder={userInfo.name}
                                                fullWidth
                                                variant="standard"
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={onClose}
                                variant="contained"
                                color="error"
                                disabled={addContactLoader}
                                sx={{ width: 80, height: 40, padding: 1 }}
                            >
                                cancel
                            </Button>
                            <Button
                                onClick={e => {
                                    validatePhone();
                                    formik.handleSubmit(e);
                                }}
                                sx={{ width: 80, height: 40, padding: 1 }}
                                variant="contained"
                                type="submit"
                            >
                                {addContactLoader ? (
                                    <CircularProgress sx={{ color: 'white' }} />
                                ) : (
                                    'SAVE'
                                )}
                            </Button>
                        </DialogActions>
                    </>
                ) : (
                    <DialogContent style={{ overflow: 'hidden' }}>
                        <Grid
                            container
                            sx={{
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Grid item>
                                <CircularProgress />
                            </Grid>
                        </Grid>
                    </DialogContent>
                )}
            </Dialog>
        </form>
    );
};

export default ContactForm;
