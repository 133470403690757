import { Alert, Grid, Typography, Tooltip, Box } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Button } from "react-admin"
import { firstCapital } from "../Admin/Approvals/util"
import ConnectorProfile from "../Profile Screens/ConnectorProfile"
import VendorProfile from './../Profile Screens/VendorProfile';

const EditComponent = (props: any) => {
    const { selectionType, user, setEditable, currentEditSelection, setCurrentEditSelection } = props
    const handleClose = () => {
        setEditable(false)
        setCurrentEditSelection(null)
    }
    const getComponent = () => {
        switch (selectionType) {
            case 'connector':
                return <ConnectorProfile editTable={true} user={user} setCurrentSelection={setCurrentEditSelection} currentSelection={currentEditSelection} setShowDialog={setEditable} />
            case 'vendor':
                return <VendorProfile editTable={true} user={user} setCurrentSelection={setCurrentEditSelection} currentSelection={currentEditSelection} setShowDialog={setEditable} />

        }
    }
    return (<>

        <Alert sx={{ padding: 0, width: '100%', backgroundColor: "#e5f6fd", '.MuiAlert-message': { width: '100%' }, '.MuiAlert-icon': { display: 'none' } }}>
            <Grid container sx={{ padding: 2, width: '100%' }}>
                <Grid item xs={11.8} sx={{ textAlign: "center" }}>
                    <Typography variant="h4" align="center" sx={{ marginTop: 1, fontWeight: 'bold', width: "100%", fontSize: { xs: "large", sm: "large", md: "34px", lg: "34px", xl: "34px" } }}>
                        {firstCapital(selectionType)} Details
                        <Tooltip
                            title={`Find ${firstCapital(selectionType)} Details here`}
                            arrow
                            placement="right-end"
                        >
                            <Button>
                                <InfoOutlinedIcon />
                            </Button>
                        </Tooltip>
                    </Typography>
                </Grid>
                <Grid item xs={0.2} sx={{ alignText: "end", justifyContent: "right" }}>
                    <Box display="flex"><CloseIcon sx={{ marginTop: 1, fontWeight: "bold", color: "red", "&:hover": { cursor: "pointer" } }} onClick={handleClose} /></Box>
                </Grid>
            </Grid>
        </Alert>
        {getComponent()}

    </>)
}

export default EditComponent