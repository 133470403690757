import { Box, Button, CircularProgress, Dialog, DialogActions, Card, CardContent, CardActions, CardMedia, DialogContent, DialogTitle, Divider, Grid, IconButton, TextField, Tooltip, Typography, InputAdornment } from '@mui/material';
import React, { useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import MuiPhoneNumber from 'material-ui-phone-number';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import axios from "axios";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import AddressAutocomplete from '../../profile/AddressAutocomplete';
import { styled, useTheme } from "@mui/system";
import { Theme } from '@mui/system';

const AddConnectorVendorForm = (props: any) => {
    const { showDialog, handleClose, saveLoader, setSaveLoader, formik, userType, phone, setPhone, phoneValidation, setPhoneValidation, checkPhoneValidation, handlePhoneChange, isAddressValidated, setIsAddressValidated, formData, setFormData } = props;


    const [loading, setLoading] = useState(false);
    const [phone2, setPhone2] = useState<string>('');

    const [error, setError] = useState(false);
    const [editable, setEditable] = useState(false);
    const [disableFields, setDisableFields] = useState(false);
    const [showAddressSuggestion, setShowAddressSuggestion] = useState("");
    const [disableSave, setDisableSave] = useState(false);
    const [phoneValidation2, setPhoneValidation2] = useState({ error: false, message: "" })



    const initialError = {
        address: false,
        addressMessage: "",
        addressLine2: false,
        zipcode: false,
        zipcodeMessage: "",
        state: false,
        stateMessage: "",
        city: false,
        cityMessage: "",
        street: false,
        streetMessage: "",
        country: false,
        countryMessage: ""
    }
    const [formValidation, setFormValidation] = useState(initialError)
    const [validatedData, setValidatedData] = useState<any>();

    const [selectedMfaType, setSelectedMfaType] = useState('');



    // Add this state variable at the beginning of your component
    const [mfaTypeSelected, setMfaTypeSelected] = useState(false);

    // Update the handleMfaTypeChange function
    const handleMfaTypeChange = (event: any) => {
        setSelectedMfaType(event.target.value);
        setMfaTypeSelected(true);

        formik.setFieldValue('mfa', event.target.value === 'sms' ? 'SMS_OTP' : 'SOFTWARE_TOKEN');
    };

    const handleWebsiteBlur = () => {

        const websiteUrl = formik.values.website;


        if (websiteUrl.toLowerCase().startsWith('https://')) {

            formik.setFieldError('website', 'Please enter a valid website without "https://"');
            return;
        }
        const domain = extractDomain(formik.values.website);

        if (domain) {
            setLoading(true);
            axios
                .get(`https://api.api-ninjas.com/v1/dnslookup?domain=${domain}`, {
                    headers: {
                        'X-Api-Key': process.env.REACT_APP_DOMAIN_VERIFIER_API_KEY,
                    },
                })
                .then((response) => {
                    if (response.data && response.data.length > 0) {
                        formik.setFieldError('website', '');
                    } else {
                        formik.setFieldError('website', 'Invalid website or domain name');
                    }
                })
                .catch((error) => {
                    formik.setFieldError('website', 'Error making API call');
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }
    function extractDomain(link: string): string | null {
        const match = link.match(/^(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9-]+(?:\.[a-zA-Z]+)+)(?:\/.*)?$/);
        return match ? match[1] : null;
    }


    const checkValidation = (types: any) => {
        if (types === "street_number") {
            setFormValidation({ ...formValidation, address: true, addressMessage: "Please enter a valid street number" });
        }
        else if (types === "subpremise") {
            setFormValidation({ ...formValidation, street: true, streetMessage: "Please enter a valid street number" });
        }
        else if (types === "locality") {
            setFormValidation({ ...formValidation, city: true, cityMessage: "Please Enter a valid city name" });
        }
        else if (types === "administrative_area_level_1") {
            setFormValidation({ ...formValidation, state: true, stateMessage: "Please enter a valid state" });
        }
        else if (types === "postal_code" || types === "postal_code_suffix") {
            setFormValidation({ ...formValidation, zipcode: true, zipcodeMessage: "Please Enter a valid zipcode" });
        }
        else if (types === "country") {
            setFormValidation({ ...formValidation, country: true, countryMessage: "Please Enter a valid country name" });
        }
    }

    function containsSpecialChars(str: string) {
        const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>?~]/;
        return specialChars.test(str);
    }

    const addressValidation = (currentData: any) => {
        return new Promise((resolve, reject) => {
            if (currentData.cityName === "" || currentData.stateName === "" || currentData.countryName === "" || currentData.zipcode === "" || currentData.address === "" || currentData.cityName === null || currentData.stateName === null || currentData.countryName === null || currentData.zipcode === null || currentData.address === null) {
                setIsAddressValidated(false);
                reject(false);
            }
            else if (containsSpecialChars(currentData.street)) {
                setFormValidation({ ...formValidation, street: true, streetMessage: "Please enter a valid street number" }); setIsAddressValidated(false);
                reject(false);
            }
            else {
                var addressLine = currentData.address + "," + currentData.cityName + ", " + currentData.stateName + ", " + currentData.zipcode + ", " + currentData.countryName;
                axios.post("https://addressvalidation.googleapis.com/v1:validateAddress?key=" + process.env.REACT_APP_GOOGLE_API_KEY, {
                    "address": {
                        "regionCode": currentData.countryCode,
                        "addressLines": [addressLine]
                    },
                    "enableUspsCass": true
                })
                    .catch((error: any) => {
                        setFormValidation({ ...formValidation, address: true, addressMessage: "Please enter a valid address, if the problem persists please reach out to the administrator." });
                        setIsAddressValidated(false);
                        reject(false);
                    })
                    .then((response: any) => {
                        if (response.data.result.verdict.hasUnconfirmedComponents) {
                            setFormValidation(initialError);

                            if (response.data.result.address.missingComponentTypes) {
                                response.data.result.address.missingComponentTypes.reverse().map((types: any) => {
                                    if (types !== "subpremise") {
                                        checkValidation(types);
                                        setIsAddressValidated(false);
                                    }
                                })
                            }
                            if (response.data.result.address.unconfirmedComponentTypes) {
                                response.data.result.address.unconfirmedComponentTypes.reverse().map((types: any) => {
                                    checkValidation(types);
                                    setIsAddressValidated(false);
                                })
                            }
                            reject(false)

                        }
                        else {
                            setValidatedData(response.data);
                            var street = "";
                            var city = ""
                            var state = "";
                            var country = "";
                            var zipcode = "";
                            response.data.result.address.addressComponents.map((record: any) => {

                                if (record.componentType === "street_number") {
                                    street = street + record.componentName.text
                                }
                                else if (record.componentType === "route") {
                                    street = street + " " + record.componentName.text
                                }
                                else if (record.componentType === "subpremise") {
                                    street = street + " " + record.componentName.text
                                }
                                else if (record.componentType === "locality") {
                                    city = " " + record.componentName.text;
                                }
                                else if (record.componentType === "administrative_area_level_1") {
                                    state = " " + record.componentName.text
                                }
                                else if (record.componentType === "postal_code") {
                                    zipcode = " " + record.componentName.text;
                                }
                                else if (record.componentType === "postal_code_suffix") {
                                    zipcode = zipcode + "-" + record.componentName.text;
                                }
                                else if (record.componentType === "country") {
                                    country = " " + record.componentName.text
                                }
                            })
                            var suggestion = "";
                            var currentAddress = "";
                            if (currentData.street) {
                                suggestion = street + " " + currentData.street + "," + city + "," + state + "," + country + "," + zipcode;
                                currentAddress = currentData.address + " " + currentData.street + ", " + currentData.cityName + ", " + currentData.stateName + ", " + currentData.countryName + ", " + currentData.zipcode;

                            }
                            else {
                                suggestion = street + "," + city + "," + state + "," + country + "," + zipcode;
                                currentAddress = currentData.address + ", " + currentData.cityName + ", " + currentData.stateName + ", " + currentData.countryName + ", " + currentData.zipcode;

                            }
                            if (suggestion === currentAddress) {
                                setIsAddressValidated(true);
                                setShowAddressSuggestion("");
                                setDisableFields(false);
                                setDisableSave(false);
                                resolve(true);
                            }
                            else {
                                setIsAddressValidated(false);
                                setShowAddressSuggestion(suggestion);
                                setDisableFields(true)
                                setDisableSave(true);
                                reject(false);
                            }
                            setFormValidation(initialError)
                        }
                    })
            }
        });
    }

    const onClickYes = () => {
        var temp = formData;
        validatedData.result.address.addressComponents.map((record: any) => {

            if (record.componentType === "street_number") {
                temp.address = record.componentName.text;
            }
            else if (record.componentType === "route") {
                temp.address += " " + record.componentName.text;
            }
            else if (record.componentType === "subpremise") {
                temp.address += ", " + record.componentName.text;
            }
            else if (record.componentType === "locality") {
                temp.cityName = record.componentName.text;
            }
            else if (record.componentType === "administrative_area_level_1") {
                temp.stateName = record.componentName.text;
            }
            else if (record.componentType === "country") {
                temp.countryName = record.componentName.text;
            }
            else if (record.componentType === "postal_code") {
                temp.zipcode = record.componentName.text;
            }
            else if (record.componentType === "postal_code_suffix") {
                temp.zipcode += "-" + record.componentName.text;
            }
        })
        //setFormData({...formData, address:street, cityName:city, stateName:state, countryName:country, zipcode:pincode})
        setIsAddressValidated(true);
        setDisableSave(false);
        setDisableFields(false);
        setShowAddressSuggestion("");
        setSaveLoader(false);
    }

    const onClickSkip = () => {
        // saveFunction(formData)
    }

    const onClickEdit = () => {
        setIsAddressValidated(false);
        setShowAddressSuggestion("");
        setDisableSave(false);
        setDisableFields(false);
    }
    const handleSave = (values: any) => {

    };



    const gridStyles = { height: "70px" }

    const handlePhoneChange2 = (value: any) => {
        formik.values.phone2 = value.replace(/[^0-9]/g, "");
        setPhone2(value);
        if (phoneValidation.error) {
            checkPhoneValidation2();
        }
    };
    const checkPhoneValidation2 = () => {
        if (phone2 !== "") {
            if (phone2.replace(/[^0-9]/g, "").length < 11 || phone2.replace(/[^0-9]/g, "").length > 12) {
                setPhoneValidation2({ error: true, message: "Please enter a valid phone number" })
                return false;
            }
            else {
                setPhoneValidation2({ error: false, message: "" })
                return true;
            }
        }
    }
    return (
        <Card sx={{
            position: 'absolute',

        }}>
            <DialogTitle sx={{ textAlign: "center", fontWeight: 'bold', backgroundColor: "#F0F7FF", fontSize: { xs: "large", sm: "large", md: "34px", lg: "34px", xl: "34px" } }}>
                {userType === "connector" ? "New Connector" : "New Vendor"} Registration
                <Tooltip
                    title={"Enter the required details to add a user"}
                    arrow
                    placement="right-end"
                >
                    <InfoOutlinedIcon color="disabled" sx={{ fontSize: 27, paddingLeft: 0.5 }} />
                </Tooltip>
                <IconButton onClick={() => {
                    setPhone('');
                    handleClose();
                }} sx={{ float: "right", color: "red" }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>



            <CardContent sx={{ padding: '2px' }}>
                <Grid container spacing={2} sx={{ paddingTop: 1, paddingX: 2 }}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ textAlign: "center", marginTop: 1 }}>
                        {/* {currentSelection.logo ?
                            <>
                                <CardMedia
                                    component="img"
                                    image={`data:${currentSelection.contentType};base64,${currentSelection.logo}`}
                                    alt="Connected People"
                                    sx={{
                                        height: "15rem",
                                        width: "15rem",
                                        textAlign: "center",
                                        margin: "auto"
                                    }}
                                />
                            </>
                            : */}
                        <>
                            <CardMedia
                                component="img"
                                image={"assets/icons/userIcon.png"}
                                alt="Connected People"
                                sx={{
                                    height: "15rem",
                                    width: "15rem",
                                    textAlign: "center",
                                    margin: "auto"
                                }}
                            />



                        </>
                        {/* <Typography sx={{ fontWeight: 'bold' }} variant="h5" mt={2}>
                            {firstCapital(currentSelection?.name)}
                        </Typography> */}
                    </Grid>

                    <Grid item xs={12} md={6} sx={{ padding: '2px' }}>
                        <Grid container spacing={2}>
                            {userType === "vendor" && (
                                <>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography sx={{ fontWeight: 'bold', fontSize: '20px' }}>
                                            Organization Details{' '}
                                            <Typography sx={{ color: '#00BFFF', display: "inline" }}>*</Typography>
                                            <Tooltip
                                                title={"Enter the organization details associated with the invitee"}
                                                arrow
                                                placement="right-end"
                                            >
                                                <InfoOutlinedIcon color="disabled" sx={{ fontSize: 22, paddingLeft: 0.5 }} />
                                            </Tooltip>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={gridStyles}>

                                        <TextField
                                            type="text"
                                            name="organizationName"
                                            id="organizationName"
                                            label="Organization Name *"
                                            placeholder="Enter your Organization name here"
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={formik.touched.organizationName && Boolean(formik.errors.organizationName)}
                                            helperText={formik.touched.organizationName && formik.errors.organizationName}
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        {(formik.values.organizationName !== "" && !formik.touched.organizationName) &&
                                                            <>{Boolean(formik.errors.organizationName) ? <CloseIcon color="error" /> : <CheckIcon color="success" />}</>}
                                                        {formik.touched.organizationName && <>{Boolean(formik.errors.organizationName) ? <CloseIcon color="error" /> : <CheckIcon color="success" />}</>}
                                                    </InputAdornment>


                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={gridStyles}>

                                        <TextField
                                            type="text"
                                            name="website"
                                            id="website"
                                            label="Website *"
                                            placeholder="Enter your Organization website here"
                                            onBlur={(e) => {
                                                formik.handleBlur(e);
                                                handleWebsiteBlur();
                                            }}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.website &&
                                                (Boolean(formik.errors.website) || (loading && formik.errors.website !== ''))
                                            }
                                            helperText={
                                                (formik.touched.website && formik.errors.website) || (loading && 'Checking...')
                                            }
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            InputProps={{
                                                startAdornment: <span >https://</span>,
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        {(formik.touched.website && !formik.errors.website) && (
                                                            <CheckIcon color="success" />
                                                        )}
                                                        {formik.touched.website && formik.errors.website && (
                                                            <CloseIcon color="error" />
                                                        )}
                                                    </InputAdornment>

                                                ),
                                            }}
                                        />
                                    </Grid>
                                </>
                            )}


                            {/* {userType === "vendor" && <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={gridStyles}>

                                    </Grid>}
                                    {userType === "vendor" && <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={gridStyles}>

                                    </Grid>} */}
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                <Typography sx={{ fontWeight: 'bold', fontSize: '20px' }}>
                                    Personal Information {' '}
                                    <Typography sx={{ color: '#00BFFF', display: "inline" }}>*</Typography>
                                    <Tooltip
                                        title={"Enter the address of the invitee"}
                                        arrow
                                        placement="right-end"
                                    >
                                        <InfoOutlinedIcon color="disabled" sx={{ fontSize: 22, paddingLeft: 0.5 }} />
                                    </Tooltip>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={gridStyles} >

                                <TextField
                                    type="text"
                                    label="First Name *"
                                    name="firstName"
                                    id="firstName"
                                    placeholder="Enter your first name here"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                    helperText={formik.touched.firstName && formik.errors.firstName}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {(formik.values.firstName !== "" && !formik.touched.firstName) &&
                                                    <>{Boolean(formik.errors.firstName) ? <CloseIcon color="error" /> : <CheckIcon color="success" />}</>}
                                                {formik.touched.firstName && <>{Boolean(formik.errors.firstName) ? <CloseIcon color="error" /> : <CheckIcon color="success" />}</>}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={gridStyles} >

                                <TextField
                                    type="text"
                                    name="lastName"
                                    label="Last Name *"
                                    id="lastName"
                                    placeholder="Enter your last name here"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                    helperText={formik.touched.lastName && formik.errors.lastName}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {(formik.values.lastName !== "" && !formik.touched.lastName) &&
                                                    <>{Boolean(formik.errors.lastName) ? <CloseIcon color="error" /> : <CheckIcon color="success" />}</>}
                                                {formik.touched.lastName && <>{Boolean(formik.errors.lastName) ? <CloseIcon color="error" /> : <CheckIcon color="success" />}</>}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={gridStyles}>
                                <TextField
                                    type="email"
                                    name="email"
                                    id="email"
                                    label="Email *"
                                    placeholder="Enter your Mail id here"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {(formik.values.email !== "" && !formik.touched.email) &&
                                                    <>{Boolean(formik.errors.email) ? <CloseIcon color="error" /> : <CheckIcon color="success" />}</>}
                                                {formik.touched.email && <>{Boolean(formik.errors.email) ? <CloseIcon color="error" /> : <CheckIcon color="success" />}</>}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={gridStyles} >

                                <MuiPhoneNumber
                                    defaultCountry={'us'}
                                    onChange={(e: any) => handlePhoneChange(e)}
                                    onBlur={checkPhoneValidation}
                                    value={phone}
                                    label="Phone Number *"
                                    variant='outlined'
                                    fullWidth
                                    error={phoneValidation.error}
                                    helperText={phoneValidation.message}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {(phone !== "" || phoneValidation.error === true) && <>
                                                    {phone.replace(/[^0-9]/g, "").length < 11 ? <CloseIcon color="error" />
                                                        : <>{phoneValidation.error ? <CloseIcon color="error" /> : <CheckIcon color="success" />}</>}
                                                </>}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={gridStyles} >
                                <MuiPhoneNumber
                                    defaultCountry={'us'}
                                    onChange={(e: any) => handlePhoneChange2(e)}
                                    onBlur={checkPhoneValidation2}
                                    value={phone2}
                                    variant='outlined'
                                    label="Alternate Number"
                                    fullWidth
                                    error={phoneValidation2.error}
                                    helperText={phoneValidation2.message}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {(phoneValidation2.error === true) && <>
                                                    {phone2.replace(/[^0-9]/g, "").length < 11 ? <CloseIcon color="error" />
                                                        : <>{phoneValidation2.error ? <CloseIcon color="error" /> : <CheckIcon color="success" />}</>}
                                                </>}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormControl
                                    component="fieldset"
                                    error={formik.touched.mfaType && Boolean(formik.errors.mfaType)}
                                    sx={{ marginBottom: '1px', minHeight: '90px' }} // Set a fixed height for the FormControl
                                >
                                    <Typography sx={{ fontWeight: 'bold', fontSize: '20px', marginTop: '1px', marginBottom: '2px' }}>
                                        MFA Type{' '}
                                        <Typography sx={{ color: '#00BFFF', display: "inline" }}>*</Typography>
                                        <Tooltip
                                            title={"Choose the Multi-Factor Authentication type"}
                                            arrow
                                            placement="right-end"
                                        >
                                            <InfoOutlinedIcon color="disabled" sx={{ fontSize: 22, paddingLeft: 0.5 }} />
                                        </Tooltip>
                                    </Typography>
                                    <RadioGroup
                                        aria-label="mfa-type"
                                        name="mfa-type"
                                        value={selectedMfaType}
                                        onChange={handleMfaTypeChange}
                                        sx={{ display: 'flex', flexDirection: 'row', gap: 0, marginBottom: '10px' }}
                                    >
                                        <FormControlLabel
                                            value="sms"
                                            control={<Radio />}
                                            label="SMS"
                                            sx={{ minHeight: '20px', width: "150px" }} // Adjust the height as needed
                                        />
                                        <FormControlLabel
                                            value="totp"
                                            control={<Radio />}
                                            label="Authenticator"
                                            sx={{ minHeight: '20px', width: "150px" }} // Adjust the height as needed
                                        />
                                    </RadioGroup>
                                    <div style={{ height: '10px' }}>
                                        <Typography variant="body2" noWrap >
                                            {selectedMfaType === 'sms'
                                                ? '(The Primary Contact number will be used for SMS OTP)'
                                                : selectedMfaType === 'totp'
                                                    ? ''
                                                    : ''}
                                        </Typography>
                                        {!mfaTypeSelected && formik.touched.mfa && (
                                            <FormHelperText sx={{ color: 'red' }} >{formik.errors.mfa}</FormHelperText>
                                        )}
                                    </div>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                <Typography sx={{ fontWeight: 'bold', fontSize: '20px' }}>
                                    Address {' '}
                                    <Tooltip
                                        title={"Enter the address of the invitee"}
                                        arrow
                                        placement="left-end"
                                    >
                                        <InfoOutlinedIcon color="disabled" sx={{ fontSize: 22, paddingLeft: 0.5 }} />
                                    </Tooltip>
                                </Typography>
                                <AddressAutocomplete
                                    formData={formData}
                                    setFormData={setFormData}
                                    formValidation={formValidation}
                                    setFormValidation={setFormValidation}
                                    showAddressSuggestion={showAddressSuggestion}
                                    onClickYes={onClickYes}
                                    onClickSkip={onClickSkip}
                                    saveLoader={saveLoader}
                                    onClickEdit={onClickEdit}
                                    disableFields={disableFields}
                                    validateAddress={addressValidation}
                                />
                                {isAddressValidated ? <Typography color="green">Address Validated</Typography> : <Typography color="red">Please enter a valid address*</Typography>}
                            </Grid>


                        </Grid>
                    </Grid>
                </Grid>





            </CardContent>
            <CardActions sx={{ paddingRight: 5, paddingY: 2, justifyContent: 'flex-end' }}>
                <Button
                    onClick={() => {
                        setPhone('');
                        handleClose();
                    }}
                    variant="contained"
                    color="error"
                    sx={{ width: 100, height: 45, padding: 1, marginRight: 2 }}
                    disabled={saveLoader}
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        formik.handleSubmit();
                        checkPhoneValidation();
                    }}
                    variant="contained"
                    color="primary"
                    sx={{ width: 100, height: 45, padding: 1 }}
                >
                    {saveLoader ? <CircularProgress sx={{ color: "white" }} /> : "Save"}
                </Button>
            </CardActions>

        </Card >
    )
}

export default AddConnectorVendorForm