import { Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import React from 'react'
import { convertCurrency, convertDate } from '../Admin/Approvals/util';

const DealProfile = (props:any) => {
    const{currentSelection} = props;
  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ textAlign: "center", marginTop:4}}>
          <CardMedia
            component="img"
            image={"assets/icons/dealIcon.png"}
            alt="Connected People"
            sx={{
              height: "15rem",
              width: "15rem",
              textAlign: "center",
              margin: "auto"
            }}
          />
          <Typography sx={{ fontWeight: 'bold' }} variant="h5" mt={2}>
            {currentSelection?.name}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <Card sx={{marginRight:2, marginBottom:2, marginTop:2}}>
          <CardContent>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                <Typography sx={{fontWeight: 'bold',fontSize: '20px',}}>
                    Name
                </Typography>
                <Card sx={{ padding: 1, boxShadow: 0,backgroundColor: '#F0F0F0',}}>
                    <Typography variant="subtitle2">
                    {currentSelection.name}
                    </Typography>
                </Card>
                </Grid>
                <Grid item xs={12}>
                <Typography sx={{fontWeight: 'bold',fontSize: '20px',}}>
                    Amount
                </Typography>
                <Card sx={{ padding: 1, boxShadow: 0,backgroundColor: '#F0F0F0',}}>
                    <Typography variant="subtitle2">
                        {convertCurrency(currentSelection.amount)}
                    </Typography>
                </Card>
                </Grid>
                <Grid item xs={12}>
                <Typography sx={{fontWeight: 'bold',fontSize: '20px',}}>
                    Stage
                </Typography>
                <Card sx={{ padding: 1, boxShadow: 0,backgroundColor: '#F0F0F0',}}>
                    <Typography variant="subtitle2">
                        {currentSelection.stage}
                    </Typography>
                </Card>
                </Grid>
                <Grid item xs={12}>
                <Typography sx={{fontWeight: 'bold',fontSize: '20px',}}>
                    Started on
                </Typography>
                <Card sx={{ padding: 1, boxShadow: 0,backgroundColor: '#F0F0F0',}}>
                    <Typography variant="subtitle2">
                        {convertDate(currentSelection.startDate)}
                    </Typography>
                </Card>
                </Grid>
                <Grid item xs={12}>
                <Typography sx={{fontWeight: 'bold',fontSize: '20px',}}>
                    Description
                </Typography>
                <Card sx={{ padding: 1, boxShadow: 0,backgroundColor: '#F0F0F0',}}>
                    <Typography variant="subtitle2">
                        {currentSelection.description?<>{currentSelection.description}</>:<><Typography sx={{fontWeight:"light"}} >No deal description available.</Typography></>}
                    </Typography>
                </Card>
                </Grid>
            </Grid>
          </CardContent>
        </Card>
        </Grid>
      </Grid>
    </>
  )
}

export default DealProfile