import { Card, CardContent, CardMedia, Divider, Grid, Typography } from '@mui/material';
import React from 'react'
import { convertNumber, firstCapital } from '../Admin/Approvals/util';

const CustomerProfile = (props:any) => {
    const{currentSelection} = props;
    const createLink = (link:any) => {
      const mail = "mailto:"+link+"?body=New mail to connector";
      return(mail)
    }
  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ textAlign: "center", marginTop:4 }}>
          <CardMedia
            component="img"
            image={"assets/icons/userIcon.png"}
            alt="Connected People"
            sx={{
              height: "15rem",
              width: "15rem",
              textAlign: "center",
              margin: "auto"
            }}
          />
          <Typography sx={{ fontWeight: 'bold' }} variant="h5" mt={2}>
            {currentSelection.name? 
                    <>{firstCapital(currentSelection.name)}</>
                    :
                   <>{firstCapital(currentSelection.firstName)} {firstCapital(currentSelection.lastName)}</>
                    }
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <Card sx={{marginRight:2, marginBottom:2, marginTop:2}}>
          <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography sx={{fontWeight: 'bold',fontSize: '20px',}}>
                Name
              </Typography>
              <Card sx={{ padding: 1, boxShadow: 0,backgroundColor: '#F0F0F0',}}>
                <Typography variant="subtitle2">
                {(currentSelection.firstName && currentSelection.lastName) ?
                  <>
                    {currentSelection.firstName} {currentSelection.lastName}
                  </>
                  :
                   <>{firstCapital(currentSelection.firstName)} {firstCapital(currentSelection.lastName)}</>
                    }
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{fontWeight: 'bold',fontSize: '20px',}}>
                Email
              </Typography>
              <Card sx={{ padding: 1, boxShadow: 0,backgroundColor: '#F0F0F0',}}>
                <Typography variant="subtitle2">
                  <a href={createLink(currentSelection.email)}> {currentSelection.email} </a>
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{fontWeight: 'bold',fontSize: '20px',}}>
                Organization
              </Typography>
              <Card sx={{ padding: 1, boxShadow: 0,backgroundColor: '#F0F0F0',}}>
                <Typography variant="subtitle2">
                  {(currentSelection.name) ?
                  <>
                    {currentSelection.name}
                  </>
                  :
                  <>
                    <Typography sx={{fontWeight:"light"}} >The customer is not associated with any organization.</Typography>
                  </>}
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{fontWeight: 'bold',fontSize: '20px',}}>
                Contact Number
              </Typography>
              <Card sx={{ padding: 1, boxShadow: 0,backgroundColor: '#F0F0F0',}}>
                <Typography variant="subtitle2">
                  {currentSelection.phoneNumber1?
                  <>
                    {convertNumber(currentSelection.phoneNumber1)}
                  </>
                  :
                  <><Typography sx={{fontWeight:"light"}} >No contact details are available for this customer.</Typography></>}
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{fontWeight: 'bold',fontSize: '20px',}}>
                 Alternate Number
              </Typography>
              <Card sx={{ padding: 1, boxShadow: 0,backgroundColor: '#F0F0F0',}}>
                <Typography variant="subtitle2">
                  {currentSelection.phoneNumber2?
                  <>
                    {convertNumber(currentSelection.phoneNumber2)}
                  </>
                  :
                  <><Typography sx={{fontWeight:"light"}} >No alternate contact details are available.</Typography></>}
                </Typography>
              </Card>
            </Grid>
          </Grid>
          </CardContent>
        </Card>
        </Grid>
      </Grid>
    </>
  )
}

export default CustomerProfile