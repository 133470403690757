import {
    Layout,
} from 'react-admin';
import * as React from 'react';
import Header from './Header';


const MyLayout = (props: any) => {
    return (
    <Layout {...props} appBar={Header} menu={MyMenu} sidebar={MySidebar}
     sx={{ '& .RaLayout-content': { marginTop: 3,padding: 0,maxWidth:"98.8vw"}, '& .RaLayout-contentWithSidebar': {} }}
     />
    );
};

export default MyLayout;

export const MyMenu = (props:any) => {
    return(
    <></>
    )

}
export const MySidebar = (props:any) => {
    return(
    <></>    
    )

}