import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as yup from 'yup';
import MuiPhoneNumber from 'material-ui-phone-number';
import axios from 'axios';


const gridStyles = { height: "130px" }
const CustomerForm = (props: any) => {
    const { showDialog, handleClose, disableSave, asterisk, onCreateNewCustomer } = props;
    const [formData, setFormData] = useState({
        phoneNumber1: "",
        phoneNumber2: ""
    })
    const [phone1Validation, setPhone1Validation] = useState({
        error: false,
        message: ""
    })
    const [phone2Validation, setPhone2Validation] = useState({
        error: false,
        message: ""
    })
    const [saveLoader, setSaveLoader] = useState(false);
    const handlePhoneChange = (e: any, phoneType: any) => {
        if (phoneType === "phone1") {
            setFormData({ ...formData, phoneNumber1: e });
            if (phone1Validation.error) {
                checkPhoneValidation("phone1")
            }
        }
        else {
            setFormData({ ...formData, phoneNumber2: e });
            if (phone2Validation.error) {
                checkPhoneValidation("phone2")
            }
        }
    };
    const checkPhoneValidation = (phoneType: any) => {
        if (phoneType === "phone1" || phoneType === "all") {
            if (formData.phoneNumber1.replace(/[^0-9]/g, "").length < 11 || formData.phoneNumber1.replace(/[^0-9]/g, "").length > 12) {
                if (formData.phoneNumber1 === "") {
                    setPhone1Validation({ error: true, message: "Please enter your phone number" })
                    return false;
                }
                else {
                    setPhone1Validation({ error: true, message: "Please enter a valid phone number" })
                    return false;
                }
            }
            else {
                setPhone1Validation({ error: false, message: "" })
                return true;
            }
        }
        else if (phoneType === "phone2" || phoneType === "all") {
            if (formData.phoneNumber2.replace(/[^0-9]/g, "").length < 11 || formData.phoneNumber2.replace(/[^0-9]/g, "").length > 12) {
                if (formData.phoneNumber2 === String("+1") || formData.phoneNumber2 === String("+")) {
                    setFormData({ ...formData, phoneNumber2: "" })
                    setPhone2Validation({ error: false, message: "" })
                }
                if (formData.phoneNumber2 === "") {
                    setPhone2Validation({ error: false, message: "" })
                    return true;
                }
                else {
                    setPhone2Validation({ error: true, message: "Please enter a valid phone number" })
                    return false;
                }
            }
            else {
                setPhone2Validation({ error: false, message: "" })
                return true;
            }
        }
    }

    const validationSchema = yup.object({
        name: yup
            .string()
            .min(4, "First name should contain 4 characters at least")
            .matches(/^[A-Za-z0-9 -]*$/, 'Please enter a valid name')
            .required("Please enter a valid organization name"),
        website: yup
            .string()
            .nullable()
            .url("Please enter a valid url"),
        firstName: yup
            .string()
            .min(4, "First name should contain 4 characters at least")
            .matches(/^[A-Za-z -]*$/, 'Please enter a valid first name')
            .required('First name is required'),
        lastName: yup
            .string()
            .min(4, "Last name should contain 4 characters at least")
            .matches(/^[A-Za-z -]*$/, 'Please enter a valid last name')
            .required('Last name is required'),
        email: yup
            .string()
            .email("Enter a valid mail address")
            .required("customer's email id is required"),
        description: yup
            .string()
            .min(10, "Description must be 10 characters long")
            .max(200, "Description shouldn't exceed 200 characters")
    });

    const formik = useFormik({
        initialValues:
        {
            firstName: '',
            lastName: '',
            name: '',
            email: '',
            description: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            registerCustomer(values);
        }
    });

    const registerCustomer = (values: any) => {
        checkPhoneValidation("all")
        if (!phone1Validation.error && !phone2Validation.error) {
            const requestBody = values;
            requestBody.isApproved = true;
            requestBody.phoneNumber1 = formData.phoneNumber1.replace(/[^0-9]/g, "");
            if (formData.phoneNumber2 !== "" && formData.phoneNumber2 !== String("+1") && formData.phoneNumber2 !== "+") {
                requestBody.phoneNumber2 = formData.phoneNumber2.replace(/[^0-9]/g, "");
            }
            setSaveLoader(true);
            const body = {
                customer: requestBody,
                role: "customer"
            }
            axios.post(process.env.REACT_APP_BASE_URL + "register", body)
                .catch((err: any) => {
                    setSaveLoader(false);
                })
                .then((response: any) => {
                    onCreateNewCustomer(response.data.id);
                    setSaveLoader(false);
                    setFormData({ ...formData, phoneNumber1: "", phoneNumber2: "" })
                    formik.resetForm();
                    handleClose();
                })
        }
    }
    return (
        <Dialog
            open={showDialog}
            fullWidth
            maxWidth="lg"
            sx={{
                '.MuiDialog-paper': {
                    position: 'absolute',
                    width: '70%',
                    height: '700px',
                    minWidth: "70%"
                },
            }}
        >
            <DialogTitle sx={{ padding: 0, backgroundColor: "#e5f6fd" }}>
                <Grid container sx={{ padding: 2 }}>
                    <Grid item xs={11.8} sx={{ textAlign: "center" }}>
                        <Typography variant="h4" align="center" sx={{ marginTop: 1, fontWeight: 'bold', width: "100%", fontSize: { xs: "large", sm: "large", md: "34px", lg: "34px", xl: "34px" } }}>
                            New Customer
                            <Tooltip
                                title={"Add a new customer here"}
                                arrow
                                placement="right-end"
                            >
                                <Button>
                                    <InfoOutlinedIcon />
                                </Button>
                            </Tooltip>
                        </Typography>
                    </Grid>
                    <Grid item xs={0.2} sx={{ alignText: "end", justifyContent: "right" }}>
                        <Box display="flex"><CloseIcon sx={{ marginTop: 1, fontWeight: "bold", color: "red", "&:hover": { cursor: "pointer" } }} onClick={handleClose} /></Box>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ marginTop: 5, height: "180px" }}>
                        <Typography sx={{ fontWeight: "bold", fontSize: '20px', marginBottom: 2 }}>Organizational Information</Typography>
                        <Typography
                            sx={{
                                fontWeight: 'bold'
                            }}
                        >
                            Organization Name {asterisk()}
                        </Typography>
                        <Typography variant="caption">
                            Enter the organization's name to associate with the customer
                        </Typography>
                        <TextField
                            fullWidth
                            label="Organization Name"
                            name="name"
                            id="name"
                            value={formik.values.name}
                            type="text"
                            variant="outlined"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                        />
                    </Grid>
                    <Grid item xs={0} sm={0} md={6} lg={6} xl={6} />
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={{ fontWeight: "bold", fontSize: '20px', }}>Contact Information</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={gridStyles}>
                        <Typography
                            sx={{
                                fontWeight: 'bold'
                            }}
                        >
                            First Name {asterisk()}
                        </Typography>
                        <Typography variant="caption">
                            Enter first name here
                        </Typography>
                        <TextField
                            fullWidth
                            label="First Name"
                            name="firstName"
                            id="firstName"
                            value={formik.values.firstName}
                            type="text"
                            variant="outlined"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                            helperText={formik.touched.firstName && formik.errors.firstName}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={gridStyles}>
                        <Typography
                            sx={{
                                fontWeight: 'bold'
                            }}
                        >
                            Last Name {asterisk()}
                        </Typography>
                        <Typography variant="caption">
                            Enter last name here
                        </Typography>
                        <TextField
                            fullWidth
                            label="Last Name"
                            name="lastName"
                            id="lastName"
                            value={formik.values.lastName}
                            type="text"
                            variant="outlined"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                            helperText={formik.touched.lastName && formik.errors.lastName}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={gridStyles}>
                        <Typography
                            sx={{
                                fontWeight: 'bold'
                            }}
                        >
                            Email {asterisk()}
                        </Typography>
                        <Typography variant="caption">
                            Enter an email to associiate with the customer's profile
                        </Typography>
                        <TextField
                            fullWidth
                            label="Email"
                            name="email"
                            id="email"
                            value={formik.values.email}
                            type="text"
                            variant="outlined"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={gridStyles}>
                        <Typography
                            sx={{
                                fontWeight: 'bold'
                            }}
                        >
                            Contact Number {asterisk()}
                        </Typography>
                        <Typography variant="caption">
                            Enter a contact number to associiate with the customer's profile
                        </Typography>
                        <MuiPhoneNumber
                            defaultCountry={"us"}
                            onChange={(e) => handlePhoneChange(e, "phone1")}
                            value={formData.phoneNumber1}
                            variant="outlined"
                            fullWidth
                            placeholder="Contact Number"
                            size="small"
                            onBlur={() => checkPhoneValidation("phone1")}
                            error={phone1Validation.error}
                            helperText={phone1Validation.message}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={gridStyles}>
                        <Typography
                            sx={{
                                fontWeight: 'bold'
                            }}
                        >
                            Alternate Contact Number
                        </Typography>
                        <Typography variant="caption">
                            Enter an alternate contact number to associiate with the customer's profile (optional)
                        </Typography>
                        <MuiPhoneNumber
                            defaultCountry={"us"}
                            onChange={(e) => handlePhoneChange(e, "phone2")}
                            value={formData.phoneNumber2}
                            variant="outlined"
                            fullWidth
                            placeholder="Contact Number"
                            size="small"
                            onBlur={() => checkPhoneValidation("phone2")}
                            error={phone2Validation.error}
                            helperText={phone2Validation.message}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={gridStyles}>
                        <Typography
                            sx={{
                                fontWeight: 'bold'
                            }}
                        >
                            Description
                        </Typography>
                        <Typography variant="caption">
                            Enter an description to associiate with the customer's profile
                        </Typography>
                        <TextField
                            multiline
                            fullWidth
                            label="Deal Description"
                            name="description"
                            id="description"
                            value={formik.values.description}
                            type="text"
                            variant="outlined"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            error={formik.touched.description && Boolean(formik.errors.description)}
                            helperText={formik.touched.description && formik.errors.description}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    variant="contained"
                    color="error"
                    sx={{ width: 100, height: 45, padding: 1 }}
                    disabled={saveLoader}
                >
                    cancel
                </Button>
                <Button onClick={() => formik.handleSubmit()} sx={{ width: 100, height: 45, padding: 1 }} variant="contained" disabled={disableSave}>
                    {saveLoader ? <><CircularProgress sx={{ color: "white" }} /></> : <>Save</>}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CustomerForm