import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, TextField, Tooltip, Typography, InputAdornment } from '@mui/material';
import React, { useState, useEffect } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import MuiPhoneNumber from 'material-ui-phone-number';
import axios from 'axios';


const InvitationForm = (props: any) => {
    const { showDialog, handleClose, mailBody, setMailBody, saveLoader, formik, userType, phone, setPhone, phoneValidation, setPhoneValidation, checkPhoneValidation, handlePhoneChange, isWebsiteValid, setIsWebsiteValid, setWebsiteError, websiteError } = props;
    const [showSignUpError, setShowSignUpError] = useState(false);
    const [loading, setLoading] = useState(false);


    const gridStyles = { height: "100px" }
    const exitSignUpError = () => {
        setShowSignUpError(false);
    }

    useEffect(() => {
        if (isWebsiteValid) {
            formik.setFieldError('website', '');
        }
        else {
            formik.setFieldError('website', 'Invalid website or domain name');
        }
    }, [isWebsiteValid])
    const handleWebsiteBlur = async () => {
        const websiteUrl = formik.values.website;

        if (websiteUrl.toLowerCase().startsWith('https://')) {
            formik.values.website = websiteUrl.substring('https://'.length);
            return;
        }

        const domain: string | null | undefined = extractDomain(formik.values.website);

        try {
            if (domain && domain.length! > 0) {
                setLoading(true);

                const response = await axios.get(`https://api.api-ninjas.com/v1/dnslookup?domain=${domain}`, {
                    headers: {
                        'X-Api-Key': 'wnecOqvlRCx2vpBCz7UvoA==hRoYqCZZCLiYBnd5',
                    },
                });

                if (response.data && response.data.length > 0) {
                    setIsWebsiteValid(true)
                    setWebsiteError(null)
                    formik.setFieldError('website', '');
                } else {
                    setIsWebsiteValid(false)
                    setWebsiteError('Invalid website or domain name');
                    formik.setFieldError('website', 'Invalid website or domain name');
                }
            } else {
                setIsWebsiteValid(false);
                setWebsiteError('Invalid website or domain name');
                formik.setFieldError('website', 'Invalid website or domain name');
            }
        } catch (error) {
            setWebsiteError('Error making API call');
            formik.setFieldError('website', 'Invalid website or domain name');
        } finally {
            setLoading(false);
        }
    };

    function extractDomain(link: string): string | null {
        const match = link.match(/^(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9-]+(?:\.[a-zA-Z]+)+)(?:\/.*)?$/);
        return match ? match[1] : null;
    }



    return (
        <Dialog
            open={showDialog}
            fullWidth
            maxWidth="lg"
            sx={{
                '.MuiDialog-paper': {
                    position: 'absolute',
                    width: { xs: "90%", sm: "85%", md: "65%", lg: "65%", xl: "50%" },
                    height: '100%',
                    minWidth: { xs: "90%", sm: "85%", md: "65%", lg: "65%", xl: "50%" },
                },
            }}
        >
            <DialogTitle sx={{ textAlign: "center", fontWeight: 'bold', backgroundColor: "#F0F7FF", fontSize: { xs: "large", sm: "large", md: "34px", lg: "34px", xl: "34px" } }}>
                New Invitation
                <Tooltip
                    title={"Enter the required details to send the invitation to the vendor"}
                    arrow
                    placement="right-end"
                >
                    <InfoOutlinedIcon color="disabled" sx={{ fontSize: 27, paddingLeft: 0.5 }} />
                </Tooltip>
                <IconButton onClick={handleClose} sx={{ float: "right", color: "red" }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{ paddingTop: 4, paddingX: 2 }}>
                    {userType === "vendor" && <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={gridStyles}>
                        <Typography sx={{ fontWeight: 'bold', fontSize: '20px' }}>
                            Organization Name{' '}
                            <Typography sx={{ color: '#00BFFF', display: "inline" }}>*</Typography>
                            <Tooltip
                                title={"Enter the organization name associated with the invitee"}
                                arrow
                                placement="right-end"
                            >
                                <InfoOutlinedIcon color="disabled" sx={{ fontSize: 22, paddingLeft: 0.5 }} />
                            </Tooltip>
                        </Typography>
                        <TextField
                            type="text"
                            name="organizationName"
                            id="organizationName"
                            placeholder="Enter your Organization name here"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            error={formik.touched.organizationName && Boolean(formik.errors.organizationName)}
                            helperText={formik.touched.organizationName && formik.errors.organizationName}
                            fullWidth
                            variant="outlined"
                            size="small"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {(formik.values.organizationName !== "" && !formik.touched.organizationName) &&
                                            <>{Boolean(formik.errors.organizationName) ? <CloseIcon color="error" /> : <CheckIcon color="success" />}</>}
                                        {formik.touched.organizationName && <>{Boolean(formik.errors.organizationName) ? <CloseIcon color="error" /> : <CheckIcon color="success" />}</>}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>}
                    {userType === "vendor" && <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={gridStyles}>
                        <Typography sx={{ fontWeight: 'bold', fontSize: '20px' }}>
                            Organization Website{' '}
                            <Typography sx={{ color: '#00BFFF', display: "inline" }}>*</Typography>
                            <Tooltip
                                title={"Enter your Organization website here"}
                                arrow
                                placement="right-end"
                            >
                                <InfoOutlinedIcon color="disabled" sx={{ fontSize: 22, paddingLeft: 0.5 }} />
                            </Tooltip>
                        </Typography>

                        <TextField
                            type="text"
                            name="website"
                            id="website"
                            placeholder="Enter your Organization website here"
                            onBlur={(e) => {
                                formik.handleBlur(e);
                                handleWebsiteBlur();
                            }}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.website &&
                                (Boolean(formik.errors.website) || (loading && formik.errors.website !== '')) ||
                                Boolean(websiteError)
                            }
                            helperText={
                                (formik.touched.website && formik.errors.website) ||
                                (loading && 'Checking...') ||
                                websiteError
                            }
                            fullWidth
                            variant="outlined"
                            size="small"
                            InputProps={{
                                startAdornment: <span >https://</span>,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {formik.touched.website && (
                                            <>
                                                {websiteError ? (
                                                    <CloseIcon color="error" />
                                                ) : (
                                                    <CheckIcon color="success" />
                                                )}
                                            </>
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                        />

                    </Grid>}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={gridStyles}>
                        <Typography sx={{ fontWeight: 'bold', fontSize: '20px' }}>
                            First name{' '}
                            <Typography sx={{ color: '#00BFFF', display: "inline" }}>*</Typography>
                            <Tooltip
                                title={"Enter the first name of the invitee"}
                                arrow
                                placement="right-end"
                            >
                                <InfoOutlinedIcon color="disabled" sx={{ fontSize: 22, paddingLeft: 0.5 }} />
                            </Tooltip>
                        </Typography>
                        <TextField
                            type="text"
                            name="firstName"
                            id="firstName"
                            placeholder="Enter your first name here"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                            helperText={formik.touched.firstName && formik.errors.firstName}
                            fullWidth
                            variant="outlined"
                            size="small"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {(formik.values.firstName !== "" && !formik.touched.firstName) &&
                                            <>{Boolean(formik.errors.firstName) ? <CloseIcon color="error" /> : <CheckIcon color="success" />}</>}
                                        {formik.touched.firstName && <>{Boolean(formik.errors.firstName) ? <CloseIcon color="error" /> : <CheckIcon color="success" />}</>}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={gridStyles}>
                        <Typography sx={{ fontWeight: 'bold', fontSize: '20px' }}>
                            Last name{' '}
                            <Typography sx={{ color: '#00BFFF', display: "inline" }}>*</Typography>
                            <Tooltip
                                title={"Enter the last name of the invitee"}
                                arrow
                                placement="right-end"
                            >
                                <InfoOutlinedIcon color="disabled" sx={{ fontSize: 22, paddingLeft: 0.5 }} />
                            </Tooltip>
                        </Typography>
                        <TextField
                            type="text"
                            name="lastName"
                            id="lastName"
                            placeholder="Enter your last name here"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                            helperText={formik.touched.lastName && formik.errors.lastName}
                            fullWidth
                            variant="outlined"
                            size="small"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {(formik.values.lastName !== "" && !formik.touched.lastName) &&
                                            <>{Boolean(formik.errors.lastName) ? <CloseIcon color="error" /> : <CheckIcon color="success" />}</>}
                                        {formik.touched.lastName && <>{Boolean(formik.errors.lastName) ? <CloseIcon color="error" /> : <CheckIcon color="success" />}</>}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={gridStyles}>
                        <Typography sx={{ fontWeight: 'bold', fontSize: '20px' }}>
                            Phone{' '}
                            <Typography sx={{ color: '#00BFFF', display: "inline" }}>*</Typography>
                            <Tooltip
                                title={"Enter the phone no. associated with the invitee"}
                                arrow
                                placement="right-end"
                            >
                                <InfoOutlinedIcon color="disabled" sx={{ fontSize: 22, paddingLeft: 0.5 }} />
                            </Tooltip>
                        </Typography>
                        <MuiPhoneNumber
                            defaultCountry={'us'}
                            onChange={(e: any) => handlePhoneChange(e)}
                            onBlur={checkPhoneValidation}
                            value={phone}
                            variant='outlined'
                            fullWidth
                            error={phoneValidation.error}
                            helperText={phoneValidation.message}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {(phone !== "" || phoneValidation.error === true) && <>
                                            {phone.replace(/[^0-9]/g, "").length < 11 ? <CloseIcon color="error" />
                                                : <>{phoneValidation.error ? <CloseIcon color="error" /> : <CheckIcon color="success" />}</>}
                                        </>}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={gridStyles}>
                        <Typography sx={{ fontWeight: 'bold', fontSize: '20px' }}>
                            Mail{' '}
                            <Typography sx={{ color: '#00BFFF', display: "inline" }}>*</Typography>
                            <Tooltip
                                title={"Enter an email address to associate with the invitee"}
                                arrow
                                placement="right-end"
                            >
                                <InfoOutlinedIcon color="disabled" sx={{ fontSize: 22, paddingLeft: 0.5 }} />
                            </Tooltip>
                        </Typography>
                        <TextField
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Enter your Mail id here"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                            fullWidth
                            variant="outlined"
                            size="small"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {(formik.values.email !== "" && !formik.touched.email) &&
                                            <>{Boolean(formik.errors.email) ? <CloseIcon color="error" /> : <CheckIcon color="success" />}</>}
                                        {formik.touched.email && <>{Boolean(formik.errors.email) ? <CloseIcon color="error" /> : <CheckIcon color="success" />}</>}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ marginY: 1 }}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={{ fontWeight: 'bold', fontSize: '20px' }}>
                            Mail Body{' '}
                            <Typography sx={{ color: '#00BFFF', display: "inline" }}>*</Typography>
                        </Typography>
                        <Box sx={{ boxShadow: 1 }}>
                            <ReactQuill theme="snow" value={mailBody} onChange={setMailBody} />
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ paddingRight: 5, paddingY: 2 }}>
                <Button
                    onClick={() => {
                        setPhone('');
                        handleClose();
                    }
                    }
                    variant="contained"
                    color="error"
                    sx={{ width: 100, height: 45, padding: 1 }}
                    disabled={saveLoader}
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        formik.handleSubmit();
                        checkPhoneValidation(); // Add this line to manually trigger phone validation
                    }}
                    variant="contained"
                    color="primary"
                    sx={{ width: 100, height: 45, padding: 1 }}
                >
                    {saveLoader ? <CircularProgress sx={{ color: "white" }} /> : "Send"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default InvitationForm