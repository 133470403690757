import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Alert, Box, Button, Grid, Tooltip, Typography } from '@mui/material';
import { firstCapital } from '../Admin/Approvals/util';
import ConnectorProfile from './ConnectorProfile';
import ContactProfile from './ContactProfile';
import CustomerProfile from './CustomerProfile';
import DealProfile from './DealProfile';
import VendorProfile from './VendorProfile';
import { Dialog } from '@mui/material';
import ProductProfile from './ProductProfile';

const ProfileScreen = (props: any) => {
    const { user, currentSelection, type, setShowDialog, handleEdit, formik, rowState, setRowState, phoneValidate, setPhoneValidate, handlePhoneOneChange, validatePhone,
        addContactLoader, setAddContactLoader, file, handleFileChange, reset, previewType, setCurrentSelection, showDialog } = props;

    const handleClose = () => {
        if (type === "contact" && previewType !== "public") {
            setAddContactLoader(false);
            setRowState({});
            formik.resetForm();
            setPhoneValidate({
                phoneError: false,
                phoneText: '',
            });
            reset();
        }
        setShowDialog(false)
    }
    return (
        <Dialog
            open={showDialog}
            fullWidth
            maxWidth="lg"
            sx={{
                '.MuiDialog-paper': {
                    position: 'absolute',
                    width: { sx: "80%", sm: "75%", md: "65%", lg: "65%", xl: "65%" },
                    height: '650px',
                    minWidth: { sx: "80%", sm: "75%", md: "65%", lg: "65%", xl: "65%" },
                },
            }}
        >
            <>
                {currentSelection && <>
                    <Alert sx={{ padding: 0, width: '100%', backgroundColor: "#e5f6fd", '.MuiAlert-message': { width: '100%' }, '.MuiAlert-icon': { display: 'none' } }}>
                        <Grid container sx={{ padding: 2, width: '100%' }}>
                            <Grid item xs={11.8} sx={{ textAlign: "center" }}>
                                <Typography variant="h4" align="center" sx={{ marginTop: 1, fontWeight: 'bold', width: "100%", fontSize: { xs: "large", sm: "large", md: "34px", lg: "34px", xl: "34px" } }}>
                                    {firstCapital(type)} Details
                                    <Tooltip
                                        title={`Find ${firstCapital(type)} Details here`}
                                        arrow
                                        placement="right-end"
                                    >
                                        <Button>
                                            <InfoOutlinedIcon />
                                        </Button>
                                    </Tooltip>
                                </Typography>
                            </Grid>
                            <Grid item xs={0.2} sx={{ alignText: "end", justifyContent: "right" }}>
                                <Box display="flex"><CloseIcon sx={{ marginTop: 1, fontWeight: "bold", color: "red", "&:hover": { cursor: "pointer" } }} onClick={handleClose} /></Box>
                            </Grid>
                        </Grid>
                    </Alert>
                    <div>
                        {type === "connector" ?
                            <>
                                <ConnectorProfile user={user} setCurrentSelection={setCurrentSelection} currentSelection={currentSelection} setShowDialog={setShowDialog} />
                            </>
                            : <></>}
                        {type === "vendor" ?
                            <>
                                <VendorProfile user={user} setCurrentSelection={setCurrentSelection} currentSelection={currentSelection} setShowDialog={setShowDialog} />
                            </>
                            : <></>}
                        {type === "customer" ?
                            <>
                                <CustomerProfile currentSelection={currentSelection} />
                            </>
                            :
                            <></>}
                        {type === "product" ?
                            <>
                                <ProductProfile currentSelection={currentSelection} />
                            </>
                            : <></>}
                        {type === "contact" ?
                            <>
                                <ContactProfile user={user} currentSelection={currentSelection} handleEdit={handleEdit} formik={formik} rowState={rowState} setRowState={setRowState}
                                    phoneValidate={phoneValidate} handlePhoneOneChange={handlePhoneOneChange} validatePhone={validatePhone} addContactLoader={addContactLoader} file={file} handleFileChange={handleFileChange} reset={reset} />
                            </>
                            : <></>}
                        {type === "deal" ?
                            <>
                                <DealProfile currentSelection={currentSelection} />
                            </>
                            : <></>}
                    </div>
                </>

                }
            </>
        </Dialog>
    );
};

export default ProfileScreen;
