import { Button, Typography, Toolbar, Box, AppBar, IconButton, CssBaseline, Divider, List, ListItem, ListItemButton, ListItemText, Drawer, CardMedia } from "@mui/material";
import { useEffect, useState } from "react";
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import InfoIcon from '@mui/icons-material/Info';
import BookIcon from '@mui/icons-material/Book';
const drawerWidth = 240;
const navItems = ['Home', 'About', 'Contact'];

const Navbar = (props: any) => {
    const { destinationPage } = props;
    const [scrollState, setScrollState] = useState("")
    const [navStyle, setNavStyle] = useState<any>({ height: "90px" })
    const navButtonStyles = { color: "#97a3f2", fontWeight: "bold", display: "inline", paddingX: 1 }
    const [mobileOpen, setMobileOpen] = useState(false);
    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };
    useEffect(() => {
        if (destinationPage === "registration") {
            setNavStyle({ backgroundColor: "#144fe9", height: "90px" })
        }
    }, [])
    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Box display="flex" alignItems="center" sx={{ alignItems: "center", marginLeft: 3, paddingY: 1, display: { xs: "block", sm: "block", md: "none", lg: "none", xl: "none" } }}>
                <CardMedia
                    component="img"
                    image={"assets/logos/ConnectorNet-Logo-Horiz-RGB.png"}
                    alt="logo"
                    sx={{
                        marginRight: '1em',
                        height: 60,
                        width: 170,
                        paddingTop: 0
                    }}
                />
            </Box>
            <Divider />
            <List>
                <Link to="/">
                    <ListItem key={"Home"} disablePadding>
                        <ListItemButton>
                            <HomeIcon sx={{ fontSize: "30px", color: "black" }} /><ListItemText primary={"Home"} sx={{ paddingLeft: 1 }} />
                        </ListItemButton>
                    </ListItem>
                </Link>
                <Link to="/conn_intro">
                    <ListItem key={"Connectors"} disablePadding>
                        <ListItemButton>
                            <PersonIcon sx={{ fontSize: "30px", color: "black" }} /><ListItemText primary={"Connectors"} sx={{ paddingLeft: 1 }} />
                        </ListItemButton>
                    </ListItem>
                </Link>
                <Link to="/partner_intro">
                    <ListItem key={"Partners"} disablePadding>
                        <ListItemButton>
                            <PersonIcon sx={{ fontSize: "30px", color: "black" }} /><ListItemText primary={"Partners"} sx={{ paddingLeft: 1 }} />
                        </ListItemButton>
                    </ListItem>
                </Link>
                <a
                    href="https://www.linkedin.com/in/asoran/recent-activity/articles/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none' }}
                >
                    <ListItem key={"Blog"} disablePadding>
                        <ListItemButton>
                            <BookIcon sx={{ fontSize: "30px", color: "black" }} /><ListItemText primary={"Blog"} sx={{ paddingLeft: 1 }} />
                        </ListItemButton>
                    </ListItem>
                </a>
                <Link to="/about_us">
                    <ListItem key={"About Us"} disablePadding>
                        <ListItemButton>
                            <InfoIcon sx={{ fontSize: "30px", color: "black" }} /><ListItemText primary={"About Us"} sx={{ paddingLeft: 1 }} />
                        </ListItemButton>
                    </ListItem>
                </Link>
            </List>
        </Box>
    );
    useEffect(() => {
        window.onscroll = () => {
            if (window.pageYOffset === 0) {
                setScrollState("top")
            }
            else {
                setScrollState("not top")
            }
        }
    });

    useEffect(() => {
        if (destinationPage === "registration") {
            setNavStyle({ backgroundColor: "#144fe9", height: "90px" })
        }
        else {
            if (scrollState === "top") {
                setNavStyle({ height: "90px" })
            }
            else if (scrollState === "not top") {
                setNavStyle({ backgroundColor: "#212832", height: "90px" })
            }
        }
    }, [scrollState])
    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar component="nav" elevation={0} sx={{ '&.MuiAppBar-root': { backgroundColor: "transparent" } }}>
                    <Toolbar sx={navStyle}>
                        <Box
                            component="div"
                            sx={{ flexGrow: 1, display: { xs: "block", sm: "block", md: "none", lg: "none", xl: "none" } }}
                        >
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                sx={{ mr: 2, display: { md: 'none' } }}
                            >
                                <MenuIcon />
                            </IconButton>
                        </Box>
                        <Box
                            component="div"
                            sx={{ flexGrow: 1, display: { xs: "none", sm: "none", md: "block", lg: "block", xl: "block" } }}
                        >
                            <img src="assets/logos/ConnectorNet-Logo-Horiz-White.png" height="80px" />
                        </Box>
                        <Link to="/login">
                            <Button variant="contained" sx={{ width: "100px", backgroundColor: "#00ba94", marginTop: 1, display: { xs: 'block', sm: 'block', md: "none" } }}>Login</Button>
                        </Link>
                        <Link to="/register">
                            <Button variant="contained" sx={{ width: "100px", backgroundColor: "#00ba94", marginTop: 1, display: { xs: 'block', sm: 'block', md: "none" }, marginLeft: 2 }}>Sign Up</Button>
                        </Link>
                        <Box sx={{ display: { xs: 'none', sm: 'none', md: "block" } }}>
                            <Link to="/">
                                <Typography sx={navButtonStyles}>Home</Typography>
                            </Link>
                            <Link to="/conn_intro">
                                <Typography sx={navButtonStyles}>Connectors</Typography>
                            </Link>
                            <Link to="/partner_intro">
                                <Typography sx={navButtonStyles}>Partners</Typography>
                            </Link>
                            <a
                                href="https://www.linkedin.com/in/asoran/recent-activity/articles/"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: 'none' }}
                            >
                                <Typography sx={navButtonStyles}>Blog</Typography>
                            </a>
                            <Link to="/about_us">
                                <Typography sx={navButtonStyles}>About Us</Typography>
                            </Link>
                            <Link to="/login">
                                <Button variant="contained" sx={{ width: "100px", backgroundColor: "#00ba94", marginLeft: 3 }}>Login</Button>
                            </Link>
                            <Link to="/register">
                                <Button variant="contained" sx={{ width: "100px", backgroundColor: "#00ba94", marginLeft: 2 }}>Sign Up</Button>
                            </Link>
                        </Box>
                    </Toolbar>
                </AppBar>
                <Box component="nav">
                    <Drawer
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'block', md: "none" },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                    >
                        {drawer}
                        <Box
                            component="div"
                            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                        >
                            <img src="assets/logos/ConnectorNet-Logo-Horiz-White.png" height="80px" />
                        </Box>
                    </Drawer>
                </Box>
            </Box>
        </>
    )
}
export default Navbar;