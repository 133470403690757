import { Badge, CircularProgress, Grid, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';
import React from 'react'
import { styled } from '@mui/material/styles';



const TabMenu = (props: any) => {
    const {setIndex, type, setSetIndex} = props;
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    
    const modifyStyles = (index:any) => {
        var menuStyle;
        if(type === "parent"){
            menuStyle={
                minHeight: 48,
                px: 2.5,
                borderBottom: (setIndex === index) ? "2px solid #9c27b0" : "",
                borderRadius: "2px"
            }
        }
        else{
            menuStyle={
                minHeight: 48,
                px: 2.5,
                borderBottom: (selectedIndex === index) ? "2px solid #9c27b0" : "",
                borderRadius: "2px"
            }
        }
        return menuStyle;
    }
    return (
        <Grid container spacing={2} sx={{ display: 'flex' }}>
            {props.item.map((menuItem: any, index: any) => <Grid item key={menuItem}>
                <ListItem disablePadding>
                    <ListItemButton
                        // selected={selectedIndex === index}
                        sx={modifyStyles(index)}
                        onClick={() => {
                            
                            if(type === "parent"){
                                setSetIndex(index);
                                setSelectedIndex(-1);
                            }
                            else{
                                setSelectedIndex(index);
                            }
                            props.tabMenu(index)
                        }}>
                        {(selectedIndex === index || setIndex === index) ? <ListItemText primary={<Typography variant="body1" fontWeight="bold">
                            {menuItem}
                        </Typography>} sx={{ whiteSpace: "nowrap", fontWeight: "bold" }} /> : <ListItemText primary={menuItem} sx={{ whiteSpace: "nowrap" }} />}
                        {props.badgeContent ? <>{(props.badgeContent[index] || props.badgeContent[index]==0) ? <Badge badgeContent={props.badgeContent[index]} sx={{ paddingX: 1 }} color="error" /> : ""}</>: <></>}
                    </ListItemButton>
                </ListItem>
            </Grid>)}
        </Grid>
    );
}

export default TabMenu