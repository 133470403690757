import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import React from 'react'

const DealStageUpdateError = (props:any) => {
    const { type, handleClose } = props;
    return (
        <Dialog open={true} sx={{width:"100%", justifyContent:"center", height:"100%", backgroundColor:"transparent"}}>
        <DialogActions>
            <Grid container>
                <Grid xs={12} sx={{textAlign:"right"}}>
                    <Button onClick={handleClose}><CloseIcon sx={{color:"red"}}/></Button>
                </Grid>
            </Grid>
        </DialogActions>
        <DialogContent>
            <Grid container>
                <Grid item xs={12} sx={{textAlign:"center", paddingX:6, marginBottom:6, backgroundColor:"transparent"}}>
                   
                    <Typography sx={{paddingTop:type === "unauthorized"?0:2}}>
                        {type === "unauthorized" ? 
                        <>
                            <img src="assets/images/unauthorized.png" height={"240vh"}/><br/>
                            Only the vendor involved in this deal can modify it's stage
                        </>
                        :
                        <>
                            <img src="assets/loader/warning-sign.png" height={"140vh"}/>
                            Some error occurred while updating the stage of the deal
                        </>
                        }
                    </Typography>
                </Grid>
            </Grid>
        </DialogContent>
    </Dialog>
    )
}

export default DealStageUpdateError