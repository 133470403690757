import { Typography, Button } from '@mui/material'
import React from 'react'

const ErrorComponent = () => {
  return (
    <>
    <img src="assets/loader/warning-sign.png" height={"140vh"}/>
    <Typography sx={{paddingTop:2}}>
        Some error occured, please
        <Button sx={{padding:0.5, textTransform:"none", fontSize:"16px"}} onClick={(e) => window.location.reload()}>
            try again
        </Button>
    </Typography>
    </>
  )
}

export default ErrorComponent