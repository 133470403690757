import { Avatar, Box, Card, CircularProgress, Grid, Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import ContactsIcon from '@mui/icons-material/Contacts';
import { firstCapital } from '../Admin/Approvals/util';
import ProfileScreen from '../Profile Screens/ProfileScreen';
import { stringAvatar } from './avatarUtil';
const UsersList = (props:any) => {
    const {userType, userInfo, user} = props;
    const [loader, setLoader] = useState<any>();
    const [empty, setEmpty] = useState(false);
    const [error, setError] = useState(false);
    const [users, setUsers] = useState<any>();
    const [profileDialog, setProfileDialog] = useState(false);
    const [currentSelection, setCurrentSelection] = useState<any>()
    

    const profileLink = 
    {
        fontWeight:"bold",
        fontSize: "16px",
        "&:hover": {
            color: "#0000ff"
        },
        textTransform:"none",
        whiteSpace: 'nowrap',
        textDecoration: 'none',
        cursor: "pointer",
        color: "#00BFFF",
        display:"inline"
    }

    useEffect(() => {
        if(user){
            if(userType === "connector" || userType === "vendor"){
                setLoader(true);
                axios.get(process.env.REACT_APP_BASE_URL+userType+"/page", 
                {
                    headers: {
                      "Authorization": "Bearer "+user.accessToken,
                    }
                    ,params:
                    { 
                        pageNo:0,
                        pageSize:3,
                        sortBy:"id",
                        sortDir:"desc"
                    }
                })
                .catch((error) => {
                    setError(true);
                    setLoader(false);
                })
                .then((response:any) => {
                    setUsers(response.data);
                    if(response.data.length === 0){
                        setEmpty(true)
                    }
                    setLoader(false);
                })
            }
            else{
                setLoader(true);
                axios.get(process.env.REACT_APP_BASE_URL+userType+"/vendor/page/"+userInfo.vendorId.id,
                {
                    headers: {
                        "Authorization": "Bearer "+user.accessToken,
                    }
                  ,params:
                    { 
                        pageNo:0,
                        pageSize:3,
                        sortBy:"id",
                        sortDir:"desc"
                    }
                })
                .catch((error) => {
                    setError(true);
                    setLoader(false);
                })
                .then((response:any) => {
                    setUsers(response.data);
                    if(response.data.length === 0){
                        setEmpty(true)
                    }
                    setLoader(false);
                })
            }
        }
    },[user])

    const showProfile = (user:any) => {
        setCurrentSelection(user);
        setProfileDialog(true);
    }

    const findDays = (user:any) => {
        const dt1 =  new Date(user.createdDatetime);
        const dt2 = new Date();
        return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) ) /(1000 * 60 * 60 * 24));
    }
    
    return (
        <>
        <ProfileScreen showDialog={profileDialog} currentSelection={currentSelection} type={userType} setShowDialog={setProfileDialog} previewType={"public"}/>
        <Grid container >
            <Grid item xs={12}>
                <Box display="flex" alignItems="center" marginBottom="1em">
                    <Box ml={2} mr={2} display="flex">
                        <ContactsIcon color="disabled" fontSize="large" />
                    </Box>
                    <Link
                        underline="none"
                        variant="h5"
                        color="textSecondary"
                        component={RouterLink}
                        to="/admin/activityCentre"
                    >
                        {firstCapital(userType)}s
                    </Link>
                </Box>
                <Card sx={{padding:3}}>
                    {loader?
                    <>
                    <Grid item xs={12} sx={{ marginTop: 5, textAlign: "center", height:"26vh" }}><CircularProgress/><Typography sx={{ paddingTop: 2 }}>Please wait while we set up your deals pipeline</Typography></Grid>
                    </>:
                    <>
                        {empty ?
                        <Box sx={{ height:"27vh"}}>
                            <Grid item xs={12} sx={{minHeight:"100%", textAlign:"center", display:"flex", alignItems:"center", justifyContent:"center"}}>
                                <Link
                                    underline="none"
                                    color="textSecondary"
                                    component={RouterLink}
                                    to={""}
                                >
                                    Couldn't find any {userType}s
                                </Link>
                            </Grid>
                        </Box>
                        :
                        <>
                            {users &&
                            <>
                                <Box sx={{ height:"27vh"}}>
                                {users.map((user:any) => {
                                    return(
                                        <Grid container sx={{marginY:1}}>
                                            {userType !== "contact"?
                                            <>
                                                <Grid item xs={2}>
                                                    {user.logo?
                                                    <Avatar alt={user.name} src={`data:${user.contentType};base64,${user.logo}`} />:
                                                    <Avatar {...stringAvatar(user.name)} />}
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <Typography onClick={() => showProfile(user)} sx={profileLink}>{firstCapital(user.name)}</Typography>
                                                    <Typography>{findDays(user) === 0? <>Today</>:<>{findDays(user)} days ago</>}</Typography>
                                                </Grid>
                                            </>
                                            :
                                            <>
                                                <Grid item xs={2}>
                                                    {user.logo?
                                                    <Avatar alt={user.firstName+" "+user.lastName} src={`data:${user.contentType};base64,${user.logo}`} />:
                                                    <Avatar {...stringAvatar(user.firstName+" "+user.lastName)} />}
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <Typography onClick={() => showProfile(user)} sx={profileLink}>{firstCapital(user.firstName+" "+user.lastName)}</Typography>
                                                    <Typography>{findDays(user) === 0? <>Today</>:<>{findDays(user)} days ago</>}</Typography>
                                                </Grid>
                                            </>}
                                        </Grid>
                                    )
                                })}
                                </Box>
                            </>}
                        </>}
                        <Typography align='center' gutterBottom>
                            {empty ? 
                                <Link
                                    underline="none"
                                    color="textSecondary"
                                    component={RouterLink}
                                    to={"/admin/activityCentre"}
                                >
                                    Get Started
                                </Link>
                                :
                                <Link
                                    underline="none"
                                    color="textSecondary"
                                    component={RouterLink}
                                    to="/admin/activityCentre"
                                >
                                    See all {userType}s
                                </Link>
                            }
                        </Typography>
                    </>}
                </Card>
            </Grid>
        </Grid>
        </>
    )
}

export default UsersList