import {
    Autocomplete,
    Box,
    Button,
    Card,
    CardContent,
    CardMedia,
    CircularProgress,
    DialogActions,
    Grid,
    IconButton,
    TextField,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { convertNumber, firstCapital } from '../Admin/Approvals/util';
import EditIcon from '@mui/icons-material/Edit';
import MuiPhoneNumber from 'material-ui-phone-number';
import SaveIcon from '@mui/icons-material/Save';
import DocumentUpload from '../transactions/DocumentUpload';
import { useAuth0 } from '@auth0/auth0-react';

const ContactProfile = (props: any) => {
    const { user, currentSelection, handleEdit, formik, rowState, setRowState, phoneValidate, handlePhoneOneChange, validatePhone, addContactLoader, file, handleFileChange, reset } = props;
    const [editable, setEditable] = useState(false);
    const fileTypes = {
        "image/*": ['.png', '.jpg', '.jpeg']
    };
    const generateLink = (obj: any) => {
        const file = new Blob([obj], { type: "image/png" });
        const fileURL = window.URL.createObjectURL(file);
        const link = document.createElement("a")
        link.href = fileURL;

        return String(fileURL);
    }

    const createLink = (link: any) => {
        const mail = 'mailto:' + link + '?body=New mail to connector';
        return mail;
    };

    const handleEditable = () => {
        setEditable(true);
        handleEdit(currentSelection)
    };
    return (
        <Grid container>
            <Grid item xs={12} sx={{ textAlign: 'right' }}>
                {(!editable && user?.role === "vendor") &&
                    <IconButton onClick={handleEditable}>
                        <EditIcon />
                    </IconButton>}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ textAlign: 'center', marginTop: 4 }}>
                {currentSelection.logo ? (
                    <>
                        <CardMedia
                            component="img"
                            image={`data:${currentSelection.contentType};base64,${currentSelection.logo}`}
                            alt="Connected People"
                            sx={{
                                width: '15rem',
                                objectFit: "contain",
                                textAlign: 'center',
                                margin: 'auto',
                            }}
                        />
                    </>
                ) : (
                    <>
                        <CardMedia
                            component="img"
                            image={'assets/icons/userIcon.png'}
                            alt="Connected People"
                            sx={{
                                width: '15rem',
                                objectFit: "contain",
                                textAlign: 'center',
                                margin: 'auto',
                            }}
                        />
                    </>
                )}
                <Typography sx={{ fontWeight: 'bold' }} variant="h5" mt={2}>
                    {firstCapital(currentSelection?.firstName)}
                    {currentSelection?.lastName
                        ? ' ' + firstCapital(currentSelection?.lastName)
                        : ''}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} mt={2}>
                <Card>
                    {editable ? (
                        <CardContent>
                            <Grid container columnSpacing={1}>
                                <Grid item xs={6}>
                                    <Typography
                                        sx={{
                                            fontWeight: 'bold',
                                            fontSize: '20px',
                                        }}
                                    >
                                        First Name{' '}
                                        <span style={{ color: '#00BFFF' }}>
                                            *
                                        </span>
                                    </Typography>
                                    <TextField
                                        variant="outlined"
                                        sx={{ width: '100%' }}
                                        type="text"
                                        placeholder="First Name"
                                        id="firstName"
                                        name="firstName"
                                        value={formik.values.firstName}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={
                                            formik.touched.firstName &&
                                            Boolean(formik.errors.firstName)
                                        }
                                        helperText={
                                            formik.touched.firstName &&
                                            formik.errors.firstName
                                        }
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography
                                        sx={{
                                            fontWeight: 'bold',
                                            fontSize: '20px',
                                        }}
                                    >
                                        Last Name{' '}
                                        <span style={{ color: '#00BFFF' }}>
                                            *
                                        </span>
                                    </Typography>
                                    <TextField
                                        variant="outlined"
                                        sx={{ width: '100%' }}
                                        type="text"
                                        placeholder="Last Name"
                                        name="lastName"
                                        id="lastName"
                                        value={formik.values.lastName}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={
                                            formik.touched.lastName &&
                                            Boolean(formik.errors.lastName)
                                        }
                                        helperText={
                                            formik.touched.lastName &&
                                            formik.errors.lastName
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        sx={{
                                            fontWeight: 'bold',
                                            fontSize: '20px',
                                        }}
                                    >
                                        Email{' '}
                                        <span style={{ color: '#00BFFF' }}>
                                            *
                                        </span>
                                    </Typography>
                                    <Card
                                        sx={{
                                            padding: 1,
                                            boxShadow: 0,
                                            backgroundColor: '#F0F0F0',
                                        }}
                                    >
                                        <Typography variant="subtitle2">
                                            {formik.values.email}
                                        </Typography>
                                    </Card>
                                </Grid>
                                <Grid xs={12}>
                                    <Typography
                                        sx={{
                                            fontWeight: 'bold',
                                            fontSize: '20px',
                                        }}
                                    >
                                        Phone{' '}
                                        <span style={{ color: '#00BFFF' }}>
                                            *
                                        </span>
                                    </Typography>

                                    <MuiPhoneNumber
                                        defaultCountry={'us'}
                                        onChange={handlePhoneOneChange}
                                        error={phoneValidate.phoneError}
                                        helperText={phoneValidate.phoneText}
                                        variant="outlined"
                                        value={
                                            rowState.phoneNumber1
                                        }
                                        fullWidth
                                        placeholder="Phone Number"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        sx={{
                                            fontWeight: 'bold',
                                            fontSize: '20px',
                                        }}
                                    >
                                        Designation
                                    </Typography>

                                    <Autocomplete
                                        id="designation"
                                        options={[
                                            'CEO',
                                            'CTO',
                                            'CMO',
                                            'COO',
                                            'CFO',
                                            'CIO',
                                            'Employee',
                                        ]}
                                        value={
                                            rowState.designation
                                                ? rowState.designation
                                                : null
                                        }
                                        onChange={(event, newValue: any) => {

                                            setRowState({
                                                ...rowState,
                                                designation: newValue,
                                            });
                                        }}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                type="text"
                                                placeholder="Designation"
                                                fullWidth
                                                variant="standard"
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        sx={{
                                            fontWeight: 'bold',
                                            fontSize: '20px',
                                        }}
                                    >
                                        Organization{' '}
                                        <span style={{ color: '#00BFFF' }}>
                                            *
                                        </span>
                                    </Typography>
                                    <Autocomplete
                                        id="companyId"
                                        options={[]}
                                        // getOptionLabel={getOptionsCompanies}
                                        disabled
                                        value={rowState.companyId ? rowState.companyId.name : ''}
                                        // onChange={handleCompanyChange}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                type="text"
                                                placeholder={currentSelection.vendorId ? currentSelection.vendorId.name : ""}
                                                fullWidth
                                                variant="standard"
                                            />
                                        )}
                                    />
                                </Grid>

                            </Grid>
                        </CardContent>
                    ) : (
                        <CardContent>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography
                                        sx={{
                                            fontWeight: 'bold',
                                            fontSize: '20px',
                                        }}
                                    >
                                        Role
                                    </Typography>
                                    <Card
                                        sx={{
                                            padding: 1,
                                            boxShadow: 0,
                                            backgroundColor: '#F0F0F0',
                                        }}
                                    >
                                        <Typography variant="subtitle2">
                                            {currentSelection?.isAdmin ? "Vendor-Admin" : "Vendor-Contact"}
                                        </Typography>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        sx={{
                                            fontWeight: 'bold',
                                            fontSize: '20px',
                                        }}
                                    >
                                        Name
                                    </Typography>
                                    <Card
                                        sx={{
                                            padding: 1,
                                            boxShadow: 0,
                                            backgroundColor: '#F0F0F0',
                                        }}
                                    >
                                        <Typography variant="subtitle2">
                                            {firstCapital(
                                                currentSelection?.firstName
                                            )}
                                            {currentSelection?.lastName
                                                ? ' ' +
                                                firstCapital(
                                                    currentSelection?.lastName
                                                )
                                                : ''}
                                        </Typography>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        sx={{
                                            fontWeight: 'bold',
                                            fontSize: '20px',
                                        }}
                                    >
                                        Email
                                    </Typography>
                                    <Card
                                        sx={{
                                            padding: 1,
                                            boxShadow: 0,
                                            backgroundColor: '#F0F0F0',
                                        }}
                                    >
                                        <Typography variant="subtitle2">
                                            <a
                                                href={createLink(
                                                    currentSelection.email
                                                )}
                                            >
                                                {' '}
                                                {currentSelection.email}{' '}
                                            </a>
                                        </Typography>
                                    </Card>
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography
                                        sx={{
                                            fontWeight: 'bold',
                                            fontSize: '20px',
                                        }}
                                    >
                                        Contact Number
                                    </Typography>
                                    <Card
                                        sx={{
                                            padding: 1,
                                            boxShadow: 0,
                                            backgroundColor: '#F0F0F0',
                                        }}
                                    >
                                        <Typography variant="subtitle2">
                                            {currentSelection.phoneNumber1 ? (
                                                <>
                                                    {convertNumber(
                                                        currentSelection.phoneNumber1
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    <Typography
                                                        sx={{
                                                            fontWeight: 'light',
                                                        }}
                                                    >
                                                        No connector contact
                                                        details available
                                                    </Typography>
                                                </>
                                            )}
                                        </Typography>
                                    </Card>
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography
                                        sx={{
                                            fontWeight: 'bold',
                                            fontSize: '20px',
                                        }}
                                    >
                                        Designation
                                    </Typography>
                                    <Card
                                        sx={{
                                            padding: 1,
                                            boxShadow: 0,
                                            backgroundColor: '#F0F0F0',
                                        }}
                                    >
                                        <Typography variant="subtitle2">
                                            {currentSelection.designation ? (
                                                <>
                                                    {
                                                        currentSelection.designation
                                                    }
                                                </>
                                            ) : (
                                                <>
                                                    <Typography
                                                        sx={{
                                                            fontWeight: 'light',
                                                        }}
                                                    >
                                                        Designation not
                                                        mentioned.
                                                    </Typography>
                                                </>
                                            )}
                                        </Typography>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{fontWeight: 'bold',fontSize: '20px',}}>
                                        Address
                                    </Typography>
                                    <Card sx={{ padding: 1, boxShadow: 0,backgroundColor: '#F0F0F0',}}>
                                        <Typography variant="subtitle2">
                                        {currentSelection.addressVerified?
                                        <>
                                            {currentSelection.address}{currentSelection.street?<> {currentSelection.street},</>:<>,</>} {currentSelection.cityName}, {currentSelection.stateName}, {currentSelection.countryName}, {currentSelection.zipcode}
                                        </>
                                        :
                                        <><Typography sx={{fontWeight:"light"}} >Couldn't find a valid address.</Typography></>}
                                        </Typography>
                                    </Card>
                                </Grid>
                            </Grid>
                        </CardContent>
                    )}
                    {editable && <DialogActions>
                        <Button
                            onClick={e => {
                                validatePhone();
                                formik.handleSubmit(e);
                            }}
                            sx={{ width: 80, height: 40, padding: 1 }}
                            variant="contained"
                            type="submit"
                        >
                            {addContactLoader ? (
                                <CircularProgress sx={{ color: 'white' }} />
                            ) : (
                                'SAVE'
                            )}
                        </Button>
                    </DialogActions>}
                </Card>
            </Grid>
        </Grid>
    );
};

export default ContactProfile;
