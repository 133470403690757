import Navbar from "./Navbar";

const ConnectorPage = () => {
    return(
        <div id="layoutDefault">
            <div id="layoutDefault_content">
                <main>
                    <Navbar/>
                    <header className="page-header-ui page-header-ui-dark bg-gradient-primary-to-secondary">
                        <div className="page-header-ui-content pt-10">
                            <div className="container px-5 text-center">
                                <div className="row gx-5 justify-content-center">
                                    <div className="col-lg-8">
                                        <h1 className="page-header-ui-title mb-3">About our company</h1>
                                        <p className="page-header-ui-text">At ConnectorNet, we strive to create a dynamic network where trusted partnerships can flourish, and businesses can prosper. Join us as a connector and be part of a network that's driving progress and innovation in healthcare.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="svg-border-rounded text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor"><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path></svg>
                        </div>
                    </header>
                    <section className="bg-white py-10">
                        <div className="container px-5">
                            <div className="row gx-5 justify-content-center">
                                <div className="col-lg-10">
                                    
                                    <h4 className="mb-4 d-flex align-items-center">
                                        <div className="icon-stack bg-primary text-white me-2"><i data-feather="arrow-right"></i></div>
                                        What is a connector? 
                                    </h4>
                                    <p className="lead">A ConnectorNet connector is a person with a diverse network of contacts in the healthcare industry. They possess the skills necessary to identify opportunities for collaboration and facilitate the creation of new partnerships and connections that may have previously been unknown or untapped.</p>
                                    
                                    <hr className="my-5" />
                                    <h4 className="mb-4 d-flex align-items-center">
                                        <div className="icon-stack bg-primary text-white me-2"><i data-feather="arrow-right"></i></div>
                                        <span >What does a connector do?</span>
                                    </h4>
                                    <p className="lead">As a connector, you facilitate partnerships between our trusted vendor-partners and top-level healthcare executives by bringing the right people together at the right time to achieve common goals.</p>
                                    
                                    <hr className="my-5" />
                                    <h4 className="mb-4 d-flex align-items-center">
                                        <div className="icon-stack bg-primary text-white me-2"><i data-feather="arrow-right"></i></div>
                                        What are the benefits of becoming a connector?
                                    </h4>
                                    <p className="lead">Becoming a ConnectorNet connector allows you to leverage your existing contacts to create new business opportunities and increase your earning potential through commission-based deals.</p>

                                    <hr className="my-5" />
                                    <h4 className="mb-4 d-flex align-items-center">
                                        <div className="icon-stack bg-primary text-white me-2"><i data-feather="arrow-right"></i></div>
                                        How does ConnectorNet compensate its connectors?
                                    </h4>
                                    <p className="lead">ConnectorNet compensates its connectors through commission-based deals, where they receive a percentage of the revenue generated from partnerships that they facilitate.</p>

                                    <hr className="my-5" />
                                    <h4 className="mb-4 d-flex align-items-center">
                                        <div className="icon-stack bg-primary text-white me-2"><i data-feather="arrow-right"></i></div>
                                        Do connectors have to take part in the compensation structure?
                                    </h4>
                                    <p className="lead">No, connectors are not required to participate in the compensation structure if they feel that it would be a conflict of interest. Connectors are free to make introductions and act as evangelists for our vendor-partners without receiving compensation.</p>
                                    
                                    <hr className="my-5" />
                                    <h4 className="mb-4 d-flex align-items-center">
                                        <div className="icon-stack bg-primary text-white me-2"><i data-feather="arrow-right"></i></div>
                                        What kind of partnerships can a ConnectorNet connector facilitate?
                                    </h4>
                                    <p className="lead">A ConnectorNet connector can facilitate partnerships between our trusted vendor-partners and top-level healthcare executives across a wide range of areas, such as technology, medical devices, pharmaceuticals, and digital health solutions.</p>
                                    
                                    <hr className="my-5" />
                                    <h4 className="mb-4 d-flex align-items-center">
                                        <div className="icon-stack bg-primary text-white me-2"><i data-feather="arrow-right"></i></div>
                                        How does ConnectorNet ensure the quality of its vendor-partners?
                                    </h4>
                                    <p className="lead">ConnectorNet carefully screens all vendor-partners to ensure that they meet our standards for quality, reliability, and ethical business practices. We also monitor the performance of our vendor-partners to ensure that they are meeting their obligations under our partnership agreements.</p>
                                    
                                    <hr className="my-5" />
                                    <h4 className="mb-4 d-flex align-items-center">
                                        <div className="icon-stack bg-primary text-white me-2"><i data-feather="arrow-right"></i></div>
                                        How can I become a ConnectorNet connector?
                                    </h4>
                                    <p className="lead">To become a connector, you can apply through our website and provide information about your experience and network in the healthcare industry. We will review your application and contact you if you meet our criteria.</p>
                                    
                                    <hr className="my-5" />
                                    <h4 className="mb-4 d-flex align-items-center">
                                        <div className="icon-stack bg-primary text-white me-2"><i data-feather="arrow-right"></i></div>
                                        How does it work?

                                    </h4>
                                    <p className="lead">
                                        <ol>
                                            <li>Complete your ConnectorNet profile</li>
                                            <li>Register an intended opportunity (i.e., potential client) for a specific partner</li>
                                            <li>Facilitate an introduction within three (3) months of registration</li>
                                            <li>Be eligible for compensation when the introduction leads to a deal</li>
                                            <li>Leave the rest to us!</li>
                                        </ol>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        </div>
        
    )
}
export default ConnectorPage;