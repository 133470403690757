import { Button, Menu, MenuItem } from '@mui/material';
import React from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
const FilterComponent = (props: any) => {
    const { anchorEl, open, setAnchorEl, options, handleFilterOpt } = props;
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <Button
                startIcon={<FilterListIcon />}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                size="small"
            >
            Add Filter
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{ 'aria-labelledby': 'basic-button' }}
            >
                {options.map((opt: any, ind: any) => (
                    <MenuItem key={opt} onClick={() => handleFilterOpt(opt)}>
                        {opt}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};
export default FilterComponent;
