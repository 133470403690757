import { Box, Card, Grid, Typography, Button } from '@mui/material';
import React from 'react'
import MailLockIcon from '@mui/icons-material/MailLock';
import { statusColor, statusColorText } from '../../tableStyle';
import { convertDate } from '../Approvals/util';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';

const toPascalCase = (text: String) => {
    return text.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
        return g1.toUpperCase() + g2.toLowerCase();
    });
}

const InvitationsCardComponent = (props: any) => {
    const { rowData } = props;

    const profileLink = {
        fontWeight: "bold",
        fontSize: "16px",
        "&:hover": {
            color: "#0000ff"
        },
        textTransform: "none",
        whiteSpace: 'nowrap',
        textDecoration: 'none',
        cursor: "pointer",
        color: "#00BFFF",
        display: "inline"
    }

    return (
        <Box>
            {rowData.map((row: any) => {
                return (
                    <>

                        <Card sx={{ width: "100%", padding: 3, marginY: 4, boxShadow: 3, shadow: 0, border: 0 }}>
                            <Grid container>
                                <Grid item xs={1}>
                                    {row.verified ? <MarkEmailReadIcon /> : <MailLockIcon />}
                                </Grid>
                                <Grid item xs={8} sx={{ textAlign: "left" }}>
                                    <Typography
                                        sx={{
                                            fontWeight: "bold",
                                            fontSize: "20px",
                                            textTransform: "none",
                                            whiteSpace: 'nowrap',
                                            textDecoration: 'none',
                                            display: "inline"
                                        }}
                                    >
                                        {toPascalCase(row.name)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} sx={{ justifyContent: "right", display: "flex", alignItems: "right" }}>
                                    <Box
                                        sx={{
                                            backgroundColor: statusColor[row.verified],
                                            color: statusColorText[row.verified],
                                            padding: 1,
                                            width: 110,
                                            borderRadius: 12,
                                            whiteSpace: "noWrap"
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontWeight: 'bold',
                                                textAlign: 'center',
                                                fontSize: "15px"
                                            }}
                                        >
                                            {(row.verified ? "Verified" : "Pending")}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="caption" sx={{ fontWeight: "light" }}>Sent on: {convertDate(row.createdDatetime)}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container sx={{ backgroundColor: "#fff5e8", justifyContent: "space-between", alignItems: "center", padding: '5px' }}>
                                        <Typography variant="caption" sx={{ fontWeight: "bold", fontSize: '17px' }}>
                                            Email:
                                            <Typography
                                                component="a"
                                                href={`mailto:${row.email}`}
                                                sx={profileLink}
                                            >
                                                {row.email}
                                            </Typography>
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={12} sx={{ textAlign: "right", marginTop: '8px' }}>
                                        <Button variant="contained" sx={{ marginRight: '8px' }} onClick={() => props.resendInvitation(row.id)}>
                                            Resend Mail
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card>
                    </>
                )
            })}
        </Box>
    )
}

export default InvitationsCardComponent;
