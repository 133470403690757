import {
    Badge,
    Box,
    Button,
    CircularProgress,
    Grid,
    Menu,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { linkCol, linkHover } from '../../tableStyle';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import TableHeadRow from '../../Layout/TableHeadRow';
import SearchTextField from '../../Layout/SearchTextField';
import PageNameComp from '../../PageNameComp';
import AddIcon from '@mui/icons-material/Add';
import ShowList from '../../products/ShowList';
import ProfileScreen from '../../Profile Screens/ProfileScreen';
import { firstCapital } from '../Approvals/util';
import ContactForm from './ContactForm';
import {
    getComparator,
    sortedRowInformation,
} from '../../utils/SortingHandler';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ToastContainer, toast } from "react-toastify";
import ErrorDialog from '../../Layout/ErrorDialog';
import TableCardComponent from '../Approvals/TableCardComponent';
import ErrorComponent from '../../Layout/ErrorComponent';
import ActivitiesHeader from '../../utils/ActivitiesHeader';

const VendorContacts = (props: any) => {
    const { user } = props;
    const [showDialog, setShowDialog] = useState(false);
    const [contactSearch, setContactSearch] = useState('');
    const [globalData, setGlobalData] = useState<any>();
    const [tableData, setTableData] = useState<any>();
    let [rowState, setRowState] = useState<any>({});
    const [vendorId, setVendorId] = useState();
    const [loader, setLoader] = useState(true);
    const [addAnother, setAddAnother] = useState(0);
    const [userInfo, setUserInfo] = useState({ name: '' });
    const [addContactLoader, setAddContactLoader] = useState(false);
    const [contactRegistrationError, setContactRegistrationError] = useState(false);
    const [phoneValidate, setPhoneValidate] = useState({
        phoneError: false,
        phoneText: '',
    });
    const [checker, setChecker] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const handleChangePage = (event: any, newPage: any) => {
        setPage(newPage);
    }; const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const [error, setError] = useState(false);
    const successToast = () => toast.success("Mail sent successfully");
    const failureToast = () => toast.error("There was some error in sending the notification, please try again.");
    const [disableAdminRevoke, setDisableAdminRevoke] = useState(false);
    const validationSchema = yup.object({
        firstName: yup
            .string()
            .required('First Name is required')
            .min(3, 'First name should contain 3 characters at least')
            .matches(/^[A-Za-z]*$/, 'Please enter valid first name'),
        lastName: yup
            .string()
            .required('Last Name is required')
            .min(3, 'Last name should contain 3 characters at least')
            .matches(/^[A-Za-z]*$/, 'Please enter valid last name'),
        email: yup
            .string()
            .email('Enter a valid email')
            .required('Email is required'),
    });
    const exitContactRegistrationError = () => {
        setShowDialog(true);
        setContactRegistrationError(false);
    }
    useEffect(() => {
        if (tableData) {
            var c = 0;
            tableData.map((record: any) => {
                if (record.isAdmin) {
                    c += 1;
                }
            })

            if (c <= 1) {
                setDisableAdminRevoke(true);
            }
            else {
                setDisableAdminRevoke(false);
            }
        }
    }, [tableData, checker])
    const addContact = (temp: any) => {
        if (!addContactLoader) {
            setAddContactLoader(true);
            if (editForm) {

                axios.put(
                    process.env.REACT_APP_BASE_URL +
                    'contact/' +
                    rowState.id,
                    temp,
                    {
                        headers: {
                            "Authorization": "Bearer " + user.accessToken,
                        }
                    }
                )
                    .catch((error) => {
                        setAddContactLoader(false);
                        return;
                    })
                    .then((response: any) => {

                        setRowState({});
                        setAddContactLoader(false);
                        setProfileDialog(false);
                        setPhoneValidate({
                            phoneError: false,
                            phoneText: '',
                        });
                        formik.resetForm();
                        setAddAnother(prevVal => prevVal + 1);
                    });
            } else {

                let body = {
                    contact: {
                        ...temp,
                        hasNewsletter: false,
                        status: 0,
                        connectorId: null,
                        companyId: null,
                        vendorId: { id: vendorId, name: userInfo.name },
                        isApproved: false,
                        createdBy: userInfo.name,
                        modifiedBy: userInfo.name,
                        addressVerified: false
                    },

                    role: 'contact',
                };
                axios.post(
                    process.env.REACT_APP_BASE_URL + 'register',
                    body,
                    {
                        headers: {
                            "Authorization": "Bearer " + user.accessToken,
                        }
                    }
                )
                    .catch((error: any) => {
                        setAddContactLoader(false);
                        setContactRegistrationError(true);
                        setShowDialog(false);
                    })
                    .then((response: any) => {
                        if (response.data) {
                            setRowState({});
                            setAddContactLoader(false);
                            setAddAnother(prevVal => prevVal + 1);
                            handleClose();
                        }
                    });
            }
        }
    }

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
        },
        validationSchema: validationSchema,

        onSubmit: values => {
            let temp = { ...rowState, ...values };

            let value = rowState.phoneNumber1;
            if (!value) {
                setPhoneValidate({
                    ...phoneValidate,
                    phoneError: true,
                    phoneText: 'Phone Number is required',
                });
                return;
            }
            if (value) {
                let newNumber = value.replace(/[^0-9]/g, '');
                if (newNumber.length < 11) {
                    setPhoneValidate({
                        ...phoneValidate,
                        phoneError: true,
                        phoneText: 'Invalid Phone Number',
                    });
                    return;
                }
                temp.phoneNumber1 = newNumber;
                addContact(temp);
            }
        },
    });

    useEffect(() => {
        if (user) {
            var userType = user?.role;
            if (user?.role === "vendor") {
                userType = "contact"
            }
            axios
                .get(
                    process.env.REACT_APP_BASE_URL +
                    userType +
                    '/mail/' +
                    user?.email,
                    {
                        headers: {
                            "Authorization": "Bearer " + user.accessToken,
                        }
                    }
                )
                .catch((error: any) => {
                    setError(true);
                })
                .then((response: any) => {
                    setVendorId(response.data.vendorId.id);
                    setUserInfo(response.data.vendorId);
                });
        }
    }, [user]);

    useEffect(() => {
        setLoader(true);
        if (vendorId) {
            axios
                .get(
                    process.env.REACT_APP_BASE_URL +
                    'contact/vendor/' +
                    vendorId,
                    {
                        headers: {
                            "Authorization": "Bearer " + user.accessToken,
                        }
                    }
                )
                .catch((error: any) => {
                    setError(true);
                    setLoader(false);
                })
                .then((response: any) => {

                    setTableData(response.data);
                    setGlobalData(response.data);
                    setLoader(false);
                });
        }
    }, [vendorId]);

    useEffect(() => {
        let tempArr = [];
        if (globalData) {
            tempArr = [...globalData];
            tempArr = tempArr.filter(row => {
                let str = row.firstName;
                if (row.lastName) str = str + row.lastName;
                return str
                    .toLowerCase()
                    .includes(contactSearch.toLowerCase().trim());
            });
        }
        setTableData(tempArr);
    }, [contactSearch]);

    const handleClose = () => {
        setAddContactLoader(false);
        setShowDialog(false);
        setRowState({});
        setPhoneValidate({
            phoneError: false,
            phoneText: '',
        });
        formik.resetForm();
    };

    const handleOpen = () => {
        setEditForm(false);
        setShowDialog(true);
    };
    const onChange = (e: any) => {
        setRowState({
            ...rowState,
            [e.target.id]: e.target.value,
        });

    };
    const validatePhone = () => {
        let phone = rowState.phoneNumber1;
        if (!phone) {
            setPhoneValidate({
                ...phoneValidate,
                phoneError: true,
                phoneText: 'Phone Number is required',
            });
            return;
        }
        if (phone.length < 11) {
            setPhoneValidate({
                ...phoneValidate,
                phoneError: true,
                phoneText: 'Invalid Phone Number',
            });
            return;
        } else {
            setPhoneValidate({
                ...phoneValidate,
                phoneError: false,
                phoneText: '',
            });
        }
    };
    const handlePhoneOneChange = (value: any) => {
        setRowState({ ...rowState, phoneNumber1: value });
        let newNumber = value.replace(/[^0-9]/g, '');
        if (newNumber.length < 11) {
            setPhoneValidate({
                ...phoneValidate,
                phoneError: true,
                phoneText: 'Invalid Phone Number',
            });
        } else {
            setPhoneValidate({
                ...phoneValidate,
                phoneError: false,
                phoneText: '',
            });
        }
    };

    useEffect(() => {
        setLoader(true);
        if (vendorId)
            axios
                .get(
                    process.env.REACT_APP_BASE_URL +
                    'contact/vendor/' +
                    vendorId,
                    {
                        headers: {
                            "Authorization": "Bearer " + user.accessToken,
                        }
                    }
                )
                .catch((error: any) => {

                    setLoader(false);
                })
                .then((response: any) => {

                    setTableData(response.data);
                    setGlobalData(response.data);
                    setLoader(false);
                });
    }, [addAnother]);

    const [editForm, setEditForm] = useState(false);

    const handleEdit = (row: any) => {
        setRowState(row);
        formik.setValues({
            firstName: row.firstName,
            lastName: row.lastName,
            email: row.email,
        });
        setEditForm(true);
        // setShowDialog(true);

    };
    const [profileDialog, setProfileDialog] = useState(false);
    const [currentProfile, setCurrentProfile] = useState<any>();

    const showProfile = (row: any) => {
        setCurrentProfile(row);
        setProfileDialog(true);
    };
    const [showProductsDialog, setShowProductsDialog] = useState(false);
    const [getProductsLoader, setGetProductsLoader] = useState(false);
    const [contactProducts, setContactProducts] = useState();
    const [productListError, setProductListError] = useState(false);

    const handleShowProducts = (row: any) => {
        setShowProductsDialog(true);
        setGetProductsLoader(true);

        let str = row.productId.toString();
        axios
            .get(process.env.REACT_APP_BASE_URL + 'product/all',
                {
                    params: {
                        ids: str,
                    }, headers: {
                        "Authorization": "Bearer " + user.accessToken,
                    }
                })
            .catch((error: any) => {

                setGetProductsLoader(false);
                setProductListError(true);
            })
            .then((response: any) => {

                setContactProducts(response.data);
                setGetProductsLoader(false);
            });
    };
    const handleShowProductsDialogClose = () => {
        setShowProductsDialog(false);
        setProductListError(false);
    };

    const [updateRowIndex, setUpdateRowIndex] = useState(-1);

    const handleMakeAdmin = (row: any, type: any) => {
        setUpdateRowIndex(row.id);
        handleMenuClose();
        let temp = { ...row }
        if (type == "makeAdmin")
            temp.isAdmin = true;
        else if (type == "remove")
            temp.isAdmin = false;
        axios.put(
            process.env.REACT_APP_BASE_URL +
            'contact/' +
            row.id,
            temp,
            {
                headers: {
                    "Authorization": "Bearer " + user.accessToken,
                }
            }
        ).catch((error: any) => {
            failureToast();
            setUpdateRowIndex(-1);
        })
            .then((response: any) => {
                successToast();
                const ind = tableData.findIndex((obj: any) => obj.id === response.data.id);
                tableData[ind] = response.data;
                setUpdateRowIndex(-1);
                setChecker(prevVal => prevVal + 1)
            });
    }

    const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>();
    const [valueToOrderBy, setValueToOrderBy] = useState();
    const createSortHandler = (property: any) => (event: any) => {
        let isAscending;
        setValueToOrderBy(property);
        isAscending = orderDirection === 'asc';
        setOrderDirection(isAscending ? 'desc' : 'asc');
        sortedRowInformation(
            tableData,
            getComparator(orderDirection, property)
        );
    };
    const onRedirect = () => {
        setShowDialog(true);
    };

    const [openElem, setOpenElem] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenuClick = (elem: any) => (event: any) => {
        setAnchorEl(event.currentTarget);
        setOpenElem(elem);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setOpenElem(null);
    };
    const [file, setFile] = useState(null);

    const handleFileChange = (file: any) => {
        setFile(file);

    };
    const reset = () => {
        setFile(null)
    }
    return (
        <>
            <ErrorDialog showDialog={contactRegistrationError} handleClose={exitContactRegistrationError} type={"contact"} />
            <ShowList
                showDialog={showProductsDialog}
                handleShowDialogClose={handleShowProductsDialogClose}
                data={contactProducts}
                getLoader={getProductsLoader}
                type={'products'}
                error={productListError}
            />
            <ProfileScreen
                user={user}
                showDialog={profileDialog}
                currentSelection={currentProfile}
                type={'contact'}
                setShowDialog={setProfileDialog}
                handleEdit={handleEdit}
                formik={formik}
                rowState={rowState}
                setRowState={setRowState}
                phoneValidate={phoneValidate}
                setPhoneValidate={setPhoneValidate}
                handlePhoneOneChange={handlePhoneOneChange}
                validatePhone={validatePhone}
                addContactLoader={addContactLoader}
                setAddContactLoader={setAddContactLoader}
                file={file}
                handleFileChange={handleFileChange}
                reset={reset}
            />
            <ContactForm
                user={user}
                showDialog={showDialog}
                onClose={handleClose}
                onChange={onChange}
                handlePhoneChange={handlePhoneOneChange}
                addContactLoader={addContactLoader}
                userInfo={userInfo}
                formik={formik}
                phoneValidate={phoneValidate}
                validatePhone={validatePhone}
                editForm={editForm}
                rowState={rowState}
                setRowState={setRowState}
            />
            <ActivitiesHeader
                currentText="Find all your contacts here"
                heading="Contacts"
            />

            {error ?
                <Grid item xs={12} sx={{ textAlign: "center", marginTop: 10 }}>
                    <ErrorComponent />
                </Grid>
                :
                <>
                    {loader ? (
                        <Grid item xs={12} sx={{ textAlign: 'center', marginTop: 10 }}>
                            <img src="assets/loader/Spinner-px.gif" height={'140vh'} />
                            <Typography sx={{ paddingTop: 2 }}>
                                Please wait while we fetch all data
                            </Typography>
                        </Grid>
                    ) : (
                        <Grid container marginBottom={2} alignItems={'center'}>
                            {globalData && globalData.length > 0 && (
                                <Grid item xs={3}>
                                    <SearchTextField
                                        val={contactSearch}
                                        funcHandle={setContactSearch}
                                    />
                                </Grid>
                            )}
                            {globalData && globalData.length > 0 && (
                                <Grid
                                    item
                                    xs={9}
                                    sx={{ textAlign: 'right', marginTop: 1 }}
                                >
                                    {user?.role === "vendor" ?
                                        <Button
                                            variant="contained"
                                            // size="small"
                                            startIcon={<AddIcon />}
                                            onClick={handleOpen}
                                        >
                                            Add Contact
                                        </Button>
                                        : <></>}
                                </Grid>
                            )}
                            {globalData && globalData.length == 0 ? (
                                <Grid
                                    item
                                    xs={12}
                                    sx={{ textAlign: 'center', marginTop: 2 }}
                                >
                                    <img
                                        src="assets/loader/not_found.gif"
                                        height={'140vh'}
                                    />
                                    <Typography sx={{ fontWeight: 'bold' }}>
                                        No contacts found!
                                    </Typography>
                                    <Typography>
                                        Seems like there aren't any contacts associated with your organization.
                                        <br />
                                    </Typography>
                                    {user?.role === "vendor" ?
                                        <>
                                            <Button
                                                sx={{
                                                    padding: 0.5,
                                                    textTransform: 'none',
                                                    fontSize: '16px',
                                                }}
                                                onClick={e => onRedirect()}
                                            >
                                                Get Started <ArrowRightAltIcon />
                                            </Button>
                                        </>
                                        : <></>}
                                </Grid>
                            ) : (
                                <Grid item xs={12}>
                                    <TableContainer
                                        component={Paper}
                                        sx={{ marginTop: 1, display: { xs: "none", sm: "none", md: "block", lg: "block", xl: "block" } }}
                                    >
                                        <Table aria-label="simple table" size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableHeadRow
                                                        createSortHandler={
                                                            createSortHandler
                                                        }
                                                        valueToOrderBy={valueToOrderBy}
                                                        arr={user?.role === "vendor" ? [
                                                            {
                                                                name: 'NAME',
                                                                alignment: 'left',
                                                                valueToOrderBy:
                                                                    'firstName',
                                                                orderDirection:
                                                                    orderDirection,
                                                            },
                                                            {
                                                                name: 'ROLE',
                                                                alignment: 'center',
                                                                valueToOrderBy: false,
                                                            },
                                                            {
                                                                name: 'ASSOCIATED PRODUCT',
                                                                alignment: 'center',
                                                                valueToOrderBy: false,
                                                            },
                                                            {
                                                                name: 'ACTION',
                                                                alignment: 'center',
                                                                valueToOrderBy: false,
                                                            },
                                                        ] : [
                                                            {
                                                                name: 'NAME',
                                                                alignment: 'left',
                                                                valueToOrderBy:
                                                                    'firstName',
                                                                orderDirection:
                                                                    orderDirection,
                                                            },
                                                            {
                                                                name: 'ROLE',
                                                                alignment: 'center',
                                                                valueToOrderBy: false,
                                                            },
                                                            {
                                                                name: 'ASSOCIATED PRODUCT',
                                                                alignment: 'center',
                                                                valueToOrderBy: false,
                                                            }]}
                                                    />
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {tableData &&
                                                    sortedRowInformation(
                                                        tableData.slice(
                                                            page * rowsPerPage,
                                                            page * rowsPerPage + rowsPerPage
                                                        ),
                                                        getComparator(
                                                            orderDirection,
                                                            valueToOrderBy
                                                        )
                                                    ).map((row: any) => {
                                                        return (
                                                            <TableRow key={row.id}>
                                                                <TableCell align="left" sx={{ width: "290px" }}>
                                                                    <Typography
                                                                        onClick={e =>
                                                                            showProfile(
                                                                                row
                                                                            )
                                                                        }
                                                                        style={linkCol}
                                                                    >
                                                                        <Typography
                                                                            sx={
                                                                                linkHover
                                                                            }
                                                                        >
                                                                            {firstCapital(
                                                                                row.firstName
                                                                            )}
                                                                            {row.lastName
                                                                                ? ' ' +
                                                                                firstCapital(
                                                                                    row.lastName
                                                                                )
                                                                                : ''}
                                                                        </Typography>
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    {updateRowIndex == row.id ? <CircularProgress /> : <>{row.isAdmin ? <Typography sx={{ color: "red", fontWeight: "bold" }}> Admin</Typography> : <Typography> Contact</Typography>}</>}
                                                                </TableCell>
                                                                <TableCell align='center'>
                                                                    <Typography>{row.productId.length}</Typography>
                                                                </TableCell>
                                                                {user?.role === "vendor" ?
                                                                    <>
                                                                        <TableCell align="center">
                                                                            <Box>

                                                                                <Button
                                                                                    color="primary"
                                                                                    variant="outlined"
                                                                                    endIcon={
                                                                                        <ArrowDropDownIcon />
                                                                                    }
                                                                                    aria-label="more"
                                                                                    aria-controls={
                                                                                        'long-menu' +
                                                                                        row
                                                                                    }
                                                                                    aria-haspopup="true"
                                                                                    sx={{
                                                                                        width: 140,
                                                                                        margin: 'auto',
                                                                                    }}
                                                                                    onClick={handleMenuClick(
                                                                                        row
                                                                                    )}
                                                                                >
                                                                                    <Typography
                                                                                        sx={{
                                                                                            fontSize:
                                                                                                '15px',
                                                                                        }}
                                                                                    >
                                                                                        SELECT
                                                                                    </Typography>
                                                                                </Button>

                                                                                <Menu
                                                                                    id={
                                                                                        'long-menu' +
                                                                                        row
                                                                                    }
                                                                                    anchorEl={
                                                                                        anchorEl
                                                                                    }
                                                                                    keepMounted
                                                                                    open={
                                                                                        openElem ===
                                                                                        row
                                                                                    }
                                                                                    onClose={
                                                                                        handleMenuClose
                                                                                    }
                                                                                    anchorOrigin={{
                                                                                        vertical:
                                                                                            'bottom',
                                                                                        horizontal:
                                                                                            'left',
                                                                                    }}
                                                                                    transformOrigin={{
                                                                                        vertical:
                                                                                            'top',
                                                                                        horizontal:
                                                                                            'left',
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        // user?.role==="vendor-admin" && 
                                                                                        <MenuItem disabled={row.isAdmin}>
                                                                                            <Typography
                                                                                                onClick={e =>
                                                                                                    handleMakeAdmin(
                                                                                                        row, "makeAdmin"
                                                                                                    )
                                                                                                }
                                                                                                sx={{
                                                                                                    fontSize:
                                                                                                        '16px',
                                                                                                    cursor: 'pointer',
                                                                                                    color: '#00BFFF',
                                                                                                    '&:hover':
                                                                                                    {
                                                                                                        color: '#0000ff',
                                                                                                    },
                                                                                                }}
                                                                                            >
                                                                                                Make
                                                                                                Admin
                                                                                            </Typography>
                                                                                        </MenuItem>}
                                                                                    {
                                                                                        <MenuItem disabled={disableAdminRevoke || !row.isAdmin}>
                                                                                            <Typography
                                                                                                onClick={e =>
                                                                                                    handleMakeAdmin(
                                                                                                        row, "remove"
                                                                                                    )
                                                                                                }
                                                                                                sx={{
                                                                                                    fontSize:
                                                                                                        '16px',
                                                                                                    cursor: 'pointer',
                                                                                                    color: '#00BFFF',
                                                                                                    '&:hover':
                                                                                                    {
                                                                                                        color: '#0000ff',
                                                                                                    },
                                                                                                }}
                                                                                            >
                                                                                                Revoke
                                                                                            </Typography>
                                                                                        </MenuItem>}
                                                                                    <MenuItem disabled={(!row.productId || row.productId.length == 0)}>
                                                                                        <Typography
                                                                                            onClick={e =>
                                                                                                handleShowProducts(
                                                                                                    row
                                                                                                )
                                                                                            }
                                                                                            style={
                                                                                                linkCol
                                                                                            }
                                                                                        >
                                                                                            <Typography
                                                                                                sx={
                                                                                                    linkHover
                                                                                                }
                                                                                            >
                                                                                                Show
                                                                                                Products
                                                                                            </Typography>
                                                                                        </Typography>
                                                                                    </MenuItem>
                                                                                </Menu>
                                                                            </Box>
                                                                        </TableCell>
                                                                    </>
                                                                    : <></>}
                                                                {/* <TableCell align="left">
                                                            <ModeEditIcon
                                                                onClick={event => {
                                                                    handleEdit(
                                                                        row
                                                                    );
                                                                }}
                                                            />
                                                        </TableCell> */}
                                                            </TableRow>
                                                        );
                                                    })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    {tableData &&
                                        <>
                                            <Grid item xs={12} sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none", xl: "none" } }}>
                                                <TableCardComponent rowData={tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)} userType={"contact"} handleMakeAdmin={handleMakeAdmin} approveLoader={updateRowIndex} disableAdminRevoke={disableAdminRevoke} handleShowProducts={handleShowProducts} showProfile={showProfile} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TablePagination
                                                    rowsPerPageOptions={[5, 10, 15, 20]}
                                                    sx={{
                                                        '.MuiTablePagination-toolbar': {
                                                            alignItems: 'baseline',
                                                        },
                                                        marginBottom: 8
                                                    }}
                                                    component="div"
                                                    count={tableData.length}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                />
                                            </Grid>
                                        </>
                                    }
                                </Grid>
                            )}
                        </Grid>
                    )}
                </>}
        </>
    );
};

export default VendorContacts;
