import { Alert, AlertTitle, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import React from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { PDFObject } from 'react-pdfobject';
import CloseIcon from '@mui/icons-material/Close';

const PreviewDoc = (props:any) => {
    const{showDialog, rowData, handleClose} = props
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    

  return (
    <>
        <Dialog open={showDialog} sx={{
                '.MuiDialog-paper': {
                    position: 'absolute',
                    width: {xs:"90%", sm:"85%", md:"65%", lg:"65%", xl:"65%"},
                    height: '900px',
                    minWidth: {xs:"90%", sm:"85%", md:"65%", lg:"65%", xl:"65%"}
                },
            }}>
            <DialogTitle sx={{ textAlign: "center", fontWeight: 'bold', backgroundColor: "#F0F7FF", fontSize:{xs:"large", sm:"large", md:"34px", lg:"34px", xl:"34px"} }}>
                Legal Document
                    <Tooltip
                        title={`Preview and download your legal document here`}
                        arrow
                        placement="right-end"
                    >
                        <Button>
                            <InfoOutlinedIcon />
                        </Button>
                    </Tooltip>
                <IconButton onClick={handleClose} sx={{ float: "right", color: "red" }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{overflow:"hidded", '&::-webkit-scrollbar': {display: "none"}}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{minHeight:"100%"}}>
                        {rowData?
                        <>
                            <PDFObject width="100%" height="660px" url={`data:application/pdf;base64,${rowData.content}`}/>
                        </>
                        :
                        <>
                            No data found
                        </>}
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    </>
  )
}

export default PreviewDoc