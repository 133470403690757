import React from 'react';
import { Typography, Button, Grid } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
const DealNotFound = (props: any) => {
    const { onRedirect, type, user } = props;
    return (
        <>
            <Grid container>
                <Grid item xs={12} sx={{ textAlign: 'center', marginTop: 2 }}>
                    <img src="assets/loader/not_found.gif" height={'140vh'} />
                    <Typography sx={{ fontWeight: 'bold' }}>
                        No data found!
                    </Typography>
                    <Typography>
                        Seems like you aren't associated with any {type} yet
                        <br />
                        {user?.role === 'connector' ? (
                            <>
                                <Button
                                    sx={{
                                        padding: 0.5,
                                        textTransform: 'none',
                                        fontSize: '16px',
                                    }}
                                    onClick={e => onRedirect()}
                                >
                                    Get Started <ArrowRightAltIcon />
                                </Button>
                            </>
                        ) : (
                            <>
                                <Link to="/admin/activityCentre">
                                    <Button
                                        sx={{
                                            padding: 0.5,
                                            textTransform: 'none',
                                            fontSize: '16px',
                                        }}
                                        onClick={e => onRedirect()}
                                    >
                                        Get Started <ArrowRightAltIcon />
                                    </Button>
                                </Link>
                            </>
                        )}
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
};

export default DealNotFound;
