import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArticleIcon from '@mui/icons-material/Article';
import CancelIcon from '@mui/icons-material/Cancel';
import PublishIcon from '@mui/icons-material/Publish';
import SaveIcon from '@mui/icons-material/Save';
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Grid,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from "react-toastify";
import PaginationLoader from '../../Layout/PaginationLoader';
import StatusCell from '../../Layout/StatusCell';
import TableHeadRow from '../../Layout/TableHeadRow';
import UploadDoument from '../../legalDocs/UploadDoument';
import {
    linkCol,
    linkHover,
    statusColor,
    statusColorText,
} from '../../tableStyle';
import {
    getComparator,
    sortedRowInformation,
} from '../../utils/SortingHandler';
import ShowSignedDoc from './ShowSignedDoc';
import TableCardComponent from './TableCardComponent';
import { convertDate, firstCapital } from './util';

const AdminTable = (props: any) => {
    let {
        user,
        data,
        valueToOrderBy,
        createSortHandler,
        orderDirection,
        checkApprove,
        editable,
        onSave,
        cancelEdit,
        setEditTable,
        rowState,
        handleEdit,
        toggleHandler,
        editLoader,
        onApprove,
        onRevoke,
        approveLoader,
        loader,
        onClickReject,
        page,
        rowsPerPage,
        handleChangeRowsPerPage,
        handleChangePage,
        totalConnectors,
        paginationLoader,
        setCurrentSelection,
        setShowDialog,
        setCurrentEditSelection,
        setSelectionType,
        setEditablePage
    } = props;
    const [rowData, setRowData] = useState(data);
    const [file, setFile] = useState(null);
    const [showUpload, setShowUpload] = useState(true);
    const [show, setShow] = useState(false);
    const [uploadDialog, setUploadDialog] = useState(false);
    const [saveLoader, setSaveLoader] = useState(false);
    const [currentConnector, setCurrentConnector] = useState<any>();
    const [profileDialog, setProfileDialog] = useState<any>();
    // const [currentSelection, setCurrentSelection] = useState<any>();
    const [documentStatus, setDocumentStatus] = useState(null);
    const [typeError, setTypeError] = useState(false);
    const showDocOptions = true;
    const successUpload = () => toast.success("Document uploaded successfully");
    const failureUpload = () => toast.error("There was some error uploading the file, please try again.");
    useEffect(() => {
        setRowData(data);
    }, [data]);

    const handleFileChange = (file: any) => {
        setFile(file);
    };

    const toggleUpload = () => {
        setShowUpload(!showUpload);
        setShow(true);
    };

    const [numPages, setNumPages] = useState(null);

    const onDocumentLoadSuccess = (numPages: any) => {
        setNumPages(numPages);
    };

    const handleUpload = (row: any) => {
        setUploadDialog(true)
        setCurrentConnector(row)
    }

    const fileTypes = {
        'application/pdf': []
    };

    const handleClose = () => {
        setFile(null)
        setUploadDialog(false)
    }
    const handleViewDetails = (row: any) => {
        setCurrentSelection(row);
        setShowDialog(true);
    };


    const handleSubmit = () => {

        setSaveLoader(true)
        const path = documentStatus === "signed" ? "document/register" : "document/admin/register/user";
        axios.post(process.env.REACT_APP_BASE_URL + path, { file },
            {
                headers: {
                    "Content-type": "multipart/form-data",
                    "Authorization": "Bearer " + user.accessToken,
                },
                params: documentStatus === "signed" ? {
                    "userId": currentConnector.id,
                    "type": "connector"
                } :
                    {
                        "userId": currentConnector.id,
                        "documentType": "connector"
                    }
            })
            .catch((error) => {
                failureUpload();
                setSaveLoader(false);
            })
            .then((response: any) => {


                if (response) {
                    successUpload();

                    if (documentStatus === "signed") {
                        const tempArr = response.data.split(" ");
                        const userId = tempArr[tempArr.length - 2];
                        const fileName = tempArr[tempArr.length - 1];
                        var temp = {
                            "id": currentConnector.id,
                            "name": fileName,
                            "isApproved": "approved"
                        };

                        axios.put(process.env.REACT_APP_BASE_URL + 'document/' + userId, temp,
                            {
                                headers: {
                                    "Authorization": "Bearer " + user.accessToken,
                                }
                            })
                    }

                }


                setShowDialog(false)
                setSaveLoader(false)
                handleClose();
            })

    }
    const showProfile = (row: any) => {
        setCurrentSelection(row);
        setProfileDialog(true)
    }

    const [openElem, setOpenElem] = React.useState(null);
    const [menuAnchor, setAnchorEl] = React.useState(null);

    const handleMenuClick = (elem: any) => (event: any) => {
        setAnchorEl(event.currentTarget);
        setOpenElem(elem);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setOpenElem(null);
    };

    const [signedDocDialog, setSignedDocDialog] = useState(false);
    const [viewSignedDocLoader, setViewSignedDocLoader] = useState(false);
    const [error, setError] = useState(false);
    const [viewDocData, setViewDocData] = useState<any>();
    const [empty, setEmpty] = useState(false);
    const [showPdf, setShowPdf] = useState(false);

    const handleCloseSignedDialog = () => {
        setSignedDocDialog(false);
        setViewDocData(null);
        setShowPdf(false);
    }
    const handleViewSignedDialog = (row: any) => {
        setSignedDocDialog(true);
        setViewSignedDocLoader(true);
        setError(false);
        setEmpty(false);
        axios.get(process.env.REACT_APP_BASE_URL + "document/connector/" + row.id,
            {
                headers: {
                    "Authorization": "Bearer " + user.accessToken,
                }
            })
            .catch((error: any) => {

                setError(true);
                setViewSignedDocLoader(false);
            })
            .then((response: any) => {

                setViewDocData(response.data)
                if (response.data.length === 0) {
                    setEmpty(true)
                }
                setViewSignedDocLoader(false);
            })
    }


    return (
        <>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <UploadDoument
                showDialog={uploadDialog}
                file={file}
                handleFileChange={handleFileChange}
                toggleUpload={toggleUpload}
                fileTypes={fileTypes}
                onDocumentLoadSuccess={onDocumentLoadSuccess}
                numPages={numPages}
                show={show}
                handleSubmit={handleSubmit}
                handleClose={handleClose}
                saveLoader={saveLoader}
                setFile={setFile}
                type={"Legal Documents"}
                showDocOptions={showDocOptions}
                setDocumentStatus={setDocumentStatus}
                documentStatus={documentStatus}
                typeError={typeError}
                setTypeError={setTypeError}
            />

            <ShowSignedDoc user={user} signedDocDialog={signedDocDialog} setSignedDocDialog={setSignedDocDialog} handleCloseSignedDialog={handleCloseSignedDialog} error={error} empty={empty} viewDocData={viewDocData} setViewDocData={setViewDocData} showPdf={showPdf} setShowPdf={setShowPdf} />
            {!loader ?
                <Grid container>
                    <TableContainer component={Paper} sx={{ marginTop: 1, display: { xs: "none", sm: "none", md: "block", lg: "block", xl: "block" } }}>
                        <Table aria-label="simple table" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableHeadRow createSortHandler={createSortHandler} valueToOrderBy={valueToOrderBy} arr={[
                                        {
                                            name: 'CONNECTOR',
                                            alignment: 'left',
                                            valueToOrderBy: 'name',
                                            orderDirection: orderDirection
                                        },
                                        {
                                            name: 'DATE',
                                            alignment: 'left',
                                            valueToOrderBy: 'createdDatetime',
                                            orderDirection: orderDirection
                                        }, {
                                            name: 'PHONE',
                                            alignment: 'center',
                                            valueToOrderBy: false,
                                        },
                                        {
                                            name: 'EMAIL',
                                            alignment: 'center',
                                            valueToOrderBy: false,
                                        },
                                        {
                                            name: 'DOCS',
                                            alignment: 'center',
                                            valueToOrderBy: false,
                                        },
                                        {
                                            name: 'ADDRESS',
                                            alignment: 'center',
                                            valueToOrderBy: false,
                                        },
                                        {
                                            name: 'STATUS',
                                            alignment: 'center',
                                            valueToOrderBy: false,
                                        },
                                        {
                                            name: 'ACTION',
                                            alignment: 'center',
                                            valueToOrderBy: false,
                                        },

                                    ]} />
                                </TableRow>
                            </TableHead>
                            {paginationLoader ?
                                <PaginationLoader colspan={8} />
                                :
                                <TableBody>
                                    {rowData &&
                                        sortedRowInformation(
                                            rowData,
                                            getComparator(orderDirection, valueToOrderBy)
                                        ).map((row: any) => {
                                            const isDisabled = checkApprove(row);
                                            return (
                                                <TableRow key={row.id}>
                                                    <TableCell align="left">
                                                        <Typography
                                                            onClick={(e) => { handleViewDetails(row) }}
                                                            style={linkCol}
                                                        >
                                                            <Typography
                                                                sx={linkHover}
                                                            >
                                                                {
                                                                    firstCapital(row.name)
                                                                }
                                                            </Typography>
                                                        </Typography>
                                                    </TableCell>

                                                    <TableCell align="left">
                                                        <Typography sx={{ fontSize: "15px" }}>
                                                            {convertDate(
                                                                row.createdDatetime
                                                            )}
                                                        </Typography>
                                                    </TableCell>

                                                    {row.id === editable ? (
                                                        <>

                                                            <TableCell align="center"><Checkbox name="phoneNumberVerified" checked={rowState.phoneNumberVerified} onChange={e => { toggleHandler(e, rowState) }} color="success" /></TableCell>
                                                            <TableCell align="center"><Checkbox name="emailVerified" checked={rowState.emailVerified} onChange={e => { toggleHandler(e, rowState) }} color="success" /></TableCell>
                                                            <TableCell align="center"><Checkbox name="legalDocsVerified" checked={rowState.legalDocsVerified} onChange={e => { toggleHandler(e, rowState) }} color="success" /></TableCell>
                                                            <TableCell align="center"><Checkbox name="addressVerified" checked={row.addressVerified} disabled color="success" /></TableCell>
                                                            <StatusCell textClr={statusColorText[row.isApproved]} bgClr={statusColor[row.isApproved]} text={firstCapital(row.isApproved)} />
                                                            <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                                                                <Box display="flex" justifyContent="center">
                                                                    {editLoader ?
                                                                        <><CircularProgress size="1.6rem" /></> :
                                                                        <><SaveIcon
                                                                            color='primary'
                                                                            fontSize='large'
                                                                            onClick={event => { onSave(); }} />
                                                                            <CancelIcon
                                                                                color="error"
                                                                                fontSize='large'
                                                                                onClick={event =>
                                                                                    cancelEdit(row)} />
                                                                        </>
                                                                    }
                                                                </Box>
                                                            </TableCell>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {(row.isApproved === "pending" || row.isApproved === "revoked") &&
                                                                <><TableCell align="center"><Checkbox name="phoneNumberVerified" checked={row.phoneNumberVerified} onChange={e => { handleEdit(row); toggleHandler(e, row) }} color="success" /></TableCell>
                                                                    <TableCell align="center"><Checkbox name="emailVerified" checked={row.emailVerified} onChange={e => { handleEdit(row); toggleHandler(e, row) }} color="success" /></TableCell>
                                                                    <TableCell align="center"><Checkbox name="legalDocsVerified" checked={row.legalDocsVerified} onChange={e => { handleEdit(row); toggleHandler(e, row) }} color="success" /></TableCell>
                                                                    <TableCell align="center"><Checkbox name="addressVerified" checked={row.addressVerified} disabled color="success" /></TableCell>
                                                                </>}
                                                            {(row.isApproved === "approved" || row.isApproved === "rejected") &&
                                                                <><TableCell align="center"><Checkbox name="phoneNumberVerified" checked={row.phoneNumberVerified} disabled /></TableCell>
                                                                    <TableCell align="center"><Checkbox name="emailVerified" checked={row.emailVerified} disabled /></TableCell>
                                                                    <TableCell align="center"><Checkbox name="legalDocsVerified" checked={row.legalDocsVerified} disabled /></TableCell>
                                                                    <TableCell align="center"><Checkbox name="addressVerified" checked={row.addressVerified} disabled color="success" /></TableCell>
                                                                </>}
                                                            <StatusCell textClr={statusColorText[row.isApproved]} bgClr={statusColor[row.isApproved]} text={firstCapital(row.isApproved)} />
                                                            <TableCell align="center">
                                                                {approveLoader === row.id ?
                                                                    <CircularProgress size="1.6rem" />
                                                                    : <>
                                                                        {(row.isApproved === 'pending' || row.isApproved === "revoked") && (
                                                                            <Box display={"flex"} justifyContent={"center"}>
                                                                                <Button
                                                                                    color="primary"
                                                                                    variant="outlined"
                                                                                    endIcon={<ArrowDropDownIcon />}
                                                                                    aria-label="more"
                                                                                    aria-controls={"long-menu" + row}
                                                                                    aria-haspopup="true"
                                                                                    sx={{
                                                                                        width: 100,
                                                                                        margin: "auto", pointerEvents: "auto"
                                                                                    }}
                                                                                    onClick={handleMenuClick(row)}
                                                                                >
                                                                                    <Typography sx={{ fontSize: "15px" }}>
                                                                                        SELECT
                                                                                    </Typography>
                                                                                </Button>
                                                                                <Menu
                                                                                    id={"long-menu" + row}
                                                                                    anchorEl={menuAnchor}
                                                                                    keepMounted
                                                                                    open={openElem === row}
                                                                                    onClose={handleMenuClose}
                                                                                    anchorOrigin={{
                                                                                        vertical: 'bottom',
                                                                                        horizontal: 'left',
                                                                                    }}
                                                                                    transformOrigin={{
                                                                                        vertical: 'top',
                                                                                        horizontal: 'left',
                                                                                    }}
                                                                                >
                                                                                    <MenuItem disabled={isDisabled} onClick={(e) => { onApprove(row, "approved") }}>
                                                                                        <Typography sx={{ fontSize: "15px", cursor: "pointer", color: "#00BFFF", '&:hover': { color: "#0000ff" } }}>Approve</Typography>
                                                                                    </MenuItem>
                                                                                    <MenuItem onClick={(e) => onClickReject(row, "rejected")}>
                                                                                        <Typography sx={{ fontSize: "15px", cursor: "pointer", color: "#00BFFF", '&:hover': { color: "#0000ff" } }}>Reject</Typography>
                                                                                    </MenuItem>
                                                                                    <MenuItem onClick={(e) => handleViewSignedDialog(row)}>
                                                                                        <Typography sx={{ fontSize: "15px", cursor: "pointer", color: "#00BFFF", '&:hover': { color: "#0000ff" } }}>Signed <ArticleIcon /></Typography>
                                                                                    </MenuItem>
                                                                                    <MenuItem onClick={(e) => { handleUpload(row) }}>
                                                                                        <Typography sx={{ fontSize: "15px", cursor: "pointer", color: "#00BFFF", '&:hover': { color: "#0000ff" } }}>Upload <PublishIcon /> </Typography>
                                                                                    </MenuItem>
                                                                                    <MenuItem onClick={() => { setEditablePage(true); setSelectionType('connector'); setCurrentEditSelection(row) }}>
                                                                                        <Typography sx={{ fontSize: "15px", cursor: "pointer", color: "#00BFFF", '&:hover': { color: "#0000ff" } }}>Edit</Typography>
                                                                                    </MenuItem>
                                                                                </Menu>
                                                                            </Box>
                                                                        )}

                                                                        {
                                                                            row.isApproved === "approved" && (
                                                                                <Box display={"flex"} justifyContent={"center"}>
                                                                                    <Button
                                                                                        color="primary"
                                                                                        variant="outlined"
                                                                                        endIcon={<ArrowDropDownIcon />}
                                                                                        aria-label="more"
                                                                                        aria-controls={"long-menu" + row}
                                                                                        aria-haspopup="true"
                                                                                        sx={{
                                                                                            width: 100,
                                                                                            margin: "auto"
                                                                                        }}
                                                                                        onClick={handleMenuClick(row)}
                                                                                    >
                                                                                        <Typography sx={{ fontSize: "15px" }}>
                                                                                            SELECT
                                                                                        </Typography>
                                                                                    </Button>
                                                                                    <Menu
                                                                                        id={"long-menu" + row}
                                                                                        anchorEl={menuAnchor}
                                                                                        keepMounted
                                                                                        open={openElem === row}
                                                                                        onClose={handleMenuClose}
                                                                                        anchorOrigin={{
                                                                                            vertical: 'bottom',
                                                                                            horizontal: 'left',
                                                                                        }}
                                                                                        transformOrigin={{
                                                                                            vertical: 'top',
                                                                                            horizontal: 'left',
                                                                                        }}
                                                                                    >
                                                                                        <MenuItem onClick={(e) => onRevoke(row)}>
                                                                                            <Typography sx={{ fontSize: "15px", cursor: "pointer", color: "#00BFFF", '&:hover': { color: "#0000ff" } }}>Revoke</Typography>
                                                                                        </MenuItem>
                                                                                        <MenuItem onClick={(e) => handleViewSignedDialog(row)}>
                                                                                            <Typography sx={{ fontSize: "15px", cursor: "pointer", color: "#00BFFF", '&:hover': { color: "#0000ff" } }}>Signed <ArticleIcon /></Typography>
                                                                                        </MenuItem>
                                                                                        <MenuItem onClick={(e) => { handleUpload(row) }}>
                                                                                            <Typography sx={{ fontSize: "15px", cursor: "pointer", color: "#00BFFF", '&:hover': { color: "#0000ff" } }}>Upload <PublishIcon /> </Typography>
                                                                                        </MenuItem>
                                                                                        <MenuItem onClick={() => { setEditablePage(true); setSelectionType('connector'); setCurrentEditSelection(row) }}>
                                                                                            <Typography sx={{ fontSize: "15px", cursor: "pointer", color: "#00BFFF", '&:hover': { color: "#0000ff" } }}>Edit</Typography>
                                                                                        </MenuItem>
                                                                                    </Menu>
                                                                                </Box>
                                                                            )
                                                                        }

                                                                        {
                                                                            (row.isApproved === "rejected") && (
                                                                                <Typography sx={{ color: '#0f0f0f', fontSize: "15px" }}>
                                                                                    No Action Required
                                                                                </Typography>
                                                                            )
                                                                        }
                                                                    </>}
                                                            </TableCell>

                                                        </>
                                                    )}
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            }

                        </Table>
                    </TableContainer>
                    <Grid item xs={12} sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none", xl: "none" } }}>
                        {rowData &&
                            <TableCardComponent
                                rowData={rowData}
                                checkApprove={checkApprove}
                                rowState={rowState}
                                editable={editable}
                                handleEdit={handleEdit}
                                toggleHandler={toggleHandler}
                                editLoader={editLoader}
                                onSave={onSave}
                                cancelEdit={cancelEdit}
                                onApprove={onApprove}
                                onClickReject={onClickReject}
                                userType={"connector"}
                                approveLoader={approveLoader}
                                onRevoke={onRevoke}
                                showProfile={showProfile}
                                handleViewSignedDialog={handleViewSignedDialog}
                                paginationLoader={paginationLoader}
                            />
                        }
                    </Grid>
                    <Grid item xs={12} sx={{ marginBottom: 8 }}>
                        {rowData && (
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 15, 20]}
                                sx={{
                                    '.MuiTablePagination-toolbar': {
                                        alignItems: 'baseline',
                                    },
                                }}
                                component="div"
                                count={totalConnectors}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        )}
                    </Grid>
                </Grid >
                :
                <Grid container>
                    <Grid item xs={12} sx={{ textAlign: "center", marginTop: 10 }}>
                        <img src="assets/loader/Spinner-px.gif" height={"140vh"} />
                        <Typography sx={{ paddingTop: 2 }}>Please wait while we fetch all connector details</Typography>
                    </Grid>
                </Grid>}
        </>
    );
};

export default AdminTable;
