import { Avatar, Badge, Box, CircularProgress, Divider, Grid, IconButton, ListItemIcon, Menu, MenuItem, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import EmailIcon from '@mui/icons-material/Email';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';
import { Auth } from "aws-amplify";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import axios from 'axios';
import ReplayIcon from '@mui/icons-material/Replay';

const NotificationMenu = (props:any) => {
    const { userInfo } = props;
    const intervalRef = useRef<number>(0);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [notificationsList, setNotificationsList] = useState<any>();
    const [notificationsLoader, setNotificationsLoader] = useState(false);
    const [empty, setEmpty] = useState(true);
    const [unseenCount, setUnseenCount] = useState(0);
    const [onChange, setOnChange] = useState(0);
    const [statusChangeLoader, setStatusChangeLoader] = useState(0);
    const open = Boolean(anchorEl);
    const [user, setUser] = useState<any>();
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const onClickReload = () => {
        setOnChange(prevVal => prevVal+1);
    }

    useEffect (() => {
        getUser();
    },[])

    const getUser = () => {
        Auth.currentAuthenticatedUser()
          .catch(() => {
            setEmpty(true);
          })
          .then(async () => {
            const user = await Auth.currentAuthenticatedUser();
            setUser({email:user.attributes.email, role:user.attributes['custom:role'], name:user.attributes.name, phoneNumber1:user.attributes['custom:phoneNumber'], accessToken:user.storage['CognitoIdentityServiceProvider.'+process.env.REACT_APP_COGNITO_CLIENT_ID+'.'+user.username+'.accessToken']});
          })
    }

    const onClickNotification = (notification:any, newStatus:any) => {
        if(newStatus === notification.status){
            if(window.location.href === notification.url){
                window.location.reload()
            }
            else{
                window.location.href = String(notification.url);
            }
            setAnchorEl(null);
        }
        else{
            setStatusChangeLoader(notification.id)
            const initialStatus = notification.status;
            notification.status = newStatus;
            axios.put(process.env.REACT_APP_BASE_URL+"alert/"+notification.id, notification,
            {
                headers: {
                    "Authorization": "Bearer "+user.accessToken,
                }
            })
            .catch((err:any) => {
                notification.status = initialStatus;
                setStatusChangeLoader(0);
            })
            .then((response:any) => {
                var tempData = [...notificationsList]
                const ind = tempData.findIndex(obj => obj.id === notification.id); 
                tempData[ind] = response.data;
                setNotificationsList(tempData);
                setStatusChangeLoader(0);
                if(newStatus !== "DISMISSED"){
                    if(window.location.href === notification.url){
                        window.location.reload()
                    }
                    else{
                        window.location.href = notification.url;
                    }
                    setAnchorEl(null);
                }
            })
        }
    }
    const fetchAlerts = () => {
        if(user && userInfo){
            setEmpty(false);
            setNotificationsLoader(true);
            var userType = user.role;
            if(user.role === "vendor"){
                userType = "contact"
            }
            axios.get(process.env.REACT_APP_BASE_URL+"alert/all/"+userType+"/"+userInfo.id,
                {
                    headers: {
                        "Authorization": "Bearer "+user.accessToken,
                    }
                })
            .catch((err:any) => {
                setNotificationsLoader(false);
                setEmpty(true);
            })
            .then((response:any) => {
                setNotificationsLoader(false);
                setNotificationsList(response.data);
                if(response.data.length === 0){
                    setEmpty(true);
                }
                var c  = 0;
                response.data.map((record:any) => {
                    if(record.status === "SEEN" || record.status === "UNSEEN"){
                        c += 1;
                    }
                })
                if(c === 0){
                    setEmpty(true);
                }
            })
        }
        else{
            setEmpty(false);
        }
    }

    useEffect(() => {
        if(userInfo){
            setInterval(() => {
                getUser();
              }, 60000);
        }
    },[userInfo])

    useEffect(() => {
        if(user){
            if(userInfo){
                fetchAlerts()
            }
            else{
                setEmpty(false);
            }
        }
        else{
            setEmpty(false);
        }
    }, [userInfo, onChange, user])

    useEffect(() => {
        if(notificationsList){
            var u = 0;
            var s = 0;
            notificationsList.map((notification:any) => {
                if(notification.status === "UNSEEN"){
                    u+=1;
                }
                else if(notification.status === "SEEN"){
                    s+=1;
                }
            })
            setUnseenCount(u);
            if(u+s === 0){
                setEmpty(true);
            }
        }
    },[notificationsList])
    return (
        <>
        <React.Fragment>
        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
            <Tooltip title="Notifications">
                <IconButton sx={{marginRight:2, paddingTop:0, color:"gray-text", borderRadius:"rounded"}} onClick={(e) => handleClick(e)}>
                    <NotificationsActiveIcon sx={{color:"white"}}/>{notificationsList && <Badge badgeContent={unseenCount} sx={{ paddingLeft: 1, paddingBottom:1.5 }} color="error"   />}
                </IconButton>
            </Tooltip>
        </Box>
        
        <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
        elevation: 0,
        sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            padding:2,
            paddingTop:0,
            width:{xs:"90vw", sm:"80vw", md:"50vw", lg:"40vw", xl:"40vw"},
            '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
            },
            '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
            },
        },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
        <Box >
            <Grid container>
                <Grid item xs={6}>
                    <Typography sx={{marginLeft:2, marginTop:1, fontWeight:"bold"}}>Notifications</Typography>
                </Grid>
                <Grid item xs={6} sx={{textAlign:"right"}}>
                    <IconButton onClick = {onClickReload}>
                        <ReplayIcon sx={{color:"blue", fontSize:"large"}}/>
                    </IconButton>
                </Grid>
            </Grid>
        </Box>
        {(empty || notificationsLoader) ? 
        <MenuItem sx={{padding:0, margin:0}}>
            <Grid container sx={{backgroundColor: "#ecf0f9", paddingX:3, paddingY:5, display:"flex", alignItems:"center", justifyContent:"center"}}>
            {empty &&  <Typography noWrap sx={{whiteSpace:"wrap"}}>You don't have any notifications at the moment!</Typography>}
            {notificationsLoader && <CircularProgress/>}
            </Grid>
        </MenuItem>
        :
        <>
            {notificationsList && 
                <>
                {notificationsList.map((notification:any) => {
                    if(notification.status === "SEEN" || notification.status === "UNSEEN"){
                        return(
                            <>
                            <MenuItem sx={{padding:0, margin:0}}>
                                {statusChangeLoader === notification.id ?
                                <Grid container sx={{backgroundColor: "#ecf0f9"}}>
                                    <Grid item xs={12} sx={{padding:4, textAlign:"center"}}>
                                        <CircularProgress/>
                                    </Grid>
                                </Grid>
                                :
                                <Grid container sx={{backgroundColor: "#ecf0f9"}}>
                                    <Grid onClick={() => onClickNotification(notification, "SEEN")} item xs={2} sm={2} md={1} lg={1} xl={1} sx={{marginTop:3, display:"flex", alignItems:"top", justifyContent:"center"}}>
                                        {notification.status === "UNSEEN" &&  <EmailIcon sx={{color:"black", fontSize:20}}/>}
                                        {notification.status === "SEEN" &&  <MarkEmailReadIcon  sx={{color:"black", fontSize:20}}/>}
                                        {notification.status === "DISMISSED" &&  <UnsubscribeIcon  sx={{color:"black", fontSize:20}}/>}
                                    </Grid>
                                    <Grid onClick={() => onClickNotification(notification, "SEEN")} item xs={9} sm={9} md={10} lg={10} xl={10} sx={{marginY:2}}>
                                        <Typography sx={{fontWeight:"bold", display:"inline", marginRight:1, fontSize:"22px"}}>{notification.taskDescription}</Typography>{notification.status === "UNSEEN"&&<FiberManualRecordIcon sx={{color:"red", fontSize:"medium", marginBottom:1}}/>}
                                        <Typography noWrap sx={{whiteSpace:"wrap"}}>{notification.message}</Typography>
                                    </Grid>
                                    {/* <Grid item xs={1} sx={{marginY:1, textAlign:"right"}}>
                                        <IconButton sx={{color:"error"}} >
                                            <ClearIcon color="error" onClick={() => onClickNotification(notification, "DISMISSED")}/>
                                        </IconButton>
                                    </Grid> */}
                                </Grid>
                                }
                            </MenuItem>
                            <Divider sx={{marginY:0, paddingY:0}}/>
                            </>
                        )
                    }
                    else{
                        return;
                    }
                })}
            </>}
        </>}
        {(notificationsList && (!empty || !notificationsLoader)) && 
        <>
            
        </>}
        
    </Menu>
        </React.Fragment>
        </>
    )
}

export default NotificationMenu