import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';

const RegistrationError = (props:any) => {
    const{showDialog, handleClose, type} = props;
  return (
    <Dialog open={showDialog} sx={{width:"100%", justifyContent:"center", height:"100%", backgroundColor:"transparent"}}>
        <DialogActions>
            <Grid container>
                <Grid xs={12} sx={{textAlign:"right"}}>
                    <Button onClick={handleClose}><CloseIcon sx={{color:"red"}}/></Button>
                </Grid>
            </Grid>
        </DialogActions>
        <DialogContent>
            <Grid container>
                <Grid item xs={12} sx={{textAlign:"center", paddingX:6, marginBottom:6, backgroundColor:"transparent"}}>
                    <img src="assets/loader/warning-sign.png" height={"140vh"}/>
                    <Typography sx={{paddingTop:2}}>
                        {type==="duplicate"?
                        <>A registration with the same Vendor, Product and Customer already exists</>
                        :
                        <> Some error occurred, please try again</>}
                    </Typography>
                </Grid>
            </Grid>
        </DialogContent>
    </Dialog>
  )
}

export default RegistrationError