import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Alert, AlertTitle, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, } from '@mui/material';
import PageNameComp from '../PageNameComp';
import axios from 'axios';
import PreviewDoc from './PreviewDoc';
import ErrorComponent from '../Layout/ErrorComponent';
import AddIcon from '@mui/icons-material/Add';
import TableHeadRow from '../Layout/TableHeadRow';
import { getComparator, sortedRowInformation } from '../utils/SortingHandler';
import { linkHover, statusColorText, linkCol, statusColor } from '../tableStyle';
import { convertDate, firstCapital } from '../Admin/Approvals/util';
import TabMenu from '../Layout/TabMenu';
import UploadDoument from './UploadDoument';
import { convertToBase64, handleDownloadClick } from './fileDownloadutil';
import StatusCell from '../Layout/StatusCell';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ToastContainer, toast } from "react-toastify";
import LegalDocsCardTable from './LegalDocsTableCard';
import { Auth } from 'aws-amplify';
import ProfileAlerts from '../Layout/ProfileAlerts';
import DeleteIcon from '@mui/icons-material/Delete';
import ActivitiesHeader from '../utils/ActivitiesHeader';
import DialogComponent from '../utils/DialogComponent';

const LegalDocs = (props: any) => {
    const { screenType } = props;
    const [user, setUser] = useState<any>();
    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .catch(() => {

                window.location.href = String(process.env.REACT_APP_LOGIN_URI)
            })
            .then(async () => {
                const user = await Auth.currentAuthenticatedUser();
                setUser({ email: user.attributes.email, role: user.attributes['custom:role'], name: user.attributes.name, phoneNumber1: user.attributes['custom:phoneNumber'], accessToken: user.storage['CognitoIdentityServiceProvider.' + process.env.REACT_APP_COGNITO_CLIENT_ID + '.' + user.username + '.accessToken'] })
            })
    }, [])
    let role = user?.role;
    const [data, setData] = useState<any>()
    const [showDialog, setShowDialog] = useState(false);
    const [rowData, setRowData] = useState<any>();
    const [userInfo, setUserInfo] = useState<any>();
    const [empty, setEmpty] = useState(false);
    const [error, setError] = useState(false);
    const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>();
    const [valueToOrderBy, setValueToOrderBy] = useState();
    const [userRole, setUserRole] = useState("connector");
    const [typeChangeloader, setTypeChangeLoader] = useState(false);
    const [showUploadDialog, setShowUploadDialog] = useState(false);
    const [file, setFile] = useState(null);
    const [showUpload, setShowUpload] = useState(true);
    const [show, setShow] = useState(false);
    const [saveLoader, setSaveLoader] = useState(false);
    const [change, setChange] = useState(0);
    const [showSignedDocs, setShowSignedDocs] = useState(false);
    const [statusLoader, setStatusLoader] = useState(0);
    const [currentDoc, setCurrentDoc] = useState<any>();
    const [submissionType, setSubmissionType] = useState("");
    const [cardUserType, setCardUserType] = useState("user-admin");
    const [legalCount, setLegalCount] = useState(0);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteInProgress, setDeleteInProgress] = useState(false);
    const [recordId, setRecordId] = useState(0);
    const [recordName, setRecordName] = useState("");
    const successToast = () => toast.success("Document uploaded successfully, Notifications have been sent to users");
    const failureToast = () => toast.error("There was some error in uploading the document, please try again.");
    const deleteFailureToast = () => toast.error("There was some error in deleting the document, please try again.");
    const deleteSuccessToast = () => toast.success("Document deleted successfully");
    useEffect(() => {
        if (user) {
            if (user?.role !== "admin" && screenType !== "myDocs") {
                var userType = user?.role;
                if (user?.role === "vendor") {
                    userType = "contact"
                }
                axios.get(
                    process.env.REACT_APP_BASE_URL +
                    userType +
                    '/mail/' +
                    user?.email,
                    {
                        headers: {
                            "Authorization": "Bearer " + user.accessToken,
                        }
                    }
                )
                    .catch((error: any) => {

                        setError(true);
                    })
                    .then((response: any) => {
                        setUserInfo(response.data)
                        var userId = response.data.id;
                        if (userType === "contact") {
                            userType = "vendor";
                            userId = response.data.vendorId.id;
                        }
                        axios.get(process.env.REACT_APP_BASE_URL + "document/" + userType + "/" + userId,
                            {
                                headers: {
                                    "Authorization": "Bearer " + user.accessToken,
                                }
                            })
                            .then((response: any) => {
                                setLegalCount(response.data.length)
                            })
                    });
            }
        }
    }, [user])
    useEffect(() => {
        if (user) {
            if (screenType === "connectorNetDocs") {
                handleUserTab(0);
                setCardUserType("user-admin")
            }
            else if (screenType === "myDocs") {
                setCardUserType("user")
                if (userInfo) {
                    handleUserTab(1);
                }
                else {
                    if (user?.role !== "admin") {
                        var userType = user?.role;
                        if (user?.role === "vendor") {
                            userType = "contact"
                        }
                        axios.get(
                            process.env.REACT_APP_BASE_URL +
                            userType +
                            '/mail/' +
                            user?.email,
                            {
                                headers: {
                                    "Authorization": "Bearer " + user.accessToken,
                                }
                            }
                        )
                            .catch((error: any) => {
                                setError(true);
                            })
                            .then((response: any) => {
                                setUserInfo(response.data);
                                setShowSignedDocs(true);
                                getSignedDocuments(response.data);
                            });
                    }
                }
            }
            else if (screenType === "connector") {
                getConnectorNetDocs("connector")
                setUserRole("connector")
            }
            else if (screenType === "vendor") {
                setUserRole("vendor")
                getConnectorNetDocs("vendor")
            }
        }
    }, [screenType, user])
    const getConnectorNetDocs = (userType: any) => {
        setTypeChangeLoader(true);
        axios.get(process.env.REACT_APP_BASE_URL + "document/admin/type/" + userType,
            {
                headers: {
                    "Authorization": "Bearer " + user.accessToken,
                }
            })
            .catch((error: any) => {

                setError(true);
                setTypeChangeLoader(false);
            })
            .then((response: any) => {
                if (response.data.length === 0) {
                    setEmpty(true)
                }
                setData(response.data)
                setTypeChangeLoader(false);
            })
    }
    useEffect(() => {
        setEmpty(false);
        if (user) {
            if (user?.role !== "admin" && screenType !== "myDocs") {
                var userType = user?.role;
                if (user?.role === "contact") {
                    userType = "vendor";
                }
                axios.get(process.env.REACT_APP_BASE_URL + "document/admin/type/" + userType,
                    {
                        headers: {
                            "Authorization": "Bearer " + user.accessToken,
                        }
                    })
                    .catch((error: any) => {
                        setError(true);
                    })
                    .then((response: any) => {
                        if (response.data.length === 0) {
                            setEmpty(true)
                        }
                        setData(response.data)
                        setTypeChangeLoader(false);
                    })
            }
            else if (user?.role === "admin" && screenType !== "vendor") {
                getConnectorNetDocs(userRole);
            }
        }
    }, [user, userRole, change])

    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const handleChangePage = (event: any, newPage: any) => {
        setPage(newPage);
    }; const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(+event.target.value);

        setPage(0);
    };
    const [openElem, setOpenElem] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleMenuClick = (elem: any) => (event: any) => {
        setAnchorEl(event.currentTarget);
        setOpenElem(elem);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
        setOpenElem(null);
    };
    const createSortHandler = (property: any) => (event: any) => {
        let isAscending;
        setValueToOrderBy(property);
        isAscending = orderDirection === 'asc';
        setOrderDirection(isAscending ? 'desc' : 'asc');
        sortedRowInformation(data, getComparator(orderDirection, property));
    };

    const [numPages, setNumPages] = useState(null);

    const onDocumentLoadSuccess = (numPages: any) => {

        setNumPages(numPages);
    };

    const onPreview = (row: any) => {

        setShowDialog(true)
        setRowData(row)
    }

    const handleClose = () => {
        setShowDialog(false);
        setRowData({});
    }

    const handleTabSwitch = (itemNo: any) => {
        if (itemNo === 0) {
            setEmpty(false);
            setUserRole("connector")
        }
        else if (itemNo === 1) {
            setEmpty(false);
            setUserRole("vendor")
        }
    }

    const getSignedDocuments = (userData: any) => {
        setEmpty(false);
        setError(false);
        setTypeChangeLoader(true);
        var identification = userData.id;
        if (user?.role === "vendor" || user?.role === "contact") {
            identification = userData.vendorId.id;
        }
        if (userData) {
            var userType = user?.role;
            if (user?.role === "contact") {
                userType = "vendor"
            }
            axios.get(process.env.REACT_APP_BASE_URL + "document/" + userType + "/" + identification,
                {
                    headers: {
                        "Authorization": "Bearer " + user.accessToken,
                    }
                })
                .catch((error: any) => {
                    setError(true);
                })
                .then((response: any) => {
                    setData(response.data)
                    if (response.data.length === 0) {
                        setEmpty(true)
                    }
                    setLegalCount(response.data.length);
                    setTypeChangeLoader(false);
                })
        }
    }

    const handleUserTab = (itemNo: any) => {
        if (itemNo === 0) {
            setEmpty(false);
            setData([])
            setShowSignedDocs(false);
            setTypeChangeLoader(true);
            var userType = user?.role;
            if (user?.role === "contact") {
                userType = "vendor"
            }
            axios.get(process.env.REACT_APP_BASE_URL + "document/admin/type/" + userType,
                {
                    headers: {
                        "Authorization": "Bearer " + user.accessToken,
                    }
                })
                .catch((error: any) => {
                    setError(true);
                })
                .then((response: any) => {
                    if (response.data.length === 0) {
                        setEmpty(true)
                    }
                    setData(response.data)
                    setTypeChangeLoader(false);
                })
        }
        else if (itemNo === 1) {
            setEmpty(false);
            setShowSignedDocs(true);
            getSignedDocuments(userInfo);
        }
    }

    const handleOpenDialog = () => {
        setShowUploadDialog(true)
        setSubmissionType("upload")
    }
    const handleCloseDialog = () => {
        setShowUploadDialog(false);
        setFile(null);
        setStatusLoader(0);
    }
    const onDocumentUpload = () => {
        setSaveLoader(true);
        if (user?.role === "admin") {
            axios.post(process.env.REACT_APP_BASE_URL + "document/admin/register", { file },
                {
                    headers: {
                        "Authorization": "Bearer " + user.accessToken,
                        "Content-type": "multipart/form-data"
                    },
                    params: {
                        "documentType": userRole
                    }
                })
                .catch((error: any) => {
                    setSaveLoader(false);
                    failureToast();
                })
                .then((response: any) => {
                    if (response.data) {
                        setChange(prevval => prevval + 1)
                        successToast();
                        setSaveLoader(false);
                        handleCloseDialog();
                        setLegalCount(prevVal => prevVal + 1)
                    }
                })
        }
        else {
            if (userInfo) {
                var identification = userInfo.id;
                if (user?.role === "vendor" || user?.role === "contact") {
                    identification = userInfo.vendorId.id;
                }
                if (submissionType === "upload") {
                    axios.post(process.env.REACT_APP_BASE_URL + "document/register", { file },
                        {
                            headers: {
                                "Authorization": "Bearer " + user.accessToken,
                                "Content-type": "multipart/form-data"
                            },
                            params: {
                                "userId": identification,
                                "type": user?.role
                            }
                        })
                        .catch((error) => {
                            handleCloseDialog();
                            failureToast();
                            setSaveLoader(false);
                        })
                        .then((response: any) => {
                            if (response.data) {
                                successToast();
                                getSignedDocuments(userInfo)
                                setShowDialog(false)
                                setSaveLoader(false)
                                handleCloseDialog()
                            }
                        })
                }
                else if (submissionType === "update") {
                    setStatusLoader(currentDoc.id);
                    setShowUploadDialog(false);
                    axios.put(process.env.REACT_APP_BASE_URL + "document/upload/" + currentDoc.id, { file },
                        {
                            headers: {
                                "Content-type": "multipart/form-data",
                                "Authorization": "Bearer " + user.accessToken
                            }
                        })
                        .catch((error: any) => {
                            setSaveLoader(false)
                            failureToast()
                        })
                        .then((response: any) => {
                            var tempData = [...data]
                            const ind = tempData.findIndex(obj => obj.id === response.data.id);
                            tempData[ind] = response.data;
                            setData(tempData);
                            successToast();
                            setShowDialog(false)
                            setSaveLoader(false)
                            handleCloseDialog()
                            setSaveLoader(false);
                        })
                }

            }
        }
    }

    const handleFileChange = (file: any) => {
        setFile(file);

    };

    const toggleUpload = () => {
        setShowUpload(!showUpload);

        setShow(true);
    };

    const fileTypes = {
        'application/pdf': []
    };

    const onSelectUpdate = (record: any) => {
        setCurrentDoc(record);
        setStatusLoader(record.id);
        setShowUploadDialog(true);
        setSubmissionType("update");
    }

    const handleDeleteDoc = (id: any) => {
        setDeleteInProgress(true);

        axios.put(process.env.REACT_APP_BASE_URL + "document/admin/delete/" + id, null,
            {
                headers: {
                    "Authorization": "Bearer " + user.accessToken
                }
            })
            .catch((error: any) => {
                if (error) {
                    deleteFailureToast();
                }
            })
            .then((response: any) => {
                if (response) {
                    setOpenDeleteDialog(false);
                    getConnectorNetDocs(userRole);
                    setDeleteInProgress(false);
                    deleteSuccessToast();
                }
            })
    }

    const handleOpenDeleteDialog = () => {
        setOpenDeleteDialog(true);

    }

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };


    return (
        <>
            <DialogComponent
                open={openDeleteDialog}
                handleClose={handleCloseDeleteDialog}
                inProgress={deleteInProgress}
                customTitle={`Are you sure you want to delete ${recordName} ?`}
                customCancelText={"Cancel"}
                customConfirmText={"Delete"}
                id={recordId}
                handleConfirm={handleDeleteDoc}
                cancelColor="primary"
                confirmColor="error"
            />
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />

            <UploadDoument
                showDialog={showUploadDialog}
                file={file}
                handleFileChange={handleFileChange}
                toggleUpload={toggleUpload}
                fileTypes={fileTypes}
                onDocumentLoadSuccess={onDocumentLoadSuccess}
                numPages={numPages}
                show={show}
                handleSubmit={onDocumentUpload}
                handleClose={handleCloseDialog}
                saveLoader={saveLoader}
                setFile={setFile}
                type={"Legal Documents"}
            />
            <PreviewDoc showDialog={showDialog} rowData={rowData} handleClose={handleClose} />
            <ActivitiesHeader
                currentText="Find all Legal Documents associated with your profile"
                heading="Documents"
            />
            <Grid container>
                <Grid container spacing={1}>
                    {error ?
                        <>
                            <Grid item xs={12} sx={{ textAlign: "center" }}>
                                <ErrorComponent />
                            </Grid>
                        </>
                        :
                        <>
                            {(role !== "admin") &&
                                <Grid item xs={12} >
                                    {(userInfo) &&
                                        <>
                                            {role === "connector" ?
                                                <>{userInfo.isApproved === "pending" && <ProfileAlerts userInfo={userInfo} legalCount={legalCount} productCount={0} role={role} />}</>
                                                :
                                                <>
                                                    {userInfo.vendorId.isApproved === "pending" &&
                                                        <ProfileAlerts userInfo={userInfo.vendorId} legalCount={legalCount} productCount={userInfo.vendorId.productCount} role={role} />
                                                    }
                                                </>}

                                        </>}
                                </Grid>
                            }
                            <Grid item xs={6} sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block", xl: "block" } }}>
                                {role === "admin" ?
                                    <>
                                        <TabMenu tabMenu={handleTabSwitch} item={["Connector", "Vendor"]} />
                                    </>
                                    :
                                    <>
                                        <TabMenu tabMenu={handleUserTab} item={["ConnectorNet Documents", "My Documents"]} />
                                    </>}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ textAlign: "right" }}>
                                {(data && !typeChangeloader) && (user?.role === "admin" || showSignedDocs) ?
                                    <Button variant="contained" onClick={handleOpenDialog} >
                                        <AddIcon />Document
                                    </Button>
                                    : <></>}
                            </Grid>
                            {empty ?
                                <>
                                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                                        <img src="assets/loader/not_found.gif" height={'140vh'} />
                                        <Typography sx={{ fontWeight: 'bold' }}>
                                            No Legal documents found!
                                        </Typography>
                                        <Typography>
                                            Unable to find any legal documents associated with your profile
                                        </Typography>
                                    </Grid>
                                </>
                                :
                                <>
                                    {data ?
                                        <>
                                            {!typeChangeloader ?
                                                <>
                                                    <Grid item xs={12}>
                                                        <TableContainer component={Paper} sx={{ marginBottom: 2, display: { sm: "none", xs: "none", md: "block", lg: "block", xl: "block" }, marginTop: 1 }}>
                                                            <Table aria-label="simple table" size="small">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        {showSignedDocs ?
                                                                            <>
                                                                                <TableHeadRow createSortHandler={createSortHandler} valueToOrderBy={valueToOrderBy} arr={[
                                                                                    {
                                                                                        name: 'DOCUMENT NAME',
                                                                                        alignment: 'left',
                                                                                        valueToOrderBy: 'name',
                                                                                        orderDirection: orderDirection
                                                                                    },
                                                                                    {
                                                                                        name: 'UPLOAD DATE',
                                                                                        alignment: 'left',
                                                                                        valueToOrderBy: 'createdDatetime',
                                                                                        orderDirection: orderDirection
                                                                                    },
                                                                                    {
                                                                                        name: 'STATUS',
                                                                                        alignment: 'center',
                                                                                        valueToOrderBy: false
                                                                                    },
                                                                                    {
                                                                                        name: 'ACTION',
                                                                                        alignment: 'center',
                                                                                        valueToOrderBy: false
                                                                                    }
                                                                                ]} />
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <TableHeadRow createSortHandler={createSortHandler} valueToOrderBy={valueToOrderBy} arr={[
                                                                                    {
                                                                                        name: 'DOCUMENT NAME',
                                                                                        alignment: 'left',
                                                                                        valueToOrderBy: 'name',
                                                                                        orderDirection: orderDirection
                                                                                    },
                                                                                    {
                                                                                        name: 'UPLOAD DATE',
                                                                                        alignment: 'left',
                                                                                        valueToOrderBy: 'createdDatetime',
                                                                                        orderDirection: orderDirection
                                                                                    },
                                                                                    {
                                                                                        name: 'ACTION',
                                                                                        alignment: 'center',
                                                                                        valueToOrderBy: false
                                                                                    }
                                                                                ]} />
                                                                            </>}

                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {sortedRowInformation(
                                                                        data.slice(
                                                                            page * rowsPerPage,
                                                                            page * rowsPerPage + rowsPerPage
                                                                        ), getComparator(orderDirection, valueToOrderBy)).map((record: any) => {
                                                                            return (
                                                                                <>

                                                                                    <TableRow>

                                                                                        <TableCell align="left" sx={{ height: 73, width: "28%" }}>
                                                                                            <Tooltip
                                                                                                title={record.name}
                                                                                                arrow
                                                                                                placement="top"
                                                                                            >
                                                                                                <Typography>
                                                                                                    {record.name}
                                                                                                </Typography>
                                                                                            </Tooltip>
                                                                                        </TableCell>
                                                                                        <TableCell align="left" sx={{ height: 73, width: "15%" }}>
                                                                                            <Typography>
                                                                                                {convertDate(record.createdDatetime)}
                                                                                            </Typography>
                                                                                        </TableCell>
                                                                                        {showSignedDocs ?
                                                                                            <>
                                                                                                <StatusCell textClr={statusColorText[record.isApproved]} bgClr={statusColor[record.isApproved]} text={firstCapital(record.isApproved)} />
                                                                                                <TableCell>
                                                                                                    {statusLoader === record.id ?
                                                                                                        <Box display={"flex"} justifyContent={"center"}><CircularProgress size="1.6rem" /></Box>
                                                                                                        :
                                                                                                        <Box display={"flex"} justifyContent={"center"}>
                                                                                                            <Button
                                                                                                                color="primary"
                                                                                                                variant="outlined"
                                                                                                                endIcon={<ArrowDropDownIcon />}
                                                                                                                aria-label="more"
                                                                                                                aria-controls={"long-menu" + record}
                                                                                                                aria-haspopup="true"
                                                                                                                sx={{
                                                                                                                    width: 100,
                                                                                                                    margin: "auto"
                                                                                                                }}
                                                                                                                onClick={handleMenuClick(record)}
                                                                                                            >
                                                                                                                <Typography sx={{ fontSize: "15px" }}>
                                                                                                                    SELECT
                                                                                                                </Typography>
                                                                                                            </Button>
                                                                                                            <Menu
                                                                                                                id={"long-menu" + record}
                                                                                                                anchorEl={anchorEl}
                                                                                                                keepMounted
                                                                                                                open={openElem === record}
                                                                                                                onClose={handleMenuClose}
                                                                                                                anchorOrigin={{
                                                                                                                    vertical: 'bottom',
                                                                                                                    horizontal: 'left',
                                                                                                                }}
                                                                                                                transformOrigin={{
                                                                                                                    vertical: 'top',
                                                                                                                    horizontal: 'left',
                                                                                                                }}
                                                                                                            >
                                                                                                                <MenuItem onClick={(e) => onPreview(record)}>
                                                                                                                    <Typography sx={{ fontSize: "15px", cursor: "pointer", color: "#00BFFF", '&:hover': { color: "#0000ff" } }} >Preview</Typography>
                                                                                                                </MenuItem>
                                                                                                                <MenuItem onClick={(e) => handleDownloadClick(record.content, record.name)}>
                                                                                                                    <Typography sx={{ fontSize: "15px", cursor: "pointer", color: "#00BFFF", '&:hover': { color: "#0000ff" } }}>Download</Typography>
                                                                                                                </MenuItem>
                                                                                                                <MenuItem disabled={record.isApproved === "approved"} onClick={(e) => onSelectUpdate(record)}>
                                                                                                                    <Typography sx={{ fontSize: "15px", cursor: "pointer", color: "#00BFFF", '&:hover': { color: "#0000ff" } }}>Re-Upload</Typography>
                                                                                                                </MenuItem>
                                                                                                            </Menu>
                                                                                                        </Box>}
                                                                                                </TableCell>
                                                                                            </>
                                                                                            :
                                                                                            <>

                                                                                                <TableCell align="center" sx={{ height: 73, width: "25%" }}>


                                                                                                    <Box display={"flex"} justifyContent={"center"}>
                                                                                                        <Button
                                                                                                            color="primary"
                                                                                                            variant="outlined"
                                                                                                            endIcon={<ArrowDropDownIcon />}
                                                                                                            aria-label="more"
                                                                                                            aria-controls={"long-menu" + record}
                                                                                                            aria-haspopup="true"
                                                                                                            sx={{
                                                                                                                width: 100,
                                                                                                                margin: "auto", pointerEvents: "auto"
                                                                                                            }}
                                                                                                            onClick={handleMenuClick(record)}
                                                                                                        >
                                                                                                            <Typography sx={{ fontSize: "15px" }}>
                                                                                                                SELECT
                                                                                                            </Typography>
                                                                                                        </Button>
                                                                                                        <Menu
                                                                                                            id={"long-menu" + record}
                                                                                                            anchorEl={anchorEl}
                                                                                                            keepMounted
                                                                                                            open={openElem === record}
                                                                                                            onClose={handleMenuClose}
                                                                                                            anchorOrigin={{
                                                                                                                vertical: 'bottom',
                                                                                                                horizontal: 'left',
                                                                                                            }}
                                                                                                            transformOrigin={{
                                                                                                                vertical: 'top',
                                                                                                                horizontal: 'left',
                                                                                                            }}
                                                                                                        >
                                                                                                            <MenuItem onClick={(e) => handleDownloadClick(record.content, record.name)}>
                                                                                                                <Typography sx={{ fontSize: "15px", cursor: "pointer", color: "#00BFFF", '&:hover': { color: "#0000ff" } }}>Download</Typography>
                                                                                                            </MenuItem>
                                                                                                            <MenuItem onClick={(e) => onPreview(record)}>
                                                                                                                <Typography sx={{ fontSize: "15px", cursor: "pointer", color: "#00BFFF", '&:hover': { color: "#0000ff" } }}>Preview</Typography>
                                                                                                            </MenuItem>
                                                                                                            {user?.role === "admin" && <MenuItem onClick={() => { setRecordId(record.id); setRecordName(record.name); handleOpenDeleteDialog() }}>
                                                                                                                <Typography sx={{ fontSize: "15px", cursor: "pointer", '&:hover': { color: "#0000ff" } }} color="error">Delete</Typography>
                                                                                                            </MenuItem>}

                                                                                                        </Menu>
                                                                                                    </Box>

                                                                                                </TableCell>
                                                                                            </>}
                                                                                    </TableRow>



                                                                                </>
                                                                            )
                                                                        })}
                                                                </TableBody>
                                                            </Table>

                                                        </TableContainer>
                                                        <Grid item xs={12} sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none", xl: "none" } }}>
                                                            {data && <LegalDocsCardTable
                                                                rowData={data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                                                                showCurrentDoc={onPreview}
                                                                userType={cardUserType}
                                                                statusLoader={statusLoader}
                                                                onSelectUpdate={onSelectUpdate}
                                                                deleteInProgress={deleteInProgress}
                                                                handleOpenDeleteDialog={handleOpenDeleteDialog}
                                                                handleCloseDeleteDialog={handleCloseDeleteDialog}
                                                                handleDeleteDoc={handleDeleteDoc}
                                                                setRecordId={setRecordId}
                                                                setRecordName={setRecordName}
                                                                user={user}

                                                            />}
                                                        </Grid>
                                                        {(data) && (
                                                            <TablePagination
                                                                rowsPerPageOptions={[5, 10, 15]}
                                                                sx={{
                                                                    '.MuiTablePagination-toolbar': {
                                                                        alignItems: 'baseline',
                                                                    },
                                                                    marginBottom: 8
                                                                }}
                                                                component="div"
                                                                count={data.length}
                                                                rowsPerPage={rowsPerPage}
                                                                page={page}
                                                                onPageChange={handleChangePage}
                                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                            />
                                                        )}
                                                    </Grid>
                                                </>
                                                :
                                                <>
                                                    <Grid item xs={12} sx={{ textAlign: "center", marginTop: 10 }}>
                                                        <img src="assets/loader/Spinner-px.gif" height={"140vh"} />
                                                        <Typography sx={{ paddingTop: 2 }}>Please wait while we fetch all legal documents</Typography>
                                                    </Grid>
                                                </>}
                                        </>
                                        :
                                        <>
                                            <Grid item xs={12} sx={{ textAlign: "center", marginTop: 10 }}>
                                                <img src="assets/loader/Spinner-px.gif" height={"140vh"} />
                                                <Typography sx={{ paddingTop: 2 }}>Please wait while we fetch all legal documents</Typography>
                                            </Grid>
                                        </>}
                                </>}
                        </>}
                </Grid>
            </Grid>
        </>
    );
};

export default LegalDocs;
