import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, HashRouter, Routes, Route } from 'react-router-dom';
import HomePage from './Landing Page/HomePage';
import ConnectorPage from './Landing Page/ConnectorPage';
import PartnerPage from './Landing Page/PartnerPage';
import AboutUs from './Landing Page/AboutUs';
import Login from './Web Authentication/Login';
import SignUp from './Web Authentication/SignUp';
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import AboutUsInfo from './Landing Page/AboutUsInfo';
import ScrollToTop from './ScrollToTop';

Amplify.configure({
    Auth: {
        region: process.env.REACT_APP_COGNITO_REGION,
        userPoolId: process.env.REACT_APP_COGNITO_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    },
});

ReactDOM.render(
    <React.StrictMode>
        <HashRouter>
            <Routes>
                <Route path="/register/*" element={<SignUp />} />
                <Route path="/admin/*" element={<App />} />
                <Route path="/conn_intro" element={<ConnectorPage />} />
                <Route path="/partner_intro" element={<PartnerPage />} />
                <Route path="/about_us" element={<AboutUs />} />
                <Route path="/about_us/info/*" element={<AboutUsInfo />} />
                <Route path="/" element={<HomePage />} />
                <Route path="/login/*" element={<Login />} />
            </Routes>
            <ScrollToTop />
        </HashRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
