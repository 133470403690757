import React, { useState, useEffect } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Box, Button, DialogContent, Divider, FormControl, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { format } from 'date-fns';
import { stageNames } from '../deals/stages';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import TrashIcon from '@mui/icons-material/Delete';
import { convertDate, convertCurrency } from '../Admin/Approvals/util';
import CloseIcon from '@mui/icons-material/Close';

const DealShowDialog = (props: any) => {
    const { onClose, open, row } = props;
    const [note, setNote] = useState('');
    const [rowData, setRowData] = useState(row);
    const [edit, setEdit] = useState(false);
    
    const [descriptionCol, setDescriptionCol] = useState(row.description);
    const [typeCol, setTypeCol] = useState(row.type);
    const [amtCol, setAmtCol] = useState(row.amount);
    const [startCol, setStartCol] = useState(row.created_at);
    const [stageCol, setStageCol] = useState(row.stage);

    // useEffect(() => {
    //     
    //     setRowData(row);
    // }, [row])
    // let amount, created_at;
    // amount = row.amount?.toLocaleString(
    //     'en-US',
    //     {
    //         notation: 'compact',
    //         style: 'currency',
    //         currency: 'USD',
    //         currencyDisplay:
    //             'narrowSymbol',
    //         minimumSignificantDigits: 3,
    //     }
    // )
    // created_at = row.created_at?.substr(0, 10);
    

    useEffect(() => {
        
    })
    const [notesArr, setNotesArr] = useState<String[]>([]);

    const HandleDescriptionChanged = (event: any) => {
        setDescriptionCol(event.target.value);
    }
    const HandleTypeChanged = (event: any) => {
        setTypeCol(event.target.value);
    }
    const HandleAmountChanged = (event: any) => {
        setAmtCol(event.target.value);
    }
    const HandleStartChanged = (event: any) => {
        setStartCol(event.target.value);
    }
    const HandleStageChanged = (event: any) => {
        setStageCol(event.target.value);
    }
    const HandleNoteChanged = (event: any) => {
        setNote(event?.target.value)
    }
    const HandleAddToNotesArr = (note: String) => {
        let newNotes = [...notesArr];
        newNotes.push(note);
        setNote('');
        
        setNotesArr(newNotes);
    }

    const HandleEdit = () => {
        setEdit(true);
    }
    const HandleSave = () => {
        const data = { description: descriptionCol, amount: amtCol, createdAt: startCol, type: typeCol, stage: stageCol };
        
        setRowData(data);
        setEdit(false);
    }
    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="md"
            sx={{
                '.MuiDialog-paper': {
                    position: 'absolute',
                    top: 50,
                },
            }}
        >
            <DialogContent>
                <Box display="flex">
                    <Box
                        width={100}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                    >
                    </Box>
                    {row && <Box ml={2} flex="1">
                    <IconButton onClick={() => onClose()} sx={{float: "right"}}><CloseIcon /></IconButton>
                        <Typography variant="h5">{rowData.name} </Typography>
                       
                        <Box display="flex" mt={2}>
                            <Box display="flex" mr={5} flexDirection="column">
                                <Typography color="textSecondary" variant="body2">
                                    Start
                                </Typography>
                                {!edit && <Typography variant="subtitle1">
                                    {convertDate(row.modifiedDatetime)}
                                </Typography>}
                                {edit && <TextField
                                    variant="filled"
                                    size="small"
                                    fullWidth
                                    onChange={HandleStartChanged}
                                    value={row.modifiedDatetime}
                                />}
                            </Box>

                            <Box display="flex" mr={5} flexDirection="column">
                                <Typography color="textSecondary" variant="body2">
                                    Type
                                </Typography>
                                {edit && <TextField
                                    variant="filled"
                                    size="small"
                                    fullWidth
                                    onChange={HandleTypeChanged}
                                    value={typeCol}
                                />}
                                {!edit && <Typography variant="subtitle1">
                                    {rowData.type}
                                </Typography>}
                            </Box>
                            <Box display="flex" mr={5} flexDirection="column">
                                <Typography color="textSecondary" variant="body2">
                                    Amount
                                </Typography>
                                {!edit && <Typography variant="subtitle1">
                                    {convertCurrency(rowData.amount)}
                                </Typography>}
                                {
                                    edit && <TextField
                                        variant="filled"
                                        size="small"
                                        fullWidth
                                        onChange={HandleAmountChanged}
                                        value={convertCurrency(amtCol)}
                                    />
                                }
                            </Box>

                            <Box display="flex" mr={5} flexDirection="column">
                                <Typography color="textSecondary" variant="body2">
                                    Stage
                                </Typography>
                                {!edit && <Typography variant="subtitle1">
                                    {/* @ts-ignore */}
                                    {stageNames[rowData.stage]}
                                </Typography>}
                                {
                                    edit && <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
                                        <InputLabel id="demo-simple-select-filled-label">Stage</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-filled-label"
                                            id="demo-simple-select-filled"
                                            value={stageCol}
                                            onChange={HandleStageChanged}
                                        >
                                            <MenuItem value="opportunity">Opportunity</MenuItem>
                                            <MenuItem value="signed">Contract Signed</MenuItem>
                                            <MenuItem value="in-negociation">Customer Introduction</MenuItem>
                                            <MenuItem value="won">Vendor Approved</MenuItem>
                                            <MenuItem value="delayed">Delayed</MenuItem>                                         
                                            <MenuItem value="lost">Lost</MenuItem>
                                            <MenuItem value="proposal-sent">Proposal Sent</MenuItem>
                                           
                                        </Select>
                                    </FormControl>
                                }
                            </Box>

                            <Box display="flex" ml={18} flexDirection="column">
                                {!edit && <ModeEditIcon onClick={HandleEdit} />}
                                {edit && <SaveIcon onClick={HandleSave} />}
                            </Box>
                        </Box>

                        {!edit && <Box mt={2} mb={2} style={{ whiteSpace: 'pre-line' }}>
                            <Typography color="textSecondary" variant="body2">
                                Description
                            </Typography>
                            <Typography>{rowData.description}</Typography>
                        </Box>}

                        {edit && <Box mt={2} mb={2} style={{ whiteSpace: 'pre-line' }}>
                            <Typography color="textSecondary" variant="body2">
                                Description
                            </Typography>
                            <TextField
                                variant="filled"
                                size="small"
                                fullWidth
                                defaultValue={rowData.description}
                                onChange={HandleDescriptionChanged}
                                value={descriptionCol}
                                multiline
                                rows={5}
                            />
                        </Box>}

                        <Divider />
                        <Box mt={2} mb={1} style={{ whiteSpace: 'pre-line' }}>
                            <TextField
                                label="Add a note"
                                variant="filled"
                                size="small"
                                fullWidth
                                value={note}
                                onChange={HandleNoteChanged}
                                multiline
                                rows={3}
                            />
                        </Box>
                        <Box sx={{ textAlign: "right" }}>
                            <Button variant="contained" disabled={note?.length == 0} onClick={() => HandleAddToNotesArr(note)}>
                                <Typography>Add Note</Typography>
                            </Button>
                        </Box>
                        {
                            notesArr.length > 0 && notesArr.map((noteItem) => {
                                
                                return (
                                    <Box mt={2} mb={1} p={2} style={{ whiteSpace: 'pre-line', backgroundColor: "#dbe7e4", borderRadius: '10px',display: 'flex',
                                    alignItems: 'stretch' }} key={Math.random() * 10}>
                                        <Box flex={1}>
                                        <Typography>{noteItem}</Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                marginLeft: 2,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-around',
                                                // visibility: isHover ? 'visible' : 'hidden',
                                            }}
                                        >
                                            <Tooltip title="Edit note">
                                                <IconButton
                                                    size="small"
                                                // onClick={handleEnterEditMode}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Delete note">
                                                <IconButton size="small"
                                                //  onClick={handleDelete}
                                                >
                                                    <TrashIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </Box>)
                            })}

                    </Box>}
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default DealShowDialog