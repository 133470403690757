import { Box, CircularProgress, Card, Grid, Typography, Button } from '@mui/material';
import React from 'react'
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import DescriptionIcon from '@mui/icons-material/Description';
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { statusColor, statusColorText } from '../tableStyle';
import { convertDate, firstCapital } from '../Admin/Approvals/util';
import { handleDownloadClick } from './fileDownloadutil';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteIcon from '@mui/icons-material/Delete';
const LegalDocsCardTable = (props: any) => {
    const { rowData, onApprove, onClickReject, showCurrentDoc, userType, statusLoader, onSelectUpdate, user,
        
        handleOpenDeleteDialog,
        
        setRecordId,setRecordName
    } = props;

    return (
        <Box>
            {rowData.map((row: any) => {
                return (
                    <>
                        <Card sx={{ width: "100%", padding: 3, marginY: 2, boxShadow: 3, shadow: 0, border: 0 }}>
                            <Grid container>
                                <Grid item xs={1}>
                                    <DescriptionIcon />
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography
                                        onClick={() => showCurrentDoc(row)}
                                        sx={{
                                            fontWeight: "bold",
                                            fontSize: (userType === "admin" || userType === "brochure") ? "16px" : "20px",
                                            textTransform: "none",
                                            whiteSpace: 'nowrap',
                                            textDecoration: 'none',
                                            color: "#00BFFF",
                                            display: "inline",
                                        }}
                                    >
                                        {row.name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} />
                                <Grid item xs={11}>
                                    <Typography variant="caption" sx={{ fontWeight: "light" }}>Uploaded: {convertDate(row.createdDatetime)}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    {(userType !== "user-admin" && userType !== "brochure") ?
                                        <Grid container sx={{ backgroundColor: userType === "connector" ? "#fff5e8" : "", height: "70px", justifyContent: "center", alignItems: "center" }}>
                                            <Grid item xs={6}>
                                                Status
                                            </Grid>
                                            <Grid item xs={6} sx={{ justifyContent: "right", display: "flex", alignItems: "right" }}>
                                                <Box
                                                    sx={{
                                                        backgroundColor: statusColor[row.isApproved],
                                                        color: statusColorText[row.isApproved],
                                                        padding: 1,
                                                        width: 110,
                                                        borderRadius: 12,
                                                        whiteSpace: "noWrap"
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontWeight: 'bold',
                                                            textAlign: 'center',
                                                            fontSize: "15px"
                                                        }}
                                                    >
                                                        {firstCapital(row.isApproved)}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        :
                                        <Grid item xs={12} sx={{ marginY: 2 }} />
                                    }
                                    <Grid container sx={{ backgroundColor: "#fff5e8", height: "55px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        {userType === "admin" ?
                                            <>
                                                {statusLoader === row.id ?
                                                    <CircularProgress size="1.6rem" />
                                                    :
                                                    <>
                                                        <Button sx={{ flexDirection: "column", marginX: 2, color: "green" }} disabled={(row.isApproved === "approved")} onClick={(e) => { onApprove(row, "approved") }}>
                                                            <VerifiedUserIcon />
                                                            <span style={{ fontSize: "small" }}>Approve</span>
                                                        </Button>
                                                        <Button sx={{ flexDirection: "column", color: "red", marginX: 2 }} disabled={(row.isApproved === "rejected")} onClick={(e) => onClickReject(row, "rejected")}>
                                                            <ContentPasteOffIcon />
                                                            <span style={{ fontSize: "small" }}>Reject</span>
                                                        </Button>
                                                        
                                                    </>}
                                            </>
                                            :
                                            <>
                                                {statusLoader === row.id ?
                                                    <CircularProgress size="1.6rem" />
                                                    :
                                                    <>
                                                        <Button sx={{ flexDirection: "column", marginX: 1 }} onClick={(e) => { showCurrentDoc(row) }}>
                                                            <VisibilityIcon fontSize='small' />
                                                            <span style={{ fontSize: "small" }}>Preview</span>
                                                        </Button>
                                                        <Button sx={{ flexDirection: "column", marginX: 1 }} disabled={(row.isApproved === "rejected")} onClick={(e) => handleDownloadClick(row.content, row.name)}>
                                                            <DownloadIcon fontSize='small' />
                                                            <span style={{ fontSize: "small" }}>Download</span>
                                                        </Button>
                                                       {user?.role === "admin" &&  <Button sx={{ flexDirection: "column", color: "red", marginX: 2 }} onClick={() => { setRecordId(row.id); setRecordName(row.name); handleOpenDeleteDialog() }}>
                                                            <DeleteIcon color='error' />
                                                            <span style={{ fontSize: "small" }}>Delete</span>
                                                        </Button>}
                                                        {userType === "user" &&
                                                            <Button sx={{ flexDirection: "column", marginX: 1 }} disabled={(row.isApproved === "approved")} onClick={(e) => onSelectUpdate(row)}>
                                                                <RefreshIcon fontSize='small' />
                                                                <span style={{ fontSize: "small" }}>Re-Upload</span>
                                                            </Button>
                                                        }
                                                    </>
                                                }
                                            </>}
                                    </Grid>
                                </Grid>
                                {userType === "user" &&
                                    <Typography variant="caption" sx={{ fontWeight: "light" }}>
                                        {row.isApproved === "approved" && "Your document has been approved!"}
                                        {row.isApproved === "pending" && "Please wait for admin's response"}
                                        {row.isApproved === "rejected" && "Your document has been rejected!"}
                                    </Typography>
                                }
                                {(userType === "user-admin" && user?.role !== "admin") &&
                                    <Grid item xs={12}>
                                        <Typography variant="caption" sx={{ fontWeight: "light" }}>Please sign and upload in "My Documents" for verification</Typography>
                                    </Grid>
                                }
                                {(userType === "user-admin") && user?.role === "admin" &&
                                    <Grid item xs={12}>
                                        <Typography variant="caption" sx={{ fontWeight: "light" }}>Please find the signed copies in user approval section</Typography>
                                    </Grid>
                                }
                            </Grid>
                        </Card>
                    </>
                )
            })}
        </Box>
    )
}

export default LegalDocsCardTable