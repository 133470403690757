import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
    Autocomplete, Box, Button, Checkbox, CircularProgress, Grid, IconButton, Menu, MenuItem, TablePagination, TextField, Typography,
} from '@mui/material';
import EnhancedTableToolbar from '../misc/EnhancedTableToolbar';
import AddIcon from '@mui/icons-material/Add';
import './styles.css';
import { convertDate, firstCapital } from '../Admin/Approvals/util';
import axios from 'axios';
import RegistrationForm from './RegistrationForm';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {
    linkCol,
    linkHover,
    statusColor,
    statusColorText,
} from '../tableStyle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import TableHeadRow from '../Layout/TableHeadRow';
import StatusCell from '../Layout/StatusCell';
import InvitationDialog from './InvitationDialog';
import DealNotFound from './DealNotFound';
import ConfirmationDialog from './ConfirmationDialog';
import ProfileScreen from "../Profile Screens/ProfileScreen"
import RegistrationError from './RegistrationError';
import FilterComponent from '../Layout/FilterComponent';
import { getComparator, sortedRowInformation } from '../utils/SortingHandler';
import SearchTextField from '../Layout/SearchTextField';
import { CreateButton } from 'react-admin';
import RegistrationCardComponent from './RegistrationCardComponent';
export default function RegisterDeal(props: any) {
    const { user, userInfo } = props;
    const [rowData, setRowData] = useState<any>();
    const [globalData, setGlobalData] = useState<any>();
    const initialData = {
        id: 0,
        vendorId: { id: 0, name: "" },
        connectorId: { id: 0, name: "", email: "" },
        productId: { id: 0, name: "" },
        customerId: { id: 0, name: "" },
        isApproved: false,
        createdBy: user.name,
        modifiedBy: user.name,
        status: "pending"
    };
    const [add, setAdd] = useState(1);
    const [selected, setSelected] = useState<readonly string[]>([]);
    const [deletable, setDeletable] = useState(false);
    const [formData, setFormData] = useState(initialData);
    const [selectedOption, setSelectedOption] = useState(null);
    const [vendorList, setVendorList] = useState<any>();
    const [customerList, setCustomerList] = useState<any>([]);
    const [productList, setProductList] = useState<any>();
    const [showDialog, setShowDialog] = useState(false);
    const [showInvitationDialog, setShowInvitationDialog] = useState(false);
    const [invitationData, setInvitationData] = useState(initialData);
    const [errorType, setErrorType] = useState("error")
    const [prodLoader, setProdLoader] = useState(false);
    const [formInfo, setFormInfo] = useState(false);
    const [showProduct, setShowProduct] = useState(true);
    const [currentVendor, setCurrentVendor] = useState<any>();
    const [currentCustomer, setCurrentCustomer] = useState<any>();
    const [currentProduct, setCurrentProduct] = useState<any>();
    const [cardVendor, setCardVendor] = useState(false);
    const [cardProduct, setCardProduct] = useState(false);
    const [cardCustomer, setCardCustomer] = useState(false);
    const [disableSave, setDisableSave] = useState(true)
    const [saveLoader, setSaveLoader] = useState(false)
    const [error, setError] = useState(false);
    const [loader, setLoader] = useState(false);
    const [formLoader, setFormLoader] = useState(true)
    const connector = userInfo;
    const [customerInviteContact, setCustomerInviteContact] = useState<any>([]);
    const [empty, setEmpty] = useState(false);
    const [registrationError, setRegistrationError] = useState(false);
    const [disableCustomer, setDisableCustomer] = useState(true);
    const [customerSearchError, setCustomerSearchError] = useState({ error: false, message: "" })
    const [customerSearchLoader, setCustomerSearchLoader] = useState(false);
    useEffect(() => {
        setLoader(true);
        if (user) {
            axios
                .get(process.env.REACT_APP_BASE_URL + 'vendor/form',
                    {
                        headers: {
                            "Authorization": "Bearer " + user.accessToken,
                        }
                    })
                .catch((error: any) => {

                })
                .then((response: any) => {
                    setVendorList(response.data);
                });
            // axios
            //     .get(process.env.REACT_APP_BASE_URL + 'customer/form',
            //         {
            //             headers: {
            //                 "Authorization": "Bearer " + user.accessToken,
            //             }
            //         })
            //     .catch((error: any) => {

            //     })
            //     .then((response: any) => {
            //         setCustomerList(response.data);
            //     });
        }
    }, [user]);

    useEffect(() => {
        setLoader(true)
        if (user) {
            if (user.role === "admin") {
                axios
                    .get(process.env.REACT_APP_BASE_URL + 'registration',
                        {
                            headers: {
                                "Authorization": "Bearer " + user.accessToken,
                            }
                        })
                    .catch((error: any) => {

                    })
                    .then((response: any) => {

                        setRowData(response.data);
                        setGlobalData(response.data)
                        setLoader(false);
                    });
            }
            else if (user.role === "connector") {
                axios.get(process.env.REACT_APP_BASE_URL + "registration/form/connector/" + userInfo.id,
                    {
                        headers: {
                            "Authorization": "Bearer " + user.accessToken,
                        }
                    })
                    .catch((error: any) => {

                        setLoader(false)
                    })
                    .then((response: any) => {
                        setLoader(false);

                        if (response.data.length === 0) {
                            setEmpty(true)
                            onRedirect();
                        }
                        else {
                            setRowData(response.data);
                            setGlobalData(response.data);
                        }
                    })
            }
        }
    }, [add, user])

    const handleCheckClick = (event: any, id: any) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: any = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const isSelected = (id: any) => selected.indexOf(id) !== -1;

    const handleDelete = () => {
        setDeletable(!deletable);
    };

    const onDelete = () => {


        var temp = rowData;
        selected.map(record => {
            temp = temp.filter((item: any) => item.id !== record);
        });
        setRowData(temp);
        setSelected([]);
    };

    const getOptions = (option: any) => {
        if (option) {
            return option.name;
        }
        return selectedOption || '';
    };

    const handleChange = (event: any, newVal: any) => {
        let ind = event.target.id.indexOf('-');
        let key = event.target.id.substring(0, ind);
        if (key === 'vendorId') {
            setFormData({ ...formData, vendorId: { id: newVal.id, name: newVal.name } });
            setError(false)
            setCardVendor(true);
            setCurrentVendor(newVal);
            setFormInfo(false);
            setFormLoader(true);
            setDisableCustomer(true);
            setDisableSave(true);
            setCurrentProduct({ id: 0, name: "" })
            setCurrentCustomer({ id: 0, name: "" })
            setCardCustomer(false);
            setCardProduct(false);
            axios
                .get(
                    process.env.REACT_APP_BASE_URL + 'product/form/' + newVal.id,
                    {
                        headers: {
                            "Authorization": "Bearer " + user.accessToken,
                        }
                    }
                )
                .catch((error: any) => {

                    setShowProduct(true);
                    setError(true)
                    setFormLoader(false)
                })
                .then((response: any) => {

                    setProductList(response.data);
                    setShowProduct(false);
                    setFormLoader(false)
                });
        } else if (key === 'customerId') {
            setFormData({ ...formData, [key]: { id: newVal.id, name: newVal.name } });
            setCardCustomer(true);
            setCurrentCustomer(newVal);
            setFormInfo(false);
            setDisableSave(false)
        } else if (key === 'productId') {
            setFormData({ ...formData, productId: { id: newVal.id, name: newVal.name } });
            setCardProduct(true);
            setFormInfo(false);
            setCurrentProduct(newVal);
            setDisableCustomer(false);
        }

    };

    const handleClose = () => {
        setFormData(initialData);
        setShowDialog(false);
        setProdLoader(false);
        setCardCustomer(false);
        setCardVendor(false);
        setCardProduct(false);
        setShowProduct(true);
        setSaveLoader(false);
        setDisableSave(true);
        setDisableCustomer(true);
        setCurrentVendor({ id: 0, name: "" });
        setCurrentProduct({ id: 0, name: "" });
        setCurrentCustomer({ id: 0, name: "" });
    };

    const handleRegister = () => {
        setFormData({ ...formData, connectorId: { id: connector.id, name: connector.name, email: connector.email } })

        if (!saveLoader) {
            setSaveLoader(true)
            axios
                .post(
                    process.env.REACT_APP_BASE_URL + 'registration/register',
                    formData,
                    {
                        headers: {
                            "Authorization": "Bearer " + user.accessToken,
                        }
                    }
                )
                .catch((error) => {

                    setSaveLoader(false)
                    if (error.response.status === 409) {
                        setErrorType("duplicate")
                    }
                    else {
                        setErrorType("error")
                    }
                    setRegistrationError(true)
                    setShowDialog(false);
                })
                .then((response: any) => {
                    if (response.data) {
                        handleClose()
                        setSaveLoader(false);
                        setEmpty(false)
                        setAdd(prevVal => prevVal + 1)
                    }
                });
        }
    };

    useEffect(() => {
        if (connector && vendorList && customerList) {
            setFormLoader(false)
        }
    }, [connector, vendorList, customerList])
    const [newCustomerLoader, setNewCustomerLoader] = useState(false);
    const [fetchLoader, setFetchLoader] = useState(false);
    const [fetchProdContactLoader, setFetchProdContactLoader] = useState(false);
    const [page, setPage] = useState(0);
    const [profileDialog, setProfileDialog] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [contactId, setContactId] = useState<any>([]);
    const [currentSelection, setCurrentSelection] = useState<any>()
    const [prodContactId, setProdContactId] = useState<any>();
    const [productContacts, setProductContacts] = useState<any>([]);
    const handleChangePage = (event: any, newPage: any) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(+event.target.value);

        setPage(0);
    };
    const [type, setType] = useState("");
    const [invitationSentId, setInvitationSentId] = useState(-1);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [confirmationRow, setConfirmationRow] = useState(null);
    const handleConfirmationDialogClose = () => {
        setShowConfirmationDialog(false);
    }

    const handleInvitationSent = (row: any, action: any) => {
        // setInvitationSentLoader(true);
        setAnchorElOption(null)
        setInvitationSentId(row.id);
        let temp = [...rowData];
        const ind = temp.findIndex(obj => obj.id === row.id);
        temp[ind].status = action;

        axios
            .put(
                process.env.REACT_APP_BASE_URL + 'registration/' + row.id,
                temp[ind],
                {
                    headers: {
                        "Authorization": "Bearer " + user.accessToken,
                    }
                }
            )
            .catch((error: any) => {

                // setInvitationSentLoader(false);
            })
            .then((response: any) => {

                // setInvitationSentLoader(false);
                setInvitationSentId(-1);
                // alert("Updated")
                setRowData(temp);
                setGlobalData(temp);
            });
    }
    const handleInvitationSentClick = (row: any) => {
        setAnchorElOption(null)
        setShowConfirmationDialog(true);
        setConfirmationRow(row);
    }
    const handleInvitationClose = () => {
        setShowInvitationDialog(false);
        setCustomerInviteContact([]);
        setContactId([]);
        setProdContactId([])
        setProductContacts([])
    }
    const handleInvite = (row: any) => {
        setAnchorElOption(null)
        setFetchLoader(true);
        setFetchProdContactLoader(true);

        setInvitationData(row)
        let id = row.customerId.id;
        if (id) {
            axios.get(process.env.REACT_APP_BASE_URL + "contact/customer/" + id,
                {
                    headers: {
                        "Authorization": "Bearer " + user.accessToken,
                    }
                })
                .catch((error: any) => {

                    let prodid = row.productId.id;

                    setFetchLoader(false);
                    if (prodid) {
                        axios.get(process.env.REACT_APP_BASE_URL + 'product/' + prodid,
                            {
                                headers: {
                                    "Authorization": "Bearer " + user.accessToken,
                                }
                            })
                            .catch((error: any) => {

                                setFetchProdContactLoader(false);
                            })
                            .then((response: any) => {

                                let contId = response.data.contactId.toString();
                                if (contId) {
                                    axios.get(process.env.REACT_APP_BASE_URL + 'contact/all',
                                        {
                                            headers: {
                                                "Authorization": "Bearer " + user.accessToken,
                                            },
                                            params: {
                                                "ids": contId,
                                            }
                                        }).catch((error: any) => {

                                            setFetchProdContactLoader(false);
                                        })
                                        .then((response: any) => {

                                            let arrId = response.data.map((contact: any) => contact.id);
                                            setProdContactId(arrId)
                                            setContactId([])
                                            let prodContact = response.data
                                            setProductContacts(prodContact)
                                            setCustomerInviteContact([]);
                                            setFetchProdContactLoader(false);
                                        });
                                }
                                else {
                                    setContactId([]);
                                    setProdContactId([])
                                    setCustomerInviteContact([]);
                                    setProductContacts([])
                                    setFetchProdContactLoader(false);
                                }

                            });
                    }
                })
                .then((response: any) => {
                    if (response) {

                        setCustomerInviteContact(response.data);
                        let idArr = response.data.map((contact: any) => contact.id);
                        setContactId(idArr);
                        setFetchLoader(false);
                        let prodid = row.productId.id;

                        if (prodid) {
                            axios.get(process.env.REACT_APP_BASE_URL + 'product/' + prodid,
                                {
                                    headers: {
                                        "Authorization": "Bearer " + user.accessToken,
                                    }
                                })
                                .catch((error: any) => {

                                    setFetchProdContactLoader(false);
                                })
                                .then((response: any) => {

                                    let contId = response.data.contactId.toString();
                                    if (contId) {
                                        axios.get(process.env.REACT_APP_BASE_URL + 'contact/all',
                                            {
                                                headers: {
                                                    "Authorization": "Bearer " + user.accessToken,
                                                },
                                                params: {
                                                    "ids": contId,
                                                }
                                            }).catch((error: any) => {

                                                setFetchProdContactLoader(false);
                                            })
                                            .then((response: any) => {

                                                let arrId = response.data.map((contact: any) => contact.id);
                                                setProdContactId(arrId)
                                                setProductContacts(response.data)
                                                setFetchProdContactLoader(false);
                                            });
                                    }
                                    else {
                                        setProdContactId([])
                                        setProductContacts([]);
                                        setFetchProdContactLoader(false);
                                    }

                                });
                        }
                    }
                })
        }
        setShowInvitationDialog(true);
    }


    const handleErrorClose = () => {
        setShowDialog(true);
        setRegistrationError(false);
    }

    const onRedirect = () => {
        setFormData({ ...formData, connectorId: { id: connector.id, name: connector.name, email: connector.email } });
        setShowDialog(true);
    }
    const showProfile = (row: any, profile: string) => {
        setType(profile)
        setCurrentSelection(row)
        setProfileDialog(true)
    }
    //Filtering logic
    const [anchorEl, setAnchorEl] = useState(null);
    const [statusVisible, setStatusVisible] = useState(false);
    const [vendorVisible, setVendorVisible] = useState(false);
    const [productVisible, setProductVisible] = useState(false);
    const [customerVisible, setCustomerVisible] = useState(false);
    const [statusValue, setStatusValue] = useState<any>(null);
    const open = Boolean(anchorEl);

    const handleFilterOpt = (opt: any) => {
        setAnchorEl(null);
        if (opt === 'Status')
            setStatusVisible(true);
        if (opt === 'Vendor')
            setVendorVisible(true);
        if (opt === 'Product')
            setProductVisible(true);
        if (opt === 'Customer')
            setCustomerVisible(true);
    };
    const handleCloseFilter = (type: any) => {
        if (type == "Status") {
            setStatusValue(null);
            setStatusVisible(false);
        }
        if (type == "Customer") {
            setCustomerSearch('');
            setCustomerVisible(false);
        }
        if (type == "Vendor") {
            setVendorSearch('');
            setVendorVisible(false);
        }
        if (type == "Product") {
            setProductSearch('');
            setProductVisible(false);
        }

    };
    const statusOption = [
        'Invites Sent', 'Deal Started', 'Approved', 'Pending', 'Cancelled', 'Expired'
    ];
    //Search
    const [vendorSearch, setVendorSearch] = useState('');
    const [productSearch, setProductSearch] = useState('');
    const [customerSearch, setCustomerSearch] = useState('');

    useEffect(() => {
        if (globalData) {
            let tempArr = [...globalData];
            if (statusValue != null) {
                tempArr = tempArr.filter(obj => {
                    let str = statusValue[0].toLowerCase() + statusValue.substring(1).replaceAll(" ", "");
                    return (obj.status === str);
                });

            }
            if (vendorSearch.trim().length > 0) {
                tempArr = tempArr.filter(row => {
                    return row.vendorId.name
                        .toLowerCase()
                        .includes(
                            vendorSearch ? String(vendorSearch).trim().toLowerCase() : ''
                        );
                });
            }
            if (productSearch.trim().length > 0) {
                tempArr = tempArr.filter(row => {
                    return row.productId.name
                        .toLowerCase()
                        .includes(
                            productSearch ? String(productSearch).trim().toLowerCase() : ''
                        );
                });
            }
            if (customerSearch.trim().length > 0) {
                tempArr = tempArr.filter(row => {
                    let str = row.customerId.firstName + row.customerId.lastName
                    return str
                        .toLowerCase()
                        .includes(
                            customerSearch ? String(customerSearch).trim().toLowerCase() : ''
                        );
                });
            }
            setPage(0)
            setRowData(tempArr);
        }
    }, [statusValue, vendorSearch, productSearch, customerSearch])

    const [valueToOrderBy, setValueToOrderBy] = useState();
    const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>();

    const createSortHandler = (property: any) => (event: any) => {
        let isAscending;
        setValueToOrderBy(property);
        isAscending = orderDirection === 'asc';
        setOrderDirection(isAscending ? 'desc' : 'asc');
        sortedRowInformation(rowData, getComparator(orderDirection, property));
    };
    const [anchorElOption, setAnchorElOption] = useState(null);
    const openOption = Boolean(anchorElOption);

    const handleClickOption = (event: any) => {
        setAnchorElOption(event.currentTarget);
    };
    const handleCloseOption = () => {
        setAnchorElOption(null);
    };

    const onCreateNewCustomer = (customerId: any) => {
        setNewCustomerLoader(true);
        axios
            .get(process.env.REACT_APP_BASE_URL + 'customer/' + customerId,
                {
                    headers: {
                        "Authorization": "Bearer " + user.accessToken,
                    }
                })
            .catch((error: any) => {
                setNewCustomerLoader(false);
            })
            .then((response: any) => {
                setCustomerList((oldArray: any) => [...oldArray, response.data]);
                setFormData({ ...formData, customerId: { id: response.data.id, name: response.data.name } });
                setCurrentCustomer(response.data);
                setCardCustomer(true);
                setFormInfo(false);
                setDisableSave(false);
                setNewCustomerLoader(false);
            });
    }

    const searchCustomerList = (currentInput: String) => {
        if (currentInput.length >= 2) {
            setCustomerSearchLoader(true);
            axios.get(process.env.REACT_APP_BASE_URL + 'customer/related/' + currentInput,
                {
                    headers: {
                        "Authorization": "Bearer " + user.accessToken,
                    }
                })
                .catch((err: any) => {
                    setCustomerSearchError({ error: true, message: "Some error occurred" })
                    setCustomerSearchLoader(false);
                })
                .then((response: any) => {
                    setCustomerList(response.data)
                    setCustomerSearchLoader(false);
                })
        }
    }
    return (
        <>
            <RegistrationError showDialog={registrationError} handleClose={handleErrorClose} type={errorType} />
            <ProfileScreen user={user} showDialog={profileDialog} currentSelection={currentSelection} type={type} setShowDialog={setProfileDialog} />
            {loader ? <>
                <Grid item xs={12} sx={{ textAlign: "center", marginTop: 10 }}>
                    <img src="assets/loader/Spinner-px.gif" height={"140vh"} />
                    <Typography sx={{ paddingTop: 2 }}>Please wait while we fetch all your registrations</Typography>
                </Grid>
            </> : <>
                <InvitationDialog user={user} open={showInvitationDialog} handleInvitationClose={handleInvitationClose} invitationData={invitationData} customerInviteContact={customerInviteContact}
                    setCustomerInviteContact={setCustomerInviteContact} fetchLoader={fetchLoader} setFetchLoader={setFetchLoader}
                    fetchProdContactLoader={fetchProdContactLoader} contactId={contactId} setContactId={setContactId}
                    prodContactId={prodContactId} setProdContactId={setProdContactId} productContacts={productContacts} handleInvitationSent={handleInvitationSent}
                    setRegistrationError={setRegistrationError} setErrorType={setErrorType} />
                <RegistrationForm
                    showDialog={showDialog}
                    setShowDialog={setShowDialog}
                    vendorList={vendorList}
                    getOptions={getOptions}
                    handleChange={handleChange}
                    productList={productList}
                    customerList={customerList}
                    prodLoader={prodLoader}
                    handleClose={handleClose}
                    handleRegister={handleRegister}
                    connector={connector}
                    formInfo={formInfo}
                    setFormInfo={setFormInfo}
                    currentVendor={currentVendor}
                    currentProduct={currentProduct}
                    currentCustomer={currentCustomer}
                    cardVendor={cardVendor}
                    cardProduct={cardProduct}
                    cardCustomer={cardCustomer}
                    showProduct={showProduct}
                    error={error}
                    disableSave={disableSave}
                    saveLoader={saveLoader}
                    formLoader={formLoader}
                    disableCustomer={disableCustomer}
                    onCreateNewCustomer={onCreateNewCustomer}
                    searchCustomerList={searchCustomerList}
                    customerSearchLoader={customerSearchLoader}
                    newCustomerLoader={newCustomerLoader}
                />
                <ConfirmationDialog showConfirmationDialog={showConfirmationDialog} handleConfirmationDialogClose={handleConfirmationDialogClose} confirmationRow={confirmationRow} handleInvitationSent={handleInvitationSent} />
                <Grid
                    container
                    sx={{ justifyContent: 'center', alignItems: 'center' }}
                >

                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            {vendorVisible && <Grid item display={"flex"}><IconButton onClick={(e) => handleCloseFilter("Vendor")}><CancelOutlinedIcon /></IconButton><SearchTextField val={vendorSearch} funcHandle={setVendorSearch} type={"Vendor"} /></Grid>}
                            {productVisible && <Grid item display={"flex"}><IconButton onClick={(e) => handleCloseFilter("Product")}><CancelOutlinedIcon /></IconButton><SearchTextField val={productSearch} funcHandle={setProductSearch} type={"Product"} /></Grid>}
                            {customerVisible && <Grid item display={"flex"}><IconButton onClick={(e) => handleCloseFilter("Customer")}><CancelOutlinedIcon /></IconButton><SearchTextField val={customerSearch} funcHandle={setCustomerSearch} type={"Customer"} /></Grid>}

                            {statusVisible && <Grid item display={"flex"}>
                                <IconButton onClick={(e) => handleCloseFilter("Status")}><CancelOutlinedIcon /></IconButton>
                                <Autocomplete
                                    id="status"
                                    options={statusOption}
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            height: '1.5rem'
                                        }
                                    }}
                                    value={statusValue}
                                    onChange={(event, newValue: any) => {

                                        setStatusValue(newValue);
                                    }}

                                    renderInput={(params) => <TextField {...params} inputProps={{
                                        ...params.inputProps,
                                        style: { paddingBottom: "7px", width: "100%" }
                                    }} placeholder="Status" type="text"

                                        fullWidth hiddenLabel />}
                                />
                            </Grid>}

                        </Grid>
                    </Grid>
                    <Grid item xs={8} sm={8} md={11} lg={11} xl={11} sx={{ textAlign: "right", justifyContent: "right", display: "flex", alignItems: "right", marginY: 1 }}>
                        {!empty && <FilterComponent anchorEl={anchorEl} open={open} setAnchorEl={setAnchorEl} options={['Status', 'Vendor', 'Product', 'Customer']} handleFilterOpt={handleFilterOpt} />}
                    </Grid>
                    <Grid
                        item
                        xs={4} sm={4} md={1} lg={1} xl={1}
                        sx={{ textAlign: 'right', marginY: 1 }}
                    >
                        <Button
                            variant="contained"
                            size="small"
                            startIcon={<AddIcon />}
                            onClick={onRedirect}
                        >
                            Registration
                        </Button>
                    </Grid>
                    {empty ?
                        <>
                            <DealNotFound user={user} onRedirect={onRedirect} type={"registration"} />
                        </>
                        :
                        <>
                            <Grid item xs={12} sx={{ marginTop: 1 }}>
                                {selected.length > 0 && (
                                    <EnhancedTableToolbar
                                        numSelected={selected.length}
                                        deletingCols={onDelete}
                                        selectedUpdate={setSelected}
                                        deletableUpdate={handleDelete}
                                    />
                                )}
                                <TableContainer component={Paper} sx={{ marginBottom: 1, display: { xs: "none", sm: "none", md: "block", lg: "block", xl: "block" } }}>
                                    <Table
                                        aria-label="simple table"
                                        size="small"

                                    >
                                        <TableHead>
                                            <TableRow>
                                                {deletable && (
                                                    <TableCell></TableCell>
                                                )}
                                                <TableHeadRow createSortHandler={createSortHandler} valueToOrderBy={valueToOrderBy} arr={[
                                                    {
                                                        name: 'VENDOR',
                                                        alignment: 'left',
                                                        valueToOrderBy: 'vendorId.name',
                                                        orderDirection: orderDirection
                                                        // orderDirection: appliedOrderDirection
                                                        // valueToOrderBy: false,
                                                    },
                                                    {
                                                        name: 'PRODUCT',
                                                        alignment: 'left',
                                                        valueToOrderBy: 'productId.name',
                                                        orderDirection: orderDirection
                                                    },
                                                    {
                                                        name: 'CUSTOMER',
                                                        alignment: 'left',
                                                        valueToOrderBy: 'customerId.firstName',
                                                        orderDirection: orderDirection
                                                    },
                                                    {
                                                        name: 'APPLIED DATE',
                                                        alignment: 'left',
                                                        valueToOrderBy: 'createdDatetime',
                                                        orderDirection: orderDirection
                                                    },
                                                    {
                                                        name: 'ACTIVE DATE',
                                                        alignment: 'left',
                                                        valueToOrderBy: 'approvedRejectedDatetime',
                                                        orderDirection: orderDirection,
                                                    },
                                                    {
                                                        name: 'EXPIRATION DATE',
                                                        alignment: 'left',
                                                        valueToOrderBy: 'expirationDate',
                                                        orderDirection: orderDirection
                                                    },
                                                    {
                                                        name: 'STATUS',
                                                        alignment: 'center',
                                                        valueToOrderBy: false,
                                                    },
                                                    {
                                                        name: 'ACTION',
                                                        alignment: 'center',
                                                        valueToOrderBy: false,
                                                    }
                                                ]} />
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rowData
                                                && sortedRowInformation(
                                                    rowData.slice(
                                                        page * rowsPerPage,
                                                        page * rowsPerPage + rowsPerPage
                                                    ),
                                                    getComparator(orderDirection, valueToOrderBy)
                                                )
                                                    .map(
                                                        (
                                                            row: any,
                                                            index: any
                                                        ) => {
                                                            const isItemSelected =
                                                                isSelected(
                                                                    row.id
                                                                );
                                                            const labelId = `enhanced-table-checkbox-${index}`;
                                                            return (
                                                                <TableRow
                                                                    key={row.id}
                                                                    sx={{
                                                                        '&:last-child td, &:last-child th':
                                                                        {
                                                                            border: 0,
                                                                        },
                                                                    }}
                                                                >
                                                                    {deletable && (
                                                                        <TableCell padding="checkbox">
                                                                            <Checkbox
                                                                                checked={
                                                                                    isItemSelected
                                                                                }
                                                                                inputProps={{
                                                                                    'aria-labelledby':
                                                                                        labelId,
                                                                                }}
                                                                                onClick={event =>
                                                                                    handleCheckClick(
                                                                                        event,
                                                                                        row.id
                                                                                    )
                                                                                }
                                                                            />
                                                                        </TableCell>
                                                                    )}
                                                                    <TableCell
                                                                        component="th"
                                                                        scope="row"
                                                                        id={
                                                                            labelId
                                                                        }
                                                                    >
                                                                        <Typography
                                                                            onClick={(e) => showProfile(row.vendorId, "vendor")}
                                                                            style={
                                                                                linkCol
                                                                            }
                                                                        >
                                                                            <Typography
                                                                                sx={
                                                                                    linkHover
                                                                                }
                                                                            >
                                                                                {
                                                                                    firstCapital(row
                                                                                        .vendorId
                                                                                        .name)
                                                                                }
                                                                            </Typography>
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell align="left">
                                                                        <Typography
                                                                            onClick={(e) => showProfile(row.productId, "product")}
                                                                            style={
                                                                                linkCol
                                                                            }
                                                                        >
                                                                            <Typography
                                                                                sx={
                                                                                    linkHover
                                                                                }
                                                                            >
                                                                                {
                                                                                    firstCapital(row
                                                                                        .productId
                                                                                        .name)
                                                                                }
                                                                            </Typography>
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell align="left">
                                                                        <Typography
                                                                            onClick={(e) => showProfile(row.customerId, "customer")}
                                                                            style={
                                                                                linkCol
                                                                            }
                                                                        >
                                                                            <Typography
                                                                                sx={
                                                                                    linkHover
                                                                                }
                                                                            >
                                                                                {row
                                                                                    .customerId
                                                                                    .firstName +
                                                                                    ' ' +
                                                                                    row
                                                                                        .customerId
                                                                                        .lastName}
                                                                            </Typography>
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell align='left'>
                                                                        <Typography sx={{ fontSize: "15px" }}>
                                                                            {row.createdDatetime ? convertDate(
                                                                                row.createdDatetime
                                                                            ) : ""}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell align='left'>
                                                                        <Typography sx={{ fontSize: "15px" }}>
                                                                            {row.status !== "pending" ?
                                                                                <>
                                                                                    {row.approvedRejectedDatetime !== null ? <>{convertDate(row.approvedRejectedDatetime)}</> : <></>}
                                                                                </> : <></>
                                                                            }
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell align='left'>
                                                                        <Typography sx={{ fontSize: "15px" }}>
                                                                            {row.expirationDate ? convertDate(
                                                                                row.expirationDate
                                                                            ) : ""}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    {(row.id === invitationSentId) ? <TableCell align="center"><CircularProgress /></TableCell> :
                                                                        <StatusCell textClr={statusColorText[row.status]} bgClr={statusColor[row.status]} text={row.status == "invitesSent" ? "Invite Sent" : row.status === "dealStarted" ? "Deal Started" : firstCapital(row.status)} />}
                                                                    <TableCell align="center">
                                                                        {row.status ===
                                                                            'pending' && (
                                                                                <Box display="flex"
                                                                                    flexDirection={"column"}
                                                                                    justifyContent={"center"}
                                                                                >
                                                                                    <Button
                                                                                        color="error"
                                                                                        variant="outlined"
                                                                                        sx={{
                                                                                            width: 122,
                                                                                            margin: "auto",
                                                                                            '&:hover':
                                                                                            {
                                                                                                backgroundColor:
                                                                                                    '	 #ffebe6',
                                                                                                color: 'red',
                                                                                            },
                                                                                        }}
                                                                                        onClick={() => handleInvitationSent(row, "cancelled")}
                                                                                    >
                                                                                        <Typography sx={{ fontSize: "15px" }}>
                                                                                            CANCEL
                                                                                        </Typography>
                                                                                    </Button>
                                                                                </Box>
                                                                            )
                                                                        }
                                                                        {row.status === "approved" && (<>
                                                                            {
                                                                                <Box display={"flex"} justifyContent={"center"}
                                                                                    flexDirection={"row"}>
                                                                                    <Button
                                                                                        color="primary"
                                                                                        variant="outlined"
                                                                                        endIcon={<ArrowDropDownIcon />}
                                                                                        id="demo-positioned-button"
                                                                                        aria-controls={openOption ? 'demo-positioned-menu' : undefined}
                                                                                        aria-haspopup="true"
                                                                                        aria-expanded={openOption ? 'true' : undefined}
                                                                                        sx={{
                                                                                            width: 122,
                                                                                            margin: "auto"
                                                                                        }}
                                                                                        onClick={handleClickOption}
                                                                                    >
                                                                                        <Typography sx={{ fontSize: "15px" }}>
                                                                                            INTRODUCE
                                                                                        </Typography>
                                                                                    </Button>
                                                                                    <Menu
                                                                                        id="demo-positioned-menu"
                                                                                        aria-labelledby="demo-positioned-button"
                                                                                        anchorEl={anchorElOption}

                                                                                        open={openOption}
                                                                                        onClose={handleCloseOption}
                                                                                        anchorOrigin={{
                                                                                            vertical: 'bottom',
                                                                                            horizontal: 'left',
                                                                                        }}
                                                                                        transformOrigin={{
                                                                                            vertical: 'top',
                                                                                            horizontal: 'left',
                                                                                        }}
                                                                                    >
                                                                                        {/* <MenuItem onClick={() => handleInvite(row)}><Typography sx={{ fontSize: "15px", cursor: "pointer", color: "#00BFFF", '&:hover': { color: "#0000ff" } }}>
                                                                                            Send Invite
                                                                                        </Typography></MenuItem> */}
                                                                                        <MenuItem onClick={() => handleInvitationSentClick(row)}><Typography sx={{ fontSize: "15px", cursor: "pointer", color: "#00BFFF", '&:hover': { color: "#0000ff" } }}>Invitation Sent?</Typography></MenuItem>
                                                                                    </Menu>
                                                                                </Box>

                                                                            }
                                                                        </>)}
                                                                        {
                                                                            row.status !== "approved" && row.status !== "pending" && (<Box display="flex"
                                                                                flexDirection={"column"}
                                                                                justifyContent={"center"}>
                                                                                <Typography sx={{ fontSize: "15px" }}>No action required</Typography>
                                                                            </Box>)
                                                                        }
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        }
                                                    )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {rowData && (
                                    <>
                                        <Grid item xs={12} sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none", xl: "none" } }}>
                                            <RegistrationCardComponent user={user} rowData={rowData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)} showProfile={showProfile} handleInvite={handleInvite} handleInvitationSentClick={handleInvitationSentClick} />
                                        </Grid>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 15, 20]}
                                            sx={{
                                                '.MuiTablePagination-toolbar': {
                                                    alignItems: 'baseline',
                                                },
                                                marginBottom: 8
                                            }}
                                            component="div"
                                            count={rowData.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </>
                                )}
                            </Grid>
                        </>}

                </Grid>
            </>}
        </>
    );
}
