import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import { stakeHolders } from './StakeHolderData';
import { Avatar, Box, Grid, Typography } from '@mui/material';

const AboutUsInfo = () => {
    const [currentStakeHolder, setCurrentStakeHolder] = useState<any>();
    const pageUrl = window.location.href.split('/');
    const getFirstName = (name:any) => {
        const firstName = name.slice(0,name.indexOf(" "));
        return firstName;
    }
    useEffect(() => {
        const name = pageUrl.at(-1)?.replace("%20", " ");
        stakeHolders.map((stakeHolder:any) => {
            if(stakeHolder.username === name){
                setCurrentStakeHolder(stakeHolder);
            }
        })
        window.scrollTo({top:0, behavior:"smooth"})
    },[])
    return (
        <div id="layoutDefault">
            <div id="layoutDefault_content">
                <main>
                    <Navbar />
                    <header className="page-header-ui pt-10 pb-3 page-header-ui-dark bg-gradient-primary-to-secondary">
                        <div className="page-header-ui-content pt-1">
                            {currentStakeHolder && 
                            <div className="container px-md-5">
                                <div className="row gx-5">
                                    <div className="col-lg-6 text-center">
                                        <Typography sx={{fontWeight:"bold", fontSize:"70px", marginTop:7}}>{currentStakeHolder.name}</Typography>
                                        {currentStakeHolder.designation && <Typography sx={{fontSize:"20px"}}>{currentStakeHolder.designation}</Typography>}
                                        <Grid container sx={{paddingTop:3, marginLeft:{xs:3, sm:0, md:10, lg:10, xl:10}}}>
                                            {/* <Grid item xs={3} sm={3} md={1} lg={1} xl={1}>
                                                <Avatar><EmailIcon sx={{color:"blue"}}/></Avatar>
                                            </Grid>
                                            <Grid item xs={9} sm={9} md={5} lg={5} xl={5} sx={{display:"flex", alignItems:"center"}}>
                                                <a href={`mailto:${currentStakeHolder.email}`}>
                                                    <Typography sx={{display:"inline", color:"white"}}>{currentStakeHolder.email}</Typography>
                                                </a>
                                            </Grid> */}
                                            <Grid item xs={3} sm={3} md={1} lg={1} xl={1}>
                                                <Avatar><LinkedInIcon sx={{color:"blue"}}/></Avatar>
                                            </Grid>
                                            <Grid item xs={9} sm={9} md={5} lg={5} xl={5} sx={{display:"flex", alignItems:"center"}}>
                                                <a href={currentStakeHolder.linkedIn}>
                                                    <Typography sx={{display:"inline", color:"white"}}>{currentStakeHolder.name}</Typography>
                                                </a>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div className="col-lg-6 text-center pt-sm-4 pt-md-4 pt-lg-0 pt-xl-0">
                                        <img src={currentStakeHolder.picture} alt={currentStakeHolder.name} className="card-team-img mb-3 rounded-circle" height="400vh"/>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </header>
                    <section className="bg-white py-10">
                        <div className="container px-5">
                            <div className="row gx-5">
                                <div className="col-md-12 col-xl-7 col-lg-7 mb-5">
                                    {currentStakeHolder && 
                                    <Box>
                                        <Typography sx={{fontWeight:"bold", fontSize:"50px", marginBottom:3}}>About {getFirstName(currentStakeHolder.name)}</Typography>
                                        <Typography sx={{marginBottom:2}}>{currentStakeHolder.para1}</Typography>
                                        <Typography sx={{marginBottom:2}}>{currentStakeHolder.para2}</Typography>
                                        <Typography sx={{marginBottom:2}}>{currentStakeHolder.para3}</Typography>
                                        {currentStakeHolder.para4 && <Typography sx={{marginBottom:2}}>{currentStakeHolder.para4}</Typography>}
                                    </Box>
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        </div>
    );
};

export default AboutUsInfo;
