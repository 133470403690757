import { Card, CardContent, CardMedia, Dialog, Grid, Typography, Alert, Button, Tooltip, Box } from '@mui/material';
import { Fragment } from 'react';
import { convertCurrency, firstCapital } from '../Admin/Approvals/util';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const ProductProfile = (props: any) => {
  const { currentSelection} = props;
  return (
      <Fragment>
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ textAlign: "center", marginTop: 4 }}>
            {currentSelection.logo ?
              <>
                <CardMedia
                  component="img"
                  image={`data:${currentSelection.contentType};base64,${currentSelection.logo}`}
                  alt="Connected People"
                  sx={{
                    height: "15rem",
                    width: "15rem",
                    textAlign: "center",
                    margin: "auto"
                  }}
                />
              </>
              :
              <>
                <CardMedia
                  component="img"
                  image={"assets/icons/productIcon.png"}
                  alt="Connected People"
                  sx={{
                    height: "15rem",
                    width: "15rem",
                    textAlign: "center",
                    margin: "auto"
                  }}
                />
              </>}
            <Typography sx={{ fontWeight: 'bold' }} variant="h5" mt={2}>
              {firstCapital(currentSelection?.name)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Card sx={{ marginRight: 2, marginBottom: 2, marginTop: 2 }}>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '20px', }}>
                      Name
                    </Typography>
                    <Card sx={{ padding: 1, boxShadow: 0, backgroundColor: '#F0F0F0', }}>
                      <Typography variant="subtitle2">
                        {firstCapital(currentSelection.name)}
                      </Typography>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '20px', }}>
                      Vendor
                    </Typography>
                    <Card sx={{ padding: 1, boxShadow: 0, backgroundColor: '#F0F0F0', }}>
                      <Typography variant="subtitle2">
                        {currentSelection.vendorId.name}

                      </Typography>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '20px', }}>
                      Type
                    </Typography>
                    <Card sx={{ padding: 1, boxShadow: 0, backgroundColor: '#F0F0F0', }}>
                      <Typography variant="subtitle2">
                        {currentSelection.type ? currentSelection.type : "Type not entered"}
                      </Typography>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '20px', }}>
                      Description
                    </Typography>
                    <Card sx={{ padding: 1, boxShadow: 0, backgroundColor: '#F0F0F0', }}>
                      <Typography variant="subtitle2">
                        {currentSelection.description}
                      </Typography>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '20px', }}>
                      Amount
                    </Typography>
                    <Card sx={{ padding: 1, boxShadow: 0, backgroundColor: '#F0F0F0', }}>
                      <Typography variant="subtitle2">
                        {convertCurrency(currentSelection.defaultPrice)}
                      </Typography>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Fragment>
  )
}

export default ProductProfile