import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    Grid,
    IconButton,
    TextField,
    Typography,
    Alert,
    DialogActions,
    DialogContent,
    Divider,
    AlertTitle,
    Autocomplete,
    Card,
    CardMedia,
    Tooltip,
    DialogTitle,
    InputAdornment,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Vendor from './RegistrationFormScreens/Vendor';
import Product from './RegistrationFormScreens/Product';
import Customer from './RegistrationFormScreens/Customer';
import FormLoader from './FormLoader';
import CloseIcon from '@mui/icons-material/Close';
import CustomerForm from './CustomerForm';
const RegistrationForm = (props: any) => {
    const {
        showDialog,
        setShowDialog,
        vendorList,
        getOptions,
        handleChange,
        productList,
        customerList,
        handleClose,
        handleRegister,
        formInfo,
        setFormInfo,
        currentVendor,
        currentProduct,
        currentCustomer,
        cardVendor,
        cardProduct,
        cardCustomer,
        showProduct,
        error,
        disableSave,
        saveLoader,
        formLoader,
        disableCustomer,
        onCreateNewCustomer,
        searchCustomerList,
        newCustomerLoader,
        customerSearchLoader
    } = props;
    const style = {padding:1, marginBottom:1 ,boxShadow:0, paddingX:0, paddingTop:2};
    const infoCompponent = () => {
        return(<>
            <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                Please follow the following steps to get started with the vendor
                application process:
            </Typography>
            <br />
            <Typography sx={{ fontSize: 14, color: '', textShadow: 3 }}>
                1. Select a vendor from the dropdown.
                <br />
                2.Select a product after selecting a vendor.
                <br />
                3.Select a customer, thid product will be for.
                <br />
                4.Review and ensure the vendor, product and connector details
                are as intended.
                <br />
                5.Click on register to start the vendor approval process.
            </Typography>
        </>)
    }
    const [showCustomerDialog, setShowCustomerDialog] = useState(false);
    const onClickAddNewCustomer = () => {
        setShowDialog(false);
        setShowCustomerDialog(true);
    }
    const handleCloseCustomerForm = () => {
        setShowCustomerDialog(false);
        setShowDialog(true);
    }
    const asterisk = () => {
        return(<><Typography sx={{color:"#00BFFF", display:"inline", fontSize:"20px", fontWeight:"bold"}}>*</Typography></>)
    }
    return (
        <>
            <CustomerForm showDialog={showCustomerDialog} handleClose={handleCloseCustomerForm} asterisk={asterisk} onCreateNewCustomer={onCreateNewCustomer}/>
            <Dialog
                open={showDialog}
                fullWidth
                maxWidth="lg"
                sx={{
                    '.MuiDialog-paper': {
                        position: 'absolute',
                        width: '90%',
                        height: '700px',
                        minWidth: "90%"
                    },
                }}
            >
                <DialogTitle sx={{padding:0, backgroundColor:"#e5f6fd"}}>
                    <Grid container sx={{padding:2}}>
                        <Grid item xs={11.8} sx={{textAlign:"center"}}>
                            <Typography variant="h4" align="center" sx={{marginTop:1, fontWeight: 'bold',width: "100%", fontSize:{xs:"large", sm:"large", md:"34px", lg:"34px", xl:"34px"}}}>
                                New Registration
                                <Tooltip
                                    title={infoCompponent()}
                                    arrow
                                    placement="right-end"
                                >
                                    <Button onClick={e => setFormInfo(!formInfo)}>
                                        <InfoOutlinedIcon />
                                    </Button>
                                </Tooltip>
                            </Typography>
                        </Grid>
                        <Grid item xs={0.2} sx={{alignText:"end", justifyContent:"right"}}>
                            <Box display="flex"><CloseIcon sx={{marginTop:1, fontWeight:"bold", color:"red", "&:hover":{cursor:"pointer"}}} onClick={handleClose}/></Box>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    {formLoader?<>
                        <FormLoader/>
                    </>:<></>}
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12} sx={{marginTop:1}}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                            <Grid container>
                                                <Grid item xs={12} >
                                                    <Typography sx={{ fontWeight: 'bold' , fontSize:"20px"}}>
                                                        Select Vendor {asterisk()}
                                                    </Typography>
                                                    <Typography variant="caption">
                                                        Select a vendor to associate with the registration
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Autocomplete
                                                        options={vendorList}
                                                        getOptionLabel={getOptions}
                                                        id="vendorId"
                                                        onChange={handleChange}
                                                        disableClearable
                                                        value={currentVendor}
                                                        renderInput={params => (
                                                            <TextField
                                                                {...params}
                                                                label="Vendor"
                                                                type="text"
                                                                fullWidth
                                                                variant="outlined"
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <Card
                                                sx={{ marginTop: 0, width: '100%', boxShadow:0, padding:1 }}
                                            >
                                            <Box sx={style}>
                                                <Vendor currentSelection={currentVendor} cardVendor={cardVendor}/>
                                            </Box>
                                        </Card>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sx={{ marginBottom: 2 }}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                            <Grid container>
                                                <Grid item xs={12} >
                                                    <Typography sx={{ fontWeight: 'bold', fontSize:"20px" }}>
                                                        Select Product {asterisk()}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {error? 
                                                    <>
                                                        <Grid item xs={12} sx={{textAlign:"center", marginTop:1}}>
                                                            <Typography>There are no products registered with the selected vendor.</Typography>
                                                        </Grid>
                                                    </>
                                                    :
                                                    <>
                                                    {showProduct?<>
                                                        <Typography variant="caption">
                                                            Select a vendor to get list of products
                                                        </Typography>
                                                    </>:<>
                                                        <Typography variant="caption">
                                                            Select a product to associate with the registration
                                                        </Typography>
                                                    </>}
                                                    <Autocomplete
                                                        options={productList}
                                                        getOptionLabel={
                                                            getOptions
                                                        }
                                                        id="productId"
                                                        value={currentProduct}
                                                        onChange={handleChange}
                                                        disabled={showProduct}
                                                        disableClearable
                                                        renderInput={params => (
                                                        <TextField
                                                            {...params}
                                                            label="Product"
                                                            type="text"
                                                            fullWidth
                                                            variant="outlined"
                                                        />
                                                        )}
                                                    />
                                                    </>}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <Card
                                                sx={{ marginTop: 0, width: '100%', boxShadow:0, padding:1 }}
                                            >
                                                <Box sx={style}>
                                                    <Product currentSelection={currentProduct} cardProduct={cardProduct}/>
                                                </Box>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sx={{marginBottom: 2 }}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                            <Grid container>
                                                <Grid item xs={12} >
                                                    <Typography sx={{ fontWeight: 'bold' , fontSize:"20px"}}>
                                                        Select Customer {asterisk()}
                                                    </Typography>
                                                    <Typography variant="caption">
                                                        {disableCustomer?<>Select a product first</>:<>Start typing in the search box below to get list of customers</>}
                                                </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                {newCustomerLoader?
                                                     <Box sx={{textAlign:"center", marginTop:2}}>
                                                        <CircularProgress  size="1.6rem"/>
                                                     </Box>
                                                :
                                                    <Autocomplete
                                                        options={customerList}
                                                        getOptionLabel={getOptions}
                                                        id="customerId"
                                                        onChange={handleChange}
                                                        disableClearable
                                                        disabled={disableCustomer}
                                                        value={currentCustomer}
                                                        onKeyUp={(e:any) => searchCustomerList(e.target.value)}
                                                        loading={customerSearchLoader}
                                                        renderInput={params => (
                                                            <TextField
                                                                {...params}
                                                                label={disableCustomer?"Customer":"Search Customers"}
                                                                type="text"
                                                                fullWidth
                                                                variant="outlined"
                                                                
                                                            />
                                                        )}
                                                    />
                                                }
                                                {!disableCustomer && 
                                                <Box sx={{textAlign:"center"}}>
                                                    <Typography variant = "caption" sx={{display:"inline"}}>Counldn't find your customer ?{" "}</Typography>
                                                    <Typography onClick={onClickAddNewCustomer} variant="caption" sx={{display:"inline", color:"#4e80ee", "&:hover":{textDecoration:"underline", cursor:"pointer"}}}>
                                                        Add New
                                                    </Typography>
                                                </Box>}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <Card
                                                sx={{ marginTop: 0, width: '100%', boxShadow:0, padding:1 }}
                                            >
                                            <Box sx={style}>
                                                <Customer currentSelection={currentCustomer} cardCustomer={cardCustomer}/>
                                            </Box>
                                        </Card>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{paddingRight:5, paddingY:2}}>
                    <Button
                        onClick={handleClose}
                        variant="contained"
                        color="error"
                        sx={{width:100, height:45, padding:1}}
                        disabled={saveLoader}
                    >
                        cancel
                    </Button>
                    <Button onClick={handleRegister} sx={{width:100, height:45 , padding:1}} variant="contained" disabled={disableSave}>
                        {saveLoader?<><CircularProgress sx={{color:"white"}}/></>:<>Save</>}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default RegistrationForm;
