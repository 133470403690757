import { Autocomplete, Box, Button, Card, CardContent, CircularProgress, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Radio, RadioGroup, TextField, Tooltip, Typography } from '@mui/material';
import ProductsTable from '../products/ProductsTable';
import PageNameComp from '../PageNameComp';
import { ModeEdit } from '@mui/icons-material';
import { convertNumber, firstCapital } from '../Admin/Approvals/util';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import MuiPhoneNumber from 'material-ui-phone-number';
import AddressAutocomplete from './AddressAutocomplete';
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import axios from 'axios';

const UserProfile = (props: any) => {
    const { data, formData, setFormData, userType, handleSave, isAddressValidated, saveLoader, editable, setEditable, formValidation, setFormValidation, showAddressSuggestion, onClickSkip, onClickYes, disableSave, setDisableSave, initialError, setShowAddressSuggestion, onClickEdit, disableFields, setDisableFields, validateAddress, screen, updateType, setUpdateType, user, setIsAddressValidated, setEditTable, setShowDialog } = props;
    const onEdit = () => {
        setEditable(true);
        setFormData(data);
    }
    const [otpButtonVisibility, setOtpButtonVisibility] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [showConfirmButton, setShowConfirmButton] = useState(true);
    const [value, setValue] = React.useState('SMS');
    const [phoneNumberChanged, setPhoneNumberChanged] = React.useState(false);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = React.useState(false);
    const handleChange = async (event: { target: { value: React.SetStateAction<string>; }; }) => {
        const mfaOption = event.target.value
        if (value !== mfaOption) {
            setValue(mfaOption);
            // @PutMapping("/authenticator") changeMFAToAuthenticator(@RequestParam String email)
            if (mfaOption === 'Authenticator' && value === 'SMS') {
                try {
                    await axios.put(process.env.REACT_APP_BASE_URL + 'auth/authenticator', null, {
                        params: { email: data.email },
                        headers: {
                            "Authorization": "Bearer " + user.accessToken,
                        }
                    })
                } catch (error) {
                    console.log(error);
                }
            }
            else if (mfaOption === 'SMS' && value === 'Authenticator') {

                try {
                    await axios.put(process.env.REACT_APP_BASE_URL + 'auth/sms', null, {
                        params: { email: data.email },
                        headers: {
                            "Authorization": "Bearer " + user.accessToken,
                        }
                    })
                } catch (error) {
                    console.log(error);
                }
            }
        }
    }

    const cancelEdit = () => {
        if (user.role === 'admin') {
            setShowDialog(false)
            setEditTable(false);
            formik.resetForm();

        }
        else {
            setEditable(false);
            setDisableSave(false);
            setFormValidation(initialError);
            setShowAddressSuggestion("");
            setDisableFields(false);
            setUpdateType("");
        }
    }


    const openConfirmationDialog = async () => {
        setConfirmationDialogOpen(true);
        try {
            const res = await axios.post(process.env.REACT_APP_BASE_URL + 'auth/smsMfa', null, {
                params: {
                    email: data.email,
                    newPhoneNumber: data.phoneNumber1
                },
                headers: {
                    "Authorization": "Bearer " + user.accessToken,
                }
            })
        } catch (error) {
            console.log(error);
        }
    };

    const resendOtp = async () => {
        try {
            const res = await axios.post(process.env.REACT_APP_BASE_URL + 'auth/resend', null, {
                params: { email: data.email },
                headers: {
                    "Authorization": "Bearer " + user.accessToken,
                }
            })
        } catch (error) {
            console.log(error);
        }
    };

    const closeConfirmationDialog = () => {
        setConfirmationDialogOpen(false);
    };
    const handlePhoneChange1 = (value: any) => {
        setFormData({ ...formData, phoneNumber1: value });
        setPhoneNumberChanged(true);
        if (value.replace(/[^0-9]/g, "").length < 11) {
            setFormValidation({ ...formValidation, phone1: true, message1: "Please enter a valid contact number." })
        }
        else {
            setFormValidation({ ...formValidation, phone1: false, message1: "" })
        }
    }
    const handlePhoneChange2 = (value: any) => {
        setFormData({ ...formData, phoneNumber2: value });
        if (value.replace(/[^0-9]/g, "").length < 11 && value.replace(/[^0-9]/g, "").length > 0) {
            setFormValidation({ ...formValidation, phone2: true, message2: "Please enter a valid alternate contact number." })
        }
        else {
            setFormValidation({ ...formValidation, phone2: false, message2: "" })
        }
    }
    useEffect(() => {
        if ((user?.role === "vendor" || user?.role === "contact") && data) {
            if (updateType !== "image") {
                setFormData(data);
                formik.setValues({
                    name: data.name,
                    description: data.description,
                    website: data.website,
                    address: data.address
                });
            }
            else {
                setFormData({ ...formData, logo: data.logo, contentType: data.contentType, imageName: data.imageName })
            }
        }
    }, [data])
    // useEffect(() => {
    //     cancelEdit();
    // }, [screen])
    const validationSchema = yup.object({
        name: yup
            .string()
            .min(4, "Name should contain 4 characters at least")
            .matches(/^[A-Za-z0-9 -]*$/, 'Please enter valid name, can contain alphabets and spaces only').trim()
            .required('Please enter a valid name'),
        description: yup
            .string()
            .min(10, "Description must be 10 characters long")
            .max(200, "Description shouldn't exceed 200 characters")
            .matches(/^[A-Za-z0-9 -,.?;:'"!]*$/, 'Please enter valid description, can contain alphabets, spaces, numbers, and ,.?;:\'"! only').trim()
            .nullable(),
        // website: yup
        //     .string()
        //     .matches(/((https):\/\/)(www\.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(\/[a-zA-Z0-9_\-]+)*\/?[a-zA-Z0-9#?%&=_\-\+\.\/]*$/,
        //         'Please enter a valid url, should contain https.')
        //     .nullable(),

    });
    const formik = useFormik({
        initialValues: {
            name: data.name,
            description: data.description,
            website: data.website,
            address: data.address,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setFormData({ ...formData, name: values.name });
            validateAddress(formData)
                .then((isAddressValidated: any) => {
                    handleSave(values);

                })
                .catch((error: any) => {
                });

        }
    });

    useEffect(() => {
        const getMfaType = async () => {
            try {
                const res = await axios.get(process.env.REACT_APP_BASE_URL + 'auth/mfaType', {
                    params: { email: data.email },
                    headers: {
                        "Authorization": "Bearer " + user.accessToken,
                    }
                })
                setValue(res.data === 'TOTP' ? 'Authenticator' : 'SMS')
            } catch (error) {
                console.log(error);
            }
        }
        const getOtpStatus = async () => {
            try {
                const res = await axios.get(process.env.REACT_APP_BASE_URL + 'auth/status', {
                    params: { email: data.email },
                    headers: {
                        "Authorization": "Bearer " + user.accessToken,
                    }
                })
                setOtpButtonVisibility(res.data)
            } catch (error) {
                console.log(error);
            }
        }
        if (user.role === 'admin') {
            getMfaType()
            getOtpStatus()
        }
    }, [])

    useEffect(() => {
        if (userType === "vendor" || userType === "contact") {
            setFormData({ ...formData, name: formik.values.name, description: formik.values.description, website: formik.values.website })
        }
        else if (userType === "connector") {
            setFormData({ ...formData, name: formik.values.name, description: formik.values.description })
        }
    }, [formik.values.name, formik.values.description, formik.values.website])

    // useEffect(() => {
    //     if (formik && !formik.values.website.includes('https'))
    //         formik.setFieldValue('website', `https://www.${formik.values.website}`)
    // }, [])

    return (
        <>

            <Card
                style={{
                    padding: 2,
                }}
            >
                <CardContent>
                    {editable ?
                        <form onSubmit={formik.handleSubmit}>
                            <Typography sx={{ fontWeight: "bold" }}>
                                {screen === "vendorAdmin" ? "Organizational Information:" : "Personal Information:"}
                            </Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                defaultValue={formik.values.name}
                                id="name"
                                name="name"
                                type="name"
                                label="Name"
                                placeholder="Name"
                                margin="normal"
                                InputProps={{
                                    style: {
                                        boxSizing: "content-box"
                                    }
                                }}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ height: "70px" }}>
                                    <MuiPhoneNumber
                                        defaultCountry={'us'}
                                        onChange={(e) => handlePhoneChange1(e)}
                                        value={formData.phoneNumber1}
                                        variant='outlined'
                                        sx={{ width: "100%" }}
                                        label="Contact Number *"
                                        error={formValidation.phone1}
                                        helperText={formValidation.message1}
                                        InputProps={{
                                            style: {
                                                boxSizing: "content-box"
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ height: "70px" }}>
                                    <MuiPhoneNumber
                                        defaultCountry={'us'}
                                        onChange={handlePhoneChange2}
                                        value={formData.phoneNumber2}
                                        variant='outlined'
                                        sx={{ width: "100%" }}
                                        label="Alternate Contact Number"
                                        error={formValidation.phone2}
                                        helperText={formValidation.message2}
                                        InputProps={{
                                            style: {
                                                boxSizing: "content-box"
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            {(user && user?.role === 'admin') && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormControl
                                    sx={{ marginBottom: '1px', minHeight: '90px' }}>
                                    {/* <FormLabel sx={{ minHeight: '20px', fontWeight: "bold" }} id="demo-controlled-radio-buttons-group"> */}
                                    <Typography sx={{ fontWeight: "bold" }}>
                                        MFA Type
                                    </Typography>
                                    {/* </FormLabel> */}
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={value}
                                        onChange={handleChange}
                                        sx={{ display: 'flex', flexDirection: 'row' }}
                                    >
                                        <FormControlLabel
                                            sx={{ minHeight: '20px', width: "150px" }}
                                            value="SMS"
                                            control={<Radio />}
                                            label="SMS"
                                        />
                                        <FormControlLabel
                                            sx={{ minHeight: '20px' }}
                                            value="Authenticator"
                                            control={<Radio />}
                                            label="Authenticator"
                                        />
                                        {value === 'SMS' && (
                                            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                                                (The Primary Contact number will be used for SMS OTP)
                                            </Typography>
                                        )}

                                    </RadioGroup>
                                </FormControl>
                            </Grid>}




                            {/* {(((userType === "vendor" || userType === "contact") ) && (screen === "vendorAdmin")) ?
                                <>
                                    

                                </>
                                :
                                <></>} */}

                            {
                                (userType === 'vendor' && user.role === 'admin') && (
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        type="text"
                                        id="website"
                                        defaultValue={formik.values.website}
                                        name="website"
                                        label="Website"
                                        placeholder="Website"
                                        margin="normal"
                                        InputProps={{
                                            style: {
                                                boxSizing: "content-box"
                                            },
                                        }}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                    />)}
                            <Typography sx={{ fontWeight: "bold", marginTop: 2 }}>Address:</Typography>
                            <Grid container>
                                <Grid item xs={12}>
                                    <AddressAutocomplete
                                        formData={formData}
                                        setFormData={setFormData}
                                        formValidation={formValidation}
                                        setFormValidation={setFormValidation}
                                        showAddressSuggestion={showAddressSuggestion}
                                        onClickYes={onClickYes}
                                        onClickSkip={onClickSkip}
                                        saveLoader={saveLoader}
                                        onClickEdit={onClickEdit}
                                        disableFields={disableFields}
                                        validateAddress={validateAddress}
                                    />
                                </Grid>
                                {isAddressValidated ? <Typography color="green">Address Validated</Typography> : <Typography color="red">Please enter a valid address*</Typography>}
                            </Grid>
                            <Typography sx={{ fontWeight: "bold" }}>Description:</Typography>
                            <TextField
                                sx={{ width: "100%" }}
                                variant="outlined"
                                type="text"
                                id="description"
                                label="Description"
                                name="description"
                                defaultValue={formData.description}
                                placeholder="Description"
                                margin="normal"
                                multiline={true}
                                rows={3}
                                InputProps={{
                                    style: {
                                        boxSizing: "content-box"
                                    }
                                }}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                error={formik.touched.description && Boolean(formik.errors.description)}
                                helperText={formik.touched.description && formik.errors.description}
                            />
                            <Box sx={{ textAlign: 'right' }}>
                                {user && user.role === 'admin' && (
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        sx={{
                                            width: 100,
                                            height: 45,
                                            padding: 1,
                                            marginRight: 1,
                                        }}
                                        disabled={otpButtonVisibility}
                                        onClick={resendOtp}
                                    >
                                        Reset Password
                                    </Button>
                                )}
                                <Button
                                    color="error"
                                    variant="contained"
                                    sx={{
                                        width: 100,
                                        height: 45,
                                        padding: 1,
                                        marginRight: 1
                                    }}
                                    disabled={saveLoader}
                                    onClick={cancelEdit}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    sx={{
                                        width: 100,
                                        padding: 1,
                                        height: 45,
                                    }}
                                    disabled={disableSave || saveLoader}
                                    onClick={(e) => {
                                        if (phoneNumberChanged && value.toLowerCase() === 'sms' && user.role === 'admin') {
                                            openConfirmationDialog();
                                        } else {
                                            formik.handleSubmit();
                                        }
                                    }}
                                >
                                    {saveLoader ? (
                                        <CircularProgress sx={{ color: 'white' }} />
                                    ) : (
                                        <>Save</>
                                    )}
                                </Button>


                                <Dialog
                                    open={confirmationDialogOpen}
                                    onClose={closeConfirmationDialog}
                                >
                                    <DialogTitle>
                                        Confirm Phone Number Change
                                    </DialogTitle>
                                    <DialogContent>
                                        <Typography>
                                            The Primary Contact Number will be updated and used for SMS OTP. Do you want to continue?
                                        </Typography>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={closeConfirmationDialog}>No</Button>
                                        <Button onClick={() => {
                                            closeConfirmationDialog();
                                            formik.handleSubmit();
                                        }}>
                                            Yes
                                        </Button>
                                    </DialogActions>
                                </Dialog>

                                {/* <Button
                                        variant="contained"
                                        sx={{
                                            width: 100,
                                            padding: 1,
                                            height: 45
                                        }}
                                        disabled={disableSave || saveLoader}
                                        onClick={(e) => formik.handleSubmit()}
                                    >
                                        {saveLoader ? <><CircularProgress sx={{ color: "white" }} /></> : <>Save</>}
                                    </Button> */}
                            </Box>
                        </form>
                        :
                        <>
                            <Grid container spacing={2} sx={{ marginBottom: 1 }}>

                                <Grid item xs={6} sx={{ display: "flex-box", justifyContent: "center" }}>
                                    <Typography sx={{ fontWeight: "bold", fontSize: 25, paddingTop: 2 }}>{firstCapital(data.name)}</Typography>
                                </Grid>
                                <Grid item xs={6} sx={{ textAlign: "right", marginTop: 1.8 }}>
                                    {screen === "vendorAdmin" ?
                                        <>
                                            {user?.role === "vendor" ? <Tooltip title="Edit your profile information" arrow placement="right"><IconButton onClick={onEdit}><ModeEdit sx={{ fontSize: 30 }} /></IconButton></Tooltip> : <></>}
                                        </>
                                        :
                                        <>
                                            <Tooltip title="Edit your profile information" arrow placement="right"><IconButton onClick={onEdit}><ModeEdit sx={{ fontSize: 30 }} /></IconButton></Tooltip>
                                        </>}
                                </Grid>
                                <Grid item xs={12} sx={{ marginTop: 1 }} />
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <Typography variant="caption">Full Name</Typography>
                                    <Typography sx={{ fontWeight: "medium" }}>{firstCapital(data.name)}</Typography>
                                </Grid>
                                {(screen === "vendor" || screen === "contact" || user.role === "connector") ?
                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <Typography variant="caption" >Email</Typography>
                                        {data.email ?
                                            <>
                                                <Typography sx={{ fontWeight: "medium" }}>{data.email}</Typography>
                                            </>
                                            :
                                            <>
                                                <Typography>No mail address found</Typography>
                                            </>}
                                    </Grid> : <></>}
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <Typography variant="caption" >Contact Number</Typography>
                                    {data.phoneNumber1 ?
                                        <> <Typography sx={{ fontWeight: "medium" }}>{convertNumber(data.phoneNumber1)}</Typography></>
                                        :
                                        <> <Typography>No contact number found</Typography></>}
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <Typography variant="caption" >Alternate Contact Number</Typography>
                                    {data.phoneNumber2 ?
                                        <> <Typography sx={{ fontWeight: "medium" }}>{convertNumber(data.phoneNumber2)}</Typography></>
                                        :
                                        <> <Typography>No alternate contact number found</Typography></>}
                                </Grid>
                                {(userType === "vendor" || userType === "contact") && (screen === "vendorAdmin") ?
                                    <>
                                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                            <Typography variant="caption" >Website</Typography>
                                            {data.website ?
                                                <><Link to={data.website}><Typography >{data.website}</Typography></Link></>
                                                :
                                                <> <Typography>No website link found</Typography></>}
                                        </Grid>
                                    </>
                                    : <></>}
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <Typography variant="caption" >Address</Typography>
                                    {data.address ?
                                        <> <Typography sx={{ fontWeight: "medium" }}>{data.address} {data.street}, {data.cityName}, {data.stateName}, {data.countryName}, {data.zipcode}</Typography></>
                                        :
                                        <> <Typography>No address found</Typography></>}
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <Typography variant="caption" >Description</Typography>
                                    {data.description ?
                                        <> <Typography sx={{ fontWeight: "medium" }}>{data.description}</Typography></>
                                        :
                                        <> <Typography>No description found</Typography></>}
                                </Grid>
                            </Grid>
                        </>}
                </CardContent>
            </Card>
        </>
    );
};

export default UserProfile;

export const VendorProfileProducts = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <PageNameComp
                    currentText="Find your products here"
                    heading="Products"
                />
            </Grid>
            <Grid item xs={12}>
                <ProductsTable />
            </Grid>
        </Grid>
    );
};
