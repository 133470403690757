import React,{useEffect, useState} from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import {Grid,Paper,Typography,} from '@mui/material';
import OfflinePinIcon from '@mui/icons-material/OfflinePin';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import WidgetsIcon from '@mui/icons-material/Widgets';
import EmailIcon from '@mui/icons-material/Email';
import MuiDrawer from '@mui/material/Drawer';
import { styled,Theme, CSSObject } from '@mui/material/styles';

export default function AdminSidebar(props: any) {
    const {setSelectedIndex, selectedIndex} = props;
    const drawerWidth = "16.3%";
    const openedMixin = (theme: Theme): CSSObject => ({
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: 'hidden',
    });
    
    const closedMixin = (theme: Theme): CSSObject => ({
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: `calc(${theme.spacing(7)} + 1px)`,
        [theme.breakpoints.up('sm')]: {
            width: `calc(${theme.spacing(8)} + 1px)`,
        },
    });

    const Drawer = styled(MuiDrawer, {
        shouldForwardProp: prop => prop !== 'open',
    })(({ theme, open }) => ({
        width: drawerWidth,
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }));

    const [open, setOpen] = useState(true);
    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);
    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });

    const drawer = (
        <List sx={{marginTop:8}}>
            <ListItem key="Approvals" disablePadding>
                <ListItemButton
                    
                    sx={{
                        backgroundColor: selectedIndex === 1 ? '#BEBEBE' : '',
                    }}
                    onClick={() => {
                        setSelectedIndex(1);
                        props.menuItemSelect(1);
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: 1,

                            justifyContent: 'center',
                        }}
                    >
                        <OfflinePinIcon fontSize='large'/>
                    </ListItemIcon>
                    <Typography variant="h6">Approvals</Typography>
                </ListItemButton>
            </ListItem>
            {props.role === 'admin' && (
                <>
                    <ListItem
                        key="LegalDocs"
                        disablePadding
                    >
                        <ListItemButton
                            sx={{
                                backgroundColor: selectedIndex === 3 ? '#BEBEBE' : '',
                            }}
                            onClick={() => {
                                setSelectedIndex(3);
                                props.menuItemSelect(3);
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: 1,
                                        justifyContent: 'center',
                                }}
                            >
                                <PlagiarismIcon fontSize="large"/>
                            </ListItemIcon>
                            <Typography variant="h6">Documents</Typography>
                        </ListItemButton>
                    </ListItem>
                    <ListItem
                        key="Invitations"
                        disablePadding
                    >
                        <ListItemButton
                            sx={{
                                backgroundColor: selectedIndex === 8 ? '#BEBEBE' : '',
                            }}
                            onClick={() => {
                                setSelectedIndex(8);
                                props.menuItemSelect(8);
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: 1,
                                        justifyContent: 'center',
                                }}
                            >
                                <EmailIcon fontSize="large"/>
                            </ListItemIcon>
                            <Typography variant="h6">Invitations</Typography>
                        </ListItemButton>
                    </ListItem>
                </>
            )}
            {(props.role === 'vendor' || props.role === "contact") && (
                <>
                    <ListItem
                        key="vendorProducts"
                        disablePadding
                    >
                        <ListItemButton
                            sx={{
                                backgroundColor: (selectedIndex === 6) ? '#BEBEBE' : '',
                            }}
                            onClick={() => {
                                setSelectedIndex(6);
                                props.menuItemSelect(6);
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: 1,
                                    justifyContent: "center"
                                }}
                            >
                                <WidgetsIcon fontSize='large'/>
                            </ListItemIcon>
                            <Typography variant="h6">Products</Typography>
                        </ListItemButton>
                    </ListItem>
                    <ListItem
                        key="vendorContacts"
                        disablePadding
                    >
                        <ListItemButton
                            sx={{
                                backgroundColor: selectedIndex === 7? '#BEBEBE' : '',
                            }}
                            onClick={() => {
                                setSelectedIndex(7);
                                props.menuItemSelect(7);
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: 1,
                                    justifyContent: "center"
                                }}
                            >
                                <ContactPageIcon fontSize='large'/>
                            </ListItemIcon>
                            <Typography variant="h6">Contacts</Typography>
                        </ListItemButton>
                    </ListItem>
                    
                </>
            )}
        </List>
    );

    return (
        <Grid container sx={{ display: 'flex' }} spacing={3}>
            <Grid item>
                    <Drawer
                        variant="permanent"
                        open={true}>
                        {drawer}
                    </Drawer>
            </Grid>
        </Grid>
    );
}
