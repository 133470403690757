import {
    Button,Dialog, DialogActions,DialogContent,DialogTitle,TextField,Typography,Tooltip,Grid,CircularProgress,InputAdornment,
    Autocomplete,CardMedia,IconButton,Box,} from '@mui/material';
import { useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { firstCapital } from '../Admin/Approvals/util';
import NumberFormatCustom from '../deals/NumberFormatCustom';
import axios from 'axios';
import DocumentUpload from '../transactions/DocumentUpload';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import ReactQuill from 'react-quill';

const ProductForm = (props: any) => {
    const {user,formData, setFormData, openDialog, handleCloseDialog, saveLoader, contactsList, amountHandler,
        handleContactChange, formik, amountValidate, productEdit, contactValidate, validateContact, validateAmount, file, setFile, editToggle, setEditToggle
        , imgUpdateMessage, setImageUpdateMessage, handleTargetCustomerChange, vendorRegistrationLoader, handleValuePropChange,openContactForm} = props;

    
    
    const getOptionsContacts = (option: any) => {
        if (option) {
            let newStr = firstCapital(option.firstName);
            if (option.lastName) {
                newStr = newStr + " " + firstCapital(option.lastName)
            }
            return (newStr);
        }
        return '';
    };
    const getContactValue = (idArr: any) => {
        let arr: any[] = [];
        
        
        if (contactsList && idArr) {
            for (let i = 0; i < idArr.length; i++) {
                let ind = contactsList.findIndex((ele: any) => ele.id == idArr[i])
                
                arr.push(contactsList[ind])
            }
        }
        return arr;
    }

    const handleImageChange = (newFile: any) => {
        setFile(newFile);
        
    };

    const generateLink = (obj: any) => {
        const file = new Blob([obj], { type: "image/png" });
        const fileURL = window.URL.createObjectURL(file);
        const link = document.createElement("a")
        link.href = fileURL;
        
        return String(fileURL);
    }

    const [imageLoader, setImageLoader] = useState(false);
    const handleEditToggle = () => {
        if (editToggle == true)
            setEditToggle(false)
        if (editToggle == false) {
            setEditToggle(true);
            setImageUpdateMessage(null);
        }
    }


    const handleUpdateImage = () => {
        
        setImageLoader(true);
        axios.put(process.env.REACT_APP_BASE_URL + "product/image/" + formData.id, { file },
            {
                headers: {
                    "Authorization": "Bearer "+user.accessToken,
                    "Content-type": "multipart/form-data"
                }
            })
            .catch((error: any) => {
                
                setImageLoader(false)
                
                setImageUpdateMessage(false)
            })
            .then((response: any) => {
                
                setImageLoader(false);
                
                setFormData({ ...formData, logo: response.data.logo, contentType: response.data.contentType });
                setEditToggle(false)
                setImageUpdateMessage(true)

            })
    }
    const fileTypes = {
        "image/*": ['.png', '.jpg', '.jpeg']
    };

    return (
        <form>
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                fullWidth
                maxWidth="lg"
                sx={{
                    '.MuiDialog-paper': {
                        position: 'absolute',
                        width: '80%',
                        height: '700px',
                        minWidth: '80%',
                    },
                }}
            >
                <DialogTitle sx={{ textAlign: "center", fontWeight: 'bold', backgroundColor: "#F0F7FF", fontSize: "26px" }}>{productEdit ? "Edit Product" : "New Product"}
                    <IconButton onClick={handleCloseDialog} sx={{ float: "right" }}>
                        <CloseIcon color="error" />
                    </IconButton>
                </DialogTitle>

                <DialogContent dividers>
                    {contactsList && contactsList.length > 0 ?
                        <Grid container spacing={1}>

                            <Grid item xs={12} sm={12} md={5} xl={5} lg={5}>
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: '16px',
                                    }}
                                >
                                    Product Image
                                </Typography>
                                {!productEdit ?
                                <>
                                    {file?
                                    <>  
                                        <Box sx={{textAlign:"right"}}><Button onClick={(e) => setFile(null)} sx={{color:"red"}}>Reset</Button></Box>
                                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><img src={generateLink(file)} alt="product image" width="50%" /></Box>
                                    </>
                                    :
                                    <Box sx={{minWidth:"100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        <DocumentUpload
                                        file={file}
                                        handleFileChange={handleImageChange}
                                        fileTypes={fileTypes}
                                        type={"productForm"}
                                        />
                                    </Box>}
                                </>
                                :<></>    
                                }
                                {(productEdit && !editToggle) && <>
                                {formData.logo ?
                                    <CardMedia
                                        component="img"
                                        image={`data:${formData.contentType};base64,${formData.logo}`}
                                        alt="Connected People"
                                        sx={{
                                            height: "12rem",
                                            objectFit: "contain"
                                        }}
                                    /> : <CardMedia
                                        component="img"
                                        image={"assets/icons/productIcon.png"}
                                        alt="Connected People"
                                        sx={{
                                            height: "12rem",
                                            objectFit: "contain"
                                        }}
                                    />}</>
                                }
                                {(productEdit && editToggle) && 
                                <>
                                {imageLoader ? <CircularProgress sx={{ display: "block", margin: "auto" }} /> 
                                : 
                                <>
                                {(imgUpdateMessage || imgUpdateMessage == false) ? 
                                <>
                                {imgUpdateMessage === false ? 
                                <>
                                    <img src="assets/loader/warning-sign.png" height={"100vh"} style={{ display: "block", margin: "auto" }} />
                                    <Typography sx={{ paddingTop: 2, display: "block", textAlign: "center", margin: "auto" }}>
                                        Some error occured
                                    </Typography>
                                </>
                                : 
                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><img src={generateLink(file)} alt="product image" width="70%" /></Box>
                                }
                                </> 
                                :
                                <>
                                    {file?
                                    <>  
                                        <Box sx={{textAlign:"right"}}><Button onClick={(e) => setFile(null)} sx={{color:"red"}}>Reset</Button></Box>
                                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><img src={generateLink(file)} alt="product image" width="50%" /></Box>
                                    </>
                                    :
                                    <Box sx={{minWidth:"100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        <DocumentUpload
                                        file={file}
                                        handleFileChange={handleImageChange}
                                        fileTypes={fileTypes}
                                        type={"productForm"}
                                        />
                                    </Box>}
                                </>
                                }</>}</>}
                            </Grid>
                            <Grid item xs={1}>{(productEdit && !file) && <Tooltip title={"Edit product image"} arrow placement="right-end" onClick={handleEditToggle}><IconButton><EditIcon /></IconButton></Tooltip>}
                                {(productEdit && file) && <Tooltip title={"Save product image"} arrow placement="right-end" onClick={handleUpdateImage}><IconButton><SaveIcon color="primary" /></IconButton></Tooltip>}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: '16px',
                                    }}
                                >
                                    Name <span style={{ color: '#00BFFF' }}>*</span>
                                    <Tooltip
                                        title={"Enter Product Name"}
                                        arrow
                                        placement="right-end"
                                    >
                                        <InfoOutlinedIcon color="disabled" sx={{fontSize: 22, paddingLeft: 0.5 }}/>
                                    </Tooltip> 
                                </Typography>

                                <TextField
                                    variant="outlined"
                                    sx={{ width: '100%' }}
                                    type="text"
                                    placeholder="Name to associate with product"
                                    id="name"
                                    name="name"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.name && Boolean(formik.errors.name)}
                                    helperText={formik.touched.name && formik.errors.name}
                                    InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            {(formik.values.name !== "" && !formik.touched.name) &&
                                              <>{Boolean(formik.errors.name) ? <CloseIcon color="error" /> : <CheckIcon color="success" />}</>}
                                            {formik.touched.name && <>{Boolean(formik.errors.name) ? <CloseIcon color="error" /> : <CheckIcon color="success" />}</>}
                                          </InputAdornment>
                                        ),
                                      }}
                                />
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: '16px',
                                    }}
                                >
                                    Product URL
                                    <Tooltip
                                        title={"Enter Product URL"}
                                        arrow
                                        placement="right-end"
                                    >
                                        <InfoOutlinedIcon color="disabled" sx={{fontSize: 22, paddingLeft: 0.5 }}/>
                                    </Tooltip> 
                                </Typography>

                                <TextField
                                    variant="outlined"
                                    sx={{ width: '100%' }}
                                    type="text"
                                    placeholder="URL to view product"
                                    id="url"
                                    name="url"
                                    value={formik.values.url}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.url && Boolean(formik.errors.url)}
                                    helperText={formik.touched.url && formik.errors.url}
                                    InputProps={{
                                        endAdornment: formik.values.url && (
                                          <InputAdornment position="end">
                                            {(formik.values.url !== "" && !formik.touched.url) &&
                                              <>{Boolean(formik.errors.url) ? <CloseIcon color="error" /> : <CheckIcon color="success" />}</>}
                                            {formik.touched.url && <>{Boolean(formik.errors.url) ? <CloseIcon color="error" /> : <CheckIcon color="success" />}</>}
                                          </InputAdornment>
                                        ),
                                      }}
                                />
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: '16px',
                                    }}
                                >
                                    Product Description
                                    <Tooltip
                                        title={"Enter Product Description"}
                                        arrow
                                        placement="right-end"
                                    >
                                        <InfoOutlinedIcon color="disabled" sx={{fontSize: 22, paddingLeft: 0.5 }}/>
                                    </Tooltip> 
                                </Typography>

                                <TextField
                                    variant="outlined"
                                    sx={{ width: '100%' }}
                                    type="text"
                                    placeholder="Brief description of product"
                                    id="description"
                                    name="description"
                                    value={formik.values.description}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.description && Boolean(formik.errors.description)}
                                    helperText={formik.touched.description && formik.errors.description}
                                    InputProps={{
                                        endAdornment: formik.values.description && (
                                          <InputAdornment position="end">
                                            {(formik.values.description !== "" && !formik.touched.description) &&
                                              <>{Boolean(formik.errors.description) ? <CloseIcon color="error" /> : <CheckIcon color="success" />}</>}
                                            {formik.touched.description && <>{Boolean(formik.errors.description) ? <CloseIcon color="error" /> : <CheckIcon color="success" />}</>}
                                          </InputAdornment>
                                        ),
                                      }}
                                />

                            </Grid>
                            <Grid item xl={4} lg={4} md={4} xs={12} sm={12}>
                            <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: '16px',
                                    }}
                                >
                                    Default Price
                                    <Tooltip
                                        title={"Enter Default Price"}
                                        arrow
                                        placement="right-end"
                                    >
                                        <InfoOutlinedIcon color="disabled" sx={{fontSize: 22, paddingLeft: 0.5 }}/>
                                    </Tooltip> 
                                </Typography>

                                <TextField
                                    variant="standard"
                                    fullWidth
                                    type="text"
                                    
                                    id="defaultPrice"
                                    name="defaultPrice"
                                    value={formData.defaultPrice}
                                    onChange={amountHandler}
                                    error={amountValidate.amountError}
                                    helperText={amountValidate.amountErrorText}
                                    InputProps={{
                                        inputComponent: NumberFormatCustom,
                                
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                $
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: '16px',
                                    }}
                                >
                                    Link Contacts <span style={{ color: '#00BFFF' }}>*</span>
                                    <Tooltip
                                        title={" Select contacts to link with product"}
                                        arrow
                                        placement="right-end"
                                    >
                                        <InfoOutlinedIcon color="disabled" sx={{fontSize: 22, paddingLeft: 0.5 }}/>
                                    </Tooltip> 
                                </Typography>
                                <Autocomplete
                                    multiple
                                    options={(contactsList && contactsList.length > 0) ? contactsList : []}
                                    getOptionLabel={getOptionsContacts}
                                    id="contactId"
                                    value={getContactValue(formData.contactId)}
                                    onChange={handleContactChange}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            
                                            size="small"
                                            error={contactValidate.contactError}
                                            helperText={contactValidate.contactErrorText}
                                            type="text"
                                            fullWidth
                                            variant="standard"
                                        />
                                    )}
                                />
                                <span style={{ fontSize: 13,color: '#00BFFF', fontWeight: "normal", cursor: "pointer" }} onClick={openContactForm}>Can't Find Contact?</span>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: '16px',
                                    }}
                                >
                                    Target Customers
                                    <Tooltip
                                        title={"Select target customers of product"}
                                        arrow
                                        placement="right-end"
                                    >
                                        <InfoOutlinedIcon color="disabled" sx={{fontSize: 22, paddingLeft: 0.5 }}/>
                                    </Tooltip>
                                </Typography>
                                <Autocomplete
                                    multiple
                                    options={["Hospital System", "Revenue Cycle Management", "Research", "Health Medical Supplies", "All"]}
                                    id="targetCustomer"
                                    defaultValue={[]}
                                    value={formData.targetCustomer}
                                    onChange={handleTargetCustomerChange}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            
                                            type="text"
                                            fullWidth
                                            variant="standard"
                                        />
                                    )}
                                />
                                </Grid>
                                <Grid item xs={12}>
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: '16px',
                                        marginBottom: 1.1
                                    }}
                                >
                                    Value Proposition
                                    <Tooltip
                                        title={"Enter Value Proposition"}
                                        arrow
                                        placement="right-end"
                                    >
                                        <InfoOutlinedIcon color="disabled" sx={{fontSize: 22, paddingLeft: 0.5 }}/>
                                    </Tooltip> 
                                </Typography>
                                <ReactQuill theme="snow" style={{ height: "90%" }} defaultValue={formData.valueProposition} onChange={handleValuePropChange} />
                            </Grid>
                        </Grid> :
                        <Grid item xs={12} sx={{ textAlign: 'center', marginTop: 2 }}>
                            {vendorRegistrationLoader ?
                                <>
                                    <Grid item xs={12} sx={{ textAlign: "center", marginTop: 10 }}>
                                        <img src="assets/loader/Spinner-px.gif" height={"140vh"} />
                                        <Typography sx={{ paddingTop: 2 }}>We couldn't find any contact associated with your profile, Please wait while we register you as a contact.</Typography>
                                    </Grid>
                                </>
                                :
                                <>
                                    {/* <img src="assets/loader/not_found.gif" height={'140vh'} />
                                    <Typography sx={{ fontWeight: 'bold' }}>
                                        No contacts found!
                                    </Typography>
                                    <Typography>
                                        Would you like to register yourself as a contact for the product ?
                                    </Typography>
                                    <Box>
                                        <Button onClick={registerVendorAsContact}>Yes</Button>
                                        <Button onClick={handleCloseDialog}>No</Button>
                                    </Box> */}
                                </>}
                        </Grid>}
                </DialogContent>
                {contactsList && contactsList.length > 0 ?
                    <>
                        <DialogActions>
                            <Button
                                onClick={handleCloseDialog}
                                variant="contained"
                                color="error"
                                disabled={saveLoader}
                                sx={{ width: 80, height: 40, padding: 1 }}
                            >
                                cancel
                            </Button>
                            <Button
                                onClick={(e) => {
                                    validateContact();
                                    validateAmount();
                                    formik.handleSubmit(e)
                                }}
                                type="button"
                                sx={{ width: 80, height: 40, padding: 1 }}
                                variant="contained"
                            >
                                {saveLoader ? (
                                    <>
                                        <CircularProgress sx={{ color: "white" }} />
                                    </>
                                ) : (
                                    <>Save</>
                                )}
                            </Button>
                        </DialogActions>
                    </>
                    : <></>}

            </Dialog>
        </form>
    );
};

export default ProductForm;
