import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import { useState, useEffect } from 'react';
import MuiDrawer from '@mui/material/Drawer';
import { useAuth0 } from '@auth0/auth0-react';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import {Button, Grid,Typography } from '@mui/material';
import { styled,Theme, CSSObject } from '@mui/material/styles';
import WidgetsIcon from '@mui/icons-material/Widgets';

const drawerWidth = "16.3%";

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});



const Drawer = styled(MuiDrawer, {
    shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
    width: drawerWidth,
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

export default function SideDrawer(props: any) {
    const { user } = props;
    let role = user?.role;
    const [open, setOpen] = useState(true);
    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });


    const handleDrawerToggle = () => {
        setOpen(!open);
    };
    
    const [selectedIndex, setSelectedIndex] = useState(0);
    useEffect(() => {
        if(role === "vendor" || role === "contact"){
            setSelectedIndex(7);
            props.menuItemSelect(7)
        }
        else{
            setSelectedIndex(1);
            props.menuItemSelect(1);
        }
    },[])
    const drawer = (
            <List sx={{ marginTop: 8 }}>
                {(role === "vendor" || role === "contact") && 
                <>
                    <ListItem key="Profile" disablePadding sx={{ display: 'block' }}>
                        <ListItemButton sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                            backgroundColor: selectedIndex === 7 ? '#BEBEBE' : '',
                        }}
                            onClick={() => {
                                setSelectedIndex(7)
                                props.menuItemSelect(7)
                            }}
                        >
                            <ListItemIcon sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            }}>
                                <AccountCircleIcon fontSize="large"/>
                            </ListItemIcon>
                            <ListItemText primary="My Organization" primaryTypographyProps={{fontSize: '18px'}}  sx={{ opacity: open ? 1 : 0}} />
                        </ListItemButton>
                    </ListItem>
                </>}
                <ListItem key="Profile" disablePadding sx={{ display: 'block' }}>
                    <ListItemButton sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                        backgroundColor: selectedIndex === 1 ? '#BEBEBE' : '',
                    }}
                        onClick={() => {
                            setSelectedIndex(1)
                            props.menuItemSelect(1)
                        }}
                    >
                        <ListItemIcon sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                        }}>
                            <AccountCircleIcon fontSize="large"/>
                        </ListItemIcon>
                        <ListItemText primary="My Profile" primaryTypographyProps={{fontSize: '18px'}}  sx={{ opacity: open ? 1 : 0}} />
                    </ListItemButton>
                </ListItem>
                {role !== "admin" &&
                    <ListItem key="LegalDocs" disablePadding sx={{ display: 'block' }}>
                        <ListItemButton sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                            backgroundColor: selectedIndex === 4 ? '#BEBEBE' : '',
                        }}
                            onClick={() => {
                                setSelectedIndex(4);
                                props.menuItemSelect(4);
                            }}>
                            <ListItemIcon sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            }}>
                                <PlagiarismIcon fontSize='large'/>
                            </ListItemIcon>
                            <ListItemText primary="Documents" primaryTypographyProps={{fontSize: '18px'}} sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>
                }
                {(role === "vendor" || role === "contact") && 
                <>
                
                <ListItem
                        key="vendorProducts"
                        disablePadding
                    >
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                                backgroundColor: selectedIndex === 5 ? '#BEBEBE' : '',
                            }}
                            onClick={() => {
                                setSelectedIndex(5);
                                props.menuItemSelect(5);
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <WidgetsIcon fontSize='large'/>
                            </ListItemIcon>
                            <Typography variant="h6">Products</Typography>
                        </ListItemButton>
                    </ListItem>
                    <ListItem
                        key="vendorContacts"
                        disablePadding
                    >
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                                backgroundColor: selectedIndex === 6 ? '#BEBEBE' : '',
                            }}
                            onClick={() => {
                                setSelectedIndex(6);
                               props. menuItemSelect(6);
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <ContactPageIcon fontSize='large'/>
                            </ListItemIcon>
                            <Typography variant="h6">Contacts</Typography>
                        </ListItemButton>
                    </ListItem>
                </>}

            </List>
    );

    return (
        <Grid container sx={{ display: 'flex' }} spacing={3}>
            <Grid item>
                    <Drawer
                        variant="permanent"
                        open={windowSize[0] > 1200 ? open : !open}>
                        {drawer}
                    </Drawer>
            </Grid>
        </Grid>
    );
}
