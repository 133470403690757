import { Alert, AlertTitle, Typography } from '@mui/material'

const PageNameComp = (props:any) => {
  return (
    <Alert severity="info" icon={false}>
        <AlertTitle>
            <Typography sx={{fontSize: "20px", fontWeight: "bold"}} display={"inline"}>{props.heading} - <Typography variant="subtitle1" display={"inline"}>{props.currentText}</Typography>
            </Typography>
        </AlertTitle>
    </Alert>
  )
}

export default PageNameComp