import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, CircularProgress, Grid, Link, Typography } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { startOfMonth, format } from 'date-fns';
import { ResponsiveBar } from '@nivo/bar';

import { Deal } from '../types';
import axios from 'axios';

const multiplier = {
    opportunity: 0.2,
    'proposal-sent': 0.5,
    'in-negociation': 0.8,
    delayed: 0.3,
};

export const DealsChart = (props:any) => {
    const {user} = props;
    const [chartLoader, setChartLoader] = useState(true);
    const [error, setError] = useState(false);
    const [empty, setEmpty] = useState(false);
    const [userInfo, setUserInfo] = useState<any>()
    const [months, setMonths] = useState<any[]>([]);
    const [dealsData, setDealsData] = useState<any>()
    useEffect(() => {
        setChartLoader(true);
        if(user){
            if(user.role === "admin"){
                axios.get(process.env.REACT_APP_BASE_URL+"deals/all",
                {
                    headers: {
                    "Authorization": "Bearer "+user.accessToken,
                  }
                })
                .then((response:any) => {
                    setDealsData(response.data)
                    setChartLoader(false);
                    if(response.data.length === 0){
                        setEmpty(true);
                    }
                })
                .catch(error => {
                    setChartLoader(false);
                } )
            }
            else{
                var userType = user?.role;
                if(user?.role === "vendor"){
                    userType = "contact"
                }
                axios.get(process.env.REACT_APP_BASE_URL+userType+"/mail/"+user?.email,
                {
                  headers: {
                      "Authorization": "Bearer "+user.accessToken,
                  }
                })
                .catch(error => {
                    setChartLoader(false)
                    setError(true)
                })
                .then((response:any) => {
                    if(user?.role === "vendor" || user?.role === "contact"){
                        setUserInfo(response.data.vendorId)
                    }
                    else{
                        setUserInfo(response.data)
                    }
                })
            }
        }
    },[user])

    useEffect(() => {
        if(userInfo){
            var userType = user.role;
            if(user?.role === "contact"){
                userType = "vendor"
            }
            if(userInfo){
                axios.get(process.env.REACT_APP_BASE_URL+"deals/"+userType+"/"+userInfo.id,
                {
                  headers: {
                      "Authorization": "Bearer "+user.accessToken,
                  }
                })
                .catch((error:any) => {
                    setChartLoader(false);
                })
                .then((response:any)=>{
                    setDealsData(response.data);
                    setChartLoader(false)
                    if(response.data.length === 0){
                        setEmpty(true);
                    }
                })
            }
        }
    },[userInfo])

    useEffect(() => {
        if (dealsData){
        const dealsByMonth = dealsData.reduce((acc:any, deal:any) => {
            const month = startOfMonth(
                deal.startDate ? new Date(deal.startDate) : new Date()
            ).toISOString();
            if (!acc[month]) {
                acc[month] = [];
            }
            acc[month].push(deal);
            return acc;
        },{} as any);

        const amountByMonth = Object.keys(dealsByMonth).map(month => {
            return {
                date: format(new Date(month), 'MMM'),
                won: dealsByMonth[month]
                    .filter((deal: Deal) => deal.stage === 'won')
                    .reduce((acc: number, deal: Deal) => {
                        acc += deal.amount;
                        return acc;
                    }, 0),
                pending: dealsByMonth[month]
                    .filter(
                        (deal: Deal) => !['won', 'lost', 'pending', 'introduction', 'opportunity', 'proposal', 'Delayed'].includes(deal.stage)
                    )
                    .reduce((acc: number, deal: Deal) => {
                        // @ts-ignore
                        acc += deal.amount * multiplier[deal.stage];
                        return acc;
                    }, 0),
                lost: dealsByMonth[month]
                    .filter((deal: Deal) => deal.stage === 'lost')
                    .reduce((acc: number, deal: Deal) => {
                        acc -= deal.amount;
                        return acc;
                    }, 0),
            };
        });

        setMonths(amountByMonth);
        }
    }, [dealsData]);

    const range = months.reduce((acc:any, month:any) => {
            acc.min = Math.min(acc.min, month.lost);
            acc.max = Math.max(acc.max, month.won + month.pending);
            return acc;
        },
        { min: 0, max: 0 }
    );

    return (
        <Grid container>
            <Grid item xs={12}>
                <Box display="flex" alignItems="center">
                    <Box ml={2} mr={2} display="flex">
                        <AttachMoneyIcon color="disabled" fontSize="large" />
                    </Box>
                    <Link
                        underline="none"
                        variant="h5"
                        color="textSecondary"
                        component={RouterLink}
                        to="/deals"
                    >
                        Upcoming Deal Revenue 
                        {error && <Typography variant="caption" sx={{marginLeft:2, display:{xs:"block", sm:"block", md:"inline", lg:"inline", xl:"inline"}}}>Some Error occurred while fetching your data, please try again</Typography>}
                        {user.role === "admin"?
                        <>
                        {empty && <Typography variant="caption" sx={{marginLeft:2, display:{xs:"block", sm:"block", md:"inline", lg:"inline", xl:"inline"}}}>There aren't any deals on your platform yet</Typography>}
                        </>:
                        <>
                        {empty && <Typography variant="caption" sx={{marginLeft:2, display:{xs:"block", sm:"block", md:"inline", lg:"inline", xl:"inline"}}}>Seems like you aren't associated with any deals yet</Typography>}
                        </>}
                    </Link>
                </Box>
                {chartLoader?
                <Grid item xs={12} sx={{ marginTop: 20, textAlign: "center", height:"45vh" }}><CircularProgress/><Typography sx={{ paddingTop: 2 }}>Please wait while we set up your revenue chart</Typography></Grid>
                :
                <Box height={500}>
                    <ResponsiveBar
                        data={months}
                        indexBy="date"
                        keys={['won', 'pending', 'lost']}
                        colors={['#61cdbb', '#97e3d5', '#e25c3b']}
                        margin={{ top: 50, right: 50, bottom: 50, left: 0 }}
                        padding={months.length > 6?0.3:months.length === 1?0.7:0.4}
                        valueScale={{
                            type: 'linear',
                            min: range.min * 1.2,
                            max: range.max * 1.2,
                        }}
                        indexScale={{ type: 'band', round: true }}
                        enableGridX={true}
                        enableGridY={false}
                        enableLabel={false}
                        axisTop={{
                            tickSize: 0,
                            tickPadding: 12,
                        }}
                        axisBottom={{
                            legendPosition: 'middle',
                            legendOffset: 50,
                            tickSize: 0,
                            tickPadding: 12,
                        }}
                        axisLeft={null}
                        axisRight={{
                            format: (v: any) => `${Math.abs(v / 1000)}k`,
                            tickValues: 8,
                        }}
                        markers={
                            [
                                {
                                    axis: 'y',
                                    value: 0,
                                    lineStyle: { strokeOpacity: 0 },
                                    textStyle: { fill: '#2ebca6' },
                                    legend: 'Won',
                                    legendPosition: 'top-left',
                                    legendOrientation: 'vertical',
                                },
                                {
                                    axis: 'y',
                                    value: 0,
                                    lineStyle: {
                                        stroke: '#f47560',
                                        strokeWidth: 1,
                                    },
                                    textStyle: { fill: '#e25c3b' },
                                    legend: 'Lost',
                                    legendPosition: 'bottom-left',
                                    legendOrientation: 'vertical',
                                },
                            ] as any
                        }
                    />
                </Box>}
            </Grid>
        </Grid>
    );
};
