import { Alert, AlertTitle } from '@mui/material'
import React from 'react'
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

const ProfileAlerts = (props:any) => {
    const {userInfo, legalCount, productCount, role} = props;
    return (
        <Alert severity="warning" sx={{marginTop : 1}}>
            <AlertTitle>Please complete the following sections of your profile so a ConnectorNet Admin can approve your profile:</AlertTitle>
            {(userInfo.addressVerified === false || userInfo.phoneNumber1 === null || userInfo.phoneNumber1 === "") ? <CheckBoxOutlineBlankIcon /> : <CheckBoxIcon sx={{ color: "green" }} />}<strong>My Organization</strong><br />
            {legalCount > 0 ? <CheckBoxIcon sx={{ color: "green" }} /> : <CheckBoxOutlineBlankIcon />}<strong>Upload signed legal documents to My Document</strong><br />
            {role !== "connector" && <>{(productCount === null || productCount === 0) ? <CheckBoxOutlineBlankIcon /> : <CheckBoxIcon sx={{ color: "green" }} />}<strong>Add at least 1 product</strong></>}
        </Alert>
    )
}

export default ProfileAlerts