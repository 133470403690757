import {Grid} from '@mui/material'
import AllProducts from './AllProducts';
import { useState } from 'react';
import ProductsTable from './ProductsTable';
import ServicesTable from './ServicesTable';
import TabMenu from '../Layout/TabMenu';
import PageNameComp from '../PageNameComp';


export default function Products({userData}:any) {
  const [currentComp,setCurrentComp] = useState(<AllProducts/>);
  const HandleTabMenu = (itemNo:any) => {
    if(itemNo===0)
      setCurrentComp(<AllProducts/>)
    else if(itemNo===1)
      setCurrentComp(<ProductsTable/>)
    else if(itemNo===2)
    setCurrentComp(<ServicesTable/>)
  }
    return (
      <Grid container spacing={2} sx={{ marginLeft: '120px' }}>
        
        <Grid item xs={12}>
          <TabMenu tabMenu={HandleTabMenu} item={["All Products","Products Table","Services Table"]}/>
        </Grid>
        <Grid item xs={11}>
          {currentComp}
      </Grid>
    </Grid>
  );
}
