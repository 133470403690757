import { Grid, stepContentClasses } from '@mui/material'
import React, { useState } from 'react'
import PageNameComp from '../PageNameComp';
import ProductsTable from '../products/ProductsTable'
import ActivitiesHeader from '../utils/ActivitiesHeader';

const VendorProducts = (props: any) => {
  const { user } = props;
  const [heading, setHeading] = useState("Products")
  const [currentText, setCurrentText] = useState("Find here the list of all products");
  return (
    <>
      <ActivitiesHeader
        currentText={currentText}
        heading={heading}
      />
      <Grid container columnSpacing={2}>
        <Grid item xs={12}>
          <ProductsTable HandleMenuItemSelect={props.HandleMenuItemSelect} user={user} />
        </Grid>
      </Grid>
    </>
  )
}

export default VendorProducts