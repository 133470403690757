import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, { useState } from 'react'
import TableHeadRow from '../../Layout/TableHeadRow'
import { convertCurrency} from '../Approvals/util';
import { linkCol } from '../../tableStyle';
import { getComparator, sortedRowInformation } from '../../utils/SortingHandler';
import TableCardComponent from '../Approvals/TableCardComponent';
import ProfileScreen from '../../Profile Screens/ProfileScreen';

const ProductList = (props: any) => {
    const { data } = props;
    const [valueToOrderBy, setValueToOrderBy] = useState();
    const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>();
    const [profileDialog, setProfileDialog] = useState<any>();
    const [currentSelection, setCurrentSelection] = useState<any>();
    const createSortHandler = (property: any) => (event: any) => {
        let isAscending;
        setValueToOrderBy(property);
        isAscending = orderDirection === 'asc';
        setOrderDirection(isAscending ? 'desc' : 'asc');
        sortedRowInformation(data, getComparator(orderDirection, property));
    }
    const showProfile = (row: any) => {
        setCurrentSelection(row);
        setProfileDialog(true)
    }
    return (
        <>
        <ProfileScreen showDialog={profileDialog} currentSelection={currentSelection} type={"product"} setShowDialog={setProfileDialog} />
        <Grid container>
        <TableContainer component={Paper} sx={{ marginTop: 1, display:{xs:"none", sm:"none", md:"block", lg:"block", xl:"block"} }}>
            <Table aria-label="simple table" size="small">
                <TableHead>
                    <TableRow>
                        <TableHeadRow createSortHandler={createSortHandler} valueToOrderBy={valueToOrderBy}
                            arr={[
                                {
                                    name: 'NAME',
                                    alignment:
                                        'left',
                                    valueToOrderBy:
                                        'name',
                                        orderDirection: orderDirection
                                },
                                {
                                    name: 'DEFAULT PRICE',
                                    alignment:
                                        'left',
                                    valueToOrderBy:
                                        'defaultPrice',
                                        orderDirection: orderDirection
                                },
                                {
                                    name: 'URL',
                                    alignment:
                                        'left',
                                    valueToOrderBy:
                                        false,
                                }
                            ]}
                        />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data && sortedRowInformation(data,getComparator(orderDirection, valueToOrderBy)).map((row: any) => {
                        return (
                            <TableRow key={row.id}>
                                <TableCell align="left"><Typography>
                                    {row.name}
                                </Typography></TableCell>
                                <TableCell align="left"><Typography>{row.defaultPrice ? convertCurrency(row.defaultPrice) : "--"}</Typography></TableCell>
                                <TableCell align="left"><Typography component="a" href={`${row.url}`} target='blank' style={linkCol}>{row.url}</Typography></TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
        <Grid item xs={12} sx={{display:{xs:"block", sm:"block", md:"none", lg:"none", xl:"none"}}}>
            <TableCardComponent rowData={data} userType={"productList"} showProfile={showProfile}/>
        </Grid>
        </Grid>
        </>
    )
}

export default ProductList