import { Divider, Grid, Typography } from '@mui/material';
import React from 'react'
import { convertNumber } from '../../Admin/Approvals/util';

const Vendor = (props:any) => {
  const {currentSelection, cardVendor} = props;
  const style = {padding:1 ,boxShadow:0, backgroundColor:"#F0F0F0", minHeight: 195};
  return (
    <>
        <Grid container spacing={2}>
          {/* <Grid xs={12} sx={{ marginTop:0 }}>
            <Divider />
          </Grid> */}
          <Grid container sx={style}>
          <Grid item xs={12} mb={1}>
          <Typography sx={{fontWeight:"bold", paddingLeft:1, fontSize:"20px"}}>Vendor Details</Typography>
          </Grid>
          <Grid container sx={{paddingX: 1}}>
            {cardVendor?<>
              <Grid item xs={12} mb={1}>
                {currentSelection.name? 
                      <Typography sx={{fontWeight:"bold"}}>{currentSelection.name}</Typography>
                    :
                      <Typography sx={{fontWeight:"light"}} >No vendor name available</Typography>
                    }
                </Grid>
                <Grid item xs={12} mb={1}>
                {currentSelection.address?
                      <Typography sx={{fontWeight:"light"}}>{currentSelection.address}</Typography>
                    :
                      <Typography sx={{fontWeight:"light"}} >No vendor address available</Typography>
                    }
                </Grid>
                <Grid item xs={12} mb={1}>
                {currentSelection.website? 
                      <Typography sx={{fontWeight:"light"}}><a href={currentSelection.website} target="/blank">{currentSelection.website}</a></Typography>
                    :
                      <Typography sx={{fontWeight:"light"}} >No vendor website link available</Typography>
                    }
                </Grid>
                <Grid item xs={12}>
                {currentSelection.description? 
                      <Typography sx={{fontWeight:"light", minHeight:40, maxHeight:40}}>{currentSelection.description}</Typography>
                    :
                      <Typography sx={{fontWeight:"light"}} >No description available</Typography>
                    }
                </Grid>
            </>
            :
            <>
              <Grid item xs={12} mb={1} height={130}>
              <Typography>Please select a vendor</Typography>
              </Grid>
            </>}
          {/* <Grid xs={12} sx={{ marginTop: 2 }}>
            <Divider />
          </Grid> */}
          </Grid>
          </Grid>
        </Grid>
    </>
  )
}

export default Vendor