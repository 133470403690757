import { Autocomplete, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const RejectionForm = (props:any) => {
    const {showDialog, handleClose, handleSubmit, saveLoader, type, formData, setFormData, rejectionType} = props;
    const rejectionReasons = [
        {id:0, reason: "Invalid Address"},
        {id:1, reason:"Invalid Legal Documents"},
        {id:2, reason:"Invalid Contact Number"},
        {id:3, reason:"Legal Agreements Violated"},
        {id:4, reason:"Inappropriate"},
        {id:5, reason:"Others"}
    ]
    const [selectedOption, setSelectedOption] = useState(null);
    const [ isInfoMandatory, setIsInfoMandatory ] = useState(false);
    const [disableSave, setDisableSave] = useState(true);
    const inputHandler = (e:any) => {
        setFormData({...formData, rejectionDescription:e.target.value});
        if(isInfoMandatory){
            if(e.target.value.length === 0){
                setFormValidation({error:true, message:"An appropriate reason is required"});
                setDisableSave(true);
            }
            else if(e.target.value.length > 0 && e.target.value.length < 10){
                setFormValidation({error:true, message:"The reason should consist of 10 characters at least"});
                setDisableSave(true);
            }
            else{
                setFormValidation({error:false, message:""});
                setDisableSave(false);
            }
        }
    }
    useEffect(() => {
        setDisableSave(true);
    },[showDialog])
    const [formValidation, setFormValidation] = useState({
        error: false,
        message:""
    });
    const getOptions = (option: any) => {
        if (option) {
            return option.reason;
        }
        return selectedOption || '';
    };
    const handleReasons = (event: any, newVal: any) => {
        if(type === "Registration"){
            setFormData({...formData, rejectionReason:newVal.reason, status:rejectionType});
        }
        else{
            setFormData({...formData, rejectionReason:newVal.reason, isApproved:rejectionType});
        }
        if(newVal.reason === "Others"){
            setIsInfoMandatory(true);
            setFormValidation({error:true, message:"An appropriate reason is required"});
            setDisableSave(true);
        }
        else{
            setIsInfoMandatory(false);
            setFormValidation({error:false, message:""});
            setDisableSave(false);
        }     
    }
    return (
        <Dialog open={showDialog}
        fullWidth
        maxWidth="lg"
        
        sx={{
            '.MuiDialog-paper': {
            position: 'absolute',
            width: {xs:"90%", sm:"85%", md:"65%", lg:"65%", xl:"50%"},
            height: {xs:"70%", sm:"70%", md:"85%", lg:"85%", xl:"85%"},
            minWidth:  {xs:"90%", sm:"85%", md:"65%", lg:"65%", xl:"50%"},
            },
        }}>
            <DialogTitle sx={{ textAlign: "center", fontWeight: 'bold', backgroundColor: "#F0F7FF", fontSize:{xs:"large", sm:"large", md:"34px", lg:"34px", xl:"34px"} }}> Rejection
                <IconButton onClick={handleClose} sx={{ float: "right", color: "red" }}>
                <CloseIcon />
                </IconButton>
            </DialogTitle>
        <DialogContent>
        <Grid container spacing={2} sx={{paddingTop:4, paddingX:2}}>
                <Grid item xs={12} sm={12} md={7} xl={7} lg={7}>
                    <Typography sx={{fontWeight: 'bold', fontSize: '20px'}}>
                        Reason{' '}
                    <Typography sx={{ color: '#00BFFF', display:"inline" }}>*</Typography>
                    </Typography>
                    <Typography variant="caption" sx={{paddingY:0}}>Select a reason behind the rejection of this {type}</Typography>
                    <Autocomplete
                        options={rejectionReasons}
                        getOptionLabel={getOptions}
                        id="reasons"
                        onChange={handleReasons}
                        disableClearable
                        renderInput={params => (
                            <TextField
                                {...params}
                                label="Select a reason"
                                type="text"
                                fullWidth
                                variant="outlined"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sx={{ marginY: 2 }}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Typography sx={{fontWeight: 'bold', fontSize: '20px'}}>
                        Additional Information{' '}
                    {isInfoMandatory?<Typography sx={{ color: '#00BFFF', display:"inline" }}>*</Typography>:<Typography variant="caption">(optional)</Typography>}
                    </Typography>
                    <Typography variant="caption" sx={{paddingY:0}}>Please share further information</Typography>
                    <TextField
                        id="mailBody"
                        name="mailBody"
                        label="Enter your reason here"
                        multiline
                        rows={3}
                        onChange={inputHandler}
                        variant="outlined"
                        fullWidth
                        error={formValidation.error}
                        helperText={formValidation.message}
                        />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
        <Button
                onClick={handleClose}
                variant="contained"
                color="error"
                sx={{ width: 100, height: 45, padding: 1 }}
                disabled={saveLoader}
            >
                Cancel
            </Button>
            <Button
                onClick={handleSubmit}
                variant="contained"
                color="primary"
                disabled={disableSave}
                sx={{ width: 100, height: 45, padding: 1 }}
            >
                {saveLoader?<CircularProgress sx={{color:"white"}}/>:"Save"}
            </Button>
        </DialogActions>
        </Dialog>
    )
}

export default RejectionForm