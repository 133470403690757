import { Card, Box, Typography, Button, Grid, Divider, Radio } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const DocumentUpload = (props: any) => {
    const { handleFileChange, file, fileTypes, toggleUpload, type, onReset, showDocOptions, setDocumentStatus, documentStatus } = props;
    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        accept: fileTypes
    });


    const [error, setError] = useState(false);
    const [boxStyles, setBoxStyles] = useState(
        {
            minHeight: 200,
            maxHeight: 250,
            paddingTop: 6,
            textAlign: "center",
            width: (type === "productForm") ? 300 : 500,
            display: { sx: { marginLeft: 0 }, sm: { marginLeft: 0 }, md: { marginLeft: 8 } },
            borderRadius: 5,
            border: "dashed",
            marginTop: (type === "productForm") ? 1.1 : 0,
        });

    const [selectedValue, setSelectedValue] = useState('');

    useEffect(() => {


        if (fileRejections.length > 0) {
            setError(true)
        }
        else (
            setError(false)
        )
        if (acceptedFiles) {
            handleFileChange(acceptedFiles[0])
        }
    }, [fileRejections, acceptedFiles])

    return (
        <>
            <Grid container justifyContent={'center'}>
                
                <Box {...getRootProps({ className: 'dropzone' })}
                    sx={boxStyles}
                >
                    <input {...getInputProps()} />
                    {file ?
                        <>
                            <CloudDoneIcon sx={{ fontSize: 50, color: "green" }} />
                            <Typography sx={{ color: "green" }}>File added successfully</Typography>
                        </>
                        :
                        <>
                            {isDragActive ?
                                <>
                                    {isDragAccept ?
                                        <Typography sx={{ color: "green" }}>Drop your file here</Typography>
                                        :
                                        <Typography sx={{ color: "red" }}>Invalid file format</Typography>}
                                </>
                                :
                                <>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <FileUploadIcon sx={{ fontSize: 50 }} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography>Drag 'n' drop some files here, or click to select files</Typography>
                                            <Typography variant="caption">
                                                Only
                                                {type === "pdf" ? <>*.pdf </> : <></>}
                                                {type === "image" || type === "productForm" ? <>*jpg, *jpeg and *png </> : <></>}
                                                {type === "attachment" ? <>*.pdf, *.zip, *.jpg, *.jpeg, *.png, *.docx, *.doc, *.ppt, *.pptx, *.xsl and *.xlsx </> : <></>}
                                                files will be accepted
                                            </Typography><br />
                                            <Typography variant="caption">Size of the file should not exceed 5MB</Typography><br />
                                            {error ?
                                                <>
                                                    <Typography variant="caption" sx={{ color: "red" }}>You tried uploading an invalid file, please try again</Typography>
                                                </>
                                                : <></>}
                                        </Grid>
                                    </Grid>
                                </>}
                        </>}


                </Box>
            </Grid>



        </>
    );
};

export default DocumentUpload;
