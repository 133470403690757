import {Box, CircularProgress, Card, Grid, Typography, Button } from '@mui/material';
import React from 'react'
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import EmailIcon from '@mui/icons-material/Email';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import HandshakeIcon from '@mui/icons-material/Handshake';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { statusColor, statusColorText } from '../tableStyle';
import { convertCurrency, convertDate, firstCapital } from '../Admin/Approvals/util';
import { useAuth0 } from '@auth0/auth0-react';
const RegistrationCardComponent = (props:any) => {
    const {rowData, onApprove, onClickReject, userType, approveLoader, showProfile, handleInvite, handleInvitationSentClick, handleCreateDeal, user} = props;
    const profileLink = 
    {
        fontWeight:"bold",
        fontSize: "16px",
        "&:hover": {
            color: "#0000ff"
        },
        textTransform:"none",
        whiteSpace: 'nowrap',
        textDecoration: 'none',
        cursor: "pointer",
        color: "#00BFFF",
        display:"inline"
    }
  return (
    <Box>
        {rowData.map((row:any) => {
            return(
                <>
                <Card sx={{width:"100%", padding:3, marginY:4, boxShadow:3, shadow:0, border:0}}>
                    <Grid container>
                        {userType === "deal" ?
                        <>
                        <Grid item xs={1}><AppRegistrationIcon/></Grid>
                        <Grid item xs={8}>
                            <Typography
                                onClick={(e) => showProfile(row,"deal")}
                                sx={{
                                    fontWeight:"bold",
                                    fontSize: "20px",
                                    "&:hover": {
                                        color: "#0000ff"
                                    },
                                    textTransform:"none",
                                    whiteSpace: 'nowrap',
                                    textDecoration: 'none',
                                    cursor: "pointer",
                                    color: "#00BFFF",
                                    display:"inline"
                                }}
                            >
                                {row.name}
                            </Typography>
                        </Grid>
                        </>
                        :
                        <Grid item xs={9}><AppRegistrationIcon/></Grid>
                        }
                        <Grid item xs={3} sx={{justifyContent:"right", display:"flex", alignItems:"right"}}>
                            <Box 
                                sx={{
                                    backgroundColor: statusColor[row.status],
                                    color: statusColorText[row.status],
                                    padding: 1,
                                    width: 110,
                                    borderRadius: 12,
                                    whiteSpace:"noWrap"
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                        fontSize: "15px"
                                    }}
                                >
                                    {row.status === "invitesSent" ? "Invite Sent" : row.status === "dealStarted" ? "Deal Started" : firstCapital(row.status)}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="caption" sx={{fontWeight:"light"}}>Applied Date: {convertDate(row.createdDatetime)}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {(user?.role === "vendor" || user?.role === "contact" || user?.role === "admin") && 
                            <Grid container sx={{backgroundColor:user.role === "admin"?"#fff5e8":"", height:"50px",justifyContent:"center", alignItems:"center"}}>
                                <Grid item xs={6}>
                                    Connector
                                </Grid>
                                <Grid item xs={6} sx={{justifyContent:"right", display:"flex", alignItems:"right"}}>
                                    <Typography
                                        onClick={(e) => showProfile(row.connectorId, "connector")}
                                        sx={profileLink}
                                    >
                                        {row.connectorId.name}
                                    </Typography>
                                </Grid>
                            </Grid>
                            }
                            {(user?.role === "connector" || user?.role === "admin") && 
                            <Grid container sx={{ height:"50px",justifyContent:"center", alignItems:"center"}}>
                                <Grid item xs={6}>
                                    Vendor
                                </Grid>
                                <Grid item xs={6} sx={{justifyContent:"right", display:"flex", alignItems:"right"}}>
                                    <Typography
                                        onClick={(e) => showProfile(row.vendorId,"vendor")}
                                        sx={profileLink}
                                    >
                                        {row.vendorId.name}
                                    </Typography>
                                </Grid>
                            </Grid>
                            }
                            <Grid container sx={{backgroundColor:"#fff5e8", height:"50px",justifyContent:"center", alignItems:"center"}}>
                                <Grid item xs={6}>
                                    Product
                                </Grid>
                                <Grid item xs={6} sx={{justifyContent:"right", display:"flex", alignItems:"right"}}>
                                    <Typography
                                        onClick={(e) => showProfile(row.productId, "product")}
                                        sx={profileLink}
                                    >
                                        {row.productId.name}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container sx={{height:"50px",justifyContent:"center", alignItems:"center"}}>
                                <Grid item xs={6}>
                                    Customer
                                </Grid>
                                <Grid item xs={6} sx={{justifyContent:"right", display:"flex", alignItems:"right"}}>
                                    <Typography
                                        onClick={(e) => showProfile(row.customerId, "customer")}
                                        sx={profileLink}
                                    >
                                        {row.customerId.firstName} {row.customerId.lastName}
                                    </Typography>
                                </Grid>
                            </Grid>
                            {userType === "deal"?
                            <>
                            <Grid container sx={{backgroundColor:"#fff5e8", height:"50px",justifyContent:"center", alignItems:"center"}}>
                                <Grid item xs={6}>
                                    Amount
                                </Grid>
                                <Grid item xs={6} sx={{justifyContent:"right", display:"flex", alignItems:"right"}}>
                                    <Typography
                                        onClick={(e) => showProfile(row.productId, "product")}
                                        sx={{fontWeight:"bold"}}
                                    >
                                        {convertCurrency(row.amount)}
                                    </Typography>
                                </Grid>
                            </Grid>
                            </>
                            :
                            <>
                            <Grid container sx={{backgroundColor:"#fff5e8", height:"50px",justifyContent:"center", alignItems:"center"}}>
                                <Grid item xs={6}>
                                    Active Date
                                </Grid>
                                <Grid item xs={6} sx={{justifyContent:"right", display:"flex", alignItems:"right"}}>
                                   <Typography>
                                        {convertDate(row.approvedRejectedDatetime)}
                                   </Typography>
                                </Grid>
                            </Grid>
                            <Grid container sx={{height:"50px",justifyContent:"center", alignItems:"center"}}>
                                <Grid item xs={6}>
                                    Expiration Date
                                </Grid>
                                <Grid item xs={6} sx={{justifyContent:"right", display:"flex", alignItems:"right"}}>
                                    <Typography>
                                        {convertDate(row.expirationDate)}
                                   </Typography>
                                </Grid>
                            </Grid>
                            </>}
                            {userType !== "deal" && 
                            <Grid container sx={{backgroundColor:"#fff5e8", height:"55px", display:"flex", justifyContent:"center", alignItems:"center"}}>
                            <Grid item xs={12} spacing={2} sx={{justifyContent:"center", display:"flex", alignItems:"center"}}>
                                {approveLoader === row.id ?
                                    <CircularProgress size="1.6rem" />
                                :
                                <>
                                    {user?.role === "contact" ?
                                    <>
                                        <Typography sx={{fontWeight:"light"}}>Only a vendor admin can take further actions</Typography>
                                    </>
                                    :
                                    <>
                                        {row.status === "approved" && 
                                        <>
                                        {user?.role === "vendor" && <Typography sx={{fontWeight:"light"}}>Please wait while the invites are being sent out</Typography>}
                                        {user?.role === "connector" && 
                                        <>
                                            <Button sx={{flexDirection:"column", marginX:1}} onClick={() => {handleInvite(row)}}>
                                                <EmailIcon/>
                                                <span style={{fontSize:"small"}}>Send Invite</span>
                                            </Button>
                                            <Button sx={{flexDirection:"column", marginX:1}} onClick={() => handleInvitationSentClick(row)}>
                                                <MarkEmailReadIcon/>
                                                <span style={{fontSize:"small"}}>Invite Sent?</span>
                                            </Button>
                                        </>}
                                        </>}
                                        {row.status === "pending" && 
                                        <>
                                        {user?.role === "vendor" && 
                                        <>
                                            <Button sx={{flexDirection:"column", marginX:1, color:"green"}} onClick={() => onApprove(row, "approved")}>
                                                <VerifiedUserIcon/>
                                                <span style={{fontSize:"small"}}>Approve</span>
                                            </Button>
                                            <Button sx={{flexDirection:"column", marginX:1, color:"red"}} onClick={onClickReject}>
                                                <PersonOffIcon/>
                                                <span style={{fontSize:"small"}}>Reject</span>
                                            </Button>
                                        </>}
                                        {user?.role === "connector" && <Typography sx={{fontWeight:"light"}}>Please wait for the vendor's response</Typography>}
                                        </>}
                                        {row.status === "invitesSent" && 
                                        <>
                                        {user?.role === "vendor" && 
                                        <>
                                            <Button sx={{flexDirection:"column", marginX:1, color:"green"}} onClick = {() => handleCreateDeal(row)}>
                                                <HandshakeIcon/>
                                                <span style={{fontSize:"small"}}>New Deal</span>
                                            </Button>
                                        </>}
                                        {user?.role === "connector" && <Typography sx={{fontWeight:"light"}}>Please wait for the vendor to start the deal</Typography>}
                                        </>}
                                        {row.status === "dealStarted" && 
                                        <Typography sx={{fontWeight:"light"}}>No further action required</Typography>}
                                    </>    
                                }
                                </>}
                            </Grid>
                        </Grid>}
                        </Grid>
                    </Grid>
                </Card>
                </>
            )
        })}
    </Box>
  )
}

export default RegistrationCardComponent