import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, { useState } from 'react'
import TableHeadRow from '../Layout/TableHeadRow';
import { getComparator, sortedRowInformation } from '../utils/SortingHandler';
import TableCardComponent from '../Admin/Approvals/TableCardComponent';

const ContactList = (props: any) => {
    const { data } = props;
    const [valueToOrderBy, setValueToOrderBy] = useState();
    const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>();
    const createSortHandler = (property: any) => (event: any) => {
        let isAscending;
        setValueToOrderBy(property);
        isAscending = orderDirection === 'asc';
        setOrderDirection(isAscending ? 'desc' : 'asc');
        sortedRowInformation(data, getComparator(orderDirection, property));
    }
    return (
        <>
       <TableContainer component={Paper} sx={{ marginTop: 1, display:{xs:"none", sm:"none", md:"block", lg:"block", xl:"block"} }}>
            <Table aria-label="simple table" size="small">
                <TableHead>
                    <TableRow>
                        <TableHeadRow createSortHandler={createSortHandler} valueToOrderBy={valueToOrderBy}
                            arr={[
                                {
                                    name: 'NAME',
                                    alignment:
                                        'left',
                                    valueToOrderBy:
                                        'firstName',
                                    orderDirection: orderDirection
                                },
                                {
                                    name: 'EMAIL',
                                    alignment:
                                        'left',
                                    valueToOrderBy:
                                        false,
                                },
                                {
                                    name: 'DESIGNATION',
                                    alignment:
                                        'left',
                                    valueToOrderBy:
                                        false,
                                }
                            ]}
                        />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data && sortedRowInformation(data,getComparator(orderDirection, valueToOrderBy)).map((row: any) => {
                        return (
                            <TableRow key={row.id}>
                                <TableCell align="left"><Typography>
                                    {row.firstName} {row.lastName ? row.lastName : ""}
                                </Typography></TableCell>
                                <TableCell align="left">
                                    <Typography
                                        component="a"
                                        href={`mailto:${row.email}`}
                                        sx={{
                                            textDecoration:
                                                'none',
                                            color: '#00BFFF',
                                            '&:hover':
                                            {
                                                color: '#0000ff',
                                            },
                                        }}
                                    >
                                        {
                                            row.email
                                        }
                                    </Typography>
                                </TableCell>
                                <TableCell align="left">{row.designation}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
        <Grid container sx={{display:{xs:"block", sm:"block", md:"none", lg:"none", xl:"none"}}}>
            <TableCardComponent rowData={data} userType = {"productContacts"}/>
        </Grid>
        </>
    )
}

export default ContactList