import { Alert, AlertTitle, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import axios from 'axios';
import { linkHover, tableHead } from '../tableStyle';
import { convertCurrency, convertDate } from '../Admin/Approvals/util';
import WestIcon from '@mui/icons-material/West';
import { useAuth0 } from '@auth0/auth0-react';
import ModeEdit from '@mui/icons-material/ModeEdit';
import NumberFormatCustom from '../deals/NumberFormatCustom';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import TableHeadRow from '../Layout/TableHeadRow';
import { getComparator, sortedRowInformation } from '../utils/SortingHandler';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import InvoiceUploadError from './InvoiceUploadError';
import { PDFObject } from 'react-pdfobject';
import CloseIcon from '@mui/icons-material/Close';
import TransactionsTableCard from './TransactionsTableCard';

const ShowInvoice = (props:any) => {
    const{showDialog, transactionData, setShowDialog, setTransactionData, setFile, user, onUpdateTotalAmounts} = props;
    const [invoice, setInvoice] = useState<any>();
    const [error, setError] = useState(false);
    const [loader, setLoader] = useState(true);
    const[showPdf, setShowPdf] = useState(false);
    const[currentInvoice, setCurrentInvoice] = useState<any>()
    const[editable, setEditable] = useState(-1);
    const[formData, setFormData] = useState<any>();
    const[disableEdit, setDisableEdit] = useState(false);
    const[amountValidation, setAmountValidation] = useState({
        error:false,
        message:""
    })
    const[saveLoader, setSaveLoader] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const handleChangePage = (event: any, newPage: any) => {
        setPage(newPage);
    };const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(+event.target.value);

        setPage(0);
    };
    const[editError, setEditError] = useState(false);
    useEffect(() => {
        if(transactionData){
            setLoader(true);
            axios.get(process.env.REACT_APP_BASE_URL+"receipt/transaction/"+transactionData.id,
            {
              headers: {
                  "Authorization": "Bearer "+user.accessToken,
              }
            })
            .catch((error) => {
                
                setError(true);
                setInvoice(null);
            })
            .then((response:any) => {
                setInvoice(response.data);
                
                setLoader(false);
            })
        }
    },[transactionData,showDialog])
    const handleClose = () => {
        setTransactionData(null);
        setShowDialog(false);
        setError(false);
        setShowPdf(false);
        cancelEdit();
        setFile(null);
        setDisableEdit(false);
    }
    const amountHandler = (e:any) => {
        setFormData({...formData, receivedAmount: Number(e.target.value)});
        if(Number(e.target.value) < 1){
            setAmountValidation({...amountValidation, error:true, message:"The received amount must be greater than 0."})
        }
        else if(Number(e.target.value) > currentInvoice.invoiceAmount){
            setAmountValidation({...amountValidation, error:true, message:"The received amount cannot be greater than the invoice amount."})
        }
        else{
            setAmountValidation({...amountValidation, error:false, message:""})
        }
    }
    const showCurrentInvoice = (record:any) => {
        setCurrentInvoice(record);
        setShowPdf(true);
    }
    const onBack = () => {
        setShowPdf(false);
    }
    const onEdit = (record:any) => {
        setEditable(record.id);
        setFormData(record);
        setDisableEdit(true);
        setCurrentInvoice(record);
    }
    const cancelEdit = () => {
        setEditable(-1);
        setDisableEdit(false);
        setAmountValidation({...amountValidation, error:false, message:""})
    }
    const onSave = () => {
        if(amountValidation.error || formData.receivedAmount === 0 || formData.receivedAmount === null){
            setAmountValidation({...amountValidation, error:true, message:"Please enter a valid amount."})
        }
        else{
            setSaveLoader(true)
            axios.put(process.env.REACT_APP_BASE_URL+"receipt/"+formData.id, formData,
            {
              headers: {
                  "Authorization": "Bearer "+user.accessToken,
              }
            })
            .catch((error:any) => {
                
                setEditError(true);
                setEditable(-1);
                setDisableEdit(false);
            })
            .then((response:any) => {
                onUpdateTotalAmounts(transactionData.id);
                var tempData = [...invoice]
                const ind = tempData.findIndex(obj => obj.id === response.data.id); 
                tempData[ind] = response.data;
                setInvoice(tempData);
                setSaveLoader(false);
                cancelEdit();
            })
        }
    }
    const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>();
    const [valueToOrderBy, setValueToOrderBy] = useState();
    const [receivedDate, setReceivedDate] = React.useState<Dayjs | null>(dayjs());
    const [today, setToday] = React.useState<Dayjs | null>(dayjs());
    const createSortHandler = (property: any) => (event: any) => {
        let isAscending;
        setValueToOrderBy(property);
        isAscending = orderDirection === 'asc';
        setOrderDirection(isAscending ? 'desc' : 'asc');
        sortedRowInformation(invoice, getComparator(orderDirection, property));
    };

    useEffect(() => {
        setFormData({ ...formData, receivedDate: receivedDate?.format().slice(0,-6) });
    }, [receivedDate,editable]);

    const truncateName = (name:string) => {
        var res = name.slice(0,12);
        res = res+"..."
        return res;
    }

    const handleErrorClose = () => {
        setEditError(false);
    }

  return (
    <>
    <InvoiceUploadError showDialog={editError} handleClose={handleErrorClose} type={"edit"}/>
    <Dialog open={showDialog} sx={{
        '.MuiDialog-paper': {
            position: 'absolute',
            width: {xs:"90%", sm:"90%", md:"80%", lg:"80%", xl:"80%"},
            height: '900px',
            minWidth: {xs:"90%", sm:"90%", md:"80%", lg:"80%", xl:"80%"}
        },
    }}>
        <DialogTitle sx={{ textAlign: "center", fontWeight: 'bold', backgroundColor: "#F0F7FF", fontSize:{xs:"large", sm:"large", md:"34px", lg:"34px", xl:"34px"} }}>
            Transaction Invoice
                <Tooltip
                    title={`Preview and download your transaction invoice here.`}
                    arrow
                    placement="right-end"
                >
                    <Button>
                        <InfoOutlinedIcon />
                    </Button>
                </Tooltip>
            <IconButton onClick={handleClose} sx={{ float: "right", color: "red" }}>
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent sx={{overflow:"hidded", '&::-webkit-scrollbar': {display: "none"}}}>
            <Grid container spacing={2}>
                {error?
                <>
                    <Grid item xs={12} sx={{ textAlign: 'center', marginTop: 8 }}>
                        <img src="assets/loader/not_found.gif" height={'140vh'} alt="No data found icon"/>
                        <Typography sx={{ fontWeight: 'bold' }}>
                            No Invoice Found!
                        </Typography>
                        <Typography>
                            Seems like you haven't uploaded any invoice yet
                            <br />
                        </Typography>
                    </Grid>
                </>
                :
                <>
                    <Grid item xs={12}>
                        {!loader?
                        <>
                        {showPdf?
                        <>
                            <Grid container sx={{justifyContent:"center"}}>
                                <Grid item xs={10}>
                                    <PDFObject width="100%" height="680px" url={`data:application/pdf;base64,${currentInvoice.content}`}/>
                                </Grid>
                            </Grid>
                        </>
                        :
                        <>
                            <TableContainer component={Paper} sx={{ marginBottom: 2, display:{xs:"none", sm:"none", md:"block", lg:"block", xl:"block"} }}>
                                <Table aria-label="simple table" size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableHeadRow createSortHandler={createSortHandler} valueToOrderBy={valueToOrderBy} arr={[
                                                {
                                                    name: 'INVOICE NAME',
                                                    alignment: 'left',
                                                    valueToOrderBy: 'name',
                                                    orderDirection: orderDirection
                                                },
                                                {
                                                    name: 'INVOICE AMOUNT',
                                                    alignment: 'right',
                                                    valueToOrderBy: 'invoiceAmount',
                                                    orderDirection: orderDirection
                                                },
                                                {
                                                    name: 'RECEIVED AMOUNT',
                                                    alignment: 'right',
                                                    valueToOrderBy: 'receivedAmount',
                                                    orderDirection: orderDirection
                                                },
                                                {
                                                    name: 'RECEIVED ON',
                                                    alignment: 'right',
                                                    valueToOrderBy: 'modifiedDatetime',
                                                    orderDirection: orderDirection
                                                },
                                                {
                                                    name: 'DUE DATE',
                                                    alignment: 'right',
                                                    valueToOrderBy: 'dueDate',
                                                    orderDirection: orderDirection
                                                },
                                                {
                                                    name: 'UPLOAD DATE',
                                                    alignment: 'right',
                                                    valueToOrderBy: 'createdDatetime',
                                                    orderDirection: orderDirection
                                                },
                                            ]}/>
                                            {user?.role !== "connector"?
                                            <>
                                                <TableCell align="right" sx={tableHead}>

                                                </TableCell>
                                            </>
                                            :
                                            <></>}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {sortedRowInformation(
                                            invoice.slice(
                                                page * rowsPerPage,
                                                page * rowsPerPage + rowsPerPage
                                                ),getComparator(orderDirection, valueToOrderBy)).map((record:any) => {
                                            return(
                                                <>
                                                    <TableRow>
                                                        <TableCell align="left" sx={{height:73, width:"150px"}}>
                                                        <Tooltip
                                                            title={record.name}
                                                            arrow
                                                            placement="top"
                                                        >
                                                            <Typography
                                                                sx={linkHover}
                                                                onClick = {(e) => {showCurrentInvoice(record)}}
                                                            >
                                                                {truncateName(record.name)}
                                                            </Typography>
                                                        </Tooltip>
                                                        </TableCell>
                                                        <TableCell align="right" sx={{height:73, paddingRight:5.2}}>
                                                            <Typography>
                                                                {convertCurrency(record.invoiceAmount)}
                                                            </Typography>
                                                        </TableCell>
                                                        {record.id === editable?
                                                        <>
                                                            <TableCell align="right" sx={{height:73}}>
                                                            <TextField
                                                                variant="filled"
                                                                sx={{ width: "160px" }}
                                                                type="text"
                                                                name="receivedAmount"
                                                                value={formData.receivedAmount}
                                                                error={amountValidation.error}
                                                                helperText={amountValidation.message}
                                                                InputProps={{
                                                                    inputComponent: NumberFormatCustom,
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            $
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                                onChange={amountHandler}
                                                            />
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <LocalizationProvider
                                                                    dateAdapter={AdapterDayjs}
                                                                    sx={{ marginTop: 0 }}
                                                                >
                                                                    <DemoContainer
                                                                        components={[
                                                                            'DatePicker',
                                                                            'DatePicker',
                                                                        ]}
                                                                        sx={{ marginTop: 0 }}
                                                                    >
                                                                        <DatePicker
                                                                            value={receivedDate}
                                                                            onChange={newValue =>
                                                                                setReceivedDate(newValue)
                                                                            }
                                                                            sx={{
                                                                                marginTop: 0
                                                                            }}
                                                                            slotProps={{
                                                                                textField: {
                                                                                    inputProps:{
                                                                                        disabled:true
                                                                                    }
                                                                                },
                                                                            }}
                                                                            maxDate={today}
                                                                        />
                                                                    </DemoContainer>
                                                                </LocalizationProvider>
                                                            </TableCell>
                                                        </>
                                                        :
                                                        <>
                                                            <TableCell align="right" sx={{height:60, paddingRight:5.2}}>
                                                                <Typography>
                                                                    {convertCurrency(record.receivedAmount)}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align="right" sx={{height:73, width:"232px", paddingRight:5.2}}>
                                                                <Typography>
                                                                    {record.receivedAmount !== null?<>{convertDate(record.receivedDate)}</>:<></>}
                                                                </Typography>
                                                            </TableCell>
                                                        </>
                                                        }
                                                        
                                                        <TableCell align="right" sx={{height:60, paddingRight:5.2}}>
                                                            <Typography>
                                                                {convertDate(record.dueDate)}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="right" sx={{height:60, paddingRight:5.2}}>
                                                            <Typography>
                                                                {convertDate(record.createdDatetime)}
                                                            </Typography>
                                                        </TableCell>
                                                        {user?.role !== "connector"?
                                                        <>
                                                        <TableCell align="right" sx={{height:60, width:110}}>
                                                            {record.id === editable?
                                                            <>
                                                            <Box display="flex" justifyContent="space-around">
                                                                {saveLoader?
                                                                <><CircularProgress /></>
                                                                :
                                                                <>
                                                                    <SaveIcon
                                                                        color='primary'
                                                                        fontSize='large'
                                                                        sx={{marginRight:1}}
                                                                        onClick = {onSave}
                                                                    />
                                                                    <CancelIcon
                                                                        color="error"
                                                                        fontSize='large'
                                                                        onClick={cancelEdit}
                                                                    />
                                                                </>}
                                                            </Box>
                                                            </>
                                                            :
                                                            <><Button sx={{color:"black"}} onClick={(e) => onEdit(record)} disabled={disableEdit}><ModeEdit/></Button></>}
                                                        </TableCell>
                                                        </>
                                                        :
                                                        <></>}
                                                    </TableRow>
                                                </>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {invoice && (
                            <>
                            <Grid item xs={12} sx={{display:{xs:"block", sm:"block", md:"none", lg:"none", xl:"none"}}}>
                                <TransactionsTableCard user={user} rowData={invoice.slice(page * rowsPerPage,page * rowsPerPage + rowsPerPage)} userType={"invoice"} editable={editable} onEdit={onEdit} onSave={onSave} cancelEdit={cancelEdit} formData={formData} amountValidation={amountValidation} amountHandler={amountHandler} receivedDate={receivedDate} setReceivedDate={setReceivedDate} today={today} saveLoader={saveLoader} truncateName={truncateName} showCurrentInvoice={showCurrentInvoice}/>
                            </Grid>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 15]}
                                sx={{'.MuiTablePagination-toolbar': {
                                        alignItems: 'baseline',
                                    },
                                }}
                                component="div"
                                count={invoice.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                            </>
                            )}
                        </>
                        }
                        </>
                        :
                        <>
                            <Grid item xs={12} sx={{textAlign:"center", marginTop:8}}>
                                <img src="assets/loader/Spinner-px.gif" height={"140vh"}/>
                                <Typography sx={{paddingTop:2}}>Please wait while we fetch your invoice</Typography>
                            </Grid>
                        </>}
                    </Grid>
                </>}
            </Grid>
        </DialogContent>
        <DialogActions sx={{ paddingRight: 5, paddingY: 2 }}>
            <Grid container>
                <Grid item xs={6} sx={{textAlign:"left"}}>
                    {showPdf?<><Button variant="outlined" sx={{marginLeft:3, width: 100, padding: 1}} onClick={(e) => {onBack()}}><WestIcon/>&nbsp;Back</Button></>:<></>}
                </Grid>
            </Grid>   
        </DialogActions>
    </Dialog>
    </>
  )
}

export default ShowInvoice