export const stages = [
    'introduction',
    'opportunity',
    'proposal',
    'won',
    'lost',
    'delayed',
];

export const stageNames = {
    introduction:'Customer Introduction',
    opportunity: 'Opportunity',
    proposal: 'Proposal',
    won: 'Won',
    lost: 'Lost',
    delayed: 'Delayed',
};

export const stageChoices = stages.map(type => ({
    id: type,
    /* @ts-ignore */
    name: stageNames[type],
}));
