import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Autocomplete from '@mui/material/Autocomplete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {
    Button,
    Checkbox,
    Chip,
    Grid,
    IconButton,
    InputAdornment,
    Menu,
    MenuItem,
    TableSortLabel,
    TextField,
    Toolbar,
    Tooltip,
    Typography,
} from '@mui/material';
import data from './Data.json';
import { Link } from 'react-router-dom';
import { Box } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import EnhancedTableToolbar from '../misc/EnhancedTableToolbar';
import AddIcon from '@mui/icons-material/Add';
import { FileUploader } from 'react-drag-drop-files';
import './styles.css';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import { sortedRowInformation, getComparator } from '../utils/SortingHandler';
import SearchTextField from '../Layout/SearchTextField';
import FilterComponent from '../Layout/FilterComponent';
import CancelIcon from '@mui/icons-material/Cancel';

const rows = data.services;
const type1 = rows.map(row => row.type);
let typeOpt = type1.filter((item, i, ar) => ar.indexOf(item) === i);
const created_by1 = rows.map(row => row.created_by);
let created_by = created_by1.filter((item, i, ar) => ar.indexOf(item) === i);

export default function ServicesTable() {
    const [rowData, setRowData] = useState(rows);
    const [orderDirection, setOrderdirection] = useState<'asc' | 'desc'>('asc');
    const [valueToOrderBy, setValueToOrderBy] = useState('default_price');
    const initialData = {
        id: 0,
        name: '',
        type: '',
        description: '',
        default_price: 0,
        created_by: '',
        created_datetime: '',
        modified_by: '',
        modified_datetime: '',
        document_uploaded: null
    };
    const [vendor, setVendor] = useState(null);
    const [type, setType] = useState(null);
    const [typeVisible, setTypeVisible] = useState(false);
    const [vendorVisible, setVendorVisible] = useState(false);
    const [serviceSearch, setServiceSearch] = useState('');
    const [deletable, setDeletable] = useState(false);
    const [selected, setSelected] = useState<readonly string[]>([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [showUpload, setShowUpload] = useState(false);
    const [file, setFile] = useState(null);
    const [formData, setFormData] = useState(initialData);
    const [editable, setEditable] = useState<Number>(0);

    const fileTypes = ['pdf'];

    const inputHandler = (e: any) => {
        const name = e.target.id;
        const value = e.target.value;
        setFormData({ ...formData, [name]: value });
    };

    const handleChange = (file: any) => {
        setFile(file);
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const toggleUpload = () => {
        setShowUpload(!showUpload);
        
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleDelete = () => {
        setDeletable(!deletable);
    };

    const checker = (id: Number) => {
        if (id === editable) {
            return true;
        }
        return false;
    };

    const cancelEdit = (row:any) =>{
        
        setFormData(row);
   }
    useEffect(() => {
        var tempArr = [...rows];
        if (type != null && type !== 'All') {
            tempArr = tempArr.filter(obj => {
                return obj.type === type;
            });
        }
        if (vendor != null && vendor !== 'All') {
            tempArr = tempArr.filter(obj => {
                return obj.created_by === vendor;
            });
        }

        tempArr = tempArr.filter(row => {
            return row.name.toLowerCase().includes(serviceSearch.toLowerCase());
        });

        
        setRowData(tempArr);
    }, [vendor, type, serviceSearch]);

    const open = Boolean(anchorEl);

    const handleVendorClose = () => {
        setVendor(null);
        setVendorVisible(false);
    };

    const handleTypeClose = () => {
        setType(null);
        setTypeVisible(false);
    };

    const handleCheckClick = (event: any, id: any) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: any = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const isSelected = (id: any) => selected.indexOf(id) !== -1;

    const createSortHandler = (property: any) => (event: any) => {
        const isAscending = orderDirection === 'asc';
        setValueToOrderBy(property);
        setOrderdirection(isAscending ? 'desc' : 'asc');
        sortedRowInformation(rowData, getComparator(orderDirection, property));
    };
    const handleEdit = (row: any) => {
        setFormData(row);
        
        setEditable(row.id);
    };

    const onSave = (row: any) => {
        var arr = rowData.filter(item => item.id !== row.id);
        arr.push(formData);
        setRowData(arr);
        setEditable(0);
    };

    const onSubmit = () => {
        
        setRowData([...rowData, formData]);
        handleCloseDialog();
        setFormData(initialData);
    };

    const onDelete = () => {
        
        var tempData = [...rows];
        for (let i = 0; i < selected.length; i++) {
            let index = tempData.findIndex(
                obj => obj.id === parseInt(selected[i])
            );
            tempData.splice(index, 1);
        }
        setSelected([]);
        setRowData(tempData);
    };

    const handleFilterOpt = (opt: any) => {
        setAnchorEl(null);
        if (opt === 'Type') setTypeVisible(true);
        else if (opt === 'Vendor') setVendorVisible(true);
    };

    return (
        <>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>Add Service</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Service Name"
                        value={formData.name}
                        onChange={e => inputHandler(e)}
                        type="text"
                        fullWidth
                        variant="standard"
                    />
                    <TextField
                        margin="dense"
                        id="link"
                        value={formData.type}
                        onChange={e => inputHandler(e)}
                        label="Service Type"
                        type="text"
                        fullWidth
                        variant="standard"
                    />
                    <TextField
                        margin="dense"
                        id="description"
                        label="Service Description"
                        value={formData.description}
                        onChange={e => inputHandler(e)}
                        type="text"
                        fullWidth
                        variant="standard"
                    />
                    <TextField
                        margin="dense"
                        id="amount"
                        label="Service Amount"
                        value={formData.default_price}
                        onChange={e => inputHandler(e)}
                        type="text"
                        fullWidth
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancel</Button>
                    <Button onClick={onSubmit}>Submit</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={showUpload} onClose={toggleUpload}>
                <div className="upload">
                    <h1>Upload your document here</h1>
                    <FileUploader
                        multiple={false}
                        handleChange={handleChange}
                        name="file"
                        types={fileTypes}
                    />
                    <p>
                        {file
                            ? `File Successfully added`
                            : 'no files uploaded yet'}
                    </p>
                </div>
                <Box sx={{ textAlign: 'center' }}>
                <Link to="/admin/viewpdf" state={file}>
                    <Button
                        variant="contained"
                        sx={{
                            width: '80px',
                            padding: '8px',
                            marginBottom: '10px',
                        }}
                        onClick={toggleUpload}
                    >
                        Upload
                    </Button>
                    </Link>
                </Box>
            </Dialog>

            <Grid container>
                <Grid item xs={10}>
                    <Grid container>
                        <Grid item xs={3}>
                            <SearchTextField
                                val={serviceSearch}
                                funcHandle={setServiceSearch}
                            />
                        </Grid>
                        <Grid item xs={2} display="flex">
                            {typeVisible && (
                                <>
                                    <IconButton onClick={handleTypeClose}>
                                        <CancelOutlinedIcon />
                                    </IconButton>
                                    <Autocomplete
                                        disablePortal
                                        id="type-dd"
                                        sx={{ width: '100%' }}
                                        value={type}
                                        options={typeOpt}
                                        onChange={(event, newValue: any) => {
                                            setType(newValue);
                                        }}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                label="Type"
                                            />
                                        )}
                                    />
                                </>
                            )}
                        </Grid>
                        <Grid item xs={2} display="flex">
                            {vendorVisible && (
                                <>
                                    <IconButton onClick={handleVendorClose}>
                                        <CancelOutlinedIcon />
                                    </IconButton>
                                    <Autocomplete
                                        disablePortal
                                        id="created_by-dd"
                                        value={vendor}
                                        sx={{ width: '100%' }}
                                        options={created_by}
                                        onChange={(event, newValue: any) => {
                                            setVendor(newValue);
                                        }}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                label="Vendor"
                                            />
                                        )}
                                    />
                                </>
                            )}
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            sx={{ textAlign: 'center', marginTop: 3 }}
                        >
                            <FilterComponent
                                anchorEl={anchorEl}
                                open={open}
                                setAnchorEl={setAnchorEl}
                                options={['Type', 'Vendor']}
                                handleFilterOpt={handleFilterOpt}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={1}
                            sx={{ textAlign: 'center', marginTop: 3 }}
                        >
                            <Button
                                startIcon={<DeleteIcon />}
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                size="small"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleDelete}
                            >
                                Delete
                            </Button>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            sx={{ textAlign: 'center', marginTop: 3 }}
                        >
                            <Button
                                variant="contained"
                                size="small"
                                startIcon={<AddIcon />}
                                onClick={handleOpenDialog}
                            >
                                Add Service
                            </Button>
                        </Grid>
                        <Grid item xs={12} sx={{ marginTop: 1 }}>
                            {selected.length > 0 && (
                                <EnhancedTableToolbar
                                    numSelected={selected.length}
                                    deletingCols={onDelete}
                                    selectedUpdate={setSelected}
                                    deletableUpdate={handleDelete}
                                />
                            )}
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            {deletable && (
                                                <TableCell></TableCell>
                                            )}
                                            <TableCell>Service Name</TableCell>
                                            <TableCell>Service Type</TableCell>
                                            <TableCell>
                                                Service Description
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                key="default_price"
                                                style={{flexDirection: "row"}}
                                            >
                                                <TableSortLabel
                                                    active={
                                                        valueToOrderBy ===
                                                        'default_price'
                                                    }
                                                    direction={
                                                        valueToOrderBy ===
                                                        'default_price'
                                                            ? orderDirection
                                                            : 'asc'
                                                    }
                                                    onClick={createSortHandler(
                                                        'default_price'
                                                    )}
                                                >
                                                    Service Amount
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell>
                                                Service Brochure
                                            </TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {sortedRowInformation(
                                            rowData,
                                            getComparator(
                                                orderDirection,
                                                valueToOrderBy
                                            )
                                        ).map((row: any, index: any) => {
                                            const isItemSelected = isSelected(
                                                row.id
                                            );
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            return (
                                                <TableRow
                                                    key={row.id}
                                                    sx={{
                                                        '&:last-child td, &:last-child th':
                                                            {
                                                                border: 0,
                                                            },
                                                    }}
                                                >
                                                    {deletable && (
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                checked={
                                                                    isItemSelected
                                                                }
                                                                inputProps={{
                                                                    'aria-labelledby':
                                                                        labelId,
                                                                }}
                                                                onClick={event =>
                                                                    handleCheckClick(
                                                                        event,
                                                                        row.id
                                                                    )
                                                                }
                                                            />
                                                        </TableCell>
                                                    )}
                                                    {!checker(row.id) ? (
                                                        <>
                                                            <TableCell align="left">
                                                                {row.name}
                                                            </TableCell>
                                                            <TableCell
                                                                component="th"
                                                                scope="row"
                                                                id={labelId}
                                                            >
                                                                {row.type}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {
                                                                    row.description
                                                                }
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {row.default_price.toLocaleString(
                                                                    'en-US',
                                                                    {
                                                                        notation:
                                                                            'compact',
                                                                        style: 'currency',
                                                                        currency:
                                                                            'USD',
                                                                        currencyDisplay:
                                                                            'narrowSymbol',
                                                                        minimumSignificantDigits: 3,
                                                                    }
                                                                )}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                            {row.document_uploaded ? (
                                                                    <>
                                                                        <a
                                                                            href={
                                                                                row.document_uploaded
                                                                            }
                                                                            target="_blank"
                                                                        >
                                                                            Document
                                                                            exists
                                                                        </a>
                                                                    </>
                                                                ) : (
                                                                    <a href="/#/admin/viewpdf">
                                                                        Upload
                                                                        document
                                                                    </a>
                                                                )}
                                                            </TableCell>
                                                            <TableCell>
                                                            {editable === 0 ? <ModeEditIcon
                                                                    onClick={event => {
                                                                        handleEdit(row);
                                                                    }}
                                                                /> : <ModeEditIcon
                                                                    color="disabled"
                                                                />}
                                                            </TableCell>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <TableCell align="left">
                                                                <TextField
                                                                    defaultValue={
                                                                        formData.name
                                                                    }
                                                                    id="name"
                                                                    onChange={e =>
                                                                        inputHandler(
                                                                            e
                                                                        )
                                                                    }
                                                                    value={formData.name}
                                                                />
                                                            </TableCell>
                                                            <TableCell
                                                                component="th"
                                                                scope="row"
                                                                id={labelId}
                                                            >
                                                                <TextField
                                                                    defaultValue={
                                                                        formData.type
                                                                    }
                                                                    value={formData.type}
                                                                    id="type"
                                                                    onChange={e =>
                                                                        inputHandler(
                                                                            e
                                                                        )
                                                                    }
                                                                />
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <TextField
                                                                    defaultValue={
                                                                        formData.description
                                                                    }
                                                                    id="description"
                                                                    onChange={e =>
                                                                        inputHandler(
                                                                            e
                                                                        )
                                                                    }
                                                                    value={formData.description}
                                                                />
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <TextField
                                                                value={formData.default_price}
                                                                    defaultValue={formData.default_price.toLocaleString(
                                                                        'en-US',
                                                                        {
                                                                            notation:
                                                                                'compact',
                                                                            style: 'currency',
                                                                            currency:
                                                                                'USD',
                                                                            currencyDisplay:
                                                                                'narrowSymbol',
                                                                            minimumSignificantDigits: 3,
                                                                        }
                                                                    )}
                                                                    id="default_price"
                                                                    onChange={e =>
                                                                        inputHandler(
                                                                            e
                                                                        )
                                                                    }
                                                                />
                                                            </TableCell>
                                                            <TableCell align="left">
                                                            {row.document_uploaded ? (
                                                                    <>
                                                                        <a
                                                                            href={
                                                                                row.document_uploaded
                                                                            }
                                                                            target="_blank"
                                                                        >
                                                                            Document
                                                                            exists
                                                                        </a>
                                                                    </>
                                                                ) : (
                                                                    <a href="/#/admin/viewpdf">
                                                                        Upload
                                                                        document
                                                                    </a>
                                                                )}
                                                            </TableCell>
                                                            <TableCell>
                                                                <SaveIcon
                                                                    onClick={() =>
                                                                        onSave(
                                                                            row
                                                                        )
                                                                    }
                                                                />
                                                                <CancelIcon onClick={event => cancelEdit(row)}/>
                                                            </TableCell>
                                                        </>
                                                    )}
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
