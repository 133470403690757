import react, { useState, useEffect } from 'react';
import { Box, Icon, Menu, MenuItem, Typography } from '@mui/material';
import { Droppable } from 'react-beautiful-dnd';
import { Identifier } from 'react-admin';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { DealCard } from './DealCard';
import { stageNames } from './stages';
import { RecordMap } from './DealListContent';

export const DealColumn = ({
    stage,
    dealIds,
    data,
}: {
    stage: string;
    dealIds: Identifier[];
    data: RecordMap;
}) => {
    const [cardDataId, setCardDataId] = useState(dealIds);
    const [sortBy, setSortBy] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
            setCardDataId(dealIds)
    },[dealIds])

    // useEffect(() => {
    //     setAnchorEl(null);
    //     const array = dealIds.map((id) => data[id]);
    //     if(sortBy=="alphabetic")
    //         array.sort((a: any, b: any) => {let fa = a.name.toLowerCase(),fb = b.name.toLowerCase();if (fa < fb) {return -1;} if (fa > fb) {return 1; }return 0;});
    //     else if(sortBy=="revenue")
    //     array.sort( (a:any,b:any) => a.amount - b.amount );
    //     dealIds = array.map((deal) => deal.id)
    // }, [sortBy])

    const open = Boolean(anchorEl);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const alphabeticSort = (event: any) => {
        setAnchorEl(null);
        const array = dealIds.map((id) => data[id]);
        
        array.sort((a: any, b: any) => {let fa = a.name.toLowerCase(),fb = b.name.toLowerCase();if (fa < fb) {return -1;} if (fa > fb) {return 1; }return 0;});
        
        const newIds = array.map((ele) => ele.id)
        setCardDataId(newIds)
    };

    const alphabeticSortDesc = (event: any) => {
        setAnchorEl(null);
        const array = dealIds.map((id) => data[id]);
        
        array.sort((a: any, b: any) => {let fa = a.name.toLowerCase(),fb = b.name.toLowerCase();if (fa > fb) {return -1;} if (fa < fb) {return 1; }return 0;});
        
        const newIds = array.map((ele) => ele.id)
        setCardDataId(newIds)
    };


    const revenueSort = (event: any) => {
        setAnchorEl(null);
        const array = dealIds.map((id) => data[id]);
        
        array.sort((a: any, b: any) => a.amount - b.amount);
        
        const newIds = array.map((ele) => ele.id)
        setCardDataId(newIds)
    };

    const revenueSortDesc = (event: any) => {
        setAnchorEl(null);
        const array = dealIds.map((id) => data[id]);
        
        array.sort((a: any, b: any) => b.amount - a.amount);
        
        const newIds = array.map((ele) => ele.id)
        setCardDataId(newIds)
    };

    // const SortCols = (event: any,type:any) => {
    //     setAnchorEl(null);
    //     const array = dealIds.map((id) => data[id]);
    //     if(type=="alphabetic")
    //         array.sort((a: any, b: any) => {let fa = a.name.toLowerCase(),fb = b.name.toLowerCase();if (fa < fb) {return -1;} if (fa > fb) {return 1; }return 0;});
    //     if(type=="alphabeticDesc")
    //         array.sort((a: any, b: any) => {let fa = a.name.toLowerCase(),fb = b.name.toLowerCase();if (fa > fb) {return -1;} if (fa < fb) {return 1; }return 0;});
    //     if(type=="revenue")
    //         array.sort((a: any, b: any) => a.amount - b.amount);
    //     if(type="revenueDesc")
    //     array.sort((a: any, b: any) => b.amount - a.amount);
    //     const newIds = array.map((ele) => ele.id)
    //     setCardDataId(newIds)
    // };

    const handleClose = (event: any) => {
        setAnchorEl(null);
    };


    return (
        <Box
            sx={{
                flex: 1,
                paddingTop: '8px',
                paddingBottom: '16px',
                bgcolor: '#eaeaee',
                '&:first-of-type': {
                    paddingLeft: '5px',
                    borderTopLeftRadius: 5,
                },
                '&:last-child': {
                    paddingRight: '5px',
                    borderTopRightRadius: 5,
                },
            }}
        >
            {/* {
                

            } */}
            <Typography align="center" variant="subtitle2">
                {/* @ts-ignore */}
                {stageNames[stage]}
                <Icon><KeyboardArrowDownIcon onClick={handleClick} fontSize="small"/></Icon>
                <Menu anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}>

                    <MenuItem key="alphabetic" onClick={alphabeticSort}>
                        Sort Alphabetically Ascending
                    </MenuItem>
                    <MenuItem key="alphabeticDesc" onClick={alphabeticSortDesc}>
                        Sort Alphabetically Descending
                    </MenuItem>
                    <MenuItem key="revenue" onClick={revenueSort}>
                        Sort by Revenue Ascending
                    </MenuItem>
                    <MenuItem key="revenueDesc" onClick={revenueSortDesc}>
                        Sort by Revenue Descending
                    </MenuItem>
                </Menu>
            </Typography>
            <Droppable droppableId={stage}>
                {(droppableProvided, snapshot) => (
                    <Box
                        ref={droppableProvided.innerRef}
                        {...droppableProvided.droppableProps}
                        className={
                            snapshot.isDraggingOver ? ' isDraggingOver' : ''
                        }
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            borderRadius: 5,
                            padding: '5px',
                            '&.isDraggingOver': {
                                bgcolor: '#dadadf',
                            },
                        }}
                    >
                        {/* {
                            
                        } */}
                        {cardDataId.map((id, index) => (
                            <DealCard key={id} index={index} deal={data[id]} />
                        ))}
                        {droppableProvided.placeholder}
                    </Box>
                )}
            </Droppable>
        </Box>
    );
};
