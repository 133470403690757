import { InputAdornment, TextField } from '@mui/material';
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
const SearchTextField = (props: any) => {
    const { val, funcHandle,type } = props;
    return (
        <TextField
            id="inputSearch"
            placeholder={type ? type : "Search"}
            value={val}
            hiddenLabel
            InputProps={{
                endAdornment: (
                    <InputAdornment position="start">
                         <SearchIcon color="disabled"/>
                    </InputAdornment>
                ),
            }}
            onChange={event =>
                {
                    funcHandle((event.target as HTMLInputElement).value)
                }
            }
            variant="filled"
        />
    );
};
export default SearchTextField;
