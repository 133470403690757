import { Alert, AlertTitle, Button, Dialog, DialogActions, DialogContent, Grid, Table, TableContainer, TableHead, TableRow, Typography, Paper, TableBody, TableCell, DialogTitle, Tooltip, Box, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import TableHeadRow from "../Layout/TableHeadRow";
import ErrorComponent from "../Layout/ErrorComponent";
import ProductList from "./ContactList";
import ContactList from "../Admin/VendorsContacts/ProductList";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';

const ShowList = (props: any) => {
    const { showDialog, handleShowDialogClose, data, getLoader, type, error } = props;
    return (<Dialog
        open={showDialog}
        onClose={handleShowDialogClose}
        fullWidth
        maxWidth="lg"
        sx={{
            '.MuiDialog-paper': {
                position: 'absolute',
                width: {xs:"90%", sm:"85%", md:"65%", lg:"65%", xl:"65%"},
                height: '700px',
            },
        }}
    >
        <DialogTitle sx={{ textAlign: "center", fontWeight: 'bold', backgroundColor: "#F0F7FF", fontSize:{xs:"large", sm:"large", md:"34px", lg:"34px", xl:"34px"} }}>
            {type=="contacts" ? "Contact List" : "Product List"}
            <Tooltip
                title={`Find ${type==="contacts" ? "Contact List" : "Product List"} associated with the current product here`}
                arrow
                placement="right-end"
            >
                <Button>
                    <InfoOutlinedIcon />
                </Button>
            </Tooltip>
            <IconButton onClick={handleShowDialogClose} sx={{ float: "right", color: "red" }}>
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        {error? 
        <Grid item xs={12} sx={{ textAlign: "center", marginTop: 10 }}>
            <ErrorComponent />
        </Grid>
        :
        <DialogContent>
            <Grid container>
                {getLoader ?
                    <Grid item xs={12} sx={{ textAlign: "center", marginTop: 10 }}>
                        <img src="assets/loader/Spinner-px.gif" height={"140vh"} />
                        <Typography sx={{ paddingTop: 2 }}>Please wait while we fetch all your contacts</Typography>
                    </Grid> :
                    <Grid container>
                        {(data && data.length == 0) ?
                            <Grid item xs={12} sx={{ textAlign: 'center', marginTop: 2 }}>
                            <img src="assets/loader/not_found.gif" height={'140vh'} />
                            <Typography sx={{ fontWeight: 'bold' }}>
                                No contacts found!
                            </Typography>
                            <Typography>
                                Seems like no contacts are linked with this product.<br />
                            </Typography>
                        </Grid> :
                            <Grid item xs={12}>
                                {type==="contacts" ? <ProductList data={data}/>: <ContactList data={data}/>}
                            </Grid>}
                    </Grid>}

            </Grid>
        </DialogContent>
        }
    </Dialog>)
}
export default ShowList;