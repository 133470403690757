import { Box, Button, Dialog, DialogContent, Grid, Typography } from "@mui/material";

const MfaConfirmationDialog = (props: any) => {
    const { showConfirmationDialog, onClickYes, onClickNo, phoneNumber, onClickDifferentNum } = props;
    return (
    <Dialog open={showConfirmationDialog} fullWidth maxWidth="md">
        <DialogContent>
            <Grid container spacing={1} justifyContent={"center"} alignItems={"center"} sx={{paddingY:5}}>
                <Grid item xs={12} sx={{textAlign:"center"}}>
                    <Typography sx={{ fontSize: 20 }}>Do you wish to set SMS MFA with this phone number : {phoneNumber} </Typography>
                </Grid>
                <Grid item xs={12} sx={{textAlign:"center"}}>
                    <Box>
                    <Button
                        onClick={onClickYes}
                        variant="contained"
                        color="primary"
                        sx={{ width: 200, height: 45, padding: 1, marginX:2 }}
                    >
                        Set up SMS MFA
                    </Button>
                    <Button
                        onClick={onClickDifferentNum}
                        variant="contained"
                        color="primary"
                        sx={{ width: 200, height: 45, padding: 1, marginX:2 }}
                    >
                        Use Different Number
                    </Button>
                    <Button
                        onClick={onClickNo}
                        variant="contained"
                        color="primary"
                        sx={{ width: 200, height: 45, padding: 1, marginX:2 }}
                    >
                        Use Authenticator
                    </Button>
                    </Box>
                </Grid>
            </Grid>
        </DialogContent>
    </Dialog>)
}
export default MfaConfirmationDialog;