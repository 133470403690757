import { Card, Grid, Typography, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { PDFObject } from 'react-pdfobject';
const DocumentPreview = (props:any) => {
    const{ file, onDocumentLoadSuccess, numPages, setFile}=props;
    const[fileLink, setFileLink] = useState<any>();
    const reset = () => {
        setFile(null)
    }
    const generateLink = (newPdf:any) => {
        return URL.createObjectURL(file)
    }
    useEffect(() => {
        if(file){
            var link =generateLink(file);
            setFileLink(link);
        }
    },[file])

    return (
        <>
            <Grid container>
                <Grid item xs={2} sx={{textAlign:"left"}}>
                    <Typography
                        sx={{
                            fontWeight: 'bold',
                            fontSize: '20px',
                        }}
                    >
                        Preview
                    </Typography>
                </Grid>
                {file?
                <>
                <Grid item xs={10} sx={{textAlign:"right"}}>
                    <Button sx={{color:"red"}} onClick={(e) => reset()}>reset</Button>
                </Grid>
                </>
                :
                <></>}
            </Grid>
            {file ? (
                <>
                    <Card
                        sx={{
                            border: 1,
                            maxWidth: '100%',
                            height: '800px',
                            marginTop: 0
                        }}
                    >
                        <PDFObject width="100%" height="800px" url={fileLink}/>

                    </Card>
                </>
            ) : (
                <>
                    <Card
                        sx={{
                            padding: 1,
                            boxShadow: 0,
                            backgroundColor: '#F0F0F0',
                            marginTop: 3,
                            textAlign: 'center',
                        }}
                    >
                        <Typography variant="subtitle2">
                            No document has been uploaded yet
                        </Typography>
                    </Card>
                </>
            )}
        </>
    );
};

export default DocumentPreview;
