import { Grid } from '@mui/material'
import React from 'react'
import PageNameComp from '../PageNameComp'

const ActivitiesHeader = (props: any) => {
    const { currentText, heading, invisible } = props;
    return (
        <Grid container spacing={2} sx={{ marginBottom: 1, display: invisible ? 'none' : 'block' }}>
            <Grid item xs={12}>
                <PageNameComp currentText={currentText} heading={heading} />
            </Grid>
        </Grid>
    )
}

export default ActivitiesHeader