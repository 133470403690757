
export function descendingComparator(a: any, b: any, orderBy: any) {
    if(orderBy)
    {
        let ind = orderBy.indexOf('.');
        
        if(ind==-1)
        {
            if (b[orderBy] > a[orderBy]) return -1;
            if (a[orderBy] > b[orderBy]) return 1;
    
        }
        else
        {
            let str1 = orderBy.substring(0,ind);
            let str2 = orderBy.substring(ind+1);
            if (b[str1][str2] > a[str1][str2]) return -1;
            if (a[str1][str2] > b[str1][str2]) return 1;
        }
    }
    return 0;
}
export function getComparator(order: any, orderBy: any) {
    return order === 'desc'
        ? (a: any, b: any) => descendingComparator(a, b, orderBy)
        : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

export const sortedRowInformation = (rowArray: any, comparator: any) => {
    const stabilizedRowArray = rowArray.map((el: any, ind: any) => [
        el,
        ind,
    ]);
    stabilizedRowArray.sort((a: any, b: any) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedRowArray.map((el: any) => el[0]);
};