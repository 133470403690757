import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Card, Link, Box, Typography, Grid, CircularProgress, Avatar } from '@mui/material';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { firstCapital } from '../Admin/Approvals/util';
import ProfileScreen from '../Profile Screens/ProfileScreen';
import { stringAvatar } from './avatarUtil';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
export const DealsPipeline = (props:any) => {
    const{user, userInfo, dataType} = props;
    const [dealsData, setDealsData] = useState<any>()
    const [chartLoader, setChartLoader] = useState(true);
    const [error, setError] = useState(false);
    const [empty, setEmpty] = useState(false);
    const [profileDialog, setProfileDialog] = useState(false);
    const [currentSelection, setCurrentSelection] = useState<any>();
    const [profileType, setProfileType] = useState()
    const showProfile = (deal:any, type:any) => {
        setProfileType(type);
        setCurrentSelection(deal);
        setProfileDialog(true);
    }

    const findDays = (user:any) => {
        const dt1 =  new Date(user.createdDatetime);
        const dt2 = new Date();
        return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) ) /(1000 * 60 * 60 * 24));
    }
    
    useEffect(() => {
        setChartLoader(true);
        if(dataType === "deals"){
            if(user){
                if(user.role === "admin"){
                    axios.get(process.env.REACT_APP_BASE_URL+"deals",
                    {headers: {
                        "Authorization": "Bearer "+user.accessToken,
                      }
                      ,params:
                        { 
                            pageNo:0,
                            pageSize:3,
                            sortBy:"id",
                            sortDir:"desc"
                        }
                    })
                    .then((response:any) => {
                        setDealsData(response.data)
                        setChartLoader(false);
                        if(response.data.length === 0){
                            setEmpty(true);
                        }
                    })
                    .catch(error => {
                        setChartLoader(false);
                    } )
                }
                else{
                    var userType = user.role;
                    var userId = userInfo.id;
                    if(user?.role === "vendor" || user?.role === "contact"){
                        userId = userInfo.vendorId.id;
                    }
                    if(user.role === "contact"){
                        userType = "vendor"
                    }
                    axios.get(process.env.REACT_APP_BASE_URL+"deals/"+userType+"/page/"+userId, 
                        {headers: {
                            "Authorization": "Bearer "+user.accessToken,
                          }
                          ,params:
                            { 
                                pageNo:0,
                                pageSize:3,
                                sortBy:"id",
                                sortDir:"desc"
                            }
                        })
                        .catch((error:any) => {
                            setChartLoader(false);
                        })
                        .then((response:any)=>{
                            setDealsData(response.data.reverse());
                            setChartLoader(false)
                            if(response.data.length === 0){
                                setEmpty(true);
                            }
                        })
                    
                }
            }
        }
        else if(dataType === "registrations"){
            if(user){
                var type = user.role;
                var id = userInfo.id;
                if(user?.role === "vendor" || user?.role === "contact"){
                    id = userInfo.vendorId.id;
                }
                if(user.role === "contact"){
                    type = "vendor"
                }
                axios.get(process.env.REACT_APP_BASE_URL+"registration/"+type+"/page/"+id,
                {headers: {
                    "Authorization": "Bearer "+user.accessToken,
                  }
                  ,params:
                    { 
                        pageNo:0,
                        pageSize:3,
                        sortBy:"id",
                        sortDir:"desc"
                    }
                })
                .catch((error:any) => {
                    setChartLoader(false);
                })
                .then((response:any)=>{
                    setDealsData(response.data.reverse());
                    setChartLoader(false)
                    if(response.data.length === 0){
                        setEmpty(true);
                    }
                })
            }
        }
    },[user])
    const profileLink = 
    {
        fontWeight:"bold",
        fontSize: "16px",
        "&:hover": {
            color: "#0000ff"
        },
        textTransform:"none",
        whiteSpace: 'nowrap',
        textDecoration: 'none',
        cursor: "pointer",
        color: "#00BFFF",
        display:"inline"
    }
    

    return (
        <>
        <ProfileScreen showDialog={profileDialog} currentSelection={currentSelection} type={profileType} setShowDialog={setProfileDialog} />
        <Grid container >
            <Grid item xs={12}>
                <Box display="flex" alignItems="center" marginBottom="1em">
                    <Box ml={2} mr={2} display="flex">
                        <MonetizationOnIcon color="disabled" fontSize="large" />
                    </Box>
                    {dataType === "deals" &&
                    <Link
                        underline="none"
                        variant="h5"
                        color="textSecondary"
                        component={RouterLink}
                        to="/admin/deals"
                    >
                        Deals Pipeline
                    </Link>}
                    {dataType === "registrations" &&
                    <Link
                        underline="none"
                        variant="h5"
                        color="textSecondary"
                        component={RouterLink}
                        to="/admin/activityCentre"
                    >
                        {user.role === "connector"? "Associated Vendors":"Associated Connectors"}
                    </Link>}
                </Box>
                <Card sx={{padding:3}}>
                    {chartLoader?
                    <>
                    <Grid item xs={12} sx={{ marginTop: 5, textAlign: "center", height:"26.7vh" }}><CircularProgress/><Typography sx={{ paddingTop: 2 }}>Please wait while we set up your deals pipeline</Typography></Grid>
                    </>:
                    <>
                    <Box sx={{ height:"28vh"}}>
                    {empty ? 
                    <Grid item xs={12} sx={{minHeight:"100%", textAlign:"center", display:"flex", alignItems:"center", justifyContent:"center"}}>
                        <Link
                            underline="none"
                            color="textSecondary"
                            component={RouterLink}
                            to={""}
                        >
                            Couldn't find any {dataType === "deals"?"deals":user.role === "connector"? "vendors":"connectors"} associated with your account
                        </Link>
                    </Grid>
                    :
                    <>
                    {dealsData && 
                    <>
                    <Grid container sx={{marginBottom:3}}>
                        {dealsData.map((deal:any) => {
                            return(
                            <>
                                <Grid item xs={12} sx={{padding:1}}>
                                    <Grid container>
                                        {dataType === "deals" && <>
                                        <Grid item xs={2}>
                                            {deal.productId.logo?
                                            <Avatar alt={deal.productId.name} src={`data:${deal.productId.contentType};base64,${deal.productId.logo}`} />
                                            :
                                            <Avatar {...stringAvatar(deal.name)} />}
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Typography onClick={() => showProfile(deal,"deal")} sx={profileLink}>{firstCapital(deal.name)}</Typography>
                                            <Typography>{findDays(deal) === 0? <>Today</>:<>{findDays(deal)} days ago</>}</Typography>
                                        </Grid>
                                        </>}
                                        {dataType === "registrations" && 
                                        <>
                                            {(user.role === "connector") && 
                                            <>
                                            <Grid item xs={2}>
                                                {deal.vendorId.logo?
                                                <Avatar alt={deal.vendorId.name} src={`data:${deal.vendorId.contentType};base64,${deal.vendorId.logo}`} />
                                                :
                                                <Avatar {...stringAvatar(deal.vendorId.name)} />}
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Typography onClick={() => showProfile(deal.vendorId,"vendor")} sx={profileLink}>{firstCapital(deal.vendorId.name)}</Typography>
                                                <Typography>{findDays(deal) === 0? <>Today</>:<>{findDays(deal)} days ago</>}</Typography>
                                            </Grid>
                                            </>
                                            }
                                            {(user.role === "vendor") && 
                                            <>
                                            <Grid item xs={2}>
                                                {deal.connectorId.logo?
                                                <Avatar alt={deal.connectorId.name} src={`data:${deal.connectorId.contentType};base64,${deal.connectorId.logo}`} />
                                                :
                                                <Avatar {...stringAvatar(deal.connectorId.name)} />}
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Typography onClick={() => showProfile(deal.connectorId,"connector")} sx={profileLink}>{firstCapital(deal.connectorId.name)}</Typography>
                                                <Typography>{findDays(deal) === 0? <>Today</>:<>{findDays(deal)} days ago</>}</Typography>
                                            </Grid>
                                            </>
                                            }
                                        </>
                                        }
                                    </Grid>
                                </Grid>
                            </>)
                        })}
                    </Grid>
                    </>
                    }
                    </>}
                    </Box>
                    <Typography align='center' gutterBottom>
                        {empty?
                        <Link
                            underline="none"
                            color="textSecondary"
                            component={RouterLink}
                            to={user.role === "connector"?"/admin/deals/registration":"/admin/activityCentre"}
                        >
                            Get Started
                        </Link>
                        :
                        <Link
                            underline="none"
                            color="textSecondary"
                            component={RouterLink}
                            to={dataType === "deals"?"/admin/deals":user.role==="connector"?"/admin/deals/registration":"/admin/activityCentre"}
                        >
                            See all {dataType === "deals"?"deals":user.role === "connector"? "Associated Vendors":"Associated Connectors"}
                        </Link>}
                    </Typography>
                    </>}
                </Card>
            </Grid>
        </Grid>
        </>
    );
};
