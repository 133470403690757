import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Autocomplete from '@mui/material/Autocomplete';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Box, Button, Checkbox, Grid, IconButton, TextField, Typography, TablePagination } from '@mui/material';
import EnhancedTableToolbar from '../misc/EnhancedTableToolbar';
import { sortedRowInformation, getComparator } from '../utils/SortingHandler';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchTextField from '../Layout/SearchTextField';
import FilterComponent from '../Layout/FilterComponent';
import AddIcon from '@mui/icons-material/Add';
import { convertDate, convertCurrency, firstCapital, } from '../Admin/Approvals/util';
import PageNameComp from '../PageNameComp';
import { useAuth0 } from '@auth0/auth0-react';
import { tableHead, linkHover } from '../tableStyle';
import TransactionForm from './TransactionForm';
import axios from 'axios';
import ErrorComponent from '../Layout/ErrorComponent';
import ProfileScreen from '../Profile Screens/ProfileScreen';
import ShowInvoice from './ShowInvoice';
import UploadDoument from '../legalDocs/UploadDoument';
import InvoiceUploadError from './InvoiceUploadError';
import TableHeadRow from '../Layout/TableHeadRow';
import TransactionsTableCard from './TransactionsTableCard';
import PaginationLoader from '../Layout/PaginationLoader';

export default function Transaction(props: any) {
    const { user } = props;
    const [rowData, setRowData] = useState<any>([]);
    const [globalData, setGlobalData] = useState<any>([]);
    const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>('asc');
    const [valueToOrderBy, setValueToOrderBy] = useState('invoice_date');
    const [statusValue, setStatusValue] = useState<any>(null);
    const [statusVisible, setStatusVisible] = useState(false);
    const [dealSearch, setDealSearch] = useState('');
    const [customerValue, setCustomerValue] = useState('');
    const [customerVisible, setCustomerVisible] = useState(false);
    const [productValue, setProductValue] = useState('');
    const [productVisible, setProductVisible] = useState(false);
    const [selected, setSelected] = useState<readonly string[]>([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [deletable, setDeletable] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [selectedOption, setSelectedOption] = useState();
    const [cardDeal, setCardDeal] = useState(false);
    const [currentDeal, setCurrentDeal] = useState<any>();
    const [file, setFile] = useState(null);
    const [showUpload, setShowUpload] = useState(true);
    const [show, setShow] = useState(false);
    const [dealList, setDealList] = useState<any>();
    const [userInfo, setUserInfo] = useState<any>();
    const [transactionLoader, setTransactionLoader] = useState(false);
    const [empty, setEmpty] = useState(false);
    const [error, setError] = useState(false);
    const [onChange, setOnChange] = useState(0);
    const [profileDialog, setProfileDialog] = useState(false);
    const [type, setType] = useState("");
    const [currentSelection, setCurrentSelection] = useState<any>()
    const [showInvoiceDialog, setShowInvoiceDialog] = useState(false);
    const [currentTransaction, setCurrentTransaction] = useState<any>();
    const [showUploadDialog, setShowUploadDialog] = useState(false);
    const [saveLoader, setSaveLoader] = useState(false);
    const [showUploadError, setShowUploadError] = useState(false);
    const [formType, setFormType] = useState("transaction");
    const [errorType, setErrorType] = useState("");
    const [totalRecords, setTotalRecords] = useState(0);
    const [firstLoader, setFirstLoader] = useState(true);
    const [paginationLoader, setPaginationLoader] = useState(false);
    const initialError = {
        error: false,
        message: ""
    }
    const [dealValidation, setDealValidation] = useState(initialError)
    const [invoiceAmountValidation, setInvoiceAmountValidation] = useState(initialError)
    const [receivedAmountValidation, setReceivedAmountValidation] = useState(initialError)
    let role = user?.role;
    const initialData = {
        vendorId: { id: 0 },
        connectorId: { id: 0 },
        customerId: { id: 0 },
        productId: { id: 0 },
        dealId: { id: 0 },
        invoiceAmount: 0,
        receivedAmount: null,
        receivedDate: null,
        dueDate: "",
        endDate: "",

        status: "pending"
    }
    const [formData, setFormData] = useState(initialData)
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const handleChangePage = (event: any, newPage: any) => {
        if (!paginationLoader) {
            setPage(newPage);
        }
    }; const handleChangeRowsPerPage = (event: any) => {
        if (!paginationLoader) {
            setRowsPerPage(+event.target.value);
            setPage(0);
        }
    };
    useEffect(() => {
        if (globalData) {
            var tempArr = [...globalData];
            if (statusValue != null) {
                tempArr = tempArr.filter(obj => {
                    let str = statusValue[0].toLowerCase() + statusValue.substring(1).replaceAll(" ", "");
                    return obj.status === str;
                });
            }
            if (customerValue.trim().length > 0) {
                tempArr = tempArr.filter(row => {
                    return row.productId.name
                        .toLowerCase()
                        .includes(customerValue.trim().toLowerCase());
                });
            }
            if (productValue.trim().length > 0) {
                tempArr = tempArr.filter(row => {
                    return row.productId.name
                        .toLowerCase()
                        .includes(productValue.trim().toLowerCase());
                });
            }
            if (dealSearch.trim().length > 0) {
                tempArr = tempArr.filter(row => {
                    return row.dealId.name
                        .toLowerCase()
                        .includes(dealSearch.trim().toLowerCase());
                });
            }


            setRowData(tempArr);
        }
    }, [statusValue, customerValue, productValue, dealSearch]);
    const open = Boolean(anchorEl);

    const handleFilterClose = (type: any) => {
        if (type == "Status") {
            setStatusValue(null);
            setStatusVisible(false);
        }
        if (type == "Customer") {
            setCustomerValue('');
            setCustomerVisible(false);
        }
        if (type == "Product") {
            setProductValue('');
            setProductVisible(false);
        }
    };

    const handleCheckClick = (event: any, id: any) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: any = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };
    const isSelected = (id: any) => selected.indexOf(id) !== -1;
    const handleDelete = () => {
        setDeletable(!deletable);
    };
    const handleFilterOpt = (opt: any) => {
        setAnchorEl(null);
        if (opt === 'Status') setStatusVisible(true);
        else if (opt === 'Product') setProductVisible(true);
        else if (opt === 'Customer') setCustomerVisible(true);
    };

    const handleAdd = (type: any) => {
        setFormType(type)
        setShowDialog(true)
    }
    const getOptions = (option: any) => {
        if (option) {
            return option.name;
        }
        return selectedOption || '';
    };

    useEffect(() => {
        if (user) {
            if (firstLoader) {
                setTransactionLoader(true);
            }
            if (user.role !== "admin") {
                var userType = user?.role;
                if (user?.role === "vendor") {
                    userType = "contact"
                }
                axios.get(process.env.REACT_APP_BASE_URL + userType + "/mail/" + user?.email,
                    {
                        headers: {
                            "Authorization": "Bearer " + user.accessToken,
                        }
                    })
                    .catch(error => {
                        setError(true);
                    })
                    .then((response: any) => {
                        var userId = 0;
                        if (user?.role === "vendor" || user?.role === "contact") {
                            setUserInfo(response.data.vendorId)
                            userId = response.data.vendorId.id
                        }
                        else {
                            setUserInfo(response.data)
                            userId = response.data.id;
                        }
                        if (userType === "contact") {
                            userType = "vendor"
                        }
                        // getUserRecords(userType, userId)
                    })
            }
        }
    }, [user])

    useEffect(() => {
        if (user) {
            if (firstLoader) {
                setTransactionLoader(true);
            }
            setPaginationLoader(true);
            if (user.role === "admin") {
                axios.get(process.env.REACT_APP_BASE_URL + "transactions/pages",
                    {
                        headers: {
                            "Authorization": "Bearer " + user.accessToken,
                        },
                        params:
                        {
                            pageNo: page,
                            pageSize: rowsPerPage,
                            sortBy: "id",
                            sortDir: "desc"
                        }
                    })
                    .catch((error: any) => {
                        setTransactionLoader(false);
                        setError(true)
                        setFirstLoader(false);
                    })
                    .then((response: any) => {
                        if (response.data.data.length === 0) {
                            setEmpty(true)
                        }
                        setTotalRecords(response.data.totalElements);
                        setRowData(response.data.data)
                        setGlobalData(response.data.data)
                        setTransactionLoader(false);
                        setPaginationLoader(false);
                        setFirstLoader(false);
                    })
            }
            else {
                if (userInfo) {
                    var userType = user?.role;
                    if (user?.role === "contact") {
                        userType = "vendor"
                    }
                    axios.get(process.env.REACT_APP_BASE_URL + "transactions/" + userType + "/pages/" + userInfo.id,
                        {
                            headers: {
                                "Authorization": "Bearer " + user.accessToken,
                            },
                            params: {
                                pageNo: page,
                                pageSize: rowsPerPage,
                                sortBy: "id",
                                sortDir: "desc"
                            }
                        })
                        .catch((error: any) => {
                            setPaginationLoader(false);
                            setTransactionLoader(false);
                            setError(true)
                            setFirstLoader(false);
                        })
                        .then((response: any) => {
                            if (response.data.data.length === 0) {
                                setEmpty(true)
                            }
                            setRowData(response.data.data)
                            setGlobalData(response.data.data)
                            setTotalRecords(response.data.totalElements)
                            setTransactionLoader(false);
                            setFirstLoader(false);
                            setPaginationLoader(false);
                        })
                    if (user?.role === "vendor" || user?.role === "contact") {
                        axios.get(process.env.REACT_APP_BASE_URL + "deals/" + userType + "/transaction/" + userInfo.id,
                            {
                                headers: {
                                    "Authorization": "Bearer " + user.accessToken,
                                }
                            })
                            .catch((error: any) => {

                                setError(true)
                            })
                            .then((response: any) => {
                                setDealList(response.data)
                            })
                    }
                }
            }
        }
    }, [user, userInfo, page, rowsPerPage, onChange])

    const inputHandler = (e: any) => {
        var val = Number(e.target.value)
        setFormData({ ...formData, [e.target.name]: val });
        if (e.target.name === "invoiceAmount") {
            checkInvoiceAmount(e.target.value);
        }
        else if (e.target.name === "receivedAmount") {
            checkReceivedAmount(e.target.value);
        }
    };
    const handleChange = (event: any, newVal: any) => {
        let ind = event.target.id.indexOf('-');
        let key = event.target.id.substring(0, ind);
        if (key === "dealId") {
            setDealValidation({ ...dealValidation, error: false, message: "" })
            setCardDeal(true);
            setCurrentDeal(newVal)

            setFormData({
                ...formData,
                vendorId: { id: newVal.vendorId.id },
                connectorId: { id: newVal.connectorId.id },
                customerId: { id: newVal.customerId.id },
                productId: { id: newVal.productId.id },
                dealId: { id: newVal.id }
            })
        }
    };

    const handleClose = () => {
        setShowDialog(false);
        setCardDeal(false);
        setFormData(initialData);
        setFile(null);
        setDealValidation(initialError);
        setInvoiceAmountValidation(initialError);
        setReceivedAmountValidation(initialError);
    }

    const handleRegister = () => {
        checkDeal();
        checkInvoiceAmount(formData.invoiceAmount);
        if (file) {
            if (!dealValidation.error && !invoiceAmountValidation.error && formData.dealId.id !== 0 && formData.invoiceAmount !== 0 && !saveLoader) {
                setSaveLoader(true);
                axios.post(process.env.REACT_APP_BASE_URL + "transactions/register", formData,
                    {
                        headers: {
                            "Authorization": "Bearer " + user.accessToken,
                        }
                    })
                    .catch((error: any) => {

                    })
                    .then((response: any) => {

                        axios.post(process.env.REACT_APP_BASE_URL + "receipt/register", { file },
                            {
                                headers: {
                                    "Authorization": "Bearer " + user.accessToken,
                                    "Content-type": "multipart/form-data"
                                },
                                params: {
                                    "transactionId": response.data.id,
                                    "dueDate": formData.dueDate,
                                    "invoiceAmount": formData.invoiceAmount,
                                    "receivedAmount": null
                                }
                            })
                            .catch((error: any) => {

                                setErrorType("invoice")
                                setShowUploadError(true);
                                setSaveLoader(false);
                            })
                            .then((response: any) => {
                                setSaveLoader(false);
                                setShowDialog(false);
                                setFormData(initialData);
                                setEmpty(false);
                                handleClose();
                                if (page === 0) {
                                    setOnChange(val => val + 1);
                                }
                                else {
                                    setPage(0);
                                }
                            })
                    })
            }
        }
        else {
            setErrorType("transaction")
            setShowUploadError(true);
        }

    }

    const showProfile = (row: any, profile: string) => {
        setType(profile)
        setCurrentSelection(row)
        setProfileDialog(true)
    }

    const invoiceShow = (transactionData: any) => {
        setCurrentTransaction(transactionData);
        setShowInvoiceDialog(true);
    }
    const handleFileChange = (file: any) => {
        setFile(file);

    };
    const toggleUpload = () => {
        setShowUpload(!showUpload);

        setShow(true);
    };
    const [numPages, setNumPages] = useState(null);
    const onDocumentLoadSuccess = (numPages: any) => {

        setNumPages(numPages);
    };

    const handleUpload = (row: any) => {
        setCurrentTransaction(row)
        setFormType("invoice")
        setShowDialog(true)
    }

    const handleUploadClose = () => {
        setShowUploadDialog(false)
        setCurrentTransaction(null)
        setFile(null)
    }

    const fileTypes = ['pdf'];

    const handleinvoiceUpload = () => {
        checkInvoiceAmount(formData.invoiceAmount);
        checkReceivedAmount(formData.receivedAmount);
        var amt = null;
        if (formData.receivedAmount !== 0) {
            amt = formData.receivedAmount;
        }
        if (file) {
            if (!invoiceAmountValidation.error && !receivedAmountValidation.error && formData.invoiceAmount !== 0 && !saveLoader) {
                setSaveLoader(true)
                axios.post(process.env.REACT_APP_BASE_URL + "receipt/register", { file },
                    {
                        headers: {
                            "Authorization": "Bearer " + user.accessToken,
                            "Content-type": "multipart/form-data"
                        },
                        params: {
                            "transactionId": currentTransaction.id,
                            "dueDate": formData.dueDate,
                            "invoiceAmount": formData.invoiceAmount,
                            "receivedAmount": amt,
                            "receivedDate": formData.receivedDate
                        }
                    })
                    .catch((error: any) => {

                        setShowUploadError(true);
                        setErrorType("invoice")
                        setSaveLoader(false);
                    })
                    .then((response: any) => {
                        onUpdateTotalAmounts(currentTransaction.id)
                        setSaveLoader(false);
                        handleClose();
                    })
            }
        }
        else {
            setErrorType("others")
            setShowUploadError(true);
        }

    }

    const handleErrorClose = () => {
        setShowUploadError(false)
    }

    const checkInvoiceAmount = (amount: any) => {
        if (amount < 1) {
            setInvoiceAmountValidation({ ...invoiceAmountValidation, error: true, message: "The invoice amount must be greater than 0" })
        }
        else if (amount > 10000000) {
            setInvoiceAmountValidation({ ...invoiceAmountValidation, error: true, message: "The invoice amount cannot be greater than 10 Millions" })
        }
        else {
            setInvoiceAmountValidation({ ...invoiceAmountValidation, error: false, message: "" })
        }
    }
    const checkReceivedAmount = (amount: any) => {
        if (Number(amount) < 0) {
            setReceivedAmountValidation({ ...receivedAmountValidation, error: true, message: "The received amount must be greater than 0" })
        }
        else if (Number(amount) > 10000000) {
            setReceivedAmountValidation({ ...receivedAmountValidation, error: true, message: "The received amount cannot be greater than 10 Millions" })
        }
        else {
            setReceivedAmountValidation({ ...receivedAmountValidation, error: false, message: "" })
        }
    }
    const checkDeal = () => {
        if (formData.dealId.id === 0) {
            setDealValidation({ ...dealValidation, error: true, message: "You need to select a deal to associate with the new transaction" })
        }
        else {
            setDealValidation({ ...dealValidation, error: false, message: "" })
        }
    }

    const createSortHandler = (property: any) => (event: any) => {
        let isAscending;
        setValueToOrderBy(property);
        isAscending = orderDirection === 'asc';
        setOrderDirection(isAscending ? 'desc' : 'asc');
        sortedRowInformation(rowData, getComparator(orderDirection, property));
    };

    const onUpdateTotalAmounts = (transactionId: any) => {
        axios.get(process.env.REACT_APP_BASE_URL + "transactions/" + transactionId,
            {
                headers: {
                    "Authorization": "Bearer " + user.accessToken,
                }
            })
            .catch((err: any) => {
            })
            .then((response: any) => {
                if (response.data) {
                    var tempData = [...rowData];
                    const ind = tempData.findIndex(obj => obj.id === response.data.id);
                    tempData[ind] = response.data;
                    setRowData(tempData);
                }
            })
    }

    return (
        <>
            <UploadDoument
                showDialog={showUploadDialog}
                file={file}
                handleFileChange={handleFileChange}
                toggleUpload={toggleUpload}
                fileTypes={fileTypes}
                onDocumentLoadSuccess={onDocumentLoadSuccess}
                numPages={numPages}
                show={show}
                handleSubmit={handleinvoiceUpload}
                handleClose={handleUploadClose}
                setFile={setFile}
                saveLoader={saveLoader}
                type={"Transaction Invoice"}
            />
            <ShowInvoice user={user} showDialog={showInvoiceDialog} transactionData={currentTransaction} setShowDialog={setShowInvoiceDialog} setTransactionData={setCurrentTransaction} setFile={setFile} onUpdateTotalAmounts={onUpdateTotalAmounts} />
            <ProfileScreen showDialog={profileDialog} currentSelection={currentSelection} type={type} setShowDialog={setProfileDialog} />
            <InvoiceUploadError showDialog={showUploadError} handleClose={handleErrorClose} type={errorType} />
            <TransactionForm
                showDialog={showDialog}
                deals={dealList}
                handleChange={handleChange}
                getOptions={getOptions}
                cardDeal={cardDeal}
                currentDeal={currentDeal}
                handleClose={handleClose}
                formData={formData}
                setFormData={setFormData}
                handleRegister={handleRegister}
                file={file}
                setFile={setFile}
                showUpload={showUpload}
                setShowUpload={setShowUpload}
                show={show}
                saveLoader={saveLoader}
                setShow={setShow}
                formType={formType}
                handleInvoiceUpload={handleinvoiceUpload}
                dealValidation={dealValidation}
                invoiceAmountValidation={invoiceAmountValidation}
                receivedAmountValidation={receivedAmountValidation}
                inputHandler={inputHandler}
            />
            <Grid container columnSpacing={1} px={3}>
                <Grid item xs={12}>
                    <PageNameComp
                        currentText="Find all the transactions here"
                        heading="Transactions"
                    />
                </Grid>
                {!error ?
                    <>
                        {!empty && !transactionLoader &&
                            <>
                                <Grid item xs={4} sm={4} md={1.8} lg={1.8} xl={1.8}>
                                    <SearchTextField val={dealSearch} funcHandle={setDealSearch} type={"Deal"} />
                                </Grid>
                                {user &&
                                    <>
                                        <Grid item xs={user.role === "vendor" ? 4 : 7.5} sx={{ textAlign: 'right', marginTop: 1.5, display: { xs: "block", sm: "block", md: "none", lg: "none", xl: "none" } }}>
                                            <FilterComponent
                                                anchorEl={anchorEl}
                                                open={open}
                                                setAnchorEl={setAnchorEl}
                                                options={['Status', 'Customer', 'Product']}
                                                handleFilterOpt={handleFilterOpt}
                                            />
                                        </Grid>
                                    </>}
                            </>}
                        {user && <>
                            {user.role === "vendor" &&
                                <Grid item xs={empty ? 11.7 : 4} sx={{ textAlign: empty ? 'right' : 'center', marginTop: 1.5, display: { xs: "block", sm: "block", md: "none", lg: "none", xl: "none" } }}>
                                    <Button variant="contained" size="small" startIcon={<AddIcon />} onClick={(e) => { handleAdd("transaction") }}>
                                        Transaction
                                    </Button>
                                </Grid>}
                        </>}
                        {!empty && !transactionLoader && role === "connector" && <Grid item xs={1}></Grid>}
                        {!empty && !transactionLoader &&
                            <Grid item xs={12} sm={12} md={7} lg={7} xl={7} display="flex">
                                {customerVisible && (
                                    <Grid item display={"flex"}><IconButton onClick={(e) => handleFilterClose("Customer")}><CancelOutlinedIcon /></IconButton><SearchTextField val={customerValue} funcHandle={setCustomerValue} type={"Customer"} /></Grid>
                                )}
                                {productVisible && (
                                    <Grid item display={"flex"}><IconButton onClick={(e) => handleFilterClose("Product")}><CancelOutlinedIcon /></IconButton><SearchTextField val={productValue} funcHandle={setProductValue} type={"Product"} /></Grid>
                                )}
                                {statusVisible && (
                                    <Grid item display={"flex"}>
                                        <IconButton onClick={(e) => handleFilterClose("Status")}>
                                            <CancelOutlinedIcon />
                                        </IconButton>
                                        <Autocomplete
                                            id="status"
                                            options={["Received", "Pending", "Approved", "Rejected"]}
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    height: '1.5rem'
                                                }
                                            }}
                                            value={statusValue}
                                            onChange={(event, newValue: any) => {
                                                setStatusValue(newValue);
                                            }}

                                            renderInput={params => (
                                                <TextField {...params} inputProps={{
                                                    ...params.inputProps,
                                                    style: { paddingBottom: "7px", width: "100%" }
                                                }} type="text" placeholder="Status" hiddenLabel fullWidth />
                                            )}
                                        />
                                    </Grid>
                                )}
                            </Grid>}
                        {!empty && !transactionLoader &&
                            <>
                                <Grid item xs={1} sx={{ textAlign: 'center', marginTop: 1.5 }}>
                                </Grid>
                                <Grid item xs={1} sx={{ textAlign: 'center', marginTop: 1.5, display: { xs: "none", sm: "none", md: "block", lg: "block", xl: "block" } }}>
                                    <FilterComponent
                                        anchorEl={anchorEl}
                                        open={open}
                                        setAnchorEl={setAnchorEl}
                                        options={['Status', 'Customer', 'Product']}
                                        handleFilterOpt={handleFilterOpt}
                                    />
                                </Grid>
                            </>}
                        {empty && <Grid item xs={10.8}></Grid>}
                        {(role === "vendor" && !transactionLoader) &&
                            <Grid item xs={1} sx={{ textAlign: 'right', marginTop: 1.5, marginRight: 1, display: { xs: "none", sm: "none", md: "block", lg: "block", xl: "block" } }}>
                                <Button variant="contained" size="small" startIcon={<AddIcon />} onClick={(e) => { handleAdd("transaction") }}>
                                    Transaction
                                </Button>
                            </Grid>
                        }
                        {transactionLoader ?
                            <>
                                <Grid item xs={12} sx={{ textAlign: "center", marginTop: 10 }}>
                                    <img src="assets/loader/Spinner-px.gif" height={"140vh"} />
                                    <Typography sx={{ paddingTop: 2 }}>Please wait while we fetch your transactions</Typography>
                                </Grid>
                            </>
                            :
                            <>
                                {empty ?
                                    <>
                                        <Grid item xs={12} sx={{ textAlign: 'center', marginTop: 2 }}>
                                            <img src="assets/loader/not_found.gif" height={'140vh'} />
                                            <Typography sx={{ fontWeight: 'bold' }}>
                                                No transactions found!
                                            </Typography>
                                            <Typography>
                                                Seems like you don't have any transactions yet
                                                <br />
                                            </Typography>
                                        </Grid>
                                    </>
                                    :
                                    <>
                                        <Grid item xs={12} mt={1}>
                                            {selected.length > 0 && (
                                                <EnhancedTableToolbar
                                                    numSelected={selected.length}
                                                    selectedUpdate={setSelected}
                                                    deletableUpdate={handleDelete}
                                                />
                                            )}
                                            <TableContainer component={Paper} sx={{ marginBottom: 2, display: { xs: "none", sm: "none", md: "block", lg: "block", xl: "block" } }}>
                                                <Table aria-label="simple table" size="small">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableHeadRow createSortHandler={createSortHandler} valueToOrderBy={valueToOrderBy} arr={[
                                                                {
                                                                    name: 'DEAL NAME',
                                                                    alignment: 'left',
                                                                    valueToOrderBy: 'dealId.name',
                                                                    orderDirection: orderDirection
                                                                },
                                                                {
                                                                    name: 'PRODUCT',
                                                                    alignment: 'left',
                                                                    valueToOrderBy: 'productId.name',
                                                                    orderDirection: orderDirection
                                                                },
                                                                {
                                                                    name: 'CUSTOMER',
                                                                    alignment: 'left',
                                                                    valueToOrderBy: 'customerId.firstName',
                                                                    orderDirection: orderDirection
                                                                }
                                                            ]} />

                                                            {(user?.role === "connector" || user?.role === "admin") ?
                                                                <TableHeadRow createSortHandler={createSortHandler} valueToOrderBy={valueToOrderBy} arr={[
                                                                    {
                                                                        name: 'VENDOR',
                                                                        alignment: 'left',
                                                                        valueToOrderBy: 'vendorId.name',
                                                                        orderDirection: orderDirection
                                                                    }]} />
                                                                : <></>}
                                                            {(user?.role === "vendor" || user?.role === "admin") ?

                                                                <TableHeadRow createSortHandler={createSortHandler} valueToOrderBy={valueToOrderBy} arr={[
                                                                    {
                                                                        name: 'CONNECTOR',
                                                                        alignment: 'left',
                                                                        valueToOrderBy: 'connectorId.name',
                                                                        orderDirection: orderDirection
                                                                    }]} />
                                                                : <></>}
                                                            <TableHeadRow createSortHandler={createSortHandler} valueToOrderBy={valueToOrderBy} arr={[
                                                                {
                                                                    name: 'DEAL AMT',
                                                                    alignment: 'right',
                                                                    valueToOrderBy: 'dealId.amount',
                                                                    orderDirection: orderDirection
                                                                },
                                                                {
                                                                    name: 'INVOICED AMT',
                                                                    alignment: "right",
                                                                    valueToOrderBy: "totalInvoiceAmount",
                                                                    orderDirection: orderDirection

                                                                },
                                                                {
                                                                    name: 'RECEIVED AMT',
                                                                    alignment: "right",
                                                                    valueToOrderBy: "totalReceivedAmount",
                                                                    orderDirection: orderDirection

                                                                }
                                                            ]}
                                                            />
                                                            <TableCell align="center" sx={tableHead}>
                                                                INVOICES
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    {paginationLoader ? <PaginationLoader colspan={8} /> :
                                                        <TableBody>
                                                            {sortedRowInformation(
                                                                rowData,
                                                                getComparator(orderDirection, valueToOrderBy)
                                                            ).map((row: any, index: any) => {
                                                                const isItemSelected = isSelected(row.id);
                                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                                return (
                                                                    <TableRow
                                                                        key={row.id}
                                                                        sx={{
                                                                            '&:last-child td, &:last-child th':
                                                                            {
                                                                                border: 0,
                                                                            },
                                                                        }}
                                                                    >
                                                                        {deletable && (
                                                                            <TableCell
                                                                                padding="checkbox"
                                                                                align="left"
                                                                            >
                                                                                <Checkbox
                                                                                    checked={isItemSelected}
                                                                                    inputProps={{
                                                                                        'aria-labelledby':
                                                                                            labelId,
                                                                                    }}
                                                                                    onClick={event =>
                                                                                        handleCheckClick(
                                                                                            event,
                                                                                            row.id
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </TableCell>
                                                                        )}
                                                                        <TableCell align="left" sx={{ height: 45 }}>
                                                                            <Typography sx={linkHover}
                                                                                onClick={(e) => showProfile(row.dealId, "deal")}>
                                                                                {row.dealId ? <>{firstCapital(row.dealId.name)}</> : <></>}
                                                                            </Typography>
                                                                        </TableCell>
                                                                        <TableCell align="left">
                                                                            <Typography sx={linkHover} onClick={(e) => showProfile(row.productId, "product")}>
                                                                                {firstCapital(row.productId.name)}
                                                                            </Typography>
                                                                        </TableCell>
                                                                        <TableCell
                                                                            align="left"
                                                                            sx={{ whiteSpace: 'nowrap' }}
                                                                        >
                                                                            <Typography sx={linkHover}
                                                                                onClick={(e) => showProfile(row.customerId, "customer")}
                                                                            >
                                                                                {row.customerId.name ? <>{firstCapital(row.customerId.name)}</> : <>{firstCapital(row.customerId.firstName)} {firstCapital(row.customerId.lastName)}</>}
                                                                            </Typography>
                                                                        </TableCell>

                                                                        {(user?.role === "connector" || user?.role === "admin") ?
                                                                            <>
                                                                                <TableCell align="left">
                                                                                    <Typography sx={linkHover}
                                                                                        onClick={(e) => showProfile(row.vendorId, "vendor")}
                                                                                    >
                                                                                        {firstCapital(row.vendorId.name)}
                                                                                    </Typography>
                                                                                </TableCell>
                                                                            </>
                                                                            : <></>}
                                                                        {(user?.role === "vendor" || user?.role === "admin") ?
                                                                            <>
                                                                                <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
                                                                                    <Typography sx={linkHover}
                                                                                        onClick={(e) => showProfile(row.connectorId, "connector")}
                                                                                    >
                                                                                        {firstCapital(row.connectorId.name)}
                                                                                    </Typography>
                                                                                </TableCell>
                                                                            </> : <></>}
                                                                        <TableCell align="right" sx={{ paddingRight: 5.3 }}>
                                                                            <Typography>
                                                                                {convertCurrency(row.dealId.amount)}
                                                                            </Typography>
                                                                        </TableCell>
                                                                        <TableCell align="right" sx={{ paddingRight: 5.3 }}>
                                                                            <Typography>
                                                                                {row.totalInvoiceAmount ? <>{convertCurrency(row.totalInvoiceAmount)}</> : <>$0.00</>}
                                                                            </Typography>
                                                                        </TableCell>
                                                                        <TableCell align="right" sx={{ paddingRight: 5.3 }}>
                                                                            <Typography>
                                                                                {row.totalReceivedAmount ? <>{convertCurrency(row.totalReceivedAmount)}</> : <>$0.00</>}
                                                                            </Typography>
                                                                        </TableCell>
                                                                        <TableCell align="center">
                                                                            <Typography sx={linkHover}
                                                                                onClick={(e) => invoiceShow(row)}
                                                                            >
                                                                                View&nbsp;
                                                                            </Typography>
                                                                            {user?.role === "vendor" ?
                                                                                <>
                                                                                    /
                                                                                    <Typography
                                                                                        sx={linkHover}
                                                                                        onClick={(e) => handleUpload(row)}
                                                                                    >
                                                                                        &nbsp;Add
                                                                                    </Typography>
                                                                                </> : <></>}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                );
                                                            })}
                                                        </TableBody>}
                                                </Table>
                                            </TableContainer>
                                            {rowData && (
                                                <>
                                                    <Grid item xs={12} sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none", xl: "none" } }}>
                                                        <TransactionsTableCard user={user} rowData={rowData} showProfile={showProfile} handleUpload={handleUpload} invoiceShow={invoiceShow} userType={"transaction"} paginationLoader={paginationLoader} />
                                                    </Grid>
                                                    <TablePagination
                                                        rowsPerPageOptions={[5, 10, 15, 20]}
                                                        sx={{
                                                            '.MuiTablePagination-toolbar': {
                                                                alignItems: 'baseline',
                                                            },
                                                        }}
                                                        SelectProps={{
                                                            disabled: paginationLoader
                                                        }}
                                                        component="div"
                                                        count={totalRecords}
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}
                                                        onPageChange={handleChangePage}
                                                        onRowsPerPageChange={handleChangeRowsPerPage}

                                                    />
                                                </>
                                            )}
                                        </Grid>
                                    </>}
                            </>}
                    </> :
                    <>
                        <Grid xs={12} sx={{ textAlign: "center", marginTop: 10 }}>
                            <ErrorComponent />
                        </Grid>
                    </>}
            </Grid>
        </>
    );
}
