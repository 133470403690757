import { TableCell, TableSortLabel, Typography } from '@mui/material';
import React,{useState} from 'react';
import { tableHead } from '../tableStyle';


const TableHeadRow = (props: any) => {
  const {createSortHandler,valueToOrderBy,arr} = props;
  // const [orderDirection, setOrderdirection] = useState<'asc' | 'desc'>('asc');
    // const [valueToOrderBy, setValueToOrderBy] = useState('');

  return arr.map((row: any, ind: number) =>
    row.valueToOrderBy!=false ? (
      <TableCell align={row.alignment} key={row.name} style={{ whiteSpace: 'nowrap',flexDirection: 'row', backgroundColor: valueToOrderBy === row.valueToOrderBy ? "#d7f5fa" : ""}}>
        <TableSortLabel
          active={true}
          onClick={createSortHandler(row.valueToOrderBy)}
          direction={
            valueToOrderBy === row.valueToOrderBy
              ? row.orderDirection
              : 'asc'
          }
          sx={{'& .MuiTableSortLabel-icon': {
            alignSelf: "baseline"
        },}}
        >
          <Typography sx={{color: "gray", fontWeight: "bold", fontSize: "15px"}}>{row.name}</Typography>
        </TableSortLabel>
      </TableCell>
      
    ) : (
      <TableCell sx={tableHead} align={row.alignment} key={row.name} style={{whiteSpace: 'nowrap'}}>{row.name}</TableCell>
    )
  );
};

export default TableHeadRow;
