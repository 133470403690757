import react, { useEffect, useState } from 'react';
import { Admin, Resource, ListGuesser, defaultTheme, CustomRoutes } from 'react-admin';
import { authProvider } from './authProvider';
import contacts from './contacts';
import companies from './companies';
import deals from './deals';
import { Dashboard } from './dashboard/Dashboard';
import Profile from './profile/Profile';
import { Route } from "react-router";
import Transactions from './transactions/Transactions';
import Activities from './Admin/Activities';
import MyLayout from './Layout';
import { Auth } from "aws-amplify";

const App = () => {
    
    const [userObj, setUserObj] = useState<any>();
    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .catch(() => {
                window.location.href = String(process.env.REACT_APP_LOGIN_URI);
            })
            .then(async () => {
                const user = await Auth.currentAuthenticatedUser();
                setUserObj({ email: user.attributes.email, role: user.attributes['custom:role'], name: user.attributes.name, phoneNumber1: user.attributes['custom:phoneNumber'], accessToken: user.storage['CognitoIdentityServiceProvider.' + process.env.REACT_APP_COGNITO_CLIENT_ID + '.' + user.username + '.accessToken'] });
            })
    }, [])
    return (
        <Admin
            basename="/admin"
            authProvider={authProvider}
            layout={MyLayout}
            dashboard={Dashboard}
            theme={{
                ...defaultTheme,
                palette: {
                    background: {
                        default: '#fafafb',
                    },
                },
            }}
        >
            <CustomRoutes>
                <Route path="/profile" element={<Profile user={userObj} />} />
                <Route path="/transactions" element={<Transactions user={userObj} />} />
                <Route path="/activityCentre/*" element={<Activities user={userObj} />} />
            </CustomRoutes>
            <Resource name="deals" {...deals} />
            <Resource name="contacts" {...contacts} />
            <Resource name="companies" {...companies} />
            <Resource name="contactNotes" />
            <Resource name="dealNotes" />
            <Resource name="tasks" list={ListGuesser} />
            <Resource name="sales" list={ListGuesser} />
            <Resource name="tags" list={ListGuesser} />
        </Admin>

    )
};

// export default App;

export default (App);
