import { useEffect, useState } from 'react';
import { Alert, AlertTitle, Box, Card, CircularProgress, Grid, Typography } from '@mui/material';
import { DealsChart } from './DealsChart';
import { DealsPipeline } from './DealsPipeline';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import axios from 'axios';
import { Link } from 'react-router-dom';
import PageNameComp from '../PageNameComp';
import ErrorComponent from '../Layout/ErrorComponent';
import { requestBodyCreator } from './RegistrationUtil';
import { Auth } from 'aws-amplify';
import UsersList from './UsersList';


export const Dashboard = ({ ...props }) => {
    const [user, setUser] = useState<any>();
    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .catch(() => {

                window.location.href = String(process.env.REACT_APP_LOGIN_URI)
            })
            .then(async () => {
                const user = await Auth.currentAuthenticatedUser();
                setUser({ email: user.attributes.email, role: user.attributes['custom:role'], name: user.attributes.name, phoneNumber1: user.attributes.phone_number ? user.attributes.phone_number : user.attributes['custom:phoneNumber'], organisationId: user.attributes['custom:organisationId'], accessToken: user.storage['CognitoIdentityServiceProvider.' + process.env.REACT_APP_COGNITO_CLIENT_ID + '.' + user.username + '.accessToken'] })
            })
    }, [])
    const [userInfo, setUserInfo] = useState(null);
    const [isApproved, setIsApproved] = useState(null);
    const [error, setError] = useState(false);
    const [defaultLoader, setDefaultLoader] = useState(true);
    const [newUserLoader, setNewUserLoader] = useState(false);
    const [finalError, setFinalError] = useState(false);
    const [pendingConnector, setPendingConnector] = useState(-1);
    const [pendingVendor, setPendingVendor] = useState(-1);
    const [pendingRegistrations, setPendingRegistrations] = useState(-1);
    useEffect(() => {
        if (user) {
            var userType = "";
            if (user?.role === "vendor") {
                userType = "contact"
            }
            else if (user?.role === "admin") {
                userType = "admin/data"
            }
            else {
                userType = user?.role;
            }
            axios
                .get(
                    process.env.REACT_APP_BASE_URL +
                    userType +
                    '/mail/' +
                    user?.email,
                    {
                        headers: {
                            "Authorization": "Bearer " + user.accessToken,
                        }
                    }
                )
                .catch((error: any) => {
                    setError(true);
                    setNewUserLoader(true);
                })
                .then((response: any) => {

                    setUserInfo(response.data);
                    setDefaultLoader(false)
                    if (user?.role === "vendor" || user?.role === "contact") {
                        setIsApproved(response.data.vendorId.isApproved);
                    }
                    else {
                        setIsApproved(response.data.isApproved)
                    }
                    setDefaultLoader(false)
                    if (user.role === "vendor" || user.role === "contact") {
                        axios.get(process.env.REACT_APP_BASE_URL + "registration/form/vendor/" + response.data.vendorId.id,
                            {
                                headers: {
                                    "Authorization": "Bearer " + user.accessToken,
                                }
                            })
                            .catch((error: any) => {

                                setPendingRegistrations(0);
                            })
                            .then((response: any) => {
                                var c = 0;

                                response.data.map((data: any) => {
                                    if (data.status === "pending") {
                                        c = c + 1;
                                    }
                                })
                                setPendingRegistrations(c);
                            })
                    }
                });

            if (user?.role === "admin") {
                axios.get(process.env.REACT_APP_BASE_URL + "vendor",
                    {
                        headers: {
                            "Authorization": "Bearer " + user.accessToken,
                        }
                    })
                    .catch((error: any) => {
                        setPendingVendor(0);
                    })
                    .then((response: any) => {
                        var c = 0;
                        if (response.data.length !== 0) {
                            response.data.map((data: any) => {
                                if (data.isApproved === "pending") {
                                    c = c + 1;
                                }
                            })
                        }

                        setPendingVendor(c)
                    })
                axios.get(process.env.REACT_APP_BASE_URL + "connector",
                    {
                        headers: {
                            "Authorization": "Bearer " + user.accessToken,
                        }
                    })
                    .catch((error: any) => {
                        setPendingConnector(0);
                    })
                    .then((response: any) => {
                        var k = 0;
                        if (response.data.length !== 0) {
                            response.data.map((data: any) => {
                                if (data.isApproved === "pending") {
                                    k = k + 1;
                                }
                            })
                        }

                        setPendingConnector(k)
                    })

            }
        }
    }, [user]);

    useEffect(() => {
        if (error && user) {
            var dataobj = requestBodyCreator(user);
            var apiUrl = "register";
            if (user?.role === "admin") {
                apiUrl = "admin/data/register";
            }
            axios
                .post(process.env.REACT_APP_BASE_URL + apiUrl, dataobj,
                    {
                        headers: {
                            "Authorization": "Bearer " + user.accessToken,
                        }
                    })
                .catch((error: any) => {

                    setFinalError(true)
                    setDefaultLoader(false)
                    setNewUserLoader(false)
                })
                .then((response: any) => {

                    setDefaultLoader(false)
                    setNewUserLoader(false)
                });
        }
    }, [error, user]);

    const classes = {
        cardStyle: { minHeight: "100%", textAlign: 'center', padding: 2 },
    };
    let role = user?.role;
    return (
        <Grid container spacing={1} mb={3} justifyContent="center" px={3}>
            <Grid item xs={12}>
                <PageNameComp currentText="See the summary at-a-glance here" heading="Dashboard" />
            </Grid>
            {!finalError ?
                <>
                    {defaultLoader ?
                        <>
                            {newUserLoader ?
                                <>
                                    <Grid item xs={12} sx={{ marginTop: 10, textAlign: "center" }}><img src="assets/loader/Spinner-px.gif" height={"140vh"} /><Typography sx={{ fontWeight: "bold", paddingTop: 2 }}>Welcome to ConnectorNet</Typography><Typography>Please wait while we set up things for you</Typography></Grid>
                                </>
                                :
                                <>
                                    <Grid item xs={12} sx={{ marginTop: 10, textAlign: "center" }}><img src="assets/loader/Spinner-px.gif" height={"140vh"} /><Typography sx={{ paddingTop: 2 }}>Please wait while we set up your dashboard</Typography></Grid>
                                </>}
                        </>
                        :
                        <>
                            {(role != "admin" && isApproved != "approved") && <Grid item xs={12}>
                                <Box sx={{ display: "flex", justifyContent: "center", marginTop: 4 }} >
                                    <img src="assets/images/unauthorized.png" height={"350vh"} style={{ textAlign: "center" }}></img>
                                </Box>
                            </Grid>}

                            {role === 'connector' && isApproved !== "approved" && (
                                <Grid item xs={12}>
                                    <Link
                                        to={'/admin/profile'}
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <Alert icon={false} severity="error" sx={{ justifyContent: "center", ":hover": { boxShadow: 3 } }}>
                                            <Typography>
                                                <strong>Your profile must be approved by a ConnectorNet Admin. Click here to complete your profile.</strong>
                                            </Typography>
                                        </Alert>
                                    </Link>
                                </Grid>
                            )}

                            {(role === 'vendor' || role === "contact") && (
                                <>
                                    {isApproved != "approved" && <Grid item xs={12}>
                                        <Link
                                            to={'/admin/profile'}
                                            style={{ textDecoration: 'none' }}
                                        >
                                            <Alert
                                                icon={false}
                                                severity="error"
                                                sx={{
                                                    justifyContent: 'center',
                                                    ":hover": { boxShadow: 3 }
                                                }}
                                            >

                                                <Typography>
                                                    <strong>
                                                        Your profile must be approved by a ConnectorNet Admin.  Click here to complete your profile.
                                                    </strong>
                                                </Typography>
                                            </Alert>
                                        </Link>
                                    </Grid>}
                                    {(isApproved == "approved" && pendingRegistrations > 0) &&
                                        <Grid item xs={6}>
                                            <Link to={'/admin/activityCentre'} style={{ textDecoration: 'none' }}>
                                                <Alert severity="warning"
                                                    sx={{
                                                        justifyContent: 'center',
                                                        textDecoration: 'none',
                                                        ":hover": { boxShadow: 3 }
                                                    }}>
                                                    <AlertTitle sx={{ textDecoration: 'none', textAlign: "center", width: "300px" }}>
                                                        {' '}
                                                        Connector Approvals Pending — <Typography display={"inline"}>{pendingRegistrations >= 0 ? <>{pendingRegistrations}</> : <><CircularProgress size="1.5rem" /></>}</Typography>
                                                        <Typography>
                                                            Approve connectors to get started
                                                        </Typography>
                                                    </AlertTitle>
                                                </Alert>
                                            </Link>
                                        </Grid>}
                                </>
                            )}
                            {role === 'admin' && (
                                <>
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            {pendingVendor > 0 ?
                                                <>
                                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                        <Link
                                                            to={'/admin/activityCentre'}
                                                            style={{ textDecoration: 'none' }}>
                                                            <Alert
                                                                severity="warning"
                                                                sx={{
                                                                    justifyContent: 'center',
                                                                    ":hover": { boxShadow: 3 }
                                                                }}>
                                                                <AlertTitle sx={{ width: "300px" }}>
                                                                    Vendor Approvals Pending — {pendingVendor >= 0 ? <>{pendingVendor}</> : <><CircularProgress size="1.5rem" /></>}
                                                                    <Typography>
                                                                        Approve Vendors
                                                                    </Typography>
                                                                </AlertTitle>
                                                            </Alert>
                                                        </Link>
                                                    </Grid>
                                                </>
                                                : <></>}
                                            {pendingConnector > 0 ?
                                                <>
                                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                        <Link
                                                            to={'/admin/activityCentre'}
                                                            style={{ textDecoration: 'none' }}>
                                                            <Alert
                                                                severity="warning"
                                                                sx={{
                                                                    justifyContent: 'center',
                                                                    ":hover": { boxShadow: 3 }
                                                                }}
                                                            >
                                                                <AlertTitle sx={{ width: "300px" }}>
                                                                    Connector Approvals Pending — {pendingConnector >= 0 ? <>{pendingConnector}</> : <><CircularProgress size="1.5rem" /></>}
                                                                    <Typography>
                                                                        Approve Connectors
                                                                    </Typography>
                                                                </AlertTitle>
                                                            </Alert>
                                                        </Link>
                                                    </Grid>
                                                </>
                                                : <></>}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Card sx={classes.cardStyle}>
                                            <Grid
                                                container
                                                spacing={1}
                                                direction="row"
                                                justifyContent="center">
                                                <Grid item>
                                                    <AttachMoneyIcon fontSize="large" />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="h4">100M</Typography>
                                                </Grid>
                                            </Grid>
                                            <Typography>Monthly Revenue</Typography>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Card sx={classes.cardStyle}>
                                            <ShowChartIcon fontSize="large" />
                                            <Typography>Live Chart</Typography>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Card sx={classes.cardStyle}>
                                            <Grid
                                                container
                                                spacing={1}
                                                direction="row"
                                                justifyContent="center"
                                            >
                                                <Grid item>
                                                    <PersonAddIcon fontSize="large" />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="h4">20K</Typography>
                                                </Grid>
                                            </Grid>
                                            <Typography>Users Onboarded</Typography>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Card sx={classes.cardStyle}>
                                            <AccessTimeIcon fontSize="large" />
                                            <Typography>Time Tracker</Typography>
                                        </Card>
                                    </Grid>
                                </>
                            )}

                            {(role === "admin" || isApproved === "approved") && <Grid item xs={12} mt={1} sx={{ marginBottom: 3 }}>
                                <DealsChart user={user} />
                            </Grid>}
                            {(role === "admin" || isApproved === "approved") && <Grid item lg={4} xl={4} md={4} sm={12} xs={12}>
                                <DealsPipeline user={user} userInfo={userInfo} dataType={"deals"} />
                            </Grid>}
                            {role === "admin" && <Grid item lg={4} xl={4} md={4} sm={12} xs={12}>
                                <UsersList user={user} userType="vendor" />
                            </Grid>}
                            {role === "admin" && <Grid item lg={4} xl={4} md={4} sm={12} xs={12}>
                                <UsersList user={user} userType="connector" />
                            </Grid>}
                            {((role === "vendor" || role === "contact") && isApproved === "approved") && <Grid item lg={4} xl={4} md={4} sm={12} xs={12}>
                                <UsersList user={user} userType="contact" userInfo={userInfo} />
                            </Grid>}
                            {((role === "vendor" || role === "contact" || role === "connector") && isApproved === "approved") && <Grid item lg={4} xl={4} md={4} sm={12} xs={12}>
                                <DealsPipeline user={user} userInfo={userInfo} dataType={"registrations"} />
                            </Grid>}
                            {((role === "connector") && isApproved === "approved") && <Grid item lg={4} xl={4} md={4} sm={12} xs={12}>

                            </Grid>}
                        </>
                    }
                </>
                :
                <><Grid item xs={12} sx={{ textAlign: "center", marginTop: 10 }}>
                    <ErrorComponent />
                </Grid></>}
        </Grid>
    );
};
