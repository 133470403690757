export const handleDownloadClick = (base64String: any, fileName: any) => {
    const binaryData = decodeBase64ToBinary(base64String);
    downloadPdf(binaryData, fileName);
}

const decodeBase64ToBinary = (base64String: any) => {
    const binaryString = window.atob(base64String);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
}

const downloadPdf = (binaryData: any, fileName: any) => {
    const blob = new Blob([binaryData], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
}

export const convertToBase64 = (file:any) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = () => {
          const base64String = fileReader.result?.toString()?.split(',')[1] || '';
          resolve(base64String);
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
        fileReader.readAsDataURL(file);
      });
}