import {
    Alert, AlertTitle, Box, Button, Card, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton,
    Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import TableHeadRow from '../Layout/TableHeadRow';
import Connector from './RegistrationFormScreens/Connector';
import Customer from './RegistrationFormScreens/Customer';
import MuiPhoneInput from 'material-ui-phone-number';
import Product from './RegistrationFormScreens/Product';
import Vendor from './RegistrationFormScreens/Vendor';
import axios from 'axios';
import { convertNumber, firstCapital } from '../Admin/Approvals/util';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useFormik } from 'formik';
import * as yup from 'yup';
import AttachmentIcon from '@mui/icons-material/Attachment';
import DocumentUpload from '../transactions/DocumentUpload';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';

const InvitationDialog = (props: any) => {
    const style = {
        padding: 1,
        marginBottom: 1,
        boxShadow: 0,
        paddingX: 3,
        paddingTop: 2,
    };
    const initialObj = {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber1: '',
    };
    const {user, open, handleInvitationClose, invitationData, customerInviteContact, setCustomerInviteContact, fetchLoader, setFetchLoader, fetchProdContactLoader, contactId, setContactId, prodContactId, setProdContactId, productContacts
        , handleInvitationSent, setRegistrationError, setErrorType
    } = props;
    const [contactForm, setContactForm] = useState(initialObj);
    const initialData = () => {
        return (
            `<p>Hello All</p><p></p><p>We would like to introduce you to other members of this deal. This invitation is intended to familiarize you with the stakeholders of this deal.</p><p>Thanks and Regards.</p>`
        )
    }
    const [email, setEmail] = useState(initialData);
    const [addContactLoader, setAddContactLoader] = useState(false);
    const [addAnother, setAddAnother] = useState(0);
    const [sendLoader, setSendLoader] = useState(false);
    const [subject, setSubject] = useState<any>("Welcome to Connectornet");
    useEffect(() => {
        if (open) {
            setFetchLoader(true)
            axios.get(process.env.REACT_APP_BASE_URL + "contact/customer/" + invitationData.customerId.id,
            {
              headers: {
                  "Authorization": "Bearer "+user.accessToken,
              }
            })
                .catch((error: any) => {
                    
                    setContactId([]);
                    setCustomerInviteContact([]);
                    setFetchLoader(false);
                })
                .then((response: any) => {
                    if (response) {
                        
                        setCustomerInviteContact(response.data);
                        let idArr = response.data.map((contact: any) => contact.id);
                        setContactId(idArr);
                        setFetchLoader(false);
                    }
                })
        }
    }, [addAnother])

    const handleCreate = (values: any) => {
        let value = contactForm.phoneNumber1;
        let newNumber = value.replace(/[^0-9]/g, "");
        if (newNumber.length < 11) {
            setFormValidation({ ...formValidation, phoneError: true, phoneMessage: "Phone number must contain 10 digits at least" })
        }
        else {
            setAddContactLoader(true);
            let body =
            {
                "contact":
                {
                    "firstName": values.firstName,
                    "lastName": values.lastName,
                    "email": values.email,
                    "phoneNumber1": newNumber,
                    "connectorId": null,
                    "companyId": null,
                    "vendorId":null,
                    "customerId": { "id": invitationData.customerId.id, "name": invitationData.customerId.firstName+" "+invitationData.customerId.lastName },
                    "isApproved": false,
                    "createdBy": invitationData.connectorId.name,
                    "modifiedBy": invitationData.connectorId.name,
                    "addressVerified": false,
                    "isAdmin": true,
                    "phoneNumberVerified": false,
                    "emailVerified": false,
                    "hasNewsLetter":false,
                    "designation":"Employee",
                    "status":0
                },
                "role": "customer-contact"
            }
             
            axios.post(process.env.REACT_APP_BASE_URL + "register", body,
            {
              headers: {
                  "Authorization": "Bearer "+user.accessToken,
              }
            })
                .catch((error: any) => {
                    
                    setContactForm(initialObj);
                    setAddContactLoader(false)
                })
                .then((response: any) => {
                    if (response) {
                        
                        setContactForm(initialObj);
                        setAddContactLoader(false)
                        setAddAnother(prevVal => prevVal + 1);
                    }
                })
        }
    };

    const getContactEmails = () => {
        let emails = invitationData.connectorId.email + "; ";
        if (contactId && contactId.length > 0) {
            contactId.forEach((element: any) => {
                for (let i = 0; i < customerInviteContact.length; i++) {
                    if (customerInviteContact[i].id === element) {
                        emails = emails.concat(customerInviteContact[i].email + "; ")
                        break;
                    }
                }
            });
        }
        if (prodContactId && prodContactId.length > 0) {
            prodContactId.forEach((element: any) => {
                for (let i = 0; i < productContacts.length; i++) {
                    if (productContacts[i].id === element) {
                        emails = emails.concat(productContacts[i].email + "; ")
                        break;
                    }
                }

            });
        }
        let n = emails.length;
        if (emails.length == 0)
            return null;
        emails = emails.substring(0, n - 2);
        return emails
    }

    const handleSend = () => {
        if (!sendLoader) {
            setSendLoader(true);
            let emails: any[] = [];
            emails.push(invitationData.connectorId.email)
            if (contactId && contactId.length > 0) {
                contactId.forEach((element: any) => {
                    for (let i = 0; i < customerInviteContact.length; i++) {
                        if (customerInviteContact[i].id === element) {
                            emails.push(customerInviteContact[i].email)
                            break;
                        }
                    }
                });
            }
            if (prodContactId && prodContactId.length > 0) {
                prodContactId.forEach((element: any) => {
                    for (let i = 0; i < productContacts.length; i++) {
                        if (productContacts[i].id === element) {
                            emails.push(productContacts[i].email)
                            break;
                        }
                    }
                });
            }
            
            
            
            axios.post(process.env.REACT_APP_BASE_URL + "mail/send", { file },
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        "Authorization": "Bearer "+user.accessToken
                    },
                    params: {
                        "subject": subject,
                        "body": email,
                        "to": emails.toString()
                    }
                })
                .catch((error: any) => {
                    

                    setRegistrationError(true);
                    setErrorType("notSend")
                    setSendLoader(false);
                })
                .then((response: any) => {
                    
                    setSendLoader(false);
                    handleInvitationClose();
                    handleInvitationSent(invitationData, "invitesSent");

                })
        }
    };

    const isSelected = (id: any, type: any) => {
        if (type == "custContact")
            return contactId.indexOf(id) !== -1;
        if (type == "prodContact")
            return prodContactId.indexOf(id) !== -1;
    }

    const handleCheckClick = (e: any, id: any, type: any) => {
        if (e.target.checked == true) {
            if (type == "custContact") {
                let temp = [...contactId, id];
                
                setContactId(temp);
            }
            if (type == "prodContact") {
                let temp = [...prodContactId, id];
                
                setProdContactId(temp);
            }
        }
        else {
            if (type == "custContact") {
                let temp = [...contactId];
                temp = temp.filter((ele: any) => ele != id);
                setContactId(temp)
            }
            if (type == "prodContact") {
                let temp = [...prodContactId];
                temp = temp.filter((ele: any) => ele != id);
                setProdContactId(temp)
            }
        }
    }
    const [formValidation, setFormValidation] = useState({ phoneError: false, phoneMessage: "" })

    const validationSchema = yup.object({
        firstName: yup
            .string()
            .min(4, "First name should contain 4 characters at least")
            .matches(/^[A-Za-z]*$/, 'Please enter a valid first name')
            .required('First name is required'),
        lastName: yup
            .string()
            .min(4, "Last name should contain 4 characters at least")
            .matches(/^[A-Za-z]*$/, 'Please enter a valid last name')
            .required('Last name is required'),
        email: yup
            .string()
            .email("Enter a valid mail address")
            .required("Email id is required"),
    });

    const formik = useFormik({
        initialValues:
        {
            firstName: '',
            lastName: '',
            email: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleCreate(values);
        }
    });
    const handlePhoneChange = (value: any) => {
        setContactForm({ ...contactForm, "phoneNumber1": value });
        if (value.replace(/[^0-9]/g, "").length < 11) {
            setFormValidation({ ...formValidation, phoneError: true, phoneMessage: "Phone number must contain 10 digits at least" });
        }
        else {
            setFormValidation({ ...formValidation, phoneError: false, phoneMessage: "" });
        }
    }
    const handleSubjectChange = (e: any) => {
        setSubject(e.target.value);
    };
    const handleInviteClose = () => {
        setContactForm(initialObj);
        handleInvitationClose();
        setAddContactLoader(false);
        setAttachmentVisible(false)
        setEmail(initialData);
        formik.resetForm();
        setFormValidation({ ...formValidation, phoneError: false, phoneMessage: "" })
    }
    const [file, setFile] = useState<any>();
    const [attachmentVisible, setAttachmentVisible] = useState(false);

    const handleFileChange = (newFile: any) => {
        setFile(newFile);
    };

    const handleAttachmentVisible = () => {
        setAttachmentVisible(!attachmentVisible);
    }
    const onReset = () => {
        setFile(null)
    }
    const fileTypes = {
        'application/pdf': [],
        "image/*": ['.png', '.jpg', '.jpeg'],
        "application/zip": ['.zip'],
        "application/msword": ['.docx', '.doc'],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": ['.xlsx'],
        "application/vnd.openxmlformats-officedocument.presentationml.presentation": ['.pptx'],
        "application/vnd.ms-powerpoint": ['.ppt']

    }
    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth="lg"
            sx={{
                '.MuiDialog-paper': {
                    position: 'absolute',
                    width: {xs:"90%", sm:"85%", md:"80%", lg:"80%", xl:"80%"},
                    height: {xs:"700px", sm:"700px", md:"1400px", lg:"1400px", xl:"1400px"},
                    minWidth: {xs:"90%", sm:"85%", md:"80%", lg:"80%", xl:"80%"},
                },
            }}
        >
            <DialogTitle sx={{ textAlign: "center", fontWeight: 'bold', backgroundColor: "#F0F7FF", fontSize:{xs:"large", sm:"large", md:"34px", lg:"34px", xl:"34px"} }}>
                    Invite Contacts
                    <Tooltip
                        title={"Send out invitations to the stakeholders of the deal"}
                        arrow
                        placement="right-end"
                    >
                        <Button>
                            <InfoOutlinedIcon />
                        </Button>
                    </Tooltip>
                <IconButton onClick={handleInviteClose} sx={{ float: "right", color: "red" }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container sx={{maxWidth:"100%"}}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Card
                            sx={{
                                width: '100%',
                                borderRadius: 4,
                                marginX: 2,
                                boxShadow: 0,
                            }}
                        >
                            <Box sx={style}>
                                <Connector
                                    currentSelection={
                                        invitationData.connectorId
                                    }
                                    cardConnector={true}
                                />
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Card
                            sx={{
                                width: '100%',
                                borderRadius: 4,
                                marginX: 2,
                                boxShadow: 0,
                            }}
                        >
                            <Box sx={style}>
                                <Vendor
                                    currentSelection={invitationData.vendorId}
                                    cardVendor={true}
                                />
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Card
                            sx={{
                                width: '100%',
                                borderRadius: 4,
                                marginX: 2,
                                boxShadow: 0,
                            }}
                        >
                            <Box sx={style}>
                                <Product
                                    currentSelection={invitationData.productId}
                                    cardProduct={true}
                                />
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Card
                            sx={{
                                width: '100%',
                                borderRadius: 4,
                                marginX: 2,
                                boxShadow: 0,
                            }}
                        >
                            <Box sx={style}>
                                <Customer
                                    currentSelection={invitationData.customerId}
                                    cardCustomer={true}
                                />
                            </Box>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sx={{ marginY: 2 }}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            sx={{ fontWeight: 'bold', fontSize: '20px' }}
                        >
                            Customer Contacts
                        </Typography>
                    </Grid>
                   
                        <Grid container spacing={1} mt={1}>
                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                <TextField
                                    fullWidth
                                    label="First Name"
                                    name="firstName"
                                    id="firstName"
                                    value={formik.values.firstName}
                                    type="text"
                                    variant="outlined"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                    helperText={formik.touched.firstName && formik.errors.firstName}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                <TextField
                                    fullWidth
                                    label="Last Name"
                                    name="lastName"
                                    id="lastName"
                                    value={formik.values.lastName}
                                    type="text"
                                    variant="outlined"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                    helperText={formik.touched.lastName && formik.errors.lastName}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <TextField
                                    fullWidth
                                    label="Email"
                                    name="email"
                                    id="email"
                                    value={formik.values.email}
                                    type="text"
                                    variant="outlined"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3} >
                                <MuiPhoneInput
                                    defaultCountry={'us'}
                                    onChange={handlePhoneChange}
                                    value={contactForm.phoneNumber1}
                                    variant='outlined'
                                    fullWidth
                                    label="Phone Number"
                                    error={formValidation.phoneError}
                                    helperText={formValidation.phoneMessage}
                                />
                            </Grid>
                            <Grid item xs={12} sx={{ marginTop: {xs:5, sm:5, md:0.70, lg:0.70, xl:0.70}, textAlign:"right" }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    onClick={() => formik.handleSubmit()}
                                    // onClick={(e) => handleCreate(initialObj)}
                                    sx={{ width: 100, height: 45, padding: 0, alignSelf: "center" }}
                                >
                                    {addContactLoader ? <CircularProgress sx={{ color: "white" }} /> : "ADD"}
                                </Button>
                            </Grid>

                            <Grid item xs={12} mt={2}>
                                {fetchLoader ? (<Grid container>
                                    <Grid item xs={12} sx={{ textAlign: "center" }}><CircularProgress /><Typography sx={{ paddingTop: 1 }}>Please wait while we fetch all the contacts of the customer</Typography></Grid></Grid>) :
                                    (customerInviteContact && customerInviteContact.length > 0) ? (
                                        <Grid container>
                                            <TableContainer
                                                component={Paper}
                                                sx={{ marginTop: 1 }}
                                            >
                                                <Table
                                                    aria-label="simple table"
                                                    size="small"
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableHeadRow
                                                                arr={[
                                                                    {
                                                                        name: '',
                                                                        alignment:
                                                                            'left',
                                                                        valueToOrderBy:
                                                                            false,
                                                                    },
                                                                    {
                                                                        name: 'CONTACT NAME',
                                                                        alignment:
                                                                            'left',
                                                                        valueToOrderBy:
                                                                            false,
                                                                    },
                                                                    {
                                                                        name: 'CONTACT EMAIL',
                                                                        alignment:
                                                                            'left',
                                                                        valueToOrderBy:
                                                                            false,
                                                                    },
                                                                    {
                                                                        name: 'CONTACT PHONE NUMBER',
                                                                        alignment:
                                                                            'left',
                                                                        valueToOrderBy:
                                                                            false,
                                                                    },
                                                                ]}
                                                            />
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {customerInviteContact.map(
                                                            (row: any, index: any) => {
                                                                const isItemSelected = isSelected(row.id, "custContact");
                                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                                return (
                                                                    <TableRow
                                                                        key={row.email}
                                                                    >
                                                                        <TableCell
                                                                            padding="checkbox"
                                                                            align="left"
                                                                        >
                                                                            <Checkbox
                                                                                checked={isItemSelected}
                                                                                inputProps={{
                                                                                    'aria-labelledby':
                                                                                        labelId,
                                                                                }}
                                                                                onClick={e => handleCheckClick(e, row.id, "custContact")}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Typography>
                                                                                {
                                                                                    firstCapital(row.firstName) + " " + firstCapital(row.lastName)
                                                                                }
                                                                            </Typography>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Typography
                                                                                component="a"
                                                                                href={`mailto:${row.email}`}
                                                                                sx={{
                                                                                    textDecoration:
                                                                                        'none',
                                                                                    color: '#00BFFF',
                                                                                    '&:hover':
                                                                                    {
                                                                                        color: '#0000ff',
                                                                                    },
                                                                                }}
                                                                            >
                                                                                {
                                                                                    row.email
                                                                                }
                                                                            </Typography>
                                                                        </TableCell>
                                                                        <TableCell align="left">
                                                                            <Typography>
                                                                                {
                                                                                    convertNumber(row.phoneNumber1)
                                                                                }
                                                                            </Typography>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                );
                                                            }
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    ) : (
                                        <Typography textAlign={'center'}>
                                            No contacts exist for this customer
                                        </Typography>
                                    )}
                            </Grid>

                            <Grid item xs={12} mt={2}>
                                <Typography
                                    sx={{ fontWeight: 'bold', fontSize: '20px' }}
                                >
                                    Vendor Contacts
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {fetchProdContactLoader ? (<Grid container>
                                    <Grid item xs={12} sx={{ textAlign: "center" }}><CircularProgress /><Typography sx={{ paddingTop: 1 }}>Please wait while we fetch all vendor contacts associated with the product</Typography></Grid></Grid>) :
                                    (productContacts && productContacts.length > 0) ? (
                                        <Grid container>
                                            <TableContainer
                                                component={Paper}
                                                sx={{ marginTop: 1 }}
                                            >
                                                <Table
                                                    aria-label="simple table"
                                                    size="small"
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableHeadRow
                                                                arr={[
                                                                    {
                                                                        name: '',
                                                                        alignment:
                                                                            'left',
                                                                        valueToOrderBy:
                                                                            false,
                                                                    },
                                                                    {
                                                                        name: 'CONTACT NAME',
                                                                        alignment:
                                                                            'left',
                                                                        valueToOrderBy:
                                                                            false,
                                                                    },
                                                                    {
                                                                        name: 'CONTACT EMAIL',
                                                                        alignment:
                                                                            'left',
                                                                        valueToOrderBy:
                                                                            false,
                                                                    },
                                                                    {
                                                                        name: 'CONTACT PHONE NUMBER',
                                                                        alignment:
                                                                            'left',
                                                                        valueToOrderBy:
                                                                            false,
                                                                    },
                                                                ]}
                                                            />
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {productContacts.map(
                                                            (row: any, index: any) => {
                                                                const isItemSelected = isSelected(row.id, "prodContact");
                                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                                return (
                                                                    <TableRow
                                                                        key={row.email}
                                                                    >
                                                                        <TableCell
                                                                            padding="checkbox"
                                                                            align="left"
                                                                        >
                                                                            <Checkbox
                                                                                checked={isItemSelected}
                                                                                inputProps={{
                                                                                    'aria-labelledby':
                                                                                        labelId,
                                                                                }}
                                                                                onClick={e => handleCheckClick(e, row.id, "prodContact")}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Typography>
                                                                                {
                                                                                    firstCapital(row.firstName) + " " + firstCapital(row.lastName)
                                                                                }
                                                                            </Typography>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Typography
                                                                                component="a"
                                                                                href={`mailto:${row.email}`}
                                                                                sx={{
                                                                                    textDecoration:
                                                                                        'none',
                                                                                    color: '#00BFFF',
                                                                                    '&:hover':
                                                                                    {
                                                                                        color: '#0000ff',
                                                                                    },
                                                                                }}
                                                                            >
                                                                                {
                                                                                    row.email
                                                                                }
                                                                            </Typography>
                                                                        </TableCell>
                                                                        <TableCell align="left">
                                                                            <Typography>
                                                                                {
                                                                                    convertNumber(row.phoneNumber1)
                                                                                }
                                                                            </Typography>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                );
                                                            }
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    ) : (
                                        <Typography textAlign={'center'}>
                                            No vendor contacts exist for this product
                                        </Typography>
                                    )}
                            </Grid>
                            <Grid item xs={12} sx={{ marginY: 2 }}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography
                                            sx={{ fontWeight: 'bold', fontSize: '20px' }}
                                        >
                                            Invitation Message
                                            <Tooltip title={"Attach file"} arrow placement="right-end"><IconButton onClick={handleAttachmentVisible}><AttachmentIcon /></IconButton></Tooltip>
                                        </Typography></Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            sx={{ fontWeight: 'bold', fontSize: '16px' }}
                                        >
                                            To</Typography></Grid>
                                    <Grid item xs={12}>

                                        <Card
                                            sx={{
                                                padding: 1,
                                                boxShadow: 0,
                                                backgroundColor: '#F0F0F0',

                                            }}
                                        >
                                            <Typography variant="subtitle2">
                                                {getContactEmails() ? getContactEmails() : "No recipient added"}
                                            </Typography>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} mt={1}>
                                        <Typography
                                            sx={{ fontWeight: 'bold', fontSize: '16px' }}
                                        >
                                            Subject</Typography></Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth

                                            name="subject"
                                            id="subject"
                                            value={subject}
                                            type="text"
                                            variant="outlined"
                                            onChange={handleSubjectChange}

                                        /></Grid>
                                    <Grid item xs={12} mt={1}>
                                        <Typography
                                            sx={{ fontWeight: 'bold', fontSize: '16px' }}> Body</Typography></Grid>
                                    <Grid item xs={12} mt={1}>
                                        <Box sx={{ boxShadow: 1 }}>
                                            <ReactQuill theme="snow" value={email} onChange={setEmail} />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {attachmentVisible && <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography
                                            sx={{ fontWeight: 'bold', fontSize: '20px' }}
                                        >
                                            Add Attachment
                                            {file && <Grid item xs={4.5} sx={{ textAlign: "right" }}><Button sx={{ color: "red" }} onClick={(e) => onReset()}>reset</Button></Grid>}
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={6} mt={1}>
                                        <DocumentUpload
                                            file={file}
                                            handleFileChange={handleFileChange}
                                            fileTypes={fileTypes}
                                            type={"attachment"}
                                        />
                                        
                                    </Grid>
                                    {file && <Grid item xs={6} mt={1}>
                                        <Typography>{file.name}</Typography>
                                    </Grid>}
                                </Grid>
                            </Grid>}
                        </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ paddingRight: 5, paddingY: 2 }}>
                <Button
                    onClick={handleInviteClose}
                    variant="contained"
                    color="error"
                    sx={{ width: 100, height: 45, padding: 1 }}
                    disabled={sendLoader}
                >
                    Cancel
                </Button>
                <Button
                    onClick={handleSend}
                    variant="contained"
                    color="primary"
                    disabled={(getContactEmails() && !fetchLoader && !fetchProdContactLoader) ? false : true}
                    sx={{ width: 100, height: 45, padding: 1 }}
                >
                    {sendLoader ? <CircularProgress sx={{ color: "white" }} /> : "Send"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default InvitationDialog;
