import React, { useEffect, useState } from 'react';
import {
    Autocomplete, Box, Button, CircularProgress, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography,
} from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Paper from '@mui/material/Paper';
import { convertDate, firstCapital } from './util';
import { linkCol, linkHover, statusColorText, statusColor, } from '../../tableStyle';
import TableHeadRow from '../../Layout/TableHeadRow';
import StatusCell from '../../Layout/StatusCell';
import { useAuth0 } from '@auth0/auth0-react';
import '../../globalStyles.css';
import VendorDealForm from '../../deals/VendorDealForm';
import ProfileScreen from '../../Profile Screens/ProfileScreen';
import SearchTextField from '../../Layout/SearchTextField';
import FilterComponent from '../../Layout/FilterComponent';
import { getComparator, sortedRowInformation } from '../../utils/SortingHandler';
import RejectionForm from './RejectionForm';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import RegistrationCardComponent from '../../deals/RegistrationCardComponent';

const VendorTable = (props: any) => {
    const { activityData, setActivityData, handleApprove, approveRow, globalVal, error, setGlobalData, user } = props;
    const [showDialog, setShowDialog] = useState(false);
    const defaultData = {
        id: 0,
        name: '',
        type: '',
        stage: 'introduction',
        description: '',
        amount: 0,
        endAmount: 0,
        createdBy: user?.name,
        modifiedBy: user?.name,
        isApproved: false,
        connectorId: { id: 0 },
        vendorId: { id: 0 },
        productId: { id: 0 },
        customerId: { id: 0 },
    };
    const [formData, setFormData] = useState(defaultData);
    const [currentRegistration, setCurrentRegistration] = useState<any>()
    const [profileDialog, setProfileDialog] = useState(false);
    const [currentProfile, setCurrentProfile] = useState<any>()
    const handleCreateDeal = (row: any) => {
        setShowDialog(true);

        setCurrentRegistration(row);
        setFormData({
            ...formData,
            vendorId: { id: row.vendorId.id },
            connectorId: { id: row.connectorId.id },
            customerId: { id: row.customerId.id },
            productId: { id: row.productId.id },
            name: "Deal-" + row.customerId.name + "-" + row.productId.name
        })
    };

    const [page, setPage] = React.useState(0);
    const [type, setType] = useState("")
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event: any, newPage: any) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleFormClose = () => {
        setShowDialog(false)
    }
    const handleDealRegistered = (record: any) => {
        setShowDialog(false);
        var tempData = [...activityData]
        const ind = tempData.findIndex(obj => obj.id === record.id);
        tempData[ind] = record;
        setActivityData(tempData);
        setGlobalData(tempData);
        successToast();
    }
    const showProfile = (row: any, profile: string) => {
        setType(profile)
        setCurrentProfile(row)
        setProfileDialog(true)
    }
    const [anchorEl, setAnchorEl] = useState(null);
    const [connSearch, setConnSearch] = useState<any>('')
    const [statusValue, setStatusValue] = useState<any>(null);
    const [statusVisible, setStatusVisible] = useState(false);
    const [productVisible, setProductVisible] = useState(false);
    const [customerVisible, setCustomerVisible] = useState(false);
    const [productSearch, setProductSearch] = useState('');
    const [customerSearch, setCustomerSearch] = useState('');
    const [showRejectionForm, setShowRejectionForm] = useState(false);
    const successToast = () => toast.success("Mail sent successfully");
    const failureToast = () => toast.error("There was some error in sending the notification, please try again.");

    const open = Boolean(anchorEl);

    const handleCloseFilter = (type: any) => {
        if (type == "Status") {
            setStatusValue(null);
            setStatusVisible(false);
        }
        if (type == "Product") {
            setProductSearch('');
            setProductVisible(false);
        }
        if (type == "Customer") {
            setCustomerSearch('');
            setCustomerVisible(false);
        }
    };

    const handleFilterOpt = (opt: any) => {
        setAnchorEl(null);
        if (opt === 'Status')
            setStatusVisible(true);
        if (opt === 'Product')
            setProductVisible(true);
        if (opt === 'Customer')
            setCustomerVisible(true);
    };

    useEffect(() => {

        if (globalVal) {
            var tempArr = [...globalVal];
            if (statusValue != null && statusValue !== 'All') {
                tempArr = tempArr.filter(obj => {
                    let str = statusValue[0].toLowerCase() + statusValue.substring(1).replaceAll(" ", "");
                    return obj.status === str;
                });
            }
            if (connSearch.trim().length > 0) {
                tempArr = tempArr.filter(row => {
                    return row.connectorId.name.toLowerCase().includes(connSearch.trim().toLowerCase());
                });
            }
            if (productSearch.trim().length > 0) {
                tempArr = tempArr.filter(row => {
                    return row.productId.name.toLowerCase().includes(productSearch.trim().toLowerCase());
                });
            }
            if (customerSearch.trim().length > 0) {
                tempArr = tempArr.filter(row => {
                    return row.customerId.firstName.toLowerCase().includes(customerSearch.trim().toLowerCase());
                });
            }
            setPage(0);
            setActivityData(tempArr);
        }
    }, [connSearch, productSearch, customerSearch, statusValue]);

    //Sorting
    const [valueToOrderBy, setValueToOrderBy] = useState('createcreatedDatetime');
    const [orderDirection, setOrderdirection] = useState<'asc' | 'desc'>('asc');

    const createSortHandler = (property: any) => (event: any) => {
        const isAscending = orderDirection === 'asc';
        setValueToOrderBy(property);
        setOrderdirection(isAscending ? 'desc' : 'asc');
        sortedRowInformation(activityData, getComparator(orderDirection, property));
    };

    const onClickReject = (row: any) => {
        setShowRejectionForm(true);
        setFormData(row);
    }

    const onCancelReject = (row: any) => {
        setShowRejectionForm(false);
    }

    const handleRejection = () => {
        setShowRejectionForm(false);
        axios.put(
            process.env.REACT_APP_BASE_URL + 'registration/' + formData.id,
            formData,
            {
                headers: {
                    "Authorization": "Bearer " + user.accessToken,
                }
            }
        )
            .catch((error: any) => {

                failureToast();
            })
            .then((response: any) => {
                var tempData = [...activityData]
                const ind = tempData.findIndex(obj => obj.id === response.data.id);
                tempData[ind] = response.data;
                setActivityData(tempData);
                setGlobalData(tempData);
                successToast();
            });
    }


    return (
        <>
            <RejectionForm showDialog={showRejectionForm} handleClose={onCancelReject} type={"Registration"} formData={formData} setFormData={setFormData} handleSubmit={handleRejection} rejectionType={"rejected"} />
            <ProfileScreen showDialog={profileDialog} currentSelection={currentProfile} type={type} setShowDialog={setProfileDialog} />
            <VendorDealForm user={user} showDialog={showDialog} registrationData={currentRegistration} handleClose={handleDealRegistered} formData={formData} setFormData={setFormData} />
            {error && (
                <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    direction="column"
                >
                    <Grid item xs={12}>
                        <Typography>
                            Error in fetching data...Please try again
                        </Typography>
                    </Grid>
                </Grid>
            )}
            {!error && (
                <Grid
                    container
                // direction="column"
                >
                    <Grid item xs={6} sx={{ textAlign: "left" }}>
                        <SearchTextField val={connSearch} funcHandle={setConnSearch} type={"Connector"} />
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "right", justifyContent: "right", display: "flex", alignItems: "right" }}>
                        <FilterComponent
                            anchorEl={anchorEl}
                            open={open}
                            setAnchorEl={setAnchorEl}
                            options={['Status', 'Product', 'Customer']}
                            handleFilterOpt={handleFilterOpt}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            {productVisible && <Grid item display={"flex"}> <IconButton onClick={(e) => handleCloseFilter("Product")}><CancelOutlinedIcon /></IconButton><SearchTextField val={productSearch} funcHandle={setProductSearch} type={"Product"} /></Grid>}
                            {customerVisible && <Grid item display={"flex"}> <IconButton onClick={(e) => handleCloseFilter("Customer")}><CancelOutlinedIcon /></IconButton><SearchTextField val={customerSearch} funcHandle={setCustomerSearch} type={"Customer"} /></Grid>}
                            {statusVisible && (
                                <Grid item display={"flex"}>
                                    <IconButton
                                        onClick={(e) => handleCloseFilter("Status")}
                                    >
                                        <CancelOutlinedIcon />
                                    </IconButton>
                                    <Autocomplete
                                        disablePortal
                                        id="status"
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                height: '1.5rem'
                                            }
                                        }}
                                        value={statusValue}
                                        onChange={(
                                            event,
                                            newValue: any
                                        ) => {
                                            setStatusValue(newValue);
                                        }}
                                        options={['Pending', 'Approved', 'Rejected', 'Cancelled', 'Deal Started', 'Invites Sent', 'Expired']}
                                        renderInput={params => (
                                            <TextField
                                                {...params} inputProps={{
                                                    ...params.inputProps,
                                                    style: { paddingBottom: "6px", width: "100%" }
                                                }}
                                                placeholder="Status"
                                                hiddenLabel
                                            />
                                        )}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper} sx={{ marginTop: 1, display: { xs: "none", sm: "none", md: "block", lg: "block", xl: "block" } }}>
                            <Table aria-label="simple table" size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableHeadRow createSortHandler={createSortHandler} valueToOrderBy={valueToOrderBy}
                                            arr={user.role === "vendor" ?
                                                [
                                                    {
                                                        name: 'CONNECTOR',
                                                        alignment: 'left',
                                                        valueToOrderBy: 'connectorId.name',
                                                        orderDirection: orderDirection
                                                    },
                                                    {
                                                        name: 'PRODUCT',
                                                        alignment: 'left',
                                                        valueToOrderBy: 'productId.name',
                                                        orderDirection: orderDirection
                                                    },
                                                    {
                                                        name: 'CUSTOMER',
                                                        alignment: 'left',
                                                        valueToOrderBy: 'customerId.firstName',
                                                        orderDirection: orderDirection
                                                    },
                                                    {
                                                        name: 'APPLIED DATE',
                                                        alignment: 'left',
                                                        valueToOrderBy: 'createdDatetime',
                                                        orderDirection: orderDirection
                                                    },
                                                    {
                                                        name: 'ACTIVE DATE',
                                                        alignment: 'left',
                                                        valueToOrderBy: 'modifiedDatetime',
                                                        orderDirection: orderDirection
                                                    },
                                                    {
                                                        name: 'EXPIRATION DATE',
                                                        alignment: 'left',
                                                        valueToOrderBy: 'expirationDate',
                                                        orderDirection: orderDirection
                                                    },
                                                    {
                                                        name: 'STATUS',
                                                        alignment: 'center',
                                                        valueToOrderBy: false,
                                                    },
                                                    {
                                                        name: 'ACTION',
                                                        alignment: 'center',
                                                        valueToOrderBy: false,
                                                    },
                                                ] : [
                                                    {
                                                        name: 'CONNECTOR',
                                                        alignment: 'left',
                                                        valueToOrderBy: 'connectorId.name',
                                                        orderDirection: orderDirection
                                                    },
                                                    {
                                                        name: 'PRODUCT',
                                                        alignment: 'left',
                                                        valueToOrderBy: 'productId.name',
                                                        orderDirection: orderDirection
                                                    },
                                                    {
                                                        name: 'CUSTOMER',
                                                        alignment: 'left',
                                                        valueToOrderBy: 'customerId.firstName',
                                                        orderDirection: orderDirection
                                                    },
                                                    {
                                                        name: 'APPLIED DATE',
                                                        alignment: 'left',
                                                        valueToOrderBy: 'createdDatetime',
                                                        orderDirection: orderDirection
                                                    },
                                                    {
                                                        name: 'ACTIVE DATE',
                                                        alignment: 'left',
                                                        valueToOrderBy: 'modifiedDatetime',
                                                        orderDirection: orderDirection
                                                    },
                                                    {
                                                        name: 'EXPIRATION DATE',
                                                        alignment: 'left',
                                                        valueToOrderBy: 'expirationDate',
                                                        orderDirection: orderDirection
                                                    },
                                                    {
                                                        name: 'STATUS',
                                                        alignment: 'center',
                                                        valueToOrderBy: false,
                                                    }
                                                ]}
                                        />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {activityData &&
                                        sortedRowInformation(activityData
                                            .slice(
                                                page * rowsPerPage,
                                                page * rowsPerPage + rowsPerPage
                                            ), getComparator(orderDirection, valueToOrderBy))
                                            .map((row: any) => {
                                                return (
                                                    <TableRow key={row.id}>
                                                        <TableCell align="left">
                                                            <Typography
                                                                onClick={(e) => showProfile(row.connectorId, "connector")}
                                                                style={linkCol}
                                                            >
                                                                <Typography
                                                                    sx={
                                                                        linkHover
                                                                    }
                                                                >
                                                                    {
                                                                        firstCapital(row
                                                                            .connectorId
                                                                            .name)
                                                                    }
                                                                </Typography>
                                                            </Typography>
                                                        </TableCell>

                                                        <TableCell align="left">
                                                            <Typography
                                                                onClick={(e) => showProfile(row.productId, "product")}
                                                                style={linkCol}
                                                            >
                                                                <Typography
                                                                    sx={
                                                                        linkHover
                                                                    }
                                                                >
                                                                    {
                                                                        row
                                                                            .productId
                                                                            .name
                                                                    }
                                                                </Typography>
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Typography
                                                                onClick={(e) => showProfile(row.customerId, "customer")}
                                                                style={linkCol}
                                                            >
                                                                <Typography
                                                                    sx={
                                                                        linkHover
                                                                    }
                                                                >
                                                                    {row.customerId.name? <>{firstCapital(row.customerId.name)}</>:<>{firstCapital(row.customerId.firstName)} {firstCapital(row.customerId.lastName)}</>}
                                                                </Typography>
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Typography
                                                                sx={{
                                                                    fontSize:
                                                                        '15px',
                                                                }}
                                                            >
                                                                {row.createdDatetime
                                                                    ? convertDate(
                                                                        row.createdDatetime
                                                                    )
                                                                    : ''}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Typography
                                                                sx={{
                                                                    fontSize:
                                                                        '15px',
                                                                }}
                                                            >
                                                                {row.modifiedDatetime
                                                                    ? convertDate(
                                                                        row.modifiedDatetime
                                                                    )
                                                                    : ''}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Typography
                                                                sx={{
                                                                    fontSize:
                                                                        '15px',
                                                                }}
                                                            >
                                                                {row.expirationDate
                                                                    ? convertDate(
                                                                        row.expirationDate
                                                                    )
                                                                    : ''}
                                                            </Typography>
                                                        </TableCell>
                                                        {/* {
                                                            <TableCell
                                                                align="left"
                                                                sx={{
                                                                    minWidth: 180,
                                                                }}
                                                            >
                                                                {row.connectorId
                                                                    .companyId ==
                                                                null ? (
                                                                    ''
                                                                ) : (
                                                                    <Link
                                                                        to="/admin/activityCentre"
                                                                        style={
                                                                            linkCol
                                                                        }
                                                                    >
                                                                        <Typography
                                                                            sx={
                                                                                linkHover
                                                                            }
                                                                        >
                                                                            {
                                                                                row
                                                                                    .connectorId
                                                                                    .companyId
                                                                                    .name
                                                                            }
                                                                        </Typography>
                                                                    </Link>
                                                                )}
                                                            </TableCell>
                                                        } */}
                                                        {row.id == approveRow ?
                                                            <>
                                                                <TableCell align="center">
                                                                    <CircularProgress />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography
                                                                        sx={{
                                                                            color: '#0f0f0f',
                                                                            fontSize: '15px',
                                                                            whiteSpace: "noWrap"
                                                                        }}
                                                                    >
                                                                        Please wait while we send out the notifications.
                                                                    </Typography>
                                                                </TableCell>
                                                            </>
                                                            :
                                                            <>
                                                                <StatusCell
                                                                    textClr={
                                                                        statusColorText[
                                                                        row.status
                                                                        ]
                                                                    }
                                                                    bgClr={
                                                                        statusColor[
                                                                        row.status
                                                                        ]
                                                                    }
                                                                    text={row.status === "invitesSent" ? "Invite Sent" : row.status === "dealStarted" ? "Deal Started" : firstCapital(row.status)}
                                                                />
                                                                {user.role === "vendor" &&
                                                                    <TableCell align="center" sx={{ whiteSpace: "noWrap" }}>
                                                                        {row.status ===
                                                                            'pending' && (
                                                                                <Box
                                                                                    display="flex"
                                                                                    justifyContent={
                                                                                        'center'
                                                                                    }
                                                                                >
                                                                                    <Button
                                                                                        color="success"
                                                                                        variant="outlined"
                                                                                        sx={{
                                                                                            minWidth: 100,
                                                                                            marginRight: 1,
                                                                                            '&:hover':
                                                                                            {
                                                                                                backgroundColor:
                                                                                                    '#e6ffe6',
                                                                                                color: 'green',
                                                                                            },
                                                                                        }}
                                                                                        onClick={() =>
                                                                                            handleApprove(
                                                                                                row,
                                                                                                'approved'
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <Typography
                                                                                            sx={{
                                                                                                fontSize:
                                                                                                    '15px',
                                                                                            }}
                                                                                        >
                                                                                            Approve
                                                                                        </Typography>
                                                                                    </Button>
                                                                                    <Button
                                                                                        color="error"
                                                                                        variant="outlined"
                                                                                        sx={{
                                                                                            minWidth: 100,
                                                                                            '&:hover':
                                                                                            {
                                                                                                backgroundColor:
                                                                                                    '#ffebe6',
                                                                                                color: 'red',
                                                                                            },
                                                                                        }}
                                                                                        onClick={() =>
                                                                                            onClickReject(row)
                                                                                        }
                                                                                    >
                                                                                        <Typography
                                                                                            sx={{
                                                                                                fontSize:
                                                                                                    '15px',
                                                                                            }}
                                                                                        >
                                                                                            Reject
                                                                                        </Typography>
                                                                                    </Button>
                                                                                </Box>
                                                                            )}
                                                                        {row.status ===
                                                                            'invitesSent' && (
                                                                                <Box
                                                                                    display="flex"
                                                                                    justifyContent={
                                                                                        'center'
                                                                                    }
                                                                                >
                                                                                    <Button
                                                                                        color="primary"
                                                                                        variant="outlined"
                                                                                        sx={{
                                                                                            minWidth: 208,
                                                                                            marginRight: 1,
                                                                                        }}
                                                                                        onClick={() =>
                                                                                            handleCreateDeal(
                                                                                                row
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <Typography
                                                                                            sx={{
                                                                                                fontSize:
                                                                                                    '15px',
                                                                                            }}
                                                                                        >
                                                                                            Create
                                                                                            Deal
                                                                                        </Typography>
                                                                                    </Button>
                                                                                </Box>
                                                                            )}
                                                                        {row.status === "approved" && (
                                                                            <Typography
                                                                                sx={{
                                                                                    color: '#0f0f0f',
                                                                                    fontSize: '15px',
                                                                                    whiteSpace: "noWrap"
                                                                                }}
                                                                            >
                                                                                Please wait while Connector contacts the customer.
                                                                            </Typography>
                                                                        )}
                                                                        {row.status !==
                                                                            'approved' &&
                                                                            row.status !==
                                                                            'pending' && row.status !== 'invitesSent' && (
                                                                                <Typography
                                                                                    sx={{
                                                                                        color: '#0f0f0f',
                                                                                        fontSize:
                                                                                            '15px',
                                                                                    }}
                                                                                >
                                                                                    No further action required
                                                                                </Typography>
                                                                            )}
                                                                    </TableCell>
                                                                }
                                                            </>
                                                        }
                                                    </TableRow>
                                                );
                                            })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {activityData && (
                            <>
                                <Grid item xs={12} sx={{ display: { sx: "block", sm: "block", md: "none", lg: "none", xl: "none" } }}>
                                    <RegistrationCardComponent user={user} rowData={activityData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)} showProfile={showProfile} onApprove={handleApprove} onClickReject={onClickReject} approveLoader={approveRow} handleCreateDeal={handleCreateDeal} />
                                </Grid>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 15, 20]}
                                    sx={{
                                        '.MuiTablePagination-toolbar': {
                                            alignItems: 'baseline',
                                        },
                                        marginBottom: 8
                                    }}
                                    component="div"
                                    count={activityData.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </>
                        )}
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default VendorTable;
