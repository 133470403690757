import { Divider, Grid, Typography } from '@mui/material';
import React from 'react'
import { convertNumber } from '../../Admin/Approvals/util';

const Product = (props:any) => {
  const {currentSelection, cardProduct} = props;
  const style = {padding:1, boxShadow:0, backgroundColor:"#F0F0F0", minHeight: 195};
  return (
    <>
        <Grid container spacing={2}>
          <Grid container sx={style}>
          <Grid item xs={12} mb={1}>
          <Typography sx={{fontWeight:"bold", paddingLeft:1, fontSize:"20px"}}>Product Details</Typography>
          </Grid>
          <Grid container sx={{paddingX: 1}}>
            {cardProduct?<>
              <Grid item xs={12} mb={1}>
                {currentSelection.name? 
                      <Typography sx={{fontWeight:"bold"}}>{currentSelection.name}</Typography>
                    :
                      <Typography sx={{fontWeight:"light"}} >No product name available</Typography>
                    }
                </Grid>
                <Grid item xs={12} mb={1}>
                {currentSelection.type?
                      <Typography sx={{fontWeight:"light"}}>{currentSelection.type}</Typography>
                    :
                      <Typography sx={{fontWeight:"light"}} >No product type available</Typography>
                    }
                </Grid>
                <Grid item xs={12}>
                {currentSelection.description? 
                      <Typography sx={{fontWeight:"light", minHeight:40, maxHeight:40}}>{currentSelection.description}</Typography>
                    :
                      <Typography sx={{fontWeight:"light"}} >No description available</Typography>
                    }
                </Grid>
            </>
            :
            <>
              <Grid item xs={12} mb={1} height={97}>
              <Typography>Please select a product</Typography>
              </Grid>
            </>}
          {/* <Grid xs={12} sx={{ marginTop: 2 }}>
            <Divider />
          </Grid> */}
          </Grid>
          </Grid>
        </Grid>
    </>
  )
}

export default Product;