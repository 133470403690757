import { Add } from '@mui/icons-material';
import { Box, Button, Grid, Typography } from '@mui/material';
import axios from 'axios';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { toast } from "react-toastify";
import * as yup from 'yup';
import ErrorComponent from '../../Layout/ErrorComponent';
import TabMenu from '../../Layout/TabMenu';
import ActivitiesHeader from '../../utils/ActivitiesHeader';
import AddConnectorVendorForm from './AddConnectorVendorForm';
import ApproveConnectors from './ApproveConnectors';
import ApproveProduct from './ApproveProduct';
import ApproveVendors from './ApproveVendors';
import EditComponent from './../../components/EditComponent';

const Approvals = (props: any) => {
  let {
    activityData,
    setActivityData,
    error,
    handleApprove,
    approveRow,
    globalData,
    setGlobalData,
    screenType,
    user,
  } = props;
  const [pendingConnectors, setPendingConnectors] = useState<any>();
  const [pendingVendors, setPendingVendors] = useState<any>();
  const [pendingProducts, setPendingProducts] = useState<any>();
  const [currentSelection, setCurrentSelection] = useState<any>();
  const [heading, setHeading] = useState('Connector Approval');
  const [setIndex, setSetIndex] = useState(0);
  const [showDialog, setShowDialog] = useState(false)

  const [editable, setEditable] = useState<boolean>(false)
  const [selectionType, setSelectionType] = useState()
  const [currentEditSelection, setCurrentEditSelection] = useState()


  const [phone, setPhone] = useState<string>('');
  const [phoneValidation, setPhoneValidation] = useState({ error: false, message: "" })
  const [saveLoader, setSaveLoader] = useState(false);
  const [isAddressValidated, setIsAddressValidated] = useState(false);
  const [formData, setFormData] = useState({
    address: '',
    addressLine2: '',
    cityName: '',
    streetName: '',
    stateName: '',
    countryName: '',
    zipcode: '',
    phoneNumber1: ''
  });

  const [currentText, setCurrentText] = useState('Find here the connectors who wish to associate with you');
  const [currentScreen, setCurrentScreen] = useState('connectors');

  var dataobj = {};

  useEffect(() => {
    setCurrentScreen('connector');
  }, [activityData, globalData]);
  useEffect(() => {
    if (screenType === 'connectors') {
      HandleTabMenu(0);
    } else if (screenType === 'vendors') {
      HandleTabMenu(1);
    } else if (screenType === 'products') {
      HandleTabMenu(2);
    } else if (screenType === 'registrations') {
      setCurrentScreen('connector');
      setCurrentText(
        'Find here the connector who wish to associate with you'
      );
      setHeading('Connector Approval');
    }
  }, [screenType]);

  const getCharacterValidationError = (str: string) => {
    return `Your password must have at least 1 ${str} character`;
  };

  useEffect(() => {
    if (user) {
      if (user?.role === 'admin') {
        axios
          .get(process.env.REACT_APP_BASE_URL + 'vendor/pages', {
            headers: {
              Authorization: 'Bearer ' + user.accessToken,
            },
            params: {
              pageNo: 0,
              pageSize: 1,
              sortBy: 'id',
              sortDir: 'desc',
            },
          })
          .catch((error: any) => { })
          .then((response: any) => {
            setPendingVendors(
              response.data.additionalData.pendingVendorCount
            );
          });
        axios
          .get(process.env.REACT_APP_BASE_URL + 'connector/pages', {
            headers: {
              Authorization: 'Bearer ' + user.accessToken,
            },
            params: {
              pageNo: 0,
              pageSize: 1,
              sortBy: 'id',
              sortDir: 'desc',
            },
          })
          .catch((error: any) => { })
          .then((response: any) => {
            setPendingConnectors(
              response.data.additionalData.pendingConnectorCount
            );
          });
        axios
          .get(process.env.REACT_APP_BASE_URL + 'product/pages', {
            headers: {
              Authorization: 'Bearer ' + user.accessToken,
            },
            params: {
              pageNo: 0,
              pageSize: 1,
              sortBy: 'id',
              sortDir: 'desc',
            },
          })
          .catch((error: any) => { })
          .then((response: any) => {
            setPendingProducts(
              response.data.additionalData.pendingProductCount
            );
          });
      }
    }
  }, [user]);

  const vendorValidationSchema = yup.object({
    organizationName: yup
      .string()
      .min(4, "Organization should have 4 characters at least")
      .matches(/^[A-Za-z0-9 -]*$/, 'Please enter a valid name')
      .required("Organization name is required"),
    website: yup
      .string()
      .required('Website is required')
      .test('no-https', 'Please enter a valid website without "https://"', (value: string | undefined) => {
        return value ? !value.toLowerCase().startsWith('https://') : true;
      }),
    firstName: yup
      .string()
      .min(4, "First name should have 4 characters at least")
      .matches(/^[A-Za-z-]*$/, 'Please enter a valid first name')
      .required('First name is required'),
    lastName: yup
      .string()
      .min(4, "First name should have 4 characters at least")
      .matches(/^[A-Za-z-]*$/, 'Please enter a valid last name')
      .required('Last name is required'),
    email: yup
      .string()
      .matches(/^[\w\.+-]+@[\w\.-]+\.\w+$/, 'Please enter a valid email')
      .email("Enter a valid mail address")
      .max(45, 'Should contain at most 45 characters')
      .required("Email id is required"),
    mfa: yup
      .string()
      .required('MFA Type is required'),
  });

  const connectorValidationSchema = yup.object({
    firstName: yup
      .string()
      .min(4, "First name should have 4 characters at least")
      .matches(/^[A-Za-z-]*$/, 'Please enter a valid first name')
      .required('First name is required'),
    lastName: yup
      .string()
      .min(4, "Last name should have 4 characters at least")
      .matches(/^[A-Za-z-]*$/, 'Please enter a valid last name')
      .required('Last name is required'),
    email: yup
      .string()
      .matches(/^[\w\.+-]+@[\w\.-]+\.\w+$/, 'Please enter a valid email')
      .email("Enter a valid mail address")
      .required("Email id is required"),
    mfa: yup
      .string()
      .required('MFA Type is required'),

  });



  const successToast = () => toast.success("User added successfully", { toastId: 'Mail-Sent' });
  const successInvitationToast = () => toast.success("Invitation sent successfully", { toastId: 'Invitation-Resend' });
  const failureToast = () => toast.error("There was some error while sending the invitation, please try again.", { toastId: 'Error-Occured' });
  const alreadyExistToast = () => toast.error("Can't Invite as the phone/mail already exists.", { toastId: 'Error-409-Occured' });

  const formik = useFormik({
    initialValues:
      currentScreen === "vendor" ? {
        organizationName: '',
        website: '',
        firstName: '',
        lastName: '',
        phone: '',
        phone2: '',
        email: '',
        mfa: '',

      } : {
        firstName: '',
        lastName: '',
        phone: '',
        phone2: '',
        email: '',
        mfa: '',
      },
    validationSchema: currentScreen === "vendor" ? vendorValidationSchema : connectorValidationSchema,
    onSubmit: (values) => {
      const res = checkPhoneValidation();
      const isOrgNameValid = values.organizationName?.trim() !== ""
      if (res) {
        if ((currentScreen === "vendor" && isOrgNameValid) || currentScreen === "connector") {
          submitHandler(values);
        }
      }
    }
  });

  useEffect(() => {
    // Define the new validation schema based on currentScreen
    const newValidationSchema =
      currentScreen === 'vendor'
        ? vendorValidationSchema
        : connectorValidationSchema;

    // Set the new validation schema
    formik.setFieldError('organizationName', ''); // Clear any previous errors
    formik.setFieldTouched('organizationName', false); // Mark the field as untouched
    // Re-initialize the form when currentScreen changes
    formik.resetForm({
      values: currentScreen === "vendor"
        ? {
          organizationName: '',
          website: '',
          firstName: '',
          lastName: '',
          phone: '',
          phone2: '',
          email: '',
          mfa: '',
        }
        : {
          firstName: '',
          lastName: '',
          phone: '',
          phone2: '',
          email: '',
          mfa: '',
        },
    });
    formik.setValues(formik.values); // This can be used to trigger re-validation if needed
  }, [currentScreen]);

  const submitHandler = (values: any) => {
    if (!saveLoader) {
      setSaveLoader(true);

      if (currentScreen === 'connector') {
        dataobj = {
          connector: {
            name: values.firstName + ' ' + values.lastName,
            email: values.email,
            phoneNumber1: values.phone.replace("+", ""),
            phoneNumber2: values.phone2.replace("+", ""),
            createdBy: user?.name,
            modifiedBy: user?.name,
            phoneNumberVerified: false,
            emailVerified: false,
            legalDocsVerified: false,
            w9_verified: false,
            isApproved: "pending",
            addressVerified: isAddressValidated,
            address: formData.address.trim() === '' ? null : formData.address,
            cityName: formData.cityName.trim() === '' ? null : formData.cityName,
            countryName: formData.countryName.trim() === '' ? null : formData.countryName,
            stateName: formData.stateName.trim() === '' ? null : formData.stateName,
            street: formData.addressLine2.trim() === '' ? null : formData.addressLine2,
            zipcode: formData.zipcode.trim() === '' ? null : formData.zipcode
          },
          "mfa": values.mfa,
          "role": "connector"
        };
        var apiUrl = "admin/data/connector";
        axios.post(process.env.REACT_APP_BASE_URL + apiUrl, dataobj,
          {
            headers: {
              "Authorization": "Bearer " + user.accessToken,
            }
          })
          .catch((error) => {
            setShowDialog(false);
            setSaveLoader(false);
            if (error.response && error.response.status === 409) {
              alreadyExistToast();
            }
          })
          .then((response: any) => {
            setShowDialog(false);
            setSaveLoader(false);
            if (response.status === 201) {
              successToast();
            }
            else {
              alreadyExistToast();
            }
            handleClose();
          })
      }
      else {
        dataobj = {
          vendor: {
            name: values.organizationName,
            website: "https://" + values.website,
            email: values.email,
            addressVerified: isAddressValidated,
            phoneNumberVerified: false,
            emailVerified: false,
            productsServicesVerified: false,
            legalDocsVerified: false,
            w9_verified: false,
            phoneNumber1: values.phone.replace("+", ""),
            phoneNumber2: values.phone2.replace("+", ""),
            isApproved: "pending",
            adminName: values.firstName + " " + values.lastName,
            vendorAdminName: values.firstName + " " + values.lastName,
            vendorAdminEmail: values.email,
            address: formData.address.trim() === '' ? null : formData.address,
            cityName: formData.cityName.trim() === '' ? null : formData.cityName,
            countryName: formData.countryName.trim() === '' ? null : formData.countryName,
            stateName: formData.stateName.trim() === '' ? null : formData.stateName,
            street: formData.addressLine2.trim() === '' ? null : formData.addressLine2,
            zipcode: formData.zipcode.trim() === '' ? null : formData.zipcode,
            createdBy: user?.name,
            modifiedBy: user?.name,
          },
          role: "vendor"
        };

        axios.post(process.env.REACT_APP_BASE_URL + "register", dataobj,
          {
            headers: {
              "Authorization": "Bearer " + user.accessToken,
            }
          })
          .catch((error) => {
            setSaveLoader(false);
            if (error.response && error.response.status === 409) {
              alreadyExistToast();
            }
          })
          .then((response: any) => {
            setSaveLoader(false);
            if (response.status === 201) {

              var dataobjVendor = {};

              dataobjVendor = {
                contact: {
                  firstName: values.firstName,
                  lastName: values.lastName,
                  email: values.email,
                  phoneNumber1: values.phone.replace("+", ""),
                  phoneNumber2: values.phone2.replace("+", ""),
                  vendorId: { id: Number(response.data.id) },
                  phoneNumberVerified: false,
                  emailVerified: false,
                  isApproved: true,
                  createdBy: user?.name,
                  modifiedBy: user?.name,
                  addressVerified: false,
                  isAdmin: true
                },
                role: "vendor",
                "mfa": values.mfa,
              }
              var apiUrl = "admin/data/vendorAdmin";
              axios.post(process.env.REACT_APP_BASE_URL + apiUrl, dataobjVendor,
                {
                  headers: {
                    "Authorization": "Bearer " + user.accessToken,
                  }
                })
                .catch((error) => {
                  setShowDialog(false);
                  setSaveLoader(false);
                  if (error.response && error.response.status === 409) {
                    alreadyExistToast();
                  }
                })
                .then((response: any) => {
                  setShowDialog(false);
                  setSaveLoader(false);
                  if (response.status === 201) {
                    successToast();
                  }
                  else {
                    alreadyExistToast();
                  }
                  handleClose();
                })
              successToast();
            }
            else {
              alreadyExistToast();
            }
            handleClose();
          })
      }
    }
  }

  useEffect(() => {
    if (showDialog === false) {
      formik.resetForm();
    }
  }, [showDialog])
  const onClickAdd = () => {
    setPhone('');
    setShowDialog(true);


  }
  const handleClose = () => {
    setPhoneValidation({ error: false, message: "" })
    if (!saveLoader) {
      setShowDialog(false);


      setSaveLoader(false);
      formik.resetForm();
      formData.address = '';
      formData.addressLine2 = '';
      formData.cityName = '';
      formData.countryName = '';
      formData.phoneNumber1 = '';
      formData.stateName = '';
      formData.streetName = '';
      formData.zipcode = '';
      setIsAddressValidated(false);
    }
  }
  const handlePhoneChange = (value: any) => {
    formik.values.phone = value.replace(/[^0-9]/g, "");
    setPhone(value);
    if (phoneValidation.error) {
      checkPhoneValidation();
    }
  };
  const checkPhoneValidation = () => {
    if (phone !== "") {
      if (phone === "" || phone === "+" || phone === String("+1")) {
        setPhoneValidation({ error: true, message: "Please enter your phone number" })
        return false;
      }
      else if (phone.replace(/[^0-9]/g, "").length < 11 || phone.replace(/[^0-9]/g, "").length > 12) {
        setPhoneValidation({ error: true, message: "Please enter a valid phone number" })
        return false;
      }
      else {
        setPhoneValidation({ error: false, message: "" })
        return true;
      }
    }
    else {
      setPhoneValidation({ error: true, message: "Phone number is required" })
      return false;
    }
  }

  useEffect(() => {
    if (user && user.role === 'admin') {
      const pageUrl = window.location.href.split('/');
      const component = pageUrl.at(-1);
      if (component === 'vendor') {
        setCurrentScreen('vendor');
        setCurrentText(
          'Find here the vendors who wish to associate with you'
        );
        setHeading('Vendor Approval');
        setSetIndex(1);
      } else if (component === 'product') {
        HandleTabMenu(2);
        setSetIndex(2);
      }
    }
  }, [user]);

  const HandleTabMenu = (itemNo: any) => {
    if (user) {
      if (user.role === 'admin') {
        if (itemNo === 0) {
          setCurrentScreen('connector');
          setCurrentText(
            'Find here the connectors who wish to associate with you'
          );
          setHeading('Connector Approval');
        } else if (itemNo === 1) {
          setCurrentScreen('vendor');
          setCurrentText(
            'Find here the vendors who wish to associate with you'
          );
          setHeading('Vendor Approval');
        } else if (itemNo === 2) {
          setCurrentScreen('product');
          setCurrentText('Find here the list of Products');
          setHeading('Product Approval');
        }
      }
      if (user.role === 'vendor' || user.role === 'contact') {
        if (itemNo === 0) {
          setCurrentScreen('connector');
          setCurrentText(
            'Find here the connector who wish to associate with you'
          );
          setHeading('Connector Approval');
        }
      }
    }
  };


  return <>
    {editable ? (<EditComponent setEditable={setEditable} user={user} selectionType={selectionType} currentEditSelection={currentEditSelection} setCurrentEditSelection={setCurrentEditSelection} />) :
      (<Grid container /*spacing={1}*/>
        <ActivitiesHeader
          currentText={currentText}
          heading={heading}
          invisible={showDialog}
        />
        {user ? (
          <>
            {user?.role === "admin" && (
              <Grid item xs={12}>
                {showDialog && (
                  <AddConnectorVendorForm
                    showDialog={showDialog}
                    handleClose={handleClose}
                    setSaveLoader={setSaveLoader}
                    saveLoader={saveLoader}
                    formik={formik}
                    userType={currentScreen}
                    phone={phone}
                    setPhone={setPhone}
                    phoneValidation={phoneValidation}
                    setPhoneValidation={setPhoneValidation}
                    checkPhoneValidation={checkPhoneValidation}
                    handlePhoneChange={handlePhoneChange}
                    isAddressValidated={isAddressValidated}
                    setIsAddressValidated={setIsAddressValidated}
                    formData={formData}
                    setFormData={setFormData}
                  />
                )}
                {!showDialog && (
                  <>
                    <Box
                      sx={{
                        display: {
                          xs: "none",
                          sm: "none",
                          md: "flex",
                          lg: "flex",
                          xl: "flex",
                        },
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <TabMenu
                        tabMenu={HandleTabMenu}
                        item={["Connector", "Vendor", "Product"]}
                        badgeContent={[
                          pendingConnectors,
                          pendingVendors,
                          pendingProducts,
                        ]}
                        setIndex={setIndex}
                        type={"parent"}
                        setSetIndex={setSetIndex}
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={onClickAdd}
                        sx={{
                          display: "inline-flex",
                          alignItems: "center",
                          gap: 1,
                          whiteSpace: "nowrap",
                        }}
                        disabled={currentScreen === "product"}
                      >
                        <Add fontSize="small" />
                        Add
                      </Button>
                    </Box>

                    {currentScreen === "connector" && (
                      <ApproveConnectors
                        user={user}
                        setCurrentSelection={setCurrentSelection}
                        activityData={activityData}
                        setActivityData={setActivityData}
                        setGlobalData={setGlobalData}
                        error={error}
                        handleApprove={handleApprove}
                        approveRow={approveRow}
                        globalVal={globalData}
                        setPendingConnectors={setPendingConnectors}
                        setCurrentEditSelection={setCurrentEditSelection}
                        setSelectionType={setSelectionType}
                        setEditablePage={setEditable}
                      />
                    )}
                    {currentScreen === "vendor" && (
                      <ApproveVendors
                        user={user}
                        setCurrentSelection={setCurrentSelection}
                        data={activityData}
                        setData={setActivityData}
                        globalVal={globalData}
                        setPendingVendors={setPendingVendors}
                        setCurrentEditSelection={setCurrentEditSelection}
                        setSelectionType={setSelectionType}
                        setEditablePage={setEditable}
                      />
                    )}
                    {currentScreen === "product" && (
                      <ApproveProduct
                        user={user}
                        setPendingProducts={setPendingProducts}
                        currentSelection={currentSelection}
                        setCurrentSelection={setCurrentSelection}
                      />
                    )}
                  </>
                )}
              </Grid>
            )}

            {(user.role === "vendor" || user.role === "contact") && (
              <>
                {error ? (
                  <>
                    <Grid
                      item
                      xs={12}
                      sx={{ textAlign: "center", marginTop: 10 }}
                    >
                      <ErrorComponent />
                    </Grid>
                  </>
                ) : (
                  <>
                    {globalData ? (
                      <>
                        {globalData.length === 0 ? (
                          <Grid
                            item
                            xs={12}
                            sx={{ textAlign: "center", marginTop: 2 }}
                          >
                            <img
                              src="assets/loader/not_found.gif"
                              height={"140vh"}
                            />
                            <Typography sx={{ fontWeight: "bold" }}>
                              No registrations found!
                            </Typography>
                            <Typography>
                              Seems like you don't have any registrations yet.
                              <br />
                            </Typography>
                          </Grid>
                        ) : (
                          <>
                            {/* <Grid item xs={12}>
                  <TabMenu tabMenu={HandleTabMenu} item={["Connector"]} badgeContent={[1, 0, 0]} />
                </Grid> */}
                            <Grid item xs={12}>
                              {currentScreen === "connector" && (
                                <ApproveConnectors
                                  user={user}
                                  activityData={activityData}
                                  setActivityData={setActivityData}
                                  setGlobalData={setGlobalData}
                                  error={error}
                                  handleApprove={handleApprove}
                                  approveRow={approveRow}
                                  globalVal={globalData}
                                  setPendingConnectors={setPendingConnectors}
                                />
                              )}
                            </Grid>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <Grid
                          item
                          xs={12}
                          sx={{ textAlign: "center", marginTop: 10 }}
                        >
                          <img
                            src="assets/loader/Spinner-px.gif"
                            height={"140vh"}
                          />
                          <Typography sx={{ paddingTop: 2 }}>
                            Please wait while we fetch all data
                          </Typography>
                        </Grid>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <Grid item xs={12} sx={{ textAlign: "center", marginTop: 10 }}>
              <img src="assets/loader/Spinner-px.gif" height={"140vh"} />
              <Typography sx={{ paddingTop: 2 }}>
                Please wait while we fetch all data
              </Typography>
            </Grid>
          </>
        )}
      </Grid>)
    }
  </>;

};

export default Approvals;
