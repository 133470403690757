import {
    Alert,
    AlertTitle,
    Autocomplete,
    Box,
    Button,
    Card,
    Dialog,
    DialogContent,
    Divider,
    Grid,
    TextField,
    Tooltip,
    Typography,
    DialogActions,
    Modal,
    DialogTitle
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useRedirect } from 'react-admin';
import SaveIcon from '@mui/icons-material/Save';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import Connector from './RegistrationFormScreens/Connector';
import Product from './RegistrationFormScreens/Product';
import Customer from './RegistrationFormScreens/Customer';
import InputAdornment from "@mui/material/InputAdornment";
import NumberFormatCustom from './NumberFormatCustom';
import FormLoader from './FormLoader';
import DealTextFields from './DealTextFields';
import { useFormik } from 'formik';
import * as yup from 'yup';

export const DealCreate = ({ open, user }: { open: boolean, user:any }) => {
    const [connectorList, setConnectorList] = useState<any>();
    const [vendor, setVendor] = useState<any>();
    const [customerList, setCustomerList] = useState<any>();
    const [disableCustomer, setDisableCustomer] = useState(true)
    const [currentProduct, setCurrentProduct] = useState<any>();
    const [currentCustomer, setCurrentCustomer] = useState<any>();
    const [currentConnector, setCurrentConnector] = useState<any>()
    const [cardProduct, setCardProduct] = useState(false);
    const [cardCustomer, setCardCustomer] = useState(false);
    const [cardConnector, setCardConnector] = useState(false);
    const [productList, setProductList] = useState<any>();
    const [disableProduct, setDisableProduct] = useState(true)
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [saveLoader, setSaveLoader] = useState(false);
    const tags = [
        { id: 0, name: 'devices' },
        { id: 1, name: 'telehealth' },
        { id: 2, name: 'medicines' },
        { id: 3, name: 'radiology' },
        { id: 4, name: 'physician' },
        { id: 5, name: 'cardiology' }
    ];

    const defaultData = {
        name: '',
        type: '',
        stage: 'introduction',
        description: '',
        amount: 0,
        endAmount: 0,
        createdBy: user?.name,
        modifiedBy: user?.name,
        isApproved: false,
        connectorId: null,
        vendorId: {id:0},
        productId:null,
        customerId: null,
    };
    const [formData, setFormData] = useState(defaultData);
    const style = { padding: 1, marginBottom: 1, boxShadow: 0, paddingX: 3, paddingTop: 2 };
    const [error, setError] = useState(false);
    const redirect = useRedirect();
    const [formLoader, setFormLoader] = useState(true);
    useEffect(() => {
        if(vendor && connectorList && productList){
            setFormLoader(false)
        }
    },[vendor,connectorList,productList])
    const[amountValidation, setAmountValidation] = useState({error: false, message:""})
    const handleClose = () => {
        setFormData(defaultData);
        redirect('/admin/deals');
        setCardConnector(false);
        setCardCustomer(false);
        setCardProduct(false);
        setDisableCustomer(true);
        setDisableSubmit(true);
        setDisableProduct(true);
        formik.resetForm();
    };

    useEffect(() => {
        if (user?.role === "vendor") {
            axios.get(process.env.REACT_APP_BASE_URL + "contact/mail/" + user?.email,
            {
              headers: {
                  "Authorization": "Bearer "+user.accessToken,
              }
            })
                .catch((error) => setError(true))
                .then((response: any) => {
                    setVendor(response.data.vendorId)
                })
        }
    }, [user])

    useEffect(() => {
        if (vendor) {
            axios.get(process.env.REACT_APP_BASE_URL + "registration/connectorbyvendor/" + vendor.id,
            {
              headers: {
                  "Authorization": "Bearer "+user.accessToken,
              }
            })
                .catch((error: any) => {
                    
                    setError(true)
                    setFormLoader(false)
                })
                .then((response: any) => {
                    
                    setConnectorList(response.data)
                })
            axios.get(process.env.REACT_APP_BASE_URL + "product/form/" + vendor.id,
            {
              headers: {
                  "Authorization": "Bearer "+user.accessToken,
              }
            })
                .catch(
                    (error: any) => 
                    {}
                )
                .then((response: any) => {
                    setProductList(response.data)
                })
        }
    }, [vendor])

    const validationSchema = yup.object({
        dealName: yup
            .string()
            .min(4,"Name should contain 4 characters at least")
            .matches(/^[A-Za-z0-9 -]*$/, 'Please enter valid name, can contain "-" and numbers only')
            .required('Name is required'),
        description: yup
            .string()
            .min(10, "Description must be 10 characters long")
            .max(200, "Description shouldn't exceed 200 characters")
      });

      const formik = useFormik({
        initialValues: {
            dealName: '',
            description:''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleSave(values)
        }
      });

    const handleChange = (event: any, newVal: any) => {
        let ind = event.target.id.indexOf('-');
        let key = event.target.id.substring(0, ind);
        
        if (key === 'connectorId') {
            setFormData({ ...formData, [key]: { id: newVal.id }, vendorId:{id: vendor.id}, createdBy:vendor.name, modifiedBy:vendor.name });
            setCardConnector(true)
            setCurrentConnector(newVal)
            setDisableProduct(false)
        }
        else if (key === 'productId') {
            setFormData({ ...formData, [key]: { id: newVal.id } });
            setFormLoader(true)
            setCardProduct(true)
            setCurrentProduct(newVal)
            axios.get(process.env.REACT_APP_BASE_URL + "registration/form/" + newVal.id + "/" + currentConnector.id,
            {
              headers: {
                  "Authorization": "Bearer "+user.accessToken,
              }
            })
                .catch((error: any) => {
                    
                    setFormLoader(false)
                    setCustomerList([]);
                    setDisableCustomer(true);
                })
                .then((response: any) => {
                    
                    setCustomerList(response.data)
                    setFormLoader(false)
                    setDisableCustomer(false)
                    
                })
        }
        else if (key === 'customerId') {
            setFormData({ ...formData, name: "Deal-" + newVal.name + "-" + currentProduct.name, [key]: { id: newVal.id } })
            formik.setValues({...formik.values, dealName:"Deal-" + newVal.name + "-" + currentProduct.name, [key]: { id: newVal.id }})
            setCardCustomer(true)
            setCurrentCustomer(newVal)
            setDisableSubmit(false)
        }
        
    };

    const handleTag = (event: any, newVal: any) => {
        setFormData({ ...formData, type: newVal.name })
        
    }

    useEffect(() => {
        setFormData({...formData, name: formik.values.dealName, description: formik.values.description})
    },[formik.values.dealName, formik.values.description])

    const handleSave = (values:any) => {
        if(amountValidation.error || formData.amount === 0){
            setAmountValidation({...amountValidation, error: true, message: "A valid amount is required"})
        }
        else{
            if(!saveLoader){
                setSaveLoader(true)
                axios.post(process.env.REACT_APP_BASE_URL + 'deals/register', formData,
                {
                  headers: {
                      "Authorization": "Bearer "+user.accessToken,
                  }
                })
                .catch((error: any) => {
                    
                    alert("There was some error in registering the deal, please try again")
                })
                .then((response: any) => {
                    
                    handleClose();
                    setSaveLoader(false)
                    window.location.reload()
                });
            }
        }
    };

    const inputHandler = (e: any) => {
        setFormData({ ...formData, [e.target.id]: e.target.value })
    }

    const amountHandler = (e: any) => {
        setFormData({ ...formData, amount: Number(e.target.value) })
        if(Number(e.target.value) <1){
            setAmountValidation({...amountValidation, error: true, message: "The deal amount must be greater than 0"})
        }
        else if(Number(e.target.value) > 10000000){
            setAmountValidation({...amountValidation, error: true, message: "The deal amount cannot be greater than 10 Millions"})
        }
        else{
            setAmountValidation({...amountValidation, error: false, message: ""})
        }
    }

    const [selectedOption, setSelectedOption] = useState(null);
    const getOptions = (option: any) => {
        if (option) {
            return option.name;
        }
        return selectedOption || '';
    };
    const infoCompponent = () => {
        return (<>
            <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                Please follow the following steps to get started with the vendor
                application process:
            </Typography>
            <br />
            <Typography sx={{ fontSize: 14, color: '', textShadow: 3 }}>
                1. Select a Connector from the dropdown.
                <br />
                2.Select a product after selecting a connector.
                <br />
                3.Select a customer, this product will be for.
                <br />
                4.Review and ensure the Connector, Product and Customer details are as intended.
                <br />
                5.Click on save to start the new deal.
            </Typography>
        </>)
    }
    
    const asterisk = () => {
        return(<Typography sx={{color:"#00BFFF", display:"inline", fontSize:"20px", fontWeight:"bold"}}>*</Typography>)
    }
    return (
        <form onSubmit={formik.handleSubmit}>
        <Dialog open={open}
            fullWidth
            maxWidth="lg"
            sx={{
                '.MuiDialog-paper': {
                    position: 'absolute',
                    width: '90%',
                    height: '700px',
                    minWidth: "90%"
                },
            }}>
            <DialogTitle sx={{padding:0, backgroundColor:"#e5f6fd"}}>
                    <Grid container sx={{padding:2}}>
                        <Grid item xs={11.8} sx={{textAlign:"center"}}>
                            <Typography variant="h4" align="center" sx={{marginTop:1, fontWeight: 'bold',width: "100%"}}>
                                New Deal
                                <Tooltip
                                    title={infoCompponent()}
                                    arrow
                                    placement="right-end"
                                >
                                    <Button>
                                        <InfoOutlinedIcon />
                                    </Button>
                                </Tooltip>
                            </Typography>
                        </Grid>
                        <Grid item xs={0.2} sx={{alignText:"end", justifyContent:"right"}}>
                            <Box display="flex"><CloseIcon sx={{marginTop:1, fontWeight:"bold", color:"red", "&:hover":{cursor:"pointer"}}} onClick={handleClose}/></Box>
                        </Grid>
                    </Grid>
                </DialogTitle>
            <DialogContent>
                <>
                {formLoader?<>
                    <FormLoader/>
                </>:<></>}
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography sx={{ fontWeight: 'bold', fontSize: "20px" }}>
                                    Start a new deal as
                                </Typography>
                            </Grid>
                            {vendor ? <>
                                <Grid item xs={6}>
                                    <Card sx={{ padding: 1, boxShadow: 0, backgroundColor: "#F0F0F0" }}><Typography variant="subtitle2">{vendor.name}</Typography></Card>
                                </Grid>
                            </> : <>
                                {/* <FormLoader type={"vendor"}/> */}
                            </>}
                            <Grid item xs={12} sx={{ marginY: 2 }}>
                                <Divider />
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <Grid container>
                                            <Grid item xs={12} >
                                                <Typography sx={{ fontWeight: 'bold', fontSize: "20px" }}>
                                                    Select Connector {asterisk()}
                                                </Typography>

                                            </Grid>
                                            {error ?
                                                <>
                                                    <Grid item xs={12} sx={{ textAlign: "center", marginTop: 1 }}>
                                                        <Typography>You have not approved any registrations.</Typography>
                                                    </Grid>
                                                </>
                                                :
                                                <>
                                                    <Grid item xs={12}>
                                                        <Typography variant="caption">
                                                            Select a connector to associate with the
                                                            deal
                                                        </Typography>
                                                        <Autocomplete
                                                            options={connectorList ? connectorList : ['']}
                                                            getOptionLabel={getOptions}
                                                            id="connectorId"
                                                            onChange={handleChange}
                                                            renderInput={params => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Connector"
                                                                    type="text"
                                                                    fullWidth
                                                                    variant="outlined"
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                </>}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <Card
                                            sx={{ width: '100%', borderRadius: 4, boxShadow: 0 }}
                                        >
                                            <Box sx={style}>
                                                <Connector currentSelection={currentConnector} cardConnector={cardConnector} />
                                            </Box>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sx={{ marginBottom: 2 }}>
                                <Divider />
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <Grid container>
                                            <Grid item xs={12} sx={{ marginTop: 1 }}>
                                                <Typography sx={{ fontWeight: 'bold', fontSize: "20px" }}>
                                                    Select Product {asterisk()}
                                                </Typography>
                                            </Grid>
                                            {error ? <>
                                                <Grid item xs={12} sx={{ textAlign: "center", marginTop: 1 }}>
                                                    <Typography>You have not approved any registrations.</Typography>
                                                </Grid>
                                            </>
                                                :
                                                <>
                                                    <Grid item xs={12}>
                                                        {disableProduct?<>
                                                        <Typography variant="caption">
                                                            Select a connector first to get a list of products
                                                        </Typography>
                                                        </>
                                                        :
                                                        <>
                                                        <Typography variant="caption">
                                                            Select a product to associate with the
                                                            deal
                                                        </Typography>
                                                        </>}
                                                        <Autocomplete
                                                            options={productList ? productList : ['']}
                                                            getOptionLabel={getOptions}
                                                            id="productId"
                                                            onChange={handleChange}
                                                            disabled={disableProduct}
                                                            renderInput={params => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Product"
                                                                    type="text"
                                                                    fullWidth
                                                                    variant="outlined"
                                                                />
                                                                )}
                                                            />
                                                    </Grid>
                                                </>}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <Card
                                            sx={{ width: '100%', borderRadius: 4, boxShadow: 0 }}
                                        >
                                            <Box sx={style}>
                                                <Product currentSelection={currentProduct} cardProduct={cardProduct} />
                                            </Box>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sx={{ marginBottom: 2 }}>
                                <Divider />
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography sx={{ fontWeight: 'bold', fontSize: "20px" }}>
                                                    Select Customer {asterisk()}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {error ? <>
                                                    <Grid item xs={12} sx={{ textAlign: "center", marginTop: 1 }}>
                                                        <Typography>You have not approved any registrations.</Typography>
                                                    </Grid>
                                                </>
                                                    :
                                                    <>
                                                        {disableCustomer?<>
                                                        <Typography variant="caption">
                                                            Select a product first to get a list of customers
                                                        </Typography>
                                                        </>:
                                                        <>
                                                        <Typography variant="caption">
                                                            Select a customer to associate with the
                                                            deal
                                                        </Typography>
                                                        </>}
                                                        <Autocomplete
                                                            options={customerList ? customerList : ['']}
                                                            getOptionLabel={getOptions}
                                                            id="customerId"
                                                            onChange={handleChange}
                                                            disabled={disableCustomer}
                                                            renderInput={params => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Customer"
                                                                    type="text"
                                                                    fullWidth
                                                                    variant="outlined"
                                                                />
                                                            )}
                                                        />
                                                    </>}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <Card
                                            sx={{ width: '100%', borderRadius: 4, boxShadow: 0 }}
                                        >
                                            <Box sx={style}>
                                                <Customer currentSelection={currentCustomer} cardCustomer={cardCustomer} />
                                            </Box>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sx={{ marginBottom: 2 }}>
                                <Divider />
                            </Grid>
                            <DealTextFields tags={tags} handleTag={handleTag} getOptions={getOptions} formData={formData} inputHandler={inputHandler} amountHandler={amountHandler} formik={formik} amountValidation={amountValidation} asterisk={asterisk} />
                        </Grid>
                    </Grid>
                </Grid>
                </>
            </DialogContent>
            <DialogActions sx={{ paddingRight: 5, paddingY: 2 }}>
                <Button
                    onClick={handleClose}
                    variant="contained"
                    color="error"
                    sx={{ width: 110, height:50, padding: 1 }}
                    disabled={saveLoader}
                >
                    cancel
                </Button>
                <Button type="submit" onClick={(e) => formik.handleSubmit()} sx={{ width: 110, height:50, padding: 1 }} variant="contained" disabled={disableSubmit}>
                    {saveLoader?<><CircularProgress sx={{color:"white"}}/></>:<>Save</>}
                </Button>
            </DialogActions>
        </Dialog>
        </form>
    );
};