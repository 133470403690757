import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';


const ErrorDialog = (props:any) => {
    const{showDialog, handleClose, type} = props;
    return (
      <Dialog open={showDialog} sx={{width:"100%", justifyContent:"center", height:"100%", backgroundColor:"transparent"}}>
          <DialogActions>
              <Grid container>
                  <Grid xs={12} sx={{textAlign:"right"}}>
                      <Button onClick={handleClose}><CloseIcon sx={{color:"red"}}/></Button>
                  </Grid>
              </Grid>
          </DialogActions>
          <DialogContent>
              <Grid container>
                  <Grid item xs={12} sx={{textAlign:"center", paddingX:6, marginBottom:6, backgroundColor:"transparent"}}>
                      <img src="assets/loader/warning-sign.png" height={"140vh"}/>
                      <Typography sx={{paddingTop:2}}>
                          {type === "organisation" && <>Your organization has already been registered on our platform<br/>Please contact your organizational admin to get more details</>}
                          {type === "contact" && <>A contact with the same credentials has already been registered.</>}
                      </Typography>
                  </Grid>
              </Grid>
          </DialogContent>
      </Dialog>
    )
}

export default ErrorDialog;