export const stakeHolders = [
    {
        name:"Andrei Soran",
        username:"andrei_soran",
        picture:"assets/stakeholders/andrei_soren.png",
        designation:"Chair",
        intro:"Andrei Soran is a seasoned healthcare executive with extensive CEO experience and a proven track record of orchestrating strategic initiatives, driving growth, executing turnarounds, and ensuring operational excellence.",
        para1:"Andrei Soran is a seasoned healthcare executive with extensive CEO experience and a proven track record of orchestrating strategic initiatives, driving growth, executing turnarounds, and ensuring operational excellence. His expertise spans private equity, public, and non-profit environments, where he has excelled as a collaborator, board member, and advocate for robust Medical Staff and physician relationships. His achievements include successful acquisitions, divestitures, and exits, complemented by his adeptness in financial restructuring and bankruptcy proceedings. In addition, Andrei brings valuable insights into managed care, risk partnerships, and value creation, all while championing innovation and technology facilitation.",
        para2:"Most recently, Andrei led Pipeline Health through a successful in-court restructuring, overseeing hospital sales and operational turnarounds. As CEO of TridentCare, he drove financial improvements, including a significant EBITDA increase, while navigating debt restructuring during bankruptcy.",
        para3:"Andrei has held key positions at Verity Health System, Detroit Medical System, among other hospitals and healthcare organizations.",
        linkedIn:"https://www.linkedin.com/in/asoran",
    },
    {
        name:"Kevin Torf",
        username:"kevin_torf",
        picture:"assets/stakeholders/kevin_torf.png",
        designation:"Advisor",
        intro:"Kevin Torf is a seasoned information systems executive specializing in large-scale IT strategic plans, project design, procurement, and implementation.  With a career spanning over 40 years, Kevin has successfully led complex IT infrastructure deployments worldwide.",
        para1:"Kevin Torf is a seasoned information systems executive specializing in large-scale IT strategic plans, project design, procurement, and implementation. With a career spanning over 40 years, Kevin has successfully led complex IT infrastructure deployments worldwide, including some of the largest communication network systems.",
        para2:"As co-founder and managing partner of T2 Group, Kevin introduced the company's hybrid-Agile methodology, guiding program and project managers in planning and executing client projects. He also plays a pivotal role as a chief strategist and advisor for leading hospital systems in the United States.",
        para3:"Prior to co-founding T2, Kevin founded and sold several technology companies including Intelliverse, Mobile Gaming Technologies, Inuntius, Tornado Development, and Torsys. He has also held senior roles as CEO, CTO, and CIO and other board positions. ",
        para4:"In his recent book, Getting the Job Done, Kevin unveils his hybrid-agile approach — dissecting effective project management tips to ensure punctual, high-quality outcomes.",
        linkedIn:"https://www.linkedin.com/in/kevintorf",
    },
    {
        name:"Traci Bowen",
        username:"traci_bowen",
        picture:"assets/stakeholders/traci_bowen.png",
        designation:"Advisor",
        intro:"Traci Bowen is an accomplished and seasoned leader in the field of Human Resources and Human Capital Management and brings a strategic and methodical approach to the development and scaling of the HR function.",
        para1:"Traci Bowen is an accomplished and seasoned leader in the field of Human Resources and Human Capital Management and brings a strategic and methodical approach to the development and scaling of the HR function. With a wealth of experience spanning diverse industries, Traci excels in planning and executing strategies that drive efficiency and enhance performance across organizations.",
        para2:"As the CEO of BHR Consulting, an innovative HR practice tailored for growth-oriented companies and small businesses, she has steered companies, including Encoda Systems, Adeptus Health, and TridentUSA Health Services, through successful IPOs and corporate transformations.",
        para3:"Traci has also held significant roles in notable organizations such as Pipeline Health System, SomaLogic, Trident USA Health Services, Ob Hospitalist Group, and Adeptus Health. As a Board Member of C5 Youth Foundation of Texas, she contributes to inspiring high-potential youth for leadership roles in college, work, and communities.",
        linkedIn:"https://www.linkedin.com/in/traci-bowen-sphr-shrm-scp-4145371",
    },
    {
        name:"Robert Frank",
        username:"robert_frank",
        picture:"assets/stakeholders/robert_frank.png",
        designation:"Advisor",
        intro:"Robert Frank is a board-certified cardiothoracic surgeon, health care executive and entrepreneur with over 25 years of broad experience encompassing clinical practice, hospital leadership, academic medicine, medical group leadership and health plan management.",
        para1:"Robert Frank is a board-certified cardiothoracic surgeon, health care executive and entrepreneur with over 25 years of broad experience encompassing clinical practice, hospital leadership, academic medicine, medical group leadership and health plan management. He is the founder of a med tech company and has served as Chief Medical Officer at multiple med-tech start-ups.",
        para2:"At Pipeline Health, as Corporate Chief Medical Officer, Robert led the financial turnaround of a seven-hospital multistate health system, skillfully guiding the company through Chapter 7 bankruptcy to achieve emergence in just four months.",
        para3:"He also served as Corporate Chief Medical Officer and EVP at Meridian Health Plan, managing the largest Medicaid health plan in Michigan and Illinois. Furthermore, he held the positions of CEO and Chief Medical Officer of the Wayne State University Physician Group, while concurrently acting as Vice Dean of Clinical Affairs at the Wayne State School of Medicine.",
        linkedIn:"https://www.linkedin.com/in/robertfrankmd",
    },
    {
        name:"Bennett Malbon",
        username:"bennett_malbon",
        picture:"assets/stakeholders/bennett_malbon.png",
        designation:"Advisor",
        intro:"Bennett is a seasoned healthcare and life science technology executive and entrepreneur.  He has a track record of successfully implementing product and technology programs in large and small organizations, guiding new ventures from concept to profit, and has multiple exits to Fortune 100 companies.",
        para1:"Bennett is a seasoned healthcare and life science technology executive and entrepreneur.  He has a track record of successfully implementing product and technology programs in large and small organizations, guiding new ventures from concept to profit, and has multiple exits to Fortune 100 companies.",
        para2:"Bennett has served as CTO at Novaseek Research where he helped conceptualize and create a research biobanking program by integrating a network of acute care laboratories and EMR data to algorithmically locate subjects and biospecimens that meet study criteria.  This program has served most of the top 10 pharma and many small drug and diagnostic developers.",
        para3:"Prior to Novaseek, Bennett was the founding technologist and co-inventor at Curaspan (acquired by Cardinal Health) where he helped build a fully integrated network of 600 acute care hospitals and 8000 post-acute providers handling 20% of care transitions in the US.  Bennett launched multiple complimentary new product lines within Curaspan, and helped grow revenue to $40M prior to acquisition.",
        para4:"He has held a variety of senior product, technology, and management consulting roles including developing AI for NASA and DARPA, building logistics programs for the Naval Surface Warfare Center, guiding technical product management for PatientKeeper (acquired by HCA), and consulting on multiple PE backed healthcare turn-arounds.  Bennett holds a BS in Computer Science from Randolph-Macon College, and an MBA from Babson.",
        linkedIn:"https://www.linkedin.com/in/bennett-malbon-2261bb2",
    },
    {
        name:"Anthony J. Tedeschi",
        username:"anthony_tedeschi",
        picture:"assets/stakeholders/anthony_tedeschi.png",
        designation:"Advisor",
        intro:"Dr. Anthony Tedeschi is a senior-level physician executive with more than 30 years of operational and clinical healthcare experience. ",
        para1:"Dr. Anthony Tedeschi is a senior-level physician executive with more than 30 years of operational and clinical healthcare experience. His career spans diverse leadership roles, including co-founding a successful consulting firm focused on organizational turnarounds, and serving as CEO for Vanguard Health Systems and Tenet Healthcare. He retired as CEO of Tenet Healthcare's Detroit/Michigan Market in 2020. ",
        para2:"Dr. Tedeschi served with Pipeline Healthcare as a senior advisor and interim CEO/market, driving strategic repositioning and successfully divesting two Chicago hospitals. As Chief Medical and Operating Officer at Medtel, he enhances surgical outcomes through tech-enabled services. Additionally, Dr. Tedeschi holds advisory roles at Guild and Resilient Health, while also serving as Board Chair for Founders Trust and Board/Executive Committee member at Knox College.",
        para3:"Board-certified in family medicine, Dr. Tedeschi earned his medical degree from the University of Illinois College of Medicine, along with master’s degrees in business administration from Northwestern University and public health from the Medical College of Wisconsin. His impactful leadership is acknowledged through awards like Modern Healthcare’s 50 Most Influential Clinical Executives, and Becker’s Hospital Review Top Physician Leader, among many others.",
        linkedIn:"https://www.linkedin.com/in/tony-tedeschi-md",
    }
]