import { BottomNavigation, BottomNavigationAction, Box, Button, Grid, Paper, Slide } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AdminSidebar from './AdminSidebar'
import LegalDocs from '../legalDocs/LegalDocs';
import Products from '../products/Products';
import Approvals from './Approvals/Approvals';
import { useAuth0 } from '@auth0/auth0-react'
import VendorProducts from './VendorProducts';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import VendorContacts from './VendorsContacts/VendorContacts';
import Invitations from './Invitations/Invitations';
import OfflinePinIcon from '@mui/icons-material/OfflinePin';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import EmailIcon from '@mui/icons-material/Email';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import WidgetsIcon from '@mui/icons-material/Widgets';

const Activities = (props: any) => {
  const { user } = props;
  let role = user?.role;
  const [activityData, setActivityData] = useState<any>();
  const [globalData, setGlobalData] = useState<any>();
  const [error, setError] = useState(false);
  const [approveRow, setApproveRow] = useState(0);
  const [userInfo, setUserInfo] = useState<any>()
  const [currentScreen, setCurrentScreen] = useState("approvals")
  const [showOptions, setShowOptions] = useState(false);
  const [value, setValue] = useState<any>();
  const [menuType, setMenuType] = useState("");
  const successToast = () => toast.success("Mail sent successfully");
  const failureToast = () => toast.error("There was some error in sending the notification, please try again.");
  const [selectedIndex, setSelectedIndex] = useState(1);

  const handleApprove = (row: any, action: string) => {
    const initialState = row.status;
    setApproveRow(row.id);
    setCurrentComp(<Approvals user={user} activityData={activityData} setActivityData={setActivityData} setGlobalData={setGlobalData} error={error} handleApprove={handleApprove} approveRow={row.id} globalData={globalData} />)
    var temp = row;
    temp.status = action;
    axios.put(
      process.env.REACT_APP_BASE_URL + 'registration/' + row.id,
      row, {
      headers: {
        "Authorization": "Bearer " + user.accessToken,
      }
    }
    )
      .catch((error: any) => {
        failureToast();
        temp.status = initialState;
        setCurrentComp(<Approvals user={user} activityData={activityData} setActivityData={setActivityData} setGlobalData={setGlobalData} error={false} handleApprove={handleApprove} approveRow={0} globalData={globalData} />)
      })
      .then((response: any) => {
        if (response.data) {
          var tempData = [...activityData]
          const ind = tempData.findIndex(obj => obj.id === response.data.id);
          tempData[ind] = response.data;
          setActivityData(tempData);
          setGlobalData(tempData);
          setApproveRow(0);
          setCurrentComp(<Approvals user={user} activityData={activityData} setActivityData={setActivityData} setGlobalData={setGlobalData} error={error} handleApprove={handleApprove} approveRow={0} globalData={globalData} />)
          successToast();
        }
      });
  };

  useEffect(() => {
    if (user) {
      const pageUrl = window.location.href.split('/');
      const component = pageUrl.at(-1);
      if (user.role === "admin" && component === "invitations") {
        HandleMenuItemSelect(8);
        setSelectedIndex(8);
      }
      else if (user.role === "admin" && component === "legal_docs") {
        HandleMenuItemSelect(3);
        setSelectedIndex(3);
      }
      else if ((user.role === "vendor" || user.role === "contact") && component === "products") {
        HandleMenuItemSelect(6);
        setSelectedIndex(6);
      }
      else if ((user.role === "vendor" || user.role === "contact") && component === "contacts") {
        HandleMenuItemSelect(7);
        setSelectedIndex(7);
      }
    }
  }, [user])

  const [currentComp, setCurrentComp] = useState(<Approvals user={user} activityData={activityData} setActivityData={setActivityData} error={error} handleApprove={handleApprove} approveRow={approveRow} globalData={globalData} />);
  useEffect(() => {
    if (user) {
      if (user?.role === "vendor" || user.role === "contact") {
        axios.get(process.env.REACT_APP_BASE_URL + "contact/mail/" + user?.email,
          {
            headers: {
              "Authorization": "Bearer " + user.accessToken,
            }
          })
          .catch((error: any) => {
            setCurrentComp(<Approvals user={user} activityData={activityData} setActivityData={setActivityData} setGlobalData={setGlobalData} error={true} handleApprove={handleApprove} approveRow={approveRow} globalData={globalData} />)
            setCurrentScreen("approvals")
          })
          .then((response: any) => {
            setUserInfo(response.data.vendorId)
          })
      }
      if (user?.role === "admin") {
        setActivityData([])
        setGlobalData([])
      }
    }
  }, [user])
  useEffect(() => {
    if (userInfo) {
      axios
        .get(process.env.REACT_APP_BASE_URL + 'registration/form/vendor/' + userInfo.id,
          {
            headers: {
              "Authorization": "Bearer " + user.accessToken,
            }
          })
        .catch((error: any) => {
          setError(true);
          setCurrentComp(<Approvals user={user} activityData={activityData} setActivityData={setActivityData} setGlobalData={setGlobalData} error={true} handleApprove={handleApprove} approveRow={approveRow} globalData={globalData} />)
        })
        .then((response: any) => {
          setError(false);

          setActivityData(response.data);
          setGlobalData(response.data)
        });
    }
  }, [userInfo]);

  useEffect(() => {
    if (activityData && currentScreen === "approvals") {
      if (!globalData) {

        setGlobalData(activityData)
        setCurrentComp(<Approvals user={user} activityData={activityData} setActivityData={setActivityData} setGlobalData={setGlobalData} error={error} handleApprove={handleApprove} approveRow={approveRow} globalData={activityData} />)
      }
      setCurrentComp(<Approvals user={user} activityData={activityData} setActivityData={setActivityData} setGlobalData={setGlobalData} error={error} handleApprove={handleApprove} approveRow={approveRow} globalData={globalData} />)
    }
  }, [activityData, globalData])


  const HandleMenuItemSelect = (itemNo: any) => {
    if (itemNo === 1) {
      setCurrentComp(<Approvals user={user} activityData={activityData} setActivityData={setActivityData} setGlobalData={setGlobalData} error={error} handleApprove={handleApprove} approveRow={approveRow} globalData={globalData} />)
      setCurrentScreen("approvals");
    }
    else if (itemNo === 3) {
      setCurrentComp(<LegalDocs />)
      setCurrentScreen("legalDocs")
    }
    else if (itemNo === 4) {
      setCurrentComp(<Products />)
      setCurrentScreen("products")
    }
    else if (itemNo === 6) {
      setCurrentComp(<VendorProducts user={user} />)
      setCurrentScreen("vendorProducts")
    }
    else if (itemNo === 7) {
      setCurrentComp(<VendorContacts user={user} />)
      setCurrentScreen("vendorContacts")
    }
    else if (itemNo === 8) {
      setCurrentComp(<Invitations user={user} />)
      setCurrentScreen("invitations")
    }
  }

  const handleShowOptions = (type: any) => {
    setMenuType(type);
    setShowOptions(true);
  }

  const onSelectApproveType = (type: any) => {
    setCurrentComp(<Approvals user={user} activityData={activityData} setActivityData={setActivityData} setGlobalData={setGlobalData} error={error} handleApprove={handleApprove} approveRow={approveRow} globalData={globalData} screenType={type} />);
    setShowOptions(false);
  }

  const onSelectVendorApproval = () => {
    setCurrentComp(<Approvals user={user} activityData={activityData} setActivityData={setActivityData} setGlobalData={setGlobalData} error={error} handleApprove={handleApprove} approveRow={approveRow} globalData={globalData} screenType={"registration"} />);
  }

  const onSelectLegalDocs = (type: any) => {
    setCurrentComp(<LegalDocs screenType={type} />);
    setShowOptions(false);
  }

  const onSelectInvitation = (type: any) => {
    setCurrentComp(<Invitations user={user} screenType={type} />);
    setShowOptions(false);
  }

  const onClickCancel = () => {
    setShowOptions(false);
  }

  const onSelectProducts = () => {
    HandleMenuItemSelect(6);
    setShowOptions(false);
  }

  const onSelectContacts = () => {
    HandleMenuItemSelect(7);
    setShowOptions(false);
  }


  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Grid container spacing={1} sx={{ minWidth: "100%" }}>
        <Grid item xs={0} sm={0} md={2} lg={2} xl={2} sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block", xl: "block" } }}>
          <AdminSidebar role={role} menuItemSelect={HandleMenuItemSelect} selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} />
        </Grid>
        <Grid item xs={12} sm={12} md={10} lg={10} >
          {currentComp}
        </Grid>
      </Grid>
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, textAlign: "center", paddingTop: 1, boxShadow: 3, borderRadius: 3, backgroundColor: showOptions ? "#f0f1f4" : "white" }} elevation={5}>
        <Slide direction="up" in={showOptions} mountOnEnter unmountOnExit>
          <Box sx={{ margin: 3, padding: 1, textAlign: "left", transition: "height 5s", backgroundColor: "white", borderRadius: 3 }}>
            {menuType === "adminApprovals" &&
              <>
                <AccountCircleIcon /><Button onClick={() => onSelectApproveType("connectors")}>Connectors</Button><br />
                <AccountCircleIcon /><Button onClick={() => onSelectApproveType("vendors")}>Vendors</Button><br />
                <AccountCircleIcon /><Button onClick={() => onSelectApproveType("products")}>Products</Button><br />
              </>
            }
            {menuType === "legals" &&
              <>
                <AccountCircleIcon /><Button onClick={() => onSelectLegalDocs("connector")}>Connectors</Button><br />
                <AccountCircleIcon /><Button onClick={() => onSelectLegalDocs("vendor")}>Vendors</Button><br />
              </>
            }
            {menuType === "invitations" &&
              <>
                <AccountCircleIcon /><Button onClick={() => onSelectInvitation("connector")}>Connectors</Button><br />
                <AccountCircleIcon /><Button onClick={() => onSelectInvitation("vendor")}>Vendors</Button><br />
              </>
            }
            <ArrowDownwardIcon /><Button onClick={onClickCancel} sx={{ color: "red" }}>Cancel</Button>
          </Box>
        </Slide>
        <Slide direction="up" in={!showOptions} mountOnEnter unmountOnExit>
          <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none", xl: "none" }, alignText: "center", justifyContent: "center" }}
          >
            {user?.role === "admin" && <BottomNavigationAction onClick={() => handleShowOptions("adminApprovals")} label="Approvals" icon={<OfflinePinIcon />} />}
            {(user?.role === "vendor" || user?.role === "contact") && <BottomNavigationAction onClick={onSelectVendorApproval} label="Approvals" icon={<OfflinePinIcon />} />}
            {user?.role === "admin" && <BottomNavigationAction onClick={() => handleShowOptions("legals")} label="Legal Documents" icon={<PlagiarismIcon />} />}
            {user?.role === "admin" && <BottomNavigationAction onClick={() => handleShowOptions("invitations")} label="Invitations" icon={<EmailIcon />} />}
            {(user?.role === "vendor" || user?.role === "contact") && <BottomNavigationAction onClick={onSelectProducts} label="Products" icon={<WidgetsIcon />} />}
            {(user?.role === "vendor" || user?.role === "contact") && <BottomNavigationAction onClick={onSelectContacts} label="Contacts" icon={<ContactPageIcon />} />}
          </BottomNavigation>
        </Slide>
      </Paper>
    </>

  )
}

export default Activities