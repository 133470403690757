import { Deal } from '../types';
import { useState, useEffect } from 'react';
import { useGetList, Loading } from 'react-admin';
import {
    Autocomplete, TextField, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, IconButton,
} from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useRedirect } from 'react-admin';
import DealShowDialog from './DealShowDialog';
import { stageNames, stages } from './../deals/stages';
import SearchTextField from '../Layout/SearchTextField';
import FilterComponent from '../Layout/FilterComponent';
import { convertDate, convertCurrency, firstCapital } from '../Admin/Approvals/util';
import { Link } from 'react-router-dom';
import {linkCol, linkHover } from "../tableStyle";
import TableHeadRow from '../Layout/TableHeadRow';
import StatusCell from '../Layout/StatusCell';
import DealNotFound from '../deals/DealNotFound';
import { getComparator, sortedRowInformation } from '../utils/SortingHandler';
import ProfileScreen from '../Profile Screens/ProfileScreen';
import RegistrationCardComponent from '../deals/RegistrationCardComponent';

const AllDeals = (props: any) => {
    const { isLoading, error } = useGetList<Deal>('deals', {
        pagination: { page: 1, perPage: 50 },
    });

    const { dealData, empty, onRedirect, user } = props;
    const redirect = useRedirect();
    const [dealSearch, setDealSearch] = useState('');
    const [stageValue, setStageValue] = useState<any>(null);
    const [typeValue, setTypeValue] = useState<any>(null);
    const [rowData, setRowData] = useState<any>(dealData);
    // const [data,setData] = useState<any>(dealData)
    const [orderDirection, setOrderdirection] = useState<'asc' | 'desc'>('asc');
    const [typeVisible, setTypeVisible] = useState(false);
    const [stageVisible, setStageVisible] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [valueToOrderBy, setValueToOrderBy] = useState('updated_at');
    const open = Boolean(anchorEl);
    const [openDialog, setOpenDialog] = useState(false);
    const [rowShow, setRowShow] = useState({});
    const [gotData, setGotData] = useState(false)
    const [currentSelection, setCurrentSelection] = useState<any>()
    const [type, setType] = useState("");
    const [profileDialog, setProfileDialog] = useState(false);
    const showProfile = (row: any, profile: string) => {
        setType(profile)
        setCurrentSelection(row)
        setProfileDialog(true)
    }

    useEffect(() => {
        setRowData(dealData)
    }, [dealData])

    const handleDialogClose = () => {
        setOpenDialog(false);
    };
    const handleDialogClickOpen = (row: any) => {
        setRowShow(row);
        setOpenDialog(true);
    };

    const stageOptions = ['Opportunity','Contract Signed','Customer Introduction','Vendor Approved','Delayed','Lost','Proposal Sent'];
    const typeOptions = ['Devices','Telehealth','Medicines','Radiology','Physician','Cardiology'];

    useEffect(() => {
        if (dealData) {
            var tempdata = dealData!;
            var tempArr = [...tempdata];
            
            if (stageValue != null) {
                tempArr = tempArr.filter(obj => {
                    {/* @ts-ignore */ }
                    return (obj.stage === stageValue.toLowerCase());
                });
                
            }
            if (typeValue != null) {
                tempArr = tempArr.filter(obj => {
                    return (obj.type === typeValue.toLowerCase());
                });
                
            }
            tempArr = tempArr.filter(row => {
                return row.name
                    .toLowerCase()
                    .includes(
                        dealSearch ? String(dealSearch).trim().toLowerCase() : ''
                    );
            });
            
            setRowData(tempArr);
        }
    }, [stageValue, typeValue, dealSearch]);
    const createSortHandler = (property: any) => (event: any) => {
        const isAscending = orderDirection === 'asc';
        setValueToOrderBy(property);
        setOrderdirection(isAscending ? 'desc' : 'asc');
        sortedRowInformation(rowData, getComparator(orderDirection, property));
    };

    const handleTypeClose = () => {
        setTypeValue(null);
        setTypeVisible(false);
    };
    const handleStageClose = () => {
        setStageValue(null);
        setStageVisible(false);
    };

    if (isLoading) {
        return <Loading />;
    }
    if (error) {
        return <p>ERROR</p>;
    }

    const stageColor: any = {
        'proposal-sent': "#f7ebe4",
        opportunity: "#fff1e6",
        lost: "#faebec",
        won: "#f7e4eb",
        delayed: "#dff2f1",
        'in-negociation': '#dbe7e4',
        signed: "#f1e6ff"
    }

    const stageColorText: any = {
        'proposal-sent': "#fca06a",
        opportunity: "#e38740",
        lost: "#f56c76",
        won: "#db4b81",
        delayed: "#47bfba",
        'in-negociation': '#43bfa0',
        signed: "#6832a8"


    }

    const handleFilterOpt = (opt: any) => {
        setAnchorEl(null);
        if (opt === 'Type')
            setTypeVisible(true);
        else if (opt === 'Status')
            setStageVisible(true);
    };
    return (
        <>
            <ProfileScreen showDialog={profileDialog} currentSelection={currentSelection} type={type} setShowDialog={setProfileDialog} />
            {empty ?
                <>
                    <DealNotFound user={user} onRedirect={onRedirect} type={"deal"}/>
                </>
                :
                <>
                    <Grid container mb={2}>
                        <Grid item xs={10}>
                            <Grid container spacing={1}>
                            <Grid item><SearchTextField val={dealSearch} funcHandle={setDealSearch} type={"Deal"}/></Grid>
                            {typeVisible && <Grid item display={"flex"}><IconButton onClick={handleTypeClose}><CancelOutlinedIcon /></IconButton>
                                <Autocomplete
                                    id="type"
                                    options={typeOptions}
                                    sx={{'& .MuiInputBase-input' : {
                                        height: '1.5rem'
                                      } }}
                                    value={typeValue}
                                    onChange={(event, newValue: any) => {
                                        setTypeValue(newValue);
                                    }}
                                    
                                    renderInput={params => (
                                        <TextField {...params} inputProps={{
                                            ...params.inputProps,
                                            style: { paddingBottom: "7px", width: "100%" }
                                        }} placeholder="Type" fullWidth hiddenLabel />
                                    )}
                                /></Grid>}
                                {stageVisible && <Grid item display={"flex"}>
                                <IconButton onClick={handleStageClose}><CancelOutlinedIcon /></IconButton>
                                <Autocomplete
                                    id="status"
                                    options={stageOptions}
                                    sx={{ '& .MuiInputBase-input' : {
                                        height: '1.5rem'}}}
                                    value={stageValue}
                                    onChange={(event, newValue: any) => {
                                        
                                        setStageValue(newValue);
                                    }}
                                    renderInput={(params) => <TextField{...params} inputProps={{
                                        ...params.inputProps,
                                        style: { paddingBottom: "7px", width: "100%" }
                                    }} placeholder="Status" type="text" fullWidth hiddenLabel/>}
                                />
                            </Grid>}
                            </Grid>
                        </Grid>
                        {/* <Grid item xs={3} display='flex'>
                            
                        </Grid> */}
                        {/* <Grid item xs={3} display='flex'>
                            
                        </Grid> */}
                        <Grid item xs={2} sx={{ textAlign: "right", marginTop: 3 }}>
                            <FilterComponent anchorEl={anchorEl} open={open} setAnchorEl={setAnchorEl} options={['Type', 'Status']} handleFilterOpt={handleFilterOpt} />
                        </Grid>
                        <Grid item xs={12} sx={{display:{xs:"none", sm:"none", md:"block", lg:"block", xl:"block"}}}>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table" size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableHeadRow createSortHandler={createSortHandler} valueToOrderBy={valueToOrderBy} arr={[
                                                {
                                                    name: 'NAME',
                                                    alignment: 'left',
                                                    valueToOrderBy: 'name',
                                                    orderDirection: orderDirection
                                                },
                                                {
                                                    name: 'DESCRIPTION',
                                                    alignment: 'left',
                                                    valueToOrderBy: 'description',
                                                    orderDirection: orderDirection
                                                },
                                                {
                                                    name: 'TYPE',
                                                    alignment: 'left',
                                                    valueToOrderBy: 'type',
                                                    orderDirection: orderDirection
                                                },
                                                {
                                                    name: 'AMOUNT',
                                                    alignment: 'right',
                                                    valueToOrderBy: 'amount',
                                                    orderDirection: orderDirection
                                                },
                                                {
                                                    name: 'LAST MODIFIED',
                                                    alignment: 'left',
                                                    valueToOrderBy: 'modifiedDatetime',
                                                    orderDirection: orderDirection
                                                },
                                                {
                                                    name: 'STATUS',
                                                    alignment: 'center',
                                                    valueToOrderBy: false,
                                                }
                                            ]} />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rowData &&
                                            sortedRowInformation(
                                                rowData,
                                                getComparator(orderDirection, valueToOrderBy)
                                            ).map((row: any, index: any) => {
                                                return (
                                                    <TableRow key={row.id} >
                                                        <TableCell align="left" onClick={() => showProfile(row,"deal")} >
                                                            <Link to="/admin/deals" style={linkCol}><Typography sx={linkHover}>{row.name}</Typography></Link>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Typography sx={{ fontSize: "15px" }}>{firstCapital(row.description)}</Typography>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Typography sx={{ fontSize: "15px" }}>{firstCapital(row.type)}</Typography>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <Typography sx={{ fontSize: "15px" }}>{convertCurrency(row.amount)}</Typography>
                                                        </TableCell>
                                                        <TableCell align="left" style={{ whiteSpace: 'nowrap' }}>
                                                            <Typography sx={{ fontSize: "15px" }}>{row.modifiedDatetime ? convertDate(row.modifiedDatetime) : ""}</Typography>
                                                        </TableCell>
                                                        {/* @ts-ignore */}
                                                        <StatusCell textClr={stageColorText[row.stage]} bgClr={stageColor[row.stage]} text={stageNames[row.stage]} />
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={12} sx={{display:{xs:"block", xm:"block", md:"none", lg:"none", xl:"none"}}}>
                            <RegistrationCardComponent user={user} rowData={rowData} userType={"deal"} showProfile={showProfile}/>
                        </Grid>
                        {openDialog && <DealShowDialog open={openDialog} onClose={handleDialogClose} row={rowShow} />}
                    </Grid>
                </>}
        </>
    );
};

export default AllDeals;
