import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    TextField,
    Typography,
    Checkbox,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import SaveIcon from '@mui/icons-material/Save';
import {
    sortedRowInformation,
    getComparator,
} from '../../utils/SortingHandler';
import SearchTextField from '../../Layout/SearchTextField';
import FilterComponent from '../../Layout/FilterComponent';
import CancelIcon from '@mui/icons-material/Cancel';
import { convertDate, firstCapital } from './util';
import axios from 'axios';
import {
    linkCol,
    linkHover,
    statusColorText,
    statusColor,
} from '../../tableStyle';
import TableHeadRow from '../../Layout/TableHeadRow';
import StatusCell from '../../Layout/StatusCell';
import { useAuth0 } from '@auth0/auth0-react';
import UploadDoument from '../../legalDocs/UploadDoument';
import ProfileScreen from '../../Profile Screens/ProfileScreen';
import ErrorComponent from '../../Layout/ErrorComponent';
import ArticleIcon from '@mui/icons-material/Article';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Product from '../../deals/RegistrationFormScreens/Product';
import ShowSignedDoc from './ShowSignedDoc';
import RejectionForm from './RejectionForm';
import { ToastContainer, toast } from "react-toastify";
import TableCardComponent from './TableCardComponent';
import PaginationLoader from '../../Layout/PaginationLoader';
import PublishIcon from '@mui/icons-material/Publish';


const ApproveVendors = (props: any) => {
    const { setPendingVendors, user, setEditablePage, setCurrentEditSelection, setSelectionType
    } = props;
    const [rowData, setRowData] = useState<any>();
    const [globalData, setGlobalData] = useState<any>();
    const [vendorSearch, setVendorSearch] = useState('');
    const [orderDirection, setOrderdirection] = useState<'asc' | 'desc'>('asc');
    const [valueToOrderBy, setValueToOrderBy] = useState('appliedDate');
    const [anchorEl, setAnchorEl] = useState(null);
    const [statusValue, setStatusValue] = useState<any>(null);
    const [approveLoader, setApproveLoader] = useState(0);
    const [statusVisible, setStatusVisible] = useState(false);
    const [editable, setEditable] = useState(-1);
    const [editLoader, setEditLoader] = useState(false);
    const [file, setFile] = useState(null);
    const [showUpload, setShowUpload] = useState(true);
    const [show, setShow] = useState(false);
    const [uploadDialog, setUploadDialog] = useState(false);
    const [saveLoader, setSaveLoader] = useState(false);
    const [currentVendor, setCurrentVendor] = useState<any>();
    const [profileDialog, setProfileDialog] = useState<any>();
    const [currentSelection, setCurrentSelection] = useState<any>();
    const [error, setError] = useState(false);
    const [change, setChange] = useState(0);
    const [loader, setLoader] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const [emptyList, setEmptyList] = useState(false);
    const [showRejectionForm, setShowRejectionForm] = useState(false);
    const [rejectionType, setRejectionType] = useState("");
    const [initialState, setInitialState] = useState<any>();
    const [paginationLoader, setPaginationLoader] = useState(false);
    const [firstLoader, setFirstLoader] = useState(true);
    const successToast = () => toast.success("Mail sent successfully");
    const failureToast = () => toast.error("There was some error in sending the notification, please try again.");
    const successUpload = () => toast.success("Document uploaded successfully");
    const failureUpload = () => toast.error("There was some error uploading the file, please try again.");
    const [records, setRecords] = useState(5);
    const [documentStatus, setDocumentStatus] = useState(null);
    const showDocOptions = true;
    const [typeError, setTypeError] = useState(false);

    const handleChangePage = (event: any, newPage: any) => {
        if (!paginationLoader) {
            setPage(newPage);
        }
    };
    const handleChangeRowsPerPage = (event: any) => {
        if (!paginationLoader) {
            setRowsPerPage(+event.target.value);
            setPage(0);
        }
    };
    const [formdata, setFormData] = useState({
        id: 0,
        name: "",
        website: null,
        email: "",
        phoneNumber1: "",
        phoneNumber2: "",
        description: "",
        type: "",
        logo: null,
        startDate: "",
        address: null,
        phoneNumberVerified: false,
        emailVerified: false,
        productsServicesVerified: false,
        legalDocsVerified: false,
        w9_verified: false,
        isApproved: false,
        status: "",
        createdBy: "",
        createdDatetime: null,
        modifiedBy: "",
        modifiedDatetime: null,
        companyId: null,
        productCount: 0
    });
    const cancelEdit = (row: any) => {
        setFormData(row);
        setEditable(-1);
    };
    useEffect(() => {
        if (globalData) {
            var tempArr = [...globalData];
            if (statusValue != null && statusValue !== 'All') {
                tempArr = tempArr.filter(obj => {
                    return obj.isApproved === statusValue.toLowerCase();
                });
            }
            if (vendorSearch.trim().length > 0) {
                tempArr = tempArr.filter(row => {
                    return row.name.toLowerCase().includes(vendorSearch.trim().toLowerCase());
                });
            }
            setRowData(tempArr);
        }
    }, [vendorSearch, statusValue]);

    const open = Boolean(anchorEl);

    const checkApprove = (row: any) => {
        if (row.legalDocsVerified === true && row.phoneNumberVerified === true && row.emailVerified === true && row.addressVerified === true && row.productCount > 0 && row.productsServicesVerified === true) return false;
        return true;
    };

    const handleStatusClose = () => {
        setStatusValue(null);
        setStatusVisible(false);
    };

    const createSortHandler = (property: any) => (event: any) => {
        const isAscending = orderDirection === 'asc';
        setValueToOrderBy(property);
        setOrderdirection(isAscending ? 'desc' : 'asc');
        sortedRowInformation(rowData, getComparator(orderDirection, property));
    };

    const handleEdit = (row: any) => {
        setFormData(row);
        setEditable(row.id);
    };

    const onSave = () => {
        setEditLoader(true);
        axios.put(process.env.REACT_APP_BASE_URL + "vendor/" + formdata.id, formdata,
            {
                headers: {
                    "Authorization": "Bearer " + user.accessToken,
                }
            })
            .catch((error) => {

            })
            .then((response: any) => {

                var tempData = [...rowData]
                const ind = tempData.findIndex(obj => obj.id === response.data.id);
                tempData[ind] = response.data;
                setRowData(tempData);
                setGlobalData(tempData);
                setEditable(-1);
                setEditLoader(false)
            })
    };

    const handleFilterOpt = (opt: any) => {
        setAnchorEl(null);
        setStatusVisible(true);
    };

    const toggleHandler = (e: any, row: any) => {
        setFormData({
            ...row,
            [e.target.name]: e.target.checked,
        });
    };

    useEffect(() => {
        if (firstLoader) {
            setLoader(true);
        }
        if (user) {
            setPaginationLoader(true);
            axios.get(process.env.REACT_APP_BASE_URL + "vendor/pages",
                {
                    headers: {
                        "Authorization": "Bearer " + user.accessToken,
                    },
                    params: {
                        pageNo: page,
                        pageSize: rowsPerPage,
                        sortBy: "id",
                        sortDir: "desc"
                    }
                }
            )
                .catch((err: any) => {
                    setError(true);
                    setPaginationLoader(false);
                    setFirstLoader(false);
                    setLoader(false);
                })
                .then((response: any) => {
                    if (response.data.data.length === 0) {
                        setEmptyList(true);
                    }
                    setRecords(response.data.totalElements)
                    setRowData(response.data.data);
                    setGlobalData(response.data.data);
                    setPendingVendors(response.data.additionalData.pendingVendorCount)
                    setPaginationLoader(false);
                    setFirstLoader(false);
                    setLoader(false);
                })
        }
    }, [page, rowsPerPage, user, change])

    const onApprove = (row: any, action: string) => {
        setApproveLoader(row.id)
        const current = row.isApproved;
        row.isApproved = action;
        axios.put(process.env.REACT_APP_BASE_URL + "vendor/" + row.id, row,
            {
                headers: {
                    "Authorization": "Bearer " + user.accessToken,
                }
            })
            .catch((error: any) => {

                alert("Error in approving the vendor, please try again later")
                setApproveLoader(0)
                setChange(prevVal => prevVal + 1)
            })
            .then((response: any) => {

                var tempData = [...rowData]
                const ind = tempData.findIndex(obj => obj.id === response.data.id);
                tempData[ind] = response.data;
                setApproveLoader(0)
                setRowData(tempData);
                setGlobalData(tempData)
                if (current === "pending") {
                    setPendingVendors((prevVal: any) => prevVal - 1);
                }
            })
    }

    const onClickReject = (row: any, action: any) => {
        setOpenElem(null)
        setRejectionType(action);
        setShowRejectionForm(true);
        setFormData(row);
    }

    const onCancelReject = (row: any) => {
        setShowRejectionForm(false);
        if (rejectionType === "revoked") {
            resetRevoke()
        }
    }

    const handleRejection = () => {
        setApproveLoader(formdata.id);
        setShowRejectionForm(false);

        axios.put(process.env.REACT_APP_BASE_URL + "vendor/" + formdata.id, formdata,
            {
                headers: {
                    "Authorization": "Bearer " + user.accessToken,
                }
            })
            .catch((error: any) => {
                alert("Error in rejecting the vendor, please try again later")
                setApproveLoader(0)
                failureToast();
                if (rejectionType === "revoked") {
                    resetRevoke()
                }
            })
            .then((response: any) => {
                var tempData = [...rowData];
                const ind = tempData.findIndex(obj => obj.id === response.data.id);
                tempData[ind] = response.data;
                successToast();
                setApproveLoader(0)
                setRowData(tempData);
                setGlobalData(tempData);
            })
    }

    const onRevoke = (row: any) => {
        setInitialState(row);
        var temp = row;
        temp.isApproved = "revoked";
        temp.phoneNumberVerified = false;
        temp.emailVerified = false;
        temp.legalDocsVerified = false;
        temp.w9_verified = false;
        onClickReject(temp, "revoked");
    }

    const resetRevoke = () => {
        var temp = initialState;
        temp.phoneNumberVerified = true;
        temp.emailVerified = true;
        temp.legalDocsVerified = true;
        temp.addressVerified = true;
        temp.isApproved = "approved";
        var tempData = [...rowData]
        const ind = tempData.findIndex(obj => obj.id === temp.id);
        tempData[ind] = temp;
        setRowData(tempData);
        setGlobalData(tempData);
    }

    const handleFileChange = (file: any) => {
        setFile(file);

    };

    const toggleUpload = () => {
        setShowUpload(!showUpload);

        setShow(true);
    };

    const [numPages, setNumPages] = useState(null);

    const onDocumentLoadSuccess = (numPages: any) => {

        setNumPages(numPages);
    };

    const handleUpload = (row: any) => {
        setUploadDialog(true)
        setCurrentVendor(row)
    }

    const handleViewDetails = (row: any) => {
        setCurrentSelection(row);
        setProfileDialog('vendor');
    };

    const fileTypes = {
        'application/pdf': []
    };

    const handleClose = () => {
        setFile(null)
        setUploadDialog(false)
    }

    const handleSubmit = () => {
        setSaveLoader(true)
        const path = documentStatus === "signed" ? "document/register" : "document/admin/register/user";

        axios.post(process.env.REACT_APP_BASE_URL + path, { file },
            {
                headers: {
                    "Content-type": "multipart/form-data",
                    "Authorization": "Bearer " + user.accessToken
                },
                params: documentStatus === "signed" ? {
                    "userId": currentVendor.id,
                    "type": "vendor"
                } : {
                    "userId": currentVendor.id,
                    "documentType": "vendor"
                }
            })
            .catch((error) => {
                failureUpload();
                setSaveLoader(false);
            })
            .then((response: any) => {

                if (response) {
                    successUpload();
                    if (documentStatus === "signed") {
                        const tempArr = response.data.split(" ");
                        const userId = tempArr[tempArr.length - 2];
                        const fileName = tempArr[tempArr.length - 1];
                        var temp = {
                            "id": currentVendor.id,
                            "name": fileName,
                            "isApproved": "approved"
                        };

                        axios.put(process.env.REACT_APP_BASE_URL + 'document/' + userId, temp,
                            {
                                headers: {
                                    "Authorization": "Bearer " + user.accessToken,
                                }
                            })
                    }

                }

                setProfileDialog(false)
                setSaveLoader(false)
                handleClose()
            })

    }
    const showProfile = (row: any) => {
        setCurrentSelection(row);
        setProfileDialog(true)
    }
    const [openElem, setOpenElem] = React.useState(null);
    const [anchorEl2, setAnchorEl2] = React.useState(null);

    const handleMenuClick = (elem: any) => (event: any) => {
        setAnchorEl2(event.currentTarget);
        setOpenElem(elem);
    };

    const handleMenuClose = () => {
        setAnchorEl2(null);
        setOpenElem(null);
    };

    const [signedDocDialog, setSignedDocDialog] = useState(false);
    const [viewSignedDocLoader, setViewSignedDocLoader] = useState(false);
    const [errorViewDoc, setErrorViewDoc] = useState(false);
    const [viewDocData, setViewDocData] = useState<any>();
    const [empty, setEmpty] = useState(false);
    const [showPdf, setShowPdf] = useState(false);

    const handleCloseSignedDialog = () => {
        setSignedDocDialog(false);
        setViewDocData(null);
        setShowPdf(false);

    }

    const handleViewSignedDialog = (row: any) => {
        setSignedDocDialog(true);
        setViewSignedDocLoader(true);
        setError(false);
        setEmpty(false);
        axios.get(process.env.REACT_APP_BASE_URL + "document/vendor/" + row.id,
            {
                headers: {
                    "Authorization": "Bearer " + user.accessToken,
                }
            })
            .catch((error: any) => {

                setErrorViewDoc(true);
                setViewSignedDocLoader(false);
            })
            .then((response: any) => {

                setViewDocData(response.data)
                if (response.data.length === 0) {
                    setEmpty(true)
                }
                setViewSignedDocLoader(false);
            })
    }
    return (
        <>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <RejectionForm showDialog={showRejectionForm} handleClose={onCancelReject} type={"vendor"} formData={formdata} setFormData={setFormData} handleSubmit={handleRejection} rejectionType={rejectionType} saveLoader={saveLoader} />
            <ShowSignedDoc user={user} signedDocDialog={signedDocDialog} handleCloseSignedDialog={handleCloseSignedDialog} error={errorViewDoc} empty={empty} viewDocData={viewDocData} setViewDocData={setViewDocData} showPdf={showPdf} setShowPdf={setShowPdf} setSignedDocDialog={setSignedDocDialog} />
            <ProfileScreen showDialog={profileDialog} currentSelection={currentSelection} type={"vendor"} setShowDialog={setProfileDialog} />
            <UploadDoument
                showDialog={uploadDialog}
                file={file}
                handleFileChange={handleFileChange}
                toggleUpload={toggleUpload}
                fileTypes={fileTypes}
                onDocumentLoadSuccess={onDocumentLoadSuccess}
                numPages={numPages}
                show={show}
                handleSubmit={handleSubmit}
                handleClose={handleClose}
                saveLoader={saveLoader}
                setFile={setFile}
                type={"Legal Documents"}
                showDocOptions={showDocOptions}
                documentStatus={documentStatus}
                setDocumentStatus={setDocumentStatus}
                typeError={typeError}
                setTypeError={setTypeError}
            />
            <Grid container>
                {error ?
                    <>
                        <Grid item xs={12} sx={{ textAlign: "center", marginTop: 10 }}>
                            <ErrorComponent />
                        </Grid>
                    </>
                    :
                    <>
                        {emptyList ?
                            <>
                                <Grid item xs={12} sx={{ textAlign: 'center', marginTop: 2 }}>
                                    <img src="assets/loader/not_found.gif" height={'140vh'} alt='' />
                                    <Typography sx={{ fontWeight: 'bold' }}>
                                        No Vendors found!
                                    </Typography>
                                    <Typography>
                                        Seems like you don't have any vendors registered on ConnectorNet yet.<br />
                                    </Typography>
                                </Grid>
                            </>
                            :
                            <>
                                {!loader ?
                                    <>
                                        <Grid item xs={6} sx={{ marginY: 1 }}>
                                            <SearchTextField
                                                val={vendorSearch}
                                                funcHandle={setVendorSearch}
                                                type={"Vendor"}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sx={{ textAlign: "right", justifyContent: "right", display: "flex", alignItems: "right", marginY: 1 }}>
                                            <FilterComponent
                                                anchorEl={anchorEl}
                                                open={open}
                                                setAnchorEl={setAnchorEl}
                                                options={['Status']}
                                                handleFilterOpt={handleFilterOpt}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container columnSpacing={1}>
                                                <Grid item xs={12} sx={{ textAlign: "right", justifyContent: "right", display: "flex", alignItems: "right" }}>
                                                    <Grid container>
                                                        <Grid item display="flex">
                                                            {statusVisible && (
                                                                <>
                                                                    <Autocomplete
                                                                        id="status"
                                                                        options={['Pending', 'Approved', 'Rejected', 'Revoked']}
                                                                        sx={{
                                                                            '& .MuiInputBase-input': {
                                                                                height: '1.5rem'
                                                                            }
                                                                        }}
                                                                        value={statusValue}
                                                                        onChange={(event, newValue: any) => {
                                                                            setStatusValue(newValue);
                                                                        }}

                                                                        renderInput={params => (
                                                                            <TextField
                                                                                {...params} inputProps={{
                                                                                    ...params.inputProps,
                                                                                    style: { paddingBottom: "7px", width: "100%" }
                                                                                }}
                                                                                placeholder="Status"
                                                                                type="text"
                                                                                fullWidth hiddenLabel
                                                                            />
                                                                        )}
                                                                    />
                                                                    <IconButton onClick={handleStatusClose}>
                                                                        <CancelOutlinedIcon />
                                                                    </IconButton>
                                                                </>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TableContainer component={Paper} sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block", xl: "block" } }}>
                                                        <Table aria-label="simple table" size="small">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableHeadRow createSortHandler={createSortHandler} valueToOrderBy={valueToOrderBy} arr={[
                                                                        {
                                                                            name: 'VENDOR',
                                                                            alignment: 'left',
                                                                            valueToOrderBy: 'name',
                                                                            orderDirection: orderDirection
                                                                        },
                                                                        {
                                                                            name: 'DATE',
                                                                            alignment: 'left',
                                                                            valueToOrderBy: 'createdDatetime',
                                                                            orderDirection: orderDirection
                                                                        },
                                                                        {
                                                                            name: 'PHONE',
                                                                            alignment: 'center',
                                                                            valueToOrderBy: false,
                                                                        }, {
                                                                            name: 'EMAIL',
                                                                            alignment: 'center',
                                                                            valueToOrderBy: false,
                                                                        }, {
                                                                            name: 'DOCUMENT',
                                                                            alignment: 'center',
                                                                            valueToOrderBy: false,
                                                                        }, {
                                                                            name: 'PRODUCT',
                                                                            alignment: 'center',
                                                                            valueToOrderBy: false,
                                                                        },
                                                                        {
                                                                            name: 'ADDRESS',
                                                                            alignment: 'center',
                                                                            valueToOrderBy: false,
                                                                        },
                                                                        {
                                                                            name: 'STATUS',
                                                                            alignment: 'center',
                                                                            valueToOrderBy: false,
                                                                        },
                                                                        {
                                                                            name: 'ACTION',
                                                                            alignment: 'center',
                                                                            valueToOrderBy: false,
                                                                        },
                                                                    ]} />
                                                                </TableRow>
                                                            </TableHead>
                                                            {paginationLoader ?
                                                                <PaginationLoader colspan={9} />
                                                                :
                                                                <TableBody>
                                                                    {rowData &&
                                                                        sortedRowInformation(
                                                                            rowData,
                                                                            getComparator(
                                                                                orderDirection,
                                                                                valueToOrderBy
                                                                            )
                                                                        ).map((row: any) => {
                                                                            let isDisabled = checkApprove(row);
                                                                            return (
                                                                                <TableRow key={row.id}>
                                                                                    <TableCell align="left">
                                                                                        <Typography
                                                                                            onClick={(e) => { handleViewDetails(row) }}
                                                                                            style={
                                                                                                linkCol
                                                                                            }
                                                                                        >
                                                                                            <Typography
                                                                                                sx={
                                                                                                    linkHover
                                                                                                }
                                                                                            >

                                                                                                {firstCapital(
                                                                                                    row.name
                                                                                                )}
                                                                                            </Typography>
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                    <TableCell align="left">
                                                                                        <Typography sx={{ fontSize: "15px" }}>
                                                                                            {convertDate(
                                                                                                row.createdDatetime
                                                                                            )}
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                    {row.id === editable ? (
                                                                                        <>
                                                                                            <TableCell align="center">
                                                                                                <Checkbox name="phoneNumberVerified" checked={formdata.phoneNumberVerified} onChange={e => { toggleHandler(e, formdata) }} color="success" />
                                                                                            </TableCell>
                                                                                            <TableCell align="center"><Checkbox name="emailVerified" checked={formdata.emailVerified} onChange={e => { toggleHandler(e, formdata) }} color="success" />
                                                                                            </TableCell>
                                                                                            <TableCell align="center">
                                                                                                <Checkbox name="legalDocsVerified" checked={formdata.legalDocsVerified} onChange={e => { toggleHandler(e, formdata) }} color="success" />
                                                                                            </TableCell>
                                                                                            {formdata.productsServicesVerified === true ? <>
                                                                                                <TableCell align="center"><Checkbox name="productsServicesVerified" checked={true} disabled color="success" /></TableCell>
                                                                                            </> :
                                                                                                <>
                                                                                                    <TableCell align="center"><Checkbox name="productsServicesVerified" checked={false} disabled color="success" /></TableCell>

                                                                                                </>}
                                                                                            <TableCell align="center"><Checkbox name="addressVerified" checked={row.addressVerified} disabled color="success" /></TableCell>
                                                                                            <StatusCell textClr={statusColorText[row.isApproved]} bgClr={statusColor[row.isApproved]} text={firstCapital(row.isApproved)} />
                                                                                            <TableCell align="center">
                                                                                                <Box display="flex" justifyContent="space-around">
                                                                                                    {editLoader ?
                                                                                                        <>
                                                                                                            <CircularProgress />
                                                                                                        </>
                                                                                                        :
                                                                                                        <>
                                                                                                            <SaveIcon
                                                                                                                color='primary'
                                                                                                                fontSize='large'
                                                                                                                onClick={event => {
                                                                                                                    onSave(

                                                                                                                    );
                                                                                                                }}
                                                                                                            />
                                                                                                            <CancelIcon
                                                                                                                color="error"
                                                                                                                fontSize='large'
                                                                                                                onClick={event =>
                                                                                                                    cancelEdit(
                                                                                                                        row
                                                                                                                    )
                                                                                                                }
                                                                                                            />
                                                                                                        </>}
                                                                                                </Box>
                                                                                            </TableCell>
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            {(row.isApproved === "pending" || row.isApproved === "revoked") &&
                                                                                                <><TableCell align="center"><Checkbox name="phoneNumberVerified" checked={row.phoneNumberVerified} onChange={e => { handleEdit(row); toggleHandler(e, row) }} color="success" /></TableCell>
                                                                                                    <TableCell align="center"><Checkbox name="emailVerified" checked={row.emailVerified} onChange={e => { handleEdit(row); toggleHandler(e, row) }} color="success" /></TableCell>
                                                                                                    <TableCell align="center"><Checkbox name="legalDocsVerified" checked={row.legalDocsVerified} onChange={e => { handleEdit(row); toggleHandler(e, row) }} color="success" /></TableCell>
                                                                                                    {row.productsServicesVerified === true ? <>
                                                                                                        <TableCell align="center"><Checkbox name="productsServicesVerified" checked={true} disabled color="success" /></TableCell>
                                                                                                    </> :
                                                                                                        <>
                                                                                                            <TableCell align="center"><Checkbox name="productsServicesVerified" checked={false} disabled color="success" /></TableCell>

                                                                                                        </>}
                                                                                                    <TableCell align="center"><Checkbox name="addressVerified" checked={row.addressVerified} disabled color="success" /></TableCell>
                                                                                                </>}
                                                                                            {(row.isApproved === "approved" || row.isApproved === "rejected") &&
                                                                                                <><TableCell align="center"><Checkbox name="phoneNumberVerified" checked={row.phoneNumberVerified} disabled /></TableCell>
                                                                                                    <TableCell align="center"><Checkbox name="emailVerified" checked={row.emailVerified} disabled /></TableCell>
                                                                                                    <TableCell align="center"><Checkbox name="legalDocsVerified" checked={row.legalDocsVerified} disabled /></TableCell>
                                                                                                    {row.productsServicesVerified === true ? <>
                                                                                                        <TableCell align="center"><Checkbox name="productsServicesVerified" checked={true} disabled color="success" /></TableCell>
                                                                                                    </> :
                                                                                                        <>
                                                                                                            <TableCell align="center"><Checkbox name="productsServicesVerified" checked={false} disabled color="success" /></TableCell>

                                                                                                        </>}
                                                                                                    <TableCell align="center"><Checkbox name="addressVerified" checked={row.addressVerified} disabled color="success" /></TableCell>
                                                                                                </>}
                                                                                            <StatusCell textClr={statusColorText[row.isApproved]} bgClr={statusColor[row.isApproved]} text={firstCapital(row.isApproved)} />
                                                                                            <TableCell align="center">
                                                                                                {approveLoader === row.id ?
                                                                                                    <>
                                                                                                        <CircularProgress />
                                                                                                    </>
                                                                                                    :
                                                                                                    <>
                                                                                                        {(row.isApproved === 'pending' || row.isApproved === "revoked") && (
                                                                                                            <Box display="flex"
                                                                                                                justifyContent={"center"}>
                                                                                                                <Button
                                                                                                                    color="primary"
                                                                                                                    variant="outlined"
                                                                                                                    endIcon={<ArrowDropDownIcon />}
                                                                                                                    aria-label="more"
                                                                                                                    aria-controls={"long-menu" + row}
                                                                                                                    aria-haspopup="true"
                                                                                                                    sx={{
                                                                                                                        width: 100,
                                                                                                                        margin: "auto"
                                                                                                                    }}
                                                                                                                    onClick={handleMenuClick(row)}
                                                                                                                >
                                                                                                                    <Typography sx={{ fontSize: "15px" }}>
                                                                                                                        SELECT
                                                                                                                    </Typography>
                                                                                                                </Button>
                                                                                                                <Menu
                                                                                                                    id={"long-menu" + row}
                                                                                                                    anchorEl={anchorEl2}
                                                                                                                    keepMounted
                                                                                                                    open={openElem === row}
                                                                                                                    onClose={handleMenuClose}
                                                                                                                    anchorOrigin={{
                                                                                                                        vertical: 'bottom',
                                                                                                                        horizontal: 'left',
                                                                                                                    }}
                                                                                                                    transformOrigin={{
                                                                                                                        vertical: 'top',
                                                                                                                        horizontal: 'left',
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <MenuItem disabled={isDisabled} onClick={(e) => { onApprove(row, "approved") }}>
                                                                                                                        <Typography sx={{ fontSize: "15px", cursor: "pointer", color: "#00BFFF", '&:hover': { color: "#0000ff" } }}>Approve</Typography>
                                                                                                                    </MenuItem>
                                                                                                                    <MenuItem onClick={(e) => onClickReject(row, "rejected")}>
                                                                                                                        <Typography sx={{ fontSize: "15px", cursor: "pointer", color: "#00BFFF", '&:hover': { color: "#0000ff" } }}>Reject</Typography>
                                                                                                                    </MenuItem>
                                                                                                                    <MenuItem onClick={(e) => handleViewSignedDialog(row)}>
                                                                                                                        <Typography sx={{ fontSize: "15px", cursor: "pointer", color: "#00BFFF", '&:hover': { color: "#0000ff" } }}>Signed <ArticleIcon /></Typography>
                                                                                                                    </MenuItem>
                                                                                                                    <MenuItem onClick={(e) => { handleUpload(row) }}>
                                                                                                                        <Typography sx={{ fontSize: "15px", cursor: "pointer", color: "#00BFFF", '&:hover': { color: "#0000ff" } }}>Upload <PublishIcon /></Typography>
                                                                                                                    </MenuItem>
                                                                                                                    <MenuItem onClick={() => { setEditablePage(true); setSelectionType('vendor'); setCurrentEditSelection(row) }}>
                                                                                                                        <Typography sx={{ fontSize: "15px", cursor: "pointer", color: "#00BFFF", '&:hover': { color: "#0000ff" } }}>Edit</Typography>
                                                                                                                    </MenuItem>
                                                                                                                </Menu>
                                                                                                            </Box>
                                                                                                        )}
                                                                                                        {row.isApproved === "approved" && (
                                                                                                            <>
                                                                                                                <Box display="flex"
                                                                                                                    justifyContent={"center"}>
                                                                                                                    <Button
                                                                                                                        color="primary"
                                                                                                                        variant="outlined"
                                                                                                                        endIcon={<ArrowDropDownIcon />}
                                                                                                                        aria-label="more"
                                                                                                                        aria-controls={"long-menu" + row}
                                                                                                                        aria-haspopup="true"
                                                                                                                        sx={{
                                                                                                                            width: 105,
                                                                                                                            margin: "auto"
                                                                                                                        }}
                                                                                                                        onClick={handleMenuClick(row)}
                                                                                                                    >
                                                                                                                        <Typography sx={{ fontSize: "15px" }}>
                                                                                                                            SELECT
                                                                                                                        </Typography>
                                                                                                                    </Button>
                                                                                                                    <Menu
                                                                                                                        id={"long-menu" + row}
                                                                                                                        anchorEl={anchorEl2}
                                                                                                                        keepMounted
                                                                                                                        open={openElem === row}
                                                                                                                        onClose={handleMenuClose}
                                                                                                                        anchorOrigin={{
                                                                                                                            vertical: 'bottom',
                                                                                                                            horizontal: 'left',
                                                                                                                        }}
                                                                                                                        transformOrigin={{
                                                                                                                            vertical: 'top',
                                                                                                                            horizontal: 'left',
                                                                                                                        }}
                                                                                                                    >

                                                                                                                        <MenuItem onClick={(e) => onRevoke(row)}>
                                                                                                                            <Typography sx={{ fontSize: "15px", cursor: "pointer", color: "#00BFFF", '&:hover': { color: "#0000ff" } }}>Revoke</Typography>
                                                                                                                        </MenuItem>
                                                                                                                        <MenuItem onClick={(e) => handleViewSignedDialog(row)}>
                                                                                                                            <Typography sx={{ fontSize: "15px", cursor: "pointer", color: "#00BFFF", '&:hover': { color: "#0000ff" } }}>Signed <ArticleIcon /></Typography>
                                                                                                                        </MenuItem>
                                                                                                                        <MenuItem onClick={(e) => handleUpload(row)}>

                                                                                                                            <Typography sx={{ fontSize: "15px", whiteSpace: "noWrap", cursor: "pointer", color: "#00BFFF", '&:hover': { color: "#0000ff" } }}>Upload <PublishIcon /> </Typography>

                                                                                                                        </MenuItem>
                                                                                                                        <MenuItem onClick={() => { setEditablePage(true); setSelectionType('vendor'); setCurrentEditSelection(row) }}>
                                                                                                                            <Typography sx={{ fontSize: "15px", cursor: "pointer", color: "#00BFFF", '&:hover': { color: "#0000ff" } }}>Edit</Typography>
                                                                                                                        </MenuItem>
                                                                                                                    </Menu>
                                                                                                                </Box>
                                                                                                            </>
                                                                                                        )
                                                                                                        }
                                                                                                        {
                                                                                                            row.isApproved === "rejected" && (
                                                                                                                <Typography sx={{ color: '#0f0f0f', fontSize: "15px", whiteSpace: 'nowrap' }}>
                                                                                                                    No further action required
                                                                                                                </Typography>
                                                                                                            )
                                                                                                        }
                                                                                                    </>}
                                                                                            </TableCell>
                                                                                        </>
                                                                                    )}
                                                                                </TableRow>
                                                                            );
                                                                        })}
                                                                </TableBody>
                                                            }
                                                        </Table>
                                                    </TableContainer>
                                                    <Grid item xs={12} sx={{ display: { sx: "block", sm: "block", md: "none", lg: "none", xl: "none" } }}>
                                                        {rowData &&
                                                            <TableCardComponent
                                                                rowData={rowData}
                                                                checkApprove={checkApprove}
                                                                rowState={formdata}
                                                                editable={editable}
                                                                handleEdit={handleEdit}
                                                                toggleHandler={toggleHandler}
                                                                editLoader={editLoader}
                                                                onSave={onSave}
                                                                cancelEdit={cancelEdit}
                                                                onApprove={onApprove}
                                                                onClickReject={onClickReject}
                                                                userType={"vendor"}
                                                                approveLoader={approveLoader}
                                                                onRevoke={onRevoke}
                                                                showProfile={showProfile}
                                                                paginationLoader={paginationLoader}
                                                            />
                                                        }
                                                    </Grid>
                                                    <Grid item xs={12} sx={{ marginBottom: 8 }}>
                                                        {rowData && (
                                                            <TablePagination
                                                                rowsPerPageOptions={[5, 10, 15, 20]}
                                                                sx={{
                                                                    '.MuiTablePagination-toolbar': {
                                                                        alignItems: 'baseline',
                                                                    }
                                                                }}
                                                                component="div"
                                                                count={records}
                                                                rowsPerPage={rowsPerPage}
                                                                page={page}
                                                                onPageChange={handleChangePage}
                                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                            />
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </> :
                                    <>
                                        <Grid item xs={12} sx={{ textAlign: "center", marginTop: 10 }}>
                                            <img src="assets/loader/Spinner-px.gif" height={"140vh"} />
                                            <Typography sx={{ paddingTop: 2 }}>Please wait while we fetch all vendor details</Typography>
                                        </Grid>
                                    </>}
                            </>}
                    </>}
            </Grid>
        </>
    );
};

export default ApproveVendors;
