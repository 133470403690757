import { Divider, Grid, Typography } from '@mui/material';
import React from 'react'
import { convertNumber } from '../../Admin/Approvals/util';

const Connector = (props:any) => {
  const {currentSelection, cardConnector} = props;
  const style = {padding:1,boxShadow:0, backgroundColor:"#F0F0F0", minHeight: 195};
  const createLink = (link:any) => {
    const mail = "mailto:"+link+"?body=New mail to connector";
    return(mail)
  }
  return (
    <>
        <Grid container spacing={2}>
          <Grid container sx={style}>
          <Grid item xs={12} mb={1}>
          <Typography sx={{fontWeight:"bold", paddingLeft:1, fontSize:"20px"}}>Connector Details</Typography>
          </Grid>
          <Grid container sx={{paddingX: 1}}>
            {cardConnector?<>
              <Grid item xs={12} mb={1}>
                {currentSelection.name? 
                      <Typography sx={{fontWeight:"bold"}}>{currentSelection.name}</Typography>
                    :
                      <Typography sx={{fontWeight:"light"}} >No connector name available</Typography>
                    }
                </Grid>
                <Grid item xs={12} mb={1}>
                {currentSelection.email?
                      <Typography sx={{fontWeight:"light"}}><a href={createLink(currentSelection.email)}> {currentSelection.email} </a></Typography>
                    :
                      <Typography sx={{fontWeight:"light"}} >No connector email available</Typography>
                    }
                </Grid>
                <Grid item xs={12} mb={1}>
                {currentSelection.phoneNumber1? 
                      <Typography sx={{fontWeight:"light"}}>{convertNumber(currentSelection.phoneNumber1)}</Typography>
                    :
                      <Typography sx={{fontWeight:"light"}} >No connector contact number available</Typography>
                    }
                </Grid>
                <Grid item xs={12}>
                {currentSelection.companyId? 
                      <Typography sx={{fontWeight:"light", minHeight:40, maxHeight:40}}>{currentSelection.companyId.name}</Typography>
                    :
                      <Typography sx={{fontWeight:"light"}} >Connector is not associated with any Organization</Typography>
                    }
                </Grid>
            </>
            :
            <>
              <Grid item xs={12} mb={1} height={130}>
              <Typography>Please select a connector</Typography>
              </Grid>
            </>}
          </Grid>
          </Grid>
        </Grid>
    </>
  )
}

export default Connector