import * as React from 'react';
import { ReferenceField, useRedirect } from 'react-admin';
import { Box, Card, Typography } from '@mui/material';
import { Draggable } from 'react-beautiful-dnd';

import { LogoField } from '../companies/LogoField';
import { Deal } from '../types';
import ProfileScreen from '../Profile Screens/ProfileScreen';
import { useState } from 'react';

export const DealCard = ({ deal, index }: { deal: Deal; index: number }) => {   
    const [profileDialog, setProfileDialog] = useState(false);
    const [currentSelection, setCurrentSelection] = useState<any>();
    if (!deal) return null;
    const showProfile = (currentDeal:any) => {
        setCurrentSelection(currentDeal);
        setProfileDialog(true);
    }

    return (
        <>
        <ProfileScreen showDialog={profileDialog} currentSelection={currentSelection} type={"deal"} setShowDialog={setProfileDialog} />
        <Draggable draggableId={String(deal.id)} index={index}>
            {(provided, snapshot) => (
                <Box
                    sx={{ marginBottom: 1 }}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    onClick={() => showProfile(deal)}
                >
                    <Card
                        style={{
                            padding:2,
                            opacity: snapshot.isDragging ? 0.9 : 1,
                            transform: snapshot.isDragging
                                ? 'rotate(-2deg)'
                                : '',
                            height: 135,
                        }}
                        elevation={snapshot.isDragging ? 3 : 1}
                    >
                        <Box padding={1}>
                            <Box sx={{ marginLeft: 1 }}>
                                <Typography variant="body2" gutterBottom>
                                    {deal.name}
                                </Typography>
                                <Typography
                                    variant="caption"
                                    color="textSecondary"
                                >
                                    {deal.amount.toLocaleString('en-US', {
                                        notation: 'compact',
                                        style: 'currency',
                                        currency: 'USD',
                                        currencyDisplay: 'narrowSymbol',
                                        minimumSignificantDigits: 3,
                                    })}
                                    
                                </Typography>
                            </Box>
                        </Box>
                    </Card>
                </Box>
            )}
        </Draggable>
        </>
    );
};
