import React from "react";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Box, Button, Card, CircularProgress, Container, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Auth } from 'aws-amplify';
import Navbar from "../Landing Page/Navbar";
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import QRCodeCanvas from 'qrcode.react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from "axios";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


function Login() {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    mfaValue: ""
  });
  const [showForm, setShowForm] = useState(true);
  const [shadowValue, setShadowValue] = useState(3)
  const [showTotpField, setShowTotpField] = useState(false);
  const [showAuthQR, setShowAuthQR] = useState(false);
  const [authSetupToken, setAuthSetupToken] = useState("");
  const [userData, setUserData] = useState("");
  const [formLoader, setFormLoader] = useState(false);
  const [passwordUpdateRequired, setPasswordUpdateRequired] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [showForgotPasswordField, setShowForgotPasswordField] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [tempUsername, setTempUsername] = useState("");
  const [authorizationLoader, setAuthorizationLoader] = useState(false);
  const [mfaType, setMfaType] = useState("");
  const [showMfaTypeSelector, setShowMfaTypeSelector] = useState(false);
  const [showMfaSetup, setShowMfaSetup] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isMFASuccessfull, setIsMFASuccessfull] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [disableFields, setDisableFields] = useState(false);

  const inputHandler = (e: any) => {
    const name = e.target.id;
    const value = e.target.value;
    setFormData({ ...formData, [name]: value });
  };
  const [error, setError] = useState({
    isError: false,
    message: ""
  })
  const getCharacterValidationError = (str: string) => {
    return `Your password must have at least 1 ${str} character`;
  };
  const successToast = (message: any) => toast.success(message);
  const failureToast = (message: any) => toast.error(message);
  const successMFAToast = () => toast.success("Congratulations! MFA has been successfully added. Please re-login ", { toastId: 'MFA-Added' });
  const successForgotSentmail = () => toast.success("We have sent an email with Verification Code ", { toastId: 'Forgot-Password' });

  const validationSchema = yup.object({
    newPassword: yup
      .string()
      .required("Please enter a password")
      .min(8, "Password must have at least 8 characters")
      .matches(/[0-9]/, getCharacterValidationError("digit"))
      .matches(/[a-z]/, getCharacterValidationError("lowercase"))
      .matches(/[A-Z]/, getCharacterValidationError("uppercase")),
    confirmPassword: yup
      .string()
      .required("Please re-type your password")
      .oneOf([yup.ref("newPassword")], "Passwords do not match"),
    verificationCode: yup
      .string()
      .nullable()
      .matches(/^[0-9]*$/, 'Verification consists of digits only')

  });

  const formik = useFormik({
    initialValues:
    {
      newPassword: '',
      confirmPassword: '',
      verificationCode: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (isForgotPassword) {
        forgotPasswordSubmit(values);
      }
      else {
        setNewPassword(values);
      }
    }
  });

  const initialError = {
    username: false,
    usernameMessage: "",
    password: false,
    passwordMessage: ""
  }

  const [formValidation, setFormValidation] = useState(initialError)

  const submitHandler = (e: any) => {
    e.preventDefault();
    if (formData.username === "" && formData.password === "") {
      setFormValidation({ ...formValidation, username: true, usernameMessage: "Please enter a valid email or username", password: true, passwordMessage: "Please enter a valid password" });
    }
    else {
      signIn()
      setFormValidation(initialError);
    }
  };

  async function signInWithMFA(e: any) {
    e.preventDefault()
    setError({ isError: false, message: "" })
    setSubmitLoader(true);
    try {
      if (
        mfaType === 'SMS_MFA' || mfaType === 'SOFTWARE_TOKEN_MFA' || mfaType === "MFA_SETUP"
      ) {
        const code = formData.mfaValue;
        if (mfaType === "SMS_MFA") {
          const loggedUser = await Auth.confirmSignIn(
            userData,
            code,
            "SMS_MFA"
          );
          window.location.href = String(process.env.REACT_APP_REDIRECT_URI).replace("us", "com");
        }
        else {
          const loggedUser = await Auth.confirmSignIn(
            userData,
            code,
            "SOFTWARE_TOKEN_MFA"
          );
          window.location.href = String(process.env.REACT_APP_REDIRECT_URI).replace("us", "com");
        }
        setSubmitLoader(false);
      } else {
        setSubmitLoader(false);
      }
    } catch (response: any) {
      setSubmitLoader(false);
      setError({ isError: true, message: response.message })
    }
  };

  async function getUserForAuthSetup() {
    setError({ isError: false, message: "" })
    try {
      const user = await Auth.signIn(formData.username, formData.password);
      setShowMfaTypeSelector(false);
      setupTOTPAuth(user);
      setUserData(user);
    } catch (error) {
    }
  }
  async function setupTOTPAuth(user: any) {
    try {
      const secretCode = await Auth.setupTOTP(user);
      const str = "otpauth://totp/" + formData.username + ":" + formData.username + "?secret=" + secretCode + "&issuer=ConnectorNet";
      setAuthSetupToken(str);
      setShowAuthQR(true);
      setShowMfaSetup(false);
    }
    catch (response: any) {
      if (response.message === "Invalid session for the user.") {
        setError({ isError: true, message: "MFA has already been set up for your account." })
      }
      else {
        setError({ isError: true, message: response.message })
      }
    }
  }

  async function verifyMFASetup(e: any) {
    e.preventDefault();
    setError({ isError: false, message: "" })
    setSubmitLoader(true);
    try {
      const cognitoUserSession: CognitoUserSession = await Auth.verifyTotpToken(
        userData,
        formData.mfaValue
      );
      await Auth.setPreferredMFA(userData, 'TOTP');
      setSubmitLoader(false);
      setIsMFASuccessfull(true);
      setDisableFields(true);
    } catch (err: any) {
      setSubmitLoader(false);
      setDisableFields(false);
      setIsMFASuccessfull(false);
      setError({ isError: true, message: err.message })
    }
  }

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(() => {
        window.location.href = String(process.env.REACT_APP_REDIRECT_URI).replace("us", "com");
      })
      .catch(() => {
        const pageUrl = window.location.href.split('/');
        if (pageUrl.at(-2) === "verify") {
          setAuthorizationLoader(true);
          axios.get(process.env.REACT_APP_BASE_URL + "verification/connector-verify/" + pageUrl.at(-1))
            .catch(error => {
              setAuthorizationLoader(false);
            })
            .then((response: any) => {
              if (response.data.verified === true) {
                setFormData({ ...formData, username: response.data.username })
                const verifiedEmail = response.data.email;
                const verifiedUsername = response.data.username;
                axios.post(process.env.REACT_APP_BASE_URL + "auth/confirm-status", null, { params: { username: verifiedUsername } })
                  .catch((err: any) => {
                    setAuthorizationLoader(false);
                  })
                  .then((response: any) => {
                    if (response.data) {
                      //axios.post(process.env.REACT_APP_BASE_URL+"notification/connector-registration",null,{params:{email:verifiedEmail, name:verifiedUsername}});
                    }
                  })
                axios.post(process.env.REACT_APP_BASE_URL + "auth/verify-email", null, { params: { username: verifiedUsername } })
                  .then((response: any) => {
                    setAuthorizationLoader(false);
                  })
              }
            })
        }
        else {
          setAuthorizationLoader(false);
        }
      })
  }, [])

  async function signIn() {
    setError({ isError: false, message: "" })
    setSubmitLoader(true)
    try {
      const user = await Auth.signIn(formData.username, formData.password);
      setUserData(user);
      axios.get(process.env.REACT_APP_BASE_URL + "auth/check-email", {
        params: { username: user.username }
      })
        .catch((error) => {
          setSubmitLoader(false);
        })
        .then((response: any) => {
          if (response.data) {
            if (user.challengeName === "MFA_SETUP") {
              mfaTypeSelector("SOFTWARE_TOKEN_MFA")
            }
            else {
              setMfaType(user.challengeName)
            }
            if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
              setFormData({
                username: "",
                password: "",
                mfaValue: ""
              });
              setPasswordUpdateRequired(true);
              setSubmitLoader(false);
            }
            else {
              setShowTotpField(true);
              setSubmitLoader(false);
            }
          }
          else {
            setError({ isError: true, message: "Please Verify your email id" })
            setSubmitLoader(false);
          }
        })

    } catch (response: any) {
      setFormLoader(false);
      if (response.message === "User is not confirmed.") {
        setError({ isError: true, message: "Please verify your mail id and try again." })
      }
      else {
        setError({ isError: true, message: response.message })
      }
      setSubmitLoader(false);
    }
  }

  async function setNewPassword(values: any) {
    setSubmitLoader(true);
    try {
      const loggedInUser = await Auth.completeNewPassword(
        userData,
        values.newPassword
      )
      setPasswordUpdateRequired(false);
      setShowMfaSetup(true);
      if (loggedInUser.challengeName === "MFA_SETUP") {
        mfaTypeSelector("SOFTWARE_TOKEN_MFA")
        setShowTotpField(true);
      }
      else {
        setMfaType(loggedInUser.challengeName)
      }
      setShowTotpField(true);
      axios.post(process.env.REACT_APP_BASE_URL + "auth/verify-email", null, {
        headers: {
          "Authorization": "Bearer " + loggedInUser.storage['CognitoIdentityServiceProvider.' + process.env.REACT_APP_COGNITO_CLIENT_ID + '.' + loggedInUser.username + '.accessToken'],
        }
        ,
        params: { username: loggedInUser.username }
      })
      setFormData({ ...formData, password: values.newPassword, username: loggedInUser.username })
      setSubmitLoader(false);
    }
    catch (err) {
      setSubmitLoader(false);
    }
  }

  const onClickForgotPassword = () => {
    setPasswordUpdateRequired(true);
    setShowForgotPasswordField(true);
    setIsForgotPassword(true);
  }

  async function sendVerificationCode(e: any) {
    setSubmitLoader(true);
    e.preventDefault()
    try {
      const data = await Auth.forgotPassword(formData.username);
      setError({ isError: false, message: "" })
      setTempUsername(formData.username)
      setFormData({ ...formData, username: "", password: "" })
      setShowForgotPasswordField(false);
      setSubmitLoader(false);
      successForgotSentmail();
    } catch (err: any) {
      setError({ isError: true, message: err.message })
      setSubmitLoader(false)
    }
  };

  async function forgotPasswordSubmit(values: any) {
    setSubmitLoader(true);
    try {
      const data = await Auth.forgotPasswordSubmit(tempUsername, String(values.verificationCode), values.newPassword);
      setError({ isError: false, message: "" })
      setSubmitLoader(false);
      successToast("New password set successfully");
      setPasswordUpdateRequired(false);
      setShowForgotPasswordField(false);
      setIsForgotPassword(false);
      axios.post(process.env.REACT_APP_BASE_URL + "notification/password-reset", null, {
        params: { username: tempUsername }
      })
    } catch (err: any) {
      setError({ isError: true, message: err.message })
      setSubmitLoader(false);
    }
  };

  const mfaTypeSelector = (type: any) => {
    setMfaType(type);
    if (type === "SOFTWARE_TOKEN_MFA" || "MFA_SETUP") {
      getUserForAuthSetup();
    }
    else {
      setShowMfaTypeSelector(false);
    }
  }

  async function resendConfirmationCode() {
    try {
      await Auth.resendSignUp(formData.username);
      successToast("OTP sent successfully")
    } catch (err) {
      failureToast("Could not send OTP")
    }
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  const handleMouseDownPassword = () => {
    setShowPassword(false);
    setShowConfirmPassword(false);
  }

  const mfaSuccessfullRedirect = () => {
    window.location.href = String(process.env.REACT_APP_REDIRECT_URI).replace("us", "com");
  };

  return (

    <>
      {authorizationLoader ?
        <Grid container sx={{ display: "flex", alignItems: "center", justifyContent: "center", minHeight: "100vh" }}>
          <Grid item xs={12} sx={{ textAlign: "center", marginY: 5 }}>
            <img src="assets/loader/Spinner-px.gif" height={"140vh"} />
            <Typography sx={{ paddingTop: 2 }}>
              Please wait while we verify your email
            </Typography>
          </Grid>
        </Grid>
        :
        <>
          <Navbar destinationPage={"registration"} />
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <Container maxWidth="xl" sx={{ justifyContent: "center", minHeight: "100vh", display: "flex", alignItems: "center", paddingTop: '10vh' }}>
            <Box sx={{ width: "75%", alignSelf: "center", margin: "auto", boxShadow: shadowValue, borderRadius: 2 }}>
              {showForm ? (
                <>
                  {/* <h1 className="text-3xl font-semibold text-center text-purple-700 underline">
                   Sign in
                </h1> */}

                  <Grid container spacing={2} sx={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "white", borderRadius: 2, paddingY: { xs: 4, sm: 4, md: 0, lg: 0, xl: 0 } }}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ textAlign: "center", backgroundColor: "#144fe9", borderRadius: 2, paddingY: 10, minHeight: "100%", display: { xs: "none", sm: "none", md: "grid", lg: "grid", xl: "grid" } }}>
                      <Container sx={{ minHeight: "100%", paddingTop: 8 }}>
                        <img
                          src="assets/logos/ConnectorNet-Logo-Vert-White.png"
                          width={"60%"}
                          alt="connectornet logo"
                        />
                      </Container>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                      <Grid container>
                        {showTotpField ?
                          <>
                            {showMfaTypeSelector ?
                              <>
                                <Grid item xs={12} sx={{ textAlign: "center" }}>
                                  <Typography variant="caption" sx={{ fontSize: "16px", color: "#1976d2" }}>
                                    Select the type of MFA you want to set up for your profile
                                  </Typography>
                                  <Box sx={{ marginTop: 3 }}>
                                    <Button variant="contained" sx={{ marginX: 2 }} onClick={() => mfaTypeSelector("SMS_MFA")}>SMS MFA</Button>
                                    <Button variant="contained" sx={{ marginX: 2 }} onClick={() => mfaTypeSelector("SOFTWARE_TOKEN_MFA")}>Authenticator App</Button>
                                  </Box>
                                </Grid>
                              </> :
                              <>
                                {mfaType === "SOFTWARE_TOKEN_MFA" &&
                                  <>
                                    {showAuthQR ?
                                      <>
                                        {authSetupToken !== "" &&
                                          <>
                                            {error.isError &&
                                              <Grid item xs={12} sx={{ textAlign: "center", marginBottom: 1, marginX: 3 }}>
                                                <Card sx={{ backgroundColor: "#F0F0F0", marginY: 2, padding: 1 }}>
                                                  <Typography sx={{ fontWeight: "light", color: "red", fontSize: "16px" }}>{error.message}</Typography>
                                                </Card>
                                              </Grid>
                                            }
                                            <Grid item xs={12} sx={{ marginX: 3, marginBottom: 1, textAlign: "center" }}>
                                              <Typography variant="caption" sx={{ fontSize: "16px", color: "#1976d2" }}>
                                                Scan QR with your authenticator app
                                              </Typography>
                                            </Grid>
                                            <Grid item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                              <QRCodeCanvas value={authSetupToken} />
                                            </Grid>
                                            <Grid item xs={12}>
                                              <form onSubmit={(e) => verifyMFASetup(e)}>
                                                <Grid item xs={12} sx={{ marginX: 3, marginTop: 2 }}>
                                                  <TextField
                                                    type="mfaValue"
                                                    name="mfaValue"
                                                    id="mfaValue"
                                                    placeholder="Enter your code 6-digit code here"
                                                    value={formData.mfaValue}
                                                    onChange={(e) => inputHandler(e)}
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                    autoFocus
                                                    disabled={disableFields}
                                                  />
                                                </Grid>
                                                <Grid item xs={12} sx={{ marginX: 3, marginTop: 2 }}>
                                                  {isMFASuccessfull &&
                                                    <>

                                                      <p className="mt-2 mb-0 text-xs font-light text-blue-600">
                                                        {" "}
                                                        Congratulations! MFA has been successfully added.
                                                      </p>
                                                      <p className="mt-0">
                                                        {" "}
                                                        Please sign in again {" "}
                                                        <Typography onClick={mfaSuccessfullRedirect} variant="caption" sx={{ color: "#4e80ee", "&:hover": { textDecoration: "underline", cursor: "pointer" }, fontSize: "1rem", }} className="text-s">
                                                          here.
                                                        </Typography>
                                                      </p>
                                                    </>
                                                  }
                                                </Grid>
                                                <Grid item xs={12} sx={{ marginX: 3 }}>
                                                  <Box sx={{ marginTop: 2, textAlign: "center" }}>
                                                    <Button variant="contained"
                                                      sx={{ minWidth: "33%", backgroundColor: "#1976d2" }}
                                                      onClick={(e) => verifyMFASetup(e)}
                                                      disabled={formData.mfaValue.length < 6 || disableFields}
                                                    >
                                                      {submitLoader ? <><CircularProgress size="1.6rem" sx={{ color: "white" }} /></> : <>Submit</>}
                                                    </Button>
                                                  </Box>
                                                </Grid>
                                              </form>
                                            </Grid>
                                          </>
                                        }
                                      </>
                                      :
                                      <>
                                        {error.isError &&
                                          <Grid item xs={12} sx={{ textAlign: "center", marginBottom: 1, marginX: 3 }}>
                                            <Card sx={{ backgroundColor: "#F0F0F0", marginY: 2, padding: 1 }}>
                                              <Typography sx={{ fontWeight: "light", color: "red", fontSize: "16px" }}>{error.message}</Typography>
                                            </Card>
                                          </Grid>
                                        }
                                        <Grid item xs={12} sx={{ marginX: 3, marginBottom: 1 }}>
                                          <Typography variant="caption" sx={{ fontSize: "16px", color: "#1976d2" }}>
                                            Please check your Authenticator app for OTP
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                          <form onSubmit={(e) => signInWithMFA(e)}>
                                            <Grid item xs={12} sx={{ marginX: 3 }}>
                                              <TextField
                                                type="mfaValue"
                                                name="mfaValue"
                                                id="mfaValue"
                                                placeholder="Enter your OTP here"
                                                value={formData.mfaValue}
                                                onChange={(e) => inputHandler(e)}
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                autoFocus
                                              />
                                              {showMfaSetup &&
                                                <p className="mt-2 text-xs font-light text-blue-600">
                                                  {" "}
                                                  To set up your MFA, {" "}
                                                  <Typography onClick={getUserForAuthSetup} variant="caption" sx={{ color: "#4e80ee", "&:hover": { textDecoration: "underline", cursor: "pointer" } }}>
                                                    click here
                                                  </Typography>
                                                </p>
                                              }
                                            </Grid>

                                            <Grid item xs={12} sx={{ marginX: 3 }}>
                                              <Box sx={{ marginTop: 2, textAlign: "center" }}>
                                                <Button variant="contained"
                                                  sx={{ minWidth: "33%", backgroundColor: "#1976d2" }}
                                                  onClick={(e) => signInWithMFA(e)}
                                                  type="submit"
                                                  disabled={formData.mfaValue.length < 6}
                                                >
                                                  {submitLoader ? <><CircularProgress size="1.6rem" sx={{ color: "white" }} /></> : <>Submit</>}
                                                </Button>
                                              </Box>
                                            </Grid>
                                          </form>
                                        </Grid>
                                      </>}
                                  </>
                                }
                                {mfaType === "SMS_MFA" &&
                                  <Grid item xs={12}>
                                    {error.isError &&
                                      <Grid item xs={12} sx={{ textAlign: "center", marginBottom: 1, marginX: 3 }}>
                                        <Card sx={{ backgroundColor: "#F0F0F0", marginY: 2, padding: 1 }}>
                                          <Typography sx={{ fontWeight: "light", color: "red", fontSize: "16px" }}>{error.message}</Typography>
                                        </Card>
                                      </Grid>
                                    }
                                    <form onSubmit={(e) => signInWithMFA(e)}>
                                      <Grid item xs={12} sx={{ marginX: 3, marginBottom: 1 }}>
                                        <Typography variant="caption" sx={{ fontSize: "16px", color: "#1976d2" }}>
                                          Please check your Registered number for OTP
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12} sx={{ marginX: 3 }}>
                                        <TextField
                                          type="mfaValue"
                                          name="mfaValue"
                                          id="mfaValue"
                                          placeholder="Enter your OTP here"
                                          value={formData.mfaValue}
                                          onChange={(e) => inputHandler(e)}
                                          fullWidth
                                          variant="outlined"
                                          size="small"
                                          autoFocus
                                        />
                                      </Grid>
                                      <Grid item xs={12} sx={{ marginX: 3 }}>
                                        <p className="mt-2 text-xs font-light text-center text-gray-700">
                                          {" "}Didn't receive a verification code ?{" "}
                                          <Typography onClick={resendConfirmationCode} variant="caption" sx={{ color: "#4e80ee", "&:hover": { textDecoration: "underline", cursor: "pointer" } }}>
                                            Re-send
                                          </Typography>
                                        </p>
                                      </Grid>
                                      <Grid item xs={12} sx={{ marginX: 3 }}>
                                        <Box sx={{ marginTop: 2, textAlign: "center" }}>
                                          <Button variant="contained"
                                            sx={{ minWidth: "33%", backgroundColor: "#1976d2" }}
                                            type="submit"
                                            onClick={(e) => signInWithMFA(e)}
                                            disabled={formData.mfaValue.length < 6}
                                          >
                                            {submitLoader ? <><CircularProgress size="1.6rem" sx={{ color: "white" }} /></> : <>Submit</>}
                                          </Button>
                                        </Box>
                                      </Grid>
                                    </form>
                                  </Grid>
                                }
                              </>}
                          </>
                          :
                          <>
                            {!passwordUpdateRequired ?
                              <>
                                <Grid item xs={12} sx={{ textAlign: "center" }}>
                                  <Typography
                                    sx={{ fontWeight: "bold", fontSize: "24px", marginTop: 2, color: "#1976d2" }}
                                  >
                                    Welcome To ConnectorNet
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ textAlign: "center", marginBottom: error.isError ? 0 : 3 }}>
                                  <Typography variant="caption" sx={{ fontSize: "16px", color: "#1976d2" }}>
                                    Please login to continue
                                  </Typography>
                                </Grid>
                                {error.isError &&
                                  <Grid item xs={12} sx={{ textAlign: "center", marginBottom: 1, marginX: 3 }}>
                                    <Card sx={{ backgroundColor: "#F0F0F0", marginY: 2, padding: 1 }}>
                                      <Typography sx={{ fontWeight: "light", color: "red", fontSize: "16px" }}>{error.message}</Typography>
                                    </Card>
                                  </Grid>
                                }
                                <Grid item xs={12} sx={{ marginX: 3 }}>
                                  <form onSubmit={(e) => submitHandler(e)}>
                                    <Grid item xs={12} sx={{ height: "70px" }}>
                                      <Typography>Email or Username*</Typography>
                                      <TextField
                                        type="username"
                                        name="username"
                                        id="username"
                                        placeholder="Enter your Email or Username here"
                                        value={formData.username}
                                        onChange={(e) => inputHandler(e)}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        autoFocus
                                        error={formValidation.username}
                                        helperText={formValidation.usernameMessage}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sx={{ height: "70px" }}>
                                      <Typography sx={{ marginTop: 1 }}>Password*</Typography>
                                      <TextField
                                        type={showPassword ? "text" : "password"}
                                        name="password"
                                        id="password"
                                        placeholder="Enter your Password here"
                                        value={formData.password}
                                        onChange={(e) => inputHandler(e)}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        error={formValidation.password}
                                        helperText={formValidation.passwordMessage}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                onBlur={handleMouseDownPassword}
                                              >
                                                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                              </IconButton>
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                      <Typography variant="caption" onClick={onClickForgotPassword} sx={{ color: "#4e80ee", "&:hover": { textDecoration: "underline", cursor: "pointer" } }}>
                                        Forgot Password?
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={{ paddingBottom: 2 }}>
                                      <Box sx={{ marginTop: 3, textAlign: "center" }}>
                                        <Button variant="contained"
                                          sx={{ minWidth: "33%", backgroundColor: "#144fe9" }}
                                          type="submit"

                                        >
                                          {submitLoader ? <><CircularProgress size="1.6rem" sx={{ color: "white" }} /></> : <>Login</>}
                                        </Button>
                                      </Box>
                                      <p className="mt-2 text-xs font-light text-center text-blue-600">
                                        {" "}
                                        Don't have an account?{" "}
                                        <Link to="/register">
                                          <Typography variant="caption" sx={{ color: "#4e80ee", "&:hover": { textDecoration: "underline", cursor: "pointer" } }}>
                                            Sign up
                                          </Typography>
                                        </Link>
                                      </p>
                                    </Grid>
                                  </form>
                                </Grid>
                              </>
                              :
                              <>
                                {showForgotPasswordField ?
                                  <>
                                    {error.isError &&
                                      <Grid item xs={12} sx={{ textAlign: "center", marginBottom: 1, marginX: 3 }}>
                                        <Card sx={{ backgroundColor: "#F0F0F0", marginY: 2, padding: 1 }}>
                                          <Typography sx={{ fontWeight: "light", color: "red", fontSize: "16px" }}>{error.message}</Typography>
                                        </Card>
                                      </Grid>
                                    }
                                    <Grid item xs={12} sx={{ textAlign: "center", marginBottom: 2 }}>
                                      <Typography variant="caption" sx={{ fontSize: "16px", color: "#1976d2" }}>
                                        Please enter your username or email to send a verification code
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={{ marginX: 3, marginTop: 2 }}>
                                      <form onSubmit={(e) => sendVerificationCode(e)}>
                                        <Grid item xs={12} sx={{ marginBottom: 4 }}>
                                          <Typography>Email or Username*</Typography>
                                          <TextField
                                            type="username"
                                            name="username"
                                            id="username"
                                            placeholder="Enter your Email or Username here"
                                            value={formData.username}
                                            onChange={(e) => inputHandler(e)}
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            autoFocus
                                            error={formValidation.username}
                                            helperText={formValidation.usernameMessage}
                                          />
                                        </Grid>
                                        <Grid item xs={12} sx={{ marginX: 3 }}>
                                          <Box sx={{ marginTop: 2, textAlign: "center" }}>
                                            <Button variant="contained"
                                              sx={{ minWidth: "33%", backgroundColor: "#1976d2" }}
                                              type="submit"

                                            >
                                              {submitLoader ? <><CircularProgress size="1.6rem" sx={{ color: "white" }} /></> : <>Submit</>}
                                            </Button>
                                          </Box>
                                        </Grid>
                                      </form>
                                    </Grid>
                                  </>
                                  :
                                  <>
                                    <Grid item xs={12} sx={{ textAlign: "center", marginBottom: 3 }}>
                                      <Typography variant="caption" sx={{ fontSize: "16px", color: "#1976d2" }}>
                                        {isForgotPassword ? <>Please enter the unique code sent through mail<br /> followed by the new password</> : "Please set up new password for your account"}
                                      </Typography>
                                    </Grid>
                                    {error.isError &&
                                      <Grid item xs={12} sx={{ textAlign: "center", marginBottom: 1, marginX: 3 }}>
                                        <Card sx={{ backgroundColor: "#F0F0F0", marginY: 2, padding: 1 }}>
                                          <Typography sx={{ fontWeight: "light", color: "red", fontSize: "16px" }}>{error.message}</Typography>
                                        </Card>
                                      </Grid>
                                    }
                                    <Grid item xs={12} sx={{ marginX: 3 }}>
                                      <form onSubmit={(e) => formik.handleSubmit(e)}>
                                        {isForgotPassword &&
                                          <Grid item xs={12} sx={{ height: '90px' }}>
                                            <Typography>Verification Code</Typography>
                                            <TextField
                                              type="number"
                                              name="verificationCode"
                                              id="verificationCode"
                                              placeholder="Enter your verification code here"
                                              fullWidth
                                              variant="outlined"
                                              size="small"
                                              autoFocus
                                              onBlur={formik.handleBlur}
                                              onChange={formik.handleChange}
                                              error={formik.touched.verificationCode && Boolean(formik.errors.verificationCode)}
                                              helperText={formik.touched.verificationCode && formik.errors.verificationCode}
                                            />
                                          </Grid>
                                        }
                                        <Grid item xs={12} sx={{ height: '90px' }}>
                                          <Typography>New Password</Typography>
                                          <TextField
                                            type={showPassword ? "text" : "password"}
                                            name="newPassword"
                                            id="newPassword"
                                            placeholder="Enter your Password here"
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            autoFocus
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                                            helperText={formik.touched.newPassword && formik.errors.newPassword}
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="end">
                                                  <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    onBlur={handleMouseDownPassword}
                                                  >
                                                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                  </IconButton>
                                                </InputAdornment>
                                              ),
                                            }}
                                          />
                                        </Grid>
                                        <Grid item xs={12} sx={{ height: '90px' }}>
                                          <Typography>Confirm Password</Typography>
                                          <TextField
                                            type={showConfirmPassword ? "text" : "password"}
                                            name="confirmPassword"
                                            id="confirmPassword"
                                            placeholder="Confirm your Password here"
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                                            helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="end">
                                                  <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowConfirmPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    onBlur={handleMouseDownPassword}
                                                  >
                                                    {showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                  </IconButton>
                                                </InputAdornment>
                                              ),
                                            }}
                                          />
                                        </Grid>
                                        <Grid item xs={12}>
                                          {isForgotPassword && <Typography variant="caption">Please make sure your email has been verified.</Typography>}
                                        </Grid>
                                        <Grid item xs={12} sx={{ marginX: 3 }}>
                                          <Box sx={{ marginTop: 2, textAlign: "center" }}>
                                            <Button variant="contained"
                                              sx={{ minWidth: "33%", backgroundColor: "#1976d2" }}
                                              type="submit"
                                            >
                                              {submitLoader ? <><CircularProgress size="1.6rem" sx={{ color: "white" }} /></> : <>Submit</>}
                                            </Button>
                                          </Box>
                                        </Grid>
                                      </form>
                                    </Grid>
                                  </>}
                              </>
                            }
                          </>}
                      </Grid>
                    </Grid>
                  </Grid>

                </>
              ) : (
                <>
                  <Grid item xs={12} sx={{ textAlign: "center", paddingY: 5 }}>
                    <img src="assets/loader/Spinner-px.gif" height={"140vh"} />
                    <Typography sx={{ paddingTop: 2 }}>
                      Please wait while we authenticate your credentials
                    </Typography>
                  </Grid>
                </>
              )}
            </Box>
          </Container>
        </>}
    </>

  );
}

export default Login;
