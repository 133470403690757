// export const sectors = [
//     'Communication Services',
//     'Consumer Discretionary',
//     'Consumer Staples',
//     'Energy',
//     'Financials',
//     'Health Care',
//     'Industrials',
//     'Information Technology',
//     'Materials',
//     'Real Estate',
//     'Utilities',
// ].map(sector => ({ id: sector, name: sector }));

export const sectors = [
    'Tele-Medicine',
    'In-vitro diagnostic substances',
    'Electro-medical and electrotherapeutic apparatuses',
    'Irradiation apparatuses',
    'Surgical Instrument & Medical Instrument Manufacturing',
    'Surgical appliances and supplies',
    'Dental equipment and supplies',
    'Ophthalmic goods',
    'Vital Signs Monitor Manufacturing',
    'Mammography Machine Manufacturing',
    'TENS Machine Manufacturing',
    'Nebulizer Manufacturing',
    'Medical Laser Machine Manufacturing',
    'Medical Device Manufacturing',
    'Medical Instrument & Supply Manufacturing',
].map(sector => ({ id: sector, name: sector }));
