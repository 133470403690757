import React, { useRef } from 'react'
import { StandaloneSearchBox, LoadScript } from '@react-google-maps/api';
import { Button, Card, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { createPortal } from 'react-dom';

const AddressAutocomplete = (props: any) => {
    const { formData, setFormData, formValidation, showAddressSuggestion, onClickYes, validateAddress, saveLoader, disableFields, onClickEdit } = props;
    const inputRef = useRef<any>("mumbai");
    const handlePlaceUpdated = () => {
        const [place] = inputRef.current.getPlaces();
        var street = "";
        var city = "";
        var state = "";
        var country = "";
        var pincode = "";
        if (place) {
            place.address_components.map((record: any) => {
                record.types.map((subRecord: any) => {
                    if (subRecord === "street_number" || subRecord === "route") {
                        if (street === "") {
                            street = record.long_name;
                        }
                        else {
                            street = street + " " + record.long_name
                        }
                    }
                    else if (subRecord === "locality") {
                        city = record.long_name;
                    }
                    else if (subRecord === "administrative_area_level_1") {
                        state = record.long_name;
                    }
                    else if (subRecord === "country") {
                        country = record.long_name;
                    }
                    else if (subRecord === "postal_code") {
                        pincode = record.long_name;
                    }
                    else if (subRecord === "postal_code_suffix") {
                        pincode = pincode + "-" + record.long_name;
                    }
                })
            })
            setFormData({ ...formData, address: street, cityName: city, stateName: state, countryName: country, zipcode: pincode })
            validateAddress({ ...formData, address: street, cityName: city, stateName: state, countryName: country, zipcode: pincode });
        }
    }
    const inputHandler = (e: any) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    const api_key = String(process.env.REACT_APP_GOOGLE_API_KEY);
    return (
        <>
            {showAddressSuggestion !== "" ?
                <>
                    <Card sx={{ padding: 3, backgroundColor: "#F0F0F0", marginBottom: 2 }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant="caption">We found this address for you, Did you mean this ?</Typography>
                                <Typography>{showAddressSuggestion}</Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ textAlign: "center" }}>
                                {saveLoader ?
                                    <>
                                        <CircularProgress />
                                    </>
                                    :
                                    <>
                                        <Button onClick={onClickEdit}>Edit</Button>
                                        <Button onClick={onClickYes}>Confirm</Button>
                                    </>}
                            </Grid>
                        </Grid>
                    </Card>
                </>
                :
                <> </>
            }<>

                <LoadScript googleMapsApiKey={api_key} libraries={["places"]}>

                    <StandaloneSearchBox
                        onLoad={ref => (inputRef.current = ref)}
                        onPlacesChanged={handlePlaceUpdated}
                    >
                        <TextField id="address" size="small" fullWidth type='text' name='address' value={formData.address} margin="normal" label="Address Line 1" placeholder="Enter your address here" variant="outlined" onChange={inputHandler} />
                    </StandaloneSearchBox>

                </LoadScript>

            </>
            <Grid container columnSpacing={1}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        id="street"
                        value={formData.street}
                        name="street"
                        label="Address Line 2"
                        margin="normal"
                        onChange={inputHandler}
                        InputProps={{
                            style: {
                                boxSizing: "content-box"
                            }
                        }}
                        onBlur={(e) => validateAddress(formData)}
                        InputLabelProps={{ shrink: formData.street }}
                        error={formValidation.street}
                        helperText={formValidation.streetMessage}
                        disabled={disableFields}
                    />
                    {/* <TextField id="addressLine2" size="small" fullWidth type='text' name='addressLine2' value={formData.street} margin="normal" label="Address Line 2" placeholder="Enter your address here" variant="outlined" onChange={inputHandler} /> */}
                </Grid>
                {/* 
            <Grid container columnSpacing={1}>
                <Grid item xs={12}>
                    <TextField
                        id="addressLine2"
                        size="small"
                        fullWidth type='text'
                        name='addressLine2'
                        value={formData.addressLine2}
                        margin="normal"
                        label="Address Line 2"
                        placeholder="Enter your address here"
                        variant="outlined"
                        onChange={inputHandler} />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        id="address"
                        value={formData.address}
                        name="address"
                        label="Street Address*"
                        margin="normal"
                        InputProps={{
                            style: {
                                boxSizing: "content-box"
                            }
                        }}
                        onChange={inputHandler}
                        onBlur={(e) => validateAddress(formData)}
                        InputLabelProps={{ shrink: formData.address }}
                        error={formValidation.address}
                        helperText={formValidation.addressMessage}
                        disabled={disableFields}
                    />
                </Grid> */}
                {/* <Grid item xs={6}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        id="street"
                        value={formData.street}
                        name="street"
                        label="Apt/Suite/Other"
                        margin="normal"
                        onChange={inputHandler}
                        InputProps={{
                            style: {
                                boxSizing: "content-box"
                            }
                        }}
                        onBlur={(e) => validateAddress(formData)}
                        InputLabelProps={{ shrink: formData.street }}
                        error={formValidation.street}
                        helperText={formValidation.streetMessage}
                        disabled={disableFields}
                    />
                </Grid> */}
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        id="cityName"
                        value={formData.cityName}
                        name="cityName"
                        label="City*"
                        margin="normal"
                        InputProps={{
                            style: {
                                boxSizing: "content-box"
                            }
                        }}
                        onChange={inputHandler}
                        onBlur={(e) => validateAddress(formData)}
                        InputLabelProps={{ shrink: formData.cityName }}
                        error={formValidation.city}
                        helperText={formValidation.cityMessage}
                        disabled={disableFields}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        id="stateName"
                        value={formData.stateName}
                        name="stateName"
                        label="State*"
                        margin="normal"
                        InputProps={{
                            style: {
                                boxSizing: "content-box"
                            }
                        }}
                        onChange={inputHandler}
                        onBlur={(e) => validateAddress(formData)}
                        InputLabelProps={{ shrink: formData.stateName }}
                        error={formValidation.state}
                        helperText={formValidation.stateMessage}
                        disabled={disableFields}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        id="countryName"
                        value={formData.countryName}
                        name="countryName"
                        label="Country*"
                        margin="normal"
                        InputProps={{
                            style: {
                                boxSizing: "content-box"
                            }
                        }}
                        onChange={inputHandler}
                        onBlur={(e) => validateAddress(formData)}
                        InputLabelProps={{ shrink: formData.countryName }}
                        error={formValidation.country}
                        helperText={formValidation.countryMessage}
                        disabled={disableFields}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        id="zipcode"
                        value={formData.zipcode}
                        name="zipcode"
                        label="Zipcode*"
                        margin="normal"
                        InputProps={{
                            style: {
                                boxSizing: "content-box"
                            }
                        }}
                        onChange={inputHandler}
                        onBlur={(e) => validateAddress(formData)}
                        InputLabelProps={{ shrink: formData.zipcode }}
                        error={formValidation.zipcode}
                        helperText={formValidation.zipcodeMessage}
                        disabled={disableFields}
                    />
                </Grid>
            </Grid>

        </>
    )
}

export default AddressAutocomplete