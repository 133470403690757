export const requestBodyCreator = (user:any) => {
    var dataobj = {};
    if (user.role === 'vendor') {
        var fname = user?.name?.slice(0,user?.name?.indexOf(" "));
        var lname = user?.name?.slice(user?.name?.indexOf(" "));
         
    dataobj = {
        contact: {
            firstName: fname.replace(" ",""),
            lastName: lname.replace(" ",""),
            email: user?.email,
            phoneNumber1: user?.phoneNumber1.replace("+",""),
            vendorId: { id: Number(user?.organisationId)},
            phoneNumberVerified: false,
            emailVerified: false,
            isApproved: true,
            createdBy: user?.nickname,
            modifiedBy: user?.nickname,
            addressVerified: false,
            isAdmin: true
        },
        role: "vendor-admin",
    };
    } 
    else if (user.role === 'connector') {
        dataobj = {
            connector: {
                name: user?.name,
                email: user?.email,
                phoneNumber1: user?.phoneNumber1.replace("+",""),
                createdBy: user?.name,
                modifiedBy: user?.name,
                phoneNumberVerified: false,
                emailVerified: false,
                legalDocsVerified: false,
                w9_verified: false,
                isApproved: "pending",
                addressVerified: false
            },
            role: user?.role,
        };
    }
    else if (user.role === 'admin'){
        dataobj = {
            name: user.name,
            email: user.email,
            phoneNumber1: user?.phoneNumber1.replace("+",""),
            createdBy: user.name,
            modifiedBy: user.name,
            emailVerified: false,
            phoneNumberVerified: false,
            isApproved: "approved",
            addressVerified: false
        }
    }
    return dataobj;
} 