import { TableBody, TableCell, TableRow } from '@mui/material'
import React from 'react'

const PaginationLoader = (props:any) => {
    const {colspan} = props;
    return (
        <TableBody>
            <TableRow>
                <TableCell colSpan={colspan} sx={{ textAlign: "center", height: "35vh" }}>
                    <img src="assets/loader/Spinner-px.gif" height={'50%'} />
                </TableCell>
            </TableRow>
        </TableBody>
    )
}

export default PaginationLoader