import {Box, CircularProgress, Card, Checkbox, Grid, Typography, Button, Badge } from '@mui/material';
import React from 'react'
import PersonIcon from '@mui/icons-material/Person';
import { linkCol, linkHover, statusColor, statusColorText } from '../../tableStyle';
import { convertCurrency, convertDate, convertNumber, firstCapital } from './util';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ArticleIcon from '@mui/icons-material/Article';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

const TableCardComponent = (props:any) => {
    const {rowData, checkApprove, editable, toggleHandler, rowState, handleEdit, editLoader, onSave, cancelEdit, onApprove, onClickReject, userType, approveLoader, onRevoke, brochureShow, showProfile, handleViewSignedDialog, handleShowContacts, handleUpload, handleMakeAdmin, disableAdminRevoke, handleShowProducts, paginationLoader} = props;
    const profileLink = 
    {
        fontWeight:"bold",
        fontSize: "16px",
        "&:hover": {
            color: "#0000ff"
        },
        textTransform:"none",
        whiteSpace: 'nowrap',
        textDecoration: 'none',
        cursor: "pointer",
        color: "#00BFFF",
        display:"inline"
    }
  return (
    <>
    {paginationLoader ? 
    <Box sx={{textAlign:"center", minHeight:"35vh", marginTop:10}}>
        <img src="assets/loader/Spinner-px.gif" alt={"loading..."} height={'10%'} />
    </Box>
    :
    <Box>
        {rowData.map((row:any) => {
            return(
                <>
                <Card sx={{width:"100%", padding:3, marginY:4, boxShadow:3, shadow:0, border:0}}>
                    <Grid container>
                        <Grid item xs={1}><PersonIcon/></Grid>
                        <Grid item xs={8}>
                            <Typography
                                onClick={userType === "product"?(e) => showProfile(row,"product"):(e) => showProfile(row)}
                                sx={{
                                    fontWeight:"bold",
                                    fontSize: "20px",
                                    "&:hover": {
                                        color: "#0000ff"
                                    },
                                    textTransform:"none",
                                    whiteSpace: 'nowrap',
                                    textDecoration: 'none',
                                    cursor: "pointer",
                                    color: "#00BFFF",
                                    display:"inline"
                                }}
                            >
                                {row.name ? <>{row.name}</>:<>{row.firstName} {row.lastName}</>}
                            </Typography>
                        </Grid>
                        <Grid item xs={3} sx={{justifyContent:"right", display:"flex", alignItems:"right"}}>
                            <Box 
                                sx={{
                                    backgroundColor: statusColor[(userType === "contact" || userType === "productContacts")?row.isAdmin?"admin":"contact":row.isApproved],
                                    color: statusColorText[(userType === "contact" || userType === "productContacts")?row.isAdmin?"admin":"contact":row.isApproved],
                                    padding: 1,
                                    width: 110,
                                    borderRadius: 12,
                                    whiteSpace:"noWrap"
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                        fontSize: "15px"
                                    }}
                                >
                                    {firstCapital((userType === "contact" || userType === "productContacts")?row.isAdmin?"admin":"contact":row.isApproved)}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={1}/>
                        <Grid item xs={11}>
                            <Typography variant="caption" sx={{fontWeight:"light"}}>
                                {userType !== "contact" ?
                                    <>Date: {convertDate(row.createdDatetime)}</>
                                    :
                                    <>Email: {row.email}</>
                                }
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {(userType === "contact" || userType === "productContacts")&& 
                            <>
                                <Grid container sx={{height:"50px",justifyContent:"center", alignItems:"center"}}>
                                    <Grid item xs={6}>
                                        Designation
                                    </Grid>
                                    <Grid item xs={6} sx={{justifyContent:"right", display:"flex", alignItems:"right"}}>
                                        {row.designation?
                                        <Typography sx={{fontWeight:"bold"}}>{row.designation}</Typography>
                                    :
                                    <Typography variant="caption" sx={{fontWeight:"light"}}>No designation found</Typography>}
                                    </Grid>
                                </Grid>
                                <Grid container sx={{height:"50px",justifyContent:"center", alignItems:"center", backgroundColor:"#fff5e8"}}>
                                    <Grid item xs={3}>
                                        Phone
                                    </Grid>
                                    <Grid item xs={9} sx={{justifyContent:"right", display:"flex", alignItems:"right"}}>
                                        <Typography sx={{fontWeight:"bold"}}>{convertNumber(row.phoneNumber1)}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container sx={{height:"50px",justifyContent:"center", alignItems:"center"}}>
                                    <Grid item xs={9}>
                                        Associated Products
                                    </Grid>
                                    <Grid item xs={3} sx={{justifyContent:"right", display:"flex", alignItems:"right"}}>
                                        <Typography sx={{fontWeight:"bold"}}>{row.productId.length}</Typography>
                                    </Grid>
                                </Grid>
                            </>}
                            {(userType === "productList")&&
                            <>
                                <Grid container sx={{backgroundColor:"#fff5e8", height:"50px",justifyContent:"center", alignItems:"center"}}>
                                    <Grid item xs={6}>
                                        Default Price
                                    </Grid>
                                    <Grid item xs={6} sx={{justifyContent:"right", display:"flex", alignItems:"right"}}>
                                        <Typography sx={{fontWeight:"bold"}}>{row.defaultPrice ? convertCurrency(row.defaultPrice) : "--"}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container sx={{height:"50px",justifyContent:"center", alignItems:"center"}}>
                                    <Grid item xs={6}>
                                        URL
                                    </Grid>
                                    <Grid item xs={6} sx={{justifyContent:"right", display:"flex", alignItems:"right"}}>
                                        <Typography component="a" href={`${row.url}`} target='blank' style={linkCol}>{row.url}</Typography>
                                    </Grid>
                                </Grid>
                            </>}
                            {(userType === "product" || userType === "vendorProduct") && 
                            <>
                                <Grid container sx={{backgroundColor:userType==="product"?"#fff5e8":"", height:"50px",justifyContent:"center", alignItems:"center"}}>
                                    <Grid item xs={6}>
                                        Vendor
                                    </Grid>
                                    <Grid item xs={6} sx={{justifyContent:"right", display:"flex", alignItems:"right"}}>
                                        <Typography onClick={(e) => showProfile(row, "vendor")} sx={profileLink}>{row.vendorId.name}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container sx={{backgroundColor:userType==="vendorProduct"?"#fff5e8":"", height:"50px",justifyContent:"center", alignItems:"center"}}>
                                    <Grid item xs={6}>
                                        Brochure
                                    </Grid>
                                    <Grid item xs={6} sx={{justifyContent:"right", display:"flex", alignItems:"right"}}>
                                        {(row.documentCount && row.documentCount>0) ? 
                                            <Typography
                                                sx={profileLink}
                                                onClick={(e) => brochureShow(row)}
                                            >
                                                View Brochures
                                                <Badge badgeContent={row.documentCount ? row.documentCount: 0} color="primary" sx={{ paddingRight: 2, marginRight:1 }} showZero/>
                                            </Typography>
                                            : 
                                            <Typography>No brochure found</Typography>
                                        }
                                    </Grid>
                                </Grid>
                                {userType === "vendorProduct" &&
                                <Grid container sx={{ height:"50px",justifyContent:"center", alignItems:"center"}}>
                                    <Grid item xs={6}>
                                        Action
                                    </Grid>
                                    <Grid item xs={6} sx={{justifyContent:"right", display:"flex", alignItems:"right"}}>
                                        {(row.contactId && row.contactId.length>0) ? 
                                            <Typography
                                                sx={profileLink}
                                                onClick={(e) => handleShowContacts(row)}
                                            >
                                                Show Contacts
                                                <Badge badgeContent={row.contactId.length ? row.contactId.length: 0} color="primary" sx={{ paddingRight: 2, marginRight:1 }} showZero/>
                                            </Typography>
                                            : 
                                            <Typography>No brochure found</Typography>
                                        }
                                    </Grid>
                                </Grid>
                            }
                            </>}
                            {(userType === "connector" || userType === "vendor") && 
                            <>
                            <Grid container sx={{backgroundColor:userType==="connector"?"#fff5e8":"", height:"50px",justifyContent:"center", alignItems:"center"}}>
                                <Grid item xs={6}>
                                    Phone Number
                                </Grid>
                                <Grid item xs={6} sx={{justifyContent:"right", display:"flex", alignItems:"right"}}>
                                    {row.id === editable ? 
                                        <Checkbox name="phoneNumberVerified" checked={rowState.phoneNumberVerified} onChange={e => { toggleHandler(e, rowState)}} color="success" />
                                    :
                                    <>
                                        {(row.isApproved === "pending" || row.isApproved === "revoked") &&
                                            <Checkbox name="phoneNumberVerified" checked={row.phoneNumberVerified} onChange={e => { handleEdit(row); toggleHandler(e, row) }} color="success" />}
                                        {(row.isApproved === "approved" || row.isApproved === "rejected") &&
                                            <Checkbox name="phoneNumberVerified" checked={row.phoneNumberVerified} disabled />
                                        }

                                    </>}
                                </Grid>
                            </Grid>
                            <Grid container sx={{backgroundColor:userType==="vendor"?"#fff5e8":"", height:"50px", justifyContent:"center", alignItems:"center"}}>
                                <Grid item xs={6}>
                                    Email
                                </Grid>
                                <Grid item xs={6} sx={{justifyContent:"right", display:"flex", alignItems:"right"}}>
                                    {row.id === editable ? 
                                        <Checkbox name="emailVerified" checked={rowState.emailVerified} onChange={e => { toggleHandler(e, rowState)}} color="success" />
                                    :
                                    <>
                                        {(row.isApproved === "pending" || row.isApproved === "revoked") &&
                                            <Checkbox name="emailVerified" checked={row.emailVerified} onChange={e => { handleEdit(row); toggleHandler(e, row) }} color="success" />}
                                        {(row.isApproved === "approved" || row.isApproved === "rejected") &&
                                            <Checkbox name="emailVerified" checked={row.emailVerified} disabled />
                                        }

                                    </>}
                                </Grid>
                            </Grid>
                            <Grid container sx={{backgroundColor:userType==="connector"?"#fff5e8":"", height:"50px", justifyContent:"center", alignItems:"center"}}>
                                <Grid item xs={6}>
                                    Documents
                                </Grid>
                                <Grid item xs={6} sx={{justifyContent:"right", display:"flex", alignItems:"right"}}>
                                    {row.id === editable ? 
                                        <Checkbox name="legalDocsVerified" checked={rowState.legalDocsVerified} onChange={e => { toggleHandler(e, rowState)}} color="success" />
                                    :
                                    <>
                                        {(row.isApproved === "pending" || row.isApproved === "revoked") &&
                                            <Checkbox name="legalDocsVerified" checked={row.legalDocsVerified} onChange={e => { handleEdit(row); toggleHandler(e, row) }} color="success" />}
                                        {(row.isApproved === "approved" || row.isApproved === "rejected") &&
                                            <Checkbox name="legalDocsVerified" checked={row.legalDocsVerified} disabled />
                                        }

                                    </>}
                                </Grid>
                            </Grid>
                            {userType === "vendor" && 
                            <Grid container sx={{height:"50px",backgroundColor:"#fff5e8", alignItems:"center"}}>
                                <Grid item xs={6}>
                                    Products
                                </Grid>
                                <Grid item xs={6} sx={{justifyContent:"right", display:"flex", alignItems:"right"}}>
                                    {row.productCount > 0?
                                    <Checkbox name="productsServicesVerified" checked={true} disabled color="success" />
                                    :
                                    <Checkbox name="productsServicesVerified" checked={false} disabled color="success" />}
                                </Grid>
                            </Grid>
                            } 
                            <Grid container sx={{height:"50px", alignItems:"center"}}>
                                <Grid item xs={6}>
                                    Address
                                </Grid>
                                <Grid item xs={6} sx={{justifyContent:"right", display:"flex", alignItems:"right"}}>
                                    <Checkbox name="addressVerified" checked={row.addressVerified} disabled />
                                </Grid>
                            </Grid>
                            </>
                            }
                            {(userType === "productContacts" || userType === "productList") ?
                            <>
                            </>
                            :
                            <Grid container sx={{backgroundColor:"#fff5e8", height:"55px", display:"flex", justifyContent:"center", alignItems:"center"}}>
                                {(userType === "connector" || userType === "vendor") &&
                                <Grid item xs={12} spacing={2} sx={{justifyContent:"center", display:"flex", alignItems:"center"}}>
                                {row.id === editable?
                                <>
                                    {editLoader?<CircularProgress size="1.6rem" />:
                                    <>
                                    <Button sx={{flexDirection:"column", marginX:1, color:"green"}}  onClick={() => { onSave();}} >
                                        <SaveIcon/>
                                        <span style={{fontSize:"small"}}>Save</span>
                                    </Button>
                                    <Button sx={{flexDirection:"column", color:"red", marginX:1}}  onClick={() => cancelEdit(row)} >
                                        <CancelIcon/>
                                        <span style={{fontSize:"small"}}>Cancel</span>
                                    </Button>
                                    </>}
                                </>
                                :
                                <>
                                    {approveLoader === row.id ?
                                        <CircularProgress size="1.6rem" />
                                    :
                                    <>
                                        <Button sx={{flexDirection:"column", marginX:1, color:"green"}} disabled={checkApprove(row) || (row.isApproved === "approved")} onClick={(e) => { onApprove(row, "approved") }}>
                                            <VerifiedUserIcon/>
                                            <span style={{fontSize:"small"}}>Approve</span>
                                        </Button>
                                        {row.isApproved === "approved"?
                                        <>
                                        <Button sx={{flexDirection:"column", color:"red", marginX:1}} onClick={(e) => onRevoke(row)}>
                                            <PersonOffIcon/>
                                            <span style={{fontSize:"small"}}>Revoke</span>
                                        </Button>
                                        </>:
                                        <>
                                        <Button sx={{flexDirection:"column", color:"red", marginX:1}} onClick={(e) => onClickReject(row,"rejected")}>
                                            <PersonOffIcon/>
                                            <span style={{fontSize:"small"}}>Reject</span>
                                        </Button>
                                        </>}
                                        <Button onClick={(e) => handleViewSignedDialog(row)} sx={{flexDirection:"column", marginX:1}}>
                                            <ArticleIcon/>
                                            <span style={{fontSize:"small"}}>Signed</span>
                                        </Button>
                                    </>}
                                </>}
                                </Grid>}
                                {userType === "product" &&
                                <>
                                    {approveLoader === row.id ?
                                        <CircularProgress size="1.6rem" />
                                    :
                                    <>
                                        <Button sx={{flexDirection:"column", marginX:2, color:"green"}} disabled={(row.isApproved === "approved")} onClick={(e) => { onApprove(row, "approved") }}>
                                            <VerifiedUserIcon/>
                                            <span style={{fontSize:"small"}}>Approve</span>
                                        </Button>
                                        <Button sx={{flexDirection:"column", color:"red", marginX:2}} disabled = {row.isApproved==="rejected"} onClick={(e) => onClickReject(row,"rejected")}>
                                            <PersonOffIcon/>
                                            <span style={{fontSize:"small"}}>Reject</span>
                                        </Button>
                                    </>}
                                </>}
                                {userType === "contact" &&
                                <>
                                    {approveLoader === row.id ?
                                        <CircularProgress size="1.6rem" />
                                    :
                                    <>
                                        {row.isAdmin?
                                            <Button sx={{flexDirection:"column", color:"red", marginX:1}} disabled = {!row.isAdmin || disableAdminRevoke} onClick={(e) => handleMakeAdmin(row,"remove")}>
                                                <PersonOffIcon/>
                                                <span style={{fontSize:"small"}}>Revoke</span>
                                            </Button>
                                        :
                                            <Button sx={{flexDirection:"column", marginX:1, color:"green"}} disabled = {row.isAdmin} onClick={(e) => { handleMakeAdmin(row,"makeAdmin")}}>
                                                <VerifiedUserIcon/>
                                                <span style={{fontSize:"small"}}>Make Admin</span>
                                            </Button>
                                        }
                                        <Button sx={{flexDirection:"column", marginX:1}} onClick={(e) => handleShowProducts(row)}>
                                            <FormatListBulletedIcon/>
                                            <span style={{fontSize:"small"}}>Products</span>
                                        </Button>
                                    </>}
                                </>}
                                {userType === "vendorProduct" && 
                                <>
                                    {approveLoader === row.id ?
                                        <CircularProgress size="1.6rem" />
                                    :
                                    <>
                                        <Button sx={{flexDirection:"column", marginX:2}} onClick={(e) => { handleUpload(row) }}>
                                            <UploadFileIcon/>
                                            <span style={{fontSize:"small"}}>Brochure</span>
                                        </Button>
                                        <Button sx={{flexDirection:"column", marginX:2}} onClick={(e) => handleEdit(row)}>
                                            <ModeEditIcon/>
                                            <span style={{fontSize:"small"}}>Edit</span>
                                        </Button>
                                    </>}
                                </>
                                }
                            </Grid>}
                        </Grid>
                    </Grid>
                </Card>
                </>
            )
        })}
    </Box>
    }
    </>
  )
}

export default TableCardComponent