import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    Grid,
    TextField,
    Typography,
    Alert,
    DialogActions,
    DialogContent,
    Divider,
    AlertTitle,
    Autocomplete,
    Card,
    Tooltip,
    InputAdornment,
    DialogTitle,
    IconButton,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Deal from '../deals/RegistrationFormScreens/Deal';
import NumberFormatCustom from '../deals/NumberFormatCustom';
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { convertDate } from '../Admin/Approvals/util';
import './styles.css';
import { firstCapital } from '../Admin/Approvals/util';
import DocumentUpload from './DocumentUpload';
import DocumentPreview from './DocumentPreview';
import FormLoader from '../deals/FormLoader';
import CloseIcon from '@mui/icons-material/Close';

const TransactionForm = (props: any) => {
    const {
        showDialog,
        handleClose,
        handleRegister,
        formInfo,
        setFormInfo,
        saveLoader,
        getOptions,
        deals,
        handleChange,
        cardDeal,
        currentDeal,
        formData,
        setFormData,
        file,
        setFile,
        showUpload,
        setShowUpload,
        show,
        setShow,
        formType,
        handleInvoiceUpload,
        dealValidation,
        invoiceAmountValidation,
        receivedAmountValidation,
        inputHandler
    } = props;
    const style = { padding: 1, boxShadow: 0, paddingX: 3, paddingTop: 2 };
    
    const [dueDate, setDueDate] = React.useState<Dayjs | null>(dayjs());
    const [receivedDate, setReceivedDate] = React.useState<Dayjs | null>(dayjs());
    const [today, setToday] = React.useState<Dayjs | null>(dayjs());
    const[disableSubmit, setDisableSubmit] = useState(true);
    const [disableReceivedDate, setDisableReceivedDate] = useState(true);
    const [error, setError] = useState(false);
    useEffect(() => {
        
        setFormData({ ...formData, dueDate: dueDate?.format().slice(0,-6), endDate: (String(Number(dueDate?.format().slice(0,4))+1)+dueDate?.format().slice(4)).slice(0,-6)  });
    }, [dueDate,showDialog]);

    useEffect(() => {
        
        if(formData.receivedAmount === 0 || formData.receivedAmount === null){
            setFormData({ ...formData, receivedDate: null});
            setDisableReceivedDate(true)
        }
        else{
            setFormData({ ...formData, receivedDate: receivedDate?.format().slice(0,-6)});
            setDisableReceivedDate(false);
        }
    }, [receivedDate, formData.receivedAmount]);

    useEffect(() => {
        if( deals && deals.length === 0){
            setDisableSubmit(true)
        }
    },[deals])
    const infoCompponent = () => {
        return (
            <>
                <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                    Please follow the following steps to get started with the a new {formType}:
                </Typography>
                <br />
                <Typography sx={{ fontSize: 14, color: '', textShadow: 3 }}>
                    {formType === "transaction"?
                    <>
                        1.Select a deal from the deals dropdown.<br/>
                        2.Fill in the due date corressponding to the first invoice.<br/>
                        3.Fill in the invoice amount corressponding to the first invoice.<br/>
                        4.Fill in the received amount corressponding to the first invoice.<br/>
                    </>
                    :
                    <>
                        1.Fill in the due date corressponding to the new invoice.<br/>
                        2.Fill in the invoice amount corressponding to the new invoice.<br/>
                        3.Fill in the received amount corressponding to the new invoice.<br/>
                    </>}
                </Typography>
            </>
        );
    };

    const handleFileChange = (file: any) => {
        if(file){
            if(file.size >= 5000000){
                setError(true);
                setFile(null);
            }
            else{
                setFile(file);
                setError(false);
            }
        } 
    };

    const toggleUpload = () => {
        setShowUpload(!showUpload);
        
        setShow(true);
    };

    const [numPages, setNumPages] = useState(null);

    const onDocumentLoadSuccess = (numPages: any) => {
        
        setNumPages(numPages);
    };

    const fileTypes = {
        'application/pdf':[]
    };

    const asterisk = () => {
        return(<Typography sx={{color:"#00BFFF", display:"inline", fontSize:"20px", fontWeight:"bold"}}>*</Typography>)
    }

    return (
        <>
            <Dialog
                open={showDialog}
                fullWidth
                maxWidth="lg"
                sx={{
                    '.MuiDialog-paper': {
                        position: 'absolute',
                        width: {xs:"90%", sm:"85%", md:"80%", lg:"80%", xl:"80%"},
                        height: '700px',
                        minWidth: {xs:"90%", sm:"85%", md:"80%", lg:"80%", xl:"80%"},
                    },
                }}
            >   
                {deals?<></>:<><FormLoader/></>}
                <DialogTitle sx={{ textAlign: "center", fontWeight: 'bold', backgroundColor: "#F0F7FF", fontSize:{xs:"large", sm:"large", md:"34px", lg:"34px", xl:"34px"} }}>
                    Transaction Invoice
                        <Tooltip
                            title={infoCompponent()}
                            arrow
                            placement="right-end"
                        >
                            <Button>
                                <InfoOutlinedIcon />
                            </Button>
                        </Tooltip>
                    <IconButton onClick={handleClose} sx={{ float: "right", color: "red" }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={1}>
                        {formType === "transaction"?
                        <>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography
                                                sx={{
                                                    fontWeight: 'bold',
                                                    fontSize: '20px',
                                                }}
                                            >
                                                Select Deal {asterisk()}
                                            </Typography>
                                            <Typography variant="caption">
                                                Select a deal to associate with
                                                the transaction
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Autocomplete
                                                options={deals}
                                                getOptionLabel={
                                                    getOptions
                                                }
                                                id="dealId"
                                                onChange={handleChange}
                                                renderInput={params => (
                                                <TextField
                                                    sx={{width:"97%"}}
                                                    {...params}
                                                    label="Deal"
                                                    type="text"
                                                    variant="outlined"
                                                    error={dealValidation.error}
                                                    helperText={dealValidation.message}
                                                />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Card
                                        sx={{
                                            marginTop: 2,
                                            width: '100%',
                                            borderRadius: 4,
                                            boxShadow: 0,
                                        }}
                                    >
                                        <Box sx={style}>
                                            <Deal
                                                currentSelection={currentDeal}
                                                cardDeal={cardDeal}
                                            />
                                        </Box>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        </>
                        :
                        <></>}
                        <Grid item xs={12}>
                            <Grid container spacing={5}>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography
                                                sx={{
                                                    fontWeight: 'bold',
                                                    fontSize: '20px',
                                                }}
                                            >
                                                Invoice Amount {asterisk()}
                                            </Typography>
                                            <Typography variant="caption">
                                                Enter invoice amount to associate with the transaction
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={{ marginTop: 0.7 }}>
                                            <TextField
                                                variant="outlined"
                                                sx={{ width: '100%' }}
                                                type="text"
                                                label="Amount"
                                                name="invoiceAmount"
                                                error={invoiceAmountValidation.error}
                                                helperText={invoiceAmountValidation.message}
                                                InputProps={{
                                                    inputComponent: NumberFormatCustom,
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            $
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                onChange={inputHandler}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography
                                                sx={{
                                                    fontWeight: 'bold',
                                                    fontSize: '20px',
                                                }}
                                            >
                                                Due date {asterisk()}
                                            </Typography>
                                            <Typography variant="caption">
                                                Enter a due date to associate with the
                                                transaction
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <LocalizationProvider
                                                dateAdapter={AdapterDayjs}
                                                sx={{ marginTop: 0 }}
                                            >
                                                <DemoContainer
                                                    components={[
                                                        'DatePicker',
                                                        'DatePicker',
                                                    ]}
                                                    sx={{ marginTop: 0 }}
                                                >
                                                    <DatePicker
                                                        value={dueDate}
                                                        onChange={newValue =>
                                                            setDueDate(newValue)
                                                        }
                                                        slotProps={{
                                                            textField: {
                                                                inputProps:{
                                                                    disabled:true
                                                                }
                                                            },
                                                          }}
                                                        sx={{
                                                            marginTop: 0,
                                                            width: '96%',
                                                            fontWeight:"bold"
                                                        }}
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        {formType === "invoice"?
                        <>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography
                                                sx={{
                                                    fontWeight: 'bold',
                                                    fontSize: '20px',
                                                }}
                                            >
                                                Received Amount
                                            </Typography>
                                            <Typography variant="caption">
                                            Enter received amount to associate with the transaction
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={{ marginTop: 0.7 }}>
                                            <TextField
                                                variant="outlined"
                                                sx={{ width: '100%' }}
                                                type="text"
                                                label="Amount"
                                                name="receivedAmount"
                                                error={receivedAmountValidation.error}
                                                helperText={receivedAmountValidation.message}
                                                InputProps={{
                                                    inputComponent: NumberFormatCustom,
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            $
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                onChange={inputHandler}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography
                                                sx={{
                                                    fontWeight: 'bold',
                                                    fontSize: '20px',
                                                }}
                                            >
                                                Received On
                                            </Typography>
                                            <Typography variant="caption">
                                                Enter the date to associate with the received amount
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <LocalizationProvider
                                                dateAdapter={AdapterDayjs}
                                                sx={{ marginTop: 0 }}
                                            >
                                                <DemoContainer
                                                    components={[
                                                        'DatePicker',
                                                        'DatePicker',
                                                    ]}
                                                    sx={{ marginTop: 0 }}
                                                >
                                                    <DatePicker
                                                        value={receivedDate}
                                                        onChange={newValue =>
                                                            setReceivedDate(newValue)
                                                        }
                                                        sx={{
                                                            marginTop: 0,
                                                            width: '96%',
                                                        }}
                                                        disabled={disableReceivedDate}
                                                        slotProps={{
                                                            textField: {
                                                                inputProps:{
                                                                    disabled:true
                                                                }
                                                            },
                                                          }}
                                                        maxDate={today}
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        </>:<></>}
                        <Grid
                            item
                            xs={12}
                            alignItems="center"
                            sx={{ marginTop: 1 }}
                        >
                            <Grid container spacing={5}>
                                <Grid
                                    item
                                    xs={12} sm={12} md={6} lg={6} xl={6}
                                    sx={{ justifyContent: 'center' }}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: 'bold',
                                            fontSize: '20px',
                                            marginBottom:1
                                        }}
                                    >
                                         Upload Invoice {asterisk()}
                                    </Typography>
                                    <Box sx={{minWidth:"100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        <DocumentUpload file={file} handleFileChange={handleFileChange} toggleUpload={toggleUpload} fileTypes={fileTypes} type={"pdf"}/>
                                    </Box>
                                    <Box sx={{textAlign:"center"}}>
                                        {error && <Typography sx={{color:"red", marginTop:1}}>The file size is exceeding the maximum file size of 5MB !</Typography>}
                                    </Box>
                                </Grid>
                                <Grid
                                    item
                                    xs={12} sm={12} md={6} lg={6} xl={6}
                                    sx={{ justifyContent: 'center' }}
                                >
                                    <DocumentPreview file={file} onDocumentLoadSuccess={onDocumentLoadSuccess} show={show} numPages={numPages} setFile={setFile}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ marginTop: 2 }}>
                            <Divider />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ paddingRight: 5, paddingY: 2 }}>
                    <Button
                        onClick={handleClose}
                        variant="contained"
                        color="error"
                        sx={{ width: 100, height: 45, padding: 1 }}
                        disabled={saveLoader}
                    >
                        cancel
                    </Button>
                    {formType === "transaction"?
                    <>
                    <Button
                        onClick={handleRegister}
                        sx={{ width: 100, height: 45, padding: 1 }}
                        variant="contained"
                    >
                        {saveLoader ? (
                            <>
                                <CircularProgress sx={{color:"white"}} />
                            </>
                        ) : (
                            <>Save</>
                        )}
                    </Button>
                    </>
                    :
                    <>
                    <Button
                        onClick={handleInvoiceUpload}
                        sx={{ width: 100, height: 45, padding: 1 }}
                        variant="contained"
                    >
                        {saveLoader ? (
                            <>
                                <CircularProgress sx={{color:"white"}}/>
                            </>
                        ) : (
                            <>Save</>
                        )}
                    </Button>
                    </>}
                </DialogActions>
            </Dialog>
        </>
    );
};

export default TransactionForm;
