import { Box, Card, Container, Grid, Typography } from '@mui/material'
import React from 'react';
import { LogoField } from '../companies/LogoField';
import data from './Data.json';

const AllProducts = () => {
  return (
    <Grid container spacing={2}>
      <Grid item>
        <Typography variant="h4" mt={3}>
          All Products
        </Typography>
      </Grid>
        <Grid container spacing={1} mt={1}>
          {data.products.map((record) =>
            <>
              <Grid item xs={4}>
                <Box>
                  <Card
                    style={{
                      height: 150,
                    }}
                  >
                    <Box padding={1}>
                      <Box sx={{ display: "flex", justifyContent: "center", padding: 1 }}>
                        <LogoField size="medium" />
                      </Box>
                      <Box sx={{ marginLeft: 1 }}>
                        <Typography variant="body2" gutterBottom>
                          {record.name}
                        </Typography>
                        <Typography
                          variant="caption"
                          color="textSecondary"
                        >
                          {record.default_price.toLocaleString('en-US', {
                            notation: 'compact',
                            style: 'currency',
                            currency: 'USD',
                            currencyDisplay: 'narrowSymbol',
                            minimumSignificantDigits: 3,
                          })}
                          <br />
                          {record.type}
                          <br />
                          {record.description}
                        </Typography>
                      </Box>
                    </Box>
                  </Card>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      <Grid item>
        <Typography variant="h4" mt={3}>
          All Services
        </Typography>
      </Grid>
      <Grid container spacing={1} mt={1}>
        {data.services.map((record) =>
          <>
            <Grid item xs={4}>
              <Box>
                <Card
                  style={{
                    height: 150,
                  }}
                >
                  <Box padding={1}>
                    <Box sx={{ display: "flex", justifyContent: "center", padding: 1 }}>
                      <LogoField size="medium" />
                    </Box>
                    <Box sx={{ marginLeft: 1 }}>
                      <Typography variant="body2" gutterBottom>
                        {record.name}
                      </Typography>
                      <Typography
                        variant="caption"
                        color="textSecondary"
                      >
                        {record.default_price.toLocaleString('en-US', {
                          notation: 'compact',
                          style: 'currency',
                          currency: 'USD',
                          currencyDisplay: 'narrowSymbol',
                          minimumSignificantDigits: 3,
                        })}
                        <br />
                        {record.type}
                        <br />
                        {record.description}
                      </Typography>
                    </Box>
                  </Box>
                </Card>
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  )
}

export default AllProducts