import {Box, CircularProgress, Card, Grid, Typography, Button, InputAdornment, TextField } from '@mui/material';
import React from 'react'
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { convertCurrency, convertDate } from '../Admin/Approvals/util';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useAuth0 } from '@auth0/auth0-react';
import { ModeEdit } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import NumberFormatCustom from '../deals/NumberFormatCustom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

const TransactionsTableCard = (props:any) => {
    const {user, rowData, userType, approveLoader, showProfile, handleUpload, invoiceShow, onEdit, editable, onSave, cancelEdit, formData, amountValidation, amountHandler, receivedDate, setReceivedDate, today, saveLoader, showCurrentInvoice, paginationLoader} = props;
    const profileLink = 
    {
        fontWeight:"bold",
        fontSize: "16px",
        "&:hover": {
            color: "#0000ff"
        },
        textTransform:"none",
        whiteSpace: 'nowrap',
        textDecoration: 'none',
        cursor: "pointer",
        color: "#00BFFF",
        display:"inline"
    }
  return (
    <>
    {paginationLoader
    ? 
    <Box sx={{textAlign:"center", minHeight:"35vh", marginTop:10}}>
        <img src="assets/loader/Spinner-px.gif" alt={"loading..."} height={'10%'} />
    </Box>
    :
    <Box>
        {rowData.map((row:any) => {
            return(
                <>
                <Card sx={{width:"100%", padding:3, marginY:4, boxShadow:3, shadow:0, border:0}}>
                    <Grid container>
                        <Grid item xs={1}><ReceiptIcon/></Grid>
                        <Grid item xs={11}>
                            <Typography
                                onClick={(e) => showProfile(row.dealId, "deal")}
                                sx={{
                                    fontWeight:"bold",
                                    fontSize: userType==="transaction"?"20px":"16px",
                                    "&:hover": {
                                        color: userType==="transaction"?"#0000ff":""
                                    },
                                    textTransform:"none",
                                    whiteSpace: 'nowrap',
                                    textDecoration: 'none',
                                    cursor: userType==="transaction"?"pointer":"",
                                    color: userType==="transaction"?"#00BFFF":"",
                                    display:"inline"
                                }}
                            >
                                {userType === "transaction" && <>{row.dealId.name}</>}
                                {userType === "invoice" && <>{row.name}</>}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}/>
                        <Grid item xs={6}>
                            <Typography variant="caption" sx={{fontWeight:"light"}}>{userType==="transaction"?<>Started:</>:<>Uploaded:</>} {convertDate(row.createdDatetime)}</Typography>
                        </Grid>
                        {(userType === "invoice" && editable === row.id) && 
                        <>
                            {amountValidation.error && 
                            <Card sx={{backgroundColor:"#F0F0F0", marginY:2, padding:1}}>
                                <Typography sx={{fontWeight:"light", color:"red", fontSize:"12px"}}>{amountValidation.message}</Typography>
                            </Card>
                            }
                        </>}
                        <Grid item xs={12}>
                            {userType === "transaction" && 
                            <>
                            <Grid container sx={{backgroundColor:user?.role === "admin"?"#fff5e8":"", height:"50px",justifyContent:"center", alignItems:"center"}}>
                                <Grid item xs={6}>
                                    Product
                                </Grid>
                                <Grid item xs={6} sx={{justifyContent:"right", display:"flex", alignItems:"right"}}>
                                    <Typography
                                        onClick={(e) => showProfile(row.productId, "product")}
                                        sx={profileLink}
                                    >
                                        {row.productId.name}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container sx={{backgroundColor:user?.role === "admin"?"":"#fff5e8", height:"50px",justifyContent:"center", alignItems:"center"}}>
                                <Grid item xs={6}>
                                    Customer
                                </Grid>
                                <Grid item xs={6} sx={{justifyContent:"right", display:"flex", alignItems:"right"}}>
                                    <Typography
                                        onClick={(e) => showProfile(row.customerId, "customer")}
                                        sx={profileLink}
                                    >
                                        {row.customerId.firstName} {row.customerId.lastName}
                                    </Typography>
                                </Grid>
                            </Grid>
                            {(user?.role === "connector" || user?.role === "admin") && 
                            <Grid container sx={{backgroundColor:user?.role === "admin"?"#fff5e8":"", height:"50px",justifyContent:"center", alignItems:"center"}}>
                                <Grid item xs={6}>
                                    Vendor
                                </Grid>
                                <Grid item xs={6} sx={{justifyContent:"right", display:"flex", alignItems:"right"}}>
                                    <Typography
                                        onClick={(e) => showProfile(row.vendorId,"vendor")}
                                        sx={profileLink}
                                    >
                                        {row.vendorId.name}
                                    </Typography>
                                </Grid>
                            </Grid>
                            }
                            {(user?.role === "vendor" || user?.role === "contact" || user?.role === "admin") && 
                            <Grid container sx={{ height:"50px",justifyContent:"center", alignItems:"center"}}>
                                <Grid item xs={6}>
                                    Connector
                                </Grid>
                                <Grid item xs={6} sx={{justifyContent:"right", display:"flex", alignItems:"right"}}>
                                    <Typography
                                        onClick={(e) => showProfile(row.connectorId, "connector")}
                                        sx={profileLink}
                                    >
                                        {row.connectorId.name}
                                    </Typography>
                                </Grid>
                            </Grid>
                            }
                            <Grid container sx={{backgroundColor:"#fff5e8", height:"50px",justifyContent:"center", alignItems:"center"}}>
                                <Grid item xs={6}>
                                    End Date
                                </Grid>
                                <Grid item xs={6} sx={{justifyContent:"right", display:"flex", alignItems:"right"}}>
                                   <Typography>
                                        {convertDate(row.endDate)}
                                   </Typography>
                                </Grid>
                            </Grid>
                            <Grid container sx={{height:"50px",justifyContent:"center", alignItems:"center"}}>
                                <Grid item xs={6}>
                                    Deal Amount
                                </Grid>
                                <Grid item xs={6} sx={{justifyContent:"right", display:"flex", alignItems:"right"}}>
                                    <Typography>
                                        {convertCurrency(row.dealId.amount)}
                                   </Typography>
                                </Grid>
                            </Grid>
                            <Grid container sx={{backgroundColor:"#fff5e8",height:"50px",justifyContent:"center", alignItems:"center"}}>
                                <Grid item xs={6}>
                                   Invoiced Amount
                                </Grid>
                                <Grid item xs={6} sx={{justifyContent:"right", display:"flex", alignItems:"right"}}>
                                    <Typography>
                                        {row.totalInvoiceAmount ? <>{convertCurrency(row.totalInvoiceAmount)}</> : <>$0.00</>}
                                   </Typography>
                                </Grid>
                            </Grid>
                            <Grid container sx={{height:"50px",justifyContent:"center", alignItems:"center"}}>
                                <Grid item xs={6}>
                                   Received Amount
                                </Grid>
                                <Grid item xs={6} sx={{justifyContent:"right", display:"flex", alignItems:"right"}}>
                                    <Typography>
                                        {row.totalReceivedAmount ? <>{convertCurrency(row.totalReceivedAmount)}</> : <>$0.00</>}
                                   </Typography>
                                </Grid>
                            </Grid>
                            </>
                            }
                            {userType === "invoice" && 
                            <>
                            <Grid container sx={{backgroundColor:"#fff5e8", height:"50px",justifyContent:"center", alignItems:"center"}}>
                                <Grid item xs={6}>
                                    Invoice Amount
                                </Grid>
                                <Grid item xs={6} sx={{justifyContent:"right", display:"flex", alignItems:"right"}}>
                                    <Typography>
                                        {convertCurrency(row.invoiceAmount)}
                                   </Typography>
                                </Grid>
                            </Grid>
                            <Grid container sx={{backgroundColor:user?.role === "admin"?"#fff5e8":"", height:"50px",justifyContent:"center", alignItems:"center"}}>
                                <Grid item xs={6}>
                                    Due Date
                                </Grid>
                                <Grid item xs={6} sx={{justifyContent:"right", display:"flex", alignItems:"right"}}>
                                    <Typography>
                                        {convertDate(row.dueDate)}
                                   </Typography>
                                </Grid>
                            </Grid>
                            <Grid container sx={{backgroundColor:"#fff5e8", height:editable===row.id?"70px":"50px" ,justifyContent:"center", alignItems:"center"}}>
                                <Grid item xs={editable === row.id?5:6}>
                                    {editable === row.id ?
                                   <>Amount</>
                                   :
                                   <>Received Amount</>}
                                </Grid>
                                <Grid item xs={editable === row.id?7:6} sx={{justifyContent:"right", display:"flex", alignItems:"right"}}>
                                    <Typography>
                                        {editable === row.id ?
                                        <>
                                        <TextField
                                            variant="filled"
                                            sx={{ width: "120px" }}
                                            type="text"
                                            name="receivedAmount"
                                            value={formData.receivedAmount}
                                            error={amountValidation.error}
                                            InputProps={{
                                                inputComponent: NumberFormatCustom,
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        $
                                                    </InputAdornment>
                                                ),
                                            }}
                                            onChange={amountHandler}
                                        />
                                        </>
                                        :
                                        <>{convertCurrency(row.receivedAmount)}</>}
                                   </Typography>
                                </Grid>
                            </Grid>
                            <Grid container sx={{  height:editable===row.id?"70px":"50px",justifyContent:"center", alignItems:"center"}}>
                                <Grid item xs={6}>
                                   {editable === row.id ?
                                   <>Date</>
                                   :
                                   <>Received On</>}
                                </Grid>
                                <Grid item xs={6} sx={{justifyContent:"right", display:"flex", alignItems:"right"}}>
                                    <Typography>
                                        {editable === row.id ?
                                        <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                        sx={{ marginTop: 0 }}
                                    >
                                        <DemoContainer
                                            components={[
                                                'DatePicker',
                                                'DatePicker',
                                            ]}
                                            sx={{ marginTop: 0 }}
                                        >
                                            <DatePicker
                                                value={receivedDate}
                                                onChange={newValue =>
                                                    setReceivedDate(newValue)
                                                }
                                                sx={{
                                                    marginTop: 0,
                                                    width:"120px"
                                                }}
                                                slotProps={{
                                                    textField: {
                                                        inputProps:{
                                                            disabled:true
                                                        }
                                                    },
                                                }}
                                                maxDate={today}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                        :
                                        <>{row.receivedDate?<>{convertDate(row.receivedDate)}</>:<></>}</>}
                                   </Typography>
                                </Grid>
                            </Grid>
                            </>}
                            <Grid container sx={{backgroundColor:"#fff5e8", height:"55px", display:"flex", justifyContent:"center", alignItems:"center"}}>
                                <Grid item xs={12} spacing={2} sx={{justifyContent:"center", display:"flex", alignItems:"center"}}>
                                    {approveLoader === row.id ?
                                        <CircularProgress size="1.6rem" />
                                    :
                                    <>
                                        {editable === row.id ?
                                        <>
                                        {saveLoader ?
                                            <CircularProgress size="1.6rem" />
                                        :
                                        <>
                                            <Button sx={{flexDirection:"column", marginX:1, color:"green"}} disabled={amountValidation.error} onClick={() => { onSave();}} >
                                                <SaveIcon/>
                                                <span style={{fontSize:"small"}}>Save</span>
                                            </Button>
                                            <Button sx={{flexDirection:"column", color:"red", marginX:1}}  onClick={() => cancelEdit(row)} >
                                                <CancelIcon/>
                                                <span style={{fontSize:"small"}}>Cancel</span>
                                            </Button>
                                        </>} 
                                        </>
                                        :
                                        <>
                                            {(user?.role === "vendor" && userType === "transaction") &&
                                            <Button sx={{flexDirection:"column", marginX:2}} onClick={(e) => { handleUpload(row) }}>
                                                <UploadFileIcon/>
                                                <span style={{fontSize:"small"}}>Invoice</span>
                                            </Button>
                                            }
                                            {(user?.role === "vendor" && userType === "invoice") &&
                                                <Button sx={{flexDirection:"column", marginX:2}} onClick={(e) => { onEdit(row) }}>
                                                    <ModeEdit/>
                                                    <span style={{fontSize:"small"}}>Edit</span>
                                                </Button>
                                            }
                                            {userType === "transaction" && 
                                                <Button sx={{flexDirection:"column", marginX:1}} onClick={(e) => { invoiceShow(row) }}>
                                                    <VisibilityIcon fontSize='small'/>
                                                    <span style={{fontSize:"small"}}>All Invoices</span>
                                                </Button>
                                            }
                                            {userType === "invoice" && 
                                                <Button sx={{flexDirection:"column", marginX:1}} onClick={(e) => { showCurrentInvoice(row) }}>
                                                    <VisibilityIcon fontSize='small'/>
                                                    <span style={{fontSize:"small"}}>Preview</span>
                                                </Button>
                                            }
                                        </>}
                                    </>}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
                </>
            )
        })}
    </Box>}
    </>
  )
}

export default TransactionsTableCard;