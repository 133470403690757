import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import {
    Alert,
    AlertTitle,
    Badge,
    Box,
    Button,
    Checkbox,
    Grid,
    TablePagination,
    Typography,
} from '@mui/material';
import data from './Data.json';
import EnhancedTableToolbar from '../misc/EnhancedTableToolbar';
import AddIcon from '@mui/icons-material/Add';
import './styles.css';
import { sortedRowInformation, getComparator } from '../utils/SortingHandler';
import SearchTextField from '../Layout/SearchTextField';
import { linkCol, linkHover, statusColor, statusColorText } from '../tableStyle';
import ProductForm from './ProductForm';
import TableHeadRow from '../Layout/TableHeadRow';
import axios from 'axios';
import { firstCapital } from '../Admin/Approvals/util';
import ProfileScreen from '../Profile Screens/ProfileScreen';
import UploadDoument from '../legalDocs/UploadDoument';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useFormik } from 'formik';
import * as yup from 'yup';
import ShowList from './ShowList';
import ShowBrochure from './ShowBrochure';
import ContactForm from '../Admin/VendorsContacts/ContactForm';
import StatusCell from '../Layout/StatusCell';
import TableCardComponent from '../Admin/Approvals/TableCardComponent';
import ErrorComponent from '../Layout/ErrorComponent';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ProfileAlerts from '../Layout/ProfileAlerts';

export default function ProductsTable(props: any) {
    const { user } = props;
    const [globalData, setGlobalData] = useState<any[]>();
    const [rowData, setRowData] = useState<any[]>();
    const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>('asc');
    const [valueToOrderBy, setValueToOrderBy] = useState('default_price');
    const [productSearch, setProductSearch] = useState('');
    const [editable, setEditable] = useState<Number>(0);
    const [selected, setSelected] = useState<readonly string[]>([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [showUpload, setShowUpload] = useState(false);
    const [file, setFile] = useState(null);
    const [show, setShow] = useState(false);
    const [deletable, setDeletable] = useState(false);
    const [formData, setFormData] = useState<any>({});
    const [vendorInfo, setVendorInfo] = useState<any>();
    const [error, setError] = useState(false);
    const [contactsList, setContactsList] = useState<any>();
    const [idArr, setIdArr] = useState(null);
    const [saveLoader, setSaveLoader] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [profileDialog, setProfileDialog] = useState(false);
    const [showContactsDialog, setShowContactsDialog] = useState(false);
    const [currentSelection, setCurrentSelection] = useState<any>();
    const [loader, setLoader] = useState(true);
    const [onChange, setOnChange] = useState(0);
    const [empty, setEmpty] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const [legalCount, setLegalCount] = useState(0);
    const [productCount, setProductCount] = useState(0);
    const handleChangePage = (event: any, newPage: any) => {
        setPage(newPage);
    }; const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const [amountValidate, setAmountValidate] = useState({
        amountError: false,
        amountErrorText: '',
    });
    const [contactValidate, setContactValidate] = useState({
        contactError: false,
        contactErrorText: '',
    });
    const [image, setImage] = useState<any>();
    const [editToggle, setEditToggle] = useState(false);
    const [changeStatus, setChangeStatus] = useState(false);
    const [vendorRegistrationLoader, setVendorRegistrationLoader] =
        useState(false);
    const validationSchema = yup.object({
        name: yup
            .string()
            .min(4, 'Should contain at least 4 characters')
            .max(45, 'Should contain at most 45 characters')
            .matches(
                /^[A-Za-z0-9 ]*$/,
                'Please enter valid name, cannot contain space or special characters'
            )
            .trim()
            .required('Name is required'),
        description: yup
            .string()
            .max(200, 'Description can contain 200 chatacters at max')
            .nullable(),
        url: yup
            .string()
            .matches(
                /((https):\/\/)(www\.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(\/[a-zA-Z0-9_\-]+)*\/?[a-zA-Z0-9#?%&=_\-\+\.\/]*$/,
                'Enter a valid url, should contain https.'
            )
            .max(60, 'Should contain at most 60 characters')
            .nullable(),
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            description: null,
            url: null,
        },
        validationSchema: validationSchema,
        onSubmit: values => {
            let amt = formData.defaultPrice;
            if (amt && amt < 0) {
                setAmountValidate({
                    ...amountValidate,
                    amountError: true,
                    amountErrorText: 'Default Price cannot be negative',
                });
                return;
            }
            if (amt && amt > 10000000) {
                setAmountValidate({
                    ...amountValidate,
                    amountError: true,
                    amountErrorText: 'Default Price value is too large',
                });
                return;
            }
            if (!formData.contactId) {
                setContactValidate({
                    ...contactValidate,
                    contactError: true,
                    contactErrorText:
                        'Please link atleast one contact with the product',
                });
                return;
            }
            if (!saveLoader) {
                setSaveLoader(true);
                let temp = {
                    ...formData,
                    ...values,
                    targetCustomer: formData.targetCustomer
                        ? formData.targetCustomer
                        : null,
                    isApproved: "pending"
                };
                if (productEdit) {
                    axios
                        .put(
                            process.env.REACT_APP_BASE_URL +
                            'product/' +
                            formData.id,
                            temp,
                            {
                                headers: {
                                    "Authorization": "Bearer " + user.accessToken,
                                }
                            }
                        )
                        .catch((error: any) => {
                            setSaveLoader(false);
                            setFormData({ targetCustomer: [] });
                            formik.resetForm();
                            alert(
                                'Error in updation of the product, please try again.'
                            );
                        })
                        .then((response: any) => {
                            setFormData({ targetCustomer: [] });
                            formik.resetForm();
                            setSaveLoader(false);
                            setImage(null);
                            setImageUpdateMessage(null);
                            setEditToggle(false);
                            setOpenDialog(false);
                            setOnChange(prevVal => prevVal + 1);
                        });
                } else {
                    let body = {
                        ...temp,
                        targetCustomer: formData.targetCustomer
                            ? formData.targetCustomer
                            : null,
                        createdBy: vendorInfo.name,
                        isApproved: 'pending',
                        modifiedBy: vendorInfo.name,
                        vendorId: {
                            id: vendorInfo.id,
                            name: vendorInfo.name
                        },
                    };
                    axios
                        .post(
                            process.env.REACT_APP_BASE_URL + 'product/register',
                            body,
                            {
                                headers: {
                                    "Authorization": "Bearer " + user.accessToken,
                                }
                            }
                        )
                        .catch((error: any) => {
                            setSaveLoader(false);
                            alert(
                                'Error in registering new product, please try again.'
                            );
                            setFormData({ targetCustomer: [] });
                            formik.resetForm();
                        })
                        .then((response: any) => {
                            let file = image;
                            setProductCount(prevVal => prevVal + 1);
                            if (response.data && file) {
                                axios
                                    .put(
                                        process.env.REACT_APP_BASE_URL +
                                        'product/image/' +
                                        response.data,
                                        { file },
                                        {
                                            headers: {
                                                'Content-type':
                                                    'multipart/form-data',
                                                "Authorization": "Bearer " + user.accessToken,
                                            },
                                        }
                                    )
                                    .catch((error: any) => {
                                        setSaveLoader(false);
                                        setFormData({ targetCustomer: [] });
                                        formik.resetForm();
                                    })
                                    .then((response: any) => {
                                        setOnChange(prevVal => prevVal + 1);
                                        setFormData({ targetCustomer: [] });
                                        formik.resetForm();
                                        setOpenDialog(false);
                                        setImage(null);
                                        setSaveLoader(false);
                                        setImageUpdateMessage(null);
                                        setEditToggle(false);
                                    });
                            } else {
                                setOnChange(prevVal => prevVal + 1);
                                setFormData({ targetCustomer: [] });
                                formik.resetForm();
                                setOpenDialog(false);
                                setImage(null);
                                setSaveLoader(false);
                                setImageUpdateMessage(<></>);
                                setEditToggle(false);
                            }
                        });
                }
            }
        },
    });

    useEffect(() => {
        if (user) {
            var userType = user?.role;
            if (user?.role === "vendor") {
                userType = "contact"
            }
            axios
                .get(
                    process.env.REACT_APP_BASE_URL +
                    userType +
                    '/mail/' +
                    user.email,
                    {
                        headers: {
                            "Authorization": "Bearer " + user.accessToken,
                        }
                    }
                )
                .catch((error: any) => {
                    setError(true);
                })
                .then((response: any) => {
                    var userId = 1;
                    if (user?.role === "vendor" || user?.role === "contact") {
                        userId = response.data.vendorId.id;
                        setVendorInfo(response.data.vendorId);
                        setProductCount(response.data.vendorId.productCount);
                    }
                    else {
                        userId = response.data.id;
                        setVendorInfo(response.data);
                        setProductCount(response.data.productCount);
                    }
                    if (userType === "contact") {
                        userType = "vendor"
                    }
                    axios.get(process.env.REACT_APP_BASE_URL + "document/" + userType + "/" + userId,
                        {
                            headers: {
                                "Authorization": "Bearer " + user.accessToken,
                            }
                        })
                        .then((response: any) => {
                            setLegalCount(response.data.length)
                        })
                });
        }
    }, [user]);

    useEffect(() => {
        if (vendorInfo) {
            setLoader(true);
            setEmpty(false);
            axios
                .get(
                    process.env.REACT_APP_BASE_URL +
                    'product/vendor/' +
                    vendorInfo.id,
                    {
                        headers: {
                            "Authorization": "Bearer " + user.accessToken,
                        }
                    }
                )
                .catch((error: any) => {
                    setError(true);
                    setLoader(false);
                    setError(true);
                })
                .then((response: any) => {
                    if (response.data.length === 0) {
                        setEmpty(true);
                    }

                    setRowData(response.data);
                    setGlobalData(response.data);
                    setLoader(false);
                });
        }
    }, [vendorInfo]);

    useEffect(() => {
        if (vendorInfo) {
            axios
                .get(
                    process.env.REACT_APP_BASE_URL +
                    'contact/vendor/' +
                    vendorInfo.id,
                    {
                        headers: {
                            "Authorization": "Bearer " + user.accessToken,
                        }
                    }
                )
                .catch((error: any) => {

                    setError(true);
                })
                .then((response: any) => {

                    setContactsList(response.data);
                    setVendorRegistrationLoader(false);
                });
        }
    }, [changeStatus, vendorInfo]);

    useEffect(() => {
        if (vendorInfo) {
            setLoader(true);
            setEmpty(false);

            axios
                .get(
                    process.env.REACT_APP_BASE_URL +
                    'product/vendor/' +
                    vendorInfo.id,
                    {
                        headers: {
                            "Authorization": "Bearer " + user.accessToken,
                        }
                    }
                )
                .catch((error: any) => {

                    setLoader(false);
                    setError(true);
                })
                .then((response: any) => {
                    if (response.data.length === 0) {
                        setEmpty(true);
                    }

                    setRowData(response.data);
                    setGlobalData(response.data);
                    setLoader(false);
                });
        }
    }, [onChange]);
    const inputHandler = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData({ ...formData, [name]: value });
    };
    useEffect(() => {
        let tempArr = [];
        if (globalData) {
            tempArr = [...globalData!];
            tempArr = tempArr.filter(row => {
                return row.name
                    .toLowerCase()
                    .includes(productSearch.toLowerCase());
            });
        }
        setRowData(tempArr);
    }, [productSearch]);

    const handleOpenDialog = () => {
        setFormData({ ...formData, addressVerified: false })
        setProductEdit(false);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setAmountValidate({ amountError: false, amountErrorText: '' });
        setContactValidate({ contactError: false, contactErrorText: '' });
        formik.resetForm();
        setFormData({ targetCustomer: [], valueProposition: null });
        setImage(null);
        setEditToggle(false);
        setImageUpdateMessage(null);
        setSaveLoader(false);
        setOpenDialog(false);
    };
    const handleCheckClick = (event: any, id: any) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: any = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const isSelected = (id: any) => selected.indexOf(id) !== -1;

    const handleDelete = () => {
        setDeletable(!deletable);
    };

    const validateContact = () => {
        let contact = formData.contactId;
        if (!contact || contact.length == 0) {
            setFormData({ ...formData, contactId: null });
            setContactValidate({
                ...contactValidate,
                contactError: true,
                contactErrorText:
                    'Please link atleast one contact with the product',
            });
            return;
        } else {
            setContactValidate({
                ...contactValidate,
                contactError: false,
                contactErrorText: '',
            });
        }
    };
    const validateAmount = () => {
        let amt = formData.defaultPrice;
        if (amt && amt < 0) {
            setAmountValidate({
                ...amountValidate,
                amountError: true,
                amountErrorText: 'Default Price cannot be negative',
            });
            return;
        } else if (amt && amt > 10000000) {
            setAmountValidate({
                ...amountValidate,
                amountError: true,
                amountErrorText: 'Default Price value is too large',
            });
            return;
        } else {
            setContactValidate({
                ...contactValidate,
                contactError: false,
                contactErrorText: '',
            });
        }
    };

    const handleContactChange = (event: any, newVal: any) => {
        let arr = null;

        if (!newVal || newVal.length == 0) {
            setIdArr(arr);
            setFormData({ ...formData, contactId: arr });
            setContactValidate({
                ...contactValidate,
                contactError: true,
                contactErrorText:
                    'Please link atleast one contact with the product',
            });
            return;
        } else {
            arr = newVal.map((element: any) => element.id);
            setFormData({ ...formData, contactId: arr });
            setContactValidate({
                ...contactValidate,
                contactError: false,
                contactErrorText: '',
            });
        }
    };
    const handleTargetCustomerChange = (event: any, newVal: any) => {

        setFormData({ ...formData, targetCustomer: newVal });
    };
    const amountHandler = (e: any) => {
        setFormData({ ...formData, defaultPrice: Number(e.target.value) });
        let amt = Number(e.target.value);
        if (amt && amt > 10000000) {
            setAmountValidate({
                ...amountValidate,
                amountError: true,
                amountErrorText: 'Default Price value is too large',
            });
            return;
        } else if (amt && amt < 0) {
            setAmountValidate({
                ...amountValidate,
                amountError: true,
                amountErrorText: 'Default Price cannot be negative',
            });
            return;
        } else
            setAmountValidate({
                ...amountValidate,
                amountError: false,
                amountErrorText: '',
            });
    };
    const [currentProduct, setCurrentProduct] = useState<any>();
    const handleUpload = (row: any) => {
        setCurrentProduct(row);
        setShowDialog(true);
    };
    const showProfile = (row: any) => {
        setCurrentSelection(row);

        setProfileDialog(true);
    };

    const handleFileChange = (file: any) => {
        setFile(file);

    };

    const toggleUpload = () => {
        setShowUpload(!showUpload);

        setShow(true);
    };

    const [numPages, setNumPages] = useState(null);

    const onDocumentLoadSuccess = (numPages: any) => {

        setNumPages(numPages);
    };

    const fileTypes = {
        'application/pdf': [],
    };

    const handleClose = () => {
        setFile(null);
        // setSaveLoader(false);
        setCurrentProduct(null);
        setShowDialog(false);
    };

    const handleSubmit = () => {

        if (file) {
            setSaveLoader(true);
            axios
                .post(
                    process.env.REACT_APP_BASE_URL +
                    'product/document/register',
                    { file },
                    {
                        headers: {
                            'Content-type': 'multipart/form-data',
                            "Authorization": "Bearer " + user.accessToken
                        },
                        params: {
                            productId: currentProduct.id
                        },
                    }
                )
                .catch((error: any) => {
                    setSaveLoader(false);
                })
                .then((response: any) => {

                    setSaveLoader(false);
                    handleClose();
                    setOnChange(prevVal => prevVal + 1);
                });
        }
    };
    const handleShowContactsDialogClose = () => {
        setShowContactsDialog(false);
        setContactListError(false);
    };
    const [productContacts, setProductContacts] = useState();
    const [getContactsLoader, setGetContactsLoader] = useState(false);
    const [contactListError, setContactListError] = useState(false);
    const handleShowContacts = (row: any) => {
        setShowContactsDialog(true);
        setGetContactsLoader(true);

        let str = row.contactId.toString();
        axios
            .get(process.env.REACT_APP_BASE_URL + 'contact/all', {
                headers: {
                    "Authorization": "Bearer " + user.accessToken,
                },
                params: {
                    ids: str,
                },
            })
            .catch((error: any) => {

                setGetContactsLoader(false);
                setContactListError(true);
            })
            .then((response: any) => {

                setProductContacts(response.data);
                setGetContactsLoader(false);
            });
    };
    const onRedirect = () => {
        setOpenDialog(true);
    };

    const [productEdit, setProductEdit] = useState(false);
    const handleEdit = (row: any) => {
        setProductEdit(true);
        if (!row.targetCustomer) setFormData({ ...row, targetCustomer: [] });
        else setFormData({ ...row });
        formik.setValues({
            name: row.name,
            description: row.description,
            url: row.url,
        });
        setOpenDialog(true);

        setEditable(row.id);
    };

    const createSortHandler = (property: any) => (event: any) => {
        let isAscending;
        setValueToOrderBy(property);
        isAscending = orderDirection === 'asc';
        setOrderDirection(isAscending ? 'desc' : 'asc');
        sortedRowInformation(rowData, getComparator(orderDirection, property));
    };
    const [showBrochureDialog, setShowBrochureDialog] = useState(false);
    const [imgUpdateMessage, setImageUpdateMessage] = useState<any>(null);
    const brochureShow = (row: any) => {
        setCurrentProduct(row);
        setShowBrochureDialog(true);
    };
    const handleValuePropChange = (e: any) => {

        setFormData({ ...formData, valueProposition: e });
    };
    const [showContactFormDialog, setShowContactFormDialog] = useState(false);
    const [rowState, setRowState] = useState<any>({});
    const [phoneValidate, setPhoneValidate] = useState({
        phoneError: false,
        phoneText: '',
    });
    const [addContactLoader, setAddContactLoader] = useState(false);
    const validationSchema1 = yup.object({
        firstName: yup
            .string()
            .required('First Name is required')
            .min(3, 'First name should contain 3 characters at least')
            .matches(/^[A-Za-z]*$/, 'Please enter valid first name'),
        lastName: yup
            .string()
            .required('Last Name is required')
            .min(3, 'Last name should contain 3 characters at least')
            .matches(/^[A-Za-z]*$/, 'Please enter valid last name'),
        email: yup
            .string()
            .email('Enter a valid email')
            .required('Email is required'),
    });
    const formik1 = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
        },
        validationSchema: validationSchema1,

        onSubmit: values => {
            let temp = { ...rowState, ...values };

            let value = rowState.phoneNumber1;
            if (!value) {
                setPhoneValidate({
                    ...phoneValidate,
                    phoneError: true,
                    phoneText: 'Phone Number is required',
                });
                return;
            }
            if (value) {
                let newNumber = value.replace(/[^0-9]/g, '');
                if (newNumber.length < 11) {
                    setPhoneValidate({
                        ...phoneValidate,
                        phoneError: true,
                        phoneText: 'Invalid Phone Number',
                    });
                    return;
                }
                temp.phoneNumber1 = newNumber;
                if (!addContactLoader) {
                    setAddContactLoader(true);

                    let body = {
                        contact: {
                            ...temp,
                            addressVerified: false,
                            hasNewsletter: false,
                            status: 0,
                            connectorId: null,
                            companyId: null,
                            vendorId: { id: vendorInfo.id, name: vendorInfo.name },
                            isApproved: false,
                            createdBy: vendorInfo.name,
                            modifiedBy: vendorInfo.name,
                        },
                        role: 'contact',
                    };
                    axios
                        .post(process.env.REACT_APP_BASE_URL + 'register', body,
                            {
                                headers: {
                                    "Authorization": "Bearer " + user.accessToken,
                                }
                            })
                        .catch((error: any) => {
                            setAddContactLoader(false);
                            handleContactClose();
                        })
                        .then((response: any) => {

                            let temp = formData.contactId
                                ? formData.contactId
                                : [];
                            temp.push(response.data.id);
                            setFormData({ ...formData, contactId: temp });
                            handleContactClose();
                            axios
                                .get(
                                    process.env.REACT_APP_BASE_URL +
                                    'contact/vendor/' +
                                    vendorInfo.id,
                                    {
                                        headers: {
                                            "Authorization": "Bearer " + user.accessToken,
                                        }
                                    }
                                )
                                .catch((error: any) => {

                                })
                                .then((response: any) => {

                                    setContactsList(response.data);

                                });
                            setOpenDialog(true);
                        });
                }
            }
        },
    });
    const handleContactClose = () => {
        setAddContactLoader(false);
        setRowState({});
        setPhoneValidate({
            phoneError: false,
            phoneText: '',
        });
        formik1.resetForm();
        setShowContactFormDialog(false);
        setOpenDialog(true);
    };
    const handlePhoneChange = (value: any) => {
        setRowState({ ...rowState, phoneNumber1: value });
        let newNumber = value.replace(/[^0-9]/g, '');
        if (newNumber.length < 11) {
            setPhoneValidate({
                ...phoneValidate,
                phoneError: true,
                phoneText: 'Invalid Phone Number',
            });
        } else {
            setPhoneValidate({
                ...phoneValidate,
                phoneError: false,
                phoneText: '',
            });
        }
    };
    const validatePhone = () => {
        let phone = rowState.phoneNumber1;
        if (!phone) {
            setPhoneValidate({
                ...phoneValidate,
                phoneError: true,
                phoneText: 'Phone Number is required',
            });
            return;
        }
        if (phone.length < 11) {
            setPhoneValidate({
                ...phoneValidate,
                phoneError: true,
                phoneText: 'Invalid Phone Number',
            });
            return;
        } else {
            setPhoneValidate({
                ...phoneValidate,
                phoneError: false,
                phoneText: '',
            });
        }
    };
    const openContactForm = () => {

        // handleCloseDialog();
        setOpenDialog(false);
        setShowContactFormDialog(true);
    };

    return (
        <>
            <ContactForm
                showDialog={showContactFormDialog}
                onClose={handleContactClose}
                onChange={inputHandler}
                handlePhoneChange={handlePhoneChange}
                addContactLoader={addContactLoader}
                userInfo={vendorInfo}
                formik={formik1}
                phoneValidate={phoneValidate}
                validatePhone={validatePhone}
                editForm={false}
                rowState={rowState}
                setRowState={setRowState}
            />
            <UploadDoument
                showDialog={showDialog}
                file={file}
                handleFileChange={handleFileChange}
                toggleUpload={toggleUpload}
                fileTypes={fileTypes}
                onDocumentLoadSuccess={onDocumentLoadSuccess}
                numPages={numPages}
                show={show}
                handleSubmit={handleSubmit}
                handleClose={handleClose}
                saveLoader={saveLoader}
                setFile={setFile}
                type={'Product Brochure'}
            />
            <ShowList
                showDialog={showContactsDialog}
                handleShowDialogClose={handleShowContactsDialogClose}
                data={productContacts}
                getLoader={getContactsLoader}
                type={'contacts'}
                error={contactListError}
            />
            <ProfileScreen
                showDialog={profileDialog}
                currentSelection={currentSelection}
                type={'product'}
                setShowDialog={setProfileDialog}
            />
            <ShowBrochure
                user={user}
                showDialog={showBrochureDialog}
                currentProduct={currentProduct}
                setCurrentProduct={setCurrentProduct}
                setShowDialog={setShowBrochureDialog}
                setFile={setFile}
            />
            <ProductForm
                user={user}
                openDialog={openDialog}
                handleCloseDialog={handleCloseDialog}
                formData={formData}
                inputHandler={inputHandler}
                contactsList={contactsList}
                amountHandler={amountHandler}
                handleContactChange={handleContactChange}
                saveLoader={saveLoader}
                formik={formik}
                amountValidate={amountValidate}
                productEdit={productEdit}
                contactValidate={contactValidate}
                validateContact={validateContact}
                validateAmount={validateAmount}
                file={image}
                setFile={setImage}
                editToggle={editToggle}
                setEditToggle={setEditToggle}
                imgUpdateMessage={imgUpdateMessage}
                setImageUpdateMessage={setImageUpdateMessage}
                handleTargetCustomerChange={handleTargetCustomerChange}
                setChangeStatus={setChangeStatus}
                vendorRegistrationLoader={vendorRegistrationLoader}
                handleValuePropChange={handleValuePropChange}
                openContactForm={openContactForm}
            />
            {error ?
                <Grid item xs={12} sx={{ textAlign: "center", marginTop: 10 }}>
                    <ErrorComponent />
                </Grid>
                :
                <>

                    {loader ? (
                        <>
                            <Grid container>
                                <Grid item xs={12} sx={{ textAlign: 'center', marginTop: 10 }}
                                >
                                    <img src="assets/loader/Spinner-px.gif" height={'140vh'} alt="No products foundq" />
                                    <Typography sx={{ paddingTop: 2 }}>
                                        Please wait while we fetch all products.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid container>
                                {(user.role !== "admin") &&
                                    <Grid item xs={12}>
                                        {(vendorInfo) &&
                                            <>
                                                {vendorInfo.isApproved === "pending" &&
                                                    <ProfileAlerts userInfo={vendorInfo} productCount={productCount} legalCount={legalCount} role={user.role} />
                                                }
                                            </>}
                                    </Grid>
                                }
                                {!empty && (
                                    <Grid item xs={12}>
                                        <Grid container >
                                            <Grid item xs={6}>
                                                <SearchTextField
                                                    val={productSearch}
                                                    funcHandle={setProductSearch}
                                                />
                                            </Grid>
                                            {user?.role === "vendor" ?
                                                <>
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        sx={{
                                                            textAlign: 'right',
                                                            marginTop: 1,

                                                        }}
                                                    >
                                                        <Button
                                                            variant="contained"

                                                            startIcon={<AddIcon />}
                                                            onClick={handleOpenDialog}
                                                        >
                                                            Product
                                                        </Button>
                                                    </Grid>
                                                </> :
                                                <></>
                                            }
                                        </Grid>
                                    </Grid>
                                )}
                                {empty ? (
                                    <>
                                        <Grid container>
                                            <Grid
                                                item
                                                xs={12}
                                                sx={{
                                                    textAlign: 'center',
                                                    marginTop: 2,
                                                }}
                                            >
                                                <img
                                                    src="assets/loader/not_found.gif"
                                                    height={'140vh'}
                                                />
                                                <Typography sx={{ fontWeight: 'bold' }}>
                                                    No products found!
                                                </Typography>
                                                <Typography>
                                                    Seems like there aren't any products associated with your organization.
                                                    <br />
                                                    {user?.role === "vendor" ?
                                                        <>
                                                            <Button
                                                                sx={{
                                                                    padding: 0.5,
                                                                    textTransform: 'none',
                                                                    fontSize: '16px',
                                                                }}
                                                                onClick={e => onRedirect()}
                                                            >
                                                                Get Started{' '}
                                                                <ArrowRightAltIcon />
                                                            </Button>
                                                        </>
                                                        : <></>}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </>
                                ) : (
                                    <>
                                        <Grid item xs={12} >
                                            {selected.length > 0 && (
                                                <EnhancedTableToolbar
                                                    numSelected={selected.length}
                                                    selectedUpdate={setSelected}
                                                    deletableUpdate={handleDelete}
                                                />
                                            )}
                                            <TableContainer
                                                component={Paper}
                                                sx={{ marginTop: 1, display: { xs: "none", sm: "none", md: "block", lg: "block", xl: "block" } }}
                                            >
                                                <Table
                                                    aria-label="simple table"
                                                    size="small"
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            {deletable && (
                                                                <TableCell></TableCell>
                                                            )}
                                                            <TableHeadRow
                                                                createSortHandler={
                                                                    createSortHandler
                                                                }
                                                                valueToOrderBy={
                                                                    valueToOrderBy
                                                                }
                                                                arr={user?.role === "vendor" ?
                                                                    [
                                                                        {
                                                                            name: 'NAME',
                                                                            alignment:
                                                                                'left',
                                                                            valueToOrderBy:
                                                                                'name',
                                                                            orderDirection:
                                                                                orderDirection,
                                                                        },
                                                                        {
                                                                            name: 'BROCHURE',
                                                                            alignment:
                                                                                'left',
                                                                            valueToOrderBy:
                                                                                false,
                                                                        },
                                                                        {
                                                                            name: 'ACTION',
                                                                            alignment:
                                                                                'left',
                                                                            valueToOrderBy:
                                                                                false,
                                                                        },
                                                                        {
                                                                            name: 'STATUS',
                                                                            alignment:
                                                                                'center',
                                                                            valueToOrderBy:
                                                                                false,
                                                                        },
                                                                        {
                                                                            name: 'EDIT',
                                                                            alignment:
                                                                                'left',
                                                                            valueToOrderBy:
                                                                                false,
                                                                        }
                                                                    ]
                                                                    :
                                                                    [
                                                                        {
                                                                            name: 'NAME',
                                                                            alignment:
                                                                                'left',
                                                                            valueToOrderBy:
                                                                                'name',
                                                                            orderDirection:
                                                                                orderDirection,
                                                                        },
                                                                        {
                                                                            name: 'BROCHURE',
                                                                            alignment:
                                                                                'left',
                                                                            valueToOrderBy:
                                                                                false,
                                                                        },
                                                                        {
                                                                            name: 'ACTION',
                                                                            alignment:
                                                                                'left',
                                                                            valueToOrderBy:
                                                                                false,
                                                                        },
                                                                        {
                                                                            name: 'STATUS',
                                                                            alignment:
                                                                                'center',
                                                                            valueToOrderBy:
                                                                                false,
                                                                        }
                                                                    ]}
                                                            />
                                                        </TableRow>
                                                    </TableHead>
                                                    {rowData &&
                                                        <TableBody>
                                                            {sortedRowInformation(
                                                                rowData.slice(
                                                                    page * rowsPerPage,
                                                                    page * rowsPerPage + rowsPerPage
                                                                ),
                                                                getComparator(
                                                                    orderDirection,
                                                                    valueToOrderBy
                                                                )
                                                            ).map(
                                                                (row: any, index: any) => {
                                                                    const isItemSelected =
                                                                        isSelected(row.id);
                                                                    const labelId = `enhanced-table-checkbox-${index}`;
                                                                    return (
                                                                        <TableRow
                                                                            key={row.id}
                                                                            sx={{
                                                                                '&:last-child td, &:last-child th':
                                                                                {
                                                                                    border: 0,
                                                                                },
                                                                            }}
                                                                        >
                                                                            {deletable && (
                                                                                <TableCell padding="checkbox">
                                                                                    <Checkbox
                                                                                        checked={
                                                                                            isItemSelected
                                                                                        }
                                                                                        inputProps={{
                                                                                            'aria-labelledby':
                                                                                                labelId,
                                                                                        }}
                                                                                        onClick={event =>
                                                                                            handleCheckClick(
                                                                                                event,
                                                                                                row.id
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </TableCell>
                                                                            )}
                                                                            <TableCell align="left">
                                                                                <Typography
                                                                                    onClick={e =>
                                                                                        showProfile(
                                                                                            row
                                                                                        )
                                                                                    }
                                                                                    style={
                                                                                        linkCol
                                                                                    }
                                                                                >
                                                                                    <Typography
                                                                                        sx={
                                                                                            linkHover
                                                                                        }
                                                                                    >
                                                                                        {firstCapital(
                                                                                            row.name
                                                                                        )}
                                                                                    </Typography>
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell align="left">
                                                                                {row.documentCount &&
                                                                                    row.documentCount >
                                                                                    0 ? (
                                                                                    <>
                                                                                        <Typography
                                                                                            sx={
                                                                                                linkHover
                                                                                            }
                                                                                            onClick={e =>
                                                                                                handleUpload(
                                                                                                    row
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            Upload
                                                                                        </Typography>

                                                                                        <Typography
                                                                                            sx={
                                                                                                linkHover
                                                                                            }
                                                                                            onClick={e =>
                                                                                                brochureShow(
                                                                                                    row
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            &nbsp;/
                                                                                            View
                                                                                            <Badge
                                                                                                badgeContent={
                                                                                                    row.documentCount
                                                                                                        ? row.documentCount
                                                                                                        : 0
                                                                                                }
                                                                                                color="primary"
                                                                                                sx={{
                                                                                                    paddingRight: 2,
                                                                                                }}
                                                                                                showZero
                                                                                            />
                                                                                        </Typography>
                                                                                    </>
                                                                                ) : (
                                                                                    <Typography
                                                                                        sx={
                                                                                            linkHover
                                                                                        }
                                                                                        onClick={e =>
                                                                                            handleUpload(
                                                                                                row
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        Upload
                                                                                    </Typography>
                                                                                )}
                                                                            </TableCell>
                                                                            {(row.contactId &&
                                                                                row
                                                                                    .contactId
                                                                                    .length) >
                                                                                0 ? (
                                                                                <TableCell align="left">
                                                                                    <Box
                                                                                        display={
                                                                                            'flex'
                                                                                        }
                                                                                        alignItems={
                                                                                            'center'
                                                                                        }
                                                                                    >
                                                                                        <Typography
                                                                                            onClick={e =>
                                                                                                handleShowContacts(
                                                                                                    row
                                                                                                )
                                                                                            }
                                                                                            style={
                                                                                                linkCol
                                                                                            }
                                                                                        >
                                                                                            <Typography
                                                                                                sx={
                                                                                                    linkHover
                                                                                                }
                                                                                            >
                                                                                                Show Contacts
                                                                                            </Typography>
                                                                                        </Typography>
                                                                                        <Badge
                                                                                            badgeContent={
                                                                                                row
                                                                                                    .contactId
                                                                                                    .length
                                                                                            }
                                                                                            color="primary"
                                                                                            sx={{
                                                                                                paddingRight: 2,
                                                                                            }}
                                                                                        />
                                                                                    </Box>
                                                                                </TableCell>
                                                                            ) : (
                                                                                <TableCell align="left">
                                                                                    <Typography>
                                                                                        No
                                                                                        contacts
                                                                                        linked
                                                                                    </Typography>
                                                                                </TableCell>
                                                                            )}
                                                                            <StatusCell textClr={statusColorText[row.isApproved]} bgClr={statusColor[row.isApproved]} text={firstCapital(row.isApproved)} />

                                                                            {user?.role === "vendor" ?
                                                                                <TableCell>
                                                                                    <ModeEditIcon
                                                                                        onClick={event => {
                                                                                            handleEdit(
                                                                                                row
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                </TableCell>
                                                                                : <></>}
                                                                        </TableRow>
                                                                    );
                                                                }
                                                            )}
                                                        </TableBody>}
                                                </Table>
                                            </TableContainer>
                                            {rowData &&
                                                <>
                                                    <Grid item xs={12} sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none", xl: "none" } }}>
                                                        <TableCardComponent rowData={rowData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)} userType={"vendorProduct"} brochureShow={brochureShow} handleShowContacts={handleShowContacts} handleUpload={handleUpload} handleEdit={handleEdit} showProfile={showProfile} />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TablePagination
                                                            rowsPerPageOptions={[5, 10, 15]}
                                                            sx={{
                                                                '.MuiTablePagination-toolbar': {
                                                                    alignItems: 'baseline',
                                                                },
                                                            }}
                                                            component="div"
                                                            count={rowData.length}
                                                            rowsPerPage={rowsPerPage}
                                                            page={page}
                                                            onPageChange={handleChangePage}
                                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                                        />
                                                    </Grid>
                                                </>}
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </>
                    )}
                </>}
        </>
    );
}