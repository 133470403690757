import { Autocomplete, Divider, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import React from 'react';
import NumberFormatCustom from './NumberFormatCustom';

const DealTextFields = (props:any) => {
    const{formData, inputHandler, tags, getOptions, handleTag, amountHandler, formik, amountValidation, asterisk}=props;
    return (
        <>
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: '20px',
                                    }}
                                >
                                    Deal Name {asterisk()}
                                </Typography>
                                <Typography variant="caption">
                                    Enter a name to associate with the deal
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Deal Name"
                                    name="dealName"
                                    id="dealName"
                                    value={formik.values.dealName}
                                    type="text"
                                    variant="outlined"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={formik.touched.dealName && Boolean(formik.errors.dealName)}
                                    helperText={formik.touched.dealName && formik.errors.dealName}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: '20px',
                                    }}
                                >
                                    Deal Amount {asterisk()}
                                </Typography>
                                <Typography variant="caption">
                                    Enter an amount to associate with the deal
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    sx={{ width: '100%' }}
                                    type="text"
                                    label="Amount"
                                    id="amount"
                                    error={amountValidation.error}
                                    helperText={amountValidation.message}
                                    InputProps={{
                                        inputComponent: NumberFormatCustom,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                $
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={amountHandler}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} sx={{ marginY: 2 }}>
                <Divider />
            </Grid>

            <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: '20px',
                                    }}
                                >
                                    Select Tag
                                </Typography>
                                <Typography variant="caption">
                                    Select a tag to associate with the deal
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    options={tags}
                                    getOptionLabel={getOptions}
                                    id="tag"
                                    onChange={handleTag}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label="Tag"
                                            type="text"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: '20px',
                                    }}
                                >
                                    Deal Description
                                </Typography>
                                <Typography variant="caption">
                                    Enter a description to associate with the deal
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    multiline
                                    fullWidth
                                    label="Deal Description"
                                    name="description"
                                    id="description"
                                    value={formik.values.description}
                                    type="text"
                                    variant="outlined"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={formik.touched.description && Boolean(formik.errors.description)}
                                    helperText={formik.touched.description && formik.errors.description}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: 3, marginBottom: 1 }}>
                <Divider />
            </Grid>
        </>
    );
};

export default DealTextFields;
