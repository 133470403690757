import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Card,
    CardContent,
    CardMedia,
    Grid,
    Typography
} from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { firstCapital } from '../Admin/Approvals/util';
import ErrorComponent from '../Layout/ErrorComponent';
import ActivitiesHeader from '../utils/ActivitiesHeader';
import ImageUpload from './ImageUpload';
import UserProfile from './UserProfile';

const MyProfile = (props: any) => {
    const { screen, user } = props;
    const [userInfo, setUserInfo] = useState<any>();
    const [isApproved, setisApproved] = useState("");
    const [gotData, setGotData] = useState(false);
    const [error, setError] = useState(false);
    const [editable, setEditable] = useState(false);
    const [saveLoader, setSaveLoader] = useState(false);
    const [showImageUpload, setShowImageUpload] = useState(false);
    const [pictureLoader, setPictureLoader] = useState(false);
    const [profileSaveLoader, setProfileSaveLoader] = useState(false);
    const [disableFields, setDisableFields] = useState(false);
    const [disableSave, setDisableSave] = useState(false);
    const [profileComplete, setProfileComplete] = useState(true);
    const initialError = {
        phone1: false,
        message1: "",
        phone2: false,
        message2: "",
        address: false,
        addressMessage: "",
        zipcode: false,
        zipcodeMessage: "",
        state: false,
        stateMessage: "",
        city: false,
        cityMessage: "",
        street: false,
        streetMessage: "",
        country: false,
        countryMessage: ""
    }
    const [formValidation, setFormValidation] = useState(initialError)
    const [formData, setFormData] = useState<any>();
    const [file, setFile] = useState<any>();
    const [isAddressValidated, setIsAddressValidated] = useState(false);
    const [showAddressSuggestion, setShowAddressSuggestion] = useState("");
    const [initialVendorData, setInitialVendorData] = useState<any>();
    const ColChangeHandler = (e: any) => {

        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };
    const [cityInfo, setCityInfo] = useState<any>();
    const [validatedData, setValidatedData] = useState<any>();
    const [initialData, setInitialData] = useState<any>();
    const [updateType, setUpdateType] = useState("");
    const [legalCount, setLegalCount] = useState(0);
    useEffect(() => {
        if (screen === "vendorAdmin") {
            if (initialVendorData) {
                setUserInfo(initialVendorData);
                setIsAddressValidated(initialVendorData.addressVerified);
            }
        }
        else {
            if (initialData) {
                setUserInfo(initialData)
                setIsAddressValidated(initialData.addressVerified);
            }
        }
    }, [screen, initialData])
    useEffect(() => {
        if (user) {
            if (user?.role !== "admin") {
                var userType = "";
                if (user?.role === "vendor") {
                    userType = "contact"
                }
                else {
                    userType = user?.role;
                }
                axios
                    .get(
                        process.env.REACT_APP_BASE_URL +
                        userType +
                        '/mail/' +
                        user?.email,
                        {
                            headers: {
                                "Authorization": "Bearer " + user.accessToken,
                            }
                        }
                    )
                    .catch((error: any) => {

                        setGotData(false);
                        setError(true);
                    })
                    .then((response: any) => {
                        if (user?.role === "connector") {
                            axios.get(process.env.REACT_APP_BASE_URL + "document/connector/" + response.data.id,
                                {
                                    headers: {
                                        "Authorization": "Bearer " + user.accessToken,
                                    }
                                })
                                .then((response: any) => {
                                    setLegalCount(response.data.length)
                                })
                            setUserInfo(response.data);
                            setInitialData(response.data);
                            setIsAddressValidated(response.data.addressVerified)
                            setisApproved(response.data.isApproved)
                        }
                        else {
                            setisApproved(response.data.vendorId.isApproved);
                            setInitialVendorData(response.data.vendorId);
                            axios.get(process.env.REACT_APP_BASE_URL + "document/vendor/" + response.data.vendorId.id,
                                {
                                    headers: {
                                        "Authorization": "Bearer " + user.accessToken,
                                    }
                                })
                                .then((response: any) => {
                                    setLegalCount(response.data.length)
                                })
                            setInitialData({ ...response.data, name: response.data.firstName + " " + response.data.lastName });
                            if (screen === "vendorAdmin") {
                                setUserInfo(response.data.vendorId);
                                setIsAddressValidated(response.data.vendorId.addressVerified)
                            }
                            else {
                                setUserInfo({ ...response.data, name: response.data.firstName + " " + response.data.lastName })
                                setIsAddressValidated(response.data.addressVerified)
                            }
                        }
                        setGotData(true);
                        setCityInfo({
                            ...cityInfo,
                            name: response.data.cityName,
                            countryCode: response.data.countryCode,
                            stateCode: response.data.stateCode,
                            latitude: response.data.latitude,
                            longitude: response.data.longitude,
                        })
                    });
            }
            else {
                setGotData(true)
            }
        }
    }, [user]);

    const saveFunction = (temp: any) => {
        setUpdateType("profile");

        temp.addressVerified = isAddressValidated;
        temp.phoneNumber1 = temp.phoneNumber1.replace(/[^0-9]/g, "");
        if (temp.phoneNumber2) {
            temp.phoneNumber2 = temp.phoneNumber2.replace(/[^0-9]/g, "");
        }
        else {
            temp.phoneNumber2 = null;
        }
        setSaveLoader(true);

        var userType = user?.role;
        if (screen === "vendor" || screen === "contact") {
            userType = "contact";
            temp.firstName = temp.name.slice(0, user?.name?.indexOf(" ")).replace(" ", "");
            temp.lastName = temp.name.slice(user?.name?.indexOf(" ")).replace(" ", "");
        }
        axios.put(process.env.REACT_APP_BASE_URL + userType + "/" + userInfo.id, temp,
            {
                headers: {
                    "Authorization": "Bearer " + user.accessToken,
                }
            })
            .catch((error) => {
                setSaveLoader(false)
                setShowAddressSuggestion("")
                setDisableFields(false);
                setDisableSave(false)
            })
            .then((response: any) => {
                if (user?.role === "connector") {
                    setUserInfo(response.data);
                }
                else {
                    if (screen === "vendorAdmin") {
                        setUserInfo(response.data);
                        setInitialVendorData(response.data)
                    }
                    else {
                        setUserInfo({ ...response.data, name: response.data.firstName + " " + response.data.lastName });
                        setInitialData({ ...response.data, name: response.data.firstName + " " + response.data.lastName })
                    }
                }
                setSaveLoader(false)
                setEditable(false)
                setShowImageUpload(false)
                setFormValidation(initialError)
                setShowAddressSuggestion("")
                setDisableFields(false);
                setDisableSave(false);
                setCityInfo({
                    ...cityInfo,
                    name: response.data.cityName,
                    countryCode: response.data.countryCode,
                    stateCode: response.data.stateCode,
                    latitude: response.data.latitude,
                    longitude: response.data.longitude,
                })
            })
    }

    const checkValidation = (types: any) => {
        if (types === "street_number") {
            setFormValidation({ ...formValidation, address: true, addressMessage: "Please enter a valid street number" });
        }
        else if (types === "subpremise") {
            setFormValidation({ ...formValidation, street: true, streetMessage: "Please enter a valid street number" });
        }
        else if (types === "locality") {
            setFormValidation({ ...formValidation, city: true, cityMessage: "Please Enter a valid city name" });
        }
        else if (types === "administrative_area_level_1") {
            setFormValidation({ ...formValidation, state: true, stateMessage: "Please enter a valid state" });
        }
        else if (types === "postal_code" || types === "postal_code_suffix") {
            setFormValidation({ ...formValidation, zipcode: true, zipcodeMessage: "Please Enter a valid zipcode" });
        }
        else if (types === "country") {
            setFormValidation({ ...formValidation, country: true, countryMessage: "Please Enter a valid country name" });
        }
    }

    function containsSpecialChars(str: string) {
        const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>?~]/;
        return specialChars.test(str);
    }

    const addressValidation = (currentData: any) => {
        return new Promise((resolve, reject) => {
            if (currentData.cityName === "" || currentData.stateName === "" || currentData.countryName === "" || currentData.zipcode === "" || currentData.address === "" || currentData.cityName === null || currentData.stateName === null || currentData.countryName === null || currentData.zipcode === null || currentData.address === null) {
                setIsAddressValidated(false);
                reject(false);
            }
            else if (containsSpecialChars(currentData.street)) {
                setFormValidation({ ...formValidation, street: true, streetMessage: "Please enter a valid street number" }); setIsAddressValidated(false);
                reject(false);
            }
            else {
                var addressLine = currentData.address + "," + currentData.cityName + ", " + currentData.stateName + ", " + currentData.zipcode + ", " + currentData.countryName;
                axios.post("https://addressvalidation.googleapis.com/v1:validateAddress?key=" + process.env.REACT_APP_GOOGLE_API_KEY, {
                    "address": {
                        "regionCode": currentData.countryCode,
                        "addressLines": [addressLine]
                    },
                    "enableUspsCass": true
                })
                    .catch((error: any) => {
                        setFormValidation({ ...formValidation, address: true, addressMessage: "Please enter a valid address, if the problem persists please reach out to the administrator." });
                        setIsAddressValidated(false);
                        reject(false);
                    })
                    .then((response: any) => {
                        if (response.data.result.verdict.hasUnconfirmedComponents) {
                            setFormValidation(initialError);

                            if (response.data.result.address.missingComponentTypes) {
                                response.data.result.address.missingComponentTypes.reverse().map((types: any) => {
                                    if (types !== "subpremise") {
                                        checkValidation(types);
                                        setIsAddressValidated(false);
                                    }
                                })
                            }
                            if (response.data.result.address.unconfirmedComponentTypes) {
                                response.data.result.address.unconfirmedComponentTypes.reverse().map((types: any) => {
                                    checkValidation(types);
                                    setIsAddressValidated(false);
                                })
                            }
                            reject(false)

                        }
                        else {
                            setValidatedData(response.data);
                            var street = "";
                            var city = ""
                            var state = "";
                            var country = "";
                            var zipcode = "";
                            response.data.result.address.addressComponents.map((record: any) => {

                                if (record.componentType === "street_number") {
                                    street = street + record.componentName.text
                                }
                                else if (record.componentType === "route") {
                                    street = street + " " + record.componentName.text
                                }
                                else if (record.componentType === "subpremise") {
                                    street = street + " " + record.componentName.text
                                }
                                else if (record.componentType === "locality") {
                                    city = " " + record.componentName.text;
                                }
                                else if (record.componentType === "administrative_area_level_1") {
                                    state = " " + record.componentName.text
                                }
                                else if (record.componentType === "postal_code") {
                                    zipcode = " " + record.componentName.text;
                                }
                                else if (record.componentType === "postal_code_suffix") {
                                    zipcode = zipcode + "-" + record.componentName.text;
                                }
                                else if (record.componentType === "country") {
                                    country = " " + record.componentName.text
                                }
                            })
                            var suggestion = "";
                            var currentAddress = "";
                            if (currentData.street) {
                                suggestion = street + " " + currentData.street + "," + city + "," + state + "," + country + "," + zipcode;
                                currentAddress = currentData.address + " " + currentData.street + ", " + currentData.cityName + ", " + currentData.stateName + ", " + currentData.countryName + ", " + currentData.zipcode;

                            }
                            else {
                                suggestion = street + "," + city + "," + state + "," + country + "," + zipcode;
                                currentAddress = currentData.address + ", " + currentData.cityName + ", " + currentData.stateName + ", " + currentData.countryName + ", " + currentData.zipcode;

                            }
                            if (suggestion === currentAddress) {
                                setIsAddressValidated(true);
                                setShowAddressSuggestion("");
                                setDisableFields(false);
                                setDisableSave(false);
                                resolve(true);
                            }
                            else {
                                setIsAddressValidated(false);
                                setShowAddressSuggestion(suggestion);
                                setDisableFields(true)
                                setDisableSave(true);
                                reject(false);
                            }
                            setFormValidation(initialError)
                        }
                    })
            }
        });
    }

    const onClickYes = () => {
        var temp = formData;
        validatedData.result.address.addressComponents.map((record: any) => {

            if (record.componentType === "street_number") {
                temp.address = record.componentName.text;
            }
            else if (record.componentType === "route") {
                temp.address += " " + record.componentName.text;
            }
            else if (record.componentType === "subpremise") {
                temp.address += ", " + record.componentName.text;
            }
            else if (record.componentType === "locality") {
                temp.cityName = record.componentName.text;
            }
            else if (record.componentType === "administrative_area_level_1") {
                temp.stateName = record.componentName.text;
            }
            else if (record.componentType === "country") {
                temp.countryName = record.componentName.text;
            }
            else if (record.componentType === "postal_code") {
                temp.zipcode = record.componentName.text;
            }
            else if (record.componentType === "postal_code_suffix") {
                temp.zipcode += "-" + record.componentName.text;
            }
        })
        //setFormData({...formData, address:street, cityName:city, stateName:state, countryName:country, zipcode:pincode})
        setIsAddressValidated(true);
        setDisableSave(false);
        setDisableFields(false);
        setShowAddressSuggestion("");
        setSaveLoader(false);
    }

    const onClickSkip = () => {
        saveFunction(formData)
    }

    const onClickEdit = () => {
        setIsAddressValidated(false);
        setShowAddressSuggestion("");
        setDisableSave(false);
        setDisableFields(false);
    }
    const handleSave = (values: any) => {
        if (formData.phoneNumber1 === null || formData.phoneNumber1 === "") {
            setFormValidation({ ...formValidation, phone1: true, message1: "Please enter a valid contact number." })
        }
        else if (formData.phoneNumber1.replace(/[^0-9]/g, "").length < 11) {
            setFormValidation({ ...formValidation, phone1: true, message1: "Please enter a valid contact number." })
        }
        else {
            if (!formValidation.phone1 && !formValidation.phone2) {
                setSaveLoader(true);
                saveFunction(formData);
            }
        }
    };

    const imageUpload = () => {
        setShowImageUpload(true);
    }

    const handleProfilePictureUpload = () => {
        setUpdateType("image")
        var userType = user?.role;
        if (user?.role === "vendor") {
            if (screen === "vendor") {
                userType = "contact"
            }
        }
        setProfileSaveLoader(true)
        setPictureLoader(true);
        axios.put(process.env.REACT_APP_BASE_URL + userType + "/image/" + userInfo.id, { file },
            {
                headers: {
                    "Authorization": "Bearer " + user.accessToken,
                    "Content-type": "multipart/form-data"
                }
            })
            .catch((error: any) => {

                setPictureLoader(false);
                setProfileSaveLoader(false)
            })
            .then((response: any) => {

                if (user?.role === "connector") {
                    setUserInfo({ ...userInfo, logo: response.data.logo, contentType: response.data.contentType, imageName: response.data.imageName });
                }
                else {
                    if (screen === "vendorAdmin") {
                        setUserInfo({ ...userInfo, logo: response.data.logo, contentType: response.data.contentType, imageName: response.data.imageName });
                        setInitialVendorData({ ...initialVendorData, logo: response.data.logo, contentType: response.data.contentType, imageName: response.data.imageName })
                    }
                    else {
                        setUserInfo({ ...userInfo, logo: response.data.logo, contentType: response.data.contentType, imageName: response.data.imageName });
                        setInitialData({ ...initialData, logo: response.data.logo, contentType: response.data.contentType, imageName: response.data.imageName })
                    }
                }
                setPictureLoader(false);
                setShowImageUpload(false);
                setFile(null);
                setProfileSaveLoader(false);
            })

    }

    useEffect(() => {
        if (userInfo) {
            if (userInfo.addressVerified === false || userInfo.phoneNumber1 === null || userInfo.phoneNumber1 === "") {
                setProfileComplete(false);
            }
            else {
                setProfileComplete(true);
            }
        }
    }, [userInfo])

    return (
        <>
            <ImageUpload showDialog={showImageUpload} setShowDialog={setShowImageUpload} saveLoader={profileSaveLoader} handleSave={handleProfilePictureUpload} file={file} setFile={setFile} />
            <ActivitiesHeader
                currentText="Find all your details here"
                heading="Profile"
            />
            <Grid container>

                {error ?
                    <>
                        <Grid item xs={12} sx={{ textAlign: "center", marginTop: 10 }}>
                            <ErrorComponent />
                        </Grid>
                    </>
                    :
                    <>
                        {!gotData && (
                            <Grid
                                item
                                xs={12}
                                sx={{ textAlign: 'center', marginTop: 5 }}
                            >
                                <img
                                    src="assets/loader/Spinner-px.gif"
                                    height={'140vh'}
                                    alt=""
                                />
                                <Typography sx={{ paddingTop: 2 }}>
                                    Please wait while we fetch your information
                                </Typography>
                            </Grid>
                        )}
                        {gotData && (
                            <Grid container columnSpacing={1}>
                                {user?.role !== "admin" ?
                                    <>
                                        {isApproved === "approved" ? (
                                            <>
                                                <Grid item xs={12} sm={12} lg={12} md={12} xl={12} mt={1} mb={2} sx={{ marginY: 1 }}>
                                                    <Alert severity="success">
                                                        <AlertTitle>
                                                            Welcome Back, {userInfo.name}
                                                        </AlertTitle>
                                                        You're already registered, you can
                                                        update your details here
                                                    </Alert>
                                                </Grid>
                                            </>
                                        ) : (
                                            <>
                                                {isApproved === "rejected" || isApproved === "revoked" &&
                                                    <Grid item xs={12} sm={12} lg={12} md={12} xl={12} mt={1} mb={2}>
                                                        <Alert severity="error">
                                                            <AlertTitle>Your Profile has been {firstCapital(isApproved)}</AlertTitle>
                                                            <strong>
                                                                Please contact ConnectorNet admin for more details
                                                            </strong>
                                                        </Alert>
                                                    </Grid>
                                                }
                                                {isApproved === "pending" &&
                                                    <>
                                                        <Grid item xs={12} sm={12} lg={12} md={12} xl={12} mt={1} mb={2}>
                                                            <Alert severity="warning">
                                                                {user.role === "connector" ?
                                                                    <>
                                                                        <AlertTitle>Please complete the following sections of your profile so a ConnectorNet Admin can approve your profile:</AlertTitle>
                                                                        {profileComplete ? <CheckBoxIcon sx={{ color: "green" }} /> : <CheckBoxOutlineBlankIcon />}<strong>My Profile</strong><br />
                                                                        {legalCount > 0 ? <CheckBoxIcon sx={{ color: "green" }} /> : <CheckBoxOutlineBlankIcon />}<strong>Upload signed legal documents to My Document</strong><br />
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <AlertTitle>Please complete the following sections of your profile so a ConnectorNet Admin can approve your profile:</AlertTitle>
                                                                        {(user.role === "vendor" || user.role === "contact") &&
                                                                            <>
                                                                                {(screen === "vendorAdmin") &&
                                                                                    <>
                                                                                        {profileComplete ? <CheckBoxIcon sx={{ color: "green" }} /> : <CheckBoxOutlineBlankIcon />}<strong>My Organization</strong><br />
                                                                                        {legalCount > 0 ? <CheckBoxIcon sx={{ color: "green" }} /> : <CheckBoxOutlineBlankIcon />}<strong>Upload signed legal documents to My Document</strong><br />
                                                                                        {(userInfo.productCount === null || userInfo.productCount === 0) ? <CheckBoxOutlineBlankIcon /> : <CheckBoxIcon sx={{ color: "green" }} />}<strong>Add at least 1 product</strong>

                                                                                    </>}
                                                                                {(screen === "vendor" || screen === "contact") &&
                                                                                    <>
                                                                                        {(initialVendorData.addressVerified === false || initialVendorData.phoneNumber1 === null || initialVendorData.phoneNumber1 === "") ? <CheckBoxOutlineBlankIcon /> : <CheckBoxIcon sx={{ color: "green" }} />}<strong>My Organization</strong><br />
                                                                                        {legalCount > 0 ? <CheckBoxIcon sx={{ color: "green" }} /> : <CheckBoxOutlineBlankIcon />}<strong>Upload signed legal documents to My Document</strong><br />
                                                                                        {(initialVendorData.productCount === null || initialVendorData.productCount === 0) ? <CheckBoxOutlineBlankIcon /> : <CheckBoxIcon sx={{ color: "green" }} />}<strong>Add at least 1 product</strong>
                                                                                    </>}
                                                                                {/* {(screen === "vendor" || screen === "contact") && 
                                        <>
                                            {userInfo.vendorId && <>{userInfo.vendorId.productCount === 0?<strong>You need to have a product atleast to be approved by ConnectorNet</strong>:<strong>Awaiting approval by ConnectorNet</strong>}</>}
                                        </>} */}
                                                                            </>}
                                                                    </>}
                                                            </Alert>
                                                        </Grid>
                                                    </>
                                                }
                                            </>
                                        )}
                                    </>
                                    :
                                    <>
                                        <Grid item xs={12} sm={12} lg={12} md={12} xl={12} mt={1} mb={2} sx={{ marginY: 1 }}>
                                            <Alert severity="success">
                                                <AlertTitle>
                                                    Welcome Back, {user?.nickname}
                                                </AlertTitle>
                                                You've the admin priveleges.
                                            </Alert>
                                        </Grid>
                                    </>}
                                <Grid container>
                                    <Grid item lg={4} xl={4} md={4} sm={12} xs={12} sx={{ textAlign: "center" }}>
                                        <Typography variant="h5" sx={{ marginY: 2 }}>{screen === "vendorAdmin" ? <>Organizational</> : <>Personal</>} Information</Typography>
                                        {user?.role !== "admin" ?
                                            <>
                                                {pictureLoader ?
                                                    <>
                                                        <CardMedia
                                                            component="img"
                                                            image={"assets/loader/Spinner-px.gif"}
                                                            alt="Connected People"
                                                            sx={{
                                                                height: "10rem",
                                                                width: "10rem",
                                                                textAlign: "center",
                                                                margin: "auto",
                                                                marginTop: 6
                                                            }}
                                                        />
                                                    </>
                                                    :
                                                    <>
                                                        {userInfo.logo ?
                                                            <>
                                                                <CardMedia
                                                                    component="img"
                                                                    image={`data:${userInfo.contentType};base64,${userInfo.logo}`}
                                                                    alt="Connected People"
                                                                    sx={{
                                                                        height: "15rem",
                                                                        width: "15rem",
                                                                        textAlign: "center",
                                                                        margin: "auto"
                                                                    }}
                                                                />
                                                            </>
                                                            :
                                                            <>
                                                                <CardMedia
                                                                    component="img"
                                                                    image={"assets/icons/userIcon.png"}
                                                                    alt="Connected People"
                                                                    sx={{
                                                                        height: "15rem",
                                                                        width: "15rem",
                                                                        textAlign: "center",
                                                                        margin: "auto"
                                                                    }}
                                                                />
                                                            </>}
                                                    </>}
                                            </>
                                            :
                                            <>
                                                <CardMedia
                                                    component="img"
                                                    image={"assets/icons/userIcon.png"}
                                                    alt="Connected People"
                                                    sx={{
                                                        height: "15rem",
                                                        width: "15rem",
                                                        textAlign: "center",
                                                        margin: "auto"
                                                    }}
                                                />
                                            </>}
                                        {editable ? <><Box sx={{ marginTop: 4 }}><Button onClick={imageUpload}>Upload new image</Button></Box></> : <></>}
                                    </Grid>
                                    {(user?.role === 'vendor' || user?.role === "connector" || user?.role === "contact") ? (
                                        <Grid item lg={8} md={8} sm={1}>
                                            <UserProfile
                                                user={user}
                                                data={userInfo}
                                                changeFunc={ColChangeHandler}
                                                userType={user.role}
                                                formData={formData}
                                                setFormData={setFormData}
                                                handleSave={handleSave}
                                                saveLoader={saveLoader}
                                                editable={editable}
                                                setEditable={setEditable}
                                                formValidation={formValidation}
                                                setFormValidation={setFormValidation}
                                                cityInfo={cityInfo}
                                                showAddressSuggestion={showAddressSuggestion}
                                                onClickSkip={onClickSkip}
                                                onClickYes={onClickYes}
                                                disableSave={disableSave}
                                                setDisableSave={setDisableSave}
                                                initialError={initialError}
                                                setShowAddressSuggestion={setShowAddressSuggestion}
                                                disableFields={disableFields}
                                                onClickEdit={onClickEdit}
                                                setDisableFields={setDisableFields}
                                                validateAddress={addressValidation}
                                                isAddressValidated={isAddressValidated}
                                                screen={screen}
                                                updateType={updateType}
                                                setUpdateType={setUpdateType}
                                            />
                                        </Grid>
                                    )
                                        :
                                        (
                                            <>
                                                <Grid xs={1} sm={1} lg={0} xl={0} md={0} />
                                                <Grid item xs={10} lg={6} xl={6} md={6} sm={10} mt={1} mb={1}>
                                                    <Card
                                                        style={{
                                                            padding: 2,
                                                        }}
                                                    >
                                                        <CardContent>
                                                            <Grid container spacing={2} sx={{ marginBottom: 1 }}>
                                                                <Grid item xs={10} sx={{ display: "flex-box", justifyContent: "center" }}>
                                                                    <Typography sx={{ fontWeight: "bold", fontSize: 25, paddingTop: 2 }}>{firstCapital(String(user?.name))}</Typography>
                                                                    <Typography sx={{ fontWeight: "medium", paddingTop: 0 }}>{user?.email}</Typography>
                                                                </Grid>
                                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                    <Typography variant="caption">Full Name</Typography>
                                                                    <Typography sx={{ fontWeight: "medium" }}>{firstCapital(String(user?.name))}</Typography>
                                                                </Grid>
                                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                    <Typography variant="caption" >Email</Typography>
                                                                    <Typography sx={{ fontWeight: "medium" }}>{user?.email}</Typography>
                                                                </Grid>
                                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                    <Typography variant="caption" >Access Type</Typography>
                                                                    <Typography sx={{ fontWeight: "medium" }}>{user?.role}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            </>
                                        )
                                    }

                                </Grid>
                            </Grid>
                        )}
                    </>}
            </Grid>
        </>
    );
};

export default MyProfile;
