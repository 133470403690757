import { Divider, Grid, Typography } from '@mui/material';
import React from 'react'
import { convertCurrency } from '../../Admin/Approvals/util';

const Deal = (props:any) => {
  const {currentSelection, cardDeal} = props;
  const style = {padding:1,boxShadow:0, backgroundColor:"#F0F0F0"};
  const createLink = (link:any) => {
    const mail = "mailto:"+link+"?body=New mail to connector";
    return(mail)
  }
  return (
    <>
        <Grid container spacing={2}>
          <Grid container sx={style}>
          <Grid item xs={4} mb={1}>
          <Typography sx={{fontWeight:"bold", paddingLeft:1, fontSize:"20px"}}>Deal Details</Typography>
          </Grid>
          <Grid container sx={{paddingX: 1}}>
            {cardDeal?<>
              <Grid item xs={12} mb={1}>
                {currentSelection.name? 
                      <Typography sx={{fontWeight:"bold"}}>{currentSelection.name}</Typography>
                    :
                      <Typography sx={{fontWeight:"light"}} >No deal name available</Typography>
                    }
                </Grid>
                <Grid item xs={12} mb={1}>
                {currentSelection.amount?
                      <Typography sx={{fontWeight:"light"}}>{convertCurrency(currentSelection.amount)}</Typography>
                    :
                      <Typography sx={{fontWeight:"light"}} >No deal amount available</Typography>
                    }
                </Grid>
                <Grid item xs={12} mb={1}>
                {currentSelection.connectorId? 
                      <Typography sx={{fontWeight:"light"}}>{currentSelection.connectorId.name}</Typography>
                    :
                      <Typography sx={{fontWeight:"light"}} >No connector information available</Typography>
                    }
                </Grid>
                <Grid item xs={12} mb={1}>
                {currentSelection.customerId? 
                      <Typography sx={{fontWeight:"light"}}>{currentSelection.customerId.name}</Typography>
                    :
                      <Typography sx={{fontWeight:"light"}} >No customer information available</Typography>
                    }
                </Grid>
                <Grid item xs={12} mb={1}>
                {currentSelection.vendorId? 
                      <Typography sx={{fontWeight:"light"}}>{currentSelection.vendorId.name}</Typography>
                    :
                      <Typography sx={{fontWeight:"light"}} >No vendor information available</Typography>
                    }
                </Grid>
                <Grid item xs={12} mb={1}>
                {currentSelection.productId? 
                      <Typography sx={{fontWeight:"light"}}>{currentSelection.productId.name}</Typography>
                    :
                      <Typography sx={{fontWeight:"light"}} >No product information available</Typography>
                    }
                </Grid>
            </>
            :
            <>
              <Grid item xs={12} mb={1} height={130}>
              <Typography>Please select a deal</Typography>
              </Grid>
            </>}
          </Grid>
          </Grid>
        </Grid>
    </>
  )
}

export default Deal