import React, { useEffect, useState } from 'react';
import {Tabs,Tab,Toolbar,AppBar,Box,ListItemIcon,MenuList, Typography, Divider, List, ListItem, ListItemButton, ListItemText, CssBaseline, IconButton, Drawer, Badge} from '@mui/material';
import { Link, matchPath, useLocation, redirect } from 'react-router-dom';
import { UserMenu, useUserMenu } from 'react-admin';
import { useAuth0 } from '@auth0/auth0-react';
import MenuItem from '@mui/material/MenuItem';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CardMedia from '@mui/material/CardMedia';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import axios from 'axios';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HandshakeIcon from '@mui/icons-material/Handshake';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ContactsIcon from '@mui/icons-material/Contacts';
import TaskIcon from '@mui/icons-material/Task';
import { Auth } from "aws-amplify";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationMenu from './Notifications/NotificationMenu';

const Header = (props: any) => {
    const [user, setUser] = useState<any>();
    async function getUser(){
        const user = await Auth.currentAuthenticatedUser();
        setUser({email:user.attributes.email, role:user.attributes['custom:role'], accessToken:user.storage['CognitoIdentityServiceProvider.'+process.env.REACT_APP_COGNITO_CLIENT_ID+'.'+user.username+'.accessToken']})
    }
    useEffect(() => {
        getUser();
    },[])
    const location = useLocation();
    const drawerWidth = 240;
    let role = user?.role;
    const onClose = useUserMenu();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [isApproved,setIsApproved] = useState<any>(null);
    const [userInfo, setUserInfo] = useState<any>();
    const handleClose = () => {
        setAnchorEl(null);
        
    }
    const [mobileOpen, setMobileOpen] = useState(false);
    const handleDrawerToggle = () => {
      setMobileOpen((prevState) => !prevState);
    };
    const[disableIcon, setDisableIcon] = useState(true);
    const[change, setChange] = useState(0);
    let currentPath = '/admin';
    if (!!matchPath('/admin/contacts/*', location.pathname)) {
        currentPath = '/admin/contacts';
    }
    else if (!!matchPath('/admin/companies/*', location.pathname)) {
        currentPath = '/admin/companies';
    } else if (!!matchPath('/admin/deals/*', location.pathname)) {
        currentPath = '/admin/deals';
    } else if (!!matchPath('/admin/transactions/*', location.pathname)) {
        currentPath = '/admin/transactions';
    }
    else if (!!matchPath('/admin/activityCentre', location.pathname)) {
        currentPath = '/admin/activityCentre';
    }
    else if (!!matchPath('/admin/venApproveConn', location.pathname)) {
        currentPath = '/admin/venApproveConn';
    }
    else if (!!matchPath('/admin/profile', location.pathname)) {
        currentPath = '/admin/proile';
    }
    else if (!!matchPath('/admin/viewpdf', location.pathname)) {
        currentPath = '/admin/products';
    }
    else if (!!matchPath('/admin/createdeal', location.pathname)) {
        currentPath = '/admin/deals';
    }

    async function signOut() {
        try {
          await Auth.signOut({ global: true });
          window.location.href=String(process.env.REACT_APP_LOGIN_URI);
        } catch (error) {
           localStorage.clear();
           window.location.href=String(process.env.REACT_APP_LOGIN_URI);
        }
      }
    
    async function checkStatus(screenType:any){
        if (user?.role!=="admin") {
            var userType = user?.role;
            if(user?.role === "vendor"){
                    userType = "contact"
                }
            if(isApproved !== "approved"){
                const userData = await axios.get
                (
                    process.env.REACT_APP_BASE_URL +
                    userType +
                    '/mail/' +
                    user?.email,
                    {
                      headers: {
                          "Authorization": "Bearer "+user.accessToken,
                      }
                    }
                )
                .catch((error: any) => {
                })
                .then((response: any) => {
                    if(user?.role === "vendor" || user?.role === "contact"){
                        setIsApproved(response.data.vendorId.isApproved)
                        if(response.data.vendorId.isApproved === "approved"){
                            setDisableIcon(false);
                        }
                    }
                    else{
                        setIsApproved(response.data.isApproved);
                        if(response.data.isApproved === "approved"){
                            setDisableIcon(false);
                        }
                    }
                    return response.data;
                });
                const pageUrl = window.location.href;
                if(user.role === "contact" || user.role === "vendor"){
                    if(userData.vendorId.isApproved === "approved"){
                        window.location.href = pageUrl+screenType;
                    }
                }
                else{
                    if(userData.isApproved === "approved"){
                        window.location.href = pageUrl+screenType;
                    }
                }
            }
        }
    }

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center'}}>
            <Box display="flex" alignItems="center" sx={{ alignItems: "center", marginLeft:3, paddingY:1, display:{xs:"block", sm:"block", md:"none", lg:"none", xl:"none"}}}>
                <CardMedia
                    component="img"
                    image={"assets/logos/ConnectorNet-Logo-Horiz-RGB.png"}
                    alt="logo"
                    sx={{
                        marginRight: '1em',
                        height: 60,
                        width: 170,
                        paddingTop: 0
                    }}
                />
            </Box>
            <Divider />
            <List>
                <Link to="/admin">
                    <ListItem key={"Dashboard"} disablePadding>
                        <ListItemButton onClick={() => checkStatus("")}>
                            <DashboardIcon sx={{fontSize:"30px", color:"black"}}/><ListItemText primary={"Dashboard"} sx={{paddingLeft:1}}/>
                        </ListItemButton>
                    </ListItem>
                </Link>
                <Link to={disableIcon?"/admin":"/admin/deals"} >
                    <ListItem key={"Deals"} disablePadding>
                        <ListItemButton onClick={() => checkStatus("deal")}>
                            <HandshakeIcon sx={{fontSize:"30px", color:"black"}}/><ListItemText primary={"Deals"} sx={{paddingLeft:1}}/>
                        </ListItemButton>
                    </ListItem>
                </Link>
                <Link  to={disableIcon?"/admin":"/admin/transactions"}>
                    <ListItem key={"Transactions"} disablePadding>
                        <ListItemButton onClick={() => checkStatus("/transactoions")}>
                            <ReceiptIcon sx={{fontSize:"30px", color:"black"}}/><ListItemText primary={"Transactions"} sx={{paddingLeft:1}}/>
                        </ListItemButton>
                    </ListItem>
                </Link>
                {/* <Link  to={disableIcon?"/admin":"/admin/contacts"}>
                    <ListItem key={"Contacts"} disablePadding>
                        <ListItemButton>
                            <ContactsIcon sx={{fontSize:"30px", color:"black"}}/><ListItemText primary={"Contacts"} sx={{paddingLeft:1}}/>
                        </ListItemButton>
                    </ListItem>
                </Link> */}
                {(role === "admin" || role === "vendor" || role==="contact")&&
                <>
                <Link  to={disableIcon?"/admin":"/admin/activitycentre"}>
                    <ListItem key={"Activity Center"} disablePadding>
                        <ListItemButton onClick={() => checkStatus("/activitycentre")}>
                            <TaskIcon sx={{fontSize:"30px", color:"black"}}/><ListItemText primary={"Activity Center"} sx={{paddingLeft:1}}/>
                        </ListItemButton>
                    </ListItem>
                </Link>
                </>}
            </List>
        </Box>
    );

    const getUserData = () => {
        if (user?.role!=="admin") {
            var userType = user?.role;
            if(user?.role === "vendor"){
                userType = "contact"
            }
            axios
                .get(
                    process.env.REACT_APP_BASE_URL +
                    userType +
                    '/mail/' +
                    user?.email,
                    {
                      headers: {
                          "Authorization": "Bearer "+user.accessToken,
                      }
                    }
                )
                .catch((error: any) => {
                })
                .then((response: any) => {
                    setUserInfo(response.data);
                    if(user?.role === "vendor" || user?.role === "contact"){
                        setIsApproved(response.data.vendorId.isApproved)
                        if(response.data.vendorId.isApproved === "approved"){
                            setDisableIcon(false);
                        }
                    }
                    else{
                        setIsApproved(response.data.isApproved);
                        if(response.data.isApproved === "approved"){
                            setDisableIcon(false);
                        }
                    }
                    return response.data;
                });
        }
        else{
            setIsApproved("approved");
            setDisableIcon(false);
        }
    }

    useEffect(() => {
        if(user){
            getUserData();
        }
    }, [user]);
    
    return (
        <Box component="nav" sx={{ display: 'flex' }}>
            <AppBar
                position="fixed"
                color="primary"
                elevation={3}
                sx={{ padding: '5px', backgroundColor: role == "connector" ? "#00ba94" : role=="vendor" || role ==="contact"? "": "#db615d", width:"100%"}}
            >
                <Toolbar variant="dense" sx={{width:"100%"}}>
                    <Box display="flex" justifyContent="space-between" textAlign={'center'} component="div" sx={{ flexGrow: 1 }} >
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ display: { md: 'none' }, marginLeft:1 }}
                    >
                        <MenuIcon />
                    </IconButton>
                        <Link to="/admin" style={{ textDecoration: "none", color: "inherit" }}>
                            <Box display="flex" alignItems="center" sx={{ alignItems: "center", display:{xs:"none", sm:"none", md:"block", lg:"block", xl:"block"}}}>
                                <CardMedia
                                    component="img"
                                    image={"assets/logos/ConnectorNet-Logo-Horiz-White.png"}
                                    alt="logo"
                                    sx={{
                                        marginRight: '1em',
                                        height: 60,
                                        width: 170,
                                        paddingTop: 0
                                    }}
                                />
                                {/* <Typography variant="h6" component="div" sx={{flexGrow:1}}>ConnectorNet</Typography> */}
                            </Box>
                        </Link>
                        <Box sx={{paddingTop: 1.2, display: { xs: 'none', sm: 'none', md:"block" }}}>
                            <Tabs
                                value={currentPath}
                                aria-label="Navigation Tabs"
                                indicatorColor="secondary"
                                textColor="inherit"
                                // variant="scrollable"
                                // scrollButtons="auto"
                            >
                                <Tab
                                    onClick={() => checkStatus("")}
                                    label={"Dashboard"}
                                    component={Link}
                                    to="/admin"
                                    value="/admin"
                                />
                                {(role=="admin" || isApproved=="approved") && <Tab
                                    onClick={() => checkStatus("/deals")}
                                    label={"Deals"}
                                    component={Link}
                                    to="/admin/deals"
                                    value="/admin/deals"
                                />}
                                {(role!="admin" && isApproved!="approved") && <Tab
                                    onClick={() => checkStatus("/deals")}
                                    label={"Deals"}
                                    component={Link}
                                    to="/admin"
                                    value="/admin/deals"
                                />}
                                {(role=="admin" || isApproved=="approved") && <Tab
                                    onClick={() => checkStatus("/transactions")}
                                    label={"Transactions"}
                                    component={Link}
                                    to="/admin/transactions"
                                    value="/admin/transactions"
                                />}
                                {(role!="admin" && isApproved!="approved") && <Tab
                                    onClick={() => checkStatus("/transactions")}
                                    label={"Transactions"}
                                    component={Link}
                                    to="/admin"
                                    value="/admin/transactions"
                                />}
                                {/* {(role!="admin" && isApproved=="approved") && <Tab
                                    label={"Contacts"}
                                    component={Link}
                                    to="/admin/contacts"
                                    value="/admin/contacts"
                                />}
                                {(role!="admin" && isApproved!="approved") && <Tab
                                    label={"Contacts"}
                                    component={Link}
                                    to="/admin"
                                    value="/admin/contacts"
                                />} */}

                                {((role === "vendor" && isApproved==="approved") || (role === "contact" && isApproved==="approved") || role === "admin") && (
                                    <Tab
                                        onClick={() => checkStatus("/activitycentre")}
                                        label={"Activity Center"}
                                        component={Link}

                                        to="/admin/activityCentre"
                                        value="/admin/activityCentre"
                                    />
                                )}
                                {((role === "vendor" && isApproved!=="approved") || (role === "contact" && isApproved!=="approved")) && (
                                    <Tab
                                        onClick={() => checkStatus("/activitycentre")}        
                                        label={"Activity Center"}
                                        component={Link}

                                        to="/admin"
                                        value="/admin/activityCentre"
                                    />
                                )}
                            </Tabs>
                        </Box>
                        <Box display="flex" sx={{ paddingTop: 1 }}>
                            {role !== "admin" && <NotificationMenu user={user} userInfo={userInfo}/>}
                            <UserMenu>
                                <MenuList>
                                    <MenuItem component={Link} to="/admin/profile"
                                        style={{
                                            textDecoration: 'none',
                                            color: 'inherit',
                                        }}
                                        onClick={handleClose}>
                                        <ListItemIcon>
                                            <AccountCircleIcon />
                                        </ListItemIcon>
                                        Profile
                                    </MenuItem>
                                    <MenuItem
                                        onClick={signOut}
                                    >
                                        <ListItemIcon>
                                            <PowerSettingsNewIcon />
                                        </ListItemIcon>
                                        Logout
                                    </MenuItem>
                                </MenuList>
                                {/* </Menu> */}
                            </UserMenu>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
            <Box component="nav">
                <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'block', md:"none" },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
                >
                {drawer}
                <Box
                    component="div"
                    sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                >
                    <img src="assets/logos/ConnectorNet-Logo-Horiz-White.png" height="80px"/>
                </Box>
                </Drawer>
            </Box>
        </Box>
    );
};

export default Header;
