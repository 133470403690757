import React, { useState } from 'react';
import Navbar from './Navbar';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import { stakeHolders } from './StakeHolderData';
import { Link } from 'react-router-dom';

const AboutUs = () => {
    const [showMoreDetails, setShowMoreDetails] = useState("")
    return (
        <div id="layoutDefault">
            <div id="layoutDefault_content">
                <main>
                    <Navbar />
                    <header className="page-header-ui page-header-ui-dark bg-gradient-primary-to-secondary">
                        <div className="page-header-ui-content pt-10">
                            <div className="container px-5 text-center">
                                <div className="row gx-5 justify-content-center">
                                    <div className="col-lg-8">
                                        <h1 className="page-header-ui-title mb-3">
                                            About Us
                                        </h1>
                                        <p className="page-header-ui-text">
                                            At ConnectorNet, we strive to create
                                            a dynamic network where trusted
                                            partnerships can flourish, and
                                            businesses can prosper. Join us as a
                                            connector and be part of a network
                                            that's driving progress and
                                            innovation in healthcare.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="svg-border-rounded text-white">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 144.54 17.34"
                                preserveAspectRatio="none"
                                fill="currentColor"
                            >
                                <path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path>
                            </svg>
                        </div>
                    </header>
                    <section className="bg-white py-10">
                        <div className="container px-5">
                            <div className="row gx-5">
                            {stakeHolders.map((stakeHolder:any) => {
                                return(
                                    <div className="col-md-12 col-xl-6 mb-5">
                                        <div className="card card-team my-5" style={{minHeight:"400px"}}>
                                            <div className="card-body">
                                                <img
                                                    className="card-team-img mb-3"
                                                    src={stakeHolder.picture}
                                                    alt={stakeHolder.name}
                                                />
                                                <div className="card-team-name">
                                                    {stakeHolder.name}
                                                </div>
                                                <div className="card-team-position mb-3">
                                                    {stakeHolder.designation}
                                                </div>
                                                <p className="small mb-3">
                                                    {stakeHolder.intro}
                                                </p>
                                                <Link to={"/about_us/info/"+stakeHolder.username}><span className='mb-2' style={{color: "#293ce2", cursor: "pointer"}}>More</span></Link>
                                            </div>
                                            <div className="card-footer text-center">
                                                {/* <a
                                                    className="btn btn-icon btn-transparent-dark mx-1"
                                                    href={`mailto:${stakeHolder.email}`}
                                                >
                                                    <EmailIcon/>
                                                </a> */}
                                                <a
                                                    className="btn btn-icon btn-transparent-dark mx-1"
                                                    href={stakeHolder.linkedIn}
                                                >
                                                    <LinkedInIcon />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        </div>
    );
};

export default AboutUs;
