import react, { useEffect, useState } from 'react';
import SideDrawer from './SideDrawer';
import MyProfile from './MyProfile';
import { BottomNavigation, BottomNavigationAction, Box, Button, Grid, Paper, Slide } from '@mui/material'
import LegalDocs from '../legalDocs/LegalDocs';
import { VendorProfileProducts } from './UserProfile';
import VendorContacts from '../Admin/VendorsContacts/VendorContacts';
import VendorProducts from '../Admin/VendorProducts';
import { useAuth0 } from '@auth0/auth0-react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import WidgetsIcon from '@mui/icons-material/Widgets';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

export default function Profile(props: any) {
  const { user } = props;
  const [currentComp, setCurrentComp] = useState(<MyProfile screen={user?.role + "Admin"} user={user} />);
  useEffect(() => {
    if (user) {
      if (user.role === "connector") {
        setCurrentComp((<MyProfile screen={user?.role + "Admin"} user={user} />));
      }
      else {
        setCurrentComp((<MyProfile screen={"vendorAdmin"} user={user} />));
      }
    }
  }, [user])
  const [value, setValue] = useState<any>();
  const [menuType, setMenuType] = useState("")
  const [showOptions, setShowOptions] = useState(false);

  const HandleMenuItemSelect = (itemNo: any) => {
    if (itemNo === 1)
      setCurrentComp(<MyProfile screen={user?.role} user={user} />)
    else if (itemNo === 2)
      setCurrentComp(<VendorContacts user={user} />)
    else if (itemNo === 3)
      setCurrentComp(<VendorProfileProducts />)
    else if (itemNo === 4)
      setCurrentComp(<LegalDocs />)
    else if (itemNo === 5)
      setCurrentComp(<VendorProducts user={user} />)
    else if (itemNo === 6)
      setCurrentComp(<VendorContacts user={user} />)
    else if (itemNo === 7)
      setCurrentComp(<MyProfile screen={"vendorAdmin"} user={user} />)
  }
  const onClickOrganisation = () => {
    HandleMenuItemSelect(7);
    setShowOptions(false);
  }
  const onClickProfile = () => {
    HandleMenuItemSelect(1);
    setShowOptions(false);
  }
  const onClickConnectorNetDocuments = () => {
    setCurrentComp(<LegalDocs screenType={"connectorNetDocs"} />)
    setShowOptions(false);
  }
  const onClickMyDocuments = () => {
    setCurrentComp(<LegalDocs screenType={"myDocs"} />)
    setShowOptions(false);
  }

  const onClickProducts = () => {
    HandleMenuItemSelect(5)
  }

  const onClickContacts = () => {
    HandleMenuItemSelect(6);
  }

  const onClickCancel = () => {
    setShowOptions(false);
  }

  const handleShowOptions = (type: any) => {
    setMenuType(type);
    setShowOptions(true);
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={0} md={0} lg={2} sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block", xl: "block" } }}>
          {user && <SideDrawer user={user} menuItemSelect={HandleMenuItemSelect} />}
        </Grid>
        <Grid item xs={12} sm={12} md={10} lg={10} sx={{ marginBottom: 9 }}>
          {currentComp}
        </Grid>
      </Grid>
      {user?.role !== "admin" ?
        <>
          <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, textAlign: "center", paddingTop: 1, boxShadow: 3, borderRadius: 3, backgroundColor: showOptions ? "#f0f1f4" : "white" }} elevation={5}>
            <Slide direction="up" in={showOptions} mountOnEnter unmountOnExit>
              <Box sx={{ margin: 3, padding: 1, textAlign: "left", transition: "height 5s", backgroundColor: "white", borderRadius: 3 }}>
                {menuType === "profile" &&
                  <>
                    <AccountCircleIcon /><Button onClick={onClickOrganisation}>My Organisation</Button><br />
                    <AccountCircleIcon /><Button onClick={onClickProfile}>My Profile</Button><br />
                  </>
                }
                {menuType === "legals" &&
                  <>
                    <PlagiarismIcon /><Button onClick={onClickConnectorNetDocuments}>ConnectorNet Documents</Button><br />
                    <PlagiarismIcon /><Button onClick={onClickMyDocuments}>My Documents</Button><br />
                  </>
                }
                <ArrowDownwardIcon /><Button onClick={onClickCancel} sx={{ color: "red" }}>Cancel</Button>
              </Box>
            </Slide>
            <Slide direction="up" in={!showOptions} mountOnEnter unmountOnExit>
              <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
                sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none", xl: "none" }, alignText: "center", justifyContent: "center" }}
              >
                {(user?.role === "vendor" || user?.role === "contact") ? <BottomNavigationAction onClick={() => handleShowOptions("profile")} label="Profiles" icon={<AccountCircleIcon />} /> : <BottomNavigationAction onClick={() => HandleMenuItemSelect(1)} label="Profile" icon={<AccountCircleIcon />} />}
                <BottomNavigationAction onClick={() => handleShowOptions("legals")} label="Legal Documents" icon={<PlagiarismIcon />} />
                {(user?.role === "vendor" || user?.role === "contact") && <BottomNavigationAction onClick={onClickProducts} label="Products" icon={<WidgetsIcon />} />}
                {(user?.role === "vendor" || user?.role === "contact") && <BottomNavigationAction onClick={onClickContacts} label="Contacts" icon={<ContactPageIcon />} />}
              </BottomNavigation>
            </Slide>
          </Paper>
        </>
        : <></>}
    </>
  );
}
