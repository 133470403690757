export const convertNumber = (phone: number) => {
    var cleaned = ('' + phone).replace(/\D/g, '');
    var match = cleaned.match(/^(1|91|44|55)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        var intlCode = (match[1] ? `+${match[1]} ` : '');
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
}

export const convertDate = (defaultDate: any) => {
    var date = new Date(defaultDate);
    return (((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear());
}

export const convertCurrency = (defaultAmount: number) => {
    const converter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    })
    return (converter.format(defaultAmount))
}

export const firstCapital = (str: string) => {
    if (str) {
        // const str2 = str.charAt(0).toUpperCase() + str.slice(1);
        const str2 = str.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
        return str2;
    }
    return "";
}

export const currentDate = (date:any) => {
    var str = date.getFullYear()+"-"+("00" + (date.getMonth() + 1)).slice(-2)
        + "-" + ("00" + date.getDate()).slice(-2) + " "
        + ("00" + date.getHours()).slice(-2) + ":"
        + ("00" + date.getMinutes()).slice(-2)
        + ":" + ("00" + date.getSeconds()).slice(-2);
    return(str);
}

export const createDate = (days:any) => {
    var targetDate = new Date();
    targetDate.setDate(targetDate.getDate() + days);
    return(currentDate(targetDate));
}

export const currentDateTime = (days:any) => {
    var date = new Date();
    date.setDate(date.getDate() + days);
    var str = date.getFullYear()+"-"+("00" + (date.getMonth() + 1)).slice(-2)
        + "-" + ("00" + date.getDate()).slice(-2) + " "
        + ("00" + date.getHours()).slice(-2) + ":"
        + ("00" + date.getMinutes()).slice(-2)
        + ":" + ("00" + date.getSeconds()).slice(-2);
    return(str);
}