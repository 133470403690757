import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
    Badge,
    Box,
    Button,
    CircularProgress,
    Grid,
    Menu,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from "react-toastify";
import ErrorComponent from '../../Layout/ErrorComponent';
import PaginationLoader from '../../Layout/PaginationLoader';
import SearchTextField from '../../Layout/SearchTextField';
import StatusCell from '../../Layout/StatusCell';
import TableHeadRow from '../../Layout/TableHeadRow';
import ShowBrochure from '../../products/ShowBrochure';
import { linkCol, linkHover, statusColor, statusColorText } from '../../tableStyle';
import ProductProfile from './../../Profile Screens/ProductProfile';
import RejectionForm from './RejectionForm';
import TableCardComponent from './TableCardComponent';
import { firstCapital } from './util';
import ProfileScreen from './../../Profile Screens/ProfileScreen';


const ApproveProduct = (props: any) => {
    const { user, setPendingProducts, setShowDialog } = props;
    const [data, setData] = useState<any>();
    const [firstLoader, setFirstLoader] = useState(true);
    const [currentSelection, setCurrentSelection] = useState()

    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState(false);
    const [loader, setLoader] = useState(false);
    const [showBrochureDialog, setShowBrochureDialog] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const [statusLoader, setStatusLoader] = useState(0);
    const [showRejectionForm, setShowRejectionForm] = useState(false);
    const [formData, setFormData] = useState<any>();
    const [saveLoader, setSaveLoader] = useState(false);
    const [paginationLoader, setPaginationLoader] = useState(false);
    const successToast = () => toast.success("Mail sent successfully");
    const failureToast = () => toast.error("There was some error in sending the notification, please try again.");
    const [records, setRecords] = useState(5);
    const [type, setType] = useState('')
    const [empty, setEmpty] = useState(false);
    useEffect(() => {
        if (firstLoader) {
            setLoader(true);
        }
        if (user) {
            setPaginationLoader(true);
            axios.get(process.env.REACT_APP_BASE_URL + "product/pages",
                {
                    headers: {
                        "Authorization": "Bearer " + user.accessToken,
                    },
                    params: {
                        pageNo: page,
                        pageSize: rowsPerPage,
                        sortBy: "id",
                        sortDir: "desc"
                    }
                })
                .catch((error: any) => {
                    setFirstLoader(false);
                    setError(true);
                    setLoader(false);
                    setPaginationLoader(false);
                })
                .then((response: any) => {
                    setRecords(response.data.totalElements)
                    setFirstLoader(false);
                    setData(response.data.data);
                    if (response.data.data.length === 0) {
                        setEmpty(true);
                    }
                    setLoader(false);
                    setPaginationLoader(false);
                    setPendingProducts(response.data.additionalData.pendingProductCount)
                })
        }
    }, [page, rowsPerPage, user])

    const onClickReject = (row: any) => {
        setShowRejectionForm(true);
        setFormData(row);
    }

    const onCancelReject = (row: any) => {
        setShowRejectionForm(false);
    }

    const handleRejection = () => {
        setSaveLoader(true);
        setShowRejectionForm(false);
        setStatusLoader(formData.id);
        axios.put(process.env.REACT_APP_BASE_URL + 'product/' + formData.id, formData,
            {
                headers: {
                    "Authorization": "Bearer " + user.accessToken,
                }
            })
            .catch((error: any) => {
                setStatusLoader(0)
                handleMenuClose()
                setSaveLoader(false);
                failureToast()
            })
            .then((response: any) => {
                setStatusLoader(0)
                var tempData = [...data]
                const ind = tempData.findIndex(obj => obj.id === response.data.id);
                tempData[ind] = response.data;
                setData(tempData);
                setSaveLoader(false);
                setShowRejectionForm(false);
                successToast();
            })
    }

    const showProfile = (row: any, profile: string) => {
        setType(profile)
        setCurrentSelection(row)
        setShowModal(true)
    }

    const [openElem, setOpenElem] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenuClick = (elem: any) => (event: any) => {
        setAnchorEl(event.currentTarget);
        setOpenElem(elem);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setOpenElem(null);
    };

    const brochureShow = (row: any) => {
        setCurrentSelection(row);
        setShowBrochureDialog(true);
    }

    const handleChangePage = (event: any, newPage: any) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const onStatusChange = (row: any, action: string) => {
        setStatusLoader(row.id)
        var current = row.isApproved;
        var temp = { ...row, isApproved: action };
        axios.put(process.env.REACT_APP_BASE_URL + 'product/' + temp.id, temp,
            {
                headers: {
                    "Authorization": "Bearer " + user.accessToken,
                }
            })
            .catch((error: any) => {
                setStatusLoader(0)
                handleMenuClose()
            })
            .then((response: any) => {
                setStatusLoader(0)
                var tempData = [...data]
                const ind = tempData.findIndex(obj => obj.id === response.data.id);
                tempData[ind] = response.data;
                setData(tempData);
                if (current === "pending") {
                    setPendingProducts((prevVal: any) => prevVal - 1)
                }
            })
    }
    return (
        <>
            <RejectionForm showDialog={showRejectionForm} handleClose={onCancelReject} type={"Product"} formData={formData} setFormData={setFormData} handleSubmit={handleRejection} rejectionType={"rejected"} saveLoader={saveLoader} />
            {showModal && <ProfileScreen
                showDialog={showModal}
                setShowDialog={setShowModal}
                user={user}
                currentSelection={currentSelection}
                type={type}
            />}
            <ShowBrochure user={user} showDialog={showBrochureDialog} currentProduct={currentSelection} setCurrentProduct={setCurrentSelection} setShowDialog={setShowBrochureDialog} />
            {error ?
                <>
                    <Grid item xs={12} sx={{ textAlign: "center", marginTop: 10 }}>
                        <ErrorComponent />
                    </Grid>
                </>
                :
                <>
                    {empty ?
                        <>
                            <Grid item xs={12} sx={{ textAlign: 'center', marginTop: 2 }}>
                                <img src="assets/loader/not_found.gif" height={'140vh'} alt="" />
                                <Typography sx={{ fontWeight: 'bold' }}>
                                    No products found!
                                </Typography>
                                <Typography>
                                    Seems like you don't have any products yet.<br />
                                </Typography>
                            </Grid>
                        </>
                        :
                        <>
                            {!loader ?
                                <>
                                    <Grid container>
                                        <Grid container rowSpacing={1}>
                                            <Grid item xs={7}>
                                                <SearchTextField />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TableContainer component={Paper} sx={{ marginTop: 1, display: { xs: "none", sm: "none", md: "block", lg: "block", xl: "block" } }}>
                                                    <Table aria-label="a dense table" size="small">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableHeadRow arr={[
                                                                    {
                                                                        name: 'NAME',
                                                                        alignment: 'left',
                                                                        valueToOrderBy: false,
                                                                    },
                                                                    {
                                                                        name: 'VENDOR',
                                                                        alignment: 'left',
                                                                        valueToOrderBy: false,
                                                                    },
                                                                    {
                                                                        name: 'BROCHURE',
                                                                        alignment: 'left',
                                                                        valueToOrderBy: false,
                                                                    },
                                                                    {
                                                                        name: 'STATUS',
                                                                        alignment: 'center',
                                                                        valueToOrderBy: false,
                                                                    },
                                                                    {
                                                                        name: 'ACTION',
                                                                        alignment: 'center',
                                                                        valueToOrderBy: false,
                                                                    },
                                                                ]} />
                                                            </TableRow>
                                                        </TableHead>
                                                        {paginationLoader ?
                                                            <PaginationLoader colspan={5} />
                                                            :
                                                            <TableBody>
                                                                {data && data.map((row: any, index: any) => {
                                                                    return (
                                                                        <TableRow
                                                                            key={row.id}
                                                                            sx={{
                                                                                '&:last-child td, &:last-child th':
                                                                                {
                                                                                    border: 0,
                                                                                },
                                                                            }}
                                                                        >
                                                                            <TableCell align="left">
                                                                                <Typography
                                                                                    onClick={(e) => showProfile(row, "product")}
                                                                                    style={linkCol}
                                                                                >
                                                                                    <Typography sx={linkHover}>
                                                                                        {firstCapital(row.name)}
                                                                                    </Typography>
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell align="left">
                                                                                <Typography style={linkCol} onClick={(e) => showProfile(row.vendorId, "vendor")}>
                                                                                    <Typography sx={linkHover}>{row.vendorId ? firstCapital(row.vendorId.name) : null}</Typography>
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {(row.documentCount && row.documentCount > 0) ? <Typography
                                                                                    sx={linkHover}
                                                                                    onClick={(e) => brochureShow(row)}
                                                                                >
                                                                                    View
                                                                                    <Badge badgeContent={row.documentCount ? row.documentCount : 0} color="primary" sx={{ paddingRight: 2 }} showZero />
                                                                                </Typography> : <Typography>No brochure found</Typography>}
                                                                            </TableCell>
                                                                            <StatusCell textClr={statusColorText[row.isApproved]} bgClr={statusColor[row.isApproved]} text={firstCapital(row.isApproved)} />
                                                                            <TableCell>
                                                                                {statusLoader === row.id ?
                                                                                    <Box display={"flex"} justifyContent={"center"}><CircularProgress size="1.6rem" /></Box> :
                                                                                    <Box display={"flex"} justifyContent={"center"}>
                                                                                        <Button
                                                                                            color="primary"
                                                                                            variant="outlined"
                                                                                            endIcon={<ArrowDropDownIcon />}
                                                                                            aria-label="more"
                                                                                            aria-controls={"long-menu" + row}
                                                                                            aria-haspopup="true"
                                                                                            sx={{
                                                                                                width: 90,
                                                                                                margin: "auto"
                                                                                            }}
                                                                                            onClick={handleMenuClick(row)}
                                                                                        >
                                                                                            <Typography sx={{ fontSize: "15px" }}>
                                                                                                SELECT
                                                                                            </Typography>
                                                                                        </Button>
                                                                                        <Menu
                                                                                            id={"long-menu" + row}
                                                                                            anchorEl={anchorEl}
                                                                                            keepMounted
                                                                                            open={openElem === row}
                                                                                            onClose={handleMenuClose}
                                                                                            anchorOrigin={{
                                                                                                vertical: 'bottom',
                                                                                                horizontal: 'left',
                                                                                            }}
                                                                                            transformOrigin={{
                                                                                                vertical: 'top',
                                                                                                horizontal: 'left',
                                                                                            }}
                                                                                        >
                                                                                            <MenuItem disabled={row.isApproved == "approved"} onClick={() => onStatusChange(row, "approved")}>
                                                                                                <Typography sx={{ fontSize: "15px", cursor: "pointer", color: "#00BFFF", '&:hover': { color: "#0000ff" } }} >Approve</Typography>
                                                                                            </MenuItem>
                                                                                            <MenuItem disabled={row.isApproved == "rejected"} onClick={() => onClickReject(row)}>
                                                                                                <Typography sx={{ fontSize: "15px", cursor: "pointer", color: "#00BFFF", '&:hover': { color: "#0000ff" } }}>Reject</Typography>
                                                                                            </MenuItem>

                                                                                        </Menu>
                                                                                    </Box>}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    );
                                                                })}
                                                            </TableBody>
                                                        }
                                                    </Table>
                                                </TableContainer>
                                                <Grid item xs={12} sx={{ display: { sx: "block", sm: "block", md: "none", lg: "none", xl: "none" } }}>
                                                    {data &&
                                                        <TableCardComponent rowData={data} userType={"product"} brochureShow={brochureShow} showProfile={showProfile} onClickReject={onClickReject} onApprove={onStatusChange} approveLoader={statusLoader} paginationLoader={paginationLoader} />
                                                    }
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {data && (
                                                        <TablePagination
                                                            rowsPerPageOptions={[5, 10, 15, 20]}
                                                            sx={{
                                                                '.MuiTablePagination-toolbar': {
                                                                    alignItems: 'baseline',
                                                                },
                                                                marginBottom: 8
                                                            }}
                                                            component="div"
                                                            count={records}
                                                            rowsPerPage={rowsPerPage}
                                                            page={page}
                                                            onPageChange={handleChangePage}
                                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                                        />
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                                :
                                <>
                                    <Grid container>
                                        <Grid item xs={12} sx={{ textAlign: "center", marginTop: 10 }}>
                                            <img src="assets/loader/Spinner-px.gif" height={"140vh"} alt="" />
                                            <Typography sx={{ paddingTop: 2 }}>Please wait while we fetch all product details</Typography>
                                        </Grid>
                                    </Grid>
                                </>
                            }
                        </>
                    }
                </>
            }
        </>
    );
};

export default ApproveProduct;
