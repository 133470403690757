import { Alert, AlertTitle, Box, Button, Card, CardActions, CardContent, CardMedia, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import axios from 'axios';
import { Viewer,Worker } from '@react-pdf-viewer/core';
import WestIcon from '@mui/icons-material/West';
import ErrorComponent from '../Layout/ErrorComponent';
import { FileDownload } from '@mui/icons-material';
import { handleDownloadClick } from '../legalDocs/fileDownloadutil';
import { PDFObject } from 'react-pdfobject';
import TableHeadRow from '../Layout/TableHeadRow';
import { getComparator, sortedRowInformation } from '../utils/SortingHandler';
import { linkHover } from '../tableStyle';
import { convertCurrency, convertDate } from '../Admin/Approvals/util';
import CloseIcon from '@mui/icons-material/Close';
import LegalDocsCardTable from '../legalDocs/LegalDocsTableCard';
const ShowBrochure = (props: any) => {
    const { user, showDialog, currentProduct, setCurrentProduct, setShowDialog, setFile } = props;
    const [showPdf, setShowPdf] = useState(false);
    const [error, setError] = useState(false);
    const [loader, setLoader] = useState(true);
    const [brochure,setBrochure] = useState<any>();
    const [currentBrochure, setCurrentBrochure] = useState<any>()
    const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>();
    const [valueToOrderBy, setValueToOrderBy] = useState();
    useEffect(() => {
        if(currentProduct){
            setLoader(true);
            axios.get(process.env.REACT_APP_BASE_URL+"product/document/product/"+currentProduct.id,
            {
              headers: {
                  "Authorization": "Bearer "+user.accessToken,
              }
            })
            .catch((error) => {
                
                setError(true);
                setBrochure(null);
            })
            .then((response:any) => {
                setBrochure(response.data);
                
                setLoader(false);
            })
        }
    },[currentProduct,showDialog])
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const handleChangePage = (event: any, newPage: any) => {
        setPage(newPage);
    };const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(+event.target.value);

        setPage(0);
    };
    const showCurrentBrochure = (record:any) => {
        setCurrentBrochure(record);
        setShowPdf(true);
    }
    const onBack = () => {
        setShowPdf(false);
    }

    const handleClose = () => {
        setCurrentProduct(null)
        setShowDialog(false);
        setError(false);
        setShowPdf(false);
        
        setFile(null);
    }
    const createSortHandler = (property: any) => (event: any) => {
        let isAscending;
        setValueToOrderBy(property);
        isAscending = orderDirection === 'asc';
        setOrderDirection(isAscending ? 'desc' : 'asc');
        sortedRowInformation(brochure, getComparator(orderDirection, property));
    };
    return (
        <Dialog open={showDialog} sx={{
            '.MuiDialog-paper': {
                position: 'absolute',
                width: {xs:"90%", sm:"85%", md:"65%", lg:"65%", xl:"65%"},
                height: '700px',
                minWidth: {xs:"90%", sm:"85%", md:"65%", lg:"65%", xl:"65%"}
            },
        }}>
            <DialogTitle sx={{ textAlign: "center", fontWeight: 'bold', backgroundColor: "#F0F7FF", fontSize:{xs:"large", sm:"large", md:"34px", lg:"34px", xl:"34px"} }}>
                    Product Brochure
                    <Tooltip
                        title={"Preview and download product brochure here."}
                        arrow
                        placement="right-end"
                    >
                        <Button>
                            <InfoOutlinedIcon />
                        </Button>
                    </Tooltip>
                <IconButton onClick={handleClose} sx={{ float: "right", color: "red" }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{overflow:"hidded", '&::-webkit-scrollbar': {display: "none"}}}>
                <Grid container spacing={2}>
                    {error? 
                        <Grid item xs={12} sx={{ textAlign: 'center', marginTop: 8 }}>
                            <ErrorComponent />
                        </Grid> 
                        : 
                        <Grid item xs={12}>
                            {!loader ? 
                            <>
                            {brochure && brochure.length==0 ?  
                            <Grid item xs={12} sx={{ textAlign: 'center', marginTop: 8 }}>
                                <img src="assets/loader/not_found.gif" height={'140vh'} alt="No data found icon" />
                                <Typography sx={{ fontWeight: 'bold' }}>
                                    No Brochure Found!
                                </Typography>
                                <Typography>
                                    Seems like you haven't uploaded any brochure for this product
                                    <br />
                                </Typography>
                            </Grid> 
                            : 
                            <>
                            {showPdf ? 
                            <>
                            <Grid container >
                                <Grid item xs={12}>
                                    <PDFObject width="100%" height="700px" url={`data:application/pdf;base64,${currentBrochure.content}`}/>
                                </Grid>
                            </Grid>
                            </> 
                            : 
                        <>
                        <TableContainer component={Paper} sx={{ marginBottom: 2, display:{xs:"none", sm:"none", md:"block", lg:"block", xl:"block"} }}>
                            <Table aria-label="simple table" size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableHeadRow createSortHandler={createSortHandler} valueToOrderBy={valueToOrderBy} arr={[
                                            {
                                                name: 'BROCHURE NAME',
                                                alignment: 'left',
                                                valueToOrderBy: 'name',
                                                orderDirection: orderDirection
                                            },
                                            {
                                                name: 'UPLOAD DATE',
                                                alignment: 'right',
                                                valueToOrderBy: 'createdDatetime',
                                                orderDirection: orderDirection
                                            }
                                        ]}/>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sortedRowInformation(
                                        brochure.slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                            ),getComparator(orderDirection, valueToOrderBy)).map((record:any) => {
                                        return(
                                            <>
                                                <TableRow>
                                                    <TableCell align="left" sx={{height:73, width:"150px"}}>
                                                    <Tooltip
                                                        title={record.name}
                                                        arrow
                                                        placement="top"
                                                    >
                                                        <Typography
                                                            sx={linkHover}
                                                            onClick = {(e) => {showCurrentBrochure(record)}}
                                                        >
                                                            {record.name}
                                                        </Typography>
                                                    </Tooltip>
                                                    </TableCell>
                                                    <TableCell align="right" sx={{height:60, paddingRight:5.2}}>
                                                        <Typography>
                                                            {convertDate(record.createdDatetime)}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        
                        {brochure && (
                            <>
                            <Grid item xs={12} sx={{display:{xs:"block", sm:"block", md:"none", lg:"none", xl:"none"}}}>
                                <LegalDocsCardTable rowData = {brochure.slice(page * rowsPerPage,page * rowsPerPage + rowsPerPage)} showCurrentDoc={showCurrentBrochure} userType = {"brochure"}/>
                            </Grid>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 15]}
                                sx={{'.MuiTablePagination-toolbar': {
                                        alignItems: 'baseline',
                                    },
                                }}
                                component="div"
                                count={brochure.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                            </>
                            )}
                        </>
                        }
                            
                            </>}</> : <>
                                <Grid item xs={12} sx={{ textAlign: "center", marginTop: 8 }}>
                                    <img src="assets/loader/Spinner-px.gif" height={"140vh"} />
                                    <Typography sx={{ paddingTop: 2 }}>Please wait while we fetch the product brochure</Typography>
                                </Grid>
                            </>}
                        </Grid>
                    }
                </Grid>
            </DialogContent>
            <DialogActions sx={{ paddingRight: 5, paddingY: 2 }}>
                <Grid container>
                    <Grid item xs={6} sx={{textAlign:"left"}}>
                        {showPdf?<Button variant="outlined" sx={{marginLeft:3, width: 100, padding: 1}} onClick={(e) => {onBack()}}><WestIcon/>&nbsp;Back</Button>:<></>}
                    </Grid>
                </Grid>
                
            </DialogActions>
        </Dialog>
    )
}

export default ShowBrochure