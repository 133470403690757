import { Alert, AlertTitle, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import DocumentUpload from '../transactions/DocumentUpload';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';

const ImageUpload = (props:any) => {
    const{showDialog, saveLoader, setShowDialog, handleSave, file, setFile} = props;
    const [showUpload, setShowUpload] = useState(true);
    const [show, setShow] = useState(false);
    const [error, setError] = useState(false);
    const handleFileChange = (file: any) => {
        setFile(file);
        
    };

    const toggleUpload = () => {
        setShowUpload(!showUpload);
        
        setShow(true);
    };

    const handleClose = () => {
        setShowDialog(false)
        setFile(null)
    }
    
    const onReset = () => {
        setFile(null)
    }
    const fileTypes = {
        "image/*":['.png','.jpg','.jpeg']
    };

    useEffect(() => {
        if(file){
            if(file.size >= 5000000){
                setFile(null);
                setError(true);
            }
            else{
                setError(false);
            }
        }
    },[file])
    
    const generateLink = (obj:any) => {
        const file = new Blob([obj], {type: "image/png"});
        const fileURL = window.URL.createObjectURL(file);
        const link = document.createElement("a")
        link.href = fileURL;
        
        return String(fileURL);
    }

    return (
        <Dialog open={showDialog}
            fullWidth
            maxWidth="lg"
            sx={{
                '.MuiDialog-paper': {
                    position: 'absolute',
                    width: {xs:"80%", sm:"80%", md:"50%", lg:"50%", xl:"50%"},
                    minHeight: '400px',
                    minWidth: {xs:"80%", sm:"80%", md:"50%", lg:"50%", xl:"50%"}
                },
            }}>
            <DialogTitle sx={{ textAlign: "center", fontWeight: 'bold', backgroundColor: "#F0F7FF", fontSize:{xs:"large", sm:"large", md:"34px", lg:"34px", xl:"34px"} }}>
                    Profile Photo
                    <Tooltip
                        title={`Drag and drop or upload your profile picture in the space provided below.`}
                        arrow
                        placement="right-end"
                    >
                        <Button>
                            <InfoOutlinedIcon />
                        </Button>
                    </Tooltip>
                <IconButton onClick={handleClose} sx={{ float: "right", color: "red" }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} sx={{ justifyContent: 'center', marginTop:3 }}>
                    {file?
                    <>
                        <Grid container>
                            <Grid item xs={6} sx={{textAlign:"left"}}>
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: '20px',
                                        whiteSpace:"no-wrap"
                                    }}
                                >
                                    Preview :
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sx={{textAlign:"right"}}>
                                <Button sx={{color:"red"}} onClick={(e) => onReset()}>reset</Button>
                            </Grid>
                            <Grid item xs={12} sx={{textAlign:"center"}}>
                                <img src={generateLink(file)} alt="profile image" width="50%" />
                            </Grid>
                        </Grid>
                    </>
                    :
                    <Box sx={{minWidth:"100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <DocumentUpload
                            file={file}
                            handleFileChange={handleFileChange}
                            toggleUpload={toggleUpload}
                            fileTypes={fileTypes}
                            type={"image"}
                            onReset={onReset}
                        />
                    </Box>}
                    <Grid item xs={12} sx={{textAlign:"center"}}>
                        {error && <Typography sx={{color:"red", marginTop:1}}>The file size is exceeding the maximum file size of 5MB !</Typography>}
                    </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ paddingRight: 5, paddingY: 2 }}>
            {file?
                <>
                    <Button
                        onClick={handleSave}
                        sx={{ width: 100, height: 45, padding: 1 }}
                        variant="contained"
                        disabled={saveLoader}
                    >
                        {saveLoader?
                        <>
                            <CircularProgress sx={{color:"white"}}/>
                        </>
                        :
                        <>
                            Save
                        </>}
                    </Button>
                </>:<></>}
            </DialogActions>
        </Dialog>
    )
}

export default ImageUpload