import { BottomNavigation, BottomNavigationAction, Grid, Paper, Slide, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { DealList } from './DealList';
import AllDeals from '../allDeals/AllDeals';
import TabMenu from '../Layout/TabMenu';
import axios from 'axios';
import RegisterDeal from './RegisterDeal';
import PageNameComp from '../PageNameComp';
import ErrorComponent from '../Layout/ErrorComponent';
import HandshakeIcon from '@mui/icons-material/Handshake';
import ViewListIcon from '@mui/icons-material/ViewList';
import EmailIcon from '@mui/icons-material/Email';
import ProfileScreen from '../Profile Screens/ProfileScreen';
import { Auth } from 'aws-amplify';
const Deals = () => {
    const [user, setUser] = useState<any>();
    useEffect(() => {
        Auth.currentAuthenticatedUser()
          .catch(() => {
             
            window.location.href = String(process.env.REACT_APP_LOGIN_URI)
          })
          .then(async () => {
            const user = await Auth.currentAuthenticatedUser();
            setUser({email:user.attributes.email, role:user.attributes['custom:role'], name:user.attributes.name, phoneNumber1:user.attributes['custom:phoneNumber'], organisationId:user.attributes['custom:organisationId'], accessToken:user.storage['CognitoIdentityServiceProvider.'+process.env.REACT_APP_COGNITO_CLIENT_ID+'.'+user.username+'.accessToken']})
          })
    }, [])
    const [dealData, setDealData] = useState([]);
    const[gotData, setGotData] = useState(false)
    const [heading, setHeading] = useState("Active Deals")
    const [currentText, setCurrentText] = useState("Find here all the active Deals");
    const [empty,setEmpty] = useState(false)
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(false);
    const [setIndex, setSetIndex] = useState(0)
    const [userInfo,setUserInfo] = useState<any>()
    const [value, setValue] = useState<any>();
    const [currentSelection, setCurrentSelection] = useState<any>()
    const [type, setType] = useState("");
    const [profileDialog, setProfileDialog] = useState(false);
    const showProfile = (row: any, profile: string) => {
        setType(profile)
        setCurrentSelection(row)
        setProfileDialog(true)
    }
    const [currentComp,setCurrentComp] = useState(<DealList setDealData={setDealData} user={user} dealData={dealData} empty={empty} showProfile={showProfile}/>);
    useEffect(() => {
        setLoader(true)
        if(user){
            if(user?.role === "admin")
        {
            axios.get(process.env.REACT_APP_BASE_URL+"deals/all",
            {
                headers: {
                    "Authorization": "Bearer "+user.accessToken,
                }
            })
            .catch((error:any) => {
                
                setLoader(false);
                setError(true);
            })
            .then((response:any)=>{
                
                if(response.data.length === 0){
                    setEmpty(true)
                    setCurrentComp(<DealList setDealData={setDealData} user={user} dealData={response.data} empty={true}/>)
                    setDealData(response.data)
                    setLoader(false)
                }
                else{
                    setCurrentComp(<DealList setDealData={setDealData} user={user} dealData={response.data} empty={empty}/>)
                    setDealData(response.data)
                    setLoader(false)
                }
            })
        }
        else{
            var userType = user?.role;
            if(user?.role === "vendor"){
                userType = "contact"
            }
            axios.get(process.env.REACT_APP_BASE_URL+userType+"/mail/"+user?.email,
            {
              headers: {
                  "Authorization": "Bearer "+user.accessToken,
              }
            })
            .catch(error => {
                
                setLoader(false)
                setError(true)
            })
            .then((response:any) => {
                
                if(user?.role === "vendor" || user?.role === "contact"){
                    setUserInfo(response.data.vendorId)
                }
                else{
                    setUserInfo(response.data)
                }
                setGotData(true)
            })
        }
        }
    },[user])
    useEffect(()=> {
        if(user){
            var userType = user?.role;
        if(user?.role === "contact"){
            userType = "vendor"
        }
        if(userInfo){
            const pageUrl = window.location.href.split('/');
            const component = pageUrl.at(-1);
            axios.get(process.env.REACT_APP_BASE_URL+"deals/"+userType+"/"+userInfo.id,
            {
                headers: {
                    "Authorization": "Bearer "+user.accessToken,
                }
            })
            .catch((error:any) => {
                
                setLoader(false);
            })
            .then((response:any)=>{
                
                setLoader(false)
                
                if(response.data.length === 0){
                    setEmpty(true);
                    setDealData(response.data);
                    if(user.role === "connector" && component === "registration"){
                        setHeading("Register Deal")
                        setCurrentText("Register your deal here")
                        setSetIndex(2);
                        setCurrentComp(<RegisterDeal user={user} userInfo={userInfo}/>)
                    }
                    else{
                        setCurrentComp(<DealList setDealData={setDealData} user={user} dealData={dealData} empty={true} onRedirect={onRedirect} showProfile={showProfile}/>);
                    }
                    
                }
                else{
                    setDealData(response.data)
                    if(user.role === "connector" && component === "registration"){
                        setHeading("Register Deal")
                        setCurrentText("Register your deal here")
                        setSetIndex(2);
                        setCurrentComp(<RegisterDeal user={user} userInfo={userInfo}/>)
                    }
                    else{
                        setCurrentComp(<DealList setDealData={setDealData} user={user} dealData={response.data} empty={empty} onRedirect={onRedirect} showProfile={showProfile}/>)   
                    }
                }
            })
        }
        }
    },[gotData])
    
    
    const onRedirect = () => {
        setCurrentComp(<RegisterDeal user={user} userInfo={userInfo}/>)
        setHeading("Register Deal")
        setSetIndex(2)
    }

    const HandleTabMenu = (itemNo:any) => {
        if(itemNo===0)
        {
          setCurrentComp(<DealList setDealData={setDealData} user={user} dealData={dealData} empty={empty} onRedirect={onRedirect} showProfile={showProfile}/>)
          setHeading("Active Deals")
          setCurrentText("Find here all the active Deals")
        }
        else if(itemNo===1)
        {
          setCurrentComp(<AllDeals user={user} dealData={dealData} empty={empty} onRedirect={onRedirect} showProfile={showProfile}/>)
          setHeading("All Deals")
          setCurrentText("Find here the list of all Deals")
        }
        else if(itemNo===2)
        {
            setCurrentComp(<RegisterDeal user={user} userInfo={userInfo}/>)
            setHeading("Register Deal")
            setCurrentText("Register your deal here")
        }
      }

    return (
        <>
        <ProfileScreen showDialog={profileDialog} currentSelection={currentSelection} type={type} setShowDialog={setProfileDialog} />
        <Grid container sx={{ display: 'flex' }} px={3}>
            <Grid item xs={12}>
                <PageNameComp currentText={currentText} heading={heading}/>
            </Grid>
            {error?<>
                <Grid item xs={12} sx={{textAlign:"center", marginTop:10}}>
                    <ErrorComponent/>
                </Grid>
            </>:<>
            {loader?<>
                <Grid item xs={12} sx={{textAlign:"center", marginTop:10}}>
                    <img src="assets/loader/Spinner-px.gif" height={"140vh"}/>
                    <Typography sx={{paddingTop:2}}>Please wait while we fetch your deals</Typography>
                </Grid>
            </>
                :
            <>
                {user&&
                <>
                    <Grid item xs={12} sx={{display:{xs:"none", sm:"none", md:"block", lg:"block", xl:"block"}}}>
                    {user?.role === "connector" ?
                        <TabMenu tabMenu={HandleTabMenu} item={["Active Deals","All Deals","Registrations"]} setIndex={setIndex} type={"parent"} setSetIndex={setSetIndex}/>
                        :
                        <TabMenu tabMenu={HandleTabMenu} item={["Active Deals","All Deals"]}/>
                    }
                    </Grid>
                    <Grid item xs={12}>
                        {currentComp}
                    </Grid>
                </>}
                </>}
            </>}
        </Grid>
        {(!error && !loader) && 
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, textAlign:"center", paddingTop:1, boxShadow:3, borderRadius:3, backgroundColor:"white"}} elevation={5}>
            {user&&
            <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
                sx={{display:{xs:"block", sm:"block", md:"none", lg:"none", xl:"none"}, alignText:"center", justifyContent:"center"}}
                >
                
                    <BottomNavigationAction onClick={() => HandleTabMenu(0)} label="Active Deals" icon={<HandshakeIcon />} />
                    <BottomNavigationAction onClick={() => HandleTabMenu(1)} label="All Deals" icon={<ViewListIcon />} />
                    {user?.role === "connector"&&<BottomNavigationAction onClick={() => HandleTabMenu(2)} label="Registrations" icon={<EmailIcon />} />}
                </BottomNavigation>
            </Slide>}
        </Paper>
        }
        </>
    )
}

export default Deals