import React, { useEffect } from "react";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { Auth } from 'aws-amplify';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  IconButton
} from "@mui/material";
import MuiPhoneNumber from 'material-ui-phone-number';
import axios from "axios";
import ErrorComponent from "../Layout/ErrorComponent";
import { useFormik } from 'formik';
import * as yup from 'yup';
import Navbar from "../Landing Page/Navbar";
import ErrorDialog from "../Layout/ErrorDialog";
import MfaConfirmationDialog from "./MfaConfirmationDialog";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
function SignUp() {
  const [formData, setFormData] = useState<any>({
    email: "",
    password: "",
    phoneNumber1: "",
  });
  const [currentUserData, setCurrentUserData] = useState<any>();
  const [showForm, setShowForm] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [pageLoader, setPageLoader] = useState(true);
  const [authorised, setAuthorised] = useState(true);
  const [userData, setUserData] = useState<any>();
  const [userRole, setUserRole] = useState("");
  const [error, setError] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [isPrefilled, setPrefilled] = useState(false);
  const [smsOtp, setSmsOtp] = useState("");
  const [showOtpField, setShowOtpField] = useState(false);
  const successToast = (message: any) => toast.success(message);
  const failureToast = () => toast.error("Registration Unsuccessful");
  const [showOrganisationFields, setShowOrganisationFields] = useState(false);
  const [showSignUpError, setShowSignUpError] = useState(false);
  const [phoneValidation, setPhoneValidation] = useState({ error: false, message: "" })
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [focusNum, setFocusNum] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isWebsiteValid, setIsWebsiteValid] = useState(true);
  const [websiteError, setWebsiteError] = useState<string | null>(null);
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(() => {
        window.location.href = String(process.env.REACT_APP_REDIRECT_URI);
      })
      .catch(() => {

      })
  }, [])
  const handleSignUpError = () => {
    setShowSignUpError(true);
  }
  const gridStyles = { height: "90px" }
  const exitSignUpError = () => {
    setShowSignUpError(false);
  }
  const pageUrl = window.location.href.split('/');
  useEffect(() => {
    if (pageUrl.at(-2) === "invite") {
      axios.get(process.env.REACT_APP_BASE_URL + "verification/" + pageUrl.at(-1))
        .catch((error: any) => {
          if (error.response.data.verified === false) {
            setAuthorised(false);
          }
          else {
            setError(true);
            setPageLoader(false);
          }
        })
        .then((response: any) => {
          if (response.data.verified === true) {
            setUserData(response.data);
            setFormData({ ...formData, email: response.data.email, phoneNumber1: response.data.phoneNumber })
            formik.setValues(
              {
                ...formik.values,
                website: response.data.website.substring('https://'.length),
                email: response.data.email,
                organisationName: response.data.name,
                firstName: response.data.firstName,
                lastName: response.data.lastName,
              })
            if (response.data.type === "vendor") {
              onSelectVendor()
            }
            else if (response.data.type === "connector") {
              onSelectConnector()
            }
            setPageLoader(false);
            setPrefilled(true);
          }
          else {
            setAuthorised(false);
          }
        })
    }
    else {
      setPageLoader(false);
    }
  }, [])

  const handlePhoneChange = (value: any) => {
    setFormData({ ...formData, phoneNumber1: value });
    if (phoneValidation.error) {
      checkPhoneValidation();
    }
  };

  const onSelectVendor = () => {
    setUserRole("vendor");
    setShowForm(true);
  };

  const onSelectConnector = () => {
    setUserRole("connector");
    setShowForm(true);
  };

  const checkPhoneValidation = () => {
    if (formData.phoneNumber1) {
      if (formData.phoneNumber1 === "" || formData.phoneNumber1 === "+" || formData.phoneNumber1 === String("+1")) {
        setPhoneValidation({ error: true, message: "Please enter your phone number" })
        return false;
      }
      else if (formData.phoneNumber1.replace(/[^0-9]/g, "").length < 11 || formData.phoneNumber1.replace(/[^0-9]/g, "").length > 12) {
        setPhoneValidation({ error: true, message: "Please enter a valid phone number" })
        return false;
      }
      else {
        setPhoneValidation({ error: false, message: "" })
        return true;
      }
    }
    else {
      setPhoneValidation({ error: true, message: "Please enter your phone number" })
      return false;
    }
  }

  const toSignInComponent = () => {
    return (
      <>
        <p className="mt-2 text-xs font-light text-center text-gray-700">
          {" "}Already have an account?{" "}
          <Link to="/login">
            <Typography variant="caption" sx={{ color: "#4e80ee", "&:hover": { textDecoration: "underline", cursor: "pointer" } }}>
              Sign in
            </Typography>
          </Link>
        </p>
      </>)
  }

  const onClickYes = () => {
    setShowConfirmationDialog(false);
    onClickRegister(currentUserData, "SMS_MFA");
  }

  const onClickNo = () => {
    setShowConfirmationDialog(false);
    onClickRegister(currentUserData, "NOMFA");
  }

  const onClickDifferentNum = () => {
    setFocusNum(true);
    setShowConfirmationDialog(false);
  }
  const onClickRegister = (values: any, mfaValue: any) => {
    setSaveLoader(true);
    var userType = userRole;
    if (userRole === "vendor") {
      userType = "contact"
    }
    axios.get(process.env.REACT_APP_BASE_URL + "exists/" + userType + "/" + values.email)
      .catch((error: any) => {
        setSaveLoader(false);
      })
      .then((response: any) => {
        if (response.data) {
          setAuthError({ isError: true, message: "An account with same email address is already registered" })
          setSaveLoader(false);
        }
        else {
          if (userRole === "connector") {
            signUp(values, 0, mfaValue)
          }
          else if (userRole === "vendor") {
            var requestBody = {
              vendor: {
                name: values.organisationName,
                website: values.website,
                addressVerified: false,
                email: values.email,
                phoneNumber1: formData.phoneNumber1.replace(/[^0-9+]/g, ""),
                phoneNumberVerified: false,
                emailVerified: false,
                productsServicesVerified: false,
                legalDocsVerified: false,
                w9_verified: false,
                isApproved: "pending",
                adminName: values.firstName + " " + values.lastName,
                vendorAdminName: values.firstName + " " + values.lastName,
                vendorAdminEmail: values.email
              },
              role: "vendor"
            };
            axios.post(process.env.REACT_APP_BASE_URL + "register", requestBody)
              .catch((error) => {
                setSaveLoader(false);
                handleSignUpError();
              })
              .then((response: any) => {


                signUp(values, response.data.id, mfaValue);
              })
          }
        }
      })
  }

  const getCharacterValidationError = (str: string) => {
    return `Your password must have at least 1 ${str} character`;
  };

  const [authError, setAuthError] = useState({
    isError: false,
    message: ""
  })

  const baseSchema = yup.object({
    firstName: yup
      .string()
      .min(4, "Should contain 4 characters at least")
      .max(45, 'Should contain at most 45 characters')
      .matches(/^[A-Za-z]+[A-Za-z-]*$/, 'Please enter a valid first name')
      .required('First name is required'),
    lastName: yup
      .string()
      .min(4, "Should contain 4 characters at least")
      .max(45, 'Should contain at most 45 characters')
      .matches(/^[A-Za-z-]*$/, 'Please enter a valid last name')
      .required('Last name is required'),
    username: yup
      .string()
      .min(4, "Should contain 4 characters at least")
      .max(45, 'Should contain at most 45 characters')
      .matches(/^\S*$/, 'Username should not contain spaces')
      .matches(/^[A-Za-z0-9-_.]*$/, 'Please enter a valid username')
      .required('Username is required'),
    email: yup
      .string()
      .email("Please enter a valid email")
      .max(45, 'Should contain at most 45 characters')
      // .matches(
      //   /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
      //   'Please enter a valid email'
      // )
      .matches(/^[\w\.+-]+@[\w\.-]+\.\w+$/, 'Please enter a valid email')
      .required("Email id is required"),
    password: yup
      .string()
      .required("Please enter a password")
      .matches(/[a-z]/, getCharacterValidationError("lowercase"))
      .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
      .matches(/[[_^@$!%*#?&]/, getCharacterValidationError("special character"))
      .matches(/[0-9]/, getCharacterValidationError("digit"))
      .min(8, "Password must have at least 8 characters")
      .matches(/^\S(.*\S)?$/, 'Password should not contain leading or trailing spaces'),
    confirmPassword: yup
      .string()
      .required("Please re-type your password")
      .oneOf([yup.ref("password")], "Passwords do not match"),

  });

  const vendorSchema = yup.object({
    organisationName: yup
      .string()
      .min(4, "Should contain 4 characters at least")
      .max(45, 'Should contain at most 45 characters')
      .trim()
      .matches(/^[A-Za-z0-9]+[A-Za-z0-9-' ]*$/, 'Please enter a valid name')
      .required('Organization name is required'),
    website: yup
      .string()
      .required('Website is required')
  });

  const validationSchema = () => {
    return userRole === 'vendor' ? baseSchema.concat(vendorSchema) : baseSchema;
  };

  const formik = useFormik({
    initialValues:
    {
      firstName: '',
      lastName: '',
      username: '',
      email: '',
      password: '',
      confirmPassword: '',
      organisationName: '',
      website: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const res = checkPhoneValidation();
      handleWebsiteBlur();
      if (res && isWebsiteValid) {
        setCurrentUserData(values);
        setShowConfirmationDialog(true);
      }
    }
  });

  async function signUp(values: any, orgId: any, mfaValue: any) {
    setSaveLoader(true);
    setAuthError({ isError: false, message: "" })
    var requestAttributes = {};
    if (mfaValue === "SMS_MFA") {
      requestAttributes = {
        email: values.email,
        phone_number: formData.phoneNumber1.replace(/[^0-9+]/g, ""),
        name: values.firstName + " " + values.lastName,
        'custom:role': userRole,
        'custom:organisationId': String(orgId)
      }
    }
    else {
      requestAttributes = {
        email: values.email,
        name: values.firstName + " " + values.lastName,
        'custom:role': userRole,
        'custom:organisationId': String(orgId),
        'custom:phoneNumber': formData.phoneNumber1.replace(/[^0-9]/g, "")
      }
    }
    try {
      const { user } = await Auth.signUp({
        username: values.username,
        password: values.password,
        attributes: requestAttributes,
        autoSignIn: {
          enabled: false,
        }
      });

      successToast("Verification Email sent successfully");
      setShowLoader(false);
      setSaveLoader(false);
      if (mfaValue === "SMS_MFA") {
        setShowOtpField(true);
        setUserRole("");
      }
      else {
        setShowSuccess(true);
        if (isPrefilled) {
          axios.post(process.env.REACT_APP_BASE_URL + "auth/verify-email", null, { params: { username: values.username } });
          axios.post(process.env.REACT_APP_BASE_URL + "auth/confirm-status", null, { params: { username: values.username } });
        }
        else {
          axios.post(process.env.REACT_APP_BASE_URL + "invitation/verify-email", null, { params: { email: values.email, username: values.username } })
        }
      }

    } catch (response: any) {
      if (response.message === "User already exists") {
        setAuthError({ isError: true, message: `${response.message}, please make sure your username and email should be unique.` });
      }
      else {
        setAuthError({ isError: true, message: response.message });
      }
      setShowForm(true);
      setShowLoader(false);
      failureToast();
      setSaveLoader(false);
    }
  }

  async function resendConfirmationCode() {
    try {
      await Auth.resendSignUp(currentUserData.username);
    } catch (err: any) {
      setAuthError({ isError: true, message: err.message })
    }
  }

  async function confirmSignUp(e: any) {
    e.preventDefault();
    setSaveLoader(true);
    try {
      await Auth.confirmSignUp(currentUserData.username, smsOtp);
      setSaveLoader(false);
      setShowSuccess(true);
      if (isPrefilled) {
        axios.post(process.env.REACT_APP_BASE_URL + "auth/verify-email", null, { params: { username: currentUserData.username } });
        axios.post(process.env.REACT_APP_BASE_URL + "auth/confirm-status", null, { params: { username: currentUserData.username } });
      }
      else {
        axios.post(process.env.REACT_APP_BASE_URL + "invitation/verify-email", null, { params: { email: currentUserData.email, username: currentUserData.username } })
      }
    } catch (err: any) {
      setSaveLoader(false);
      setAuthError({ isError: true, message: err.message });
    }
  }

  const onClickRegisterButton = (e: any) => {
    e.preventDefault();
    formik.handleSubmit();
    checkPhoneValidation();
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  const handleMouseDownPassword = () => {
    setShowPassword(false);
    setShowConfirmPassword(false);
  }
  const handleWebsiteBlur = async () => {
    const websiteUrl = formik.values.website;

    if (websiteUrl.toLowerCase().startsWith('https://')) {
      formik.values.website = websiteUrl.substring('https://'.length);
      return;
    }

    const domain: string | null | undefined = extractDomain(formik.values.website);

    try {
      if (domain && domain.length! > 0) {
        setLoading(true);

        const response = await axios.get(`https://api.api-ninjas.com/v1/dnslookup?domain=${domain}`, {
          headers: {
            'X-Api-Key': 'wnecOqvlRCx2vpBCz7UvoA==hRoYqCZZCLiYBnd5',
          },
        });

        if (response.data && response.data.length > 0) {
          setIsWebsiteValid(true)
          setWebsiteError(null)
          formik.setFieldError('website', '');
        } else {
          setIsWebsiteValid(false)
          setWebsiteError('Invalid website or domain name');
          formik.setFieldError('website', 'Invalid website or domain name');
          console.log("Formik", formik)
        }
      } else {
        setIsWebsiteValid(false);
        setWebsiteError('Invalid website or domain name');
        formik.setFieldError('website', 'Invalid website or domain name');
        console.log("Formik", formik)
      }
    } catch (error) {
      setWebsiteError('Error making API call');
      formik.setFieldError('website', 'Invalid website or domain name');
    } finally {
      setLoading(false);
    }
  };

  function extractDomain(link: string): string | null {
    const match = link.match(/^(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9-]+(?:\.[a-zA-Z]+)+)(?:\/.*)?$/);
    return match ? match[1] : null;
  }
  return (
    <>
      <MfaConfirmationDialog showConfirmationDialog={showConfirmationDialog} onClickYes={onClickYes} onClickNo={onClickNo} phoneNumber={formData.phoneNumber1} onClickDifferentNum={onClickDifferentNum} />
      <ErrorDialog showDialog={showSignUpError} handleClose={exitSignUpError} type={"organisation"} />
      {!error ?
        <>
          {authorised ?
            <>
              {pageLoader ?
                <>
                  <Grid container sx={{ display: "flex", alignItems: "center", justifyContent: "center", minHeight: "100vh" }}>
                    <Grid item xs={12} sx={{ textAlign: "center", marginY: 5 }}>
                      <img src="assets/loader/Spinner-px.gif" height={"140vh"} />
                      <Typography sx={{ paddingTop: 2 }}>
                        Please wait while we verify your credentials
                      </Typography>
                    </Grid>
                  </Grid>
                </>
                :
                <>
                  <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                  />
                  <Navbar destinationPage={"registration"} />
                  <Container maxWidth="xl" sx={{ justifyContent: "center", minHeight: "100vh", display: "flex", alignItems: "center", paddingTop: { xs: '20vh', sm: '20vh', md: '20vh', lg: '10vh' } }}>
                    <Box sx={{ width: "75%", alignSelf: "center", margin: "auto", boxShadow: showSuccess ? 0 : 3, borderRadius: 2, padding: '0' }}>
                      {showSuccess ? (
                        <>
                          <Box sx={{ textAlign: "center" }}>
                            <Typography sx={{ color: "green", paddingY: 10 }}>
                              Registration Successful !! <br />
                              {isPrefilled ?
                                <>
                                  Click <Link to="/login"><u>here</u></Link> to login into the application
                                </>
                                :
                                <>
                                  Please check your inbox for the link to verify your registration<br />
                                  Once verified, you'll be able to log in to our application
                                </>}
                            </Typography>
                          </Box>
                        </>
                      ) : (
                        <>
                          {showLoader ? (
                            <>
                              <Grid container>
                                <Grid item xs={12} sx={{ textAlign: "center", marginY: 5 }}>
                                  <img src="assets/loader/Spinner-px.gif" height={"140vh"} />
                                  <Typography sx={{ paddingTop: 2 }}>
                                    Please wait while we register you as a user
                                  </Typography>
                                </Grid>
                              </Grid>
                            </>
                          ) : (
                            <>
                              <Grid container spacing={1}
                              >
                                <Grid item
                                  xs={12}
                                  sm={12}
                                  md={6}
                                  lg={6}
                                  xl={6}
                                  sx={{
                                    backgroundColor: "#144fe9", paddingY: {
                                      xs: 0,
                                      md: 15,
                                    }, borderRadius: 2, marginTop: showForm ? { xs: 0, md: 0 } : "", display: showForm ? userRole === "vendor" ? "block" : { xs: "none", sm: "none", md: "grid", lg: "grid", xl: "grid" } : { xs: "none", sm: "none", md: "grid", lg: "grid", xl: "grid" }
                                  }}
                                  className="py-32 rounded-md"
                                >
                                  {userRole === "vendor" ?
                                    <>
                                      <Grid container sx={{ padding: 2 }}>
                                        <Grid item xs={12} sx={{ paddingTop: 2, paddingBottom: 1 }}>
                                          <Typography sx={{ fontWeight: "bold", color: "white" }}>Organizational Information</Typography>
                                        </Grid>
                                        <Grid container columnSpacing={2} rowSpacing={2}>
                                          <Grid item xs={12} sx={gridStyles}>
                                            <Typography sx={{ color: "white" }}>Name<span style={{ color: '#e9143b' }}> *</span></Typography>
                                            <TextField
                                              type="text"
                                              name="organisationName"
                                              id="organisationName"
                                              placeholder="Organization Name"
                                              onBlur={formik.handleBlur}
                                              onChange={formik.handleChange}
                                              value={formik.values.organisationName}
                                              error={formik.touched.organisationName && Boolean(formik.errors.organisationName)}
                                              helperText={formik.touched.organisationName && formik.errors.organisationName}
                                              fullWidth
                                              variant="outlined"
                                              size="small"
                                              sx={{
                                                backgroundColor: 'white',
                                                borderRadius: 1
                                              }}
                                              InputLabelProps={{
                                                sx: {
                                                  color: '#003566',
                                                  textTransform: 'capitalize',
                                                },
                                              }}
                                              InputProps={{
                                                endAdornment: (
                                                  <InputAdornment position="end">
                                                    {(formik.values.organisationName !== "" && !formik.touched.organisationName) &&
                                                      <>{Boolean(formik.errors.organisationName) ? <CloseIcon color="error" /> : <CheckIcon color="success" />}</>}
                                                    {formik.touched.organisationName && <>{Boolean(formik.errors.organisationName) ? <CloseIcon color="error" /> : <CheckIcon color="success" />}</>}
                                                  </InputAdornment>
                                                ),
                                              }}
                                            />
                                          </Grid>
                                          <Grid item xs={12} sx={gridStyles}>
                                            <Typography sx={{ color: "white" }}>Website<span style={{ color: '#e9143b' }}> *</span></Typography>
                                            <TextField
                                              type="text"
                                              name="website"
                                              id="website"
                                              placeholder="Organization Website"
                                              onBlur={(e) => {
                                                formik.handleBlur(e);
                                                handleWebsiteBlur();
                                              }}
                                              onChange={formik.handleChange}
                                              value={formik.values.website}
                                              error={
                                                formik.touched.website &&
                                                (Boolean(formik.errors.website) || (loading && formik.errors.website !== '')) ||
                                                Boolean(websiteError)
                                              }
                                              helperText={
                                                (formik.touched.website && formik.errors.website) ||
                                                (loading && 'Checking...') ||
                                                websiteError
                                              }
                                              fullWidth
                                              variant="outlined"
                                              size="small"
                                              sx={{
                                                backgroundColor: 'white',
                                                borderRadius: 1
                                              }}
                                              InputLabelProps={{
                                                sx: {
                                                  color: '#003566',
                                                  textTransform: 'capitalize',
                                                },

                                              }}
                                              InputProps={{
                                                startAdornment: <span >https://</span>,
                                                endAdornment: (
                                                  <InputAdornment position="end">
                                                    {formik.touched.website && (
                                                      <>
                                                        {websiteError ? (
                                                          <CloseIcon color="error" />
                                                        ) : (
                                                          <CheckIcon color="success" />
                                                        )}
                                                      </>
                                                    )}
                                                  </InputAdornment>
                                                ),
                                              }}
                                            />
                                          </Grid>
                                        </Grid>

                                      </Grid>
                                    </>
                                    :
                                    <>
                                      <Container sx={{ minHeight: "100%", paddingTop: 8, textAlign: "center" }}>
                                        <img
                                          src="assets/logos/ConnectorNet-Logo-Vert-White.png"
                                          width={"60%"}
                                          alt="connectornet logo"
                                        />
                                      </Container>
                                    </>}
                                </Grid>
                                <Grid item xs={12}
                                  sm={12}
                                  md={6}
                                  lg={6}
                                  xl={6} sx={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "white", borderRadius: 2 }} >
                                  {showForm ? (
                                    <>
                                      {showOtpField ?
                                        <Grid item xs={12}>
                                          <form onSubmit={(e) => confirmSignUp(e)}>
                                            <Grid container spacing={2} sx={{ padding: 2 }}>
                                              {authError.isError &&
                                                <Grid item xs={12} sx={{ textAlign: "center", marginBottom: 1, marginX: 3 }}>
                                                  <Card sx={{ backgroundColor: "#F0F0F0", padding: 1 }}>
                                                    <Typography sx={{ fontWeight: "light", color: "red", fontSize: "16px" }}>{authError.message}</Typography>
                                                  </Card>
                                                </Grid>
                                              }
                                              <Grid item xs={12} sx={{ marginX: 3, marginBottom: 1 }}>
                                                <Typography variant="caption" sx={{ fontSize: "16px", color: "#1976d2" }}>
                                                  Please check your messages for an OTP
                                                </Typography>
                                              </Grid>

                                              <Grid item xs={12} sx={{ marginX: 3 }}>
                                                <TextField
                                                  type="mfaValue"
                                                  name="mfaValue"
                                                  id="mfaValue"
                                                  placeholder="Enter your OTP here"
                                                  value={formData.mfaValue}
                                                  onChange={(e: any) => setSmsOtp(e.target.value)}
                                                  fullWidth
                                                  variant="outlined"
                                                  size="small"
                                                  autoFocus
                                                />
                                              </Grid>
                                              <Grid item xs={12} sx={{ marginX: 3 }}>
                                                <p className="mt-2 text-xs font-light text-center text-gray-700">
                                                  {" "}Didn't receive a verification code ?{" "}
                                                  <Typography onClick={resendConfirmationCode} variant="caption" sx={{ color: "#4e80ee", "&:hover": { textDecoration: "underline", cursor: "pointer" } }}>
                                                    Re-send
                                                  </Typography>
                                                </p>
                                              </Grid>
                                              <Grid item xs={12} sx={{ marginX: 3 }}>
                                                <Box sx={{ textAlign: "center" }}>
                                                  <Button variant="contained"
                                                    sx={{ minWidth: "33%", backgroundColor: "#1976d2" }}
                                                    onClick={(e) => confirmSignUp(e)}
                                                    disabled={smsOtp.length < 6}
                                                  >
                                                    {saveLoader ? <><CircularProgress size="1.6rem" sx={{ color: "white" }} /></> : <>Submit</>}
                                                  </Button>
                                                </Box>
                                              </Grid>
                                            </Grid>
                                          </form>
                                        </Grid>
                                        :
                                        <>
                                          <form onSubmit={(e) => onClickRegisterButton(e)}>
                                            <Grid container spacing={2} sx={{ padding: 2 }}>
                                              <Grid item xs={12} sx={{ paddingTop: 1, paddingBottom: authError.isError ? 0 : 1 }}>
                                                <Typography sx={{ fontWeight: "bold", color: "#1976d2" }}>General Information</Typography>
                                              </Grid>
                                              {authError.isError &&
                                                <Grid item xs={12} sx={{ textAlign: "center", marginBottom: 1, marginX: 3 }}>
                                                  <Card sx={{ backgroundColor: "#F0F0F0", padding: 1 }}>
                                                    <Typography sx={{ fontWeight: "light", color: "red", fontSize: "16px" }}>{authError.message}</Typography>
                                                  </Card>
                                                </Grid>
                                              }
                                              {isPrefilled ?
                                                <Grid item xs={12}>
                                                  <Typography>Mail*</Typography>
                                                  <Card sx={{ padding: 1, boxShadow: 0, backgroundColor: "#F0F0F0" }}>
                                                    <Typography variant="subtitle2">
                                                      {userData ?
                                                        <>{userData.email}</> : <>No mail id found</>}
                                                    </Typography></Card>
                                                </Grid>
                                                :
                                                <>
                                                  <Grid item xs={12} sx={gridStyles}>
                                                    <Typography>Mail<span style={{ color: '#00BFFF' }}> *</span></Typography>
                                                    <TextField
                                                      type="email"
                                                      name="email"
                                                      id="email"
                                                      placeholder="Enter your Mail id"
                                                      onBlur={formik.handleBlur}
                                                      onChange={formik.handleChange}
                                                      error={formik.touched.email && Boolean(formik.errors.email)}
                                                      helperText={formik.touched.email && formik.errors.email}
                                                      fullWidth
                                                      variant="outlined"
                                                      size="small"
                                                      InputProps={{
                                                        endAdornment: (
                                                          <InputAdornment position="end">
                                                            {(formik.values.email !== "" && !formik.touched.email) &&
                                                              <>{Boolean(formik.errors.email) ? <CloseIcon color="error" /> : <CheckIcon color="success" />}</>}
                                                            {formik.touched.email && <>{Boolean(formik.errors.email) ? <CloseIcon color="error" /> : <CheckIcon color="success" />}</>}
                                                          </InputAdornment>
                                                        ),
                                                      }}
                                                    />
                                                  </Grid>
                                                </>}
                                              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={gridStyles}>
                                                <Typography>First Name<span style={{ color: '#00BFFF' }}> *</span></Typography>
                                                <TextField
                                                  type="text"
                                                  name="firstName"
                                                  id="firstName"
                                                  placeholder="Enter your first name"
                                                  onBlur={formik.handleBlur}
                                                  onChange={formik.handleChange}
                                                  value={formik.values.firstName}
                                                  error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                                  helperText={formik.touched.firstName && formik.errors.firstName}
                                                  fullWidth
                                                  variant="outlined"
                                                  size="small"
                                                  InputProps={{
                                                    endAdornment: (
                                                      <InputAdornment position="end">
                                                        {(formik.values.firstName !== "" && !formik.touched.firstName) &&
                                                          <>{Boolean(formik.errors.firstName) ? <CloseIcon color="error" /> : <CheckIcon color="success" />}</>}
                                                        {formik.touched.firstName && <>{Boolean(formik.errors.firstName) ? <CloseIcon color="error" /> : <CheckIcon color="success" />}</>}
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                />
                                              </Grid>
                                              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={gridStyles}>
                                                <Typography>Last Name<span style={{ color: '#00BFFF' }}> *</span></Typography>
                                                <TextField
                                                  type="text"
                                                  name="lastName"
                                                  id="lastName"
                                                  placeholder="Enter your last name"
                                                  onBlur={formik.handleBlur}
                                                  onChange={formik.handleChange}
                                                  value={formik.values.lastName}
                                                  error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                                  helperText={formik.touched.lastName && formik.errors.lastName}
                                                  fullWidth
                                                  variant="outlined"
                                                  size="small"
                                                  InputProps={{
                                                    endAdornment: (
                                                      <InputAdornment position="end">
                                                        {(formik.values.lastName !== "" && !formik.touched.lastName) &&
                                                          <>{Boolean(formik.errors.lastName) ? <CloseIcon color="error" /> : <CheckIcon color="success" />}</>}
                                                        {formik.touched.lastName && <>{Boolean(formik.errors.lastName) ? <CloseIcon color="error" /> : <CheckIcon color="success" />}</>}
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                />
                                              </Grid>
                                              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={gridStyles}>
                                                <Typography>Username<span style={{ color: '#00BFFF' }}> *</span></Typography>
                                                <TextField
                                                  type="text"
                                                  name="username"
                                                  id="username"
                                                  placeholder="Enter your username name"
                                                  onBlur={formik.handleBlur}
                                                  onChange={formik.handleChange}
                                                  error={formik.touched.username && Boolean(formik.errors.username)}
                                                  helperText={formik.touched.username && formik.errors.username}
                                                  fullWidth
                                                  variant="outlined"
                                                  size="small"
                                                  InputProps={{
                                                    endAdornment: (
                                                      <InputAdornment position="end">
                                                        {(formik.values.username !== "" && !formik.touched.username) &&
                                                          <>{Boolean(formik.errors.username) ? <CloseIcon color="error" /> : <CheckIcon color="success" />}</>}
                                                        {formik.touched.username && <>{Boolean(formik.errors.username) ? <CloseIcon color="error" /> : <CheckIcon color="success" />}</>}
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                />
                                              </Grid>
                                              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={gridStyles}>
                                                <Typography>Phone Number<span style={{ color: '#00BFFF' }}> *</span></Typography>
                                                <MuiPhoneNumber
                                                  defaultCountry={"us"}
                                                  onChange={(e: any) => handlePhoneChange(e)}
                                                  value={formData.phoneNumber1}
                                                  variant="outlined"
                                                  fullWidth
                                                  placeholder="Contact Number"
                                                  size="small"
                                                  onBlur={checkPhoneValidation}
                                                  error={phoneValidation.error}
                                                  helperText={phoneValidation.message}
                                                  InputProps={{
                                                    endAdornment: (
                                                      <InputAdornment position="end">
                                                        {(formData.phoneNumber1 !== "" || phoneValidation.error === true) && <>
                                                          {formData.phoneNumber1.replace(/[^0-9]/g, "").length < 11 ? <CloseIcon color="error" />
                                                            : <>{phoneValidation.error ? <CloseIcon color="error" /> : <CheckIcon color="success" />}</>}
                                                        </>}
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                />
                                              </Grid>
                                              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={gridStyles}>
                                                <Typography>Password<span style={{ color: '#00BFFF' }}> *</span></Typography>
                                                <TextField
                                                  type={showPassword ? "text" : "password"}
                                                  name="password"
                                                  id="password"
                                                  placeholder="Enter your Password"
                                                  onBlur={formik.handleBlur}
                                                  onChange={formik.handleChange}
                                                  error={formik.touched.password && Boolean(formik.errors.password)}
                                                  helperText={formik.errors.password}
                                                  fullWidth
                                                  variant="outlined"
                                                  size="small"
                                                  InputProps={{
                                                    endAdornment: (
                                                      <InputAdornment position="end">
                                                        {(formik.values.password !== "" && !formik.touched.password) &&
                                                          <>{Boolean(formik.errors.password) ? <CloseIcon color="error" /> : <CheckIcon color="success" />}</>}
                                                        {formik.touched.password && <>{Boolean(formik.errors.password) ? <CloseIcon color="error" /> : <CheckIcon color="success" />}</>}

                                                        <IconButton
                                                          aria-label="toggle password visibility"
                                                          onClick={handleClickShowPassword}
                                                          onMouseDown={handleMouseDownPassword}
                                                          onBlur={handleMouseDownPassword}
                                                        >
                                                          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                        </IconButton>
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                />
                                              </Grid>
                                              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={gridStyles}>
                                                <Typography>Confirm Password<span style={{ color: '#00BFFF' }}> *</span></Typography>
                                                <TextField
                                                  type={showConfirmPassword ? "text" : "password"}
                                                  name="confirmPassword"
                                                  id="confirmPassword"
                                                  placeholder="Re-type your Password"
                                                  onBlur={formik.handleBlur}
                                                  onChange={formik.handleChange}
                                                  error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                                                  helperText={formik.errors.confirmPassword}
                                                  fullWidth
                                                  variant="outlined"
                                                  size="small"
                                                  InputProps={{
                                                    endAdornment: (
                                                      <InputAdornment position="end">
                                                        {(formik.values.confirmPassword !== "" && !formik.touched.confirmPassword) &&
                                                          <>{Boolean(formik.errors.confirmPassword) ? <CloseIcon color="error" /> : <CheckIcon color="success" />}</>}
                                                        {formik.touched.confirmPassword && <>{Boolean(formik.errors.confirmPassword) ? <CloseIcon color="error" /> : <CheckIcon color="success" />}</>}
                                                        <IconButton
                                                          aria-label="toggle password visibility"
                                                          onClick={handleClickShowConfirmPassword}
                                                          onMouseDown={handleMouseDownPassword}
                                                          onBlur={handleMouseDownPassword}
                                                        >
                                                          {showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                        </IconButton>
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                />
                                              </Grid>
                                              {showOrganisationFields ? <Grid item xs={12} sx={{ marginTop: 2 }} /> : <></>}
                                              <Grid item xs={12} sx={{ paddingBottom: 5 }}>
                                                <Box className="mt-6">
                                                  <div className="mt-6 text-center">
                                                    <Button
                                                      variant="contained"
                                                      sx={{ minWidth: "33%", backgroundColor: "#1976d2", marginTop: "1.5rem" }}
                                                      // onClick={ (e) => onClickRegisterButton (e)}
                                                      type="submit"
                                                    >
                                                      {saveLoader ? <><CircularProgress size="1.6rem" sx={{ color: "white" }} /></> : <>Register</>}
                                                    </Button>
                                                  </div>
                                                  {toSignInComponent()}
                                                </Box>
                                              </Grid>
                                            </Grid>
                                          </form>
                                        </>}
                                    </>
                                  ) : (
                                    <>
                                      <Grid container sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <Box sx={{ textAlign: "center" }} >
                                          <img
                                            src="assets/logos/ConnectorNetDiagramDark.png"
                                            width={"50%"}
                                            alt="ConnectorNet logo diagram"
                                          />
                                        </Box>
                                        <>
                                          <Grid
                                            item
                                            xs={12}
                                            sx={{ textAlign: "center", marginTop: 3, marginBottom: 1.5 }}
                                          >
                                            <Typography>
                                              What kind of account would you like to register ?
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={12} sx={{ textAlign: "center" }}>
                                            <Box>
                                              {/* <Button onClick={onSelectVendor}>Vendor</Button> */}
                                              <Button variant="outlined" onClick={onSelectConnector} color="success" sx={{ color: "green", marginX: 1, width: 120 }}>Connector</Button>
                                              <Button variant="outlined" onClick={onSelectVendor} color="success" sx={{ color: "green", marginX: 1, width: 120 }}>Vendor</Button>

                                              {/* <Link to="/login"><Button sx={{color:"red"}}>No</Button></Link> */}
                                            </Box>
                                            {toSignInComponent()}
                                          </Grid>
                                        </>
                                      </Grid>
                                    </>
                                  )}
                                </Grid>
                              </Grid>
                            </>
                          )}
                        </>
                      )}
                    </Box>
                  </Container>
                </>}
            </>
            :
            <>
              <Grid container sx={{ display: "flex", alignItems: "center", justifyContent: "center", minHeight: "100vh" }}>
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  <img src="assets/images/unauthorized.png" height={"350vh"} />
                  <Typography>
                    You are not authorised to view this page
                  </Typography>
                </Grid>
              </Grid>
            </>}
        </>
        :
        <>
          <Grid item xs={12} sx={{ marginTop: "10%", textAlign: "center" }}>
            <ErrorComponent />
          </Grid>
        </>}
    </>
  );
}

export default SignUp;
