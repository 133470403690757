import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';

const InvoiceUploadError = (props:any) => {
    const{showDialog, handleClose, type} = props;
  return (
    <Dialog open={showDialog} sx={{width:"100%", justifyContent:"center", height:"100%", backgroundColor:"transparent"}}>
        <DialogActions>
            <Grid container>
                <Grid xs={12} sx={{textAlign:"right"}}>
                    <Button onClick={handleClose}><CloseIcon sx={{color:"red"}}/></Button>
                </Grid>
            </Grid>
        </DialogActions>
        <DialogContent>
            <Grid container>
                <Grid item xs={12} sx={{textAlign:"center", paddingX:6, marginBottom:6, backgroundColor:"transparent"}}>
                    <img src="assets/loader/warning-sign.png" height={"140vh"}/>
                    {type === "invoice" ?<Typography sx={{paddingTop:2}}>Some error occurred while uploading new invoice,<br/>please check if the file size is greater than 5MB.</Typography>:<></>}
                    {type === "transaction" ?<Typography sx={{paddingTop:2}}>Please upload a invoice document to associate with the new transaction.</Typography>:<></>}
                    {type === "others"?<Typography sx={{paddingTop:2}}>Please upload a invoice document <br/>to associate with the new invoice.</Typography>:<></>}
                    {type === "edit"?<Typography sx={{paddingTop:2}}>Some error occured while modifying the invoice<br/>Please try again later</Typography>:<></>}
                </Grid>
            </Grid>
        </DialogContent>
    </Dialog>
  )
}

export default InvoiceUploadError;