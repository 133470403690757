import { Box, Button, Card, CardActions, CardContent, CardMedia, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import ErrorComponent from '../../Layout/ErrorComponent';
import { PDFObject } from 'react-pdfobject';
import { convertDate, firstCapital } from '../Approvals/util';
import TableHeadRow from '../../Layout/TableHeadRow';
import { linkHover, statusColor, statusColorText } from '../../tableStyle';
import WestIcon from '@mui/icons-material/West';
import StatusCell from '../../Layout/StatusCell';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import axios from 'axios';
import RejectionForm from './RejectionForm';
import { ToastContainer, toast } from "react-toastify";
import LegalDocsCardTable from '../../legalDocs/LegalDocsTableCard';
import { useAuth0 } from '@auth0/auth0-react';

const ShowSignedDoc = (props: any) => {
  const { user } = props;
  const { signedDocDialog, handleCloseSignedDialog, error, empty, viewDocData, setViewDocData, showPdf, setShowPdf, setSignedDocDialog } = props;
  const [currentDoc, setCurrentDoc] = useState<any>();
  const [statusLoader, setStatusLoader] = useState(0);
  const [showRejectionForm, setShowRejectionForm] = useState(false);
  const [formData, setFormData] = useState<any>();
  const [saveLoader, setSaveLoader] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const successToast = () => toast.success("Mail sent successfully");
  const failureToast = () => toast.error("There was some error in sending the notification, please try again.");
  const showCurrentDoc = (record: any) => {
    setCurrentDoc(record);
    setShowPdf(true);
  }
  const onBack = () => {
    setShowPdf(false);
  }
  const [openElem, setOpenElem] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleMenuClick = (elem: any) => (event: any) => {
    setAnchorEl(event.currentTarget);
    setOpenElem(elem);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpenElem(null);
  };
  const onClickReject = (record: any) => {
    setFormData(record);
    setShowRejectionForm(true);
    setSignedDocDialog(false);
  }
  const onCancelReject = () => {
    setShowRejectionForm(false);
    setSignedDocDialog(true);
    setStatusLoader(0);
  }
  const handleRejection = () => {
    setStatusLoader(formData.id);

    setShowRejectionForm(false);
    setSignedDocDialog(true);

    axios.put(process.env.REACT_APP_BASE_URL + 'document/' + formData.id, formData,
      {
        headers: {
          "Authorization": "Bearer " + user.accessToken,
        }
      })
      .catch((error: any) => {
        setStatusLoader(0)
        handleMenuClose()
        failureToast()
      })
      .then((response: any) => {
        setSaveLoader(false);
        setStatusLoader(0)
        var tempData = [...viewDocData]
        const ind = tempData.findIndex(obj => obj.id === response.data.id);
        tempData[ind] = response.data;
        setViewDocData(tempData);
        successToast();
      })
  }

  const onStatusChange = (row: any, action: string) => {
    setStatusLoader(row.id)

    var temp = {
      "id": row.id,
      "name": row.name,
      "isApproved": action
    };

    axios.put(process.env.REACT_APP_BASE_URL + 'document/' + temp.id, temp,
      {
        headers: {
          "Authorization": "Bearer " + user.accessToken,
        }
      })
      .catch((error: any) => {
        setStatusLoader(0);
        failureToast();
        handleMenuClose();
      })
      .then((response: any) => {
        setStatusLoader(0);
        successToast();
        var tempData = [...viewDocData]
        const ind = tempData.findIndex(obj => obj.id === response.data.id);
        tempData[ind] = response.data;
        setViewDocData(tempData);
      })
  }
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const slicedData = viewDocData ? viewDocData.slice(startIndex, endIndex) : [];

  return (
    <>
      <RejectionForm showDialog={showRejectionForm} handleClose={onCancelReject} type={"Legal Document"} formData={formData} setFormData={setFormData} handleSubmit={handleRejection} rejectionType={"rejected"} saveLoader={saveLoader} />
      <Dialog open={signedDocDialog}
        sx={{
          '.MuiDialog-paper': {
            position: 'absolute',
            width: { xs: "90%", sm: "85%", md: "65%", lg: "65%", xl: "65%" },
            height: '700px',
            minWidth: { xs: "90%", sm: "85%", md: "65%", lg: "65%", xl: "65%" }
          },
        }}>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <DialogTitle sx={{ textAlign: "center", fontWeight: 'bold', backgroundColor: "#F0F7FF", fontSize: { xs: "large", sm: "large", md: "34px", lg: "34px", xl: "34px" } }}> Signed Documents
          <IconButton onClick={handleCloseSignedDialog} sx={{ float: "right", color: "red" }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ overflow: "hidden" }}>
          <Grid container>
            <Grid container sx={{ marginTop: { sx: 0, sm: 0, md: 3, lg: 3, xl: 3 }, marginLeft: { sx: 0, sm: 0, md: 3, lg: 3, xl: 3 } }}>
              {error ?
                <>
                  <Grid item xs={12} sx={{ textAlign: "center" }}>
                    <ErrorComponent />
                  </Grid>
                </>
                :
                <>
                  {empty ?
                    <>
                      <Grid item xs={12} sx={{ textAlign: "center" }}>
                        <img src="assets/loader/not_found.gif" height={'140vh'} />
                        <Typography sx={{ fontWeight: 'bold' }}>
                          No Legal documents found!
                        </Typography>
                        <Typography>
                          Unable to find any legal documents associated with your profile
                        </Typography>
                      </Grid>
                    </>
                    :
                    <>
                      {showPdf ?
                        <>
                          <Grid container>

                            <Grid item xs={12}>

                              <PDFObject width="100%" height="480px" url={`data:application/pdf;base64,${currentDoc.content}`} />

                            </Grid>
                          </Grid>
                        </> : <>
                          {viewDocData ?
                            <Grid container>
                              <TableContainer component={Paper} sx={{ marginBottom: 2, display: { xs: "none", sm: "none", md: "block", lg: "block", xl: "block" } }}>
                                <Table aria-label="simple table" size="small">
                                  <TableHead>
                                    <TableRow>
                                      <TableHeadRow arr={[
                                        {
                                          name: 'BROCHURE NAME',
                                          alignment: 'left',
                                          valueToOrderBy: false
                                        },
                                        {
                                          name: 'UPLOAD DATE',
                                          alignment: 'right',
                                          valueToOrderBy: false
                                        },
                                        {
                                          name: 'STATUS',
                                          alignment: 'center',
                                          valueToOrderBy: false,
                                        },
                                        {
                                          name: 'ACTION',
                                          alignment: 'center',
                                          valueToOrderBy: false,
                                        }
                                      ]} />
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {slicedData.map((record: any) => {
                                      return (
                                        <>
                                          <TableRow key={record.id}>
                                            <TableCell align="left" sx={{ height: 73, width: "150px" }}>
                                              <Tooltip
                                                title={record.name}
                                                arrow
                                                placement="top"
                                              >
                                                <Typography
                                                  sx={linkHover}
                                                  onClick={(e) => { showCurrentDoc(record) }}
                                                >
                                                  {record.name}
                                                </Typography>
                                              </Tooltip>
                                            </TableCell>
                                            <TableCell align="right" sx={{ height: 60, paddingRight: 5.2 }}>
                                              <Typography>
                                                {convertDate(record.createdDatetime)}
                                              </Typography>
                                            </TableCell>
                                            <StatusCell textClr={statusColorText[record.isApproved]} bgClr={statusColor[record.isApproved]} text={firstCapital(record.isApproved)} />
                                            <TableCell>
                                              {statusLoader === record.id ?
                                                <Box display={"flex"} justifyContent={"center"}><CircularProgress size="1.6rem" /></Box> :
                                                <Box display={"flex"} justifyContent={"center"}>
                                                  <Button
                                                    color="primary"
                                                    variant="outlined"
                                                    endIcon={<ArrowDropDownIcon />}
                                                    aria-label="more"
                                                    aria-controls={"long-menu" + record}
                                                    aria-haspopup="true"
                                                    sx={{
                                                      width: 100,
                                                      margin: "auto"
                                                    }}
                                                    onClick={handleMenuClick(record)}
                                                  >
                                                    <Typography sx={{ fontSize: "15px" }}>
                                                      SELECT
                                                    </Typography>
                                                  </Button>
                                                  <Menu
                                                    id={"long-menu" + record}
                                                    anchorEl={anchorEl}
                                                    keepMounted
                                                    open={openElem === record}
                                                    onClose={handleMenuClose}
                                                    anchorOrigin={{
                                                      vertical: 'bottom',
                                                      horizontal: 'left',
                                                    }}
                                                    transformOrigin={{
                                                      vertical: 'top',
                                                      horizontal: 'left',
                                                    }}
                                                  >
                                                    <MenuItem disabled={record.isApproved === "approved"} onClick={() => onStatusChange(record, "approved")}>
                                                      <Typography sx={{ fontSize: "15px", cursor: "pointer", color: "#00BFFF", '&:hover': { color: "#0000ff" } }} >Approve</Typography>
                                                    </MenuItem>
                                                    <MenuItem disabled={record.isApproved === "rejected"} onClick={() => onClickReject(record)}>
                                                      <Typography sx={{ fontSize: "15px", cursor: "pointer", color: "#00BFFF", '&:hover': { color: "#0000ff" } }}>Reject</Typography>
                                                    </MenuItem>

                                                  </Menu>
                                                </Box>}
                                            </TableCell>
                                          </TableRow>
                                        </>
                                      )
                                    })}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                              <Grid item xs={12} sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none", xl: "none" } }}>
                                <LegalDocsCardTable rowData={viewDocData} showCurrentDoc={showCurrentDoc} onApprove={onStatusChange} onClickReject={onClickReject} userType={user?.role} statusLoader={statusLoader} />
                              </Grid>
                              <Grid item xs={12} sx={{ marginBottom: 8 }}>
                                {viewDocData && (
                                  <TablePagination
                                    rowsPerPageOptions={[5, 10, 15, 20]}
                                    sx={{
                                      '.MuiTablePagination-toolbar': {
                                        alignItems: 'baseline'
                                      },
                                    }}
                                    component="div"
                                    count={viewDocData.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                  />
                                )}
                              </Grid>
                            </Grid>
                            :
                            <>
                              <Grid item xs={12} sx={{ textAlign: "center", margin: "auto" }}>
                                <img src="assets/loader/Spinner-px.gif" height={"140vh"} />
                                <Typography sx={{ paddingTop: 2 }}>Please wait while we fetch signed legal documents</Typography>
                              </Grid>
                            </>}</>}

                    </>}
                </>}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container>
            <Grid item xs={6} sx={{ textAlign: "left" }}>
              {showPdf ? <Button variant="outlined" sx={{ marginLeft: 3, width: 100, padding: 1 }} onClick={(e) => { onBack() }}><WestIcon />&nbsp;Back</Button> : <></>}
            </Grid>

          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ShowSignedDoc