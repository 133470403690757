import { Dialog, DialogContent, Grid, Typography, Box, CircularProgress } from '@mui/material'
import React from 'react'
import { Button } from 'react-admin'

const DialogComponent = (props:any) => {
    const {open, handleClose, inProgress, customTitle, customCancelText, customConfirmText, id, handleConfirm, cancelColor, confirmColor} = props;
  return (
    <Dialog open={open}

                onClose={handleClose} maxWidth="md">
                <DialogContent>
                    <Grid container spacing={1} justifyContent={"center"} alignItems={"center"} sx={{ paddingY: 5 }}>
                        <Grid item xs={12} sx={{ textAlign: "center" }}>
                            <Typography sx={{ fontSize: 20 }}> {customTitle}  </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: "center" }}>
                            <Box>
                                <Button
                                    onClick={handleClose}
                                    variant="contained"
                                    color={cancelColor}
                                    disabled={inProgress}
                                    sx={{ width: 100, height: 45, padding: 1, marginX: 2 }}
                                >
                                    {customCancelText}
                                </Button>
                                <Button
                                    onClick={(e) => {
                                        handleConfirm(id)
                                    }}
                                    variant="contained"
                                    color={confirmColor}
                                    sx={{ width: 100, height: 45, padding: 1, marginX: 2 }}
                                >
                                    {inProgress ? <CircularProgress sx={{ color: "white" }} />
                                        : customConfirmText}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
  )
}

export default DialogComponent