import {Auth0Client} from '@auth0/auth0-spa-js';
import { AuthProvider } from 'react-admin';
import { Auth } from "aws-amplify";
import { useEffect, useState } from 'react';

const auth0 = new Auth0Client({
    domain: process.env.REACT_APP_DOMAIN!,
    clientId: process.env.REACT_APP_CLIENT_ID!,
    cacheLocation: 'localstorage',
    useRefreshTokens: true
});

const getInitials = (name:any) => {
  let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
  let initials = [...name.matchAll(rgx)] || [];
  initials = (
    (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
  ).toLowerCase();
  return initials;
}

const CallbackURI = process.env.REACT_APP_REDIRECT_URI!

export const authProvider:AuthProvider = {
    // called when the user attempts to log in
    login: (url: { location: string | undefined; }) => {
        if (typeof url === 'undefined') {
            return auth0.loginWithRedirect({
              authorizationParams: {
                redirect_uri: CallbackURI
              }
            })
        }
        return auth0.handleRedirectCallback(url.location);
    },
    // called when the user clicks on the logout button
    logout: () => {
        return auth0.isAuthenticated().then(function (isAuthenticated) {
            if (isAuthenticated) { // need to check for this as react-admin calls logout in case checkAuth failed
                return auth0.logout({
                  logoutParams: {
                    returnTo: window.location.origin,
                    federated: true // have to be enabled to invalidate refresh token
                  },
                   
                });
            }
            return Promise.resolve()
        })
    },
    // called when the API returns an error
    checkError: ({status}) => {
        if (status === 401 || status === 403) {
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: async() => {
      const isAuthenticated = await auth0.isAuthenticated();
      if (isAuthenticated) {
          return Promise.resolve();
      }
  },
    getPermissions: () => {
        return Promise.resolve()
    },
    
    getIdentity: async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const userOBJ = {
          id: 1 || user!.attributes.sub,
          fullName: user!.attributes.name,
          avatar: "https://i1.wp.com/cdn.auth0.com/avatars/"+String(getInitials(user!.attributes.name))+".png?ssl=1",
        }
        return Promise.resolve(userOBJ);
    } catch (error) {
        return Promise.reject(error);
    }
    }
  }
        















// import { AuthProvider } from 'react-admin';
// import userDetails from './Services/utils'

// const userObj = {
//     user_id: 0,
//     nickname: "Arpita",
//     picture: 'https://s.gravatar.com/avatar/f77226b0d59fc796ef61c196de94b503?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fku.png'
// }

// // let key = "@@auth0spajs@@::Etx56lKl4Si8edfnSkTC8KIxdS4L37nY::@@user@@"
// // const a:any = localStorage.getItem(key);
// // const b = JSON.parse(a);
// // const user = b.decodedToken.user;

// // const newUserObj = {
// //     user_id: 0,
// //     nickname: user.nickname,
// //     picture: user.picture
// // }
// 




// export const authProvider: AuthProvider = {
//     login: ({ username }) => {
//         localStorage.setItem('username', username);
//         // accept all username/password combinations
//         return Promise.resolve();
//     },
//     logout: () => {
//         localStorage.removeItem('username');
//         return Promise.resolve();
//     },
//     checkError: () => Promise.resolve(),
//     checkAuth: () =>
//         localStorage.getItem('username') ? Promise.resolve() : Promise.reject(),
//     getPermissions: () => Promise.reject('Unknown method'),
//     getIdentity: () =>
//         Promise.resolve({
//             id: userObj.user_id,
//             fullName: userObj.nickname,
//             avatar: userObj.picture
//         }),
// };
