import { Box, Button, Card, CardContent, CardMedia, Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react'
import { convertNumber, firstCapital } from '../Admin/Approvals/util';
import { ModeEdit } from '@mui/icons-material';
import UserProfile from '../profile/UserProfile';
import axios from 'axios';
import ImageUpload from '../profile/ImageUpload';

const VendorProfile = (props: any) => {
  const { currentSelection, user, setCurrentSelection, editTable, setEditTable, setModalShow, setShowDialog } = props;
  const [editable, setEditable] = useState(false);
  const createLink = (link: any) => {
    const mail = "mailto:" + link + "?body=New mail to connector";
    return (mail)
  }
  const [isAddressValidated, setIsAddressValidated] = useState(false);
  const [validatedData, setValidatedData] = useState<any>();
  const [showAddressSuggestion, setShowAddressSuggestion] = useState("");
  const [saveLoader, setSaveLoader] = useState(false);
  const [cityInfo, setCityInfo] = useState<any>();
  const [disableFields, setDisableFields] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const initialError = {
    phone1: false,
    message1: "",
    phone2: false,
    message2: "",
    address: false,
    addressMessage: "",
    zipcode: false,
    zipcodeMessage: "",
    state: false,
    stateMessage: "",
    city: false,
    cityMessage: "",
    street: false,
    streetMessage: "",
    country: false,
    countryMessage: ""
  }
  const [formValidation, setFormValidation] = useState(initialError);
  const [showImageUpload, setShowImageUpload] = useState(false)
  const [profileSaveLoader, setProfileSaveLoader] = useState(false);
  const [file, setFile] = useState<any>();


  const imageUpload = () => {
    setShowImageUpload(true);
  }

  const onEdit = () => {
    setModalShow(false)
    setEditTable(true);
  }

  const handleProfilePictureUpload = () => {
    var userType = user?.role;
    setProfileSaveLoader(true)
    // setPictureLoader(true);
    axios.put(process.env.REACT_APP_BASE_URL + "vendor" + "/image/" + currentSelection.id, { file },
      {
        headers: {
          "Authorization": "Bearer " + user.accessToken,
          "Content-type": "multipart/form-data"
        }
      })
      .catch((error: any) => {

        setProfileSaveLoader(false)
      })
      .then((response: any) => {

        setShowImageUpload(false);
        // setFile(null);
        setProfileSaveLoader(false);
      })

  }

  useEffect(() => {
    addressValidation(currentSelection)
  }, [])

  const saveFunction = (temp: any) => {

    temp.addressVerified = isAddressValidated;
    temp.phoneNumber1 = temp.phoneNumber1.replace(/[^0-9]/g, "");
    if (temp.phoneNumber2) {
      temp.phoneNumber2 = temp.phoneNumber2.replace(/[^0-9]/g, "");
    }
    else {
      temp.phoneNumber2 = null;
    }
    setSaveLoader(true);

    var userType = user?.role;
    axios.put(process.env.REACT_APP_BASE_URL + 'vendor' + "/" + currentSelection.id, temp,
      {
        headers: {
          "Authorization": "Bearer " + user.accessToken,
        }
      })
      .catch((error: any) => {
        setSaveLoader(false)
        setShowAddressSuggestion("")
        setDisableFields(false);
        setDisableSave(false)
      })
      .then((response: any) => {
        setSaveLoader(false)
        setShowDialog(false)
        setEditTable(false)
        setFormValidation(initialError)
        setShowAddressSuggestion("")
        setDisableFields(false);
        setDisableSave(false);
        setCityInfo({
          ...cityInfo,
          name: response.data.cityName,
          countryCode: response.data.countryCode,
          stateCode: response.data.stateCode,
          latitude: response.data.latitude,
          longitude: response.data.longitude,
        })
      })
  }


  const handleSave = (values: any) => {
    if (currentSelection.phoneNumber1 === null || currentSelection.phoneNumber1 === "") {
      setFormValidation({ ...formValidation, phone1: true, message1: "Please enter a valid contact number." })
    }
    else if (currentSelection.phoneNumber1.replace(/[^0-9]/g, "").length < 11) {
      setFormValidation({ ...formValidation, phone1: true, message1: "Please enter a valid contact number." })
    }
    else {
      if (!formValidation.phone1 && !formValidation.phone2) {
        setSaveLoader(true);
        saveFunction(currentSelection);
      }
    }
  };

  const onClickYes = () => {
    var temp = currentSelection;
    validatedData.result.address.addressComponents.map((record: any) => {

      if (record.componentType === "street_number") {
        temp.address = record.componentName.text;
      }
      else if (record.componentType === "route") {
        temp.address += " " + record.componentName.text;
      }
      else if (record.componentType === "subpremise") {
        temp.address += ", " + record.componentName.text;
      }
      else if (record.componentType === "locality") {
        temp.cityName = record.componentName.text;
      }
      else if (record.componentType === "administrative_area_level_1") {
        temp.stateName = record.componentName.text;
      }
      else if (record.componentType === "country") {
        temp.countryName = record.componentName.text;
      }
      else if (record.componentType === "postal_code") {
        temp.zipcode = record.componentName.text;
      }
      else if (record.componentType === "postal_code_suffix") {
        temp.zipcode += "-" + record.componentName.text;
      }
    })
    // setCurrentSelection({ ...currentSelection, address: street, cityName: city, stateName: state, countryName: country, zipcode: pincode })
    setIsAddressValidated(true);
    setDisableSave(false);
    setDisableFields(false);
    setShowAddressSuggestion("");
    setSaveLoader(false);
  }

  const checkValidation = (types: any) => {
    if (types === "street_number") {
      setFormValidation({ ...formValidation, address: true, addressMessage: "Please enter a valid street number" });
    }
    else if (types === "subpremise") {
      setFormValidation({ ...formValidation, street: true, streetMessage: "Please enter a valid street number" });
    }
    else if (types === "locality") {
      setFormValidation({ ...formValidation, city: true, cityMessage: "Please Enter a valid city name" });
    }
    else if (types === "administrative_area_level_1") {
      setFormValidation({ ...formValidation, state: true, stateMessage: "Please enter a valid state" });
    }
    else if (types === "postal_code" || types === "postal_code_suffix") {
      setFormValidation({ ...formValidation, zipcode: true, zipcodeMessage: "Please Enter a valid zipcode" });
    }
    else if (types === "country") {
      setFormValidation({ ...formValidation, country: true, countryMessage: "Please Enter a valid country name" });
    }
  }
  function containsSpecialChars(str: string) {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>?~]/;
    return specialChars.test(str);
  }
  const addressValidation = (currentData: any) => {
    return new Promise((resolve, reject) => {
      if (currentData.cityName === "" || currentData.stateName === "" || currentData.countryName === "" || currentData.zipcode === "" || currentData.address === "" || currentData.cityName === null || currentData.stateName === null || currentData.countryName === null || currentData.zipcode === null || currentData.address === null) {
        setIsAddressValidated(false);
        reject(false);
      }
      else if (containsSpecialChars(currentData.street)) {
        setFormValidation({ ...formValidation, street: true, streetMessage: "Please enter a valid street number" }); setIsAddressValidated(false);
        reject(false);
      }
      else {
        var addressLine = currentData.address + "," + currentData.cityName + ", " + currentData.stateName + ", " + currentData.zipcode + ", " + currentData.countryName;
        axios.post("https://addressvalidation.googleapis.com/v1:validateAddress?key=" + process.env.REACT_APP_GOOGLE_API_KEY, {
          "address": {
            "regionCode": currentData.countryCode,
            "addressLines": [addressLine]
          },
          "enableUspsCass": true
        })
          .catch((error: any) => {
            setFormValidation({ ...formValidation, address: true, addressMessage: "Please enter a valid address, if the problem persists please reach out to the administrator." });
            setIsAddressValidated(false);
            reject(false);
          })
          .then((response: any) => {

            if (response.data.result.verdict.hasUnconfirmedComponents) {
              setFormValidation(initialError);

              if (response.data.result.address.missingComponentTypes) {
                response.data.result.address.missingComponentTypes.reverse().map((types: any) => {
                  if (types !== "subpremise") {
                    checkValidation(types);
                    setIsAddressValidated(false);
                  }
                })
              }
              if (response.data.result.address.unconfirmedComponentTypes) {
                response.data.result.address.unconfirmedComponentTypes.reverse().map((types: any) => {
                  checkValidation(types);
                  setIsAddressValidated(false);
                })
              }
              reject(false)

            }
            else {
              setValidatedData(response.data);
              var street = "";
              var city = ""
              var state = "";
              var country = "";
              var zipcode = "";
              response.data.result.address.addressComponents.map((record: any) => {

                if (record.componentType === "street_number") {
                  street = street + record.componentName.text
                }
                else if (record.componentType === "route") {
                  street = street + " " + record.componentName.text
                }
                else if (record.componentType === "subpremise") {
                  street = street + " " + record.componentName.text
                }
                else if (record.componentType === "locality") {
                  city = " " + record.componentName.text;
                }
                else if (record.componentType === "administrative_area_level_1") {
                  state = " " + record.componentName.text
                }
                else if (record.componentType === "postal_code") {
                  zipcode = " " + record.componentName.text;
                }
                else if (record.componentType === "postal_code_suffix") {
                  zipcode = zipcode + "-" + record.componentName.text;
                }
                else if (record.componentType === "country") {
                  country = " " + record.componentName.text
                }
              })
              var suggestion = "";
              var currentAddress = "";
              if (currentData.street) {
                suggestion = street + " " + currentData.street + "," + city + "," + state + "," + country + "," + zipcode;
                currentAddress = currentData.address + " " + currentData.street + ", " + currentData.cityName + ", " + currentData.stateName + ", " + currentData.countryName + ", " + currentData.zipcode;

              }
              else {
                suggestion = street + "," + city + "," + state + "," + country + "," + zipcode;
                currentAddress = currentData.address + ", " + currentData.cityName + ", " + currentData.stateName + ", " + currentData.countryName + ", " + currentData.zipcode;

              }

              if (suggestion === currentAddress) {
                setIsAddressValidated(true);
                setShowAddressSuggestion("");
                setDisableFields(false);
                setDisableSave(false);
                resolve(true);
              }
              else {
                setIsAddressValidated(false);
                setShowAddressSuggestion(suggestion);
                setDisableFields(true)
                setDisableSave(true);
                reject(false);
              }
              setFormValidation(initialError)
            }
          })
      }
    });
  }
  const onClickEdit = () => {
    setIsAddressValidated(false);
    setShowAddressSuggestion("");
    setDisableSave(false);
    setDisableFields(false);
  }

  return (
    <>
      <ImageUpload showDialog={showImageUpload} setShowDialog={setShowImageUpload} saveLoader={profileSaveLoader} handleSave={handleProfilePictureUpload} file={file} setFile={setFile} />
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ textAlign: "center", marginTop: 4 }}>
          {currentSelection.logo ?
            <>
              {file ? <CardMedia
                component="img"
                // image={`data:${currentSelection.contentType};base64,${currentSelection.logo}`}
                image={URL.createObjectURL(file)}
                alt="Connected People"
                sx={{
                  height: "15rem",
                  width: "15rem",
                  textAlign: "center",
                  margin: "auto"
                }}
              /> :
                <CardMedia
                  component="img"
                  image={`data:${currentSelection.contentType};base64,${currentSelection.logo}`}
                  // image={URL.createObjectURL(file)}
                  alt="Connected People"
                  sx={{
                    height: "15rem",
                    width: "15rem",
                    textAlign: "center",
                    margin: "auto"
                  }}
                />
              }
            </>
            :
            <>
              <CardMedia
                component="img"
                image={"assets/icons/userIcon.png"}
                alt="Connected People"
                sx={{
                  height: "15rem",
                  width: "15rem",
                  textAlign: "center",
                  margin: "auto"
                }}
              />
            </>}
          {editTable ? <><Box sx={{ marginTop: 4 }}><Button onClick={imageUpload}>Upload new image</Button></Box></> : <></>}
          <Typography sx={{ fontWeight: 'bold' }} variant="h5" mt={2}>
            {firstCapital(currentSelection?.name)}
          </Typography>
        </Grid>

        {editTable ?
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ marginTop: 4 }}>
            <UserProfile userType='vendor' validateAddress={addressValidation} setShowDialog={setShowDialog} setModalShow={setModalShow} setFormData={setCurrentSelection} saveLoader={saveLoader} handleSave={handleSave} isAddressValidated={isAddressValidated} data={currentSelection} user={user} showAddressSuggestion={showAddressSuggestion} setEditTable={setEditTable} editable={true} onClickEdit={onClickEdit} onClickYes={onClickYes} formData={currentSelection} formValidation={formValidation} setFormValidation={setFormValidation} />
          </Grid>
          :
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Card sx={{ marginRight: 2, marginBottom: 2, marginTop: 2 }}>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12}>

                    <Typography sx={{ fontWeight: 'bold', fontSize: '20px', }}>
                      Admin Name
                    </Typography>

                    <Card sx={{ padding: 1, boxShadow: 0, backgroundColor: '#F0F0F0', }}>
                      <Typography variant="subtitle2">
                        {currentSelection.adminName ?
                          <>
                            {firstCapital(currentSelection.adminName)}
                          </>
                          :
                          <><Typography sx={{ fontWeight: "light" }} >No admin name available.</Typography></>}
                      </Typography>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '20px', }}>
                      Website
                    </Typography>
                    <Card sx={{ padding: 1, boxShadow: 0, backgroundColor: '#F0F0F0', }}>
                      <Typography variant="subtitle2">
                        {currentSelection.website ?
                          <>
                            {currentSelection.website}
                          </>
                          :
                          <><Typography sx={{ fontWeight: "light" }} >couldn't find any website associated with the vendor</Typography></>}
                      </Typography>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '20px', }}>
                      Contact Number
                    </Typography>
                    <Card sx={{ padding: 1, boxShadow: 0, backgroundColor: '#F0F0F0', }}>
                      <Typography variant="subtitle2">
                        {currentSelection.phoneNumber1 ?
                          <>
                            {convertNumber(currentSelection.phoneNumber1)}
                          </>
                          :
                          <><Typography sx={{ fontWeight: "light" }} >No contact details available</Typography></>}
                      </Typography>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '20px', }}>
                      Email
                    </Typography>
                    <Card sx={{ padding: 1, boxShadow: 0, backgroundColor: '#F0F0F0', }}>
                      <Typography variant="subtitle2">
                        {currentSelection.email ?
                          <>
                            {currentSelection.email}
                          </>
                          :
                          <><Typography sx={{ fontWeight: "light" }} >No alternate contact available.</Typography></>}
                      </Typography>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '20px', }}>
                      Address
                    </Typography>
                    <Card sx={{ padding: 1, boxShadow: 0, backgroundColor: '#F0F0F0', }}>
                      <Typography variant="subtitle2">
                        {currentSelection.addressVerified ?
                          <>
                            {currentSelection.address}{currentSelection.street ? <> {currentSelection.street},</> : <>,</>} {currentSelection.cityName}, {currentSelection.stateName}, {currentSelection.countryName}, {currentSelection.zipcode}
                          </>
                          :
                          <><Typography sx={{ fontWeight: "light" }} >Couldn't find a valid address.</Typography></>}
                      </Typography>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '20px', }}>
                      Description
                    </Typography>
                    <Card sx={{ padding: 1, boxShadow: 0, backgroundColor: '#F0F0F0', }}>
                      <Typography variant="subtitle2">
                        {currentSelection.description ?
                          <>
                            {currentSelection.description}
                          </>
                          :
                          <><Typography sx={{ fontWeight: "light" }} >No description found.</Typography></>}
                      </Typography>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        }
      </Grid>
    </>
  )
}

export default VendorProfile