import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Autocomplete, Grid, IconButton, TextField, Typography, } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { ToastContainer, toast } from "react-toastify";
import ErrorComponent from '../../Layout/ErrorComponent';
import FilterComponent from '../../Layout/FilterComponent';
import SearchTextField from '../../Layout/SearchTextField';
import { getComparator, sortedRowInformation } from '../../utils/SortingHandler';
import AdminTable from './AdminTable';
import RejectionForm from './RejectionForm';
import VendorTable from './VendorTable';
import ProfileScreen from '../../Profile Screens/ProfileScreen';


const ApproveConnectors = (props: any) => {
    let { activityData, setActivityData, handleApprove, approveRow, globalVal, setPendingConnectors, user, setEditablePage, setCurrentEditSelection, setSelectionType } =
        props;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const [rowData, setRowData] = useState<any>();
    const [globalData, setGlobalData] = useState<any>();
    const [connSearch, setConnSearch] = useState('');
    const [orderDirection, setOrderdirection] = useState<'asc' | 'desc'>('asc');
    const [valueToOrderBy, setValueToOrderBy] = useState('appliedDate');
    const [anchorEl, setAnchorEl] = useState(null);
    const [statusValue, setStatusValue] = useState<any>(null);
    const [statusVisible, setStatusVisible] = useState(false);
    const [EditLoader, setEditLoader] = useState(false)
    const [approveLoader, setApproveLoader] = useState(0);
    const [error, setError] = useState(false);
    const [change, setChange] = useState(0);
    const [loader, setLoader] = useState(false);
    const [formData, setFormData] = useState<any>();
    const [showRejectionForm, setShowRejectionForm] = useState(false);
    const [saveLoader, setSaveLoader] = useState(false);
    const [rejectionType, setRejectionType] = useState("");
    const [empty, setEmpty] = useState(false);
    const [paginationLoader, setPaginationLoader] = useState(false);
    const [firstLoader, setFirstLoader] = useState(true);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const [totalConnectors, setTotalConnectors] = useState(5);
    const [editable, setEditable] = useState(-1)
    const [showDialog, setShowDialog] = useState<boolean>(false)
    const [currentSelection, setCurrentSelection] = useState()
    const handleChangePage = (event: any, newPage: any) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    let [rowState, setRowState] = useState({
        id: 0,
        name: '',
        email: '',
        gender: null,
        title: null,
        phoneNumber1: null,
        phoneNumber2: null,
        hasNewsletter: null,
        phoneNumberVerified: false,
        emailVerified: false,
        legalDocsVerified: false,
        w9_verified: false,
        isApproved: false,
        status: '',
        createdBy: '',
        createdDatetime: null,
        modifiedBy: '',
        modifiedDatetime: null,
        companyId: null,
        addressVerified: false,
    });
    const successToast = () => toast.success("Mail sent successfully");
    const failureToast = () => toast.error("There was some error in sending the notification, please try again.");

    useEffect(() => {
        if (firstLoader) {
            setLoader(true);
        }
        if (user) {

            setPaginationLoader(true);
            axios.get(process.env.REACT_APP_BASE_URL + "connector/pages",
                {
                    headers: {
                        "Authorization": "Bearer " + user.accessToken,
                    },
                    params: {
                        pageNo: page,
                        pageSize: rowsPerPage,
                        sortBy: "id",
                        sortDir: "desc"
                    }
                }
            )
                .catch((err: any) => {
                    setError(true);
                    setPaginationLoader(false);
                    setFirstLoader(false);
                    setLoader(false);
                })
                .then((response: any) => {
                    setRowData(response.data.data);
                    setGlobalData(response.data.data);
                    if (response.data.data.length === 0) {
                        setEmpty(true);
                    }
                    setTotalConnectors(response.data.totalElements)
                    setPendingConnectors(response.data.additionalData.pendingConnectorCount)
                    setPaginationLoader(false);
                    setFirstLoader(false);
                    setLoader(false);
                })
        }
    }, [page, rowsPerPage, user, change])

    useEffect(() => {
        if (globalData) {
            var tempArr = [...globalData];
            if (statusValue != null && statusValue !== 'All') {
                tempArr = tempArr.filter(obj => {
                    return obj.isApproved === statusValue.toLowerCase();
                });
            }
            if (connSearch.trim().length > 0) {
                tempArr = tempArr.filter(row => {
                    return row.name.toLowerCase().includes(connSearch.trim().toLowerCase());
                });
            }
            setRowData(tempArr);
        }
    }, [connSearch, statusValue]);

    const open = Boolean(anchorEl);

    const checkApprove = (row: any) => {
        if (row.legalDocsVerified && row.phoneNumberVerified && row.emailVerified && row.addressVerified) return false;;
        return true;
    };

    const handleEdit = (row: any) => {
        setRowState(row);
        setEditable(row.id);
    };

    const handleStatusClose = () => {
        setStatusValue(null);
        setStatusVisible(false);
    };

    const createSortHandler = (property: any) => (event: any) => {
        const isAscending = orderDirection === 'asc';
        setValueToOrderBy(property);
        setOrderdirection(isAscending ? 'desc' : 'asc');
        sortedRowInformation(rowData, getComparator(orderDirection, property));
    };


    const handleFilterOpt = (opt: any) => {
        setAnchorEl(null);
        setStatusVisible(true);
    };

    const cancelEdit = (row: any) => {
        setRowState(row);
        setEditable(-1);
    };

    const onSave = () => {
        setEditLoader(true)
        axios.put(process.env.REACT_APP_BASE_URL + "connector/" + rowState.id, rowState,
            {
                headers: {
                    "Authorization": "Bearer " + user.accessToken,
                }
            })
            .catch((error) => {

            })
            .then((response: any) => {
                var tempData = [...rowData]
                const ind = tempData.findIndex(obj => obj.id === response.data.id);
                tempData[ind] = response.data;
                setRowData(tempData);
                setGlobalData(tempData);
                setEditable(-1);
                setEditLoader(false)
                // setChange(prevVal => prevVal + 1)
            })
    };

    const toggleHandler = (e: any, row: any) => {
        setRowState({
            ...row,
            [e.target.name]: e.target.checked,
        });
    };

    const onApprove = (row: any, action: string) => {
        const current = row.isApproved;
        setApproveLoader(row.id)
        var temp = row;
        temp.isApproved = action;
        axios.put(process.env.REACT_APP_BASE_URL + "connector/" + temp.id, temp,
            {
                headers: {
                    "Authorization": "Bearer " + user.accessToken,
                }
            })
            .catch((error: any) => {
                failureToast();
                setApproveLoader(0)
                setChange(prevVal => prevVal + 1)
                setSaveLoader(false);
            })
            .then((response: any) => {
                var tempData = [...rowData]
                const ind = tempData.findIndex(obj => obj.id === response.data.id);
                tempData[ind] = response.data;
                if (current === "pending") {
                    setPendingConnectors((prevVal: any) => prevVal - 1)
                }
                setApproveLoader(0);
                successToast();
                setRowData(tempData);
                setGlobalData(tempData);
                setSaveLoader(false);
                setShowRejectionForm(false);
            })
    }

    const onClickReject = (row: any, type: any) => {
        setRejectionType(type)
        setShowRejectionForm(true);
        setFormData(row);
    }

    const resetRevoke = () => {
        var temp = rowState;
        temp.phoneNumberVerified = true;
        temp.emailVerified = true;
        temp.legalDocsVerified = true;
        temp.w9_verified = true;
        temp.addressVerified = true;
        var tempData = [...rowData]
        const ind = tempData.findIndex(obj => obj.id === temp.id);
        tempData[ind] = temp;
        setRowData(tempData);
        setGlobalData(tempData);
    }
    const onCancelReject = (row: any) => {
        setShowRejectionForm(false);

        if (rejectionType === "revoked") {
            resetRevoke()
        }
    }

    const handleRejection = () => {
        setApproveLoader(formData.id)
        setShowRejectionForm(false);

        if (!saveLoader) {
            setSaveLoader(true);
            axios.put(process.env.REACT_APP_BASE_URL + "connector/" + formData.id, formData,
                {
                    headers: {
                        "Authorization": "Bearer " + user.accessToken,
                    }
                })
                .catch((error: any) => {
                    failureToast();
                    setApproveLoader(0);
                    if (rejectionType === "revoked") {
                        resetRevoke();
                    }
                })
                .then((response: any) => {
                    var tempData = [...rowData]
                    const ind = tempData.findIndex(obj => obj.id === response.data.id);
                    tempData[ind] = response.data;
                    setRowData(tempData);
                    setGlobalData(tempData);
                    setSaveLoader(false);
                    setApproveLoader(0);
                    successToast();
                    setShowRejectionForm(false);
                })
        }
    }

    const onRevoke = (row: any) => {
        setRowState(row);
        var temp = row;
        temp.phoneNumberVerified = false;
        temp.emailVerified = false;
        temp.legalDocsVerified = false;
        temp.w9_verified = false;
        onClickReject(temp, "revoked")
    }
    const statusOption = ['Pending', 'Approved', 'Rejected', 'Revoked']
    return (
        <>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <ProfileScreen
                showDialog={showDialog}
                setShowDialog={setShowDialog}
                user={user}
                currentSelection={currentSelection}
                type={"connector"}
            />
            <RejectionForm showDialog={showRejectionForm} handleClose={onCancelReject} type={"connector"} formData={formData} setFormData={setFormData} handleSubmit={handleRejection} rejectionType={rejectionType} saveLoader={saveLoader} />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {user &&
                        <Grid container rowSpacing={1}>
                            {user!.role === 'admin' && (
                                <>
                                    {empty ?
                                        <>
                                            <Grid item xs={12} sx={{ textAlign: 'center', marginTop: 2 }}>
                                                <img src="assets/loader/not_found.gif" height={'140vh'} alt='' />
                                                <Typography sx={{ fontWeight: 'bold' }}>
                                                    No Connectors found!
                                                </Typography>
                                                <Typography>
                                                    Seems like you don't have any connectors registered on ConnectorNet yet.<br />
                                                </Typography>
                                            </Grid>
                                        </>
                                        :
                                        <>
                                            {rowData &&
                                                <>
                                                    <Grid item xs={6}>
                                                        <SearchTextField
                                                            val={connSearch}
                                                            funcHandle={setConnSearch}
                                                            type={"Connector"}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sx={{ textAlign: "right", justifyContent: "right", display: "flex", alignItems: "right" }}>
                                                        <FilterComponent
                                                            anchorEl={anchorEl}
                                                            open={open}
                                                            setAnchorEl={setAnchorEl}
                                                            options={['Status']}
                                                            handleFilterOpt={handleFilterOpt}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={1}>
                                                            {statusVisible && (
                                                                <>
                                                                    <Autocomplete
                                                                        id="status"
                                                                        options={statusOption}
                                                                        sx={{
                                                                            '& .MuiInputBase-input': {
                                                                                height: '1.5rem'
                                                                            }
                                                                        }}
                                                                        value={statusValue}
                                                                        onChange={(
                                                                            event,
                                                                            newValue: any
                                                                        ) => {
                                                                            setStatusValue(newValue);
                                                                        }}
                                                                        renderInput={params => (
                                                                            <TextField
                                                                                {...params} inputProps={{
                                                                                    ...params.inputProps,
                                                                                    style: { paddingBottom: "7px", width: "100%" }
                                                                                }}
                                                                                type='text'
                                                                                placeholder="Status" hiddenLabel fullWidth
                                                                            />
                                                                        )}
                                                                    />
                                                                    <IconButton
                                                                        onClick={handleStatusClose}
                                                                    >
                                                                        <CancelOutlinedIcon />
                                                                    </IconButton>
                                                                </>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </>}
                                        </>}
                                    {error ?
                                        <>
                                            <Grid item xs={12} sx={{ textAlign: "center", marginTop: 10 }}>
                                                <ErrorComponent />
                                            </Grid>
                                        </> :
                                        <>
                                            {!empty &&
                                                <>
                                                    <AdminTable
                                                        user={user}
                                                        data={rowData}
                                                        setCurrentSelection={setCurrentSelection}
                                                        setShowDialog={setShowDialog}
                                                        valueToOrderBy={valueToOrderBy}
                                                        createSortHandler={createSortHandler}
                                                        orderDirection={orderDirection}
                                                        checkApprove={checkApprove}
                                                        editable={editable}
                                                        onSave={onSave}
                                                        cancelEdit={cancelEdit}
                                                        rowState={rowState}
                                                        handleEdit={handleEdit}
                                                        toggleHandler={toggleHandler}
                                                        editLoader={EditLoader}
                                                        onApprove={onApprove}
                                                        approveLoader={approveLoader}
                                                        onRevoke={onRevoke}
                                                        loader={loader}
                                                        onClickReject={onClickReject}
                                                        page={page}
                                                        rowsPerPage={rowsPerPage}
                                                        handleChangePage={handleChangePage}
                                                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                                                        totalConnectors={totalConnectors}
                                                        paginationLoader={paginationLoader}
                                                        setEditablePage={setEditablePage}
                                                        setSelectionType={setSelectionType}
                                                        setCurrentEditSelection={setCurrentEditSelection}
                                                    />
                                                </>}
                                        </>}
                                </>
                            )}
                            {(user?.role === 'vendor' || user.role === "contact") && (
                                <VendorTable
                                    activityData={activityData}
                                    setActivityData={setActivityData}
                                    handleApprove={handleApprove}
                                    approveRow={approveRow}
                                    globalVal={globalVal}
                                    user={user}
                                />
                            )
                            }
                        </Grid>
                    }
                </Grid>
            </Grid>
        </>
    );
};
export default ApproveConnectors;
