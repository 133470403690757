import { Box, Button, Dialog, DialogActions, DialogContent, Grid, Typography } from "@mui/material";

const ConfirmationDialog = (props: any) => {
    const { showConfirmationDialog, handleConfirmationDialogClose, confirmationRow, handleInvitationSent, customContent, customConfirmText, customCancelText } = props;
    const handleConfirm = () => {
        handleConfirmationDialogClose();
        handleInvitationSent(confirmationRow, 'invitesSent');
    }
    return (
        <Dialog open={showConfirmationDialog} fullWidth maxWidth="md">
            <DialogContent>
                <Grid container spacing={1} justifyContent={"center"} alignItems={"center"} sx={{ paddingY: 5 }}>
                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                        <Typography sx={{ fontSize: 20 }}>  {customContent || 'Do you agree to having sent the invitation manually?'} </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                        <Box>
                            <Button
                                onClick={handleConfirmationDialogClose}
                                variant="contained"
                                color="error"
                                sx={{ width: 100, height: 45, padding: 1, marginX: 2 }}
                            >
                                {customCancelText || 'No'}
                            </Button>
                            <Button
                                onClick={handleConfirm}
                                variant="contained"
                                color="primary"
                                sx={{ width: 100, height: 45, padding: 1, marginX: 2 }}
                            >
                                {customConfirmText || 'Yes'}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>)
}
export default ConfirmationDialog;