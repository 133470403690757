import { Alert, AlertTitle, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, Radio, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DocumentPreview from '../transactions/DocumentPreview';
import DocumentUpload from '../transactions/DocumentUpload';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';

const UploadDoument = (props: any) => {
    const { showDialog, file, handleFileChange, toggleUpload, fileTypes, onDocumentLoadSuccess, show, numPages, handleSubmit, handleClose, setFile, saveLoader, type, showDocOptions, setDocumentStatus, documentStatus, typeError, setTypeError } = props;
    const onReset = () => {
        setFile(null)
        setDocumentStatus(null)
    }
    const [error, setError] = useState(false);
    
    useEffect(() => {

        if(documentStatus && setTypeError){
            setTypeError(false);
        }

        if(file){
            if(!documentStatus && setTypeError){
                setFile(null);
                setTypeError(true);
                return;
            }
            else if(file.size >= 5000000){
                setFile(null);
                setError(true);
            }
            else{
                setError(false);
            }
        }
    }, [file, documentStatus])


    const handleUploadDoc = (value: any) => {
        setDocumentStatus(value);

    };


    return (
        <Dialog open={showDialog}
            fullWidth
            maxWidth="lg"
            sx={{
                '.MuiDialog-paper': {
                    position: 'absolute',
                    width:  {xs:"90%", sm:"85%", md:"50%", lg:"50%", xl:"50%"},
                    minHeight: '400px',
                    minWidth: {xs:"90%", sm:"85%", md:"50%", lg:"50%", xl:"50%"}
                },
            }}>
            <DialogTitle sx={{ textAlign: "center", fontWeight: 'bold', backgroundColor: "#F0F7FF", fontSize:{xs:"large", sm:"large", md:"34px", lg:"34px", xl:"34px"} }}>
                    {type}
                    <Tooltip
                        title={`Drag and drop or upload your ${type} in the space provided below.`}
                        arrow
                        placement="right-end"
                    >
                        <Button>
                            <InfoOutlinedIcon />
                        </Button>
                    </Tooltip>
                <IconButton onClick={handleClose} sx={{ float: "right", color: "red" }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} sx={{ justifyContent: 'center', marginTop:3 }}>
                        {file?
                            <>
                                <DocumentPreview
                                    file={file}
                                    onDocumentLoadSuccess={onDocumentLoadSuccess}
                                    show={show}
                                    numPages={numPages}
                                    setFile={setFile}
                                />
                            </>
                            :
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "2rem",
                                    height: "400px",
                                }}
                            >
                                {showDocOptions && (
                                    <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                        <Box
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                marginBottom: "23px",
                                            }}
                                            sx={{
                                                height : "50px"
                                            }}
                                        >
                                            <FormControlLabel
                                                control={<Radio checked={documentStatus === 'signed'} onChange={() => handleUploadDoc('signed')} />}
                                                label="Signed"
                                                sx={{ marginRight: 3 }}
                                            />
                                            <FormControlLabel
                                                control={<Radio checked={documentStatus === 'unsigned'} onChange={() => handleUploadDoc('unsigned')} />}
                                                label="Unsigned"
                                            />
                                        </Box>
                                    </Grid>
                                )}
                                <DocumentUpload
                                    file={file}
                                    handleFileChange={handleFileChange}
                                    toggleUpload={toggleUpload}
                                    fileTypes={fileTypes}
                                    type={"pdf"}
                                    onReset={onReset}
                                    showDocOptions={showDocOptions}
                                    setDocumentStatus={setDocumentStatus}
                                    documentStatus={documentStatus}
                                />
                            </Box>}
                        <Grid item xs={12} sx={{textAlign:"center"}}>
                            {error && <Typography sx={{color:"red", marginTop:1}}>The file size is exceeding the maximum file size of 5MB !</Typography>}
                            {typeError && <Typography sx={{color:"red", marginTop:1}}>Please choose the type of document you want to upload !</Typography>}
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ paddingRight: 5, paddingY: 2 }}>
                {/* {file? 
                <> */}
                    <Button
                        onClick={handleSubmit}
                        sx={{ width: 100, height: 45, padding: 1 }}
                        variant="contained"
                        disabled={saveLoader || !file || typeError}
                    >
                        {saveLoader?
                        <>
                            <CircularProgress sx={{color:"white"}}/>
                        </>
                        :
                        <>
                            Save
                        </>}
                    </Button>
                {/* </>:<></>} */}
            </DialogActions>
        </Dialog>
    );
};

export default UploadDoument;
