import { Box, TableCell, Typography } from '@mui/material';
import React from 'react';

const StatusCell = (props:any) => {
    const {textClr, bgClr, text} = props;
    return (
        <TableCell align="center">
            <Box display="flex" justifyContent="center"
                sx={{
                    backgroundColor: bgClr,
                    color: textClr,
                    padding: 1,
                    width: 110,
                    borderRadius: 12,
                    margin:"auto",
                    whiteSpace:"noWrap"
                }}
            >
                {/* @ts-ignore */}
                <Typography
                    sx={{
                        fontWeight: 'bold',
                        textAlign: 'center',
                        fontSize: "15px"
                    }}
                >
                    {text}
                </Typography>
            </Box>
        </TableCell>
    );
};

export default StatusCell;
